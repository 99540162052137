import React, { Component } from "react";
import Skeleton from '@material-ui/lab/Skeleton';
import MahoeIcon from "components/MahoeIcons";
import { Tooltip } from "@material-ui/core";

export class StatsCard extends Component {
  render() {
    if (this.props.loading) {
      return (
        <Skeleton className="card-stats" animation="wave" variant="rect" width={"100%"} height={"100%"} style={{ borderRadius: 16, marginBottom: 30 }} />
      )
    }

    if ((!this.props.statsText && this.props.statsText !== 0 && !this.props.statsComponent) /*|| this.props.statsText === "n/a"*/) {
      return (
        <div className="card card-stats" id={this.props.id}>
          <div className="content">
            <div className={`first-line ${this.props.smallText ? 'first-line--small-text' : ''}`} style={{ lineHeight: this.props.lineHeight || 0 }}>
              <p>{"Falha ao buscar dados"}</p>
              <div className="icon-big text-center icon-warning">
                {
                  this.props.onReload
                    ? <MahoeIcon
                      aria-describedby={"iconTooltip"}
                      name="reload"
                      size="23px"
                      fill="#039BE5"
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.props.onReload()}
                    />
                    : <MahoeIcon
                      aria-describedby={"iconTooltip"}
                      name="info"
                      size="23px"
                      fill="red"
                    />
                }
              </div>
            </div>
            <div style={{ fontWeight: 500, color: "rgb(97,97,97)", fontSize: this.props.labelSize ? this.props.labelSize : "1rem", lineHeight: "26px" }}>{this.props.statsValue}</div>
          </div>
        </div>
      )
    }

    return (
      <div className="card card-stats" id={this.props.id} style={this.props.style && this.props.style }>
        <div className="content">
          {this.props.tooltip &&
            <Tooltip title={this.props.tooltip} placement="right-start">
              <div className="help-botton" style={this.props.TooltipStyle && this.props.TooltipStyle}>
                <MahoeIcon
                  aria-describedby={"iconTooltip"}
                  name="question"
                  size="1.11em"
                  fill="white"
                />
              </div>
            </Tooltip>}
          <div className={`first-line ${this.props.smallText ? 'first-line--small-text' : ''}`} style={{ lineHeight: this.props.lineHeight || 0 }}>
            {this.props.statsComponent && this.props.statsComponent}
            <p style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>{this.props.statsText}</p>
            <div className="icon-big text-center icon-warning">
              {this.props.bigIcon}
            </div>
          </div>
          <div style={{ fontWeight: 500, color: "rgb(97,97,97)", fontSize: this.props.labelSize ? this.props.labelSize : "1rem", lineHeight: "16px" }}>
            {this.props.statsValue}{this.props.minorStats && <span style={{ fontSize: '.7em' }}>{this.props.minorStats}</span>}
          </div>
          <span style={{ fontWeight: 500, color: "rgb(97,97,97)", fontSize: this.props.labelSize ? this.props.labelSize : "1rem", lineHeight: "16px" }}>{this.props.subStats}</span>
        </div>
      </div>
    );
  }
}

export default StatsCard;
