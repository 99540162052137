import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { ConfirmationAlert } from "components/Alert/CustomAlert.jsx";
import MahoeIcon from "components/MahoeIcons";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import getUserData from "utils/checkUser.js";
import config from 'utils/serverVars'
import UserContext from "context/userContext";
import ProfileAvatar from "components/ProfileAvatar";
import NavbarLink from "components/NavbarLink";
import NavbarMenu from "components/NavbarMenu";
import { translateLevel } from "utils/translate";
import NotificationList from "components/NotificationList"
import Badge from '@material-ui/core/Badge';
import { _agencyId, isSupportMarAberto } from 'utils/CommonData'
import { SupportGoBack } from "components/Support/SupportGoBack";
import { ClubeAmigoId } from "utils/CommonData";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      showSwalMessage: false,
      userData: {},
      profilePhoto: "",
      maxHeight: 0
    };
  }

  static contextType = UserContext

  getUserDataOnMount = async () => {
    const { setSocialName, setUserName, setUnreads } = this.context

    let userData = await getUserData(this.props.history);
    this.setState({ userData });
    this.updateDimensions()
    setSocialName(userData.socialName)
    setUserName(userData.name)
    setUnreads(userData.unreads)
  }

  clearSession = () => {
    localStorage.clear();
    document.location.href = "/";
  };

  updateDimensions() {
    const parentHeight = document.querySelector('.sidebar').clientHeight
    const siblingHeight = document.querySelector('.logo').clientHeight
    const maxHeight = parentHeight - siblingHeight
    this.setState({ width: window.innerWidth, maxHeight});
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    this.getUserDataOnMount();
  }

  firstAndLastWord = string => {
    if (!string) return ""

    let words = string.split(" ");
    if (words.length === 1) return words[0];
    return `${words[0]} ${words[words.length - 1].charAt(0)}`;
  };

  removeBodyClick = () => {

    if (document.documentElement.classList.contains("nav-open")) {
      let node = document.getElementById("bodyClick");
      document.body.removeChild(node);
    }

  };

  handleProfileButton = () => {
    const userData = this.state.userData
    const history = this.props.history

    if (['master', 'submaster', "operator", "agx"].includes(userData.level)) return

    else if (['corporation', 'subcorp'].includes(userData.level)) {
      history.push('/admin/corporation/profile')
      return
    }

    history.push('/admin/profile')
  }

  handleSocialName = (socialName) => (socialName?.length > 20 ? socialName.slice(0, 17).trim() + '...' : socialName)

  render() {
    const { showSwalMessage, userData } = this.state;
    const { socialName, userNameContext, unreads } = this.context
    const isAffiliate = userData?.agencyId === _agencyId
    const isSupport = isSupportMarAberto.includes(userData?.id) || userData?.hasSupport

    let userLevel = translateLevel(userData.level, userData?.corpId)

    if (userData.level === "administrator" && userData.hasManager) {
      userLevel = translateLevel("partner", userData?.corpId)
    }

    let isCorpOrMaster = ["corporation", "master", "submaster", "subcorp", "operator", "agx"].indexOf(userData.level) + 1;
    //let isCorp = userData.level === "corporation";
    let isAdm = userData.level === "administrator";
    let userName = "";
    if (userData.name) {
      switch (userData.level) {
        case 'subcorp':
        case 'corporation':
          userName = (socialName > 11 ? socialName.slice(0, 9) + '...' : socialName)
          break;
        case 'submaster':
        case 'master':
          userName = userData.name
          break;
        default:
          userName = this.firstAndLastWord(userNameContext)
          break;
      }
    }

    const corpOrMasterProfile = (
      <div className={['master', 'submaster', "operator", "agx"].includes(userData.level) ? 'master-logo-wrapper' : 'logo-wrapper'} onClick={() => this.handleProfileButton()}>
        <div className='logo-link flex-center'>
          <UserContext.Consumer>
            {context => (
              <ProfileAvatar level={userData.level} avatar={context.avatar} />
            )}
          </UserContext.Consumer>
        </div>
        <div className='username-container'>
          {(!['master', 'submaster', "operator", "agx"].includes(userData.level)) && (
            <div className="user-data-button">
              <h3 className="user-data-profile">Ver perfil</h3>
              <ArrowForwardIosIcon className="user-arrow" />
            </div>
          )}
          <div className='sidebar-container-user'>
            <UserContext.Consumer>
              {context => {
                if (["operator", "agx"].includes(userData.level) || userData?.hasSupport) return (
                  <span className="sidebar-username" style={{ fontSize: "1rem" }}>{this.firstAndLastWord(userData.name)}</span>
                )

                return (
                  <>
                    {(userData.level.toLowerCase() === "master" || userData.level.toLowerCase() === "submaster") ?
                      <span className="sidebar-username" style={{ fontSize: "1rem" }}>Banco PAN</span>
                      :
                      <span className="sidebar-username" style={{ fontSize: context?.socialName?.length >= 12 ? "0.8rem" : "1rem" }}>{this.handleSocialName(context.socialName)}</span>
                    }
                  </>
                )
              }}
            </UserContext.Consumer>
            <span className="sidebar-userlevel">{userData?.hasSupport ? "Suporte" : userLevel}</span>
          </div>
        </div>
      </div>
    )

    const affiliateProfile = (
      <div className='logo-wrapper' onClick={() => this.handleProfileButton()}>
        <div className='logo-link flex-center'>
          <UserContext.Consumer>
            {context => (
              <ProfileAvatar level={userData.level} avatar={context.avatar} />
            )}
          </UserContext.Consumer>
        </div>
        <div className='username-container'>
          {(!['master', 'submaster'].includes(userData.level)) && (
            <div className="user-data-button">
              <h3 className="user-data-profile">Ver perfil</h3>
              <ArrowForwardIosIcon className="user-arrow" />
            </div>
          )}
          <div className='sidebar-container-user'>
            <span className="sidebar-username" style={{ fontSize: userName.length >= 12 ? "0.8rem" : "1rem" }}>{this.handleSocialName(userName)}</span>
            <span className="sidebar-userlevel">{userLevel}</span>
          </div>
        </div>
      </div>
    )

    const sellerProfile = (
      <div className="user-data-wrapper">
        <div className='logo-wrapper' onClick={() => this.handleProfileButton()}>
          <div className='logo-link flex-center'>
            <UserContext.Consumer>
              {context => (
                <ProfileAvatar level={userData.level} avatar={context.avatar} />
              )}
            </UserContext.Consumer>
          </div>
          <div className='username-container'>
            {!['master', 'submaster'].includes(userData.level) && !isSupport && (
              <div className="user-data-button">
                <h3 className="user-data-profile">Ver perfil</h3>
                <ArrowForwardIosIcon className="user-arrow" />
              </div>
            )}
            <div className='sidebar-container-user'>
              <span className="sidebar-username">{userName}</span>
              <span className="sidebar-userlevel">{userLevel}</span>
            </div>
          </div>
        </div>
        {(userData.socialName && userData.agencyName) &&
          <div className='user-info'>
            <span className="sidebar-userlevel pd-top-1">
              <strong>{translateLevel('corporation', userData?.corpId)}: </strong>{this.handleSocialName(userData.socialName) || ""}
            </span>
            <span className="sidebar-userlevel pd-top-1"><strong>{translateLevel('agency', userData?.corpId)}: </strong>{this.handleSocialName(userData.agencyName) || ""}</span>
            {Boolean(userData.code) && <span className="sidebar-userlevel pd-top-1"><strong>Código: </strong>{userData.code}</span>}
            {Boolean(userData.reCode) && <span className="sidebar-userlevel pd-top-1"><strong>RE: </strong>{userData.reCode}</span>}
            {(Boolean(userData.supervisorName) && userData?.corpId === ClubeAmigoId)&& <span className="sidebar-userlevel pd-top-1"><strong>{translateLevel('supervisor', userData?.corpId)}: </strong>{this.handleSocialName(userData.supervisorName)}</span>}
          </div>}
      </div>
    )

    return (
      <>
        <ConfirmationAlert
          show={showSwalMessage}
          title="Sair?"
          confirmBtnCssClass="btn-danger"
          text="Tem certeza de que deseja sair?"
          onCancel={() => {
            this.setState({ showSwalMessage: false });
            var node = document.createElement("div");
            node.id = "bodyClick";
            node.onclick = function () {
              this.parentElement.removeChild(this);
              document.documentElement.classList.toggle("nav-open");
            };
            document.body.appendChild(node);
          }}
          onConfirm={this.clearSession}
        />
        <div id="sidebar" className={`sidebar ${userData?.supportAccess && 'support-sidebar'}`} data-color={this.props.color}>
          <div className="logo">
            <div className="corp-sidebar-logo">
              <a href="/admin/dashboard">
                <img alt="" src={config.LOGO_ALT} />
              </a>
              <div className='corp-sidebar-notification'>
                {!['superintendent', 'private', 'exclusive', 'executive'].includes(userData.level) &&
                  <Badge badgeContent={unreads} color="primary" className="badge-message-sidebar">
                    <MahoeIcon
                      name="message-chat"
                      fill="#FFF"
                      size={20}
                      onClick={() => { this.props.history.push('/admin/communication') }}
                    />
                  </Badge>
                }
                {
                  !['master'].includes(userData.level) ?
                    <NotificationList />
                    :
                    <></>
                }
              </div>
            </div>

            {isCorpOrMaster ? corpOrMasterProfile : (userData.affiliate ? affiliateProfile : sellerProfile)}
          </div>
          <div
            className={process.env.REACT_APP_ENV == 'custom' ? 'sidebar-wrapper background-pink' : (isAdm && userData.corpLogo
              ? "sidebar-wrapper sidebar-fix-height"
              : "sidebar-wrapper")
            }
            style={{ height: this.state.maxHeight, overflow: 'auto' }}
          >
            <ul className="nav">
              {this.props.routes.map((prop, index) => {
                if (!prop.redirect) {
                  if (prop.insideLinks) return <NavbarMenu config={prop} index={index} actualRoute={this.props.location.pathname} />
                  return <NavbarLink config={prop} actualRoute={this.props.location.pathname} />
                }
                return null;
              })}
              {
                userData?.supportAccess
                  ? <SupportGoBack />
                  : <li key="exit">
                    <NavLink
                      key={999}
                      className="nav-link"
                      activeClassName="active"
                      to="#"
                      onClick={(e) => {
                        e.preventDefault()
                        this.setState({ showSwalMessage: true });
                        this.removeBodyClick();
                      }}
                    >
                      <MahoeIcon
                        name="exit"
                        size="20px"
                        fill="rgba(255,255,255,0.8)"
                      />

                      <p>Sair</p>
                    </NavLink>
                  </li>
              }
            </ul>
          </div>
        </div>
      </>
    );
  }
}

export default Sidebar;