import React from 'react'

import swal from 'components/Alert/alert'
import { Link } from 'react-router-dom'
import formater from 'utils/formater'
import { typeManager, translateLevel } from 'utils/translate'
import { firstAndLastWord } from 'utils/formatData'
import Toogle from 'react-toggle'

const options = ( totalDocs, page, onSortChange, changePage ) =>  {

  return {
    count: totalDocs,
    selectableRows: false,
    rowsPerPage: 25,      
    filterType: "dropdown",
    responsive: "scrollMaxHeight",  
    serverSide: true,
    pagination: true,
    print: false,
    filter: false,
    search: false,
    download: false,
    rowsPerPageOptions: [],
    textLabels: {
      body: {
        noMatch: "Desculpe, nenhum dado corresponde a pesquisa"
      },
      pagination: {
        next: "Próxima Página",
        previous: "Pagina Anterior",
        rowsPerPage: "Linhas por página:",
        displayRows: "de"
      },
      toolbar: {
        search: "Procurar",
        downloadCsv: "Download CSV",
        print: "Imprimir",
        viewColumns: "Mostrar Colunas",
        filterTable: "Filtrar Tabela"
      },
      filter: {
        title: "FILTROS",
        reset: "limpar",
        all: "Todos"
      },
      viewColumns: {
        title: "Mostrar Colunas"
      }
    },    
    onColumnSortChange: (value, type) => {
      const hasPlus = 'descending' === type ? '-' : ''
      onSortChange(hasPlus + value)
    },
    onTableChange: (action, tableState) => {                 
      switch (action) {
        case 'changePage':
          changePage(tableState.page)
          break;
        case 'propsUpdate':
          if (page !== tableState.page + 1) tableState.page = 0;            
          break;
        default:
          break;
      }
    }  
  }
}

const TableConfig = ( isCorp, updateManagerBlock, blockUser ) => {   

  return [
    {
      name: "_id",
      label: "ID",
      options: {
        display: 'excluded',
        hint: 'Identificador do usuário.'
      }
    },
    {
      name: "name",
      label: "Nome",
      options: {
        sort: true,
        hint: 'Nome do usuário.',        
        customBodyRender: (value, rowIndex) => {
          const ID = rowIndex.rowData[0]        
          
          return (<Link className="link-style" to={`/admin/create/managers?id=${ID}`}>{value}</Link>)        
        }
      }
    },
    {
      name: "cpf",
      label: "CPF",
      options: {
        sort: true,
        hint: 'CPF do usuário.',
        customBodyRender: value => {
          if(!value) return 'N/A'
          return formater.toCPF(value)
        }        
      }
    },
    {
      name: "level",
      label: "Nível",
      options: {     
        sort: true,
        hint: 'Nível do usuário no sistema.',
        customBodyRender: value => {
          return typeManager(value)
        }
      }
    },    
    {
      name: "email",
      label: "Email",
      options: {
        sort: true,
        hint: 'Email do usuário.'        
      }
    },
    {
      name: "cellphone",
      label: "Celular",
      options: {     
        sort: true,
        hint: 'Celular do usuário.',        
        customBodyRender: (value) => {
          if(!value) return 'n/a'
  
          return formater.formatPhone(value)
        }
      }
    },
    {
      name: "agencies",
      label: "Parceiros",
      options: {
        sort: true,
        hint: 'Quantidade de parceiros gerenciados.',        
      }
    },      
    {
      name: "inviterUser",
      label: "Convidado por",
      options: {
        display: true,
        hint: 'Usuário que convidou o gerente.',        
        customBodyRender: (value) => {
          return value.name ? `${value.name} (${translateLevel(value.level)})` : 'Master /\nSubMaster'
        }
      }
    },
    {
      name: "editAgencies",
      label: "Atribuir Lojas",
      options: {
        display: isCorp ? 'excluded' : true,
        hint: 'Adicionar lojas ao gerente.',        
        customBodyRender: (value, rowIndex) => {
          const ID = rowIndex.rowData[0]

          return (<Link className="link-style" to={`/admin/support?id=${ID}`}>Atribuir</Link>)        
        }
      }
    }, 
    {
      name: "ddds",
      label: "DDDs",
      options: {     
        sort: false, 
        hint: 'DDDs que usuário está destinado.',
        customBodyRender: (value) => {                   
          if(value.length > 1) return <button className="link-style" onClick={() => swal.text("DDDs", formater.separeteValuesByComma(value))}>Ver Mais</button>
          return value
        }
      }
    },
    {
      name: "ufs",
      label: "UFs",
      options: {
        sort: false,
        hint: 'Estados que usuário está destinado.',        
        customBodyRender: (value) => {        
          if(value.length > 1) return <button className="link-style" onClick={() => swal.text("Estados", formater.separeteValuesByComma(value))}>Ver Mais</button>
          return value
        }
      }
    },
    {    
      name: "isEditable",
      label: "Editável",
      options: {
        display: false,
        viewColumns: false
      }    
    },
    {    
      name: "blocked",
      label: "Bloqueado",
      options: {
        sort: true, 
        hint: 'O usuário está bloqueado?',        
        customBodyRender: (value, rowIndex) => {
          const ID = rowIndex.rowData[0]
          const LEVEL = rowIndex.rowData[2]          

          return (
            <Toogle 
              checked={value}
              className="custom-toggle-class"
              onChange={() => {                
                swal.confirmNegate('warning', 'Atenção', 'Deseja bloquear o usuário?', () => {                  
                  if(isCorp) updateManagerBlock(!value, ID, LEVEL)
                  else blockUser(ID)
                })

              }}
            />  
          )
          
        }
      }    
    }
  ]  
}

export { TableConfig, options }