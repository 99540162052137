import React from 'react'
import { Backup } from '@material-ui/icons';

const UploadButton = ({ children, onClick, ...rest }) => {
  return (
    <button className="link-style icon-button-style" onClick={onClick} {...rest} >
      <Backup /> {children}
    </button>
  )
}

export default UploadButton