import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import Card from "components/Card/Card";

import InputMask from "react-input-mask";
import { FilePicker } from "react-file-picker";
import dataTour from 'utils/dataTour';

import mainApi from "services/mainApi";
import PaymentForm from "../components/PaymentForm";
import {
  LoadingAlert,
  Dialog,
  InputAlert
} from "../components/Alert/CustomAlert";
import LoadingCover from "../components/LoadingCover";
import { TextField, FormControl, FormControlLabel } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import UserContext from 'context/userContext'
import getUserData from "../utils/checkUser";

import MuiSelect from 'components/Selects/MuiSelect'
import MuiInput from 'components/InputMuiField'
import Button from 'components/Buttons/Button'
import swal from 'components/Alert/alert'

import { introSystem } from 'utils/introToSystem'
import { PIXOPTIONS } from 'utils/CommonData'

class Perfil extends Component {
  constructor(props) {
    super(props);
    this.state = {
      states: {},
      loadingProfile: false,
      profilePhoto: null,
      profilePhotoFile: null,
      profilePhotoFileName: null,
      originalUserInfo: {},
      name: "",
      cpf: "",
      email: "",
      telephone: "",
      cellphone: "",
      blockEmailMarketing: false,
      unsubscribeEmailToken: '',
      userChanged: false,
      userAddressChanged: false,
      loadingAddress: false,
      addressId: "",
      street: "",
      zipcode: "",
      neighborhood: "",
      city: "",
      number: "",
      additional: "",
      state: "",
      swalShowLoading: false,
      swalMessageShow: false,
      swalInputShow: false,
      swalMessage: [],
      addressInvalidFields: {},
      profileInvalidFields: {},
      pix: {
        type: "",
        value: ""        
      },
      pixError: ""
    };
    this.states = [
      "AC",
      "AL",
      "AP",
      "AM",
      "BA",
      "CE",
      "DF",
      "ES",
      "GO",
      "MA",
      "MT",
      "MS",
      "MG",
      "PA",
      "PB",
      "PR",
      "PE",
      "PI",
      "RJ",
      "RN",
      "RS",
      "RO",
      "RR",
      "SC",
      "SP",
      "SE",
      "TO"
    ];    
  }

  static contextType = UserContext

  getUserAddress = async () => {
    this.setState({ loadingAddress: true });
    try {
      const response = await mainApi.get("/address");
      this.setState({
        ...response.data.addresses[0],
        addressId: response.data.addresses[0]._id
      });      

    } catch (err) {
      console.log(err);
    }
    this.setState({ loadingAddress: false });
  };

  maskPix = (type) => {

    if(!type) return ''

    switch(type) {     
      case 'cpf':
        return "999.999.999-99"
      case 'cnpj':
        return "99.999.999/9999-99"
      case 'cellphone':
        return "(99) 99999-9999"    
    }
  }

  translatePix = type => {
    if(!type) return ''

    switch(type) {     
      case 'cpf':
        return "CPF"
      case 'cnpj':
        return "CNPJ"
      case 'cellphone':
        return "Celular"
      case 'email':
        return "Email"
      case 'randomkey':
        return "Chave aleatória"
      default:
        return type
    }
  }

  updatePix = async () => {

    const { pix } = this.state
    const { value, type } = pix

    let rawValue = value    

    if(!['email', 'randomkey'].includes(type)) rawValue = value.replace(/\D/g, "");

    if(!rawValue.length) {
      this.setState({ pixError: "Esta campo é de preenchimento obrigatório." })
      return
    }

    try {
      const response = await mainApi.patch('/pix', { value: rawValue, type })      
      swal.basic('success', response.data.message)
    } catch(err) {      
            
      if(!err.response) return
      if(err.response.data.invalid) return swal.basic('warning', err.response.data.invalid[0].message)
      swal.basic('warning', err.response.data.message)
    }
  }

  getUserPersonalInfo = async () => {   
    const { setAvatar, setUserName } = this.context

    this.setState({ loadingProfile: true });

    try {
      const response = await mainApi.get("/profile");            

      setAvatar(response.data.user.selfie)

      this.setState({
        ...response.data.user,

        originalUserInfo: { ...response.data.user },
        blockEmailMarketing: response.data.user.blockEmailMarketing
      });      

      setUserName(response.data.user.name)
    } catch (err) {
      console.log(err)
    }

    this.setState({ loadingProfile: false });
  };

  getUserProfilePhoto = async (userId) => {
    try {
      const response = await mainApi.get("/selfie");
      let profilePhoto = null;
      if (response.data.selfie) {
        let base64Flag = "data:image/png;base64,";
        let buffer = new Buffer(response.data, "base64");
        profilePhoto = base64Flag + buffer.toString("ascii");
      }
      this.setState({
        profilePhoto
      });
    } catch (err) {
      console.log(err);
    }
  };
  handleZipcode = async event => {
    const searchCep = event.target.value;
    this.setState({ zipcode: searchCep });
    const rawCep = searchCep.replace(/\D/g, "");
    if (rawCep.length === 8) {
      try {
        const response = await mainApi.get(`/resources/cep/${rawCep}`);
        const {
          street,
          neighborhood,
          city,
          state,
          additional
        } = response.data.address;
        this.setState({ street, neighborhood, city, state, additional });
        this.numberRef.current.focus();
      } catch (error) {}
    }
  };

  addEmailUnsubscribeList = async () => {    
    const { unsubscribeEmailToken } = this.state  
    
    this.setState({ swalShowLoading: true })

    if(unsubscribeEmailToken !== '') {
      const data = { token: unsubscribeEmailToken }

      try {
        await mainApi.post('/mail/unsubscribe', data) 
      } catch (err) {      
        console.log(err)
      }
    }    

    this.setState({ swalShowLoading: false })
  }

  deleteEmailUnsubscribeList = async () => {    
    const { unsubscribeEmailToken } = this.state    
    
    this.setState({ swalShowLoading: true })

    if(unsubscribeEmailToken !== '') {    

      try {
        await mainApi.delete(`/mail/unsubscribe/delete/${unsubscribeEmailToken}`) 
      } catch(err) {
        console.log(err)        
      }    
    }

    this.setState({ swalShowLoading: false })
  }

  updateUserInfo = async () => {
    const {
      name,
      telephone,
      cellphone,
      profilePhotoFile,
      originalUserInfo       
    } = this.state;    

    this.setState({ swalShowLoading: true });
    const rawTelephone = telephone.replace(/\D/g, "");
    const rawCellphone = cellphone.replace(/\D/g, "");

    const formData = new FormData();

    if (profilePhotoFile) formData.append("selfie", profilePhotoFile);
    if (name && name !== originalUserInfo.name) formData.append("name", name);
    if (rawTelephone && rawTelephone !== originalUserInfo.telephone)
      formData.append("telephone", rawTelephone);
    if (rawCellphone && rawCellphone !== originalUserInfo.cellphone)
      formData.append("cellphone", rawCellphone);
    try {
      const response = await mainApi.patch("/profile", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      this.setState({
        swalShowLoading: false,
        swalMessage: [
          "success",
          response.data.message,
          profilePhotoFile
            ? "Foto de perfil alterada com sucesso."
            : ""
        ],
        swalMessageShow: true
      }, () => this.getUserPersonalInfo());
    } catch (err) {
      if (!err.response) {
        if (err.message === "Network Error") {
          this.showMessage(
            "error",
            "Problemas de Conexão",
            "Não foi possível se comunicar com o servidor, tente novamente mais tarde."
          );
          this.setState({ loading: false });
          return;
        }
        if (err.code === "ECONNABORTED")
          this.showMessage(
            "error",
            "Problemas de Conexão",
            "Tempo de espera atingido."
          );
        this.setState({ loading: false });
        return;
      }
      // console.log(err.response.status)
      if (err.response.status === 422) {
        const invalid = {}
        err.response.data.fields.forEach(field => {
          invalid[field.name] = field.message 
        })
        this.setState({ profileInvalidFields: invalid })
      }

      this.setState({
        swalShowLoading: false,
        swalMessage: ["error", err.response.data.message, ""],
        swalMessageShow: true
      });
    }

    this.setState({ swalShowLoading: false });
  };

  showMessage = (type, title, message, boldMessage) => {
    this.setState({
      swalMessageShow: true,
      swalMessage: [type, title, message, boldMessage],
      swalShowLoading: false
    });
  };

  requestEmailChange = () => {
    this.setState({
      swalInputShow: true,
      swalMessage: ["info", "Insira o novo email abaixo"],
      swalShowLoading: false
    });
  };

  handleEmail = async email => {
    try {
      mainApi.post(
        "/email",
        { email }
      );
      this.showMessage(
        "success",
        "Sucesso!",
        "Email enviado. Você deve confirmar sua conta para alterar seu email."
      );
    } catch (err) {
      this.showMessage(
        "warning",
        "Algo deu errado",
        "Não foi possível enviar o email."
      );
    }
  };

  updateUserAddress = async () => {
    const {
      addressId,
      street,
      zipcode,
      neighborhood,
      city,
      number,
      state,
      additional
    } = this.state;
    this.setState({ swalShowLoading: true });

    const data = {};

    if (street) data.street = street;
    if (zipcode) data.zipcode = zipcode.toString().replace(/\D/g, "");
    if (neighborhood) data.neighborhood = neighborhood;
    if (city) data.city = city;
    if (number) data.number = number;
    if (state) data.state = state;
    if (additional) data.additional = additional;
    try {
      const response = await mainApi.put(`/address/${addressId}`, data);
      this.setState({
        swalShowLoading: false,
        swalMessage: [
          "success",
          "Endereço Atualizado!",
          response.data.message,
          ""
        ],
        swalMessageShow: true
      });
    } catch (err) {
      if (!err.response) {
        if (err.message === "Network Error") {
          this.showMessage(
            "error",
            "Problemas de Conexão",
            "Não foi possível se comunicar com o servidor, tente novamente mais tarde."
          );
          this.setState({ loading: false });
          return;
        }
        if (err.code === "ECONNABORTED")
          this.showMessage(
            "error",
            "Problemas de Conexão",
            "Tempo de espera atingido."
          );
        this.setState({ loading: false });
        return;
      }

      if (err.response.status === 422) {
        const invalid = {}
        err.response.data.fields.forEach(field => {
          invalid[field.name] = field.message 
        })
        this.setState({ addressInvalidFields: invalid })
      }
      if (err.response.status === 400) {
        const invalid = {}
        err.response.data.fields.forEach(field => {
          invalid[field] = 'Campo obrigatório.' 
        })
        this.setState({ addressInvalidFields: invalid })
      }
      
      this.setState({
        swalShowLoading: false,
        swalMessage: [
          "warning",
          "Temos um problema",
          err.response.data.message,
          ""
        ],
        swalMessageShow: true
      });
    }

    this.setState({ swalShowLoading: false });
  };

  componentDidMount = async () => {     
    const { routeKey } = this.props

    const userData = await getUserData(this.props.history);    

    window.digitalData = {
      "site": {
          "nome": "Banco Pan"
      },
      "page": {
          "secao": "IndiqueoPan",
          "estadoLogin": "LG",
          "nome": "BP:IndiqueOPan:Perfil",
      },
      "user": {
          "crmid": userData.id,
          "tipo": userData.brType
      }
    }
    
    var evt = new CustomEvent('event-view-start');
    document.body.dispatchEvent(evt);

    await this.getUserAddress();
    await this.getUserPersonalInfo();

    introSystem(routeKey)
  };

  render() {
    const {
      loadingProfile,
      profilePhotoFileName,
      selfie,
      name,
      telephone,
      cellphone,
      email,
      cpf,
      loadingAddress,
      street,
      zipcode,
      neighborhood,
      city,
      number,
      additional,
      state,
      userChanged,
      userAddressChanged,
      swalShowLoading,
      swalMessageShow,
      swalInputShow,
      swalMessage,
      blockEmailMarketing,
      addressInvalidFields,
      profileInvalidFields,
      pix,
      pixError
    } = this.state;            

    let cantUpdate = true;
    let cantUpdateAddress = true;
    cantUpdate = userChanged ? false : true;
    cantUpdateAddress = userAddressChanged ? false : true;
    const swalInputConfig = {
      title: "Email",
      placeholder: "Ex. joaosilva@mail.com",
      // eslint-disable-next-line
      pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    };


    return (
      <div className="content">
        
        <Dialog
          type={swalMessage[0]}
          show={swalMessageShow}
          title={swalMessage[1] || ""}
          onConfirm={() => {
            this.setState({ swalMessageShow: false });
          }}
          text={swalMessage[2]}
          boldText={swalMessage[3]}
        />
        <InputAlert
          type={swalMessage[0]}
          show={swalInputShow}
          title={"Troca de email"}
          onCancel={() => {
            this.setState({ swalInputShow: false });
          }}
          onConfirm={email => {
            this.handleEmail(email);
            this.setState({ swalInputShow: false });
          }}
          needConfirmation
          text={swalMessage[2]}
          inputConfig={swalInputConfig}
        />
        <LoadingAlert show={swalShowLoading} />
        <div className="container-fluid">
          <Row>
            <Col md={5} lg={6}>
              <Card
                title="Dados Pessoais"
                category="Informações pessoais sobre o seu perfil."
                content={
                  <>
                    <Row
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column"
                      }}
                    >
                      {loadingProfile ? <LoadingCover /> : null}

                      <Col md={9} xs={12} style={{ textAlign: 'center' }}>
                        <img
                          data-intro={dataTour.userProfile.profilePhoto}
                          className="user-avatar-container-profile"
                          alt=''
                          src={
                            selfie
                              ? selfie
                              : "https://freesvg.org/img/abstract-user-flat-3.png"
                          }
                        ></img>
                      </Col>
                      <br />
                      <FilePicker
                        extensions={["png", "jpeg", "jpg"]}
                        onChange={FileObject =>
                          this.setState({
                            profilePhotoFile: FileObject,
                            profilePhotoFileName: FileObject.name,
                            userChanged: true
                          })
                        }
                        onError={errMsg => {
                          let swalMessage = [];
                          if (errMsg === "File size must be less than 2 MB.") {
                            swalMessage = [
                              "error",
                              "Arquivo muito grande!",
                              "O arquivo pode ter até 2Mb"
                            ];
                          } else {
                            swalMessage = [
                              "error",
                              "Formato de arquivo invalido!",
                              "Os formatos válidos são: png, jpeg, jpg."
                            ];
                          }
                          this.setState({
                            swalMessage,
                            swalMessageShow: true
                          });
                        }}
                      >
                        <button className="link-style">
                          {profilePhotoFileName
                            ? profilePhotoFileName
                            : "Escolher Foto de Perfil"}
                        </button>
                      </FilePicker>
                      <br />
                      <Col md={12} xs={12} style={{ textAlign: "center" }}>
                        <Row>
                          <div className="form-group col-xs-12 col-lg-6">
                            <TextField
                              data-intro={dataTour.userProfile.name}
                              label="Nome Completo"
                              InputProps={{
                                classes: {
                                  input: "mui-input-control",
                                  root: "mui-input-control"
                                }
                              }}
                              InputLabelProps={{
                                classes: { root: "mui-input-control mui-label" }
                              }}
                              variant="outlined"
                              value={name}
                              fullWidth
                              error={Boolean(profileInvalidFields.name)}
                              helperText={profileInvalidFields.name}
                              onChange={event => {
                                this.setState({
                                  name: event.target.value,
                                  userChanged: true
                                });
                              }}
                              placeholder="Nome Completo"
                            />
                          </div>
                        
                          <div className="form-group col-xs-12 col-lg-6">
                            <TextField
                              data-intro={dataTour.userProfile.email}
                              label="Email"
                              InputProps={{
                                classes: {
                                  input: "mui-input-control",
                                  root: "mui-input-control"
                                }
                              }}
                              InputLabelProps={{
                                classes: { root: "mui-input-control mui-label" }
                              }}
                              variant="outlined"
                              value={email}
                              error={Boolean(profileInvalidFields.email)}
                              helperText={profileInvalidFields.email}
                              fullWidth
                              readOnly
                              placeholder="Email"
                            />
                          </div>
                        
                          <div className="form-group col-xs-12 col-lg-6">
                            <InputMask
                              mask="999.999.999-99"
                              value={cpf}
                              color="secondary"
                              readOnly
                              onChange={event => {
                                this.setState({
                                  cpf: event.target.value,
                                  userChanged: true
                                });
                              }}
                            >                              
                              {() => (
                                <TextField
                                  label="CPF"
                                  data-intro={dataTour.userProfile.cpf}
                                  InputProps={{
                                    classes: {
                                      root: "mui-input-control",
                                      input: "mui-input-control",
                                      notchedOutline: "mui-input-focus"
                                    }
                                  }}
                                  fullWidth
                                  error={Boolean(profileInvalidFields.cpf)}
                                  helperText={profileInvalidFields.cpf}
                                  InputLabelProps={{
                                    classes: {
                                      root: "mui-input-control mui-label"
                                    }
                                  }}
                                  variant="outlined"
                                />
                              )}
                            </InputMask>
                          </div>
                        
                          <div className="form-group col-xs-12 col-lg-6">
                            <InputMask
                              mask="(99) 9999-9999"
                              value={telephone}
                              onChange={event => {
                                this.setState({
                                  telephone: event.target.value,
                                  userChanged: true
                                });
                              }}
                            >
                              {() => (
                                <TextField
                                  data-intro={dataTour.userProfile.telephone}
                                  label="Telefone Fixo"
                                  error={Boolean(profileInvalidFields.telephone)}
                                  helperText={profileInvalidFields.telephone}
                                  InputProps={{
                                    classes: {
                                      input: "mui-input-control",
                                      root: "mui-input-control"
                                    }
                                  }}
                                  InputLabelProps={{
                                    classes: {
                                      root: "mui-input-control mui-label"
                                    }
                                  }}
                                  fullWidth
                                  variant="outlined"
                                />
                              )}
                            </InputMask>
                          </div>
                        </Row>
                        <Row>
                          <div className="form-group col-xs-12 col-lg-6">
                            <InputMask
                              mask="(99) 99999-9999"
                              value={cellphone}
                              onChange={event => {
                                this.setState({
                                  cellphone: event.target.value,
                                  userChanged: true
                                });
                              }}
                            >
                              {() => (
                                <TextField                                  
                                  label="Celular"
                                  data-intro={dataTour.userProfile.cellphone}
                                  InputProps={{
                                    classes: {
                                      input: "mui-input-control",
                                      root: "mui-input-control"
                                    }
                                  }}
                                  InputLabelProps={{
                                    classes: {
                                      root: "mui-input-control mui-label"
                                    }
                                  }}
                                  fullWidth
                                  error={Boolean(profileInvalidFields.cellphone)}
                                  helperText={profileInvalidFields.cellphone}
                                  variant="outlined"
                                />
                              )}
                            </InputMask>
                          </div>
                        </Row>

                        <Row>     
                          <div className="form-group col-xs-12">

                            <FormControlLabel
                              data-intro={dataTour.userProfile.emailMarketing}
                              control={
                                <Checkbox                                  
                                  color="default" 
                                  name="permission"
                                  checked={blockEmailMarketing}
                                  onChange={(event) => {                                    
                                    if(blockEmailMarketing === false) this.addEmailUnsubscribeList()
                                    if(blockEmailMarketing === true) this.deleteEmailUnsubscribeList()
                                    
                                    this.setState({ 
                                      blockEmailMarketing: event.target.checked,
                                      userChanged: true
                                    })}                                    
                                  } />
                              }
                              label="Não desejo receber e-mails de promoções e novos produtos."
                            />   
                          </div>                          
                        </Row>
                                                    
                        <button
                          data-intro={dataTour.userProfile.save}
                          className="btn btn-fill btn-block btn-round btn-mlm"
                          onClick={() => {
                            this.updateUserInfo()                                                          
                          }}
                          disabled={cantUpdate}
                        >
                          <span className="save-text">Salvar</span>
                        </button>                        

                        <div style={{ marginTop: "20px" }}>
                          <button
                            data-intro={dataTour.userProfile.changeEmail}
                            className="link-style"
                            onClick={this.requestEmailChange}
                          >
                            Solicitar troca de Email
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </>
                }
              />
            </Col>
            <Col md={7} lg={6}>
              <div data-intro={dataTour.userProfile.address}>
                <Card                
                  title="Endereço"
                  category="Informações pessoais sobre seu endereço."
                  content={
                    <>
                      {loadingAddress ? <LoadingCover /> : null}

                      <Row>
                        <Col style={{ marginLeft: 15, marginRight: 15 }}>
                          <Row>
                            <div className="form-group col-lg-4 col-md-8 col-xs-12">
                              <InputMask
                                mask="99999-999"
                                value={zipcode}
                                onChange={event => {
                                  this.handleZipcode(event);
                                  this.setState({
                                    userAddressChanged: true
                                  });
                                }}
                              >
                                {() => (
                                  <TextField
                                    label="CEP"
                                    InputProps={{
                                      classes: {
                                        input: "mui-input-control",
                                        root: "mui-input-control"
                                      }
                                    }}
                                    InputLabelProps={{
                                      classes: {
                                        root: "mui-input-control mui-label"
                                      }
                                    }}
                                    error={Boolean(addressInvalidFields.zipcode)}
                                    helperText={addressInvalidFields.zipcode}
                                    fullWidth
                                    variant="outlined"
                                  />
                                )}
                              </InputMask>
                            </div>
                          </Row>
                          <Row>
                            <Col md={8} lg={9}>
                              <div className="form-group">
                                <TextField
                                  label="Logradouro"
                                  InputProps={{
                                    classes: {
                                      input: "mui-input-control",
                                      root: "mui-input-control"
                                    }
                                  }}
                                  InputLabelProps={{
                                    classes: {
                                      root: "mui-input-control mui-label"
                                    }
                                  }}
                                  variant="outlined"
                                  value={street}
                                  fullWidth
                                  error={Boolean(addressInvalidFields.street)}
                                  helperText={addressInvalidFields.street}
                                  onChange={event => {
                                    this.setState({
                                      street: event.target.value,
                                      userAddressChanged: true
                                    });
                                  }}
                                  placeholder="Logradouro"
                                />
                              </div>
                            </Col>
                            <Col md={4} lg={3}>
                              <div className="form-group">
                                <TextField
                                  label="Número"
                                  InputProps={{
                                    classes: {
                                      input: "mui-input-control",
                                      root: "mui-input-control"
                                    },
                                    type: "number",
                                    inputMode: "tel",
                                    pattern: "[0-9]*"
                                  }}
                                  InputLabelProps={{
                                    classes: {
                                      root: "mui-input-control mui-label"
                                    }
                                  }}
                                  variant="outlined"
                                  value={number}
                                  error={Boolean(addressInvalidFields.number)}
                                  helperText={addressInvalidFields.number}
                                  fullWidth
                                  onChange={event => {
                                    this.setState({
                                      number: event.target.value,
                                      userAddressChanged: true
                                    });
                                  }}
                                  placeholder="Número"
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={5}>
                              <div className="form-group">
                                <TextField
                                  label="Complemento"
                                  InputProps={{
                                    classes: {
                                      input: "mui-input-control",
                                      root: "mui-input-control"
                                    }
                                  }}
                                  InputLabelProps={{
                                    classes: {
                                      root: "mui-input-control mui-label"
                                    }
                                  }}
                                  variant="outlined"
                                  value={additional}
                                  error={Boolean(addressInvalidFields.additional)}
                                  helperText={addressInvalidFields.additional}
                                  fullWidth
                                  onChange={event => {
                                    this.setState({
                                      additional: event.target.value,
                                      userAddressChanged: true
                                    });
                                  }}
                                  placeholder="Complemento"
                                />
                              </div>
                            </Col>
                            <Col md={7}>
                              <div className="form-group">
                                <TextField
                                  label="Bairro"
                                  InputProps={{
                                    classes: {
                                      input: "mui-input-control",
                                      root: "mui-input-control"
                                    }
                                  }}
                                  InputLabelProps={{
                                    classes: {
                                      root: "mui-input-control mui-label"
                                    }
                                  }}
                                  variant="outlined"
                                  value={neighborhood}
                                  error={Boolean(addressInvalidFields.neighborhood)}
                                  helperText={addressInvalidFields.neighborhood}
                                  fullWidth
                                  onChange={event => {
                                    this.setState({
                                      neighborhood: event.target.value,
                                      userAddressChanged: true
                                    });
                                  }}
                                  placeholder="Bairro"
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div className="form-group">
                                <TextField
                                  label="Cidade"
                                  InputProps={{
                                    classes: {
                                      input: "mui-input-control",
                                      root: "mui-input-control"
                                    }
                                  }}
                                  InputLabelProps={{
                                    classes: {
                                      root: "mui-input-control mui-label"
                                    }
                                  }}
                                  variant="outlined"
                                  value={city}
                                  fullWidth
                                  error={Boolean(addressInvalidFields.city)}
                                  helperText={addressInvalidFields.city}
                                  onChange={event => {
                                    this.setState({
                                      city: event.target.value,
                                      userAddressChanged: true
                                    });
                                  }}
                                  placeholder="Cidade"
                                />
                              </div>
                            </Col>

                            <Col sm={12} md={3} lg={3}>
                              <FormControl variant="outlined" fullWidth>
                                <select
                                  className="form-control select-focus-fix"
                                  value={state}
                                  onChange={event => {
                                    this.setState({
                                      state: event.target.value,
                                      userAddressChanged: true
                                    });
                                  }}
                                >
                                  <option value="UF">UF</option>
                                  {this.states.map(state => (
                                    <option value={state} key={state}>
                                      {state}
                                    </option>
                                  ))}
                                </select>
                              </FormControl>
                            </Col>                          
                          </Row>
                          <Row>
                            <div
                              className="form-group "
                              style={{
                                textAlign: "center",
                                marginLeft: 20,
                                marginRight: 20
                              }}
                            >                            
                              <button
                                className="btn btn-fill btn-block btn-round btn-mlm"
                                style={{ marginTop: 15 }}
                                onClick={() => this.updateUserAddress()}
                                disabled={cantUpdateAddress}
                              >
                                <span className="save-text">Salvar</span>
                              </button>                              
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </>
                  }
                />
              </div>          
              </Col>    
          </Row>

          <Row>
            <Col md={6} sm={12}>
              <Card
                style={{minHeight: 325}}
                title="Chave PIX"
                category="Cadastre uma chave PIX para receber seu dinheiro."
                content={
                  <>                  
                    {loadingProfile ? <LoadingCover /> : null}                                      

                    <>
                    {console.log({value: pix.value, label: this.translatePix(pix?.type)})}
                    </>

                    <Row>
                      <Col md={12} className="pb-1">                        
                        <MuiSelect 
                          placeholder="Escolha a categoria de chave"
                          value={ pix.type ? { value: pix.value, label: this.translatePix(pix?.type) } : ""}
                          options={PIXOPTIONS}
                          onChange={(event) => {                        
                            this.setState({ 
                              pix: { value: "", type: event.value },
                              pixError: ""
                            })
                          }}
                        />
                      </Col>

                      <Col md={12}>
                        {this.maskPix(pix?.type) ?
                          <InputMask
                            mask={ this.maskPix(pix?.type) }
                            disabled={pix?.type === "" ? true : false}
                            value={ pix?.value }                            
                            color="secondary"
                            onChange={(event) => {
                              this.setState({
                                pix: { ...pix, value: event.target.value }
                              })
                            }}
                          >
                            {() => (
                              <MuiInput
                                disabled={pix?.type === "" ? true : false}
                                label={this.translatePix(pix?.type)}
                                value={ pix?.value }
                                error={pixError}                                  
                              />                                
                            )}
                          </InputMask>                          
                        :                              
                          <MuiInput
                            disabled={pix?.type === "" ? true : false}
                            label={this.translatePix(pix?.type)}
                            value={ pix?.value }
                            error={pixError}
                            onChange={(event) => {
                              this.setState({
                                pix: { ...pix, value: event.target.value }
                              })
                            }}
                          />                        
                        }
                      </Col>

                      <Col md={12} className="pb-3 pt-1">
                        <Button 
                          onClick={() => {

                            swal.confirmNegate('warning', 'Atenção', `Tem certeza que deseja cadastrar a chave pix referente ao ${this.translatePix(pix?.type)}: ${pix.value}`, () => this.updatePix())
                          }}
                          disabled={pix?.type === "" ? true : false}
                        >Atualizar</Button>
                      </Col>
                    </Row>
                  </>
                }
              />
            </Col>

            <Col md={6} sm={12}>
              <Card
                style={{minHeight: 325}}
                title="Dados Bancarios"
                category="Informações pessoais sobre seus dados bancarios."
                content={<PaymentForm />}
              />
            </Col>
          </Row>

        </div>
      </div>
    );
  }
}

export default Perfil;