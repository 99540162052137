import React from 'react'

import { ApiCard } from './styled'

const Response = ({ image, route }) => {

  const copy = () => {
    var textField = document.createElement('textarea')

    textField.innerText = route
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
  }

  return (
    <ApiCard>
      <div className='route'>
        <div id={route}>{route}</div>
        <button onClick={() => copy()}>Copy</button>
      </div>
      <div className='response'>
        <img src={image} alt={route} />
      </div>
    </ApiCard>
  )
}

export default Response