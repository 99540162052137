import React, { Component, createRef } from "react";
import Card from "components/Card/Card";
import InputMuiField from "components/InputMuiField";

import { Multiselect } from "multiselect-react-dropdown";

import MuiSelect from "components/Selects/MuiSelect";
import { Grid, Col, Row } from "react-bootstrap";
import Button from "components/Buttons/Button";
import { UFddd } from "utils/CommonData";
import swal from "components/Alert/alert";
import InputMask from "react-input-mask";

import { LoadingAlert } from "components/Alert/CustomAlert";

import mainApi from "services/mainApi";
import LinkVoltar from "components/LinkVoltar";

import queryString from "query-string";
import getUserData from "utils/checkUser";

import { MANAGEROPTIONS, MASTEROPTIONS, CORPOPTIONS } from "./values";

class CreateManager extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      manager: "",
      link: "",
      cellphone: "",
      whatsappLink: "",
      loading: false,

      isEditing: false,
      firstEdit: false,

      totalDocs: 0,
      page: 1,

      UFsList: [],
      DDDsList: [],
      chosenUFs: [],
      chosenDDDs: [],
      chosenUF: {},
      chosenDDD: {},
      errors: {},
      isCorp: false,

      managerOptions: MANAGEROPTIONS,
    };

    this.ManagerRef = createRef();
    this.multiselectUFRef = React.createRef();
    this.multiselectDDDRef = React.createRef();
    
    this.multiSelectUFCSS = {
      chips: {
        background: "#b0bfb4",
        height:"20px",
        borderRadius:"2px",
        width:"45px",
        fontSize:"10px",
        padding:"none"
      },  
      searchBox: { // To change search box element look
         outline: "none",
         paddingLeft:"12px",
         paddingTop:"10px",   
         paddingBottom:"10px",
         width:"100%",

      }
    };

    this.multiSelectDDDCSS = {
      chips: {
        background: "#b0bfb4",
        height:"20px",
        borderRadius:"2px",
        fontSize:"10px",
      },  
      searchBox: { // To change search box element look
         outline: "none",
         paddingLeft:"12px",
         paddingTop:"10px",
         paddingBottom:"10px",
      }
    }
  }
  
  setUserData = async (  ) => {

    const { isEditing } = this.state;
    const userData = await getUserData(this.props.history);

    const isCorp = ["corporation", "subcorp"].includes(userData.level);

    if (isEditing) this.setState({ managerOptions: MANAGEROPTIONS });
    else if (isCorp) {
      await this.setManager("executive");
      this.setState({ managerOptions: CORPOPTIONS });
    } else this.setState({ managerOptions: MASTEROPTIONS });

    this.setState({ isCorp });
  };

  createManager = async () => {
    const { manager, name, email, cellphone,chosenUFs,chosenDDDs } = this.state;

    const rawPhone = cellphone.replace(/\D/g, "");

    const data = {
      level: manager,
      name,
      email,
      cellphone: rawPhone,
      ufs:chosenUFs,
      ddds:chosenDDDs
    };

    this.setState({ loading: true });
    try {
      const response = await mainApi.post("manager/invite", data);
      const link = response.data.data.link;

      await this.setLink(link);
      this.clearErrors();
      this.clearFields();

      swal.confirm("success", "Sucesso", response.data.message, () =>
        this.props.history.push("/admin/managers")
      );
    } catch (err) {
      if (!err.response)
        return swal.basic(
          "warning",
          "Atenção!",
          "Falha ao se comunicar com o servidor!"
        );

      if (err.response.data.fields)
        this.handleErrorsResponse(err.response.data.fields);

      swal.basic("warning", "Atenção!", err.response.data.message);
    }
    this.setState({ loading: false });
  };

  handleErrorsResponse = (response) => {
    const errors = {};

    if (!response) return;

    response.forEach((item) => {
      errors[item.name] = item.message;
    });

    this.setState({ errors });
  };

  clearErrors = () => {
    this.setState({ errors: {} });
  };

  clearFields = () => {
    this.setState({
      name: "",
      email: "",
      cellphone: "",
    });
    this.resetManager();
  };

  setName = (value) => {
    this.setState({ name: value });
  };

  setCellphone = (value) => {
    this.setState({ cellphone: value });
  };

  setLink = async (value) => {
    await this.setWhatsLink(value);
    this.setState({ link: value });
  };

  setWhatsLink = (link) => {
    const { cellphone, manager } = this.state;

    const translateManager = MANAGEROPTIONS.find(
      (element) => element.value === manager
    ).label;

    if (cellphone) {
      const rawPhone = cellphone.replace(/\D/g, "");

      let linkText = `Você recebeu uma indicação para o cargo de ${translateManager} do Programa de Afiliados Banco PAN. Clique no link para concluir: ${link}`;
      let whatsappLink = `https://api.whatsapp.com/send?phone=55${rawPhone}&text=${encodeURIComponent(
        linkText
      )}`;

      this.setState({ whatsappLink });
    }
  };

  clearLink = () => {
    this.setState({ link: "" });
  };

  setEmail = (value) => {
    this.setState({ email: value });
  };

  setManager = async (value) => {
    this.setState({ manager: value });
  };

  isExecutiveOrExclusive = () => {
    const { manager } = this.state;

    return manager && (manager === "executive" || manager === "exclusive");
  };

  isExecutive = () => {
    const { manager } = this.state;

    return manager && manager === "executive";
  };

  isExclusive = () => {
    const { manager } = this.state;

    return manager && manager === "exclusive";
  };

  isPrivateOrSuperintendent = () => {
    const { manager } = this.state;

    return manager && (manager === "superintendent" || manager === "private");
  };

  setwhatsapplink = (value) => {
    this.setState({ whatsapplink: value });
  };

  resetManager = () => {
    this.ManagerRef.current.state.value = "";
    this.setState({ manager: "" });
  };

  setLoading = (bool) => {
    this.setState({ loading: bool });
  };

  setDDDsList = (dddArray) => {
    this.setState(() => {
      return {
        DDDsList: dddArray,
      };
    });
  };

  addSelectAllUFOption = () => {
    this.setUFsList((prevState) => ({
      UFsList: prevState.UFsList.unshift("Selecionar Tudo"),
    }));
  };

  setChosenDDDs = (dddArray) => {
    this.setState(() => {
      return {
        chosenDDDs: dddArray,
      };
    });
  };

  setChosenUFs = (ufsArray) => {
    this.setState(() => {
      return {
        chosenUFs: ufsArray,
      };
    });
  };
  setUFsList = (ufsArray) => {
    this.setState(() => {
      return {
        UFsList: ufsArray,
      };
    });
  };
  setDDDsList = (ufsArray) => {
    this.setState(() => {
      return {
        UFsList: ufsArray,
      };
    });
  };
  getManager = async () => {
    const { chosenUFs } = this.state;
    this.setState({ isEditing: true });

    let id = queryString.parse(this.props.location.search).id;

    try {
      const response = await mainApi.get(`/manager/${id}`);

      const {
        name,
        email,
        cellphone,
        level,
        ufs,
        ddds,
      } = response.data.data.manager;      

      let allDDDs = [];

      ufs.forEach((uf) => {
        allDDDs.push(...UFddd[uf])
      })

      const notSelectedDDDs = allDDDs.filter((ddd)=>!ddds.includes(ddd));

      this.setName(name);
      this.setEmail(email);
      this.setCellphone(cellphone);
      this.setManager(level);

      await this.setChosenUFs(ufs);
      await this.setChosenDDDs(ddds);
      await this.setDDDsList(notSelectedDDDs);

      const allUFs = Object.keys(UFddd);
      const remainedUFs = allUFs.filter((uf) => !ufs.includes(uf));

      if (chosenUFs.length < 27) {
        await this.setUFsList(["Selecionar Tudo", ...remainedUFs]);        
      }

      let title = document.querySelector("#title-page-affiliate");
      title.innerHTML = `Editando ${name}`;

      this.setState({ manager: level, firstEdit: true });
    } catch (err) {
      console.log(err);
    }
  };

  updateManager = async () => {
    const {
      manager,
      name,
      email,
      cellphone,
      chosenDDDs,
      chosenUFs,
    } = this.state;

    let id = queryString.parse(this.props.location.search).id;

    const data = {
      ufs: chosenUFs,
      ddds: chosenDDDs,
      userId: id,
      name,
      email,
      cellphone,
    };

    this.setLoading(true);
    try {
      const response = await mainApi.put("/manager", data);

      swal.confirm("success", "Sucesso", response.data.message, () =>
        window.history.back()
      );
    } catch (err) {
      if (!err.response)
        swal.basic(
          "warning",
          "Atenção",
          "Falha ao se comunicar com o servidor."
        );

      if (err.response.data.fields)
        this.handleErrorsResponse(err.response.data.fields);

      swal.basic("warning", "Atenção", err.response.data.message);
    }
    this.setLoading(false);
  };

  setDDDsList = (dddArray) => {
    this.setState(() => {
      return {
        DDDsList: dddArray,
      };
    });
  };

  addSelectAllUFOption = () => {
    this.setUFsList((prevState) => ({
      UFsList: prevState.UFsList.unshift("Selecionar Tudo"),
    }));
  };

  setChosenDDDs = (dddArray) => {
    this.setState(() => {
      return {
        chosenDDDs: dddArray,
      };
    });
  };

  setChosenUFs = (ufsArray) => {
    this.setState(() => {
      return {
        chosenUFs: ufsArray,
      };
    });
  };
  setUFsList = (ufsArray) => {
    this.setState(() => {
      return {
        UFsList: ufsArray,
      };
    });
  };

  setAllUFs = async (e) => {
    const { chosenUFs, UFsList } = this.state;
    const ufsArray = [];

    for (let ddd in UFddd) {
      ufsArray.push(ddd);
    }
    const remainedUfs = chosenUFs.filter((uf) => !UFsList.includes(uf));

    await this.setUFsList(["Selecionar Tudo", ...ufsArray]);
  };

  addDDDs = async (selectedList, selectedItem) => {
    const { UFsList, chosenUFs, chosenDDDs } = this.state;
    if (!UFsList.includes("Selecionar Tudo")) {
      await this.setUFsList(["Selecionar Tudo", ...UFsList]);
    }

    if (selectedItem === "Selecionar Tudo") {
      const allUF = Object.keys(UFddd);
      let allDDDs = [];
      allUF.forEach((uf) => allDDDs.push(...UFddd[uf]));
      await this.setUFsList([]);
      await this.setChosenUFs(allUF);
      await this.setChosenDDDs(allDDDs);
    } else {
      const selectedDDDs = UFddd[selectedItem];

      await this.setChosenUFs([...chosenUFs, selectedItem]);
      await this.setChosenDDDs([...chosenDDDs, ...selectedDDDs]);
    }
    if (chosenUFs.length === 27) {
      await this.setUFsList([]);
    }
  };

  removeDDDs = async (selectedList, removedItem) => {
    const { chosenDDDs, chosenUFs, DDDsList, UFsList } = this.state;

    const remainedUFs = chosenUFs.filter((UF) => UF !== removedItem);
    const dddsToRemove = UFddd[removedItem];
    const remainedDDDs = chosenDDDs.filter(
      (ddd) => !dddsToRemove.includes(ddd)
    );
    const remainedDDDsList = DDDsList.filter(
      (ddd) => !dddsToRemove.includes(ddd)
    );

    await this.setChosenUFs(remainedUFs);
    await this.setChosenDDDs(remainedDDDs);
    await this.setDDDsList(remainedDDDsList);

    const withoutUFDuplicate = UFsList.filter(
      (uf) => uf !== "Selecionar Tudo" && uf !== removedItem
    );

    await this.setUFsList([
      "Selecionar Tudo",
      ...withoutUFDuplicate,
      removedItem,
    ]);
  };

  removeDDD = async (selectedList, removedItem) => {
    const { chosenDDDs, DDDsList } = this.state;

    const remainedDDDs = chosenDDDs.filter((ddd) => ddd !== removedItem);

    if (!DDDsList.includes(removedItem)) {
      await this.setChosenDDDs(remainedDDDs);
      await this.setDDDsList([...DDDsList, removedItem]);
    } else {
      await this.setChosenDDDs(remainedDDDs);
    }
  };

  addDDD = (selectedList, selectedItem) => {
    this.setState((prevState) => ({
      chosenDDDs: [...prevState.chosenDDDs, selectedItem],

    }));
  };

  resetUFs = async () => {
    const { UFsList, chosenUFs } = this.state;
    if (UFsList.length === 0) {
      await this.setUFsList(["Selecionar Tudo", ...chosenUFs]);
    }
    await this.setChosenUFs([]);
    await this.setChosenDDDs([]);
    await this.setDDDsList([]);
    this.multiselectUFRef.current.resetSelectedValues();
    const allUFs = Object.keys(UFddd);
    await this.setUFsList(["Selecionar Tudo", ...allUFs]);
  };

  resetDDDs = async () => {
    const { chosenDDDs, DDDsList } = this.state;
    await this.setDDDsList([...DDDsList, ...chosenDDDs]);
    await this.setChosenDDDs([]);
    this.multiselectDDDRef.current.resetSelectedValues();
  };

  componentDidMount = async () => {    
    await this.setUserData();

    if ("id" in queryString.parse(this.props.location.search)){
      await this.getManager();
    } else {       
      this.setAllUFs();
    }    
  };

  render() {
    const {
      errors,
      loading,
      name,
      email,
      cellphone,
      isEditing,
      manager,
      isCorp,
      managerOptions,
      UFsList,
      DDDsList,
      chosenUFs,
      chosenDDDs,
    } = this.state;

    return (
      <div className="content">
        <div className="container-fluid">
          <LoadingAlert show={loading} />

          <LinkVoltar className="mb-3" />

          <Card
            category="Preencha os dados para adicionar um novo gerente."
            content={
              <Grid fluid className="p-0">
                <Row>
                  <Col md={6}>
                    <InputMuiField
                      label="Nome"
                      value={name}
                      onChange={(event) => this.setName(event.target.value)}
                      error={errors.name}
                    >
                      Nome
                    </InputMuiField>
                  </Col>

                  <Col md={6}>
                    <InputMuiField
                      label="Email"
                      value={email}
                      onChange={(event) => this.setEmail(event.target.value)}
                      error={errors.email}
                    >
                      Email
                    </InputMuiField>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <InputMask
                      mask="(99) 99999-9999"
                      maskChar="_"
                      value={cellphone}
                      onChange={(event) =>
                        this.setCellphone(event.target.value)
                      }
                    >
                      {() => (
                        <InputMuiField label="Celular" error={errors.cellphone}>
                          Celular
                        </InputMuiField>
                      )}
                    </InputMask>
                  </Col>

                  <Col md={6}>
                    <MuiSelect
                      placeholder="Selecione o tipo de gerente"
                      onChange={(event) => this.setManager(event.value)}
                      options={managerOptions}
                      value={managerOptions.find(
                        (element) => element.value === manager
                      )}
                      error={errors.level}
                      isDisabled={isEditing && isCorp}
                      inputRef={this.ManagerRef}
                    />
                  </Col>

                  <Col md={12}>
                    <div
                      className="multi-wrapper"
                      style={{
                        marginBottom: "20px",
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Multiselect
                        placeholder="Selecione os UFs"
                        options={UFsList}
                        isObject={false}
                        selectedValues={chosenUFs}
                        onSelect={this.addDDDs}
                        onRemove={this.removeDDDs}
                        hidePlaceholder={true}
                        ref={this.multiselectUFRef}
                        selectionLimit={27}
                        emptyRecordMsg=""
                        style={this.multiSelectUFCSS}
                      />
                      <a
                        onClick={this.resetUFs}
                        style={{
                          cursor: "pointer",
                          color: "grey",
                          position: "absolute",
                          right: "15px",

                        }}
                      >
                        <strong>X</strong>
                      </a>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div
                      className="multi-wrapper"
                      style={{
                        marginBottom: "20px",
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Multiselect
                        options={DDDsList}
                        placeholder="Selecione os DDD"
                        isObject={false}
                        selectedValues={chosenDDDs}
                        onSelect={this.addDDD}
                        onRemove={this.removeDDD}
                        hidePlaceholder={true}
                        ref={this.multiselectDDDRef}
                        emptyRecordMsg=""
                        style={this.multiSelectDDDCSS}

                      />
                      <a
                        onClick={this.resetDDDs}
                        style={{
                          cursor: "pointer",
                          color: "grey",
                          position: "absolute",
                          right: "15px",
                        }}
                      >
                        <strong>X</strong>
                      </a>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg={4} md={12}>
                    <Button
                      onClick={() => {
                        if (isEditing) return this.updateManager();
                        this.createManager();
                      }}
                    >
                      {!isEditing ? "Enviar convite para cadastro" : "Salvar"}
                    </Button>
                  </Col>
                </Row>
              </Grid>
            }
          />
        </div>
      </div>
    );
  }
}

export default CreateManager;
