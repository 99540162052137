import React,{useState, useEffect} from 'react';

import {Title} from './styled'

const OperatorPanelHome = () => {
  const [name, setName] = useState('')

  useEffect(()=>{
   const name =  localStorage.getItem("@uxtech-mlm/UserName").split('.')[0]
  
    setName(name)

  },[])


  return (
    <>
    <h1>Seja Muito Bem-Vindo {name}</h1>
    </>
  )

}

export default OperatorPanelHome





