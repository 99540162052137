import React, { Component, memo } from 'react'

import MUIDataTable from "mui-datatables"
import { MuiThemeProvider } from "@material-ui/core/styles"
import getMuiTheme from 'utils/getMuiTheme'
import { options, columns } from './tableConfig'

import { LoadingAlert, ConfirmationAlert } from 'components/Alert/CustomAlert'
import LinkVoltar from 'components/LinkVoltar'

import mainApi from 'services/mainApi'

class ManagerPendingList extends Component {

  constructor(props) {
    super(props)

    this.state = {
      managers: [], 
      totalDocs: 0,
      page: 1,
      sort: '',
      loading: false,
      show: false,
      onConfirm: () => {},
      text: '',
      title: '',
      bold: ''
    }
  }

  setLoading = bool => {
    this.setState({ loading: bool })
  }

  setQuery = () => {
    const { page, sort } = this.state

    let query = ''

    query += `?page=${page}&limit=25`

    if (sort) query += `&sort=${sort}`

    return query
  }

  changePage = (page) => {
    this.setState({ page: page + 1 }, () => this.getManagers())
  }

  onSortChange = (value) => {
    this.setState({ sort: value }, () => this.changePage(0))
  }

  getManagers = async () => {    

    const query = this.setQuery()

    this.setLoading(true)
    try {
      const response = await mainApi.get(`manager/list/presignup${query}`)
      const managers = response.data.data.managers.docs
      const totalDocs = response.data.data.managers.totalDocs


      this.setState({ totalDocs })
      this.setManagers(managers)
    } catch (err) {
      console.log(err)
    }
    this.setLoading(false)

  }

  showConfirm = ({ userId, userName, userLevel }) => {

    this.setState({ 
      onConfirm: () => this.deleteUser(userId), 
      show: true, 
      title: `Remover convite?`,
      text: `Você deseja remover o convite para o ${userName} para o cargo ${userLevel}?`,
      bold: 'ATENÇÃO: Se o gerente estiver atribuido a alguma loja, essas atribuições serão perdidas.'
    })
  }

  deleteUser = async (userId) => {
    this.setState({ show: false })
    this.setLoading(true)
    try {
      const response = await mainApi.delete(`manager/${userId}`)
    } catch (err) {
      console.log(err)
    }
    this.setLoading(false)
  }

  setManagers = value => {
    this.setState({ managers: value })
  }          

  componentDidMount = async () => {
    await this.getManagers()    
  }

  render() {    
    const { managers, totalDocs, loading, onConfirm, show } = this.state        
  
    const finalOptions = {
      ...options,
      count: this.state.totalDocs,
      onColumnSortChange: (value, type) => {
        const hasPlus = 'descending' === type ? '-' : ''
        this.onSortChange(hasPlus + value)
      },
      onTableChange: (action, tableState) => {     
        const { page } = this.state
  
        switch (action) {
          case 'changePage':
            this.changePage(tableState.page)
            break;
          case 'propsUpdate':
            if (page !== tableState.page + 1) tableState.page = 0;
            break;
          default:
            break;
        }
      }
    }

    const column = columns({ deleteUser: this.showConfirm })

    return (
      <div className="content">
        <div className="container-fluid">

          <LoadingAlert 
            show={loading}
          />
          < ConfirmationAlert
            show={show}
            title={this.state.title}
            text={this.state.text}
            onConfirm={onConfirm}
            onCancel={() => this.setState({ show: false })}
            bold={this.state.bold}
            type='warning'
          />

          <LinkVoltar className="mb-3" />

          <MuiThemeProvider theme={getMuiTheme}>
            <MUIDataTable
              title={`Total de usuários encontrados: ${totalDocs}`}
              data={managers}
              columns={column}
              options={finalOptions}
            />
          </MuiThemeProvider>

        </div>
      </div>
    )
  }  
}

export default memo(ManagerPendingList)