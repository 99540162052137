import React, { useState, useLayoutEffect, useEffect, useRef } from 'react'

import { Grid, Row, Col } from 'react-bootstrap'

import MuiSelect from 'components/Selects/MuiSelect'

import MahoeIcon from "components/MahoeIcons"
import Funnel from "components/Funnel"
import { Line, HorizontalBar } from 'react-chartjs-2'

import { StatsCard } from "components/StatsCard/StatsCard.jsx"
import PercentageCard from 'components/PercentageCard'
import mainApi from 'services/mainApi'
import labelValue from "utils/labelWithValue"
import { Card } from "components/Card/Card.jsx"
import { LoadingAlert } from 'components/Alert/CustomAlert'

import MuiDatepickerMonth from 'components/MuiDatepickerMonth'
import { objectToParams } from 'utils/formatData'
import { translateMonths } from 'utils/translate'
import { formatYearMonth } from 'utils/formaterDate'
import getUserData from 'utils/checkUser'
import { funnelAppsflyerLabels } from 'utils/CommonData'
import { FormHelperText } from '@material-ui/core'
import moment from 'moment'
import { formatToBrWithHour } from 'utils/formaterDate'

const allMonths = [
  'Jan',
  'Fev',
  'Mar',
  'Abril',
  'Mai',
  'Jun',
  'Jul',
  'Ago',
  'Set',
  'Out',
  'Nov',
  'Dez'
]

const colorByEvent = {
  "BP:APP:Onboarding:HoraDasFotos": "rgba(255, 99, 132, 0.8)",
  "BP:APP:Onboarding:VamosTirarUmaSelfie": "rgba(54, 162, 235, 0.8)",
  "BP:APP:Onboarding:DadosIniciais": "rgba(255, 206, 86, 0.8)",
  "BP:APP:Onboarding:MaisSobreVoce": "rgba(75, 192, 192, 0.8)",
  "PRD:BP:APP:Onboarding:RetornoContaAbertaSucesso": "rgba(153, 102, 255, 0.8)",
  "BP:APP:Onboarding:OndeVoceMora": "rgba(255, 159, 64, 0.8)"
}

const graphOptions = {
  plugins: {
    datalabels: {
      backgroundColor: function (context) {
        return context.dataset.backgroundColor;
      },
      borderRadius: 4,
      color: "black",
      font: {
        weight: "bold",
      },
      formatter: Math.round
    },
  }
};

function Dashboard({ history }) {

  const [ loading, setLoading ] = useState(false)

  // cards
  const [ links, setLinks ] = useState(0)
  const [ converted, setConverted ] = useState(0)
  const [ clicks, setClicks ] = useState(0)
  const [ createAccount, setCreateAccount ] = useState(0)
  const [ installs, setInstalls ] = useState(0)
  const [ startedSignup, setStartedSignup ] = useState(0)
  const [ appsFlyerGraphData, setAppsFlyerGraphData ] = useState({})

  // charts
  const [ clicksChart, setClicksChart ] = useState([])
  const [ convertedChart, setConvertedChart ] = useState([])
  const [ topText, setTopText ] = useState('')

  // options
  const [ corporationAgenciesOptions, setCorporationAgenciesOptions ] = useState([])
  const [ agenciesOptions, setAgenciesOptions ] = useState([])
  const [ agencyCorp, setAgencyCorp ] = useState('')
  const [ productOptions ] = useState([
    { value: '5f5777c4e2fd425e4bd7da5a', label: 'Conta digital' },
  ])

  // filters
  const [ selectedDate, setSelectedDate ] = useState(new Date())
  const [ corporation, setCorporation ] = useState('')
  const [ agency, setAgency ] = useState('')
  const [ product, setProduct ] = useState(productOptions[0]?.value)

  const [ top5, setTop5 ] = useState({})

  const [ growth, setGrowth ] = useState({})
  const [ month, setMonth ] = useState()
  const [ isCorp, setIsCorp ] = useState()
  const [ appsFlyerDatas, setAppsFlyerDatas ] = useState()

  const partnerRef = useRef(null)
  const [updatedAt, setUpdatedAt] = useState()

  const valuesToTop5 = (top) => {

    const labels = [];
    const clicks = [];

    if (top.length > 0) {

      top.forEach((item) => {
        labels.push(item.label[0]);
        clicks.push(item.value)
      });

    }

    const chartData = {
      labels: [...labels],
      datasets: [
        {
          label: "Quantidade de contas abertas",
          data: [...clicks],
          backgroundColor: [
            "#039be582",
            "#039be582",
            "#039be582",
            "#039be582",
            "#039be582",
          ],
          borderColor: [
            "#039be582",
            "#039be582",
            "#039be582",
            "#039be582",
            "#039be582"
          ],
          borderWidth: 1,
        }
      ]
    };

    setTop5(chartData)
  }

  const makeQuery = () => {
    const params = {}

    if(corporation) params.corporationId = corporation
    if(product) params.productId = product
    if(selectedDate) params.date = formatYearMonth(selectedDate)
    if(agency) {
      params.agencyId = agency
    }
    if(agencyCorp) params.agencyId = agencyCorp

    return objectToParams(params)
  }

  const getAgenciesInManager = async () => {
    try {
      const response = await mainApi.get(`/corporation/list/agencies/${corporation}`)
      const options = response.data.agencies
      options.unshift({ value: '', label: 'Todos os parceiros' })

      setAgenciesOptions(options)
      if(partnerRef.current) partnerRef.current.state.value = options[0]
    } catch(err) {
      console.log(err)
    }
  }

  const getDashbordValues = async () => {
    const query = makeQuery()

    setLoading(true)
    try {
      const response = await mainApi.get(`/managers/dashboard${query}`)
      const data = response.data.calculations

      setUpdatedAt(Date.now())
      const { cards, graphs, top5, percentage } = data

      // cards
      setClicks(cards.clicked)
      setCreateAccount(cards.signup)
      setLinks(cards.links)
      setInstalls(cards.installs)
      setStartedSignup(cards.startedSignup)
      setConverted(cards.converted)

      const growthFormat = {
        actual: { name: Object.keys(percentage)[0], value: Object.values(percentage)[0] },
        previous: { name: Object.keys(percentage)[1], value: Object.values(percentage)[1] },
        percentage: percentage.percentage
      }

      setGrowth(growthFormat)

      // Charts
      setClicksChart(returnMonthsOrder(graphs.clicked))
      setConvertedChart(returnMonthsOrder(graphs.signupData))

      valuesToTop5(top5)

    } catch(err) {
      console.log(err)
    }
    setLoading(false)

  }

  const mountAppsFlyerGraphData = data => {


    const width = 50* data.length
    const dataWidth = []
    data.forEach((item, i)=>{
     dataWidth.push(width-(50*i))
    })

    var appsFlyerData = {
      labels: [],
      data: [],
      datasets: [
        {
          data: dataWidth,
          backgroundColor: [],
          borderWidth: 0,
          weight: 10,
          pointStyle: "rectRounded"
        }
      ]
    };

    data.sort((valueA, valueB) => valueB.count - valueA.count)

    // data.forEach(key => {
    //   appsFlyerData.labels.push(key._id.split(":Onboarding")[1].substr(1));

    //   if (key._id == "PRD:BP:APP:Onboarding:RetornoContaAbertaSucesso") {
    //     // appsFlyerData.datasets[0].data.push(converted);
    //     appsFlyerData.data.push(converted);
    //   } else {
    //     // appsFlyerData.datasets[0].data.push(key.count);
    //     appsFlyerData.data.push(key.count);
    //   }

    //   appsFlyerData.datasets[0].backgroundColor.push(colorByEvent[key._id]);
    // });

    funnelAppsflyerLabels.forEach(key =>{

      if(data.length){

        let currentData = data.find(item => item._id.includes(key))

        if(currentData) {
          appsFlyerData.labels.push(key)

          if (currentData?._id == "PRD:BP:APP:Onboarding:RetornoContaAbertaSucesso") {
            // appsFlyerData.datasets[0].data.push(converted);
            appsFlyerData.data.push(converted);
          } else {
            // appsFlyerData.datasets[0].data.push(key.count);
            appsFlyerData.data.push(currentData?.count);
          }
          appsFlyerData.datasets[0].backgroundColor.push(colorByEvent[currentData?._id]);
        }
      }
    })

    console.log(appsFlyerData)

    setAppsFlyerGraphData(appsFlyerData)
  };

  useEffect(() => {
    setAppsFlyerGraphData({
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
          borderWidth: 0,
          weight: 10,
          pointStyle: "rectRounded"
        }
      ]
    })

    const getAppsFlyer = async () => {
      const query = makeQuery()

      try {
        const appsflyerDataResponse = await mainApi.get(`/appsflyer/events${query}`)

        const appsFlyerData = appsflyerDataResponse.data.data.events

        setAppsFlyerDatas(appsFlyerData)
        mountAppsFlyerGraphData(appsFlyerData)

      } catch (err) {
        console.log(err)
      }
    }

    getAppsFlyer()
  }, [ converted ])

  // useEffect(() => {
  //   if (appsFlyerDatas) mountAppsFlyerGraphData(appsFlyerDatas)
  // }, [converted])

  useLayoutEffect(() => {
    getDashbordValues()

    if(corporation) getAgenciesInManager()

  }, [ corporation, product, selectedDate, agency, agencyCorp ])

  useEffect(() => {

    setAgencyCorp('')
    if(partnerRef.current) partnerRef.current.state.value = null
  }, [ corporation ])

  useLayoutEffect(() => {

    const getCorporations = async () => {
      try {
        const response = await mainApi.get('/corps/manager')
        const options = response.data.data.corporations

        options.unshift({ value: '', label: 'Todas as corporações' })

        setCorporationAgenciesOptions(options)
      } catch(err) {
        console.log(err)
      }
    }

    const getAgencies = async () => {
      try {
        const response = await mainApi.get('/corporation/list/agencies/select')
        const options = response.data.agencies

        options.unshift({ value: '', label: 'Todos os parceiros' })

        setCorporationAgenciesOptions(options)
      } catch(err) {
        console.log(err)
      }
    }

    const setUserData = async () => {
      const userData = await getUserData(history.location.pathname)

      const isCorp = ['corporation', 'subcorp'].includes(userData.level)
      setIsCorp(isCorp)

      if(!isCorp) getCorporations()
      else getAgencies()
    }

    setUserData()
  }, [])

  function TopText() {

    let top = 'Top 5'

    if(isCorp) {

      if(!agency) top += ' parceiros'
      if(agency) top += ' vendedores'
    } else {

      if(!corporation && !agencyCorp) top += ' corporações'
      if(corporation && !agencyCorp) top += ' parceiros'
      if(corporation && agencyCorp) top += ' vendedores'
    }

    return top
  }

  const returnMonthsOrder = (months) => {

    if(!months) return []

    return [
      months.january,
      months.february,
      months.march,
      months.april,
      months.may,
      months.june,
      months.july,
      months.august,
      months.september,
      months.october,
      months.november,
      months.december
    ]
  }

  const time = formatToBrWithHour(moment(updatedAt).toDate());

  return (
    <div className="content">

      <LoadingAlert
        show={loading}
      />

      <Grid className="pt-3" fluid>
        <Row className="pb-md-3">
          <Col md={3} sm={12}>
            <FormHelperText>Selecione um produto</FormHelperText>
            <MuiSelect
              placeholder="Selecione um produto"
              defaultValue={ productOptions[0] }
              onChange={(event) => setProduct(event.value)}
              options={productOptions}
            />
          </Col>

          {isCorp ?
            <Col md={3} sm={12}>
              <FormHelperText>Selecione um parceiro</FormHelperText>
              <MuiSelect
                placeholder="Selecione um parceiro"
                onChange={(event) => setAgency(event.value)}
                defaultValue={{ value: '', label: 'Todos os parceiros' }}
                options={corporationAgenciesOptions}
                defaultValue={{ value: '', label: 'Todos os parceiros' }}
              />
            </Col>
          :
            <>
            <Col md={3} sm={12}>
              <FormHelperText>Selecione uma corporação</FormHelperText>
              <MuiSelect
                placeholder="Selecione uma corporação"
                onChange={(event) => setCorporation(event.value)}
                defaultValue={{ value: '', label: 'Todas as corporações' }}
                options={corporationAgenciesOptions}
                defaultValue={{ value: '', label: 'Todas as corporações' }}
              />
            </Col>

            <Col md={3} sm={12}>
              <FormHelperText>Selecione um parceiro</FormHelperText>
              <MuiSelect
                placeholder="Selecione um parceiro"
                isDisabled={corporation ? false : true}
                inputRef={partnerRef}
                onChange={(event) => setAgencyCorp(event.value)}
                options={agenciesOptions}
              />
            </Col>
            </>
          }

          <Col md={3} sm={12}>
            <FormHelperText>Selecione um mês</FormHelperText>
            <MuiDatepickerMonth
              date={selectedDate}
              onChange={(date, event) => setSelectedDate(date)}
              isClearable={false}
            />
          </Col>
        </Row>

        {selectedDate ? (
          <Row>
            <Col style={{ display: 'flex' }}>
              <div style={{ paddingLeft: '15px' }}>
                <p>Dados referentes ao mês de <strong>{translateMonths(moment(selectedDate).format('MMMM'))}</strong></p>
              </div>

              <div
                style={{
                  marginLeft: "auto ",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <span className="txtUpdateDashboard">
                  Última atualização:{" "}
                  {loading ? "Buscando..." : time}
                </span>

                <span
                  onClick={getDashbordValues}
                  style={{
                    cursor: "pointer",
                    marginLeft: "10px",
                    marginRight: "10px",
                    zIndex: 998,
                  }}
                >
                  <MahoeIcon name="reload" size="22px" fill="#039BE5" />
                </span>
              </div>
            </Col>
          </Row>
        ) : null}

        <Row>
          <Col md={6} sm={12}>

            <Row>
              <Col md={6} sm={12}>
                <StatsCard
                  bigIcon={
                    <MahoeIcon
                      name="link-default"
                      size="25px"
                      fill="rgb(128, 210, 99)"
                    />
                  }
                  statsText={ links }
                  statsValue="Links gerados"
                  statsIcon={<i className="fa fa-calendar-o" />}
                  statsIconText="Instalou o app"
                />
              </Col>

              <Col md={6} sm={12}>
                <StatsCard
                  bigIcon={
                    <MahoeIcon
                      name="touch"
                      size="25px"
                      fill="rgb(128, 210, 99)"
                    />
                  }
                  statsText={ clicks }
                  statsValue="Cliques"
                  statsIcon={<i className="fa fa-calendar-o" />}
                  statsIconText="Instalou o app"
                />
              </Col>

              <Col md={6} sm={12}>
                <StatsCard
                  bigIcon={
                    <MahoeIcon
                      name="coins"
                      size="25px"
                      fill="rgb(128, 210, 99)"
                    />
                  }
                  statsText={ converted }
                  statsValue="Links convertidos"
                  statsIcon={<i className="fa fa-calendar-o" />}
                  statsIconText="Instalou o app"
                />
              </Col>

              <Col md={6} sm={ 12 }>
                <StatsCard
                  bigIcon={
                    <MahoeIcon
                      name="person-add"
                      size="25px"
                      fill="rgb(128, 210, 99)"
                    />
                  }
                  statsText={ installs }
                  statsValue="Instalados"
                  statsIcon={<i className="fa fa-calendar-o" />}
                  statsIconText="Instalou o app"
                />
              </Col>

              <Col md={6} sm={12}>
                <StatsCard
                  bigIcon={
                    <MahoeIcon
                      name="resize"
                      size="25px"
                      fill="rgb(128, 210, 99)"
                    />
                  }
                  statsText={ startedSignup }
                  statsValue="Dados inseridos"
                  statsIcon={<i className="fa fa-calendar-o" />}
                  statsIconText="Instalou o app"
                />
              </Col>

              <Col md={6} sm={12}>
                <StatsCard
                  bigIcon={
                    <MahoeIcon
                      name="growing-up"
                      size="25px"
                      fill="rgb(128, 210, 99)"
                    />
                  }
                  statsText={ createAccount }
                  statsValue="Contas abertas"
                  statsIcon={<i className="fa fa-calendar-o" />}
                  statsIconText="Instalou o app"
                />
              </Col>
            </Row>

          </Col>

          <Col md={6}>
            <Card
              title="Eventos"
              category="Quantidade de eventos registrados"
              content={
                <>
                  {appsFlyerGraphData.labels?.length < 1 ?
                    <div style={{ height: 240 }} className="flex-full-center">
                      <h2 style={{ fontSize: 25, margin: 0, fontWeight: 'bold' }}>Nenhum dado disponível</h2>
                    </div>
                  :
                  <div style={{ pointerEvents: 'none' }}>
                    <Funnel
                      data={appsFlyerGraphData}
                      height={240}
                      options={{
                        legend: {
                          ...labelValue.legend,
                          position: window.innerWidth <= 1024 ? 'bottom' : 'left'
                        },
                        ...graphOptions,
                        sort: 'desc',
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {datalabels: false}
                      }}
                    />
                  </div>
                  }
                </>
              }
            />
          </Col>
        </Row>

        <Row className="br-3">
          <Col md={4} sm={12}>

            <Card
              // style={ (isCorp && agency) ? { display: 'none' } : {} }
              className="p-3"
              title={TopText()}
              category="Quantidade contas abertas"
              content={
                <>
                  {top5.labels?.length < 1 ?
                    <div style={{ height: 300 }} className="flex-full-center">
                      <h2 style={{ fontSize: 25, margin: 0, fontWeight: 'bold' }}>Nenhum dado disponível</h2>
                    </div>
                  :
                  <HorizontalBar
                    data={top5}
                    height={300}
                    options={{
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              fontColor: "black",
                              padding: -5,
                              mirror: true,
                              beginAtZero:true
                            },
                            stacked: true
                          },
                        ],
                        xAxes: [
                          {
                            stacked: true,
                            ticks: {
                              stepSize: 1,
                              beginAtZero: true
                            }
                          }
                        ]
                      },
                      responsive: true,
                      maintainAspectRatio: false,
                      ...graphOptions
                    }}
                  />
                  }
                </>
              }
            />
          </Col>

          {!selectedDate && (
            <Col md={8} sm={12}>
              <Card
                title="Contas abertas"
                category="Quantidade contas abertas"
                content={
                  <Line
                    data={{
                      labels: [...allMonths],
                      datasets: [
                        {
                          label: 'Contas abertas',
                          fill: false,
                          lineTension: 0.1,
                          backgroundColor: 'rgba(75,192,192,0.4)',
                          borderColor: 'rgba(75,192,192,1)',
                          borderCapStyle: 'butt',
                          borderDash: [],
                          borderDashOffset: 0.0,
                          borderJoinStyle: 'miter',
                          pointBorderColor: 'rgba(75,192,192,1)',
                          pointBackgroundColor: '#fff',
                          pointBorderWidth: 1,
                          pointHoverRadius: 5,
                          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                          pointHoverBorderColor: 'rgba(220,220,220,1)',
                          pointHoverBorderWidth: 2,
                          pointRadius: 1,
                          pointHitRadius: 10,
                          data: [...convertedChart]
                        }
                      ]
                    }}
                    height={300}
                    options={{
                      sort: 'desc',
                      maintainAspectRatio: false,
                      scales: {
                        yAxes: [{
                          ticks: {
                            stepSize: 1,
                            beginAtZero: true,
                            userCallback: function(label, index, labels) {
                              if (Math.floor(label) === label) {
                                  return label;
                              }
                            },
                          }
                        }],
                        xAxues: [{

                        }]
                      },
                      ...graphOptions
                    }}
                  />
                }
              />
            </Col>
          )}

          {!selectedDate && (
            <Col md={8} sm={12}>
              <Card
                title="Cliques no ano"
                category="Cliques nos links gerados"
                content={
                  <Line
                    data={{
                      labels: [...allMonths],
                      datasets: [
                        {
                          label: 'Cliques nos links gerados',
                          fill: false,
                          lineTension: 0.1,
                          backgroundColor: 'rgba(75,192,192,0.4)',
                          borderColor: 'rgba(75,192,192,1)',
                          borderCapStyle: 'butt',
                          borderDash: [],
                          borderDashOffset: 0.0,
                          borderJoinStyle: 'miter',
                          pointBorderColor: 'rgba(75,192,192,1)',
                          pointBackgroundColor: '#fff',
                          pointBorderWidth: 1,
                          pointHoverRadius: 5,
                          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                          pointHoverBorderColor: 'rgba(220,220,220,1)',
                          pointHoverBorderWidth: 2,
                          pointRadius: 1,
                          pointHitRadius: 10,
                          data: [
                            ...clicksChart
                          ]
                        }
                      ]
                    }}
                    height={300}
                    options={{
                      ...graphOptions,
                      sort: 'desc',
                      maintainAspectRatio: false,
                      scales: {
                        yAxes: [{
                          ticks: {
                            userCallback: function(label, index, labels) {
                              if (Math.floor(label) === label) {
                                  return label;
                              }
                            },
                            beginAtZero: true
                          }
                        }]
                      }
                    }}
                  />
                }
              />
            </Col>
          )}

          <Col md={4} sm={12}>

            <Row>
              <Col sm={12}>
                <PercentageCard
                  color="#bdecab"
                  secondColor="white"

                  title={ translateMonths(growth.actual?.name) }
                  number={ growth.actual?.value }

                  secondTitle={ translateMonths(growth.previous?.name)  }
                  secondNumber={ growth.previous?.value }

                  percentage={ growth.percentage }
                />
              </Col>
            </Row>

          </Col>

        </Row>
      </Grid>
    </div>
  )

}

export default Dashboard
