import React, { useEffect, useState, useRef } from 'react'

import { Grid, Row, Col, } from 'react-bootstrap'
import {servers} from "utils/config";

import Description from 'components/DocumentationDescription'
import ResponseCard from 'components/Card/Response'
import Accordion from 'components/Accordion'
import MahoeIcons from "components/MahoeIcons"

import { AddCircleOutline, RemoveCircleOutline } from "@material-ui/icons"
import { FormControlLabel, Switch, TextField, IconButton, Checkbox } from '@material-ui/core';

import getUserData from "utils/checkUser";
import { removeEmptyProps } from 'utils/formatData'
import mainApi from "services/mainApi";

import spinner from "assets/img/spinner.gif";
import getLinkResponse from 'assets/img/agx/responseGetLink.jpeg'
import postLinkResponse from 'assets/img/agx/responsePostLink.jpeg'
import postProposalsResponse from 'assets/img/agx/responseGetProposals.jpg'
import consignadoExample from 'assets/img/agx/consignadoExample.jpeg'
import { Dialog } from "components/Alert/CustomAlert";

import { Container, ApiCard, Column, Token, Loading, CallbackForm, Params } from './styled'

const PartnerDocumentation = ({ history }) => {
  const [corporation, setCorporation] = useState()
  const [expanded, setExpanded] = useState(false)
  const [useCallback, setUseCallback] = useState(true)
  const [onCreateCallback, setOnCreateCallback] = useState(false)
  const [onUpdateCallback, setOnUpdateCallback] = useState(false)
  const [checkDispatch, setCheckDispatch] = useState({ createProposal: false, updateProposal: false })
  const [callbackUrl, setCallbackUrl] = useState('')
  const [slug, setSlug] = useState('')
  const [products, setProducts] = useState([])
  const [token, setToken] = useState()
  const [apiUrl, setApiUrl] = useState('')
  const [test, setTest] = useState(true)
  const [callbackToken, setCallbackToken] = useState('')
  const [inputParams, setInputParams] = useState({ 
    createdAt: '',
    number: '',
    product: '',
    value: '',
    state: '',
    ddd: '',
    phone: '',
    paymentDate: '',
    proposalDate: '',
    linkId: '',
    productId: '',
    name: '',
    client: '',
    code: '',
    situation: ''
  })
  
  const [swal, setSwal] = useState({message: '', show: false, bold:""})

  const copy = (elementId) => {
    var copyText = document.getElementById(elementId);

    var textField = document.createElement('textarea')
    textField.innerText = copyText.innerHTML
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
  }

  const getProducts = async () => {
    try {
      const userData = await getUserData(history);
      const response = await mainApi.get(`/corporation/${userData.id}/products`)
      setProducts(response.data.data.products)
    } catch (err) {
      if (err.response) console.log(err.response.data)
    }
  }

  const getToken = async () => {
    try {
      const userData = await getUserData(history);
      const response = await mainApi.get(`/corporation/${userData.id}/partner/token`)
      const token = response.data.data.token

      setCorporation(userData.corpName)
      setToken(token)
      setSlug(userData.corporationSlug)
    } catch (err) {
      if (err.response) console.log(err.response.data)
    }
  }

  const getCallback = async () => {
    try {
      const userData = await getUserData(history);
      const response = await mainApi.get(`/corporation/${userData.id}/partner/callback`)
      const callback = response.data.data.callback

      setUseCallback(callback.use)
      setCallbackUrl(callback.url)
      setCallbackToken(callback.token)
      setInputParams(callback.params)
      if (response.data.data.dispatch.length) {
        setCheckDispatch(response.data.data.dispatch.reduce((prev, value) => ({ ...prev, [value]: true }), {}))
      }
    } catch (err) {
      if (err.response) console.log(err.response.data)
    }
  }

  useEffect(() => {
    getToken()
    getCallback()
    getProducts()
    const env = process.env.REACT_APP_ENV
    setApiUrl(servers[env])
  }, []);

  if (!corporation) return (
    <Loading>
      <img src={spinner} alt="girando" />
      <h2>Carregando...</h2>
    </Loading>
  )

  const handleUrlChange = (value) => setCallbackUrl(value.target.value)

  const handleInputParams = (event, name) => {
    const newInputParams = Object.assign({}, inputParams)
    newInputParams[name] = event.target.value
    setInputParams(newInputParams)
  }

  const handleSubmitCallback = async () => {
    const userData = await getUserData(history);
    const data = removeEmptyProps(inputParams)
    const dispatch = []

    for (let [key, value] of Object.entries(checkDispatch)) {
      if (!!value) dispatch.push(key)
    }
    
    try {
      
      const response = await mainApi.put(`/corporation/${slug}/partner/callback`, {
        useCallback,
        params: data,
        url: callbackUrl,
        token: callbackToken,
        dispatch
      }, { headers: { Authorization: `Bearer ${userData.token}` } })
      showMessage('success', 'Sucesso', 'Valores atualizados com sucesso')
    } catch(exception) {
      if(exception.response) {
        showMessage('warning', 'Atenção', exception.response.data.message)
      } else {
        showMessage('warning', 'Atenção', 'Não foi possível realizar a solicitação. Tente novamente mais tarde!')
      }
    }
  }

  const handleChangeCallback = async () => {
    const userData = await getUserData(history);
    try {
      const response = await mainApi.put(`/corporation/${slug}/partner/callback`, {
        useCallback: !useCallback
      }, { headers: { Authorization: `Bearer ${userData.token}` } })
      showMessage('success', 'Sucesso', response.data.message)
      setUseCallback(!useCallback)
      
    } catch(exception) {
      if(exception.response) {
        showMessage('warning', 'Atenção', exception.response.data.message)
      } else {
        showMessage('warning', 'Atenção', 'Não foi possível realizar a solicitação. Tente novamente mais tarde!')
      }
    }
  }

  const handleDispatchCallback = (event, name) => setCheckDispatch({... checkDispatch, [name]: event.target.checked })

  const showMessage = (type, title, message, bold) => setSwal({ show: true, message, type, bold, title })

  return (
    <div style={{ width: '100%', height: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px 20px' }}>
      <Dialog
          type={swal.type}
          show={swal.show}
          title={swal.title}
          onConfirm={() => { setSwal((value) =>  ({ ...value, show: false }) ) }}
          text={swal.message}
          boldText={swal.bold}
        />
      <Grid style={{ width: '100%', height: '100%', paddingTop: 20, backgroundColor: '#fff' }}>
        <Row>
          <Container>
            <div className='theme'>
              <h3>Introdução</h3>
              <p>API exclusiva para parceiros</p>
            </div>
            <div className='theme'>
              <h3>Token de acesso</h3>
              <p>A token do acesso é usada para o cliente solicitar ou manipular os recursos aos quais o usuário tem permissão de acesso e deve ser usada em todas as requisições. A token não tem tempo de expiração. </p>
            </div>
            <div className='theme' style={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={() => { setExpanded(!expanded) }}>
              {expanded ? (<MahoeIcons
                name="chevron-down"
                size="24px"
                fill="#000"
              />) :
                (<MahoeIcons
                  name="chevron-right"
                  size="24px"
                  fill="#000"
                />)} Clique aqui para ver seu Token de Acesso
              </div>
            {expanded && (
              <>
                <Token>
                  <div>
                    <div id="token">{token}</div>
                    <button onClick={() => copy("token")}>Copy</button>
                  </div>
                </Token>
              </>
            )}
            <h3>Rotas</h3>
          </Container>
        </Row>
        <Column >
          <Container noPadding>
            <h4 style={{fontWeight: 600, fontSize: "1.4em"}}>Geração de links</h4>
            <p>A criação de links de indicação que pode redirecionar diretamente o cliente para a página do produto (GET) ou retornar o link gerado (POST).</p>
            <h4> <span style={{ backgroundColor: 'rgb(29,129,39)', borderRadius: '5px', color: '#fff', padding: '2px 10px', fontSize: '20px' }}>GET</span> Gerar link para indicação</h4>
            <p style={{fontSize:"12px"}}>{`${apiUrl}/partner/${slug}?product={nomeDoProduto}&client={codigoDoCliente}`}</p>
            <p>Retorna uma página com o QR code e o link para baixar o APP.</p>

            <h5>Header params</h5>
            <table>
              <tbody>
                {/* <Description
                  name="authorization"
                  required
                  type="Bearer <string>"
                  text="Token de autorização da API para acesso (disponível acima)"
                /> */}
              </tbody>
            </table>

            <h5>Query params</h5>
            <table>
              <tbody>
                <Description required type='string' name='client' text={"Identificação do cliente no sistema da " + corporation || 'corporação'} />
                <Description
                  required
                  type='string'
                  name='product'
                  text='Palavra chave que identifica o produto'
                  example={products ? products.map(product => product.slug) : products}
                />
              </tbody>
            </table>
            <Accordion title='200 OK' >
              <table>
                <tbody>
                  <Description type='HTML' text='Será enviado um html' />
                </tbody>
              </table>
            </Accordion>

            <Accordion title='400 BAD_REQUEST' disabled />
            <Accordion title='401 UNAUTHORIZED' disabled />
            <Accordion title='403 FORBIDDEN' disabled />
            <Accordion title='404 NOT_FOUND' disabled />
            <Accordion title='500 INTERNAL_SERVER_ERROR' disabled />
          </Container>

          <ResponseCard image={products.reduce((prev, product) =>  (product.slug == 'conta') || prev, false) ? getLinkResponse : consignadoExample} route={`${apiUrl}/partner/${slug}?product={nomeDoProduto}&client={codigoDoCliente}`} />
          {/* <ApiCard >
            <div className='route'>
              <div id="route">https://agx.test/{slug}?product=cartao&client=idClient</div>
              <button onClick={() => copy("route")}>Copy</button>
            </div>
            <div className='response'>
              <img src={getLinkResponse} alt="image"/>
            </div>
          </ApiCard> */}
        </Column>

        <Column >
          <Container noPadding>
            <h4><span style={{ backgroundColor: 'rgb(24, 111, 175)', borderRadius: '5px', color: '#fff', padding: '2px 10px', fontSize: '20px' }}>POST</span> Gerar link para indicação</h4>
            <p style={{fontSize:"12px"}}>{`${apiUrl}/partner/${slug}`}</p>
            <p>Retorna o link gerado para a indicação.</p>

            <h5>Header params</h5>
            <table>
              <tbody>
                <Description
                  name="authorization"
                  required
                  type="Bearer <string>"
                  text="Token de autorização da API para acesso (disponível acima)"
                />
              </tbody>
            </table>
            <h5>Body params</h5>
            <table>
              <tbody>
                <Description
                  name="client"
                  required
                  type="string"
                  text={"Identificação do cliente no sistema da " + corporation || 'corporação' + " (string)"}
                />

                <Description
                  name="product"
                  required
                  type="string"
                  text="Palavra chave que identifica o produto"
                  example={products ? products.map(product => product.slug) : products}
                />
              </tbody>
            </table>
            <Accordion title='200 OK' >
              <table>
                <tbody>
                  <Description
                    name="status"
                    type="string"
                    text="Nome do Status HTTP da resposta"
                  />
                  <Description
                    name="code"
                    type="string"
                    text="Código HTTP da resposta"
                  />
                  <Description
                    name="success"
                    type="string"
                    text="True se a requisição teve resposta positiva"
                  />
                  <Description
                    name="message"
                    type="string"
                    text="Mensagem PT-BR que descreve o resultado da requisição"
                  />
                  <tr>
                    <Accordion title='data' >
                      <table>
                        <tbody>
                          <Description
                            name="link"
                            type="string"
                            text="URL do link"
                          />
                          <Description
                            name="hash"
                            type="string"
                            text="Código do link"
                          />
                        </tbody>
                      </table>
                    </Accordion>
                  </tr>
                </tbody>
              </table>
            </Accordion>

            <Accordion title='400 BAD_REQUEST' disabled />
            <Accordion title='401 UNAUTHORIZED' disabled />
            <Accordion title='403 FORBIDDEN' disabled />
            <Accordion title='404 NOT_FOUND' disabled />
            <Accordion title='500 INTERNAL_SERVER_ERROR' disabled />
          </Container>

          <ResponseCard image={postLinkResponse} route={`${apiUrl}/partner/${slug}`} />
        </Column>


        <Column >
          <Container noPadding>
            <h4> <span style={{ backgroundColor: 'rgb(29,129,39)', borderRadius: '5px', color: '#fff', padding: '2px 10px', fontSize: '20px' }}>GET</span> Buscar propostas</h4>
            <p>{`${apiUrl}/partner/${slug}/proposals`}</p>
            <p>Retorna uma listagem de propostas de acordo com os filtros.</p>

            <h5>Header params</h5>
            <table>
              <tbody>
                <Description
                  name="authorization"
                  required
                  type="Bearer <string>"
                  text="Token de autorização da API para acesso (disponível acima)"
                />
              </tbody>
            </table>

            <h5>Query params</h5>
            <table>
              <tbody>
                <Description
                  name="client"
                  type="string"
                  text={"Identificação do cliente no sistema da " + corporation || 'corporação'} />

                <Description
                  name="page"
                  type="integer"
                  text="Página selecionada" />

                <Description
                  name="limit"
                  type="integer"
                  text="Número máximo de propostas que podem ser retornadas" />

                <Description
                  name="sort"
                  type="String"
                  text="Ordenação das propostas" />

                <Description
                  name="noPagination"
                  type="Boolean"
                  text="Quando verdadeiro será enviado todas as propostas sem paginação" />

                <Description
                  name="integration"
                  type="Boolean"
                  text="Quando true retorna somente as propostas cadastradas pelo link de integração" />
              </tbody>
            </table>
            <Accordion title='200 OK' >
              <table>
                <tbody>
                  <Description
                    name="status"
                    type="string"
                    text="Nome do Status HTTP da resposta"
                  />
                  <Description
                    name="code"
                    type="string"
                    text="Código HTTP da resposta"
                  />
                  <Description
                    name="success"
                    type="string"
                    text="True se a requisição teve resposta positiva"
                  />
                  <Description
                    name="message"
                    type="string"
                    text="Mensagem PT-BR que descreve o resultado da requisição"
                  />
                  <tr>
                    <Accordion title='data' >
                      <table>
                        <tbody>
                          <Description
                            name="docs"
                            type="array"
                            text="Array de objetos contendo os valores da proposta"
                          />
                          <Description
                            name="totalDocs"
                            type="number"
                            text="Total de propostas encontradas"
                          />
                          <Description
                            name="limit"
                            type="number"
                            text="Limite de propostas por página"
                          />
                          <Description
                            name="page"
                            type="number"
                            text="Número da página atual"
                          />
                          <Description
                            name="totalPages"
                            type="number"
                            text="Quantidade total de páginas"
                          />
                          <Description
                            name="pagingCounter"
                            type="number"
                            text="Contador de página?"
                          />
                          <Description
                            name="hasPrevPge"
                            type="boolean"
                            text="Retorna true se houver página anterior"
                          />
                          <Description
                            name="hasNextPge"
                            type="boolean"
                            text="Retorna true se houver página posterior"
                          />
                        </tbody>
                      </table>
                    </Accordion>
                  </tr>
                </tbody>
              </table>
            </Accordion>

            <Accordion title='400 BAD_REQUEST' disabled />
            <Accordion title='401 UNAUTHORIZED' disabled />
            <Accordion title='403 FORBIDDEN' disabled />
            <Accordion title='404 NOT_FOUND' disabled />
            <Accordion title='500 INTERNAL_SERVER_ERROR' disabled />
          </Container>

          <ResponseCard image={postProposalsResponse} route={`${apiUrl}/partner/${slug}/proposals`} />
        </Column>

        <Container noPadding>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

            <h4 style={{ marginRight: '10px', fontWeight: 600, fontSize: '1.4em' }}>WebHooks</h4>
            <FormControlLabel
              control={<Switch color="primary" checked={useCallback} onChange={() => handleChangeCallback()} name="checkedA" />}
              label={useCallback ? "Desativar" : "Ativar"}
              />
          </div>
              <p>O disparo de callback o correrá para todo link seja ele gerado via API ou pela plataforma em outros níveis de acesso abaixo da corporação.</p>
          {useCallback &&
              (
                <>
                <CallbackForm>
                  <div className="callback-form">
                <h4> <span style={{ backgroundColor: 'rgb(29,129,39)', borderRadius: '5px', color: '#fff', padding: '2px 10px', fontSize: '20px' }}>GET</span> Disparo de callbacks</h4>
                <p>Parâmetros não preenchidos não serão utilizados.</p>


                <div>
                  <TextField fullWidth label="URL" variant="outlined" value={callbackUrl} onChange={handleUrlChange} />
                </div>

                <div style={{ marginTop: '20px' }}>
                  <p>Selecione em quais eventos a callback será disparada (Mínimo um evento) *</p>
                </div>

                <div style={{ marginBottom: '20px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox 
                        color="primary" 
                        checked={checkDispatch.createProposal} 
                        onChange={(event) => handleDispatchCallback(event, 'createProposal')} 
                        name="createProposal" 
                      />}
                    label="No recebimento de uma proposta para análise"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary" 
                        checked={checkDispatch.updateProposal}
                        onChange={(event) => handleDispatchCallback(event, 'updateProposal')} 
                        name="updateProposal" 
                      />
                    }
                    label="Na atualização da proposta"
                  />
                </div>

                <div style={{ marginTop: '20px' }}>
                  <p>Digite uma token para receber requisições com cabeçalho de autorização (opcional)</p>
                </div>

                <div style={{ marginBottom: '20px' }}>
                  <TextField 
                    fullWidth 
                    label="Token" 
                    variant="outlined" 
                    value={callbackToken}
                    onChange={(event) => setCallbackToken(event.target.value)}
                  />
                  <p style={{ color: '#919191' }}> Resultado: {`{ Authorization: Bearer ${callbackToken} }`}</p>
                </div>

                {
                  <div style={{ display: 'flex', flexDirection: 'row' }} className='callback-sm-column'>

                  <Col lg={6} md={12} sm={12}>
                    <Params>
                      <div className="input-padding">
                        <p>createdAt:</p>
                      </div>
                      <div className="input-padding">
                        <TextField 
                          fullWidth 
                          label="Parametro" 
                          variant="outlined" 
                          value={inputParams.createdAt}
                          onChange={(event) => handleInputParams(event, 'createdAt')} 
                        />
                      </div>

                    </Params>
                    
                    <Params>
                      <div className="input-padding">
                        <p>number:</p>
                      </div>
                      <div className="input-padding">
                        <TextField 
                          fullWidth 
                          label="Parametro" 
                          variant="outlined" 
                          value={inputParams.number}
                          onChange={(event) => handleInputParams(event, 'number')} 
                        />
                      </div>

                    </Params>
                    
                    <Params>
                      <div className="input-padding">
                        <p>product:</p>
                      </div>
                      <div className="input-padding">
                        <TextField 
                          fullWidth 
                          label="Parametro" 
                          variant="outlined" 
                          value={inputParams.product}
                          onChange={(event) => handleInputParams(event, 'product')} 
                        />
                      </div>

                    </Params>
                    
                    <Params>
                      <div className="input-padding">
                        <p>value:</p>
                      </div>
                      <div className="input-padding">
                        <TextField 
                          fullWidth 
                          label="Parametro" 
                          variant="outlined" 
                          value={inputParams.value}
                          onChange={(event) => handleInputParams(event, 'value')} 
                        />
                      </div>

                    </Params>
                        
                    <Params>
                      <div className="input-padding">
                        <p>state:</p>
                      </div>
                      <div className="input-padding">
                        <TextField 
                          fullWidth 
                          label="Parametro" 
                          variant="outlined" 
                          value={inputParams.state}
                          onChange={(event) => handleInputParams(event, 'state')} 
                        />
                      </div>

                    </Params>

                    <Params>
                      <div className="input-padding">
                        <p>ddd:</p>
                      </div>
                      <div className="input-padding">
                        <TextField 
                          fullWidth 
                          label="Parametro" 
                          variant="outlined" 
                          value={inputParams.ddd}
                          onChange={(event) => handleInputParams(event, 'ddd')} 
                        />
                      </div>

                    </Params>

                    <Params>
                      <div className="input-padding">
                        <p>situation:</p>
                      </div>
                      <div className="input-padding">
                        <TextField 
                          fullWidth 
                          label="Parametro" 
                          variant="outlined" 
                          value={inputParams.situation}
                          onChange={(event) => handleInputParams(event, 'situation')} 
                        />
                      </div>

                    </Params>

                    <Params>
                      <div className="input-padding">
                        <p>phone:</p>
                      </div>
                      <div className="input-padding">
                        <TextField 
                          fullWidth 
                          label="Parametro" 
                          variant="outlined" 
                          value={inputParams.phone}
                          onChange={(event) => handleInputParams(event, 'phone')} 
                        />
                      </div>

                    </Params>
                  </Col>
                    
                  <Col lg={6} md={12} sm={12}>
                    <Params>
                      <div className="input-padding">
                        <p>paymentDate:</p>
                      </div>
                      <div className="input-padding">
                        <TextField 
                          fullWidth 
                          label="Parametro" 
                          variant="outlined" 
                          value={inputParams.paymentDate}
                          onChange={(event) => handleInputParams(event, 'paymentDate')} 
                        />
                      </div>

                    </Params>

                    <Params>
                      <div className="input-padding">
                        <p>proposalDate:</p>
                      </div>
                      <div className="input-padding">
                        <TextField 
                          fullWidth 
                          label="Parametro" 
                          variant="outlined" 
                          value={inputParams.proposalDate}
                          onChange={(event) => handleInputParams(event, 'proposalDate')} 
                        />
                      </div>

                    </Params>

                    <Params>
                      <div className="input-padding">
                        <p>linkId:</p>
                      </div>
                      <div className="input-padding">
                        <TextField 
                          fullWidth 
                          label="Parametro" 
                          variant="outlined" 
                          value={inputParams.linkId}
                          onChange={(event) => handleInputParams(event, 'linkId')} 
                        />
                      </div>

                    </Params>
                    
                    <Params>
                      <div className="input-padding">
                        <p>productId:</p>
                      </div>
                      <div className="input-padding">
                        <TextField 
                          fullWidth 
                          label="Parametro" 
                          variant="outlined" 
                          value={inputParams.productId}
                          onChange={(event) => handleInputParams(event, 'productId')} 
                        />
                      </div>

                    </Params>

                    <Params>
                      <div className="input-padding">
                        <p>name:</p>
                      </div>
                      <div className="input-padding">
                        <TextField 
                          fullWidth 
                          label="Parametro" 
                          variant="outlined" 
                          value={inputParams.name}
                          onChange={(event) => handleInputParams(event, 'name')} 
                        />
                      </div>

                    </Params>

                    <Params>
                      <div className="input-padding">
                        <p>client:</p>
                      </div>
                      <div className="input-padding">
                        <TextField 
                          fullWidth 
                          label="Parametro" 
                          variant="outlined" 
                          value={inputParams.client}
                          onChange={(event) => handleInputParams(event, 'client')} 
                        />
                      </div>
                      

                    </Params>

                    <Params>
                      <div className="input-padding">
                        <p>code:</p>
                      </div>
                      <div className="input-padding">
                        <TextField 
                          fullWidth 
                          label="Parametro" 
                          variant="outlined" 
                          value={inputParams.code}
                          onChange={(event) => handleInputParams(event, 'code')} 
                        />
                      </div>

                    </Params>
                  </Col>
                    
                  </div>
                }
                <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItens: 'center', margin: '10px 0px'}}>
                  <button className='btn btn-primary bty-fill' onClick={handleSubmitCallback} >Salvar</button>
                </div>
                </div>
              </CallbackForm>
              </> 
            )}
        </Container>
      </Grid>
    </div >
  )
}

export default PartnerDocumentation