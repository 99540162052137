import React, { useEffect, useLayoutEffect, useState } from 'react'
import queryString from "query-string";
import mainApi from "services/mainApi";

import { Grid, Row, Col } from 'react-bootstrap';
import UserDetails from './UserDetails';
import MasterDetails from './MasterDetails';
import CorpDetails from './CorpDetails';
import AgencyDetails from './AgencyDetails';
import TableMui from 'components/TableMui';
import { columns, options } from './tableFunctions';
import Skeleton from '@material-ui/lab/Skeleton';
import loadingGif from 'assets/img/loading.gif'
import MahoeIcon from 'components/MahoeIcons'
import getRoutesByLevel from "routes";
import getUserDataByLocalStorage, { setUserData } from "utils/checkUser";
import swal from 'components/Alert/alert';
import Swal from 'sweetalert2';


function OperatorUserDetails(props) {

  const [loading, setLoading] = useState(true)
  const [loadingEmails, setLoadingEmails] = useState(true)
  const [loadingAccess, setLoadingAccess] = useState(false)

  const [user, setUser] = useState()
  const [currentUserData, setCurrentUserData] = useState()

  const [emails, setEmails] = useState([])

  const [totalDocs, setTotalDocs] = useState(0)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)

  const getLogEmails = async () => {
    setLoadingEmails(true)
    const query = queryString.parse(props.location.search)
    if (!query?.id) {
      setLoadingEmails(false)
      return
    }
    try {
      const response = await mainApi.get(`/support/user/${query.id}/email`, {
        params: {
          page,
          limit
        }
      })

      setEmails(response?.data?.data?.emails?.docs)
      setPage(response?.data?.data?.emails?.page)
      setTotalDocs(response?.data?.data?.emails?.totalDocs)

      setLoadingEmails(false)
    } catch (err) {
      console.log(err)
      setLoadingEmails(false)
    }
  }

  const getUserData = async () => {
    setLoading(true)
    const query = queryString.parse(props.location.search)
    if (!query?.id) {
      setLoading(false)
      return
    }
    try {
      const response = await mainApi.get(`/support/user/${query.id}/personal-data`)
      setUser(response?.data?.data?.user)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  useLayoutEffect(() => {

    const getcurrentUser = async () => {
      try {
        const user = await getUserDataByLocalStorage(props.history)
        setCurrentUserData(user)
      } catch (err) {
        console.log(err)
      }
    }

    getcurrentUser()
    getLogEmails()
    getUserData()
  }, [])


  const customOptions = {
    ...options,
    count: totalDocs,
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          setPage(tableState.page + 1)
          break;
        case 'propsUpdate':
          if (page !== tableState.page + 1) tableState.page = 0;
          break;
        default:
          break;
      }
    },
    onChangeRowsPerPage: (number) => {
      setLimit(number)
    }
  }

  const handleAccessUser = async () => {
    let message = ''
    let buttons = true

    const { blocked, resetPassword, firstLogin, analysisSituation, analysis } = user


    if (analysisSituation && analysisSituation !== 'approved' && analysis) {
      buttons = false
      message += `<li>Usuário está ${String(analysis).toLowerCase()}</li>`
    }

    if (blocked) {
      message += "<li>Usuário bloqueado.</li>"
    }

    if (!firstLogin) {
      message += "<li>Usuário não realizou primeiro acesso.</li>"
    }

    if (firstLogin && resetPassword) {
      message += "<li>Usuário solicitou alteração de senha.</li>"
    }



    if (message) {
      Swal.fire({
        icon: 'warning',
        title: 'Atenção',
        html: `${message} ${buttons ? '<br/> <strong> Deseja acessá-lo? </strong>' : ''}`,
        // showConfirmButton: buttons,
        showCancelButton: buttons,
        cancelButtonText: 'Cancelar',
        closeOnClickOutside: false,
        customClass: {
          confirmButton: 'btn btn-success w-100',
          cancelButton: 'btn btn-danger w-100'
        }
      })
        .then((result) => {
          if (buttons && result.isConfirmed) {
            accessUser()
          }
        })
    } else {
      accessUser()
    }

  }

  const accessUser = async () => {
    setLoadingAccess(true)
    try {
      const response = await mainApi.get(`/operator/user/${user?._id}/login`)
      if (response) {

        let supportToken = String(currentUserData?.token).split(' ')[1]
        let supportId = currentUserData?.id

        localStorage.clear();
        await setUserData({ supportToken, supportId, ...response.data })

        const newUserData = await getUserDataByLocalStorage(props.history)
        const routes = getRoutesByLevel(newUserData)

        window.location.replace("/admin" + routes[0].path)
      }

    } catch (err) {
      console.log(err)
      swal.basic(
        'warning',
        'Atenção',
        err.response?.data?.message
      )

    }
    setLoadingAccess(false)
  }

  function handleUnlinkUser() {
    Swal.fire({
      icon: 'warning',
      title: 'Atenção',
      html: `Tem certeza que deseja desvincular o usuário da corporação <strong>${user.corporation.name}</strong>?</br>O usuário não será capaz de acessar o sistema, mas estará liberado para novo cadastro com mesmo CPF.<br/><strong>Está ação é irreversível</strong>.`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
      closeOnClickOutside: false,
      customClass: {
        confirmButton: 'btn btn-success w-100',
        cancelButton: 'btn btn-danger w-100'
      }
    })
    .then((result) => {
      if (result.isConfirmed) {
        unlinkUser()
      }
    })
  }

  async function unlinkUser () {
    const { _id } = user
    if (!_id) return
    try {
      const response = await mainApi.patch(`/internal/operator/users/${_id}/unlink`)
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        html: `Usuário desvinculado com sucesso.`,
        // showConfirmButton: buttons,
        closeOnClickOutside: false,
        customClass: {
          confirmButton: 'btn btn-success w-100',
        }
      })
      .then(() => {
        getUserData()
      })
    } catch (err) {
      swal.basic(
        'warning',
        'Atenção',
        err.response?.data?.message
      )
    }
  }

  useEffect(() => {
    getLogEmails()
  }, [page, limit])

  return (
    <div className="content">
      <Grid fluid >
        <Row className="mb-2"  >
          <Col lg={3} md={3} sm={3} xs={12}>
            <div style={{ marginBottom: '10px' }}>
              <button className="go-back-link" style={{ border: 0, backgroundColor: 'transparent' }} onClick={() => props.history.push('/admin/operator/listUsers')}>
                <MahoeIcon name='arrow-back' fill='#0277bd' style={{ width: '15px', height: '15px', margin: '-4px 2px' }} />
                Voltar
              </button>
            </div>
          </Col>
          <Row style={{ display: 'flex',  justifyContent: 'flex-end', flexWrap: 'wrap', gap: '1em', maxWidth: '100%', marginLeft: 0, marginRight: -15 }}>
          {
            ((user && ['seller', 'supervisor'].includes(user.level) && !user.unlink ) && ['master', 'submaster'].includes(currentUserData?.level)) &&
            <Col className="p-0" lg={4} md={4} sm={4} xs={12} >
              <button
                className='btn btn-round btn-block btn-secondary'
                onClick={handleUnlinkUser}
                disabled={loadingAccess || loading}
                style={{ margin: '0' }}
              >
                Desvincular Usuário
              </button>
            </Col>
          }
          {
            ((user && !['master', 'submaster', 'corporation', 'subcorp'].includes(user.level)) || ['master'].includes(currentUserData?.level)) &&
            <Col className="p-0" lg={4} md={4} sm={4} xs={12} >
              <button
                className='btn btn-mlm btn-round btn-fill btn-block'
                onClick={handleAccessUser}
                disabled={loadingAccess || loading}
                style={{ margin: '0' }}
              >
                {
                  loadingAccess
                    ? <>
                      <i aria-hidden="true" className="af-button-icon fa fa-spinner fa-spin" style={{ marginRight: 20 }}></i>
                      Acessando Usuário
                    </>
                    : <>
                      <MahoeIcon name="person" fill="white" size={15} style={{ marginRight: 20, marginBottom: '-1px' }} />
                      Acessar Usuário
                    </>
                }
              </button>
            </Col>
          }
          </Row>
        </Row>
        <Row className="mb-md-1 mb-1 support-details" >
          <Col className={`mb-1 p-0 ${!loading && 'card'}`} style={{ minHeight: '35vh' }} lg={8} md={6} >
            {(loading && !user)
              ? <Skeleton animation="wave" style={{ height: '100%', transform: 'initial', minHeight: '40vh' }} />
              : <UserDetails user={user} />
            }
          </Col>

          <Col className={`${loading && 'px-1 px-md-0'}`} lg={4} md={6}  >
            {(loading && !user)
              ? <>
                <Skeleton animation="wave" style={{ transform: 'initial', minHeight: '15vh', marginBottom: 5 }} />
                <Skeleton animation="wave" style={{ transform: 'initial', minHeight: '15vh', marginBottom: 5 }} />
                <Skeleton animation="wave" style={{ transform: 'initial', minHeight: '15vh', marginBottom: 10 }} />
              </>
              : <>
                <MasterDetails master={user?.master} />
                <CorpDetails corporation={user?.corporation} />
                <AgencyDetails agency={user?.agency} />
              </>
            }
          </Col>
        </Row>
        <Row>
          <Col style={{ position: 'relative' }}>
            {
              (loadingEmails && !emails.length)
                ? <Skeleton animation="wave" style={{ height: '100%', transform: 'initial', minHeight: '30vh' }} />
                : (
                  <>
                    {
                      loadingEmails &&
                      <div className={`loading-email`} onClick={e => e.stopPropagation()}>
                        <img src={loadingGif} alt="Gif de carregamento" height="20%" />
                      </div>
                    }
                    <TableMui
                      title={`Registro de E-mails`}
                      data={emails}
                      columns={columns}
                      options={customOptions}
                    />
                  </>
                )
            }
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

export default OperatorUserDetails
