import React, { Component } from "react";
import { Grid, Col, Row } from 'react-bootstrap'
import MUIDataTable from "mui-datatables";
import DatePicker from "react-datepicker";
import Moment from 'moment'

import { Dialog } from 'components/Alert/CustomAlert'
import { StatsCard } from "components/StatsCard/StatsCard.jsx"
import MahoeIcon from "../../components/MahoeIcons"
import { numToBRL, getMonthAndYear } from "utils/formatData"
import mainApi from "services/mainApi"
import { introSystem } from 'utils/introToSystem'
import getUserData from "utils/checkUser"
import { contaId } from "utils/CommonData"
import { LoadingAlert } from 'components/Alert/CustomAlert'

import './styles.css'
import dev from "utils/dev";
import { MuiThemeProvider } from "@material-ui/core";
import getMuiTheme from "utils/getMuiTheme";
import { translateMonths } from 'utils/translate';
import Select from "react-select";

import { agenciesHeader, adminHeader } from './tableConfig'

import LoadingCover from "components/LoadingCover";


const hasManager = ['true', true, 1, '1'].includes(localStorage.getItem("@uxtech-mlm/hasManager"))

class Extract extends Component {
  constructor(props) {
    super(props)
    this.state = {
      extracts: [],
      products: [],
      agencies: [],
      csv: '',

      //query
      selectDate: new Date(),
      search: '',
      sort: '',
      selectProduct: '',
      ISADMIN: '',

      //totals
      total: 0,
      totalCount: 0,
      totalCommission: 0,

      //Pagination
      page: 1,
      limit: 10,
      totalDocs: 0,

      loading: false,
      loadingExtract: false,
      userData: {},
      swalMessageShow: false,
      swalTitle: '',
      swalText: ''
    }
  }

  handleStatsCard = async () => {
    const { selectDate, selectProduct, ISADMIN } = this.state;

    this.setState({ loading: true })

    const formatedDate = Moment(selectDate).format('YYYY-MM')

    let product = ''

    let endPoint = `/corp/extract/details`
    if (ISADMIN) endPoint = 'admin/extract/sellers/details'

    if (selectProduct.value) product = '&product=' + selectProduct.value

    try {
      const response = await mainApi.get(
        `${endPoint}?date=${formatedDate}${product}`
      );

      this.setState({
        total: response.data.data.total,
        totalCount: response.data.data.count,
        totalCommission: response.data.data.commission
      })

    } catch (err) {
      console.log(err);
      this.setState({
        total: 0,
        totalCount: 0,
        totalCommission: 0
      })
    }
    this.setState({ loading: false })
  };

  handleAgencyList = async () => {
    const { selectDate, page, limit, search, sort, selectProduct, ISADMIN } = this.state;

    const formatedDate = Moment(selectDate).format('YYYY-MM')
    let product = ''

    if (selectProduct.value) product = '&product=' + selectProduct.value

    let endPoint = `/corp/extract/agency/list`
    if (ISADMIN) endPoint = 'admin/extract/sellers/list'

    try {
      const response = await mainApi.get(
        `${endPoint}?date=${formatedDate}&page=${page}&limit=${limit}${search}${sort}${product}`
      );

      this.setState({
        agencies: response.data.docs,
        totalDocs: response.data.totalDocs
      })

    } catch (err) {
      dev.log(err);
    }
  }

  handleAgencyCsv = async () => {
    const { selectDate, search, sort, selectProduct } = this.state;

    const formatedDate = Moment(selectDate).format('YYYY-MM')

    let responseResult = false

    let product = ''
    if (selectProduct.value) product = '&product=' + selectProduct.value

    try {
      const response = await mainApi.get(
        `/corp/extract/csv/agencies?date=${formatedDate}${search}${sort}${product}`
      );

      this.setState({ csv: response.data.csv }, () => {
        if (this.state.csv) {
          this.createDownloadLink('agencies');
        } else {
          this.setState({
            swalMessageShow: true,
            swalTitle: 'Documento não disponível',
            swalText: 'O documento solicitado nao possui nenhum registro.'
          })
        }
      })

      responseResult = true
    } catch (err) {
      responseResult = false
      dev.log(err);
    }

    if (responseResult) {
      var eventDetail = { "detail": { "button": "Click:BP:IndiqueOPan:BaixarExtratoLojas" } };
      var interactionClick = new CustomEvent("interactionClick", eventDetail);
      document.body.dispatchEvent(interactionClick);
    }
  }

  handleAdminCsv = async () => {

    const { selectDate, search, sort, selectProduct } = this.state;

    const formatedDate = Moment(selectDate).format('YYYY-MM')
    let product = ''

    if (selectProduct.value) product = '&product=' + selectProduct.value

    this.setState({ loadingExtract: true })
    try {
      const response = await mainApi.get(`admin/extract/sellers/extract?date=${formatedDate}${search}${sort}${product}`)

      this.setState({ csv: response.data.csv }, () => {

        if (this.state.csv) {
          this.createDownloadLink('sellers');
        } else {

          this.setState({
            swalMessageShow: true,
            swalTitle: 'Documento não disponível',
            swalText: 'O documento solicitado nao possui nenhum registro.'
          })
        }
      })

    } catch (err) {
      dev.log(err);
    }
    this.setState({ loadingExtract: false })
  }

  handleSellerCsv = async () => {
    const { selectDate, search, sort, selectProduct } = this.state;

    const formatedDate = Moment(selectDate).format('YYYY-MM')

    let responseResult = false
    let product = ''
    if (selectProduct.value) product = '&product=' + selectProduct.value

    try {
      const response = await mainApi.get(
        `/corp/extract/csv/sellers?date=${formatedDate}${search}${sort}${product}`
      );

      this.setState({ csv: response.data.csv }, () => {
        if (this.state.csv) {
          this.createDownloadLink('sellers');
        } else {
          this.setState({
            swalMessageShow: true,
            swalTitle: 'Documento não disponível',
            swalText: 'O documento solicitado nao possui nenhum registro.'
          })
        }
      })

      responseResult = true
    } catch (err) {
      responseResult = false
      console.log(err);
    }

    if (responseResult) {
      var eventDetail = { "detail": { "button": "Click:BP:IndiqueOPan:BaixarExtratoVendedores" } };
      var interactionClick = new CustomEvent("interactionClick", eventDetail);
      document.body.dispatchEvent(interactionClick);
    }
  }

  createDownloadLink = (name) => {
    const { csv, selectDate } = this.state

    let file = "text/CSV;charset=utf-8,%EF%BB%BF" + encodeURI(csv);
    let filename = `${name}_${Moment(selectDate).format('YYYY-MM')}`;

    let a = document.createElement('a');

    a.href = "data:" + file;
    a.download = filename + ".csv";

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    this.setState({ csv: '' })
  }


  clearStates = () => {
    this.setState({
      total: 0,
      totalCount: 0,
      totalCommission: 0,
      csv: '',
      sort: '',
      agencies: [],
      page: 1,
      limit: 10
    })
  }

  getProducts = async () => {
    const { userData } = this.state;

    let endpoint = "";

    if (["master", "submaster"].includes(userData.level))
      endpoint = "/master/products";
    else if (["corporation", "subcorp"].includes(userData.level))
      endpoint = `/corporation/${userData.id}/products`
    else endpoint = "/products";

    try {
      const response = await mainApi.get(endpoint);
      const products = endpoint == "/master/products" || endpoint == "/products" ? response.data.products : response.data.data.products;

      const productsToSelect = products.map((product) => ({
        value: product._id || product.id,
        label: product.name,
      }));

      if (productsToSelect.length) {
        this.setState({
          products: productsToSelect,
          selectProduct: productsToSelect[0]
        });
      }
    } catch (err) {
      if (err.response) console.log(err.response.data);
    }

    this.handleStatsCard()
    this.handleAgencyList()
  };

  componentDidMount = async () => {
    const userData = await getUserData(this.props.history);

    this.setState({
      userData,
      ISADMIN: userData.level === 'administrator'
    })

    window.digitalData = {
      "site": {
        "nome": "Banco Pan"
      },
      "page": {
        "secao": "IndiqueoPan",
        "estadoLogin": "LG",
        "nome": "BP:IndiqueOPan:Extrato",
      },
      "user": {
        "crmid": userData.id,
        "tipo": userData.brType
      }
    }

    var evt = new CustomEvent('event-view-start');
    document.body.dispatchEvent(evt);

    this.getProducts()

    const { routeKey } = this.props

    // introSystem(routeKey)
  }

  changeSelectProduct = async (event) => {
    this.setState(
      { selectProduct: event }, () => {
        this.handleStatsCard()
        this.handleAgencyList()
      }
    );
  };

  render() {
    const { selectDate,
      totalCommission,
      total,
      totalCount,
      loading,
      agencies,
      swalMessageShow,
      swalTitle,
      swalText,
      userData,
      selectProduct,
      ISADMIN,
      loadingExtract
    } = this.state

    const isConta = selectProduct && selectProduct.value == contaId

    const tableOptions = {
      elevation: 0,
      filterType: "dropdown",
      count: this.state.totalDocs,
      responsive: "scrollMaxHeight",
      serverSide: true,
      pagination: true,
      selectableRows: false,
      viewColumns: false,
      download: false,
      print: false,
      search: true,
      filter: false,
      rowsPerPage: this.state.limit,
      rowsPerPageOptions: [10, 15, 30, 50],
      onColumnSortChange: (value, type) => {
        const hasPlus = 'descending' === type ? '-' : ''
        this.setState({ sort: `&sort=${hasPlus}${value}` }, () => this.handleAgencyList())
      },
      onSearchChange: (text) => {
        if (text?.length <= 0 || text?.length >= 4)
          this.setState({ search: `&name=${text}` }, () => this.handleAgencyList())
      },
      onSearchClose: () => {
        this.setState({ search: '' }, () => this.handleAgencyList())
      },
      onTableChange: (action, tableState) => {
        switch (action) {
          case "changePage":
            this.setState({ page: tableState.page + 1 }, () => {
              this.handleAgencyList()
            })
            break;
          case "changeRowsPerPage":
            this.setState({ limit: tableState.rowsPerPage, page: 0 }, () => {
              this.handleAgencyList()
            })
            break;
          default: break;
        }
      },
      textLabels: {
        body: {
          noMatch: "Desculpe, nenhum dado corresponde a pesquisa"
        },
        pagination: {
          next: "Próxima Página",
          previous: "Pagina Anterior",
          rowsPerPage: "Linhas por página:",
          displayRows: "de"
        },
        toolbar: {
          search: "Procurar",
          downloadCsv: "Download CSV",
          print: "Imprimir",
          viewColumns: "Mostrar Colunas",
          filterTable: "Filtrar Tabela"
        },
        filter: {
          title: "FILTROS",
          reset: "limpar",
          all: "Todos"
        },
        viewColumns: {
          title: "Mostrar Colunas"
        }
      },
    };

    const DatePickerInput = ({ value, onClick }) => (
      <button className="datepicker-input-custom" onClick={onClick}>
        {value}
      </button>
    );

    return (
      <>
        {loading && <LoadingCover />}

        <Dialog
          type={'warning'}
          show={swalMessageShow}
          title={swalTitle || ""}
          onConfirm={() => this.setState({ swalMessageShow: false })}
          text={swalText}
        />

        <LoadingAlert
          show={loadingExtract}
        />
        <div className="content">
          <Grid fluid style={{ paddingRight: 15 }}>
            <Row style={{ marginBottom: '10px' }}>
              <Col className="form-group" md={4} lg={4}>
                <Select
                  className="custom-select"
                  placeholder="Selecione um Produto"
                  options={this.state.products}
                  value={selectProduct}
                  onChange={(event) => this.changeSelectProduct(event)}
                />
              </Col>

              <Col className="form-group" md={4} lg={3}>
                <DatePicker
                  className="datepicker-theThird"
                  dateFormat="MM/yyyy"
                  locale="pt-BR"
                  customInput={
                    <div className="div-datepicker">

                      <div className="col-6">
                        <span className="placeholder-datepicker">
                          {selectDate ? "Mês selecionado:" : "Selecione um mês"}
                        </span>
                      </div>

                      <div className="col-3">
                        <p className="date-datepicker">
                          {selectDate ? getMonthAndYear(selectDate) : ""}
                        </p>
                      </div>

                      <div
                        className="col-3"
                        style={{ alignSelf: "center" }}
                        // onMouseEnter={(event) => this.handleMouse(event)}
                        onMouseLeave={() =>
                          this.setState({ openPopper: false })
                        }
                      >
                        {selectDate ? (
                          <></>
                        ) : (
                            <MahoeIcon
                              aria-describedby={"iconTooltip"}
                              name="info"
                              size="23px"
                              fill="#039BE5"
                            />
                          )}
                      </div>
                    </div>
                  }
                  selected={selectDate}
                  showMonthYearPicker
                  onChange={date => {
                    this.setState({ selectDate: date }, () => {
                      this.clearStates()
                      this.handleStatsCard()
                      this.handleAgencyList()
                    })
                  }}
                  minDate={new Date('12-12-2019')}
                  maxDate={new Date()}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={4} md={4} sm={12}>
                <StatsCard
                  bigIcon={
                    <MahoeIcon
                      name="coins"
                      size="25px"
                      fill="rgb(128, 210, 99)"
                    />
                  }
                  id="data-tour-extract-corporation-total-commision"
                  statsText={loading ? 'n/a' : (total === 0 ? "R$ 0,00" : numToBRL(total))}
                  statsValue={ISADMIN ? `Bonificação ${userData.hasManager ? 'parceiro.' : 'loja.'}` : "Total vendido"}
                  statsIcon={<i className="fa fa-calendar-o" />}
                  statsIconText="Atual"
                />
              </Col>

              <Col lg={4} md={4} sm={12}>
                <StatsCard
                  bigIcon={
                    <MahoeIcon
                      name="coins"
                      size="25px"
                      fill="rgb(128, 210, 99)"
                    />
                  }
                  id="data-tour-extract-corporation-total-agencie"
                  statsText={loading ? 'n/a' : (totalCommission === 0 ? "R$ 0,00" : numToBRL(totalCommission))}
                  statsValue={ISADMIN ? "Total bonificação vendedores" : "Total bonificado lojas"}
                  statsIcon={<i className="fa fa-calendar-o" />}
                  statsIconText="Atual"
                />
              </Col>

              <Col lg={4} md={4} sm={12}>
                <StatsCard
                  bigIcon={
                    <MahoeIcon
                      name="contract"
                      size="25px"
                      fill="rgb(128, 210, 99)"
                    />
                  }
                  id="data-tour-extract-corporation-total-sells"
                  statsText={loading ? 'n/a' : totalCount}
                  statsValue={isConta ? "Quantidade de contas abertas" : "Quantidade de propostas aprovadas"}
                  statsIcon={<i className="fa fa-calendar-o" />}
                  statsIconText="Atual"
                />
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={12} sm={12}>
                <p style={{ marginLeft: '10px' }}><strong>Atenção:</strong> ao baixar e abrir o extrato, separe apenas por <strong style={{ fontSize: '25px' }}>;</strong> (ponto-e-vírgula) para realizar a leitura dos dados corretamente.</p>
                <div className="extract-list">
                  <div className="extract-donwload-container">

                    <p className="mb-0" style={{ flex: 1, fontSize: 18 }}>Dados referentes ao produto <em className="bold">{selectProduct.label}</em>  no mês de <em className="bold">{translateMonths(Moment(selectDate).format('MMMM'))}</em></p>

                    {ISADMIN ?
                      <button
                        className="btn btn-fill btn-mlm btn-mlm-sm btn-small-extract"
                        onClick={() => {
                          this.handleAdminCsv();
                        }}
                      >
                        Baixar extrato vendedores
                    </button>
                      :
                      <>
                        <button
                          id="data-tour-extract-corporation-button-agencie"
                          className="btn btn-fill btn-mlm btn-mlm-sm btn-small-extract"
                          onClick={() => {
                            this.handleAgencyCsv();
                          }}
                        >
                          Baixar extrato por lojas
                      </button>

                        {(userData.hasManager) ?
                          <></>
                          :
                          <button
                            id="data-tour-extract-corporation-button-affiliate"
                            onClick={() => {
                              this.handleSellerCsv()
                            }}
                            className="btn btn-fill btn-mlm btn-mlm-sm btn-small-extract"
                          >
                            Baixar extrato por vendedores
                        </button>
                        }
                      </>
                    }


                  </div>

                  <MuiThemeProvider theme={getMuiTheme}>
                    <MUIDataTable
                      columns={ISADMIN ? adminHeader(isConta, userData.hasManager) : agenciesHeader(isConta)}
                      data={agencies}
                      options={tableOptions}
                    />
                  </MuiThemeProvider>
                </div>
              </Col>
            </Row>
          </Grid>
        </div>
      </>
    )
  }
}

export default Extract;