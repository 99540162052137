import React, { Component } from "react";
import "assets/css/styles.css";

import config from 'utils/serverVars';

import logoP from "assets/img/pan-logo-p.png";
import logo from "assets/img/pan_white_logo.png";

import EmailConfirmation from "components/EmailConfirmation";
import clubeAmigoBanner from 'assets/img/clubeAmigo/clubeAmigoBanner.png'
import TermsModal from "components/TermsModal/TermsModal";
import { ISClubeAmigo } from "utils/environment";
import campanhaWhatsappBanner from 'assets/img/pan_cover_campanha_whatsapp.png'

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banner: config.IMG_PF,
      loginType: "cnpj"
    };
  }

  render() {
    let banner, panLogo;
    let path = this.props.location.pathname.split("/")[1];
    const campanha = localStorage.getItem('@uxtech-mlm/campanha')
    const IS_WHATSAPP = campanha === "whatsapp"
    if (ISClubeAmigo) {
      banner = clubeAmigoBanner
      panLogo = path === "cnpj" ? config.MINIMAL_LOGO : config.LOGO_ALT;
    }
    else if (path === "cnpj") {
      banner = config.IMG_PJ;
      panLogo = logoP;
    } else if (path === "cpf") {
      banner = config.IMG_PF;
      panLogo = logo;
    } else {
      banner = config.IMG_PF;
      panLogo = logo;
      path = ''
    }
    if(IS_WHATSAPP){
      banner = campanhaWhatsappBanner
    }


    return (
      <div className="grid-parent">
        <div className={config.CLIENT === 'pan' ? 'kv-informative' : 'kv-informative agx-bg'}>
          {config.CLIENT === 'pan' && <>
            <img className="login-pan-logo" alt="logo do banco pan" src={panLogo}></img>
            <div className="clubeAmigo_continer">
              <img className="people" alt="banner do banco pan" src={banner}></img>
              {ISClubeAmigo && <TermsModal classNameLink={'clubeAmigo_terms'} />}
            </div>
          </>}
        </div>
        <div className="kv-form-login">
          <EmailConfirmation
            {...this.props}
            path={path}
          />
        </div>
      </div>
    );
  }
}

export default ResetPassword;
