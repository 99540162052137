import React, { useLayoutEffect, useState, useEffect, useRef, useMemo, memo } from 'react'

import TableMui from 'components/TableMui'

import mainApi from 'services/mainApi'
import { columns, options } from './tableConfig'

import InputMuiFilter from 'components/InputMuiFilter'
import { LoadingAlert } from 'components/Alert/CustomAlert'

import RegionMap from 'components/RegionMap'
import StatsCardDash from 'components/StatusCardDash'
import formater from 'utils/formater'

import { formatToBr, monthYear } from 'utils/formaterDate'
import swal from 'components/Alert/alert'

import { Grid, MapItem, TableItem, RegionsIten, Grid2, Row, Description, Search, SecondSearch } from './styled'
import LinkButton from "components/LinkButton"
import MahoeIcons from "components/MahoeIcons"

import getUserData from "utils/checkUser"

// date-picker
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptbr from 'date-fns/locale/pt-BR'
import moment from 'moment'
import { CircularProgress } from '@material-ui/core'
registerLocale('pt-BR', ptbr)

const startsRegions = [
  { name: "norte", converted: 0, links: 0, paid: 0, agencies: 0, color: '#408f70', svg: "https://servicodados.ibge.gov.br/api/v2/malhas/1" },
  { name: "nordeste", converted: 0, links: 0, paid: 0, agencies: 0, color: '#fe0605', svg: 'https://servicodados.ibge.gov.br/api/v2/malhas/2' },
  { name: "centro-oeste", converted: 0, links: 0, paid: 0, agencies: 0, agencies: 0, color: '#ff8b4d', svg: 'https://servicodados.ibge.gov.br/api/v2/malhas/5' },
  { name: "sudeste", converted: 0, links: 0, paid: 0, agencies: 0, color: '#B2B500', svg: 'https://servicodados.ibge.gov.br/api/v2/malhas/3' },
  { name: "sul", converted: 0, links: 0, paid: 0, agencies: 0, color: '#9091c9', svg: 'https://servicodados.ibge.gov.br/api/v2/malhas/4' }
]

const RegionsCards = ({ regions, setCorpId, setRegion, setState, region, userlevel, setAgencies }) => {

  if (!regions.length) return (<></>)

  return regions.map(item => (
    <StatsCardDash
      key={item.name}
      active={region === formater.camalize(item.name)}
      onClick={() => {
        if(!['corporation', 'subcorp'].includes(userlevel)){
          setCorpId('')
        }
        if(region === formater.camalize(item.name)) {
          setRegion('')
        } else {
          setRegion(formater.camalize(item.name))
        }
        setState('')
        setAgencies([])
      }}
      map={item.svg}
      color={item.color}
      statsText={item.name}
      statsValue={[
        {
          name: "Links gerados",
          value: item.links
        },
        {
          name: "Contas abertas",
          value: item.converted
        },
        {
          name: 'Parceiros',
          value: item.agencies
        }
      ]}
    />
  ))
}

function ManagerAgencyList(props) {
  const userLevel = localStorage.getItem("@uxtech-mlm/UserLevel");
  const hasManager = localStorage.getItem("@uxtech-mlm/hasManager");

  const [ agencies, setAgencies ] = useState([])
  const [ totalDocs, setTotalDocs ] = useState(0)
  const [ page, setPage ] = useState(1)
  const [ sort, setSort ] = useState('name')
  const [ limit ] = useState(10)
  const [ name, setName ] = useState(null)
  const [ loading, setLoading ] = useState(false)
  const [ loadingTable, setLoadingTable ] = useState(false)
  const [ state, setState ] = useState('')
  const [ corpId, setCorpId ] = useState('')
  const [corpName, setCorpName] = useState('')
  const [ startDate, setStartDate ] = useState(new Date())
  const [ region, setRegion ] = useState('')
  const [ regions, setRegions ] = useState(startsRegions)
  const [userData, setUserData] = useState({})

  const [pagination, setPagination] = useState({});
  const month = moment(startDate).format('MMMM')
  const tableRef = useRef(null);

  const makeQuery = (download = false) => {

    let query = ''

    query += `?page=${page}`
    if (limit) query += `&limit=${limit}`
    if (sort) query += `&sort=${sort}`
    if (name) query += `&name=${name}`
    if (state) query += `&uf=${state}`
    if (corpId) query += `&corpId=${corpId}&option=agency`
    if (region) query += `&region=${region}`
    if (startDate) query += `&date=${monthYear(startDate)}`
    if (['corporation', 'subcorp'].includes(userLevel)) query += '&option=agency'
    if (download) query += `&download=true`

    return query
  }

  const changeState = async (value) => {
    setRegion('')
    setCorpId('')
    setState(value)
  }

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

  const downloadCsv = async () => {
    const date = formatToBr(new Date())

    const query = makeQuery(true)
    const routePath = corpId ? 'agency' : 'corporation'

    setLoading(true)
    try {
      const response = await mainApi.get(`managers/${routePath}${query}`)
      const data = response.data.data[routePath]

      if (data) {
        let file = "text/CSV;charset=utf-8,%EF%BB%BF" + encodeURI(data);
        let filename = `quantidade_contas_${date}`;

        createDownloadLink(file, filename);
      } else {
        swal.basic("warning", "Documento não disponível", "O documento solicitado nao possui nenhum registro.")
      }
    } catch (err) {
      swal.basic("warning", "Algo deu errado", err.response.message)
    }
    setLoading(false)
  }

  const createDownloadLink = (file, filename) => {
    let a = document.createElement('a');
    a.href = "data:" + file;
    a.download = filename + ".csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  const getAgencies = async () => {
    let query = makeQuery()
    const routePath = corpId ? 'agency' : 'corporation'
    if (!region && !state) return
    setLoadingTable(true)

    try {
      const route = `managers/${routePath}${query}`
      const response = await mainApi.get(route)
      const data = response.data.data[routePath]

      setAgencies(data.docs)
      setTotalDocs(data.totalDocs)

    } catch (err) {
      console.log(err)
    }
    setLoadingTable(false)

    if(tableRef.current) scrollToRef(tableRef)
  }

  useLayoutEffect(() => {
    getAgencies()

  }, [ page, sort, limit, state, corpId, region, startDate ])

  useEffect(() => {
    const searchName = () => {
      if (name === null) return
      if (name.length === 0) getAgencies()
      if (name.length > 2) getAgencies()
    }

    searchName()
  }, [name])

  const getRegions = async () => {
    let query = ''
    if (startDate) query = `?date=${monthYear(startDate)}`

    setLoading(true)
    try {
      const response = await mainApi.get(`map/manager${query}`)
      const regionsResponse = response.data.data.totals

      setRegions(() => {
        let formatRegions = startsRegions

        return formatRegions.map(
          region => ({...region, ...regionsResponse.find(
            responseRegion => responseRegion.name === region.name)}
          )
        )
      })
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  useEffect(() => {
    getRegions()

  }, [ startDate ])

  function onSortChange(value, type) {
    const hasPlus = 'descending' === type ? '-' : ''

    setSort(hasPlus + value)
    setPage(1)
  }

  useEffect(()=>{
    if(corpId && agencies){
      const corpName = (agencies.find((corp) => corp._id === corpId))
      if(corpName) setCorpName (corpName.name)
    }
  },[corpId])

  const UserData = async () => {
    const userData = await getUserData(props.history);
    if(['corporation', 'subcorp'].includes(userData.level)){
      setCorpId(userData.id)
    }
    setUserData(userData)
  };

  useEffect(() => {
    UserData()
  }, [])


  const finalColumns = useMemo(() => columns({corpId, setCorpId, isCorp: ['corporation', 'subcorp'].includes(userLevel), hasManager, setAgencies, loadingTable}), [ corpId ])
  const finalOptions = useMemo(() => options({ download: true, limit, onSortChange, setPage, downloadCsv, page, count: totalDocs }), [ page, sort, totalDocs ])

  return (
    <div className="content">
      <Grid>
        <LoadingAlert show={loading} title="Carregando..." />

        <Row>
          <Description>Escolha uma região ou um estado no mapa para liberar a tabela com os dados de contas abertas.</Description>
        </Row>
        <Search>
          <DatePicker
            className="date-picker map-date-picker"
            placeholderText="Filtrar por mês"
            locale='pt-BR'
            showYearDropdown
            dateFormat="MM/yyyy"
            showMonthYearPicker
            selected={startDate}
            onChange={(date, event) => setStartDate(date, event)}
            startDate={startDate}
            maxDate={new Date()}
          />
          <h3 style={{textTransform: 'capitalize'}}><strong>{month ? month : ''}</strong></h3>
        </Search>

        <RegionsIten>
          <Grid2>
            <RegionsCards
              regions={regions}
              region={region}
              setCorpId={setCorpId}
              setRegion={setRegion}
              setState={setState}
              userlevel={userLevel}
              setAgencies={setAgencies}
            />
          </Grid2>
        </RegionsIten>

        <MapItem>
          {state !== '' && (
            <button
              className="bottom-rigth btn"
              style={{marginTop: 5, maxWidth: '90%', overflow: 'hidden', background: '#414141', color: 'white'}}
              onClick={() => setState('')}
            >
              Remover seleção
            </button>
          )}
          <RegionMap changeState={changeState} selectedState={state} />
        </MapItem>

        {(region || state) && (
          <>
            <Search>
              <InputMuiFilter
                className="input-background"
                label="Pesquisar por nome"
                onChange={event => setName(event.target.value)}
              />
            </Search>

            <div ref={tableRef} />
            <TableItem style={{maxWidth: '100%', overflowX: 'auto', boxShadow: 'none', border: '1px solid #E0E0E0', borderRadius: '16px'}}>
              <TableMui
                title={
                  <>
                    {corpId || ['corporation', 'subcorp'].includes(userLevel) ?
                    <>
                      {
                        ['corporation', 'subcorp'].includes(userLevel) ? '' : (
                        <LinkButton
                          style={{marginTop: 10}}
                          onClick={() => {
                            setCorpId('')
                            setAgencies([])
                            setPage(1)
                          }}
                          className='link-style mr-1'>
                            <MahoeIcons name="arrow-back" fill="#0277bd" size={16} style={{margin: '-4px 2px'}} />
                            Voltar
                        </LinkButton>
                        )
                      }
                      <h3 style={{textTransform: 'capitalize', margin: '10px 0'}}>
                        <strong> {month ? month : ''}</strong>
                        {loadingTable && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}
                      </h3>
                      <p style={{ fontSize: 20 }}>{corpName}</p>
                      <span style={{ fontSize: 20 }}>{`Parceiros encontrados: ${totalDocs}`}</span>
                    </>
                    :
                    <>
                    <h3 style={{textTransform: 'capitalize', margin: '10px 0'}}>
                      <strong>{month ? month : ''} - {region || state} </strong>
                      {loadingTable && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}
                    </h3>
                    <p>Corporações encontradas: {totalDocs}</p>
                    </>
                    }

                  </>
                }
                data={agencies}
                options={finalOptions}
                columns={finalColumns}
              />
            </TableItem>
          </>
        )}

      </Grid>
    </div>
  )
}

export default ManagerAgencyList