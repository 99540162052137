import React from 'react'

function Switch ({ values, selectedSwitch, setSelectedSwitch, disabled }) {
  return (
    <div className={`switch ${disabled ? 'switch-disabled' : ''}`}>
      {values.map((item) =>
        <button
          className={`option ${item.value === selectedSwitch.value ? 'selected' : ''} ${disabled ? 'switch-disabled' : ''}`}
          key={item.label}
          onClick={() => setSelectedSwitch(item)}
          disabled={disabled}
        >
          {item.label}
        </button>
      )}
    </div>
  )
}

export default Switch
