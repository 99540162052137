import styled from 'styled-components'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 350px 350px 350px;
  margin-bottom: 1.5em;
`

export const Title = styled.span`
  color: #414141;
  font-size: 1.1rem;
`


export const Content = styled.span`
  color: #212121;
  font-size: 1.5rem;
  text-align: left;
`

export const Card = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
`

export const Legend = styled.span`
  color: #A1A1A8;
  font-size: .9rem;
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Label = styled.p`
  text-align: left;
  width: 100%;
  margin-bottom: 0;
  font-weight: 500;
`

export const FuidCentered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`