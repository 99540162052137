import React from 'react'
import DatePicker from "react-datepicker";
import { ISClubeAmigo } from './environment';

const DateButton =  ({ value, onClick }) => (
  <div className='datepicker-campaign' >
    <p>Mês Selecionado:</p>
    <button className="datepicker-input-custom campaign" onClick={onClick}>
      {value}
    </button>
  </div>
)

const MuiDataTableOptions = {
  sellerList: ({ pagination, downloadCsv, changePage, limitRows, onSortChange }) => {
        return {
          elevation: 0,
          selectableRows: false,
          rowsPerPage: 10,
          filterType: "dropdown",
          responsive: "scrollMaxHeight",
          count: pagination.totalDocs,
          onColumnSortChange: onSortChange,
          serverSide: true,
          pagination: true,
          download: false,
          viewColumns: false,
          print: false,
          filter: false,
          search: false,
          rowsPerPageOptions: [10],
          textLabels: {
            body: {
              noMatch: "Desculpe, nenhum dado corresponde a pesquisa"
            },
            pagination: {
              next: "Próxima Página",
              previous: "Pagina Anterior",
              rowsPerPage: "Linhas por página:",
              displayRows: "de"
            },
            toolbar: {
              search: "Procurar",
              downloadCsv: "Download CSV",
              print: "Imprimir",
              viewColumns: "Mostrar Colunas",
              filterTable: "Filtrar Tabela"
            },
            filter: {
              title: "FILTROS",
              reset: "limpar",
              all: "Todos"
            },
            viewColumns: {
              title: "Mostrar Colunas"
            }
          },

          onTableChange: (action, tableState) => {
            switch (action) {
              case 'changePage':
                changePage(tableState.page)
                break;
              case 'propsUpdate':
                // if(pagination.page !== tableState.page + 1) tableState.page = 0;
                break;
              default:
                break;
            }
          },
          onChangeRowsPerPage: (number) => {
            limitRows(number)
          },
          onDownload: () => {
            downloadCsv()
            return false;
          }
        }
  },

  campaignLink: ({ pagination, downloadCsv, changePage, limitRows, search, selectedMonth, onMonthSelect, corporation, onSortChange }) => {
    return {
      elevation: 0,
      size: "small",
      selectableRows: false,
      rowsPerPage: 50,
      filterType: "dropdown",
      responsive: "scrollMaxHeight",
      count: pagination.totalDocs,
      serverSide: true,
      pagination: true,
      download: true,
      viewColumns: true,
      print: false,
      filter: false,
      search: true,
      onColumnSortChange: onSortChange,
      rowsPerPageOptions: [10],
      customSearch: '',
      textLabels: {
        body: {
          noMatch: "Desculpe, nenhum dado corresponde a pesquisa"
        },
        pagination: {
          next: "Próxima Página",
          previous: "Pagina Anterior",
          rowsPerPage: "Linhas por página:",
          displayRows: "de"
        },
        toolbar: {
          search: "Procurar",
          downloadCsv: "Download CSV",
          print: "Imprimir",
          viewColumns: "Mostrar Colunas",
          filterTable: "Filtrar Tabela"
        },
        filter: {
          title: "FILTROS",
          reset: "limpar",
          all: "Todos"
        },
        viewColumns: {
          title: "Mostrar Colunas"
        }
      },

      onTableChange: (action, tableState) => {
        switch (action) {
          case 'changePage':
            changePage(tableState.page)
            break;
          case 'propsUpdate':
            // if(pagination.page !== tableState.page + 1) tableState.page = 0;
            break;
          case 'search':
            if (tableState?.searchText?.length > 3 || !tableState?.searchText) {
              search(tableState.searchText)
            }
            break;
          default:
            break;
        }
      },
      onChangeRowsPerPage: (number) => {
        limitRows(number)
      },
      onDownload: () => {
        downloadCsv()
        return false;
      },
      // customToolbar: () => {
      //   return (
      //     <DatePicker
      //       wrapperClassName="right-side-date-picker"
      //       onChange={onMonthSelect}
      //       minDate={new Date('12-12-2019')}
      //       maxDate={new Date()}
      //       dateFormat="MM/yyyy"
      //       className='Date-picker'
      //       locale="pt-BR"
      //       selected={selectedMonth}
      //       showMonthYearPicker
      //       customInput={<DateButton/>}
      //     />
      //   );
      // },
    }
  },

  paymentCorp: ({ customToolbar, customFilter, downloadCsv, onSortChange }) => {
    return {
      elevation: 0,
      size: "small",
      filterType: "dropdown",
      download: false,
      print: !ISClubeAmigo,
      responsive: "scrollMaxHeight",
      selectableRows: "none",
      rowsPerPage: 25,
      rowsPerPageOptions: [25, 50, 75],
      confirmFilters: true,
      serverSide: true,
      onColumnSortChange: onSortChange,
      // onTableChange: (action, tableState) => {
      //   switch (action) {
      //     case 'filterChange':
      //       console.log(tableState)
      //     default:
      //       break
      //   }
      // },
      customToolbar: () => customToolbar(),
      onFilterChange: (type, filter) => {
        customFilter(type, filter)
        return false
      },
      downloadOptions: {
        filename: "Relatorio.csv",
        filterOptions: {
          useDisplayedColumnsOnly: true
        }
      },
      onDownload: () => {
        downloadCsv()
        return false
      },
      textLabels: {
        body: {
          noMatch: "Nenhum dado encontrado."
        },
        pagination: {
          next: "Próxima Página",
          previous: "Pagina Anterior",
          rowsPerPage: "Linhas por página:",
          displayRows: "de"
        },
        toolbar: {
          search: "Procurar",
          downloadCsv: "Download CSV",
          print: "Imprimir",
          viewColumns: "Mostrar Colunas",
          filterTable: "Filtrar Tabela"
        },
        filter: {
          title: "FILTROS",
          reset: "limpar",
          all: "Todos"
        },
        viewColumns: {
          title: "Mostrar Colunas"
        }
      }
    }
  },

  voucherReport: () => ({
    elevation: 0,
    size: "small",
    filterType: "dropdown",
    download: false,
    responsive: "scrollMaxHeight",
    selectableRows: "none",
    pagination: false,
    rowsPerPage: 25,
    customToolbar: () => undefined,
    rowsPerPageOptions: [25, 50, 75],
    confirmFilters: true,
    serverSide: true,
    customFilter: undefined,
    print: false,
    filter: false,
    search: false,
    viewColumns: false,
    onFilterChange: (type, filter) => {

      return false
    },
    downloadOptions: {

    },
    textLabels: {
      body: {
        noMatch: "Nenhum dado encontrado."
      },
      pagination: {
        next: "Próxima Página",
        previous: "Pagina Anterior",
        rowsPerPage: "Linhas por página:",
        displayRows: "de"
      },
      toolbar: {
      },
      filter: {
        title: "FILTROS",
        reset: "limpar",
        all: "Todos"
      },
      viewColumns: {
        title: "Mostrar Colunas"
      }
    }
  })
}

export default MuiDataTableOptions