import React, { useState, useRef, useEffect } from "react";
import { TextField } from "@material-ui/core";
import { Grid, Row, Col } from "react-bootstrap";
import mainApi from "services/mainApi";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Card from "components/Card/Card";
import swal from 'components/Alert/alert'

import getUserData from "utils/checkUser.js"
import spinner from "assets/img/spinner.gif";


const SearchMenagers = (Props) => {
  const [code, setCode] = useState('');
  const [storeInfo, setStoreInfo] = useState();
  const [loading, setLoading] = useState(false)
  const [userData, setUserData] = useState({})

  const inputRef = useRef(null)

  async function UserData() {
    const userData = await getUserData(Props.history);
    setUserData(userData)
  };

  async function handleCode() {
    if (!code) return
    setStoreInfo()
    setLoading(true)
    try {
      const response = await mainApi.get(`/managers/findbyagencycode/?code=${code}`);
      let storeInfo
      if (!response) {
        swal.basic(
          "error",
          "Não foi possível se comunicar com o servidor, tente novamente mais tarde."
        );
      } else {
        storeInfo = response.data?.data
      }

      if (!storeInfo)
        swal.basic(
          "warning",
          "Ateção",
          "O loja pesquisada não foi encontrada."
        )
      else {
        setStoreInfo(storeInfo)
        inputRef.current.value = ''
      }

      setLoading(false)
    } catch (err) {
      setLoading(false)
      if (err?.response?.data?.message) {
        swal.basic(
          "warning",
          "Ateção",
          err.response.data.message
        )
      }
    }
  }

  useEffect(() => {
    UserData()
  }, [])

  return (
    <div className="content card" style={{ margin: 10, }}>
      <div className="container-fluid ">
        <Grid fluid style={{ paddingInline: "15px" }}>
          <Row className="filters">
            <Col lg={6}>
              <TextField
                fullWidth
                label="Digite o código da loja"
                color="primary"
                variant="outlined"
                inputRef={inputRef}
                onChange={(e) => {
                  setCode(e.target.value);
                }}
                style={{ backgroundColor: "white", paddingBottom: 10 }}
              />
            </Col>
            <Col md={2}>
              <button
                className="btn btn-fill btn-mlm"
                style={{ width: "100%" }}
                onClick={handleCode}
              >
                Pesquisar
              </button>
            </Col>
          </Row>
          {
            loading &&
            <Row>
              <Col style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
                <img src={spinner} alt="" />
              </Col>
            </Row>
          }
          {
            storeInfo &&
            <Row>
              <Col lg={6} md={6} sm={12} xs={12}>
                <Card
                  style={{ marginTop: 30 }}
                  title={storeInfo['Código da Loja'] ? `(${storeInfo['Código da Loja']}) ${storeInfo.Loja}` :  storeInfo.Loja}
                  hCenter={true}
                  content={
                    <>
                      <Table >
                        <TableBody>
                          {Object.entries(storeInfo)?.map(([key, value]) => (
                            <TableRow key={key}>
                              <TableCell style={{ fontWeight: 'bold' }}>{key}</TableCell>
                                <TableCell>{value}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </>
                  }
                />
              </Col>
            </Row>
          }
        </Grid>
      </div >
    </div >
  );
};

export default SearchMenagers;
