import { translateProduct } from "utils/translate"

export const graphOptions = {
  plugins: {
    datalabels: {
      backgroundColor: function (context) {
        return context.dataset.backgroundColor
      },      
      borderRadius: 4,
      color: "white",
      font: {
        weight: "bold",
      },
      formatter: Math.round,
    },    
  }  
}

export const getRealProductsNames = (products) => {
  let newProducts = []
  while (products.length > 0) {
    const product = products.pop()
    newProducts.push(translateProduct(product))
  }
  return newProducts.reverse()
}

export const getColors = (keys) => {
  let colors = []
  while (keys.length > 0) {
    const key = keys.pop()
    switch (key) {
      case "cartao":
        colors.push("rgba(255, 99, 132, 0.8)")
        break
      case "novo":
        colors.push("rgba(54, 162, 235, 0.8)")
        break
      case "saque":
        colors.push("rgba(255, 206, 86, 0.8)")
        break
      case "port":
        colors.push("rgba(75, 192, 192, 0.8)")
        break
      case "refin":
        colors.push("rgba(153, 102, 255, 0.8)")
        break
      default:
        colors.push("rgba(255, 159, 64, 0.8)")
        break
    }
  }
  return colors.reverse()
}

export const weeklyInitialState = {
  labels: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
  datasets: [
    {
      data: [],
      backgroundColor: [
        "rgb(3,155,229)",
        "rgb(3,155,229)",
        "rgb(3,155,229)",
        "rgb(3,155,229)",
        "rgb(3,155,229)",
        "rgb(3,155,229)",
        "rgb(3,155,229)",
      ],
      borderColor: "rgba(3,155,229, 0.7)",
      pointStyle: "rectRounded",
      borderWidth: 2,
      label: "Clicks",
      pointRadius: 5,
      fill: false,
    },
  ],
}