import React, { memo } from 'react'
import getMuiTheme from "utils/getMuiTheme";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";

const TableMui = ({ title, data, columns, options }) => {
  return (
    <MuiThemeProvider theme={getMuiTheme}>
      <MUIDataTable
        title={title}
        data={data}
        columns={columns}
        options={options}
      />
    </MuiThemeProvider>
  )
}

export default memo(TableMui)