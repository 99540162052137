import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Row, Col } from "react-bootstrap";
import Card from "components/Card/Card";
import mainApi from "services/mainApi";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import InputMask from "react-input-mask";
import { CopyToClipboard } from "react-copy-to-clipboard";
import QRCode from "qrcode.react";
import getUserData from "utils/checkUser";
import { TextField, FormControlLabel } from "@material-ui/core";
import { toTitleCase } from "utils/formatData";
import Checkbox from '@material-ui/core/Checkbox';
import swal from "sweetalert2";
import getRoutesByLevel from "routes";
import { ISClubeAmigo} from 'utils/environment'

class GenerateLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cpf: "",
      phone: "",
      cep: "",
      email: "",
      whatsapp: false,
      products: [],
      product: "",
      seletedProduct: "",
      productName: "",
      emailMessage: "",
      phoneMessage: "",
      cpfMessage: "",
      cepMessage: "",
      productMessage: "",
      shareWithWhatsApp: "",
      generatedLinkId: "",
      swalMessage: [],
      swalMessageShow: false,
      userData: {},
      loading: false,
      printQR: null,
      width: 200,
      campaignName: '',
      slugName: '',
      department: '',
      campaignMessage: '',
      departmentMessage: '',
      sendEmail: false,
      corporationName: '',
    };

    this.inputStyle = {
      fontFamily: "Montserrat",
      fontSize: 18.9
    };
  }

  getProducts = async () => {
    const { messageHandler } = this.props
    try {
      const response = await mainApi.get("/products");
      let newProducts = [];
      response.data.products.forEach(item => {
        let product = {};
        product.label = item.name;
        product.value = item._id;
        product.text = item.linkText;
        newProducts.push(product);
      });
      this.setState({ products: newProducts });
    } catch (err) {
      if (err.response) {
        messageHandler(
          "error",
          "Não foi possível buscar os produtos, tente novamente mais tarde."
        );
        return;
      }
    }
  };

  onProductChange = product => {
    const { products } = this.state || {products: []}
    const filteredProducts = products.filter(p => p.value === product.value)
    const seletedProduct = filteredProducts[0]?.label || "Error"
    this.setState({ product: product, generatedLink: '', seletedProduct: toTitleCase(seletedProduct) });
  };

  updateDimensions = () => {
    let width = 400;
    let wrapper = null;
    wrapper = document.getElementById('qrcode-wrapper');
    if (wrapper) {
      width = wrapper.clientWidth;
    }
    wrapper = document.getElementById('qrcode-wrapper');
    if (wrapper) {
      width = wrapper.clientWidth;
      this.setState({ width })
    }
  }

  handleTerms = () =>{
    const formData = new FormData();
    formData.append("linkGenerationTerms", true)

    try {
      mainApi.patch("/profile", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
  
      localStorage.setItem("@uxtech-mlm/linkGenerationTerms", true)      
  
    } catch(err) {
      console.log(err)
    }
  }

  componentDidMount = async () => {
    const { agencyId} = this.props
    const userData = await getUserData(this.props.history);
    
    const corporationName = localStorage.getItem('@uxtech-mlm/CorporationSlug')
    this.setState({ corporationName })

    const linkGenerationTerms = localStorage.getItem('@uxtech-mlm/linkGenerationTerms')
    const terms = linkGenerationTerms === 'undefined'? undefined : JSON.parse(linkGenerationTerms)
    if(!terms && !ISClubeAmigo){
      swal.fire({
        icon: "warning",
        title: "ATENÇÃO",
        text: `A divulgação e propagação deste link deve estar em acordo com as normas, diretrizes e regulamentos do Banco PAN, sob pena de bloqueio do link e na recorrência uma maior penalidade poderá ser aplicada.`,
        showCancelButton: true,
        confirmButtonText: "Entendo",
        cancelButtonText: "Quero voltar",
        cancelButtonColor: "#d33",
      }).then((result) => {
        if(result.isConfirmed){
          this.handleTerms()
        }
        if (result.dismiss){
          const routes = getRoutesByLevel(userData)
          this.props.history.push("/admin"+routes[0].path)
        }
      });
    }

    const page = agencyId ? "LinkLoja" : "Admin"
    window.digitalData = {
      "site": {
          "nome": "Banco Pan"
      },
      "page": {
          "secao": "IndiqueoPan",
          "estadoLogin": "LG",
          "nome": `BP:IndiqueOPan:${page}`,
      },
      "user": {
          "crmid": userData.id,
          "tipo": userData.brType
      }
    }

    var evt = new CustomEvent('event-view-start');
    document.body.dispatchEvent(evt);

    window.addEventListener('resize', this.updateDimensions);
    let width = 400;
    let wrapper = null;
    wrapper = document.getElementById('qrcode-wrapper');
    if (wrapper) {
      width = wrapper.clientWidth;
      this.setState({ width })
    }
      this.setState({ userData }, () => {
        this.getProducts();
      })
  };

  isCellphone = string => {
    let phone = string.trim().replace(/\D/g, "");
    let ddd = phone[0] + phone[1];
    let phoneWithoutDDD = phone.substr(2);
    let phoneFirstDigit = phoneWithoutDDD[0];
    let phoneLength = phoneWithoutDDD.length;

    const invalidDDDs = [
      "23",
      "25",
      "26",
      "29",
      "36",
      "39",
      "52",
      "56",
      "57",
      "58",
      "59",
      "72",
      "76",
      "78"
    ];

    if (ddd[0] === "0" || ddd[1] === "0" || invalidDDDs.includes(ddd))
      return false;

    const mobileLength = 9,
      mobileInvalidFirstDigits = ["2", "3", "4", "5"];

    if (phoneLength !== mobileLength) {
      return false;
    }

    if (mobileInvalidFirstDigits.includes(phoneFirstDigit)) {
      return false;
    }

    return true;
  };

  handlePhone = phone => {
    if(!phone) return

    let whatsapp = false;
    whatsapp = this.isCellphone(phone);
    this.setState({ phone, whatsapp });
    if (whatsapp) {
      let shareWithWhatsApp = this.generateWhatsText(phone, this.state.generatedLink);
      this.setState({ shareWithWhatsApp });
    }
  };

  generateWhatsText = (phone, link) => {
    const {products, userData, generatedLinkId} = this.state 
    const {agencyId, campaignLink} = this.props 
    const lastProduct = products.filter(product => product.value === generatedLinkId)[0] || {}

    let username = userData?.name ? 'de ' + userData?.name?.split(' ')[0] : ''
    if(!userData || campaignLink || agencyId){
      username = ""
    }
    const textWithPersonName = lastProduct.text ? lastProduct?.text.replace(':name:', username) : ""

    const linkText = textWithPersonName || `Você recebeu uma indicação para ${lastProduct.label} do Programa de Afiliados Banco PAN. Clique no link para concluir:`

    let rawPhone = phone.replace(/\D/g, "");

    return `https://api.whatsapp.com/send?phone=55${rawPhone}&text=${encodeURIComponent(linkText)} ${link}`
  }

  handleGenerateLink = async () => {
    const { messageHandler, campaignLink, reloadCampaign } = this.props
    const { seletedProduct, slugName } = this.state
    this.setState({
      cpfMessage: "",
      phoneMessage: "",
      cepMessage: "",
      productMessage: "",
      emailMessage: "",
      loading: true
    });

    const { phone, cpf, cep, email, product, campaignName, department, sendEmail } = this.state;
    const { specific, agencyId } = this.props;
    const suffix = specific ? "Espec" : ""
    let responseResult = false

    if (!product) {
      this.setState({
        productMessage: "É necessário selecionar um produto",
        loading: false
      });
    }
    const rawPhone = phone.replace(/\D/g, "");
    const rawCep = cep.replace(/\D/g, "");
    const rawCpf = cpf.replace(/\D/g, "");
    const data = {
      productId: product.value,

    };
    this.setState({generatedLinkId: product.value})

    if (specific) {
      data.cpf = rawCpf;
      data.phone = rawPhone;
      data.cep = rawCep;
      data.email = email ? email.trim() : '';
      data.specific = true;
      data.sendEmail = sendEmail;
    }

    if (agencyId) data.agency = true;   
    if (slugName) data.campaignSlug = slugName;

    if (campaignLink) {
      data.campaignLink = true
      data.campaign = campaignName
      data.department = department 
    }

    try {
      const response = await mainApi.post("/links", data);
      if (specific) {
        let whatsapp = response.data.whatsapp;
        let shareWithWhatsApp = this.generateWhatsText(phone, response.data.link)
        this.setState({
          generatedLink: response.data.url,
          shareWithWhatsApp,
          whatsapp
        });      
        
      } else {
        this.setState({ generatedLink: response.data.url });
      }

      if (campaignLink) {
        reloadCampaign()
      }

      responseResult = true
    } catch (err) {
      this.setState({ loading: false });
      
      if (!err.response) {
        if (err.code === "ECONNABORTED")
          messageHandler("error", "Tempo de espera atingido.");
        else
          messageHandler(
            "error",
            `Não foi possível gerar seu link, tente novamente mais tarde.`
          );

        this.setState({ loading: false });
        return;
      }
      if (err.response.status === 500) {
        messageHandler(
          "error",
          `Não foi possível gerar seu link, tente novamente mais tarde. (código de erro: ${err.response.status})`
        );
        return;
      }
      if (err.response.status > 400 && err.response.status !== 422) {
        let errorMessage = ""
        if (typeof err.response.data === 'string') errorMessage = err.response.data
        if (typeof err.response.data === 'object' && typeof err.response.data.message === 'string') errorMessage = err.response.data.message
        messageHandler("warning", errorMessage);
        return;
      }

      const fields = err.response.data.fields;
      if (!Array.isArray(fields)) {
        return;
      }
      if (!fields[0].name) {
        while (fields.length > 0) {
          let field = fields.pop();
          switch (field) {
            case "phone": {
              this.setState({
                phoneMessage: "O campo Celular é obrigatorio."
              });
              break;
            }
            case "cpf": {
              this.setState({ cpfMessage: "O campo CPF é obrigatorio." });
              break;
            }
            case "cep": {
              this.setState({ cepMessage: "O campo CEP é obrigatorio." });
              break;
            }
            case "email": {
              this.setState({ emailMessage: "O campo Email é obrigatorio." });
              break;
            }
            case 'campaign': {
              this.setState({ campaignMessage: "O campo campanha é obrigatorio." });
              break
            }
            case 'department': {
              this.setState({ departmentMessage: "O campo departamento é obrigatorio." });
              break
            }
            default: {
              break
            }
          }
        }
      } else {
        while (fields.length > 0) {
          let field = fields.pop();
          switch (field.name) {
            case "phone": {
              this.setState({ phoneMessage: field.message });
              break;
            }
            case "cpf": {
              this.setState({ cpfMessage: field.message });
              break;
            }
            case "cep": {
              this.setState({ cepMessage: field.message });
              break;
            }
            case "email": {
              this.setState({ emailMessage: field.message });
              break;
            }
            case 'campaign': {
              this.setState({ campaignMessage: field.message });
              break
            }
            case 'department': {
              this.setState({ departmentMessage: field.message });
              break
            }
            default:
              break;
          }
        }
      }

      responseResult = false
    }
    this.handlePhone(rawPhone)

    if(responseResult) {
      var eventDetail = {"detail": {"button": `Click:BP:IndiqueOPan:GerarLink${suffix}`, "product": seletedProduct }} 
      var interactionClick = new CustomEvent("interactionClick", eventDetail)
      document.body.dispatchEvent(interactionClick);
    }


    this.setState({ loading: false });
  };

  copyToClipboardAdobeAction = () => {
    const { seletedProduct } = this.state
    const { specific } = this.props;
    const suffix = specific ? "Espec" : ""

    var eventDetail = {"detail": {"button": `Click:BP:IndiqueOPan:CopiarLink${suffix}`, "product": seletedProduct  }} 
    var interactionClick = new CustomEvent("interactionClick", eventDetail)
    try{
      document.body.dispatchEvent(interactionClick);
    }catch(err){
      console.log("DispatchEvent",err)
    }   
  }

  shareWithWhatsAppAction = () => {
    const { seletedProduct } = this.state;
    const { specific } = this.props;
    const suffix = specific ? "Espec" : ""

    var eventDetail = {"detail": {"button": `Click:BP:IndiqueOPan:CompartilharLinkWApp${suffix}`, "product": seletedProduct }} 
    var interactionClick = new CustomEvent("interactionClick", eventDetail)
    document.body.dispatchEvent(interactionClick);
  }

  printQrCode = () => {
    const { generatedLink, product } = this.state;
    const { width, height } = window.screen;
    var printWindow = window.open(
      "",
      "Imprimir Código QR",
      `width=${width},height=${height},top=200, left=580`
    );
    printWindow.document.write(`
    <html><head><title>Imprimir QR Code</title><link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" integrity="sha384-BVYiiSIFeK1dGmJRAkycuHAHRg32OmUcww7on3RYdg4Va+PmSTsz/K68vbdEjh4u" crossorigin="anonymous"></head><body>
    <div id='print-root'></div></body>`);
    let printComponent = (
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', }}>
        <h3 style={{ marginBottom: 40 }}>QRCode para venda de {product.label}</h3>
        <QRCode value={generatedLink} renderAs="svg" size={428} />
        <button style={{ width: '120px', marginTop: 40 }} className="btn" onClick={() => printWindow.print()}>
          Imprimir
        </button>
      </div>
    );
    ReactDOM.render(printComponent, printWindow.document.getElementById("print-root"));
  };

  downloadQrCode = () => {
    const { product } = this.state
    const canvas = document.querySelector('.download-qrcode').toDataURL()
    if (canvas) {
      const link = document.createElement('a');
      link.href = canvas
      link.download = 'QR Code ' + product.label + '.png'
      link.click();
    }
  }

  handleSpecificCpf = () => {
    const { cpf, cpfMessage } = this.state;
    return (
    <Col className="form-group" md={6} lg={6}>
      <InputMask
        mask="999.999.999-99"
        value={cpf}
        color="secondary"
        onChange={event => {
          this.setState({ cpf: event.target.value });
        }}
      >
        {() => (
          <TextField
            label="CPF"
            InputProps={{
              classes: {
                root: "mui-input-control",
                input: "mui-input-control",
                notchedOutline: "mui-input-focus"
              }
            }}
            fullWidth
            InputLabelProps={{
              classes: { root: "mui-input-control mui-label" }
            }}
            error={Boolean(cpfMessage)}
            variant="outlined"
          />
        )}
      </InputMask>
      <div className={cpfMessage ? "invalid-feedback" : "input-generate-link invalid-feedback"}>{cpfMessage}</div>
    </Col>
  )}

  handleSpecificAddress = () => {
    const {
      phone,
      cep,
      email,
      phoneMessage,
      cepMessage,
      emailMessage,
      sendEmail
    } = this.state;

    return (
    <>
      <Row>
        <Col className="form-group" md={6} lg={6}>
          <InputMask
            mask="99999-999"
            value={cep}
            onChange={event => {
              this.setState({ cep: event.target.value });
            }}
          >
            {() => (
              <TextField
                label="CEP"
                InputProps={{
                  classes: {
                    input: "mui-input-control",
                    root: "mui-input-control"
                  }
                }}
                fullWidth
                InputLabelProps={{
                  classes: { root: "mui-input-control mui-label" }
                }}
                error={Boolean(cepMessage)}
                variant="outlined"
              />
            )}
          </InputMask>
          <div className={cepMessage ? "invalid-feedback" : "input-generate-link invalid-feedback"}>{cepMessage}</div>
        </Col>
        <Col className="form-group" md={6} lg={6}>
          <InputMask
            mask="(99) 99999-9999"
            value={phone}
            onChange={event => {
              this.setState({ phone: event.target.value });
            }}
          >
            {() => (
              <TextField
                label="Celular"
                InputProps={{
                  classes: {
                    input: "mui-input-control",
                    root: "mui-input-control"
                  }
                }}
                InputLabelProps={{
                  classes: { root: "mui-input-control mui-label" }
                }}
                fullWidth
                error={Boolean(phoneMessage)}
                variant="outlined"
              />
            )}
          </InputMask>
          <div className={phoneMessage ? "invalid-feedback" : "input-generate-link invalid-feedback"}>{phoneMessage}</div>
        </Col>
      </Row>
      <Row>
        <Col className="form-group" md={12}>
          <TextField
            label="Email"
            InputProps={{
              classes: {
                input: "mui-input-control",
                root: "mui-input-control"
              }
            }}
            InputLabelProps={{
              classes: { root: "mui-input-control mui-label" }
            }}
            error={Boolean(emailMessage)}
            variant="outlined"
            value={email}
            fullWidth
            onChange={event => {
              this.setState({ email: event.target.value });
            }}
            placeholder="Email"
          />
          <div className={emailMessage ? "invalid-feedback" : "input-generate-link invalid-feedback"}>{emailMessage}</div>
        </Col>
        <Col md={12}>
          <FormControlLabel                              
            control={
              <Checkbox                                  
                color="default" 
                name="permission"
                className="checkbox-fix"
                checked={sendEmail}
                onChange={(event) => {
                  this.setState({ sendEmail: !sendEmail })
                }} 
              />
            }
          label='Enviar para o email automaticamente '
          />
        </Col>
      </Row>
    </>
  )}

  handleSpecificWhats = () => {
    const { whatsapp } = this.state;
    return (
      <Col className="form-group" md={6}>
        <div className="form-group">
          <a href={this.state.shareWithWhatsApp} target="_blank" rel="noopener noreferrer">
            <button
              className="btn btn-mlm btn-round btn-fill btn-block "
              disabled={!(whatsapp && this.state.generatedLink)}                            
            >
              <FontAwesomeIcon
                icon={faWhatsapp}
                size="lg"
                style={{ marginRight: 2 }}
              />
            WhatsApp
          </button>
          </a>
        </div>
      </Col>
    )
  }

  handleCampaignName = () => {
    const { corporationName, slugName } = this.state

    return (
      <>
        <Col className="form-group" md={12} lg={12}>
          <TextField
            label="Campanha"
            InputProps={{
              classes: {
                root: "mui-input-control",
                input: "mui-input-control",
                notchedOutline: "mui-input-focus"
              }
            }}
            fullWidth
            InputLabelProps={{
              classes: { root: "mui-input-control mui-label" }
            }}
            value={this.state.campaignName}
            onChange={(event) => {
              // eslint-disable-next-line
              const regexCampaign = /^([a-z0-9\s\/\-]+$)/i.test(event.target.value) 

              if (regexCampaign || !event.target.value) {
                this.setState({ campaignName: event.target.value })
              }
            }}
            error={Boolean(this.state.campaignMessage)}
            variant="outlined"
          />
          <div className={this.state.campaignMessage ? "invalid-feedback" : "input-generate-link invalid-feedback"}>{this.state.campaignMessage}</div>
        </Col>

        {corporationName && (
          <>
            <p className="pl-3">Adicione um <strong>slug</strong> (palavra usada para links personalizados) abaixo para incrementar a url</p>            
            <Col className="form-group" md={12} lg={12}>
              <TextField
                label="Slug"
                InputProps={{
                  classes: {
                    root: "mui-input-control",
                    input: "mui-input-control",
                    notchedOutline: "mui-input-focus"
                  }
                }}
                fullWidth
                InputLabelProps={{
                  classes: { root: "mui-input-control mui-label" }
                }}
                value={this.state.slugName}
                onChange={(event) => {
                  // eslint-disable-next-line
                  const regexSlug = /^([a-z0-9\-]+$)/.test(event.target.value)

                  if (regexSlug || !event.target.value) {
                    this.setState({ slugName: event.target.value })
                  }
                }}
                error={Boolean(this.state.campaignMessage)}
                variant="outlined"
              />

              <p className="pt-3" style={{ fontWeight: 'bold' }}>Exemplo link final: https://agx.link/{corporationName}<span style={{ fontWeight: 'bold' }}>-{slugName}</span></p>
              <div className={this.state.campaignMessage ? "invalid-feedback" : "input-generate-link invalid-feedback"}>{this.state.campaignMessage}</div>
            </Col>
          </>
        )}

      </>
    )
  }

  handleCampaignDepartment = () => {
    return (
      <Col className="form-group" md={6} lg={6}>
        <TextField
          label="Departamento"
          InputProps={{
            classes: {
              root: "mui-input-control",
              input: "mui-input-control",
              notchedOutline: "mui-input-focus"
            }
          }}
          fullWidth
          InputLabelProps={{
            classes: { root: "mui-input-control mui-label" }
          }}
          value={this.state.department}
          onChange={(event) => {
            // eslint-disable-next-line 
            const regexCampaign = /^([a-z0-9\s\/\-]+$)/i.test(event.target.value) 
            
            if (regexCampaign || !event.target.value) {
              this.setState({ department: event.target.value })
            }
          }}
          error={Boolean(this.state.departmentMessage)}
          variant="outlined"
        />
        <div className={this.state.departmentMessage ? "invalid-feedback" : "input-generate-link invalid-feedback"}>{this.state.departmentMessage}</div>
      </Col>
    )
  }

  render() {
    const {
      phone,
      generatedLink,
      whatsapp,
      phoneMessage,
      productMessage,
      width,
    } = this.state;

    const { title, description, specific, agencyId, size, campaignLink, bottomRow } = this.props;
    return (
      <>
      <Col md={12} lg={size}>
        <Card
          title={title}
          category={description}
          content={
            <>
              <div data-intro={this.props.firstDataIntro}>
                <Row>
                  <Col className="form-group" md={6} lg={specific || campaignLink ? 6 : 12}>
                    <Select
                      className={`custom-select ${
                        productMessage ? "is-invalid" : ""
                        }`}
                      value={this.state.product}
                      onChange={this.onProductChange}
                      options={this.state.products}
                      placeholder="Selecione um Produto"
                    />

                    <div className={productMessage ? "invalid-feedback" : "input-generate-link invalid-feedback"}>{productMessage}</div>                    
                  </Col>

                  {specific ? this.handleSpecificCpf() : null}
                  {campaignLink ? this.handleCampaignDepartment() : null}
                </Row>
                {specific ? this.handleSpecificAddress() : null}
                {specific ? this.handleSpecificWhats() : null}
                <Row>
                  {campaignLink ?
                    <Col md={12} lg={12} style={{ display: 'flex', fontSize: '7px' , alignItems: 'center', justifyContent: 'flex-end' }}>
                      <p>Caracteres especiais permitido <strong>espaço - /</strong></p>
                    </Col>
                    :
                  null}
                  {campaignLink ? this.handleCampaignName() : null}

                  <Col className="form-group" md={specific ? 6 : 12}>
                    <button
                      className="btn btn-mlm btn-round btn-fill btn-block "
                      disabled={this.state.loading}
                      onClick={this.handleGenerateLink}
                    >
                      {!this.state.loading ? "Gerar Link" : "Gerando..."}
                    </button>
                  </Col>
                </Row>
              </div>

              <div data-intro={this.props.secondDataIntro}>                
                <div data-step="6" data-intro={this.props.finalDataIntro}>
                  <Row>
                    <Col className="form-group" lg={6}>
                      <TextField
                        label="Link Gerado"
                        InputProps={{
                          classes: {
                            input: "mui-input-control",
                            root: "mui-input-control"
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: "mui-input-control mui-label generated-link"
                          }
                        }}
                        readOnly
                        variant="outlined"
                        value={
                          generatedLink || "O link aparecerá aqui, quando gerado."
                        }
                        fullWidth
                        placeholder="O link aparecerá aqui, quando gerado."
                      />
                    </Col>

                    <Col className="form-group remove-padding-left" md={3}>
                      <CopyToClipboard
                        text={this.state.generatedLink}
                        onCopy={this.copyToClipboardAdobeAction}
                      >
                        <button
                          className="btn btn-mlm btn-round btn-fill btn-block"
                          disabled={!this.state.generatedLink}
                        >
                          Copiar
                      </button>
                      </CopyToClipboard>
                    </Col>

                    <Col className="form-group remove-padding-left" md={3}>
                      <a
                        target="_blank" rel="noopener noreferrer"
                        className="btn btn-mlm btn-round btn-fill btn-block"
                        href={this.state.generatedLink}
                        disabled={!this.state.generatedLink}
                      >
                        Abrir
                      </a>
                    </Col>
                  </Row>

                  {specific ? null : (
                    <Row data-intro={this.props.secondPart2DataIntro}>                      
                      <Col className="form-group" lg={8}>
                        <InputMask
                          mask="(99) 99999-9999"
                          value={phone}
                          onChange={event => {
                            if (event.target && generatedLink) this.handlePhone(event.target.value);
                            else this.setState({ phone: event.target.value });
                          }}
                        >
                          {() => (
                            <TextField
                              label="Celular"
                              InputProps={{
                                classes: {
                                  input: "mui-input-control",
                                  root: "mui-input-control"
                                }
                              }}
                              InputLabelProps={{
                                classes: { root: "mui-input-control mui-label" }
                              }}
                              fullWidth
                              error={Boolean(phoneMessage)}
                              variant="outlined"
                            />
                          )}
                        </InputMask>
                        <div className="invalid-feedback">{phoneMessage}</div>
                      </Col>
                      <Col className="form-group remove-padding-left" md={4}>
                        <div className="form-group">
                          <a href={this.state.shareWithWhatsApp} onClick={this.shareWithWhatsAppAction} target="_blank" rel="noopener noreferrer">
                            <button
                              className="btn btn-mlm btn-round btn-fill btn-block "
                              disabled={!(whatsapp && this.state.generatedLink)}
                            >
                              <FontAwesomeIcon
                                icon={faWhatsapp}
                                size="lg"
                                style={{ marginRight: 2 }}
                              />
                            WhatsApp
                          </button>
                          </a>
                        </div>
                      </Col>                      
                    </Row>
                  )}
                  <Row>
                    <Col xs={12} id="qrcode-wrapper">
                      <div style={{ flex: 1, textAlign: "center" }}>
                        {generatedLink ? (
                          <>
                            <p>QRCode: </p>{" "}
                            <QRCode
                              value={generatedLink}
                              renderAs="svg"
                              size={agencyId ? width < 500 ? width - (width / 2.5) : width - (width / 1.35) : (width - (width / 1.60))}
                            />
                            <QRCode
                              value={generatedLink}
                              className='download-qrcode'
                              renderAs="canvas"
                              size={428}
                              bgColor='#FFFFFF00'
                              style={{display: 'none'}}
                            />
                            <br />
                            <div style={{ display: 'flex', gap: '1em', flexWrap: 'wrap' }}>
                              <button
                                style={{ marginTop: 10, flexGrow: 1, minWidth: '40%' }}
                                className="btn btn-mlm btn-round btn-fill"
                                onClick={this.printQrCode}
                                disabled={!(this.state.generatedLink)}
                              >Imprimir QRCode</button>
                              <button
                                className='btn btn-fill btn-mlm'
                                style={{  marginTop: 10, flexGrow: 1, minWidth: '40%'}}
                                disabled={!(this.state.generatedLink)}
                                onClick={this.downloadQrCode}
                              >
                                Baixar PNG
                              </button>
                            </div>
                          </>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </div>                
              </div>
            </>
          }
        />
        {bottomRow &&
          <Row style={{marginLeft: 0, marginRight: 0}}>
            {bottomRow}
          </Row>
        }
      </Col>

      </>
    );
  }
}

export default GenerateLink;
