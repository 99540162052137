import React, { useState, useEffect } from 'react';
import { Button } from "react-bootstrap/";
import { Select, MenuItem } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { translateLevel } from 'utils/translate';
import getUserData from 'utils/checkUser';
import { useHistory } from 'react-router-dom';

const AgenciesModal = ({show, agencies = [], actualAgency = '', onSelect, sellerId, onClose, ...rest}) => {
  const [userData, setUserData] = useState({})
  const [selectedAgency, setSelectedAgency] = useState("none");
  const history = useHistory()

  const DialogTitle = (props) => {
    const { children, classes, onClose, ...other } = props;

    return (
      <MuiDialogTitle disableTypography {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            style={{
              position: "absolute",
              right: 1,
              top: 1,
              color: "#717171",
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };


  const close = () => {
    setSelectedAgency("none");
    onClose();
  };

  async function updateUserData () {
    const userData =  await getUserData(history)
    setUserData(userData)
  }

  const availableAgencies = (agencies) ? agencies.filter(agency => agency._id !== actualAgency) : []

  useEffect(() => {
    updateUserData()
  }, [])

  return (
    <Dialog
    onClose={close}
    aria-labelledby="customized-dialog-title"
    open={show}
    maxWidth="sm"
    fullWidth
  >
    <DialogTitle id="customized-dialog-title" onClose={close}>
      Transferir {translateLevel('seller', userData?.corpId)}es
    </DialogTitle>
    <MuiDialogContent dividers>
      <h4>Selecione uma loja:</h4>
      <div>
        <Select
          onChange={(event) => {
            setSelectedAgency(event.target.value);
          }}
          value={selectedAgency}
          InputProps={{
            classes: {
              input: "mui-input-control",
              root: " mui-input-control",
            },
          }}
          InputLabelProps={{
            classes: {
              root: "mui-input-control mui-label ",
            },
          }}
          fullWidth
          variant="outlined"
        >
          <MenuItem key="agency-select-null" value="none">
            <em>Selecione uma loja...</em>
          </MenuItem>
          {availableAgencies.map((agency) => (
            <MenuItem
              key={`agency-select-${agency._id}`}
              value={agency._id}
            >
              {agency.name}
            </MenuItem>
          ))}
        </Select>
      </div>
      <br />
      <Button
        fill
        block
        disabled={selectedAgency === "none"}
        className="btn-fill btn-mlm"
        onClick={() => onSelect(selectedAgency, sellerId)}
      >
        Transferir
      </Button>
    </MuiDialogContent>
  </Dialog>
  );
}

export default AgenciesModal;
