import React, { Component } from 'react'
import MahoeIcons from "components/MahoeIcons"
import Loader from "components/loader"
import { formatToBr } from "utils/formaterDate"
import { Title } from "./styled"

export class CardData extends Component {

  constructor(props) {
    super(props)
    this.state = {
      load: 100
    }
  }

  render() {
    const {
      createdAt,
      fileName,
      success,
      error,
      userName = '',
      link,
      percentage,
      step
    } = this.props.data

    const { isLoading } = this.props

    return (

      <div className="card" style={{ padding: '15px' }}>
        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <MahoeIcons name="document-lines" fill="#414141" />
          <Title>{fileName}</Title>
        </div>
        { isLoading ? (
          <div style={{ width: '100%', height: '100%' }}>
            <p>{step}</p>
            <Loader percentage={percentage}></Loader>
          </div>
        )
          :
          (
            <>
              <div style={{ display: 'grid', justifyContent: 'center' }}>
                <p style={{ margin: '0px', justifySelf: 'start' }}>
                  <strong>Sucessos: </strong>{success}
                  <br />
                  <strong>Erros: </strong>{error}
                  <br />
                  <strong>Data: </strong>{createdAt ? formatToBr(createdAt) : 'n/a'}
                  <br />
                  <strong>Inserido por: </strong>{userName || 'Master / Submaster'}
                  <br />
                </p>
              </div>
              <div style={{ justifyContent: 'center', display: 'flex', marginTop: "10px" }}>
                {
                  link ? 
                    <a href={link} download className="btn btn-fill btn-mlm btn-mlm-sm btn-small-extract">Baixar CSV</a>
                  :
                    <a className="btn btn-fill btn-mlm btn-mlm-sm btn-small-extract disabled">Falha ao inserir</a>
                }
                <a />
              </div>
            </>
          )}
      </div>
    )
  }
}


export default CardData