import React from "react";
import ReactDOM from "react-dom";
import { Dialog } from "components/Alert/CustomAlert";
import mainApi from "services/mainApi";

const getUserData = async (history) => {

  const data = { token: "", id: "", level: "", name: "" };
  data.token = localStorage.getItem("@uxtech-mlm/UserToken");
  const reset = localStorage.getItem("@uxtech-mlm/UserResetPassword");
  const id = localStorage.getItem("@uxtech-mlm/UserId");
  data.sessionTime = localStorage.getItem("@uxtech-mlm/UserSessionTime");
  data.name = localStorage.getItem("@uxtech-mlm/UserName");
  data.socialName = localStorage.getItem("@uxtech-mlm/SocialName");
  data.level = localStorage.getItem("@uxtech-mlm/UserLevel");
  data.agencyId = localStorage.getItem("@uxtech-mlm/AgencyId");
  data.agencyName = localStorage.getItem("@uxtech-mlm/AgencyName");
  data.profilePhoto = localStorage.getItem("@uxtech-mlm/UserProfilePhoto");
  data.corporationSlug = localStorage.getItem("@uxtech-mlm/CorporationSlug")

  const affiliate = localStorage.getItem("@uxtech-mlm/Affiliate");
  const hasManager = localStorage.getItem("@uxtech-mlm/hasManager");
  const isIntegratedPartner = localStorage.getItem("@uxtech-mlm/isIntegratedPartner");
  const email = localStorage.getItem("@uxtech-mlm/Email");

  data.corpLogo = localStorage.getItem("@uxtech-mlm/CorpLogo");
  data.corpName = localStorage.getItem("@uxtech-mlm/CorpName");
  data.corpId = localStorage.getItem("@uxtech-mlm/CorpId");
  data.affiliate = affiliate === 'true';
  data.code = localStorage.getItem("@uxtech-mlm/Code")
  data.hasSupport = JSON.parse(localStorage.getItem("@uxtech-mlm/hasSupport"))
  data.firstLogin = JSON.parse(localStorage.getItem("@uxtech-mlm/FirstLogin"))

  data.products = JSON.parse(localStorage.getItem("@uxtech-mlm/products"));
  data.unreads = JSON.parse(localStorage.getItem("@uxtech-mlm/communication"));
  data.supportAccess = JSON.parse(localStorage.getItem("@uxtech-mlm/supportAccess"));
  data.supportToken = localStorage.getItem("@uxtech-mlm/supportToken");
  data.supportId = localStorage.getItem("@uxtech-mlm/supportId");
  data.supervisorId = localStorage.getItem("@uxtech-mlm/SupervisorId");
  data.supervisorName = localStorage.getItem("@uxtech-mlm/SupervisorName");
  
  data.reCode =  localStorage.getItem("@uxtech-mlm/reCode");
  
  const levels = {
    seller: "Vendedor",
    administrator: "Administrador",
    corporation: "Corporação",
    master: "Master",
    supervisor: "Supervisor",
    partner: "Parceiro"
  };

  if (data.affiliate === "true") data.brType = "Vendedor Mar Aberto"
  else data.brType = levels[data.level];

  if (email) data.email = email;

  data.hasManager = hasManager === "true" ? true : false

  data.isIntegratedPartner = isIntegratedPartner === "true"

  data.whatsappMessage =
    localStorage.getItem("@uxtech-mlm/WhatsappMessage") ||
    "Clique no link para continuar:";
  data.finishedTour = localStorage.getItem("@uxtech-mlm/finishedTour");
  data.finishedPagesTour = localStorage.getItem(
    "@uxtech-mlm/finishedPagesTour"
  );
  const loginType = Boolean(["corporation", "master", "subcorp", "submaster", "partner"].indexOf(data.level) + 1)
    ? "cnpj"
    : "cpf";
  if (reset === "true" && !data.supportAccess) {
    history.push(`/${loginType}/password/change`);
  }
  try {
    data.id = id;
    if (!data.token || !data.id) {
      document.location.href = "/";
      return {};
    }
    return data;
  } catch (err) {
    if (err.response && err.response.status === 401) {
      ReactDOM.render(
        <Dialog
          show
          type="info"
          title="Sua sessão expirou!"
          text="Faça o login novamente."
          onConfirm={() => {
            localStorage.clear();
            document.location.href = "/";
          }}
        />,
        document.getElementById("exit-swal")
      );
      return {};
    }
  }

  if (!data.token || !data.id) document.location.href = "/";
};

const getProducts = async (userData) => {
  let endpoint = "";

  if (["master", "submaster"].includes(userData.level))
    endpoint = "/master/products";
  else if (["corporation", "subcorp"].includes(userData.level))
    endpoint = `/corporation/${userData._id}/products`
  else endpoint = "/products";


  try {
    const response = await mainApi.get(endpoint);
    const products = endpoint == "/master/products" || endpoint == "/products" ? response.data.products : response.data.data.products;

    const productsToSelect = products.map(product => ({
      value: product._id || product.id,
      label: product.name
    })
    )

    localStorage.setItem("@uxtech-mlm/products", JSON.stringify(productsToSelect));

  } catch (err) {
    if (err.response) console.log(err.response.data);
  }
};

const getCommunicationUnread = async () => {
  try {
    const response = await mainApi.get("/communication/read");
    localStorage.setItem("@uxtech-mlm/communication", response.data?.data?.read);
  } catch (err) {
    console.log(err)
  }
}

export const setUserData = async (userData) => {

  const { supportToken, supportId, invade, token, refreshToken, expiresIn, user } = userData

  let sessionTime = expiresIn * 60 * 1000;

  localStorage.setItem("@uxtech-mlm/UserToken", "Bearer " + token);
  localStorage.setItem("@uxtech-mlm/UserLevel", user.level);

  await getProducts(user)
  await getCommunicationUnread()
  if (
    user.analysis &&
    user.analysis !== "approved" &&
    user.affiliate === true
  ) {
    localStorage.setItem("@uxtech-mlm/UserAnalysis", user.analysis);

  //   if (user.resetPassword) {
  //     this.props.history.push("/password/change");
  //   } else {
  //     this.props.history.push("/preregister");
  //   }

  //   return;
  }

  localStorage.setItem("@uxtech-mlm/supportAccess", Boolean(invade));
  localStorage.setItem("@uxtech-mlm/supportToken", supportToken);
  localStorage.setItem("@uxtech-mlm/supportId", supportId);

  localStorage.setItem("@uxtech-mlm/UserRefreshToken", refreshToken);
  localStorage.setItem("@uxtech-mlm/UserSessionTime", sessionTime);
  localStorage.setItem("@uxtech-mlm/LastTokenRefresh", Date.now());
  localStorage.setItem("@uxtech-mlm/UserId", user._id);
  localStorage.setItem("@uxtech-mlm/UserResetPassword", user.resetPassword);
  localStorage.setItem("@uxtech-mlm/UserName", user.name);
  localStorage.setItem("@uxtech-mlm/UserLevel", user.level);
  localStorage.setItem("@uxtech-mlm/finishedTour", user.finishedTour);
  localStorage.setItem("@uxtech-mlm/Affiliate", user.affiliate);
  localStorage.setItem("@uxtech-mlm/hasManager", user.hasManager);
  localStorage.setItem("@uxtech-mlm/hasSupport", Boolean(user.support));
  localStorage.setItem("@uxtech-mlm/isIntegratedPartner", user.isIntegratedPartner);
  localStorage.setItem("@uxtech-mlm/linkGenerationTerms", user.linkGenerationTerms)
  localStorage.setItem("@uxtech-mlm/finishedPagesTour", user.finishedPagesTour);
  localStorage.setItem("@uxtech-mlm/FirstLogin", Boolean(user.firstLogin));


  if (user.email) localStorage.setItem("@uxtech-mlm/Email", user.email);
  if (user.code) localStorage.setItem("@uxtech-mlm/Code", user.code);
  if (user.reCode) localStorage.setItem("@uxtech-mlm/reCode", user.reCode);

  if (user.socialName) localStorage.setItem("@uxtech-mlm/SocialName", user.socialName);
  if (user.agencyId) localStorage.setItem("@uxtech-mlm/AgencyId", user.agencyId);
  if (user.agencyName) localStorage.setItem("@uxtech-mlm/AgencyName", user.agencyName);
  if (user.corporationName) localStorage.setItem("@uxtech-mlm/CorpName", user.corporationName);
  if (user.corporationId) localStorage.setItem("@uxtech-mlm/CorpId", user.corporationId);
  if (user.corporationSlug) localStorage.setItem("@uxtech-mlm/CorporationSlug", user.corporationSlug);
  if (user.supervisorId) localStorage.setItem("@uxtech-mlm/SupervisorId", user.supervisorId);
  if (user.supervisorName) localStorage.setItem("@uxtech-mlm/SupervisorName", user.supervisorName);

}

export default getUserData;
