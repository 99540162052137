import React, { Component } from "react";

import { Row, Col } from "react-bootstrap";
import {
  LoadingAlert,
  ConfirmationAlert,
  Dialog,
} from "../components/Alert/CustomAlert";
import { TextField, FormControl, InputAdornment } from "@material-ui/core";
import InputMask from "react-input-mask";
import getUserData from "utils/checkUser";

import { numberLength } from  'utils/numberMethods'
import mainApi from "services/mainApi";
import LoadingCover from "../components/LoadingCover";
import Card from "components/Card/Card";
import PaymentForm from "components/PaymentForm";
import checkUser from "utils/checkUser";
import dataTour from 'utils/dataTour';
import { introSystem } from 'utils/introToSystem';
import { translateLevel } from "utils/translate";

export default class AgencyInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      addressChanged: false,
      administratorName: "",
      sellersCommission: 0,
      supervisorsCommission: 0,
      showSwal: false,
      swalShowLoading: false,
      swalMessageShow: false,
      showModal: false,
      showInviteModal: false,
      swalMessage: [],
      agency: {},
      street: "",
      zipcode: "",
      neighborhood: "",
      city: "",
      number: "",
      state: "",
      additional: "",
      loadingAddress: false,
      cantUpdateAddress: false,
      title: "Confirmação",
      text: "Tem certeza de que deseja transformar o vendedor em Supervisor?",
      sellerId: null,
      inviteToken: null,
      addressInvalidFields: {}
    };

    this.numberRef = React.createRef();

    this.states = [
      "AC",
      "AL",
      "AP",
      "AM",
      "BA",
      "CE",
      "DF",
      "ES",
      "GO",
      "MA",
      "MT",
      "MS",
      "MG",
      "PA",
      "PB",
      "PR",
      "PE",
      "PI",
      "RJ",
      "RN",
      "RS",
      "RO",
      "RR",
      "SC",
      "SP",
      "SE",
      "TO",
    ];
  }

  invite = async (agencyId) => {
    this.props.history.push("/admin/affiliate?agencyId=" + agencyId);
  };

  getAgencyInfo = async () => {
    try {
      const response = await mainApi.get("/agency");
      let agency = response.data.agency;
      this.setState({ loadingAddress: true });
      this.setState({
        agency,
        sellersCommission: response.data.agency.sellersCommission,
        supervisorsCommission: response.data.agency.supervisorsCommission,
        administratorName: checkUser.name,
      });

      const responseAddress = await mainApi.get(
        `/agency/${agency._id}/address`
      );

      this.setState({
        ...responseAddress.data.addresses[0],
        addressId: responseAddress.data.addresses[0]._id,
      });
    } catch (err) {
      console.log(err.response);
    }
    this.setState({ loadingAddress: false });
  };

  updateAgencyAddress = async () => {
    const {
      addressId,
      street,
      zipcode,
      neighborhood,
      city,
      number,
      state,
      additional,
      agency,
    } = this.state;
    const data = {
      street,
      zipcode: String(zipcode).replace(/\D/g, "") || zipcode,
      neighborhood,
      city,
      number,
      state,
      additional,
    };

    this.setState({ swalShowLoading: true });
    try {
      if (addressId) {
        await mainApi.put(`/agency/${agency._id}/address/${addressId}`, data);
      } else {
        await mainApi.post(
          `/addresses`,
          { ...data, agencyId: agency._id }
        );
      }

      this.setState({
        swalMessage: ["success", "Endereço da loja atualizado com sucesso!"],
        swalMessageShow: true,
      });
    } catch (err) {
      if (!err.response) {
        if (err.message === "Network Error") {
          this.showMessage(
            "error",
            "Não foi possível se comunicar com o servidor, tente novamente mais tarde."
          );
          this.setState({ loading: false });
          return;
        }
        if (err.code === "ECONNABORTED")
          this.showMessage("error", "Tempo de espera atingido.");
          this.setState({ loading: false });
        return;
      }
      if (err.response.status === 422) {
        const invalid = {}
        err.response.data.fields.forEach(field => {
          invalid[field.name] = field.message
        })
        this.setState({ addressInvalidFields: invalid })
      }
      if (err.response.status === 400) {
        const invalid = {}
        err.response.data.fields.forEach(field => {
          invalid[field] = 'Campo obrigatório.'
        })
        this.setState({ addressInvalidFields: invalid })
      }

      this.setState({
        swalMessage: [
          "warning",
          "Temos um problema",
          err.response.data.message,
          ""
        ],
        swalMessageShow: true
      });
    }

    this.setState({ swalShowLoading: false })
  };

  saveAgencyInfo = async () => {
    const {
      sellersCommission,
      supervisorsCommission,
      addressChanged,
    } = this.state;
    this.setState({ swalShowLoading: true });

    let responseResult = false

    try {
      await mainApi.patch(
        "/agency/supandsellers/commission",
        { supervisorsCommission, sellersCommission }
      );
      if (addressChanged) {
        await mainApi.patch(
          "/agency/supandsellers/commission",
          { supervisorsCommission, sellersCommission }
        );
      }
      this.setState({
        swalMessage: ["success", "Loja atualizada com sucesso!"],
        swalMessageShow: true,
      });

      responseResult = true
    } catch (err) {
      if (err.response) {
        if (err.response.data[0])
          this.setState({
            swalMessage: ["error", err.response.data[0].message],
            swalMessageShow: true,
          });
        else
          this.setState({
            swalMessage: ["error", err.response.data.message],
            swalMessageShow: true,
          });
      } else
        this.setState({
          swalMessage: ["error", "Erro Desconhecido"],
          swalMessageShow: true,
        });

      responseResult = false
    }

    if(responseResult) {
      let eventDetail = {"detail": {"button": "Click:BP:IndiqueOPan:SalvarBonificacaoMatriz" }}
      let interactionClick = new CustomEvent("interactionClick", eventDetail)
      document.body.dispatchEvent(interactionClick);
    }

    this.setState({ swalShowLoading: false });
  };

  toggleModal = (agency) => {
    if (agency) this.setState({ agency });
    this.setState({ showModal: !this.state.showModal });
  };

  toggleBlock = async (blocked, agencyId) => {
    try {
      this.setState({ swalShowLoading: true });

      let endpoint = null;

      blocked ? (endpoint = "/agency/unblock") : (endpoint = "/agency/block");

      const response = await mainApi.post(
        endpoint,
        { agencyId }
      );
      this.setState({
        swalMessage: ["success", response.data.message],
        swalShowLoading: false,
      });
    } catch (err) {
      if (!err.response) return ["error", "Erro Desconhecido (ou Timeout)."];
      this.setState({
        swalMessage: [
          "error",
          err.response.data.message || "Erro Desconhecido",
        ],
        swalShowLoading: false,
      });
    }
    this.setState({ swalMessageShow: true });
  };

  handleCep = async event => {
    const searchCep = event.target.value;
    if (searchCep === this.state.zipcode) return;
    this.setState({ zipcode: searchCep });
    const rawCep = searchCep.replace(/\D/g, "");
    if (rawCep.length === 8) {
      try {
        const response = await mainApi.get(`/resources/cep/${rawCep}`);
        const {
          street,
          neighborhood,
          city,
          state,
          additional
        } = response.data.address;
        this.setState({ street, neighborhood, city, state, additional });
        this.numberRef.current.focus();
      } catch (error) { }
    }
  };

  componentDidMount = async () => {
    const userData = await getUserData(this.props.history);

    window.digitalData = {
      "site": {
          "nome": "Banco Pan"
      },
      "page": {
          "secao": "IndiqueoPan",
          "estadoLogin": "LG",
          "nome": "BP:IndiqueOPan:Loja",
      },
      "user": {
          "crmid": userData.id,
          "tipo": userData.brType
      }
    }

    var evt = new CustomEvent('event-view-start');
    document.body.dispatchEvent(evt);
    await this.getAgencyInfo();

    const { routeKey } = this.props;

    this.setState({ userData, text: `Tem certeza de que deseja transformar o ${translateLevel('seller', userData?.corpId)} em ${translateLevel('supervisor', userData?.corpId)}?` });
    await this.getAgencyInfo(userData);

    introSystem(routeKey)
  };

  render() {
    const {
      edit,
      agency,
      sellersCommission,
      supervisorsCommission,
      title,
      text,
      street,
      zipcode,
      neighborhood,
      city,
      number,
      state,
      additional,
      loadingAddress,
      cantUpdateAddress,
      swalShowLoading,
      swalMessageShow,
      swalMessage,
      addressInvalidFields
    } = this.state;

    return (
      <div className="content">

        <ConfirmationAlert
          show={this.state.showSwal}
          title={title}
          onCancel={() => {
            this.setState({ showSwal: false });
          }}
          onConfirm={() => {
            this.upgradeSeller();
          }}
          text={text}
        />
        <Dialog
          type={swalMessage[0]}
          show={swalMessageShow}
          title={swalMessage[0] === "success" ? "Sucesso!" : "Erro"}
          onConfirm={() => {
            this.setState({ swalMessageShow: false });
          }}
          text={swalMessage[1]}
        />
        <LoadingAlert show={swalShowLoading} />
        <div className="container-fluid">
          <Row>
            <Col md={7} lg={6}>
              <Card
                title="Endereço"
                category="Informações pessoais sobre seu endereço."
                content={
                  <>
                    {loadingAddress ? <LoadingCover /> : null}
                    <Row>
                      <Col style={{ marginLeft: 15, marginRight: 15 }} data-intro={dataTour.agencyInfo.address}>
                        <Row>
                          <div className="form-group col-lg-4 col-md-8 col-xs-12">
                            <InputMask
                              mask="99999-999"
                              value={zipcode}
                              onChange={(event) => {
                                this.handleCep(event)
                                this.setState({
                                  addressChanged: true,
                                });
                              }}
                            >
                              {() => (
                                <TextField
                                  label="CEP"
                                  InputProps={{
                                    classes: {
                                      input: "mui-input-control",
                                      root: "mui-input-control",
                                    },
                                  }}
                                  InputLabelProps={{
                                    classes: {
                                      root: "mui-input-control mui-label",
                                    },
                                  }}
                                  error={Boolean(addressInvalidFields.zipcode)}
                                  helperText={addressInvalidFields.zipcode}
                                  fullWidth
                                  variant="outlined"
                                />
                              )}
                            </InputMask>
                          </div>
                        </Row>
                        <Row>
                          <Col md={7} lg={8}>
                            <div className="form-group">
                              <TextField
                                label="Logradouro"
                                InputProps={{
                                  classes: {
                                    input: "mui-input-control",
                                    root: "mui-input-control",
                                  },
                                }}
                                InputLabelProps={{
                                  classes: {
                                    root: "mui-input-control mui-label",
                                  },
                                }}
                                variant="outlined"
                                value={street}
                                fullWidth
                                error={Boolean(addressInvalidFields.street)}
                                helperText={addressInvalidFields.street}
                                onChange={(event) => {
                                  this.setState({
                                    street: event.target.value,
                                    addressChanged: true,
                                  });
                                }}
                                placeholder="Logradouro"
                              />
                            </div>
                          </Col>
                          <Col md={5} lg={4}>
                            <div className="form-group">
                              <TextField
                                label="Número"
                                inputRef={this.numberRef}
                                InputProps={{
                                  classes: {
                                    input: "mui-input-control",
                                    root: "mui-input-control",
                                  },
                                  type: "number",
                                  inputMode: "tel",
                                  pattern: "[0-9]*",
                                }}
                                InputLabelProps={{
                                  classes: {
                                    root: "mui-input-control mui-label",
                                  },
                                }}
                                variant="outlined"
                                value={number}
                                fullWidth
                                error={Boolean(addressInvalidFields.number)}
                                helperText={addressInvalidFields.number}
                                onChange={(event) => {
                                  this.setState({
                                    number: event.target.value,
                                    addressChanged: true,
                                  });
                                }}
                                placeholder="Número"
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={5}>
                            <div className="form-group">
                              <TextField
                                label="Complemento"
                                InputProps={{
                                  classes: {
                                    input: "mui-input-control",
                                    root: "mui-input-control",
                                  },
                                }}
                                InputLabelProps={{
                                  classes: {
                                    root: "mui-input-control mui-label",
                                  },
                                }}
                                variant="outlined"
                                value={additional}
                                fullWidth
                                error={Boolean(addressInvalidFields.additional)}
                                helperText={addressInvalidFields.additional}
                                onChange={(event) => {
                                  this.setState({
                                    additional: event.target.value,
                                    addressChanged: true,
                                  });
                                }}
                                placeholder="Complemento"
                              />
                            </div>
                          </Col>
                          <Col md={7}>
                            <div className="form-group">
                              <TextField
                                label="Bairro"
                                InputProps={{
                                  classes: {
                                    input: "mui-input-control",
                                    root: "mui-input-control",
                                  },
                                }}
                                InputLabelProps={{
                                  classes: {
                                    root: "mui-input-control mui-label",
                                  },
                                }}
                                variant="outlined"
                                value={neighborhood}
                                fullWidth
                                error={Boolean(addressInvalidFields.neighborhood)}
                                helperText={addressInvalidFields.neighborhood}
                                onChange={(event) => {
                                  this.setState({
                                    neighborhood: event.target.value,
                                    addressChanged: true,
                                  });
                                }}
                                placeholder="Bairro"
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <div className="form-group">
                              <TextField
                                label="Cidade"
                                InputProps={{
                                  classes: {
                                    input: "mui-input-control",
                                    root: "mui-input-control",
                                  },
                                }}
                                InputLabelProps={{
                                  classes: {
                                    root: "mui-input-control mui-label",
                                  },
                                }}
                                variant="outlined"
                                value={city}
                                fullWidth
                                error={Boolean(addressInvalidFields.city)}
                                helperText={addressInvalidFields.city}
                                onChange={(event) => {
                                  this.setState({
                                    city: event.target.value,
                                    addressChanged: true,
                                  });
                                }}
                                placeholder="Cidade"
                              />
                            </div>
                          </Col>

                          <Col md={3} lg={3}>
                            <FormControl variant="outlined">
                              <select
                                className="form-control select-focus-fix"
                                value={state}
                                onChange={(event) => {
                                  this.setState({
                                    state: event.target.value,
                                    addressChanged: true,
                                  });
                                }}
                              >
                                <option value="UF">UF</option>
                                {this.states.map((state) => (
                                  <option value={state} key={state}>
                                    {state}
                                  </option>
                                ))}
                              </select>
                            </FormControl>
                          </Col>
                        </Row>
                        <Row>
                          <div
                            className="form-group "
                            style={{
                              textAlign: "center",
                              marginLeft: 20,
                              marginRight: 20,
                            }}
                          >
                            <button
                              className="btn btn-fill btn-block btn-round btn-mlm"
                              style={{ marginTop: 15 }}
                              onClick={this.updateAgencyAddress}
                              disabled={cantUpdateAddress}
                            >
                              <span className="save-text">Salvar</span>
                            </button>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  </>
                }
              />
            </Col>
            <Col md={12} lg={6}>
              <Card
                title={
                  agency && agency.name
                    ? "Bonificações de " + agency.name
                    : "Carregando..."
                }
                category={
                  <span>
                    A bonificação do {translateLevel('supervisor', this.state.userData?.corpId)} sobre uma venda de seu {translateLevel('seller', this.state.userData?.corpId)} é baseada no valor da bonificação da venda.
                  </span>
                }
                content={
                  <>
                    <Row>
                      <Col style={{ marginLeft: 15, marginRight: 15 }}>
                        <Row>
                          <Col md={6}>
                            <div className="form-group">
                              <TextField
                                data-intro={dataTour.agencyInfo.sellers}
                                label={translateLevel('seller', this.state.userData?.corpId) +'es'}
                                type="number"
                                InputProps={{
                                  classes: {
                                    input: "mui-input-control",
                                    root: "mui-input-control",
                                  },
                                  inputMode: "tel",
                                  pattern: "[0-9]*",
                                  startAdornment: (
                                    <InputAdornment
                                      style={{
                                        position: "absolute",
                                        marginLeft: Number.isInteger(
                                          Number(sellersCommission)
                                        )
                                          ? 40
                                          : 50,
                                      }}
                                      position="start"
                                    >  %</InputAdornment>
                                  ),

                                  // type: "number",
                                  readOnly: !edit,
                                }}
                                InputLabelProps={{
                                  classes: {
                                    root: "mui-input-control mui-label",
                                  },
                                }}
                                // eslint-disable-next-line react/jsx-no-duplicate-props
                                inputProps={{
                                  step: "0.1",
                                }}
                                variant="outlined"
                                value={sellersCommission}
                                fullWidth
                                disabled={!edit}
                                onFocus={element => element.target.select() }
                                onChange={(event) => {

                                  if((event.target.value === '') || Number(event.target.value)) {
                                    let commission = numberLength(event.target.value);
                                    if (commission >= 100) commission = 100;
                                    if (commission < 0) commission = 0;

                                    this.setState({
                                      sellersCommission: commission,
                                    })
                                  }
                                }}
                                placeholder="Número"
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="form-group">
                              <TextField
                                data-intro={dataTour.agencyInfo.supervisors}
                                label={translateLevel('supervisor', this.state.userData?.corpId) +'es'}
                                pattern="[0-9]+([\.][0-9]+)?"
                                InputProps={{
                                  classes: {
                                    input: "mui-input-control",
                                    root: "mui-input-control",
                                  },
                                  type: "number",
                                  id: "input-step",
                                  readOnly: !edit,
                                  startAdornment: (
                                    <InputAdornment
                                      style={{
                                        position: "absolute",
                                        marginLeft: Number.isInteger(
                                          Number(supervisorsCommission)
                                        )
                                          ? 40
                                          : 50,
                                      }}
                                      position="start"
                                    >
                                      %
                                    </InputAdornment>
                                  )
                                }}
                                // eslint-disable-next-line react/jsx-no-duplicate-props
                                inputProps={{
                                  step: "0.1",
                                }}
                                InputLabelProps={{
                                  classes: {
                                    root: "mui-input-control mui-label",
                                  },
                                }}
                                variant="outlined"
                                value={supervisorsCommission}
                                onFocus={element => element.target.select() }
                                fullWidth
                                disabled={!edit}
                                onChange={(event) => {
                                  if((event.target.value === '') || Number(event.target.value)) {
                                    let commission = numberLength(event.target.value);
                                    if (commission >= 100) commission = 100;
                                    if (commission < 0) commission = 0;

                                    this.setState({
                                      supervisorsCommission: commission,
                                    })
                                  }
                                }}
                                placeholder="Número"
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row style={{ float: "right" }}>
                          <Col md={12}>
                            <button
                              data-step="2"
                              data-intro={dataTour.agencyInfo.editButton}
                              className="btn btn-fill btn-round btn-mlm"
                              style={{ marginLeft: 10, marginBottom: 4 }}
                              onClick={() => {
                                this.setState({ edit: !this.state.edit });
                              }}
                            >
                              Editar
                            </button>
                            <button
                              data-intro={dataTour.agencyInfo.saveButton}
                              className="btn btn-fill btn-round btn-success"
                              style={{ marginLeft: 10, marginBottom: 4 }}
                              onClick={this.saveAgencyInfo}
                              disabled={!edit}
                            >
                              Salvar
                            </button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </>
                }
              />
            </Col>
            {/* <Col md={6}>
              <Card
                title="Dados Bancarios"
                category="Informações pessoais sobre seus dados bancarios."
                content={<PaymentForm isAgency />}
              ></Card>
            </Col> */}
          </Row>
        </div>
      </div>
    );
  }
}
