import React, { Component } from "react"
import { Row, Col } from "react-bootstrap"
import Button from "components/CustomButton/CustomButton.jsx"
import MuiMonthSelect from "components/MuiMonthSelect";
import { getDatesList } from "utils/formatData";
import mainApi from "services/mainApi";
import SweetAlert from "sweetalert2";

class PaymentCorp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: {
        label: '',
        value: ''
      },
    }
  } 
  
  handleClick = async () => {
    
    SweetAlert.fire({
      title: 'Atenção?',
      text: 'O relatório será enviado para o e-mail cadastrado em sua conta irreversivelmente. Confirma?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, solicitar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
    
        if(!this.state.selectedDate || !this.state.selectedDate.value) {
          SweetAlert.fire('Aviso', 'Por favor selecione uma data', 'warning')
          return
        }
        
        const [month, year] = this.state.selectedDate.value.split('-')
    
        const body = {
          month: month,
          year: year
        }

        try {
          const response = await mainApi.post(`/reports/payment`, body);
          SweetAlert.fire('Sucesso!', response.data?.message, 'success')
          console.log(response)
          
        } catch (err) {
          SweetAlert.fire('Ops!', err?.data?.message || "Erro Desconhecido", 'warning')
          console.warn(err);
          return
        }
        
      }
    })
    

  }
  
  onDateSelect = date => {
    this.setState({ selectedDate: date })
    console.log(date)
  };


  render() {
    
    return (
      <div className="content">
        <div className="container-fluid">
          <Row>
            
            <Col md={12}>
            
              <div className="card" style={{ padding: '50px 20px 50px 20px' }}>
                
                <Row>
                  <Col md={2}>
                    <MuiMonthSelect month={this.selectedDate} size="sm" onMonthSelect={this.onDateSelect} availableDates={getDatesList('2020-08')}/>
                  </Col>
                  
                  <Col md={2}>
                    <Button  bsStyle="primary" fill style={{ width: "100%" }} onClick={()=> this.handleClick()}>Solicitar Relatório</Button>
                  </Col>
                  
                </Row>
            

                
                
                {/* { loading ? (
                  <LoadingGif />
                ) : (
                  <div>

                  </div>
                )} */}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default PaymentCorp;
