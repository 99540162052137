import React from 'react'

import { Row, Col, Modal } from "react-bootstrap"

function ModalBonification({ show, onClose }) {
  return (
    <Modal
      show={show}
      onHide={() => onClose()}
    >
      <Modal.Header closeButton>
        <Modal.Title>
        Tabela de Bonificação
      </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className='table-bonification-header'>
          <Col lg={6} md={6} sm={6} xs={6}>
          </Col>
          <Col lg={3} md={3} sm={3} xs={3}>
            Margem livre
          </Col>
          <Col lg={3} md={3} sm={3} xs={3}>
            REFIN
          </Col>
        </Row>
        <Row className='table-bonification-row-alpha'>
          <Col lg={6} md={6} sm={6} xs={6}>
            R$ 500 a R$ 1.000
          </Col>
          <Col lg={3} md={3} sm={3} xs={3}>
            R$ 45
          </Col>
          <Col lg={3} md={3} sm={3} xs={3}>
            R$ 9
          </Col>
        </Row>
        <Row className='table-bonification-row-beta'>
          <Col lg={6} md={6} sm={6} xs={6}>
            R$ 1000 a R$ 3.000
          </Col>
          <Col lg={3} md={3} sm={3} xs={3}>
            R$ 120
          </Col>
          <Col lg={3} md={3} sm={3} xs={3}>
            R$ 24
          </Col>
        </Row>
        <Row className='table-bonification-row-alpha'>
          <Col lg={6} md={6} sm={6} xs={6}>
            Acima de R$ 3.000
          </Col>
          <Col lg={3} md={3} sm={3} xs={3}>
            R$ 200
          </Col>
          <Col lg={3} md={3} sm={3} xs={3}>
            R$ 40
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default ModalBonification
