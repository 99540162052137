import { Line } from "react-chartjs-2"
import Card from "components/Card/Card"
import React from "react"

import {graphOptions} from './common'

function ClicksChart({bigger, data}) {

  return (
    <Card
      style={{ minHeight: bigger ? 320 : 370 }}
      statsIcon="fa fa-history"
      id="chartHours"
      title="Status de Interação"
      category={"Performance Média de Clicks na Semana Atual"}
      content={
        <Line
          data={data}
          height={300}
          options={{
            legend: {
              position: "top",
              align: "start",
              labels: { usePointStyle: true },
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    stepSize: 1,
                    beginAtZero: true,
                  },
                },
              ],
            },
            responsive: true,
            maintainAspectRatio: false,
            ...graphOptions,
          }}
        />
      }
    />
  );
}

export default ClicksChart;
