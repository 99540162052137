import React from "react";
import { Modal, Checkbox } from "react-bootstrap";
import renderHTML from 'react-render-html';
import { ISClubeAmigo } from "utils/environment";
import TermsClubeAmigo from "./TermsModal/TermsClubeAmigo";


const AgreementModal = ({onFinish, termsAgreed, whatsappAgreed, termsHtml, show, setTermsAgreed, setWhatsappAgreed, termsError, hideModal, ...rest}) => {

    return (
      <>
        <Modal
          show={show}
          dialogClassName="modal-90w"
          aria-labelledby="agency-data"
          {...rest}
        >
          <Modal.Header>
            <Modal.Title id="agency-data-title">
              Termos de Acordo
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ marginBottom: 0 }}>
            <div style={{maxHeight: '50vh', overflow:"auto", marginBottom: 21}}>
              {ISClubeAmigo ? <TermsClubeAmigo/>: renderHTML(termsHtml)}
            </div>
            <Checkbox inline checked={termsAgreed} onChange={() => {setTermsAgreed(!termsAgreed)}}>Li e concordo com os termos de acordo.</Checkbox>
            <div>
              <Checkbox inline checked={whatsappAgreed} onChange={() => {setWhatsappAgreed(!whatsappAgreed)}}>Eu autorizo o Banco PAN entrar em contato via WhatsApp</Checkbox>
            </div>
            <p className='error-description' style={{ minHeight: 21, margin: 0 }}>{termsError}</p>
          </Modal.Body>
          <Modal.Footer className="footer__buttons">
            <button className="btn  btn-secondary" onClick={hideModal}>
              Fechar
            </button>
            <button className="btn btn-fill btn-mlm" onClick={onFinish}>
              Concluir
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

export default AgreementModal;
