import styled from 'styled-components'

export default styled.button`
  background: none;
  text-decoration: ${props => props.isLink ? 'underline' : 'none'};
  border: none;
  color: ${props => props.isLink ? 'var(--primary-color)' : 'black'};
  font-weight: 500;
  font-size: ${props => props.fontSize ? props.fontSize+'px' : '12px'};
  white-space: ${props => props.noWrap ? 'nowrap' : 'normal'};
  padding: 0;
  `
  // text-align: ${props => props.textAlign ? props.textAlign : 'center'}