import React, { Component } from "react";

import { Col, Grid, Row } from "react-bootstrap";

import mainApi from "services/mainApi";
import getUserData from "utils/checkUser";
import Card from "../components/Card/Card";
import { LoadingAlert, Dialog } from "../components/Alert/CustomAlert";
import CorpDetailsCard from "../components/CorpDetailsCard";
import PaymentForm from "components/PaymentForm";
import LoadingCover from "../components/LoadingCover";
import { TextField, FormControl } from "@material-ui/core";
import InputMask from "react-input-mask";
import { ConfirmationAlert } from "components/Alert/CustomAlert";
import UserContext from "context/userContext";
import { introSystem } from "utils/introToSystem";
import dataTour from "utils/dataTour";

export default class CorpDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
      cnpj: "",
      userData: {},
      corporation: {},
      corporationUser: {},
      corporationAddress: {},
      loadingAddress: false,
      swalMessage: ["success", "default", "null"],
      swalShowMessage: false,
      swalConfirmMessage: [],
      swalShowConfirm: false,
      swalShowLoading: false,
      cnpjMessage: "",
      nameMessage: "",
      descriptionMessage: "",
      addressId: "",
      street: "",
      zipcode: "",
      neighborhood: "",
      city: "",
      number: "",
      state: "",
      additional: "",
      corpId: null,
      agencies: [],
      loading: true,
      collapse: false,
      showCreate: false,
      zipcodeMessage: "",
      addressMessage: "",
      numberMessage: "",
      stateMessage: "",
      neighborhoodMessage: "",
      cityMessage: "",
      additionalMessage: "",
      email: "",
      emailChanged: false,
      socialName: "",
      socialNameChanged: false,
      alertConfirmation: "",
      addressInvalidFields: {}
    };
    this.numberRef = React.createRef();
    this.states = [
      "AC",
      "AL",
      "AP",
      "AM",
      "BA",
      "CE",
      "DF",
      "ES",
      "GO",
      "MA",
      "MT",
      "MS",
      "MG",
      "PA",
      "PB",
      "PR",
      "PE",
      "PI",
      "RJ",
      "RN",
      "RS",
      "RO",
      "RR",
      "SC",
      "SP",
      "SE",
      "TO",
    ];
  }

  static contextType = UserContext;

  showMessage = (type, title, message, bold) => {
    this.setState({
      swalShowMessage: true,
      swalMessage: [type, title, message, bold],
      loading: false,
    });
  };

  resetPassword = async () => {
    const { email, corporationUser } = this.state;
    this.setState({ swalShowLoading: true });

    const data = { corpUserId: corporationUser._id, email };
    try {
      const response = await mainApi.patch("/corpuser/reset/password", data);
      this.setState({ swalShowLoading: false });
      this.showMessage("success", response.data.message);
    } catch (err) {
      this.setState({ swalShowLoading: false });
      if (!err.response) {
        if (err.code === "ECONNABORTED") {
          this.showMessage(
            "error",
            "A senha não foi resetada",
            "Tempo de espera atingido."
          );
          return;
        } else {
          this.showMessage(
            "error",
            "A senha não foi resetada",
            "Não foi possível resetar a senha, tente novamente mais tarde."`(código de erro: ${err.response.status})`
          );
          return;
        }
      }
      if (err.response.status === 500) {
        this.showMessage(
          "error",
          "A senha não foi resetada",
          "Não foi possível resetar a senha, tente novamente mais tarde.",
          `(código de erro: ${err.response.status})`
        );
        return;
      }
      if (err.response.data.error === "invalid-fields") {
        let fields = err.response.data.fields;
        while (fields.length > 0) {
          const field = fields.pop();
          switch (field.name) {
            case "email": {
              this.showMessage("warning", "Email inválido", field.message);
              return;
            }
            case "corpUserId": {
              this.showMessage("warning", "Usuário inválido", field.message);
              return;
            }
            default:
              break;
          }
        }
      }
      this.showMessage(
        "warning",
        "Não foi possivel resetar a senha",
        err.response.data.message
      );
    }
  };

  getCorpData = async () => {
    this.setState({ loadingAddress: true, loading: true });
    let endpoint = "/corporation";
    const response = await mainApi.get(endpoint);
    let corporation = response.data.corporation;
    let corporationUser = response.data.corporation.user;
    this.setState({ email: corporationUser.email });
    this.setState({ socialName: corporation.socialName });
    this.setState({
      corporation,
      corporationUser,
      loading: false,
      loadingAddress: false,
    });
  };

  getCorpAddress = async () => {
    this.setState({ loadingAddress: true });
    try {
      const response = await mainApi.get(`/address`);
      this.setState({
        ...response.data.addresses[0],
        addressId: response.data.addresses[0]._id,
        corporationAddress: { ...response.data.addresses[0] },
      });
    } catch (err) {
    } finally {
      this.setState({ loadingAddress: false });
    }
  };

  handleZipcode = async (event) => {
    const searchZipcode = event.target.value;
    this.setState({ zipcode: searchZipcode });

    const rawZipcode = searchZipcode.replace(/\D/g, "");
    if (rawZipcode.length === 8) {
      try {
        const response = await mainApi.get(`/resources/cep/${rawZipcode}`);
        const {
          street,
          neighborhood,
          city,
          state,
          additional,
        } = response.data.address;
        this.setState({ street, neighborhood, city, state, additional });
        this.numberRef.current.focus();
      } catch (error) {}
    }
  };

  updateCorpAddress = async () => {
    const {
      addressId,
      street,
      zipcode,
      neighborhood,
      city,
      number,
      state,
      additional,
    } = this.state;

    const checkFields = [
      "zipcodeMessage",
      "streetMessage",
      "numberMessage",
      "stateMessage",
      "neighborhoodMessage",
      "cityMessage",
      "additionalMessage",
    ];

    checkFields.map((field) => this.setState({ [field]: "" }));

    this.setState({ swalShowLoading: true });

    const data = {};

    if (street) data.street = street;
    if (zipcode) data.zipcode = String(zipcode).replace(/\D/g, "");
    if (neighborhood) data.neighborhood = neighborhood;
    if (city) data.city = city;
    if (number) data.number = number;
    if (state) data.state = state;
    if (additional) data.additional = additional;
    try {
      let response;
      if (addressId) {
        response = await mainApi.put(`/address/${addressId}`, data);
      } else {
        response = await mainApi.post(`/addresses`, data);
      }
      this.setState({
        swalShowLoading: false,
        swalMessage: ["success", response.data.message, ""],
        swalShowMessage: true,
      });
    } catch (err) {
      if (!err.response) {
        if (err.message === "Network Error") {
          this.showMessage(
            "error",
            "Não foi possível se comunicar com o servidor, tente novamente mais tarde."
          );
          this.setState({ loading: false });
          return;
        }
        if (err.code === "ECONNABORTED")
          this.showMessage("error", "Tempo de espera atingido.");
        this.setState({ loading: false });
        return;
      }
      if (err.response.data.code === 422) {
        console.log(err.response.data.fields)
        const invalid = {}
        err.response.data.fields.forEach(field => {
          invalid[field.name] = field.message 
        })
        this.setState({ addressInvalidFields: invalid }, () => { console.log(this.state.addressInvalidFields) })
      }
      if (err.response.data.code === 400) {
        const invalid = {}
        err.response.data.fields.forEach(field => {
          invalid[field] = 'Campo obrigatório.' 
        })
        this.setState({ addressInvalidFields: invalid }, () => { console.log(this.state.addressInvalidFields) })
      }

      this.setState({
        swalShowLoading: false,
        swalMessage: [
          "warning",
          "Temos um problema",
          err.response.data.message,
          ""
        ],
        swalShowMessage: true
      });
    }

    this.setState({ swalShowLoading: false });
  };

  componentDidMount = async () => {    
    const userData = await getUserData(this.props.history);
    const { routeKey } = this.props;

    await this.getCorpData();    

    window.digitalData = {
      site: {
        nome: "Banco Pan",
      },
      page: {
        secao: "IndiqueoPan",
        estadoLogin: "LG",
        nome: "BP:IndiqueOPan:Perfil",
      },
      user: {
        crmid: userData.id,
        tipo: userData.brType,
      },
    };
    
    var evt = new CustomEvent("event-view-start");
    document.body.dispatchEvent(evt);

    const corpId = this.state.corporation._id;
    this.getCorpAddress();
    this.setState({ corpId, userData });

    introSystem(routeKey);
  };

  handleEmail = async () => {
    const { email } = this.state;
    try {
      mainApi.post("/email", { email });
      this.showMessage(
        "success",
        "success",
        "Email enviado. Você deve confirmar sua conta para alterar seu email."
      );
    } catch (err) {
      this.showMessage(
        "warning",
        "warning",
        "Não foi possivel enviar o pedido de alteração de Email. Por favor tente novamente mais tarde."
      );
    }
  };

  handleSocialName = async () => {
    const { socialName, corpId } = this.state;
    const { setSocialName } = this.context;

    try {
      mainApi.put(`/corporation/${corpId}/socialname`, { socialName });
      this.showMessage(
        "success",
        "success",
        "Nome fantasia alterado com sucesso!"
      );
    } catch (err) {
      this.showMessage(
        "warning",
        "warning",
        "Não foi possivel alterar o nome fantasia. Por favor tente novamente mais tarde."
      );
    } finally {
      setSocialName(socialName);
    }
  };

  setWhatsMessage = async () => {
    const { whatsMsg } = this.state;
    try {
      mainApi.post("/whatsappMessage", { whatsMsg });
      this.showMessage("success", "success", "Mensagem alterada com sucesso!");
    } catch (err) {
      this.showMessage(
        "error",
        "warning",
        "Não foi possivel alterar a sua mensagem. Por favor tente novamente mais tarde."
      );
    }
  };

  render() {
    const {
      swalShowLoading,
      swalMessage,
      swalShowMessage,
      swalConfirmMessage,
      swalShowConfirm,
      corporation,
      corporationUser,
      corporationAddress,
      loadingAddress,
      loading,
      street,
      zipcode,
      neighborhood,
      city,
      number,
      additional,
      state,
      email,
      userData,
      showCreate,      
      additionalMessage,
      emailMessage,
      emailChanged,
      socialName,
      socialNameChanged,
      alertConfirmation,
      addressInvalidFields
    } = this.state;
    return (
      <div className="content">
        <ConfirmationAlert
          type={swalConfirmMessage[0]}
          show={swalShowConfirm}
          title={swalConfirmMessage[1]}
          onConfirm={() => {
            this.setState({ swalShowConfirm: false });
            if (alertConfirmation === "email") this.handleEmail();
            else if (alertConfirmation === "socialName")
              this.handleSocialName();
          }}
          onCancel={() => {
            this.setState({ swalShowConfirm: false });
          }}
          text={swalConfirmMessage[2]}
        />
        <Dialog
          type={swalMessage[0]}
          show={swalShowMessage}
          title={swalMessage[1] === "success" ? "Sucesso!" : swalMessage[1]}
          onConfirm={() => {
            this.setState({ swalShowMessage: false });
          }}
          text={swalMessage[2]}
          boldText={swalMessage[3]}
        />
        <LoadingAlert show={swalShowLoading} />

        <Grid fluid>
          <Row>
            <Col md={12}>
              <CorpDetailsCard
                getCorpData={this.getCorpData}
                corporation={corporation}
                userData={userData}
                corporationUser={corporationUser}
                corporationAddress={{ ...corporationAddress, loadingAddress }}
              />
            </Col>
            <Col md={12}>
              {showCreate ? (
                <Card
                  title="Nova Loja"
                  content={
                    <>
                      <Row>
                        <form>
                          <Col sm={12} md={6} lg={4}>
                            <input
                              className="form-control"
                              onChange={(event) =>
                                this.setState({
                                  agencyName: event.target.value,
                                })
                              }
                              placeholder="Nome da loja"
                            />
                          </Col>
                          <Col sm={12} md={3} lg={2}>
                            <input
                              type="number"
                              className="form-control"
                              min={0}
                              max={100.0}
                              step={0.1}
                              onChange={(event) => {
                                let commission = event.target.value;
                                if (commission >= 100) commission = 100;
                                if (commission < 0) commission = "";
                                this.setState({
                                  agencyCommission: commission,
                                });
                              }}
                              placeholder="Bonificação"
                            />
                          </Col>
                        </form>
                      </Row>
                      <Row>
                        <Col sm={12} md={4} lg={3}>
                          <button
                            className="btn btn-fill btn-round btn-success btn-block"
                            onClick={this.createNewAgency}
                          >
                            Criar
                          </button>
                        </Col>
                        <Col sm={12} md={4} lg={3}>
                          <button
                            className="btn btn-fill btn-round btn-info btn-block"
                            onClick={this.toggleNewAgency}
                          >
                            Voltar
                          </button>
                        </Col>
                      </Row>
                    </>
                  }
                />
              ) : null}
            </Col>
          </Row>

          <Row>
            <Col md={7} lg={6}>
              <div data-intro={dataTour.corpProfile.address}>
                <Card
                  title="Endereço"
                  category="Informações pessoais sobre seu endereço."
                  content={
                    <>
                      {loadingAddress ? <LoadingCover /> : null}

                      <Row>
                        <Col style={{ marginLeft: 15, marginRight: 15 }}>
                          <Row>
                            <div className="form-group col-lg-4 col-md-8 col-xs-12">
                              <InputMask
                                mask="99999-999"
                                value={zipcode}
                                onChange={(event) => {
                                  if (event.target.value.length >= 9) {
                                    this.handleZipcode(event);
                                  }
                                  this.setState({
                                    zipcode: event.target.value,
                                    userAddressChanged: true,
                                  });
                                }}
                              >
                                {() => (
                                  <TextField
                                    label="CEP"
                                    InputProps={{
                                      classes: {
                                        input: "mui-input-control",
                                        root: "mui-input-control",
                                      },
                                    }}
                                    InputLabelProps={{
                                      classes: {
                                        root: "mui-input-control mui-label",
                                      },
                                    }}
                                    error={Boolean(addressInvalidFields.zipcode)}
                                    helperText={addressInvalidFields.zipcode}
                                    value={zipcode}
                                    fullWidth
                                    variant="outlined"
                                  />
                                )}
                              </InputMask>
                            </div>
                          </Row>
                          <Row>
                            <Col md={8} lg={9}>
                              <div className="form-group">
                                <TextField
                                  label="Logradouro"
                                  InputProps={{
                                    classes: {
                                      input: "mui-input-control",
                                      root: "mui-input-control",
                                    },
                                    value: street || "",
                                  }}
                                  InputLabelProps={{
                                    classes: {
                                      root: "mui-input-control mui-label",
                                    },
                                  }}
                                  variant="outlined"
                                  error={Boolean(addressInvalidFields.street)}
                                  helperText={addressInvalidFields.street}
                                  fullWidth
                                  onChange={(event) => {
                                    this.setState({
                                      street: event.target.value,
                                      userAddressChanged: true,
                                    });
                                  }}
                                  placeholder="Logradouro"
                                />
                              </div>
                            </Col>
                            <Col md={4} lg={3}>
                              <div className="form-group">
                                <TextField
                                  label="Número"
                                  inputRef={this.numberRef}
                                  InputProps={{
                                    classes: {
                                      input: "mui-input-control",
                                      root: "mui-input-control",
                                    },
                                    type: "number",
                                    inputMode: "tel",
                                    pattern: "[0-9]*",
                                  }}
                                  InputLabelProps={{
                                    classes: {
                                      root: "mui-input-control mui-label",
                                    },
                                  }}
                                  variant="outlined"
                                  value={number || ""}
                                  error={Boolean(addressInvalidFields.number)}
                                  helperText={addressInvalidFields.number}
                                  fullWidth
                                  onChange={(event) => {
                                    this.setState({
                                      number: event.target.value,
                                      userAddressChanged: true,
                                    });
                                  }}
                                  placeholder="Número"
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={5}>
                              <div className="form-group">
                                <TextField
                                  label="Complemento"
                                  InputProps={{
                                    classes: {
                                      input: "mui-input-control",
                                      root: "mui-input-control",
                                    },
                                  }}
                                  InputLabelProps={{
                                    classes: {
                                      root: "mui-input-control mui-label",
                                    },
                                  }}
                                  variant="outlined"
                                  value={additional || ""}
                                  error={Boolean(additionalMessage)}
                                  helperText={additionalMessage}
                                  fullWidth
                                  onChange={(event) => {
                                    this.setState({
                                      additional: event.target.value,
                                      userAddressChanged: true,
                                    });
                                  }}
                                  placeholder="Complemento"
                                />
                              </div>
                            </Col>
                            <Col md={7}>
                              <div className="form-group">
                                <TextField
                                  label="Bairro"
                                  InputProps={{
                                    classes: {
                                      input: "mui-input-control",
                                      root: "mui-input-control",
                                    },
                                  }}
                                  InputLabelProps={{
                                    classes: {
                                      root: "mui-input-control mui-label",
                                    },
                                  }}
                                  variant="outlined"
                                  value={neighborhood || ""}
                                  error={Boolean(addressInvalidFields.neighborhood)}
                                  helperText={addressInvalidFields.neighborhood}
                                  fullWidth
                                  onChange={(event) => {
                                    this.setState({
                                      neighborhood: event.target.value,
                                      userAddressChanged: true,
                                    });
                                  }}
                                  placeholder="Bairro"
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div className="form-group">
                                <TextField
                                  label="Cidade"
                                  InputProps={{
                                    classes: {
                                      input: "mui-input-control",
                                      root: "mui-input-control",
                                    },
                                  }}
                                  InputLabelProps={{
                                    classes: {
                                      root: "mui-input-control mui-label",
                                    },
                                  }}
                                  variant="outlined"
                                  value={city || ""}
                                  error={Boolean(addressInvalidFields.city)}
                                  helperText={addressInvalidFields.city}
                                  fullWidth
                                  onChange={(event) => {
                                    this.setState({
                                      city: event.target.value,
                                      userAddressChanged: true,
                                    });
                                  }}
                                  placeholder="Cidade"
                                />
                              </div>
                            </Col>

                            <Col md={3} lg={3}>
                              <FormControl variant="outlined">
                                <select
                                  className="form-control select-focus-fix"
                                  value={state || ""}
                                  onChange={(event) => {
                                    this.setState({
                                      state: event.target.value,
                                      userAddressChanged: true,
                                    });
                                  }}
                                >
                                  <option value="UF">UF</option>
                                  {this.states.map((state) => (
                                    <option value={state} key={state}>
                                      {state}
                                    </option>
                                  ))}
                                </select>
                              </FormControl>
                            </Col>
                          </Row>
                          <Row>
                            <div
                              className="form-group "
                              style={{
                                textAlign: "center",
                                marginLeft: 20,
                                marginRight: 20,
                              }}
                            >
                              <button
                                className="btn btn-fill btn-block btn-round btn-mlm"
                                style={{ marginTop: 15 }}
                                onClick={this.updateCorpAddress}
                              >
                                <span className="save-text">Salvar</span>
                              </button>
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </>
                  }
                />
              </div>
            </Col>

            <Col md={6}>
              <div data-intro={dataTour.corpProfile.email}>
                <Card
                  title="Email"
                  category="Email para contato e recuperação de senha."
                  content={
                    <>
                      {loading ? <LoadingCover /> : null}
                      <div className="form-group">
                        <TextField
                          label="Email"
                          InputProps={{
                            classes: {
                              input: "mui-input-control",
                              root: "mui-input-control",
                            },
                          }}
                          InputLabelProps={{
                            classes: { root: "mui-input-control mui-label" },
                          }}
                          variant="outlined"
                          value={email || ""}
                          error={Boolean(emailMessage)}
                          helperText={emailMessage}
                          fullWidth
                          onChange={(event) => {
                            this.setState({
                              emailChanged: true,
                              email: event.target.value,
                            });
                          }}
                          placeholder="Email"
                        />
                      </div>
                      <div className="form-group">
                        <button
                          className="btn btn-fill btn-round btn-mlm btn-block"
                          disabled={!emailChanged}
                          onClick={() => {
                            this.setState({
                              swalConfirmMessage: [
                                "info",
                                "Deseja alterar o email?",
                                "O email será alterado somente após confimação.",
                              ],
                              swalShowConfirm: true,
                              alertConfirmation: "email",
                            });
                          }}
                        >
                          Salvar
                        </button>
                      </div>
                    </>
                  }
                ></Card>
              </div>

              <div data-intro={dataTour.corpProfile.socialName}>
                <Card
                  title="Nome fantasia"
                  category="Nome fantasia da corporação."
                  content={
                    <>
                      {loading ? <LoadingCover /> : null}
                      <div className="form-group">
                        <TextField
                          label="Nome fantasia"
                          InputProps={{
                            classes: {
                              input: "mui-input-control",
                              root: "mui-input-control",
                            },
                          }}
                          InputLabelProps={{
                            classes: { root: "mui-input-control mui-label" },
                          }}
                          variant="outlined"
                          value={socialName || ""}
                          error={Boolean(emailMessage)}
                          helperText={emailMessage}
                          fullWidth
                          onChange={(event) => {
                            this.setState({
                              socialNameChanged: true,
                              socialName: event.target.value,
                            });
                          }}
                          placeholder="Nome fantasia"
                        />
                      </div>
                      <div className="form-group">
                        <button
                          className="btn btn-fill btn-round btn-mlm btn-block"
                          disabled={!socialNameChanged}
                          onClick={() => {
                            this.setState({
                              swalConfirmMessage: [
                                "info",
                                "Deseja alterar o nome fantasia?",
                                `O nome fantasia será alterado somente após confimação.`,
                              ],
                              swalShowConfirm: true,
                              alertConfirmation: "socialName",
                            });
                          }}
                        >
                          Salvar
                        </button>
                      </div>
                    </>
                  }
                ></Card>
              </div>
              <Card
                title="Dados Bancarios"
                category="Informações pessoais sobre seus dados bancarios."
                content={<PaymentForm userData={userData} />}
              ></Card>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
