import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';



function ImportContactModal({ open, onClose, csv }) {
  const [update, setUpdate] = useState(true)
  const [error, setError] = useState(false)

  const {
    name,
    invalidFieldsCounter,
    updatedFieldsCounter
  } = csv


  return (
    <Modal
      show={open}
      bsSize="lg"
      onHide={onClose}

    >
      <Modal.Header closeButton>
        <Modal.Title className='title-card-modal'>{name}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "600px", minHeight: "450px", overflowY: "auto" }}>
        <Accordion
          className='table-import-contact-header'
          expanded={ update}
          onChange={()=>setUpdate(!update)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography >Campos atualizados</Typography>
          </AccordionSummary>
          <AccordionDetails>
          {updatedFieldsCounter
          ? <table className="table-import-contact">
              {updatedFieldsCounter?.map((invalid, index) => (
                <tr className={(index % 2 == 0) ? 'table-row-alpha' : 'table-row-beta'}>
                  <th className="table-import-contact-label">{invalid.reason}</th>
                  <th className="table-import-contact-value">{invalid.value}</th>
                </tr>
              ))}
            </table>
          : <div className="flex-center" style={{width:'100%'}}>
              Não possui mais detalhes 
            </div>
          }
          </AccordionDetails>
        </Accordion>
        <Accordion 
          className='table-import-contact-header'
          expanded={ error}
          onChange={()=>setError(!error)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography >Erros de atualização</Typography>
          </AccordionSummary>
          <AccordionDetails>
          {
            invalidFieldsCounter
            ?<table className="table-import-contact">
              {invalidFieldsCounter?.map((invalid, index) => (
                <tr className={(index % 2 == 0) ? 'table-row-alpha' : 'table-row-beta'}>
                  <th className="table-import-contact-label">{invalid.reason}</th>
                  <th className="table-import-contact-value">{invalid.value}</th>
                </tr>
              ))}
            </table>
            : <div className="flex-center" style={{width:'100%'}}>
              Não possui mais detalhes 
            </div>
            }
          </AccordionDetails>
        </Accordion>
      </Modal.Body>
    </Modal>
  )
}

export default ImportContactModal
