import React from 'react'
import { createMuiTheme } from "@material-ui/core/styles";
import BasicPopover from 'components/BasicPopover/BasicPopover';
import formater from 'utils/formater';
import { translateLevel } from 'utils/translate';

const columns = [
  {
    name: "id",
    label: "ID",
    options: {
      setCellProps: () => ({ className: 'no-wrap' }),
      display: false,
      hint: "Identificador do afiliado"

    }
  },
  {
    name: "loginType",
    label: "Acesso",
    options: {
      setCellProps: () => ({ className: 'no-wrap' }),
      sort: false,
      hint: "Tipo de acesso do usuário",
      customBodyRender: (value) => {
        if (!value) return '-'
        return value
      }
    }
  },
  {
    name: "login",
    label: "Login",
    options: {
      setCellProps: () => ({ className: 'no-wrap' }),
      sort: false,
      hint: "Login do usuário",
      customBodyRender: (value) => {
        if (!value) return '-'
        return value
      }
    }
  },
  {
    name: "name",
    label: "Nome",
    options: {
      setCellProps: () => ({ className: 'no-wrap' }),
      sort: true,
      hint: "Nome do afiliado",
      customBodyRender: (value) => {
        if (!value) return '-'
        return value
      }
    }
  },
  {
    name: "level",
    label: "Nível",
    options: {
      setCellProps: () => ({ className: 'no-wrap' }),
      sort: true,
      hint: "Nível do afiliado no sistema",
      customBodyRender: (value) => {
        if (!value) return '-'
        return value
      }
    }
  },
  {
    name: "email",
    label: "Email",
    options: {
      setCellProps: () => ({ className: 'no-wrap' }),
      sort: true,
      hint: "Email do afiliado",
      customBodyRender: (value) => {
        if (!value) return '-'
        return String(value).length > 30 ? (
          <BasicPopover
            noWrap={true}
            text={value}
            buttonText={`${String(value).slice(0,30)}...`}
          />
        ) : (
          value
        )
      }
    }
  },
  {
    name: "telephone",
    label: "Telefone",
    options: {
      setCellProps: () => ({ className: 'no-wrap' }),
      sort: true,
      hint: "Telefone do afiliado",
      customBodyRender: (value) => {
        if (!value) return '-'
        return String(value).length > 30 ? (
          <BasicPopover
            noWrap={true}
            text={value}
            buttonText={`${String(value).slice(0,30)}...`}
          />
        ) : (
          formater.formatTelephone(value)
        )
      }
    }
  },
  {
    name: "cellphone",
    label: "Celular",
    options: {
      setCellProps: () => ({ className: 'no-wrap' }),
      sort: true,
      hint: "Celular do afiliado",
      customBodyRender: (value) => {
        if (!value) return '-'
        return String(value).length > 30 ? (
          <BasicPopover
            noWrap={true}
            text={value}
            buttonText={`${String(value).slice(0,30)}...`}
          />
        ) : (
          formater.formatPhone(value)
        )
      }
    }
  },
  {
    name: "createdAt",
    label: "Data cadastro",
    options: {
      setCellProps: () => ({ className: 'no-wrap' }),
      sort: true,
      hint: "Data que o afiliado realizou o cadastro.",
      customBodyRender: (value) => {
        if (!value) return '-'
        return value
      }
    }
  }
]

const getMuiTheme = () => {
  createMuiTheme({
    typography: {
      fontFamily: '"Montserrat"',
      fontSize: 14
    },
    overrides: {
      typography: {
        fontFamily: '"Montserrat"',
        fontSize: 14
      },
      MUIDataTable: {
        root: {
          fontSize: 44
        }
      },
      MuiTypography: {
        root: {
          fontFamily: '"Montserrat"',
          fontSize: 14
        }
      },
      MUIDataTableBodyCell: {
        root: {
          fontSize: 14,
          fontFamily: "Montserrat",
          lineHeight: "20px",
          fontWeight: "500",
          color: "#616161",
          textAlign: "left"
        }
      },
      MUIDataTableHeadCell: {
        root: {
          fontSize: 14,
          fontFamily: "Montserrat",
          lineHeight: "20px",
          fontWeight: "600",
          color: "#414141",
          textAlign: "left"
        }
      }
    }
  });
}

const levelOptions = (strictId) => [
  { value: 'master', label: 'Master' },
  { value: 'submaster', label: 'Sub Master' },
  { value: 'corporation', label: 'Corporação' },
  { value: 'subcorp', label: 'Sub Corporação' },
  { value: 'administrator', label: translateLevel('administrator', strictId) },
  { value: 'supervisor', label: translateLevel('supervisor', strictId) },
  { value: 'seller', label: translateLevel('seller', strictId) },
  { value: 'private', label: 'Gerente Privado' },
  { value: 'exclusive', label: 'Gerente Exclusivo' },
  { value: 'superintendent', label: 'Superintendente' },
  { value: 'executive', label: 'Gerente Executivo' },

]

const typeOptions = [
  { value: '', label: 'Todos' },
  { value: 'partner', label: 'Parceiro' },
  { value: 'maraberto', label: 'Mar Aberto' },
  { value: 'corban', label: 'CORBAN' },
  { value: 'corp', label: 'Corporação' },
  { value: 'manager', label: 'Gerente' }
]

export { columns, getMuiTheme, levelOptions, typeOptions }

