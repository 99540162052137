import React, { Component } from 'react'
import mainApi from 'services/mainApi'
import getUserData from 'utils/checkUser'
import { Grid, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MUIDataTable from 'mui-datatables'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { faFrown } from '@fortawesome/free-regular-svg-icons'
import spinner from 'assets/img/spinner.gif'
import getMuiTheme from 'utils/getMuiTheme'
import { firstAndLastWord } from 'utils/formatData'
import { introSystem } from 'utils/introToSystem'
import formater from 'utils/formater'
import InputMuiField from 'components/InputMuiField'
import Button from 'components/Buttons/Button'
import { CircularProgress } from '@material-ui/core'
import { getMonthAndYear } from 'utils/formatData'
import { translateMonths } from 'utils/translate'
import moment from 'moment'
import MahoeIcon from 'components/MahoeIcons'
import DatePicker from 'react-datepicker'
import { translateLevel } from 'utils/translate';

import 'react-datepicker/dist/react-datepicker.css'
import LoadingGif from 'components/loadingGif'
import BasicPopover from 'components/BasicPopover/BasicPopover'
import { shortenValue } from 'utils/formatData'
import { ISClubeAmigo } from "utils/environment"
import { Link } from 'react-router-dom'

const LIMIT = 25

class SupervisedTeam extends Component {

  constructor(props) {
    super(props)

    this.state = {
      userData: null,
      sellers: [],
      totalDocs: 0,
      page: 0,
      handleSearch: '',
      loading: null,
      selectDate: new Date()
    }

    this.tableOptions = (docs, page) => ({
      elevation: 0,
      filterType: 'dropdown',
      responsive: 'scrollMaxHeight',
      selectableRows: 'none',
      rowsPerPage: LIMIT,
      count: docs || 0,
      serverSide: true,
      pagination: true,
      page,
      filter: false,
      download: false,
      print: false,
      search: false,
      rowsPerPageOptions: [25],
      textLabels: {
        pagination: {
          next: 'Próxima Página',
          previous: 'Pagina Anterior',
          rowsPerPage: 'Linhas por página:',
          displayRows: 'de'
        },
        toolbar: {
          search: 'Procurar',
          downloadCsv: 'Download CSV',
          print: 'Imprimir',
          viewColumns: 'Mostrar Colunas',
          filterTable: 'Filtrar Tabela'
        },
        filter: {
          title: 'FILTROS',
          reset: 'limpar',
          all: 'Todos'
        },
        viewColumns: {
          title: 'Mostrar Colunas'
        }
      },
      onTableChange: (action, tableState) => {
        switch (action) {
          case 'changePage':
            this.setState({ page: tableState.page, name: '' }, () => {
              this.getSupervisorSellers()
            })
            break
          case 'propsUpdate':
            if (page !== tableState.page) tableState.page = 0;
            break
        }
      },
    })

    this.teamHeader = [
      {
        name: 'name',
        label: 'Nome Completo',
        options: {
          hint: 'Nome completo do usuário.',
          filter: true,
          sort: true,
          setCellProps: () => ({ className: 'no-wrap' }),
          customBodyRender: (value) => {
            const formatedValue = firstAndLastWord(value)
            const shortenName = shortenValue(formatedValue)

            return shortenName ?
              <BasicPopover
                noWrap={true}
                text={formatedValue}
                buttonText={shortenName}
              />
              : formatedValue
          }
        }
      },
      {
        name: 'email',
        label: 'Email',
        options: {
          hint: 'Email do usuário.',
          filter: true,
          sort: true,
          setCellProps: () => ({ className: 'no-wrap' }),
          customBodyRender: (value) => {
            const shortenEmail = shortenValue(value, 20)

            return shortenEmail ?
              <BasicPopover
                noWrap={true}
                text={value}
                buttonText={shortenEmail}
              />
              : value
          }
        }
      },
      {
        name: 'telephone',
        label: 'Telefone Fixo',
        options: {
          hint: 'Telefone fixo cadastrado.',
          filter: true,
          sort: true,
          setCellProps: () => ({ className: 'no-wrap' }),
          customBodyRender: (value) => formater.formatTelephone(value)
        }
      },
      {
        name: 'cellphone',
        label: 'Celular',
        options: {
          hint: 'Celular do usuário.',
          filter: true,
          sort: true,
          setCellProps: () => ({ className: 'no-wrap' }),
          customBodyRender: (value) => formater.formatPhone(value),
        }
      },
      {
        name: 'datas.paid',
        label: 'Qtd. aprovado',
        options: {
          // hint: 'Telefone fixo cadastrado.',
          filter: false,
          sort: false
        }
      },
      {
        name: 'datas.pending',
        label: 'Qtd. análise',
        options: {
          // hint: 'Telefone fixo cadastrado.',
          filter: false,
          sort: false
        }
      },
      {
        name: 'datas.refused',
        label: 'Qtd. recusado',
        options: {
          // hint: 'Telefone fixo cadastrado.',
          filter: false,
          sort: false
        }
      },
      {
        name: 'createdAt',
        label: 'Data de Cadastro',
        options: {
          hint: 'Data de cadastro no sistema.',
          filter: true,
          sort: true
        }
      },
      {
        name: 'unlink',
        label: 'Desvinculado',
        options: {
          sort: false,
          setCellProps: () => ({ className: 'no-wrap' }),
          customBodyRender: (value) => {

            return value ? "Sim" : "Não"
          }
        }
      },
      {
        name: 'cpf',
        options: {
          display: false
        }
      },
      ISClubeAmigo ?
      {
        name: '',
        label: 'Relatório de vouchers',
        options: {
          customBodyRender: (value, rowIndex) => (
            <Link
              to={{
                pathname: 'user-voucher-report',
                state: rowIndex?.rowData[9]
              }}
            >
              Acessar
            </Link>
          )
        }
      } : {
        name: '',
        options: {
          display: false
        }
      },
    ];
  }

  setQuery = () => {
    const { page, name, selectDate } = this.state

    let query = ''
    query += `?limit=${LIMIT}&page=${page + 1}`
    if (name) query += `&name=${name}`
    if (selectDate) query += `&date=${moment(selectDate).format("YYYY-MM")}`

    return query
  }

  getSupervisorSellers = async () => {

    const query = this.setQuery()

    this.setState({ loading: true })
    try {
      const response = await mainApi.get(`/team/supervisor${query}`)

      const tempSellers = response.data.sellers.docs
      const totalDocs = response.data.sellers.totalDocs

      let sellers = []

      tempSellers.forEach(person => {
        if (person.name) person.name = firstAndLastWord(person.name)
        if (!person.telephone) person.telephone = 'n/a';
        if (!person.cellphone) person.cellphone = 'n/a';

        sellers.push(person);
      });

      this.setState({ sellers, totalDocs })

    } catch (err) {
      console.log(err)
    }
    this.setState({ loading: false })
  }

  mountUserData = async () => {
    const userData = await getUserData(this.props.history);

    this.setState({ userData });
  }

  componentDidMount = async () => {
    await this.mountUserData()

    const { routeKey } = this.props
    const { userData } = this.state

    if (!userData) {
      this.props.history.push('/');
      return
    }

    if (userData.level === 'supervisor') {
      await this.getSupervisorSellers();
    }

    introSystem(routeKey);
  };

  handleSearch = () => {
    this.setState({ page: 0 }, () => this.getSupervisorSellers())
  }

  render() {
    const { sellers, loading, userData, totalDocs, page, name, selectDate } = this.state

    return (
      <div className='content'>
        <Grid fluid>
          <Row>
            <Col md={4} lg={4} sm={12}>
              <InputMuiField
                label='Pesquisar por nome'
                value={name}
                onChange={(event) => {
                  this.setState({ name: event.target.value })
                }}
              />
            </Col>

            <Col md={2} lg={2} sm={12} className='mb-md-3'>
              <Button onClick={this.handleSearch}>
                Filtrar
              </Button>
            </Col>
            <Col xsOffset={3} md={3} lg={3} sm={12}>
              <DatePicker
                className={`datepicker-theThird ${loading ? 'datepicker-theThird-disabled' : ''}`}
                dateFormat='MM/yyyy'
                locale='pt-BR'
                disabled={loading}
                customInput={
                <div className='div-datepicker' style={{height:'initial'}}>
                  <div className='col-6'>
                    <span className='placeholder-datepicker'>
                      {selectDate ? 'Mês selecionado:' : 'Selecione um mês'}
                    </span>
                  </div>
                  <div className='col-3'>
                    <p className='date-datepicker'>
                      {selectDate ? getMonthAndYear(selectDate) : ''}
                    </p>
                  </div>
                  <div
                    className='col-3'
                    style={{ alignSelf: 'center' }}
                    onMouseEnter={(event) => this.handleMouse(event)}
                    onMouseLeave={() =>
                      this.setState({ openPopper: false })
                    }
                  >
                    {selectDate ? (
                      <></>
                    ) : (
                      <MahoeIcon
                        aria-describedby={'iconTooltip'}
                        name='info'
                        size='23px'
                        fill='#039BE5'
                      />
                    )}
                  </div>
                </div>
                }
                selected={selectDate}
                showMonthYearPicker
                onChange={(date, event) => this.setState({ selectDate: date }, () => this.getSupervisorSellers())}
                maxDate={new Date()}
                minDate={new Date('2020-01-01')}
              />
            </Col>

            <Col md={12}>
              <div className='content-fluid'>
              {
                loading
                ? (
                    <div className="card">
                      <LoadingGif />
                    </div>
                  )
                : (
                  sellers.length > 0 ? (
                    <div>
                      <MuiThemeProvider theme={getMuiTheme}>
                        <MUIDataTable
                          title={
                            <div style={{ display: 'inline-flex' }}>
                              <h5 variant='title'>
                                {`Equipe de ${firstAndLastWord(userData.name)}`}
                              </h5>
                              {loading && (
                                <CircularProgress
                                  size={24}
                                  style={{
                                    marginLeft: 15,
                                    position: 'relative',
                                    top: 4
                                  }}
                                />
                              )}
                            </div>
                          }
                          columns={this.teamHeader}
                          options={this.tableOptions(totalDocs, page)}
                          data={sellers}
                        />
                      </MuiThemeProvider>
                    </div>
                  ) : (
                    <div className="card">
                      <div className='no-seller-here'>
                        <FontAwesomeIcon
                          icon={faFrown}
                          size='6x'
                          style={{ marginRight: 4 }}
                        />
                        <span>
                          Você não tem nenhum {translateLevel('seller', userData?.corpId)} cadastrado.{' '}
                        </span>
                      </div>
                    </div>
                  )
                )
              }
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

export default SupervisedTeam