import React from "react";
import ReactDOM from "react-dom";
import mainApi from "services/mainApi";
import { Dialog } from "components/Alert/CustomAlert";

const refreshToken = async (history, sessionTime) => {
  const refreshToken = localStorage.getItem("@uxtech-mlm/UserRefreshToken");
  if (!refreshToken) return;

  const lastTokenRefresh = localStorage.getItem("@uxtech-mlm/LastTokenRefresh");
  const currentDate = Date.now();

  if (lastTokenRefresh && currentDate - lastTokenRefresh >= sessionTime) {    
    localStorage.clear();    
    ReactDOM.render(
      <Dialog
        show
        type="info"
        title="Sua sessão expirou!"
        text="Faça o login novamente."
        onConfirm={() => {
            localStorage.clear();
            document.location.href = "/";
          }}
        />,
        document.getElementById("exit-swal")
    );
    return;
  } else if ((currentDate - lastTokenRefresh) < (sessionTime * 0.80)) {
    return;
  } else {
    try {
      console.log("New Token Date" + Date())
      const response = await mainApi.post(
        "/tokens",
        { token: refreshToken }
      );
      const newToken = response.data.data.token;
      localStorage.setItem("@uxtech-mlm/UserToken", "Bearer " + newToken);
      localStorage.setItem("@uxtech-mlm/LastTokenRefresh", currentDate);
    } catch (err) {
      console.log(err)
      if (err.response && (err.response.status === 401 || err.response.status === 403) ) {        
        console.log('session error')
        ReactDOM.render(
          <Dialog
            show
            type="info"
            title="Sua sessão expirou!"
            text="Faça o login novamente."
            onConfirm={() => {
                localStorage.clear();
                document.location.href = "/";
              }}
            />,
            document.getElementById("exit-swal")
        );
      } else {
        console.log(err)
      }
    }
  }
};

export default refreshToken;
