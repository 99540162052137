import React, { Component } from "react";
import { Col, Grid, Row } from "react-bootstrap";

import mainApi from "services/mainApi";
import getUserData from "utils/checkUser";
import { LoadingAlert, Dialog } from "../components/Alert/CustomAlert";
import CorpDetailsCard from "../components/CorpDetailsCard";
import queryString from "query-string";
import Agencies from "components/Agencies/Agencies";
import { formatToBr } from 'utils/formaterDate';
import { ISClubeAmigo } from "utils/environment";

import swal from 'components/Alert/alert'

import { introSystem } from 'utils/introToSystem';

export default class CorpDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      description: "",
      cnpj: "",
      userData: {},
      corporation: {},
      corporationUser: {},
      swalMessage: [],
      swalMessageShow: false,
      swalShowLoading: false,
      cnpjMessage: "",
      nameMessage: "",
      descriptionMessage: "",
      hasManager: "",
      corpId: undefined,
      agencies: [],
      loading: true,
      showCreate: false,
      totalDocs: undefined,
      exportDocument: false,
      queryStrings: {},
      page: 1,
      agenciesFooter: {}
    };
  }

  getCorpData = async (options = {}) => {
    const { userData, queryStrings } = this.state;

    if(!options.noLoading)
      this.setState({ swalShowLoading: true })
    let endpoint = "";
    if (['corporation', 'subcorp'].includes(userData.level)) endpoint = "/corporation";
    else endpoint = `/corporation/${queryStrings.id}`;

    const response = await mainApi.get(endpoint, {
      headers: { Authorization: userData.token },
    });

    let corporation = response.data.corporation;
    let corporationUser = response.data.corporation.user;
    this.setState({ swalShowLoading: false })

    this.setState({
      corporation,
      corporationUser,
    });
  };

  getCorpAgencies = async (query = '') => {
    const { userData, queryStrings, page } = this.state;

    // query += `?page=${page}`

    this.setState({ swalShowLoading: true })
    try {
      let endpoint = "";

      if (['corporation', 'subcorp'].includes(userData.level)) {
        endpoint = `/agencies${query}`;
        if (ISClubeAmigo) {
          const response = await mainApi.get(`/agencies/users/count${query}`)
          this.setState({ agenciesFooter: response?.data?.data })
        }
      }
      else endpoint = `/corporation/${queryStrings.id}/agencies${query}`;
      this.setState({ loading: true });

      const response = await mainApi.get(endpoint, {
        headers: { Authorization: userData.token },
      });

      const totalDocs = response.data.agencies.totalDocs;
      const agencies = response.data.agencies.docs

      this.setState({ agencies, totalDocs, loading: false });
    } catch(err) {
      console.log(err)
    }
    this.setState({ swalShowLoading: false })
  };

  downloadCsv = async () => {

    const { userData, queryStrings } = this.state

    await this.setState({ exportDocument: true })

    const date = formatToBr(new Date())

    const query = this.setQuery()

    this.setState({ swalShowLoading: true })
    try {
      let endpoint = "";

      if (['corporation', 'subcorp'].includes(userData.level)) endpoint = `/agencies${query}`;
      else endpoint = `/corporation/${queryStrings.id}/agencies${query}`;
      this.setState({ loading: true });

      const response = await mainApi.get(endpoint, {
        headers: { Authorization: userData.token },
      });

      const csv = response.data.csv

      if (csv) {
        let file = "text/CSV;charset=utf-8,%EF%BB%BF" + encodeURI(csv);
        let filename = `lista_parceiros_${date}`;
        this.createDownloadLink(file, filename);
      } else {
        swal.basic(
          "warning",
          "Documento não disponível",
          "O documento solicitado nao possui nenhum registro."
        )
      }
    } catch (err) {
      swal.basic(
        "warning",
        "Algo deu errado",
        err.message
      )
    }
    this.setState({ swalShowLoading: false, exportDocument: false })
  }

  createDownloadLink = (file, filename) => {
    let a = document.createElement('a');
    a.href = "data:" + file;
    a.download = filename + ".csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  componentDidMount = async () => {
    const { routeKey } = this.props;

    await this.setUserData();
    await this.setQueryString();
    await this.getTableData();

    if(routeKey) introSystem(routeKey)
  };

  setUserData = async () => {
    const userData = await getUserData(this.props.history);

    this.setState({ userData });
  };

  setQueryString = async () => {
    const queryStrings = await queryString.parse(this.props.location.search);

    this.setState({ queryStrings });
  };

  setQuery = () => {
    const { page, name, blocked, sort, exportDocument } = this.state;

    let query = "";

    if (page) query += `?page=${page}`;
    if (name) query += `&name=${name}`;
    if (blocked) query += `&blocked=${blocked.value}`;
    if (sort) query += `&sort=${sort}`
    if (exportDocument) query += "&export=true"

    return query;
  };

  searchButton = async (name, blocked) => {
    await this.setState({ name, blocked });
    this.resetPageAndCallSetList();
  };

  onSortChange = async (value) => {
    await this.setState({ sort: value });

    const query = this.setQuery();

    this.getCorpAgencies(query)
  }

  changePage = async (page) => {
    await this.setState({ page: page + 1 });

    const query = this.setQuery();
    this.getCorpAgencies(query);
  };

  resetPageAndCallSetList = () => {
    this.changePage(0);
  };

  getTableData = async () => {
    const { userData, queryStrings } = this.state;

    const corpId = queryStrings.id;
    if (['master', 'submaster'].includes(userData.level)) await this.getCorpData();

    this.getCorpAgencies("?page=1");
    this.setState({ userData, corpId });
  };

  handleCep = async (event) => {
    const searchCep = event.target.value;
    if (searchCep === this.state.zipcode) return;
    this.setState({ zipcode: searchCep });
    const rawCep = searchCep.replace(/\D/g, "");
    if (rawCep.length === 8) {
      try {
        const response = await mainApi.get(`/resources/cep/${rawCep}`);

        const {
          street,
          neighborhood,
          city,
          state,
          additional,
        } = response.data.address;

        this.setState({ street, neighborhood, city, state, additional });
        this.numberRef.current.focus();
      } catch (error) {}
    }
  };

  render() {
    const {
      agencies,
      swalShowLoading,
      swalMessage,
      swalMessageShow,
      corporation,
      corporationUser,
      userData
    } = this.state;

    let agenciesWithoutmain

    if(userData.hasManager){
      agenciesWithoutmain = agencies.filter(agency => !agency.main)
    }

    return (
      <div className="content">
        <Dialog
          type={swalMessage[0]}
          show={swalMessageShow}
          title={swalMessage[0] === "success" ? "Sucesso!" : "Erro"}
          onConfirm={() => {
            this.setState({ swalMessageShow: false });
            if (swalMessage[0] === "success") {
              this.getCorpAgencies();
            }
          }}
          text={swalMessage[1]}
          boldText={swalMessage[2]}
        />

        <LoadingAlert show={swalShowLoading} />

        <Grid fluid>
          <Row>
            {userData && userData.level !== "corporation" && userData.level !== "subcorp" ? (
              <Col md={12}>
                <CorpDetailsCard
                  getCorpData={this.getCorpData}
                  corporation={corporation}
                  userData={userData}
                  corporationUser={corporationUser}
                  loadingCorp={swalShowLoading}
                />
              </Col>
            ) : null}

            <Col md={12}>
              <div className="content-fluid">
                {userData && (
                  <Agencies
                    userData={userData}
                    data={userData.hasManager ? agenciesWithoutmain : agencies}
                    onSortChange={this.onSortChange}
                    token={this.state.userData.token}
                    level={this.state.userData.level}
                    history={this.props.history}
                    totalDocs={this.state.totalDocs}
                    reloadTable={this.getTableData}
                    getCorpAgencies={this.getCorpAgencies}
                    changePage={this.changePage}
                    searchButton={this.searchButton}
                    downloadCsv={this.downloadCsv}
                    page={this.state.page}
                    corporation={corporation}
                    agenciesFooter={this.state.agenciesFooter}
                    loadingAgencies={this.state.swalShowLoading}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
