
function toCPF (value) { 
    if(!value) return
    return `${value.slice(0,3)}.${value.slice(3,6)}.${value.slice(6,9)}-${value.slice(9, 11)}`
}
function toCNPJ (value) { return `${value.slice(0,2)}.${value.slice(2,5)}.${value.slice(5,8)}/${value.slice(8, 12)}-${value.slice(12,14)}`}

function toCellphone (value){
  if (!value) return
  return String(value).replace('^\+[1-9]{1}[0-9]{3,14}$')
}
function toJustFirstName (value) {
    if(!value) return ''
    const words = value.split(' ')
    if(words.length > 0)
        return words.map((word, index) => index === 0 ? word : word[0]?.toUpperCase()).join(' ')
}

function separeteValuesByComma(states) {  
    if(!states) return []
    if(states.length < 1) return states      
    return states.reduce((text, value, i, array) => text + (i < array.length - 1 ? ', ' : ' e ') + value);  
}

function camalize(str) {
    return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}

const formatPhone = (phone) => {
    if (!phone || phone === null) return ''
    if (phone.length <= 5) return phone
    return `(${phone.substr(0, 2)}) ${phone.substr(2, 5)}-${phone.substr(7)}`
}

const formatTelephone = (phone) => {
  if (!phone || phone === null) return ''
  if (phone.length <= 5) return phone
  return `(${phone.substr(0, 2)}) ${phone.substr(2, 4)}-${phone.substr(6)}`
}

const getWeekDay = (item) => {
  switch(item) {
    case 'monday': return 2
    case 'tuesday': return 3
    case 'wednesday': return 4
    case 'thursday': return 5
    case 'friday': return 6
    case 'saturday': return 7
    case 'sunday': return 1
    default: return 
  }
}

const getMonthNumber = (item) => {
  switch(item) {
    case 'january': return 0
    case 'february': return 1
    case 'march': return 2
    case 'april': return 3
    case 'may': return 4
    case 'june': return 5 
    case 'july': return 6
    case 'august': return 7
    case 'september': return 8
    case 'october': return 9
    case 'november': return 10
    case 'december': return 11
    default: return 
  }
}


function initials(name) {

  let tempInitial = ''

  if(!name) return initials
  if(name.indexOf(' ') === -1) tempInitial = name.slice(0, 1).toUpperCase()
  tempInitial = name.split(" ").map((n, i, a) => i === 0 || i+1 === a.length ? n[0] : null).join("").toUpperCase()

  return tempInitial
}

export default { toCPF, toJustFirstName, formatPhone, separeteValuesByComma, camalize, toCNPJ, getWeekDay, getMonthNumber,initials, formatTelephone }