import React, { Component } from 'react';
import mainApi from "services/mainApi";
import { translateStatus } from 'utils/translate';
import { formatToBrWithHour } from 'utils/formaterDate';
import LoadingGif from "components/loadingGif";
import { Dialog } from 'components/Alert/CustomAlert'
import { LoadingAlert } from 'components/Alert/CustomAlert';
import PreRegisterNoAccount from './PreRigisterNoAccount'

class PreRegister extends Component {

  constructor(props) {
    super(props)

    this.state = {
      token: null,
      affiliate: null,
      loading: true,      
      loadingScreen: false,
      storageStatus: null,      
      swalInfo: {show: false, text: '', title: ''}            
    }
  }

  getToken = () => {
    const token = localStorage.getItem('@uxtech-mlm/UserToken');           
    this.setState({ token })
  }

  getUserAnalysis = () => {
    const userStatus = localStorage.getItem('@uxtech-mlm/UserAnalysis')

    if(userStatus === null || userStatus === 'approved') this.returnHome();
  }

  getAnalysis = async () => {
    const { token } = this.state       

    if(token === null) this.returnHome();    
    
    this.setState({ loading: true })    
    try {
      const response = await mainApi.get('/analysis', { headers: { Authorization: token } })
      const value = response.data.data                       
      
      const data = {
        name: value.name,
        message: value.message,
        status: translateStatus(value.analysis),     
        reprovedSituation: value.reprovedSituation      
      }            
      
      if(value.analysis === 'approved') this.returnHome();

      if(value.lastAnalysisRequest) data.lastRequest = formatToBrWithHour(value.lastAnalysisRequest)
      if(value.lastAnalysisResponse) data.lastResponse = formatToBrWithHour(value.lastAnalysisResponse)

      this.setState({ affiliate: data })

    } catch(err) {      
      if(err.response) {      

        if(err.response.data.message === 'Acesso negado: usuário não autenticado.') this.returnHome()

        this.setSwalInfos(true, 'Ops!', err.response.data.message, 'warning')      
      }            
    }
    this.setState({ loading: false })
  }

  tryAgainAnalysis = async () => {
    const { token } = this.state            

    this.setState({ loadingScreen: true })
    try {
      const response = await mainApi.post('/analysis', { headers: { Authorization: token } })      

      this.setSwalInfos(true, 'Sucesso!', response.data.message, 'success')           
    } catch(err) {      
      if(err.response) {
        this.setSwalInfos(true, 'Ops!', err.response.data.message, 'warning')      
      }      
    }
    this.setState({ loadingScreen: false })
    
  }    

  returnHome = () => {
    const { history } = this.props
    localStorage.clear()    
    history.push("/")    
  }

  setSwalInfos = (show, title, text, type) => {
    this.setState({ 
      swalInfo: { show, title, text, type }
    })
  }

  componentDidMount = async () => {
    await this.getToken()
    await this.getUserAnalysis()
    this.getAnalysis()    
  }  

  render() {
    const { affiliate, swalInfo, loading, loadingScreen } = this.state             

    return(      
      <>        
        <LoadingAlert show={loadingScreen} />

        <Dialog
          type={swalInfo.type}
          show={swalInfo.show}
          title={swalInfo.title}
          text={swalInfo.text}
          onConfirm={() => {
            if(swalInfo.text === 'Acesso negado: usuário não autenticado.') {              
              this.returnHome()
              window.location.reload()
            }

            this.setSwalInfos(false)
            this.getAnalysis()             
          }}
        />

        <div className="flex-center" style={{ height: '100%', backgroundColor: 'var(--primary-clear-color)' }}>
          
            {loading ? 
              <div style={{ padding: 20 }}>
                <LoadingGif />      
              </div>              
            :                
              <>
                {affiliate.status === "Reprovado" && affiliate.reprovedSituation === 'without_account' ? 
                  <PreRegisterNoAccount 
                    affiliate={affiliate}
                    tryAgainAnalysis={this.tryAgainAnalysis} />
                :                
                  <div className="card card-bootstrap text-center">
                    <div className={`card-header ${affiliate.status === 'Pendente' ? 'status-analysis-pending' : 'status-analysis-refused'}`}>
                      Status: {affiliate.status}
                    </div>

                    <div className="card-body">
                      <h5 className="card-title">Olá senhor(a) {affiliate.name}</h5>
                      <p className="card-text">{affiliate.message}</p>

                      <div style={{ paddingBottom: 15 }}>
                        {affiliate.status === "Reprovado" && (
                          <>
                            <p><strong>Ultima requisição: {affiliate.lastRequest}</strong></p>
                            <p><strong>{affiliate.lastResponse ? `Ultima análise: ${affiliate.lastResponse}` : ''}</strong></p>
                          </>
                        )}
                      </div>

                      {affiliate.status === "Reprovado" && (
                        <button className="btn btn-login" style={{ marginBottom: 15, marginRight: 15 }} onClick={() => this.tryAgainAnalysis()}>Tentar novamente</button>
                      )}            
                      <button className="btn btn-login" style={{ marginBottom: 15 }} onClick={() => this.returnHome()}>Retornar</button>
                    </div>
                  </div>                
                }
              </>
            }                                                                                                                                   
        </div>          
      </>
    )
  }
}

export default PreRegister