import React, { Component, createRef } from 'react';
import getUserData from "utils/checkUser";
import mainApi from "services/mainApi";
import { columns, levelOptions, typeAffiliate } from './tableFunctions';
import { Grid, Row, Col } from 'react-bootstrap';
import { booleanAffiliate } from 'utils/translate';
import Select from "react-select";
import { FormControlLabel } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import { formatToBackendFilter, formatToBr } from 'utils/formaterDate';
import { LoadingAlert, Dialog } from 'components/Alert/CustomAlert';
import { firstAndLastWord } from 'utils/formatData';
import { introSystem } from 'utils/introToSystem';
import Toogle from 'react-toggle'
import TableMui from 'components/TableMui'
import swal from '../../components/Alert/alert'

// date-picker
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptbr from 'date-fns/locale/pt-BR'
import { translateLevel } from 'utils/translate';
registerLocale('pt-BR', ptbr)

class UserListMaster extends Component {

  constructor(props) {
    super(props)

    this.state = {
      users: [],
      selectCorporations: [],
      selectAgencies: [],

      showAlert: {
        show: false,
        title: "",
        text: "",
        type: ""
      },

      agencySelectDisable: true,
      corporation: null,
      agency: null,
      userData: null,
      totalDocs: 0,
      page: 1,
      resetPageMui: false,
      name: null,
      level: null,
      affiliate: null,
      blocked: null,
      loading: true,

      startDate: null,
      endDate: null,

      startDateApprove: null,
      endDateApprove: null
    }

    this.agencyRef = createRef()
  }

  showMessage = (type, message, boldMessage) => {
    this.setState({
      swalShowMessage: true,
      swalMessage: [type, message],
      btnTitle: "Entrar",
      loading: false
    });
  };

  setUserData = async () => {
    const userData = await getUserData(this.props.history)

    this.setState({ userData })
  }

  setQuery = () => {
    const { page, name, level, affiliate, blocked, corporation, agency, limit, startDate, endDate, startDateApprove, endDateApprove, sort } = this.state

    let query = ''

    query += `?page=${page}`

    if (limit) query += `&limit=${limit}`
    if (name) query += `&name=${name}`
    if (level) query += `&level=${level}`
    if (affiliate) query += `&affiliate=${affiliate}`
    if (blocked !== null) query += `&blocked=${blocked}`
    if (corporation) query += `&corporationId=${corporation}`
    if (agency) query += `&agencyId=${agency}`
    if (startDate) query += `&start=${formatToBackendFilter(startDate, '00:00')}`
    if (endDate) query += `&end=${formatToBackendFilter(endDate, '23:59')}`
    if (startDateApprove) query += `&startApprove=${formatToBackendFilter(startDateApprove, '00:00')}`
    if (endDateApprove) query += `&endApprove=${formatToBackendFilter(endDateApprove, '23:59')}`
    if (sort) query += `&sort=${sort}`

    return query
  }

  getUsers = async () => {

    const query = await this.setQuery()

    this.setState({ loading: true })
    try {
      const response = await mainApi.get(`users/list${query}`)
      const users = response.data.data.docs
      const totalDocs = response.data.data.totalDocs

      const formatedUser = []

      users.forEach(itens => {
        const temp = {
          id: itens._id,
          name: firstAndLastWord(itens.name),
          level: translateLevel(itens.level),
          email: itens.email,
          createdAt: formatToBr(itens.createdAt),
          approvedAt: formatToBr(itens.approvedAt),
          affiliate: booleanAffiliate(itens.affiliate),
          blocked: itens.level === "administrator" ? itens.agency.blocked  || itens.blocked : itens.blocked,
          agencyName: itens.agencyName,
          agencyId: itens.agencyId,
          corporationId: itens.corporationId,
          corporationName: itens.corporationName
        }

        formatedUser.push(temp)
      })

      this.setState({
        users: formatedUser,
        totalDocs
      })
    } catch (err) {
      console.log(err)
    }
    this.setState({ loading: false })
  }

  changePage = (page) => {
    this.setState({ page: page + 1 }, () => this.getUsers())
  }

  searchByName = (name) => {
    if (name.length === 0) this.setState({ name: null }, () => this.resetPageAndCallUsers())
    if (name.length > 2) this.setState({ name: name }, () => this.resetPageAndCallUsers())
  }

  searchByLevel = (value) => {
    this.setState({ level: value }, () => this.resetPageAndCallUsers())
  }

  blockUser = async (id, isAdmin, value) => {
    try {
      let response
      if (isAdmin) {
        if (!value) {
          response = await mainApi.post(`/agency/block`, { agencyId: id })
        }
        else {
          response = await mainApi.post(`/agency/unblock`, { agencyId: id })
        }
      }
      else {
        response = await mainApi.patch(`/master/user/block/${id}`)
      }

      await this.getUsers()

      this.setShowAlert("success", true, "Sucesso!", response.data.message)

    } catch (err) {
      this.setShowAlert("warning", true, "Atenção!", err.response.data.message)
    }

  }

  setShowAlert = (type, show, title, text) => {
    this.setState({
      showAlert: {
        type,
        show,
        title,
        text
      }
    })
  }

  searchByCorporation = async (value) => {
    this.setState({ corporation: value }, () => this.resetPageAndCallUsers())
    await this.resetAgencies()
    this.freeSelectAgencie()
    this.getAgencies(value)
  }

  freeSelectAgencie = () => {
    this.setState({ agencySelectDisable: false })
  }

  searchByAgencie = (value) => {
    this.setState({ agency: value }, () => this.resetPageAndCallUsers())
  }

  filterAffiliate = (value) => {
    this.setState({ affiliate: value }, () => this.resetPageAndCallUsers())
  }

  filterBlocked = (value) => {
    this.setState({ blocked: value }, () => this.resetPageAndCallUsers())
  }

  AddLimitRows = (limit) => {
    this.setState({ limit }, () => this.resetPageAndCallUsers())
  }

  resetPageAndCallUsers = async () => {
    await this.changePage(0)
    this.getUsers()
  }

  getAgencies = async (id) => {

    if (!id) {
      this.blockSelectAgencie()
      this.resetAgencies()
      return
    }

    try {
      const response = await mainApi(`corporation/list/agencies/${id}`)
      const agencies = response.data.agencies

      this.setState({ selectAgencies: agencies })
    } catch (err) {
      console.log(err)
    }
  }

  resetAgencies = () => {
    this.agencyRef.current.state.value = ''
    this.setState({ agency: null })
  }

  getCorporations = async () => {
    const FIRST_ITEM_SELECT = { value: '', label: 'Todas' }

    try {
      const response = await mainApi('corporations/list')
      const tempData = response.data.corps
      const corporations = [FIRST_ITEM_SELECT, ...tempData]

      this.setState({ selectCorporations: corporations })
    } catch (err) {
      console.log(err)
    }
  }

  fillDataFilters = () => {
    const { name, level, affiliate, blocked, corporation, agency, startDate, endDate, startDateApprove, endDateApprove } = this.state

    const data = {}

    if (name) data.name = name
    if (level) data.level = level
    if (affiliate) data.affiliate = affiliate
    if (blocked) data.blocked = blocked
    if (corporation) data.corporationId = corporation
    if (agency) data.agencyId = agency
    if (startDate) data.start = startDate
    if (endDate) data.end = endDate
    if (startDateApprove) data.startApprove = startDateApprove
    if (endDateApprove) data.endApprove = endDateApprove

    return data
  }

  downloadCsv = async () => {

    const date = formatToBr(new Date())
    const data = this.fillDataFilters()

    this.setState({ loading: true })
    try {
      const response = await mainApi.post(
        'master/extract/affiliates/list',
        data
      )
      const csv = response.data.csv

      if (csv) {
        let file = "text/CSV;charset=utf-8,%EF%BB%BF" + encodeURI(csv);
        let filename = `lista_afiliados_${date}`;

        this.createDownloadLink(file, filename);
      } else {
        this.showMessage(
          "warning",
          "Documento não disponível",
          "O documento solicitado nao possui nenhum registro."
        )
      }
    } catch (err) {
      this.showMessage(
        "warning",
        "Algo deu errado",
        err.message
      )
    }
    this.setState({ loading: false })
  }

  createDownloadLink = (file, filename) => {
    let a = document.createElement('a');
    a.href = "data:" + file;
    a.download = filename + ".csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  searchByCorporation = async (value) => {
    this.setState({ corporation: value }, () => this.resetPageAndCallUsers())
    await this.resetAgencies()
    this.freeSelectAgencie()
    this.getAgencies(value)
  }

  changePage = (page) => {
    this.setState({ page: page + 1 }, () => this.getUsers())
  }

  searchByName = (name) => {
    if (name.length === 0) this.setState({ name: null }, () => this.resetPageAndCallUsers())
    if (name.length > 2) this.setState({ name: name }, () => this.resetPageAndCallUsers())
  }

  searchByLevel = (value) => {
    this.setState({ level: value }, () => this.resetPageAndCallUsers())
  }

  freeSelectAgencie = () => {
    this.setState({ agencySelectDisable: false })
  }

  blockSelectAgencie = () => {
    this.setState({ agencySelectDisable: true })
  }

  searchByAgencie = (value) => {
    this.setState({ agency: value }, () => this.resetPageAndCallUsers())
  }

  filterAffiliate = (value) => {
    this.setState({ affiliate: value }, () => this.resetPageAndCallUsers())
  }

  filterBlocked = (value) => {
    this.setState({ blocked: value }, () => this.resetPageAndCallUsers())
  }

  setStartDate = (date, event) => {
    const verifyInput = this.verifyLengthInputDatePicker(event, date)

    if (verifyInput) this.setState({ startDate: date }, () => this.resetPageAndCallUsers())
  }

  setEndDate = (date, event) => {
    const verifyInput = this.verifyLengthInputDatePicker(event, date)

    if (verifyInput) this.setState({ endDate: date }, () => this.resetPageAndCallUsers())
  }

  setStartDateApprove = (date, event) => {
    const verifyInput = this.verifyLengthInputDatePicker(event, date)

    if (verifyInput) this.setState({ startDateApprove: date }, () => this.resetPageAndCallUsers())
  }

  setEndDateApprove = (date, event) => {
    const verifyInput = this.verifyLengthInputDatePicker(event, date)

    if (verifyInput) this.setState({ endDateApprove: date }, () => this.resetPageAndCallUsers())
  }

  verifyLengthInputDatePicker = (event, date) => {
    const valueInput = event.target.value

    if (valueInput === undefined) return true
    else if (valueInput && valueInput.length > 9) return true
    else if (date === null) return true
    else return false
  }

  resetPageAndCallUsers = async () => {
    await this.changePage(0)
    this.setState({ resetPageMui: true })
  }

  componentDidMount = async () => {
    const { routeKey } = this.props

    await this.setUserData()
    await this.getUsers()
    await this.getCorporations()

    const { userData } = this.state

    window.digitalData = {
      "site": {
        "nome": "Banco Pan"
      },
      "page": {
        "secao": "IndiqueoPan",
        "estadoLogin": "LG",
        "nome": "BP:IndiqueOPan:ListagemAfiliados",
      },
      "user": {
        "crmid": userData.id,
        "tipo": userData.brType
      }
    }

    var evt = new CustomEvent('event-view-start');
    document.body.dispatchEvent(evt);

    introSystem(routeKey)
  }

  resetShowAlert = () => {
    this.setState({
      showAlert: {
        show: false,
        title: "",
        text: "",
        type: ""
      }
    })
  }

  render() {
    const {
      users,
      totalDocs,
      selectCorporations,
      selectAgencies,
      agencySelectDisable,
      startDate,
      endDate,
      startDateApprove,
      endDateApprove,
      loading,
      showAlert } = this.state

    const options = {
      selectableRows: false,
      rowsPerPage: 10,
      filterType: "dropdown",
      responsive: "scrollMaxHeight",
      count: totalDocs,
      serverSide: true,
      pagination: true,
      print: false,
      filter: false,
      search: false,
      rowsPerPageOptions: [10, 15, 30, 50],
      textLabels: {
        body: {
          noMatch: "Desculpe, nenhum dado corresponde a pesquisa"
        },
        pagination: {
          next: "Próxima Página",
          previous: "Pagina Anterior",
          rowsPerPage: "Linhas por página:",
          displayRows: "de"
        },
        toolbar: {
          search: "Procurar",
          downloadCsv: "Download CSV",
          print: "Imprimir",
          viewColumns: "Mostrar Colunas",
          filterTable: "Filtrar Tabela"
        },
        filter: {
          title: "FILTROS",
          reset: "limpar",
          all: "Todos"
        },
        viewColumns: {
          title: "Mostrar Colunas"
        }
      },
      onColumnSortChange: (value, type) => {
        const hasPlus = 'descending' === type ? '-' : ''
        this.setState({ sort: hasPlus + value }, () => {
          this.getUsers()
        })
      },
      onTableChange: (action, tableState) => {
        const { page } = this.state
        switch (action) {
          case 'changePage':
            this.changePage(tableState.page)
            break;
          case 'propsUpdate':
            if (page !== tableState.page + 1) tableState.page = 0;
            break;
          default:
            break;
        }
      },
      onChangeRowsPerPage: (number) => {
        this.AddLimitRows(number)
      },
      onDownload: () => {
        this.downloadCsv()
        return false;
      }
    }

    const newColumns = [
      ...columns,
      {
        name: "blocked",
        label: "Bloqueado",
        options: {
          sort: false,
          customBodyRender: (value, rowIndex) => {
            const ID = rowIndex.rowData[0]
            const level = rowIndex.rowData[2]
            const agencyId = rowIndex.rowData[10]
            const agencyName = rowIndex.rowData[6]
            const name = rowIndex.rowData[1]
            const isAdmin = level === "Administrador" ? true : false
            return (
              <Toogle
                checked={value}
                className="custom-toggle-class"
                onChange={() => {
                  // this.blockUser(isAdmin ? agencyId : ID, isAdmin)
                  if (!value) {
                    swal.confirmNegate(
                      'Warning',
                      `Bloquear`,
                      `Tem certeza de que deseja bloquear ${isAdmin ? 'o administrador' : ''} ${name}? ${isAdmin ? `Os usuários pertencentes à sua loja, ${agencyName}, não conseguirão acessar suas contas.` : ''}`,
                      () => this.blockUser(isAdmin ? agencyId : ID, isAdmin, value)
                    )
                  } else {
                    this.blockUser(isAdmin ? agencyId : ID, isAdmin, value)
                  }
                }}
              />

            )
          }
        }
      },
      {
        name: "agencyId",
        label: "AgencyId",
        options: {
          display: false
        }
      }
    ]

    return (
      <div className="content">
        <LoadingAlert
          show={loading}
        />

        <Dialog
          show={showAlert.show}
          title={showAlert.title}
          text={showAlert.text}
          type={showAlert.type}

          onConfirm={() => this.resetShowAlert()}
        />

        <Grid fluid>
          <Row id="data-tour-user-list-master-filter">
            <Col lg={3} md={6} className="margin-filter-user-list">
              <input
                onChange={(e) => this.searchByName(e.target.value)}
                placeholder="Pesquisar por nome"
                className="input-search-user-list"
                style={{ background: 'white', width: '100%' }}
              />
            </Col>

            <Col lg={3} md={6} className="margin-filter-user-list">
              <Select
                placeholder="Filtrar por corporação"
                styles={{ menu: styles => ({ ...styles, zIndex: 999 }) }}
                options={selectCorporations}
                onChange={(event) => this.searchByCorporation(event.value)}
              />
            </Col>

            <Col lg={3} md={6} className="margin-filter-user-list">
              <Select
                ref={this.agencyRef}
                isDisabled={agencySelectDisable}
                placeholder="Filtrar por loja"
                styles={{ menu: styles => ({ ...styles, zIndex: 999 }) }}
                options={selectAgencies}
                onChange={(event) => this.searchByAgencie(event.value)}
              />
            </Col>

            <Col lg={3} md={6} className="margin-filter-user-list">
              <Select
                placeholder="Filtrar por nível"
                styles={{ menu: styles => ({ ...styles, zIndex: 999 }) }}
                options={levelOptions(this.state.userData?.corpId)}
                onChange={(event) => this.searchByLevel(event.value)}
              />
            </Col>

            <Col lg={3} md={6} className="margin-filter-user-list">
              <DatePicker
                id="data-tour-user-list-master-filter-register-initialDate"
                className="date-picker"
                dateFormat='dd/MM/yyyy'
                placeholderText="Data cadastro inicial"
                locale='pt-BR'
                showYearDropdown
                showMonthDropdown
                isClearable
                selected={startDate}
                onChange={(date, event) => this.setStartDate(date, event)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                maxDate={new Date()}
              />
            </Col>

            <Col lg={3} md={6} className="margin-filter-user-list">
              <DatePicker
                id="data-tour-user-list-master-filter-register-finalDate"
                className="date-picker"
                dateFormat='dd/MM/yyyy'
                placeholderText="Data cadastro final"
                locale='pt-BR'
                showYearDropdown
                showMonthDropdown
                selected={endDate}
                onChange={(date, event) => this.setEndDate(date, event)}
                selectsEnd
                isClearable
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                maxDate={new Date()}
              />
            </Col>

            <Col lg={6} md={6} className="margin-filter-user-list">
              <FormControlLabel
                id="data-tour-user-list-master-filter-block"
                style={{ justifyContent: 'center' }}
                control={
                  <Checkbox
                    color="default"
                    name="permission"
                    onChange={(event) => this.filterBlocked(event.target.checked)}
                  />
                }
                label="Bloqueado."
              />
            </Col>
            <Col lg={3} md={6} className="margin-filter-user-list">
              <Select
                id="data-tour-user-list-master-filter-type"
                placeholder="Filtrar por tipo"
                styles={{ menu: styles => ({ ...styles, zIndex: 999 }) }}
                options={typeAffiliate}
                onChange={(event) => this.filterAffiliate(event.value)}
              />
            </Col>

            <Col lg={3} md={6} className="margin-filter-user-list">
              <DatePicker
                id="data-tour-user-list-master-aproved-initialDate"
                className="date-picker"
                dateFormat='dd/MM/yyyy'
                placeholderText="Data aprovação inicial"
                locale='pt-BR'
                showYearDropdown
                showMonthDropdown
                selected={startDateApprove}
                onChange={(date, event) => this.setStartDateApprove(date, event)}
                isClearable
                selectsStart
                startDate={startDateApprove}
                endDate={endDateApprove}
                minDate={startDateApprove}
                maxDate={new Date()}
              />
            </Col>

            <Col lg={3} md={6} className="margin-filter-user-list">
              <DatePicker
                id="data-tour-user-list-master-aproved-finalDate"
                className="date-picker"
                dateFormat='dd/MM/yyyy'
                placeholderText="Data aprovação final"
                locale='pt-BR'
                showYearDropdown
                showMonthDropdown
                selected={endDateApprove}
                onChange={(date, event) => this.setEndDateApprove(date, event)}
                isClearable
                selectsEnd
                startDate={startDateApprove}
                endDate={endDateApprove}
                minDate={startDateApprove}
                maxDate={new Date()}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <TableMui
                title={`Afiliados encontrados: ${totalDocs}`}
                data={users}
                columns={newColumns}
                options={options}
              />
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

export default UserListMaster