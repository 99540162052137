import React, { Component, createRef } from "react"
import InputMask from "react-input-mask"
import { TextField, InputAdornment } from "@material-ui/core"
import { Card } from "components/Card/Card.jsx"

import mainApi from "services/mainApi"
import getUserData from "utils/checkUser"
import getStatusType from "utils/getStatusType"
import { numberLength } from 'utils/numberMethods'
import { LoadingAlert, Dialog } from "../components/Alert/CustomAlert"
import { introSystem } from 'utils/introToSystem'
import dataTour from 'utils/dataTour'
import { Row, Col, Grid } from 'react-bootstrap'

export default class CreateCorp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      socialName: "",
      description: "",
      cnpj: "",
      commission: 0,
      phone: "",
      email: "",
      corbanCode: '',
      image: null,
      userData: {},
      swalMessage: [],
      swalMessageShow: false,
      swalShowLoading: false,
      cnpjMessage: "",
      nameMessage: "",
      socialNameMessage: "",
      descriptionMessage: "",
      phoneMessage: "",
      commissionMessage: "",
      corbanCodeMessage: "",
      valuePerAccount: 0,
      hasManager: true,
      slug: ""
    };

    this.formRef = createRef()
  }

  showMessage = (type, title, message, boldText) => {
    this.setState({
      swalMessageShow: true,
      swalMessage: [type, title, message, boldText],
      swalShowLoading: false
    });
  };

  translateFields = fields => {
    let translatedFields = [];
    for (let field of fields) {
      switch (field) {
        case "cnpj":
          translatedFields.push(" CNPJ");
          break;
        case "name":
          translatedFields.push(" Nome");
          break;
        case "description":
          translatedFields.push(" Descrição");
          break;
        case "cellphone":
          translatedFields.push(" Celular");
          break;
        case "phone":
        case "telephone":
          translatedFields.push(" Telefone");
          break;
        case "email":
          translatedFields.push(" Email");
          break;
        case "corbanCode":
          translatedFields.push(" Código Corban");
          break;
        default:
          translatedFields.push(" " + field);

      }
    }
    return translatedFields.reverse();
  };

  createCorp = async () => {
    const {
      cnpj,
      name,
      socialName,
      description,
      phone,
      email,
      corbanCode,
      commission,
      image,
      valuePerAccount,
      hasManager,
      slug
    } = this.state;

    const checkFields = [
      "descriptionMessage",
      "nameMessage",
      "cnpjMessage",
      "phoneMessage",
      "emailMessage",
      "socialNameMessage",
      "corbanCodeMessage"
    ];

    checkFields.map(field => this.setState({ [field]: "" }));

    const rawCnpj = cnpj.replace(/\D/g, "");
    const rawPhone = phone.replace(/\D/g, "");
    const formData = new FormData();

    formData.append("cnpj", rawCnpj);
    formData.append("name", name);
    formData.append("socialName", socialName);
    formData.append("description", description);
    formData.append("phone", rawPhone);
    formData.append("email", email);
    formData.append("commission", commission);
    formData.append("hasManager", hasManager);
    formData.append("value", valuePerAccount ? Number(valuePerAccount) : 0);
    formData.append("slug", slug);
    formData.append("corbanCode", corbanCode);

    if (image) formData.append("logo", image);

    if (description && description.length > 150) {
      this.showMessage(
        "warning",
        "Descrição muito longa!",
        "A descrição não pode ter mais de 150 caracteres."
      );
      return;
    }

    try {
      this.setState({ swalShowLoading: true });
      const response = await mainApi.post("/corporations", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      this.setState({ corpsData: response.data.docs });
      this.setState({
        swalShowLoading: false,
        swalMessageShow: true,
        swalMessage: ["success", "Corporação cadastrada!", `${name} foi cadastrada com sucesso!`]
      });

    } catch (err) {

      if (err.code === "ECONNABORTED") {
        this.showMessage(
          "error",
          "Temos um Problema!",
          "Tempo máximo de espera atingido."
        );
        return;
      }
      if (!err.response) {
        this.showMessage(
          "error",
          "Ocorreu um erro!",
          "Não foi possivel se comunicar com o servidor, verifique sua internet e tente novamente."
        );
        return;
      }
      const status = getStatusType(err.response.status)
      if (err.response.status >= 400) {
        if (!err.response.data.fields) {
          this.showMessage(
            status,
            "Temos um Problema!",
            err.response.data.message || `Erro (${err.response.status})`,
          );
          return;
        }
        let invalidFields
        if (err.response.status === 422)
          invalidFields = Object.values(err.response.data.fields).map(field => field.name);
        else
          invalidFields = Object.values(err.response.data.fields);

        invalidFields = this.translateFields(invalidFields);
        this.showMessage(
          status,
          "Temos um Problema!",
          err.response.data.message,
          "( " + invalidFields + " )"
        );

        const fields = err.response.data.fields;

        if (!Array.isArray(fields)) {
          return;
        }
        while (fields.length > 0) {
          let field = fields.pop();
          switch (field.name) {
            case "cnpj": {
              this.setState({ cnpjMessage: field.message || "Insira um CNPJ" });
              break;
            }
            case "name": {
              this.setState({ nameMessage: "Insira a Razão social." });
              break;
            }
            case "socialName": {
              this.setState({ socialNameMessage: "Insira um nome fantasia." });
              break;
            }
            case "description": {
              this.setState({
                descriptionMessage: field.message || "Insira uma Descrição"
              });
              break;
            }
            case "phone": {
              this.setState({
                phoneMessage: field.message || "Insira um telefone válido"
              });
              break;
            }
            case "telephone": {
              this.setState({
                phoneMessage: field.message || "Insira um telefone válido"
              });
              break;
            }
            case "cellphone": {
              this.setState({
                phoneMessage: field.message || "Insira um telefone válido"
              });
              break;
            }
            case "email": {
              this.setState({
                emailMessage: field.message || "Insira um Email válido"
              });
              break;
            }
            case "corbanCode": {
              this.setState({
                emailMessage: field.message || "Insira um Código Corban válido"
              });
              break;
            }
            default: break;
          }
          switch (field) {
            case "cnpj": {
              this.setState({ cnpjMessage: "Insira um CNPJ." });
              break;
            }
            case "name": {
              this.setState({ nameMessage: "Insira um nome." });
              break;
            }
            case "socialName": {
              this.setState({ socialNameMessage: "Insira um nome fantasia." });
              break;
            }
            case "description": {
              this.setState({
                descriptionMessage: field.message || "Insira uma Descrição"
              });
              break;
            }
            case "phone": {
              this.setState({
                phoneMessage: field.message || "Insira um telefone válido"
              });
              break;
            }
            case "telephone": {
              this.setState({
                phoneMessage: field.message || "Insira um telefone válido"
              });
              break;
            }
            case "cellphone": {
              this.setState({
                phoneMessage: field.message || "Insira um telefone válido"
              });
              break;
            }
            case "email": {
              this.setState({
                emailMessage: field.message || "Insira um Email válido"
              });
              break;
            }
            case "corbanCode": {
              this.setState({
                emailMessage: field.message || "Insira um Código Corban válido"
              });
              break;
            }
            default: break;
          }
        }
      } else {
        this.showMessage(
          status,
          "",
          err.response.data.message
        );
      }
    }

  };

  handleFile = event => {
    let file = event.target.files[0];
    if (!file) return;
    let isCorretFiletype = Boolean(['image/png', 'image/jpeg', 'image/jpg'].indexOf(file.type) + 1);
    if (isCorretFiletype)
      this.setState({ image: event.target.files[0] });
    else {
      this.setState({ image: null, swalMessage: ['warning', 'Logo inválida', 'Utilize somente os formatos válidos (png, jpg ou jpeg)'], swalMessageShow: true });
      return false
    }
    return true
  }

  componentDidMount = async () => {
    const { routeKey } = this.props
    const userData = await getUserData(this.props.history);

    window.digitalData = {
      "site": {
        "nome": "Banco Pan"
      },
      "page": {
        "secao": "IndiqueoPan",
        "estadoLogin": "LG",
        "nome": "BP:IndiqueOPan:CriarCorporacao",
      },
      "user": {
        "crmid": userData.id,
        "tipo": userData.brType
      }
    }

    var evt = new CustomEvent('event-view-start');
    document.body.dispatchEvent(evt);

    introSystem(routeKey)
    this.setState({ userData })
  };  

  render() {
    const {
      name,
      socialName,
      cnpj,
      description,
      phone,
      commission,
      corbanCode,
      email,
      swalShowLoading,
      swalMessage,
      swalMessageShow,
      cnpjMessage,
      nameMessage,
      descriptionMessage,
      phoneMessage,
      commissionMessage,
      socialNameMessage,
      corbanCodeMessage,
      emailMessage,
      valuePerAccount      
    } = this.state;

    return (
      <div className="content">
        <Dialog
          type={swalMessage[0]}
          show={swalMessageShow}
          title={swalMessage[1] || ""}
          onConfirm={() => {
            this.setState({ swalMessageShow: false });
            if (swalMessage[0] === "success") {
              setTimeout(() => {
                this.props.history.push("/admin/corporations");
              }, 500);
            }
          }}
          text={swalMessage[2]}
          boldText={swalMessage[3]}
        />

        <LoadingAlert show={swalShowLoading} />

        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                paddingForm
                title="Cadastrar corporação"
                category="Preencha todos os dados para adicionar uma nova corporação"
                content={
                  <div style={{ display: 'flow-root' }}>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <InputMask
                          mask="99.999.999/9999-99"
                          value={cnpj}
                          onChange={event => {
                            this.setState({
                              cnpj: event.target.value,
                              userChanged: true
                            });
                          }}
                        >
                          {() => (
                            <TextField
                              label="CNPJ"
                              data-intro={dataTour.createCorp.cnpj}
                              InputProps={{
                                classes: {
                                  root: "mui-input-control",
                                  input: "mui-input-control",
                                  notchedOutline: "mui-input-focus"
                                },
                                type: "tel",
                                pattern: "[0-9]*",
                                inputMode: "numeric"
                              }}
                              fullWidth
                              InputLabelProps={{
                                classes: {
                                  root: "mui-input-control mui-label"
                                }
                              }}
                              variant="outlined"
                              error={Boolean(cnpjMessage)}
                            />
                          )}
                        </InputMask>
                        <div className="invalid-feedback">{cnpjMessage}</div>
                      </div>

                      <div className="form-group col-md-6">
                        <TextField
                          label="Código Corban"
                          data-intro={dataTour.createCorp.corbanCode}
                          InputProps={{
                            classes: {
                              input: "mui-input-control",
                              root: "mui-input-control"
                            }
                          }}
                          InputLabelProps={{
                            classes: { root: "mui-input-control mui-label" }
                          }}
                          variant="outlined"
                          value={corbanCode}
                          fullWidth
                          error={Boolean(corbanCodeMessage)}
                          onChange={event => {
                            this.setState({
                              corbanCode: event.target.value,
                              userChanged: true
                            });
                          }}
                          placeholder="Código Corban"
                        />
                        <div className="invalid-feedback">{corbanCodeMessage}</div>
                      </div>

                      <div className="form-group col-md-6">
                        <TextField
                          label="Razão social"
                          data-intro={dataTour.createCorp.name}
                          InputProps={{
                            classes: {
                              input: "mui-input-control",
                              root: "mui-input-control"
                            }
                          }}
                          InputLabelProps={{
                            classes: { root: "mui-input-control mui-label" }
                          }}
                          variant="outlined"
                          value={name}
                          fullWidth
                          error={Boolean(nameMessage)}
                          onChange={event => {
                            this.setState({
                              name: event.target.value,
                              userChanged: true
                            });
                          }}
                          placeholder="Razão social"
                        />
                        <div className="invalid-feedback">{nameMessage}</div>
                      </div>

                      <div className="form-group col-md-6">
                        <TextField
                          label="Nome fantasia"
                          data-intro={dataTour.createCorp.socialName}
                          InputProps={{
                            classes: {
                              input: "mui-input-control",
                              root: "mui-input-control"
                            }
                          }}
                          InputLabelProps={{
                            classes: { root: "mui-input-control mui-label" }
                          }}
                          variant="outlined"
                          value={socialName}
                          fullWidth
                          error={Boolean(socialNameMessage)}
                          onChange={event => {
                            this.setState({
                              socialName: event.target.value,
                              userChanged: true
                            });
                          }}
                          placeholder="Nome fantasia"
                        />
                        <div className="invalid-feedback">{socialNameMessage}</div>
                      </div>

                      <div className="form-group col-md-3">
                        <TextField
                          type="number"
                          label="Porcentagem (%) de bonificação do consignado"
                          data-intro={dataTour.createCorp.percentage}
                          InputProps={{
                            classes: {
                              input: "mui-input-control",
                              root: "mui-input-control",
                            },
                            inputMode: "tel",
                            pattern: "[0-9]*",
                            startAdornment: (
                              <InputAdornment
                                style={{
                                  position: "absolute",
                                  marginLeft: Number.isInteger(
                                    Number(commission)
                                  )
                                    ? 40
                                    : 50,
                                }}
                                position="start"
                              >
                                {" "}
                          %
                              </InputAdornment>
                            ),
                          }}
                          InputLabelProps={{
                            classes: {
                              root: "mui-input-control mui-label",
                            },
                          }}
                          // eslint-disable-next-line react/jsx-no-duplicate-props
                          inputProps={{
                            step: "0.1",
                          }}
                          variant="outlined"
                          value={commission}
                          fullWidth
                          error={Boolean(commissionMessage)}
                          onFocus={element => element.target.select()}
                          onChange={(event) => {
                            if ((event.target.value.length === 0) || Number(event.target.value)) {
                              let commissionCorp = numberLength(event.target.value);
                              if (commissionCorp >= 100) commissionCorp = 100;
                              if (commissionCorp < 0) commissionCorp = '';

                              this.setState({
                                commission: commissionCorp,
                              })
                            }
                          }}
                          placeholder="Ex: 12.05%"
                        />
                        <div className="invalid-feedback">{commissionMessage}</div>
                      </div>

                      <div className="form-group col-md-3">
                        <TextField
                          type="number"
                          label="Valor de bonificação para Conta Digital"
                          InputProps={{
                            classes: {
                              input: "mui-input-control",
                              root: "mui-input-control",
                            },
                            inputMode: "tel",
                            pattern: "[0-9]*",
                            startAdornment: (
                              <InputAdornment
                                style={{
                                  position: "absolute",
                                  marginRight: Number.isInteger(
                                    Number(valuePerAccount)
                                  )
                                    ? 40
                                    : 50,
                                }}
                                position="start"
                              >
                                R$
                                {" "}
                              </InputAdornment>
                            ),
                          }}
                          InputLabelProps={{
                            classes: {
                              root: "mui-input-control mui-label",
                            },
                          }}
                          // eslint-disable-next-line react/jsx-no-duplicate-props
                          inputProps={{
                            step: "0.1",
                            style: { marginLeft: 35 }
                          }}
                          variant="outlined"
                          value={valuePerAccount}
                          fullWidth
                          onFocus={element => element.target.select()}
                          onChange={(event) => {
                            if (Number(event.target.value)) {
                              let accountCommissionCorp = numberLength(event.target.value);
                              if (accountCommissionCorp < 0) accountCommissionCorp = 0;

                              this.setState({
                                valuePerAccount: accountCommissionCorp,
                              })
                            } else this.setState({ valuePerAccount: 0 })
                          }}
                          placeholder="Ex: R$ 5,00"
                        />
                      </div>
                    </div>

                    <div className="form-group col-md-12">
                      <TextField
                        data-intro={dataTour.createCorp.description}
                        label="Descrição"
                        InputProps={{
                          classes: {
                            input: "mui-input-control",
                            root: "mui-input-control"
                          }
                        }}
                        InputLabelProps={{
                          classes: { root: "mui-input-control mui-label" }
                        }}
                        variant="outlined"
                        value={description}
                        fullWidth
                        error={Boolean(descriptionMessage)}
                        onChange={event => {
                          this.setState({
                            description: event.target.value,
                            userChanged: true
                          });
                        }}
                        placeholder="Descrição"
                      />
                      <div className="invalid-feedback">{descriptionMessage}</div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <TextField
                          data-intro={dataTour.createCorp.email}
                          label="Email"
                          InputProps={{
                            classes: {
                              input: "mui-input-control",
                              root: "mui-input-control"
                            }
                          }}
                          InputLabelProps={{
                            classes: { root: "mui-input-control mui-label" }
                          }}
                          variant="outlined"
                          value={email}
                          fullWidth
                          error={Boolean(emailMessage)}
                          onChange={event => {
                            this.setState({
                              email: event.target.value,
                              userChanged: true
                            });
                          }}
                          placeholder="Email"
                        />
                        <div className="invalid-feedback">{emailMessage}</div>
                      </div>

                      <div className="form-group col-md-6">
                        <InputMask
                          mask="(99) 99999-9999"
                          value={phone}
                          onChange={event => {
                            this.setState({
                              phone: event.target.value,
                              userChanged: true
                            });
                          }}
                        >
                          {() => (
                            <TextField
                              label="Telefone/Fixo para contato."
                              data-intro={dataTour.createCorp.telephone}
                              InputProps={{
                                classes: {
                                  root: "mui-input-control",
                                  input: "mui-input-control",
                                  notchedOutline: "mui-input-focus"
                                }
                              }}
                              fullWidth
                              InputLabelProps={{
                                classes: {
                                  root: "mui-input-control mui-label"
                                }
                              }}
                              error={Boolean(phoneMessage)}
                              variant="outlined"
                            />
                          )}
                        </InputMask>
                        <div className="invalid-feedback">{phoneMessage}</div>
                      </div>

                      <div className="form-group col-md-6">
                        <p style={{ fontWeight: 'bold' }}>Slug da corporação, <span style={{ color: 'red' }}>ATENÇÃO</span> esse valor não pode ser alterado.</p>
                        <p>O slug será uma palavra usada para links personalizados. Ex: https://agx.link/<span style={{ fontWeight: 'bold' }}>empresa</span></p>
                        <TextField
                          label="Slug"
                          InputProps={{
                            classes: {
                              root: "mui-input-control",
                              input: "mui-input-control",
                              notchedOutline: "mui-input-focus"
                            }
                          }}
                          fullWidth
                          InputLabelProps={{
                            classes: {
                              root: "mui-input-control mui-label"
                            }
                          }}
                          value={this.state.slug}
                          onChange={(event) => {
                            // eslint-disable-next-line
                            const regexSlug = /^([a-z0-9\-]+$)/.test(event.target.value)
                            if (regexSlug || !event.target.value) {
                              this.setState({
                                slug: event.target.value,
                                userChanged: true
                              })
                            }
                          }}
                          error={Boolean(phoneMessage)}
                          variant="outlined"
                        />
                      </div>
                    </div>

                    <div className="form-group col-md-12" data-intro={dataTour.createCorp.logo}>
                      <label htmlFor="logo-file">Logo: </label>
                      <input
                        type="file"
                        name="file"
                        id="logo-file"
                        accept="image/png, image/jpeg, image/jpg"
                        className="form-control inputfile"
                        placeholder="Bonificação"
                        onChange={event => {
                          if (!this.handleFile(event))
                            event.target.value = ""
                        }}
                      />
                    </div>

                    <div className="form-group col-md-12 container-button-create-corp" ref={this.formRef}>
                      <button
                        className="btn btn-fill btn-block btn-round btn-mlm button-create-corp"
                        onClick={this.createCorp}
                      >
                        Cadastrar
                        </button>
                    </div>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
