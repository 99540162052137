import React, { Component, memo } from "react";

import InputMask from "react-input-mask";

import "assets/css/styles.css";
import { Dialog, LoadingAlert } from "components/Alert/CustomAlert";
import mainApi from "services/mainApi";
import MahoeIcon from "components/MahoeIcons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshake } from "@fortawesome/free-solid-svg-icons";
import { dateToUS } from "utils/formatData";
import swal from "sweetalert2";

import queryString from 'query-string'

import AgreementModal from "./AgreementModal";
import config from 'utils/serverVars'

import clubeAmigoLogo from 'assets/img/clubeAmigo/clubeAmigoLogo.png'

import {
  Checkbox,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormGroup
} from "@material-ui/core";
import { ClubeAmigoId } from "utils/CommonData";
import { ISClubeAmigo } from "utils/environment";

const MuiTextField = props => (
  <TextField
    InputProps={{
      classes: {
        input: "mui-input-control",
        root: "mui-input-control"
      }
    }}
    InputLabelProps={{
      classes: {
        root: "mui-input-control mui-label "
      }
    }}
    fullWidth
    variant="outlined"
    {...props}
  >
    {props.children}
  </TextField>
);

class SignupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      swalMessage: [],
      showSwal: false,
      invited: false,
      mode: "",
      inviteStatus: "success",
      user: {},
      loginType: "cpf",
      inviteCode: "",
      termsAgreed: false,
      whatsappAgreed: false,
      termsError: '',
      name: "",
      email: "",
      logo: null,
      cpf_or_cnpj: "",
      birthdate: "",
      phone: "",
      gender: "",
      zipcode: "",
      street: "",
      number: "",
      state: "UF",
      neighborhood: "",
      city: "",
      additional: "",
      inviteCodeMessage: "",
      nameMessage: "",
      emailMessage: "",
      cpf_or_cnpjMessage: "",
      birthdateMessage: "",
      phoneMessage: "",
      genderMessage: "",
      zipcodeMessage: "",
      addressMessage: "",
      numberMessage: "",
      stateMessage: "",
      neighborhoodMessage: "",
      disableSignup: false,
      cityMessage: "",
      additionalMessage: "",
      invalidFields: [],
      btnTitle: "Cadastrar",
      btnValidateAccont: "Usar esta conta",
      cantSignup: true,
      showAgreementModal: false,
      showPlatformModal: false,
      termsHtml: "",
      changeZipcode: false,
      filledFields: {},
      loading: false
    };
    this.numberRef = React.createRef();
    this.states = [
      "AC",
      "AL",
      "AP",
      "AM",
      "BA",
      "CE",
      "DF",
      "ES",
      "GO",
      "MA",
      "MT",
      "MS",
      "MG",
      "PA",
      "PB",
      "PR",
      "PE",
      "PI",
      "RJ",
      "RN",
      "RS",
      "RO",
      "RR",
      "SC",
      "SP",
      "SE",
      "TO"
    ];
  }

  componentDidMount() {
    const { inviteCode, mode } = this.props;

    window.digitalData = {
      "site": {
        "nome": "Banco Pan"
      },
      "page": {
        "secao": "IndiqueoPan",
        "estadoLogin": "NL",
        "nome": "BP:IndiqueOPan:Cadastro"
      }
    }

    var evt = new CustomEvent('event-view-start');
    document.body.dispatchEvent(evt);

    if (inviteCode && inviteCode.length >= 24) {
      this.setState({ inviteCode: inviteCode, mode, invited: true });
    }
    if (inviteCode) this.checkInvite();
    document.addEventListener(
      "keydown",
      this.handleKeyPressedOnDocument,
      false
    );
    this.getAgreement();
  }

  getAgreement = async () => {
    try {
      const termsResponse = await mainApi.get("/terms");
      this.setState({
        termsHtml: termsResponse.data.data.terms
      });
    } catch (err) {
      setTimeout(() => {
        this.getAgreement();
      }, 3000);
    }
  };

  componentWillUnmount() {
    document.removeEventListener(
      "keydown",
      this.handleKeyPressedOnDocument,
      false
    );
  }
  handleKeyPressedOnStreet = event => {
    if (event.charCode === 44) {
      this.numberRef.current.focus();
    }
  };
  handleKeyPressedOnDocument = event => {
    if (event.keyCode === 13 && !this.state.showSwal) {
      if (this.state.cantSignup) {
        event.preventDefault();

      } else {
        this.handleSubmit();
      }
    }
  };
  handleStreet = event => {
    let street = event.target.value.replace(/,/g, "");
    this.setState({ street });
  };

  checkInvite = async () => {
    const { inviteCode, mode } = this.props;
    let response = null;
    const search = queryString.parse(this.props.location.search);
    
    this.setState({ loaded: false });
    try {
      switch (mode) {
        case "common-invite":
          if ((['5fa6ad8b3d56db0004c98178'].includes(search?.agencyId) || ['601c4618321d1f0003e167e6', '5f4fd423caf57500045449b3', ClubeAmigoId].includes(search?.corpId)) && search?.invite) {
            response = await mainApi.post("/invite/agency/id", {
              agencyId: search?.agencyId,
              ...search
            });
            this.setState({ disableSignup: false })
          } else
            swal.fire({
              icon: "warning",
              title: "ATENÇÃO",
              text: `Convites de vendedores estão desativados no momento. Você será redirecionado(a) para a tela de login `,
              confirmButtonText: "Entendi",
            }).then(() => {
              const login = window.location.host
              setTimeout(() => window.location = 'http://' + login, 300)
            });
          break;
        case "agency-invite":
          response = await mainApi.post("/invite/agency/id", {
            agencyId: inviteCode,
            ...search
          });
          this.setState({ disableSignup: false })
          break;
        case "admin-invite":
          response = await mainApi.post("/invite/admin/id", {
            adminInviteToken: inviteCode
          });
          this.setState({ disableSignup: false })
          break;
        case "managerToken":
          response = await mainApi.post('/invite/manager/id', {
            managerToken: inviteCode
          })
          break;
        default:
          break;

      }
      let inviteStatus = "";
      let disableSignup = false;
      if (response.data.valid) {
        inviteStatus = "success";
        disableSignup = false;
      } else {
        inviteStatus = "error";
        disableSignup = true;
      }
      if (response.data.logo) {
        let logo = response.data.logo;

        this.setState({
          logo
        });
      }
      this.setState({
        inviteCodeMessage: response.data.message,
        inviteStatus,
        disableSignup
      });
      this.setState({ loaded: true });
    } catch (err) {
      if (err.response) {
        this.setState({
          inviteCodeMessage: err.response.data.message || "Erro desconhecido",
          inviteStatus: "error",
          btnTitle: "Cadastrar"
        });
      }

      return;
    }
  };
  handleRadio = event => {
    const target = event.target;
    this.setState({
      gender: target.value
    });
  };

  showMessage = (type, title, message, ref) => {
    this.setState({
      showSwal: true,
      swalMessage: [type, title, message, ref]
    });
  };

  onFinish = () => {
    if (this.state.termsAgreed && this.state.whatsappAgreed) {
      this.setState({ showAgreementModal: false }, () => {
        this.handleSubmit()
      });
    } else {
      this.setState({ termsError: 'Você deve aceitar os termos para prosseguir.' })
    }
  }

  setTermsAgreed = () => {
    this.setState({ termsAgreed: !this.state.termsAgreed });
  };

  setWhatsappAgreed = () => {
    this.setState({ whatsappAgreed: !this.state.whatsappAgreed })
  }

  hideModal = () => {
    this.setState({ showAgreementModal: false })
  }

  handleSubmit = async (commit) => {
    const {
      mode,
      inviteCode,
      name,
      email,
      cpf_or_cnpj,
      birthdate,
      phone,
      gender,
      zipcode,
      street,
      number,
      state,
      additional,
      neighborhood,
      city,
      termsAgreed,
      whatsappAgreed
    } = this.state;

    const { unlockPan } = this.props
    const apiUrl = ISClubeAmigo ? '/users/clubeamigo' : '/users'

    let formatedBirthdate = dateToUS(birthdate);
    const checkFields = [
      "nameMessage",
      "emailMessage",
      "cpf_or_cnpjMessage",
      "birthdateMessage",
      "phoneMessage",
      "genderMessage",
      "zipcodeMessage",
      "addressMessage",
      "numberMessage",
      "stateMessage",
      "neighborhoodMessage",
      "cityMessage",
      "additionalMessage"
    ];
    checkFields.map(field => this.setState({ [field]: "" }));
    this.setState({ btnTitle: "Carregando...", disableSignup: true, loading: true });
    const queryStrings = queryString.parse(this.props.location.search)
  
    if (inviteCode) {
      if (mode === 'managerToken') {
        try {
          await mainApi.post("/invite/manager/id", { managerToken: inviteCode });
        } catch (error) {
          this.setState({
            inviteCodeMessage: "Código de convite inválido",
            btnTitle: "Cadastrar",
            loading: false
          });
          return;
        }
      }
      else if (inviteCode.length === 24) {
        try {
          if (mode === "agency-invite")
            await mainApi.post("/invite/agency/id", { agencyId: inviteCode, ...queryStrings });
          else await mainApi.post("/invite/user/id", { userId: inviteCode });
        } catch (error) {
          this.setState({
            inviteCodeMessage: "Código de convite inválido",
            btnTitle: "Cadastrar",
            loading: false
          });
          return;
        }
      } else if (inviteCode.length > 24) {

        try {
          await mainApi.post("/invite/admin/id", {
            adminInviteToken: inviteCode
          });
        } catch (error) {
          this.setState({
            inviteCodeMessage: "Código de convite inválido",
            btnTitle: "Cadastrar",
            loading: false
          });
          return;
        }
      } else {
        this.showMessage(
          "warning",
          "Código de convite invalido!",
          ""
        );
        this.setState({ btnTitle: "Cadastrar", disableSignup: false });
        return;
      }
    }
    const rawPhone = phone.replace(/\D/g, "");
    const rawCpf = cpf_or_cnpj.replace(/\D/g, "");
    const rawZipcode = zipcode.replace(/\D/g, "");
    let missingFields = false;
    if (!name) {
      this.setState({ nameMessage: "Nome é obrigatorio." });
      missingFields = true;
    }
    if (!email) {
      this.setState({ emailMessage: "Email é obrigatorio." });
      missingFields = true;
    }
    if (!cpf_or_cnpj) {
      this.setState({ cpf_or_cnpjMessage: "CPF/CNPJ é obrigatorio." });
      missingFields = true;
    }
    if (!birthdate) {
      this.setState({ birthdateMessage: "Data de Nascimento é obrigatorio." });
      missingFields = true;
    }
    if (!gender) {
      this.setState({ genderMessage: "Genero é obrigatorio." });
      missingFields = true;
    }
    if (!phone) {
      this.setState({ phoneMessage: "Telefone é obrigatorio." });
      missingFields = true;
    }
    if (!zipcode) {
      this.setState({ zipcodeMessage: "CEP é obrigatorio." });
      missingFields = true;
    }
    if (!street) {
      this.setState({ addressMessage: "Logradouro é obrigatorio." });
      missingFields = true;
    }
    if (!city) {
      this.setState({ cityMessage: "Cidade é obrigatorio." });
      missingFields = true;
    }
    if (!neighborhood) {
      this.setState({ neighborhoodMessage: "Bairro é obrigatorio." });
      missingFields = true;
    }
    if (!number) {
      this.setState({ numberMessage: "Número é obrigatorio." });
      missingFields = true;
    }
    if (missingFields) {
      this.showMessage(
        "warning",
        "Faltam campos",
        "Insira os campos marcados."
      );
      this.setState({ btnTitle: "Cadastrar", disableSignup: false, loading: false });
      return;
    }
    const data = {
      name,
      email,
      birthdate: formatedBirthdate,
      unlockPan,
      gender,
      phone: rawPhone,
      address: {
        zipcode: rawZipcode,
        street,
        number,
        neighborhood,
        city,
        state,
        additional
      }
    };
    const address = {
      zipcode: rawZipcode,
      street,
      number,
      neighborhood,
      city,
      state,
      additional
    };
    data.address = address
    data.origin = window.location.href
    if (commit) data.transferUser = true;
    switch (mode) {
      case "common-invite":
        data.inviterId = inviteCode;
        break;
      case "agency-invite":
        data.agencyId = inviteCode;
        break;
      case "admin-invite":
        data.adminInviteToken = inviteCode;
        break;
      case "managerToken":
        data.managerToken = inviteCode
        break
      default:
        break;
    }
    if (rawCpf.length >= 12) data.cnpj = rawCpf;
    else data.cpf = rawCpf;
    if (termsAgreed && whatsappAgreed) {
      data.agreed = termsAgreed && whatsappAgreed;
    }

    const search = queryString.parse(this.props.location.search);
    const agencyId = search?.agencyId
    const supervisorId = search?.supervisorId

    if (ISClubeAmigo && agencyId) data.agencyId = agencyId
    if (ISClubeAmigo && supervisorId) data.supervisorId = supervisorId

    if ((!inviteCode || ISClubeAmigo) && (!termsAgreed || !whatsappAgreed)) {
      this.setState({
        showAgreementModal: true,
        btnTitle: "Cadastrar",
        disableSignup: false,
        loading: false
      });
      return;
    }

    let user = { email: 'gustavofelipe68@gmail.com', _id: 'b1sae4abs3as11b6c' }

    try {
      // User's Signup
      let response
      if (commit) {
        response = await mainApi.patch(`${apiUrl}/transfer`, data);
      } else {
        response = await mainApi.post(`${apiUrl}`, data);
      }

      user = response.data.user

      this.setState({ user, loading: false })

      let successMessage;
      if (!inviteCode)
        successMessage =
          "Usuário cadastrado com sucesso, você receberá um email de confirmação.";
      else
        successMessage =
          "Usuário cadastrado com sucesso, você receberá um email de confirmação.";
      this.setState({
        btnTitle: "Cadastrar",
        disableSignup: false,
        swalMessage: [
          "success",
          "Usuário cadastrado!",
          successMessage,
          "signup-done"
        ],
        showSwal: true
      });

    } catch (err) {
      if (!err.response) {
        this.showMessage(
          "warning",
          "Verifique sua conexão!",
          "Não foi possivel se comunicar com o servidor."
        );
        this.setState({ btnTitle: "Cadastrar", disableSignup: false, loading: false });
        return;
      }
      const responseStatus = err.response.status;
      if (responseStatus === 422 && err.response.data.userCanRegisterAgain) {
        this.showConfirmation('info',
          'Cadastrar-se em agêcia?',
          'Você já possuí um cadastro pendente para afiliação, '
          + 'deseja realizar o cadastro nesta corporação? (Esta ação'
          + ' resultará no cancelamento da afiliação anterior.)')
        this.handleSubmit(true)
        return
      }

      if (err.response?.status === 429 &&  typeof err.response?.data === 'string'){
        this.showMessage("warning", "Atenção", err.response.data);
        this.setState({ btnTitle: "Cadastrar", disableSignup: false, loading: false });
        return
      }

      if (responseStatus !== 404) {
        const { error, message, fields } = err.response.data;
        const responseMessage = message ? message : error;
        if (responseMessage) {
          if (!Array.isArray(fields)) {
            let invalidFields = [];
            switch (fields) {
              case "cpf" || "cnpj": {
                this.setState({ cpf_or_cnpjMessage: responseMessage });
                invalidFields.push("CPF/CNPJ");
                break;
              }
              case "name": {
                this.setState({ nameMessage: responseMessage });
                invalidFields.push("Nome");
                break;
              }
              case "email": {
                this.setState({ emailMessage: responseMessage });
                invalidFields.push("Email");
                break;
              }
              case "birthdate": {
                this.setState({ birthdateMessage: responseMessage });
                invalidFields.push("Data de Nascimento");
                break;
              }
              default: {
                this.showMessage(
                  "warning",
                  "Atenção!",
                  responseMessage
                );
              }
            }
            if (invalidFields.length) {
              this.showMessage(
                "warning",
                "Campo inválido",
                `Verifique o campo: ${invalidFields.toString()}`
              );
            }
            this.setState({ btnTitle: "Cadastrar", disableSignup: false, loading: false });
            return;
          }
          this.showMessage(
            "warning",
            "Faltam campos",
            err.response.data.message
          );
          this.setState({
            btnTitle: "Cadastrar",
            disableSignup: false,
            showSwal: true
          });
          let invalidField = false;
          while (fields.length > 0) {
            let field = fields.pop();
            switch (field.name) {
              case "name": {
                this.setState({ nameMessage: field.message });
                invalidField = true;
                break;
              }
              case "cellphone":
              case "telephone":
              case "phone": {
                this.setState({ phoneMessage: field.message });
                invalidField = true;
                break;
              }
              case "cpf" || "cnpj": {
                this.setState({ cpf_or_cnpjMessage: field.message });
                invalidField = true;
                break;
              }
              case "email": {
                this.setState({ emailMessage: field.message });
                invalidField = true;
                break;
              }
              case "birthdate": {
                this.setState({ birthdateMessage: field.message });
                invalidField = true;
                break;
              }
              default: {
                invalidField = true;
                break;
              }
            }
          }
          if (invalidField) {
            this.showMessage(
              "warning",
              "Faltam campos",
              err.response.data.message
            );
          }
        }
      }

      
      this.showMessage(
        "warning",
        "Faltam campos",
        err.response.data.message
      );
      this.setState({ btnTitle: "Cadastrar", disableSignup: false, loading: false });
      return;
    }
    if (user && user._id) {
      var eventDetail = { detail: { button: "BP:IndiqueOPan:Cadastro:Sucesso" } };
      var interactionClick = new CustomEvent("event-signup-success", eventDetail);
      document.body.dispatchEvent(interactionClick);
      // var eventDetail = {"detail": {"crmid": user._id }}
      // var interactionClick = new CustomEvent("event-signup-success", eventDetail)
      // document.body.dispatchEvent(interactionClick);
      return
    }
  };

  handleCep = async event => {
    const searchCep = event.target.value;
    if (searchCep === this.state.zipcode) return;
    this.setState({ zipcode: searchCep });
    const rawCep = searchCep.replace(/\D/g, "");
    if (rawCep.length === 8) {
      try {
        const response = await mainApi.get(`/resources/cep/${rawCep}`);
        const {
          street,
          neighborhood,
          city,
          state,
          additional
        } = response.data.address;
        this.setState({
          street,
          neighborhood,
          city,
          state,
          additional,
          changeZipcode: true,
          filledFields: {
            city,
            state,
          }
        });
        this.numberRef.current.focus();
      } catch (error) { }
    }
  };

  onStateChange = event => {
    this.setState({ state: event.target.value });
  };

  isValid = value => {
    return this.state.invalidFields.includes(value);
  };

  render() {
    const {
      swalMessage,
      inviteCodeMessage,
      nameMessage,
      emailMessage,
      inviteStatus,
      cpf_or_cnpjMessage,
      birthdateMessage,
      phoneMessage,
      genderMessage,
      zipcodeMessage,
      addressMessage,
      numberMessage,
      stateMessage,
      neighborhoodMessage,
      cityMessage,
      additionalMessage,
      logo,
      loaded,
      showSwal,
      btnTitle,
      showAgreementModal,
      termsHtml,
      termsAgreed,
      whatsappAgreed,
      termsError,
      changeZipcode,
      filledFields,
      user,
      loading,
      mode
    } = this.state;

    const disableSignup = false
    const cantSignup = false

    const { inviteCode, isCorpInvite, finishSignup } = this.props;

    return (
      <div className="signup-form-container">
        <LoadingAlert show={loading} />
        <AgreementModal
          termsHtml={termsHtml}
          show={showAgreementModal}
          termsAgreed={termsAgreed}
          setTermsAgreed={this.setTermsAgreed}
          whatsappAgreed={whatsappAgreed}
          setWhatsappAgreed={this.setWhatsappAgreed}
          onFinish={this.onFinish}
          termsError={termsError}
          hideModal={this.hideModal}
        />
        <Dialog
          type={swalMessage[0]}
          show={showSwal}
          title={swalMessage[1] || ""}
          text={swalMessage[2]}
          onConfirm={() => {
            this.setState({ showSwal: false }, () => {
              if (swalMessage[0] === 'success') finishSignup(user.email, user._id)
            });
          }}
        />

        {isCorpInvite ?
          <img className="brand-logo" alt="logo do produto" src={ISClubeAmigo ? clubeAmigoLogo : config.LOGO}></img>

          :
          <img className="brand-logo" alt="logo do produto" src={ISClubeAmigo ? clubeAmigoLogo : config.PRODUCT_LOGO}></img>
        }

        <form style={{ display: "flex", flexDirection: "column" }}>
          <br />
          {inviteCode ? (
            <>
              <div className="corp-card-signup">
                {loaded  && (window.innerWidth > 940)  ? (
                  logo ? (
                    <img alt="logo do produto" src={logo} />
                  ) : (
                    <FontAwesomeIcon
                      icon={faHandshake}
                      size="10x"
                      color="#414141"
                    />
                  )
                ) : null}
              </div>
              <div
                className={
                  inviteStatus === "success" ? "invite-success" : "invite-error"
                }
              >
                {inviteCodeMessage}
              </div>
            </>
          ) : null}
          <div>
            {isCorpInvite || !cantSignup ? (
              <>
                <h4 className="section-name" id="personal-data">
                  Dados Pessoais{" "}
                  <MahoeIcon name="picto" fill="#FF5630" size="8px" />
                </h4>
                <br />
                <div className="form-group">
                  <MuiTextField
                    label="Nome"
                    type="text"
                    error={Boolean(nameMessage)}
                    onChange={event => {
                      this.setState({ name: event.target.value });
                    }}
                  />

                  <div className="invalid-feedback-signup">{nameMessage}</div>
                </div>
                <div className="form-group">
                  <MuiTextField
                    label="Email"
                    type="email"
                    error={Boolean(emailMessage)}
                    onChange={event => {
                      this.setState({ email: event.target.value });
                    }}
                  />
                  <div className="invalid-feedback-signup">{emailMessage}</div>
                </div>
                <div className="form-group two-inputs">
                  <div>
                    <InputMask
                      mask="999.999.999-99"
                      placeholder="CPF"
                      maskChar=""
                      pattern="[0-9]*"
                      inputMode="numeric"
                      className="form-control input-login"
                      onChange={event => {
                        this.setState({
                          cpf_or_cnpj: event.target.value
                        });
                      }}
                    >
                      {() => (
                        <MuiTextField
                          label="CPF"
                          error={Boolean(cpf_or_cnpjMessage)}
                        />
                      )}
                    </InputMask>
                    <div className="invalid-feedback-signup">
                      {cpf_or_cnpjMessage}
                    </div>
                  </div>
                  <div>
                    <InputMask
                      mask="99/99/9999"
                      placeholder="Dasta de Nascimento"
                      maskChar=""
                      pattern="[0-9]*"
                      inputMode="numeric"
                      onChange={event => {
                        this.setState({
                          birthdate: event.target.value
                        });
                      }}
                    >
                      {() => (
                        <MuiTextField
                          label="Data de Nascimento"
                          error={Boolean(birthdateMessage)}
                        />
                      )}
                    </InputMask>

                    <div className="invalid-feedback-signup">
                      {birthdateMessage}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <FormLabel
                    component="legend"
                    style={{ textAlign: "left", marginTop: "5%" }}
                  >
                    Gênero:
                    <RadioGroup
                      style={{ marginTop: "2%" }}
                      aria-label="gender"
                      name="gender"
                      row
                      value={this.state.gender}
                      onChange={this.handleRadio}
                    >
                      <FormControlLabel
                        value="female"
                        control={<Radio color="primary" />}
                        label="Feminino"
                      />
                      <FormControlLabel
                        value="male"
                        control={<Radio color="primary" />}
                        label="Masculino"
                      />
                    </RadioGroup>
                  </FormLabel>

                  <div className="invalid-feedback-signup">{genderMessage}</div>
                </div>
                <div className="form-group two-inputs">
                  <div>
                    <InputMask
                      mask="(99) 99999-9999"
                      placeholder="Telefone ou Celular"
                      className="form-control input-login"
                      onChange={event => {
                        this.setState({ phone: event.target.value });
                      }}
                    >
                      {() => (
                        <MuiTextField
                          label="Telefone ou Celular"
                          error={Boolean(phoneMessage)}
                        />
                      )}
                    </InputMask>
                    <div className="invalid-feedback-signup">
                      {phoneMessage}
                    </div>
                  </div>
                </div>
                <h4 className="section-name" id="personal-data">
                  Endereço <MahoeIcon name="picto" fill="#FF5630" size="8px" />
                </h4>
                <br />
                <div className="form-group signup-half-size">
                  <div>
                    <InputMask
                      mask="99999-999"
                      maskChar=""
                      value={this.state.zipcode}
                      onChange={event => {
                        this.handleCep(event);
                      }}
                    >
                      {() => (
                        <MuiTextField
                          label="CEP"
                          error={Boolean(zipcodeMessage)}
                        />
                      )}
                    </InputMask>
                    <div className="invalid-feedback-signup">
                      {zipcodeMessage}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <MuiTextField
                    label="Logradouro"
                    className="form-control input-login"
                    value={this.state.street}
                    error={Boolean(addressMessage)}
                    onKeyPress={this.handleKeyPressedOnStreet}
                    onChange={this.handleStreet}
                    disabled={!changeZipcode || filledFields.street}
                  />
                  <div className="invalid-feedback-signup">
                    {addressMessage}
                  </div>
                </div>
                <div className="form-group two-inputs">
                  <div>
                    <MuiTextField
                      label="Número"
                      className="form-control input-login"
                      error={Boolean(numberMessage)}
                      pattern="[0-9]*"
                      inputMode="numeric"
                      type="tel"
                      value={this.state.number}
                      inputRef={this.numberRef}
                      disabled={!changeZipcode || filledFields.number}
                      onChange={event => {
                        let value = event.target.value.replace(/\D/g, "");
                        this.setState({ number: value });
                      }}
                    />
                    <div className="invalid-feedback-signup">
                      {numberMessage}
                    </div>
                  </div>
                  <div>
                    <MuiTextField
                      label="Complemento (opcional)"
                      className="form-control input-login"
                      error={Boolean(additionalMessage)}
                      value={this.state.additional}
                      onChange={event => {
                        this.setState({ additional: event.target.value });
                      }}
                    />
                    <div className="invalid-feedback-signup">
                      {additionalMessage}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <MuiTextField
                    label="Bairro"
                    className="form-control input-login"
                    value={this.state.neighborhood}
                    error={Boolean(neighborhoodMessage)}
                    disabled={!changeZipcode || filledFields.neighborhood}
                    onChange={event => {
                      this.setState({ neighborhood: event.target.value });
                    }}
                  />
                  <div className="invalid-feedback-signup">
                    {neighborhoodMessage}
                  </div>
                </div>
                <div className="form-group small-inputs">
                  <div>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      error={Boolean(stateMessage)}
                    >
                      <InputLabel>UF</InputLabel>
                      <Select
                        classes={{ root: "fix-height" }}
                        className="fix-height"
                        value={this.state.state}
                        disabled={!changeZipcode || filledFields.state}
                        onChange={this.onStateChange}
                        label="UF"
                      >
                        <MenuItem value="UF" key="default">
                          UF
                        </MenuItem>
                        {this.states.map(state => (
                          <MenuItem value={state} key={state}>
                            {state}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <div className="invalid-feedback-signup">
                      {stateMessage}
                    </div>
                  </div>
                  <div>
                    <MuiTextField
                      label="Cidade"
                      className="form-control input-login"
                      value={this.state.city}
                      disabled={!changeZipcode || filledFields.city}
                      error={Boolean(cityMessage)}
                      onChange={event => {
                        this.setState({ city: event.target.value });
                      }}
                    />
                    <div className="invalid-feedback-signup">{cityMessage}</div>
                  </div>
                </div>

                <br />
                {!inviteCode || mode == 'managerToken' && (
                  <>
                    <FormGroup
                      aria-label="position"
                      row
                      style={{ textAlign: "left" }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={termsAgreed}
                            onChange={e =>
                              this.setTermsAgreed()
                            }
                          />
                        }
                        label={
                          <span className="agreement-label">
                            Li e concordo com os{" "}

                          </span>
                        }
                        labelPlacement="end"
                      />
                      {/* eslint-disable-next-line*/}
                      <a
                        className="link-style terms-link"
                        href="#"
                        onClick={() => {
                          this.setState({ showAgreementModal: true });
                        }}
                      >
                        termos de acordo.
                      </a>
                    </FormGroup>
                    <FormGroup
                      aria-label="position"
                      row
                      style={{ textAlign: "left" }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={whatsappAgreed}
                            onChange={e =>
                              this.setWhatsappAgreed()
                            }
                          />
                        }
                        label={
                          <span className="agreement-label">
                            Eu autorizo banco PAN entrar  em contato via WhatsApp
                          </span>
                        }
                        labelPlacement="end"
                      />
                    </FormGroup>
                  </>
                )}
                <button
                  className="btn btn-login"
                  onClick={e => {
                    e.preventDefault();
                    this.handleSubmit();
                  }}
                  disabled={isCorpInvite ? false : disableSignup || cantSignup}
                >
                  {btnTitle}
                </button>
              </>
            ) : null}
          </div>
          <div style={{ margin: "5% 0" }}>
            <a className="signup-link " href="/">
              Voltar ao login
            </a>
          </div>
          <a
            className="agx-link"
            href="https://www.agxsoftware.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            AGX Software © {new Date().getFullYear()}
          </a>
          <span style={{ marginBottom: 20 }}>
            <a
              className="optanon-show-settings optanon-toggle-display"
              style={{ cursor: "pointer" }}
            >
              Cookies que utilizamos
            </a>
          </span>
        </form>
      </div>
    );
  }
}

export default memo(SignupForm);