import styled from 'styled-components';

export const Wrapper = styled.div`
 display:flex;
`

export const Title = styled.h1`
  margin: 0%;
  font-size: 24px;
  padding: 20px;
`

export const Filters = styled.div`
  padding-bottom:15px;
  display:block;
`

export const Table = styled.div`
  display: flex;
  flex-direction: column;
`

export const ErrorRow = styled.p`
  margin:0;
  word-break:break-all;
` 

export const IconStyle = styled.div`
  border-radius: 10px;
  border: #F5F5F5 1px solid;
  padding:5px;
  margin-right: 15px;
`

export const IconButton = styled.div`
  border-radius: 10px;
  padding:5px;
  margin-right: 15px;
  cursor:pointer;
`

export const PaginationContainer = styled.div`
  /* border:black 1px solid; */
  height:100%;
  display:flex;
  justify-content:center;
  align-items:flex-end;
`

export const ArrowsContainer = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:center;
  width:200px;

`

export const NothingFound = styled.p`
  border-top: solid 1px #FFF;
  border-bottom: solid 1px #FFF;
  color: #777;
  height:400px;
  font-size: 24px;
`

export const ArrowContainer = styled.div`

  padding: 5px;
  background-color: white; 
  width: fit-content; 
  border-radius: 5px; 
  display: flex; 
  justify-content: center; 
  align-items: center; 
  cursor: pointer; 
`