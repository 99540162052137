import React, { Component } from "react";
import { RedocStandalone } from 'redoc';

import mainApi from "services/mainApi";

import devDocs from 'assets/developmentDocumentation.yaml'
import prodDocs from 'assets/productionDocumentation.yaml'
// let documentation

class Documentation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      level: '',
      loading: false,
      windowHeight: 100,
      windowWidth: 100,
      INTERRUPT: false,
      documentation: null
    };
  }

  updateDimensions = () => {
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    });
  };


  getUserActualInfo = async () => {
    const response = await mainApi.get('/user/info')
    const level = response.data.level || 'none'
    this.setState({level})
  }

  componentDidMount () {
    let preUserLevel = localStorage.getItem('@uxtech-mlm/UserLevel')
      
    if(['master', 'submaster'].includes(preUserLevel)){
      let documentation
      if(process.env.REACT_APP_ENV === 'production')
        documentation = devDocs//require('assets/productionDocumentation.yaml');
      else
        documentation = prodDocs//require('assets/developmentDocumentation.yaml');

      this.setState({ documentation })
    }else{
      this.setState({ INTERRUPT: true })
    }
    
    document.body.style = 'overflow: hidden';
    this.getUserActualInfo();
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    document.body.style = 'overflow: auto';
    window.addEventListener("resize", this.updateDimensions);
  }

  render() {
    const {windowHeight, level, INTERRUPT, documentation} = this.state
    console.log(documentation)
    return (
      <>
      {!INTERRUPT && ['master', 'submaster'].includes(level) && documentation ?
      <div style={{maxHeight: windowHeight, overflow: 'auto'}}>
          <RedocStandalone spec={documentation} options={{hideDownloadButton:true}}/>
      </div>
    : <h1>Você não tem permissão para ver esta página.</h1>  
    }</>);
  }
}

export default Documentation;
