import React, { useState, memo } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core/styles'
import MahoeIcons from 'components/MahoeIcons'

const theme = createMuiTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#11cb5f',

    },
    secondary: {
      // This is green.A700 as hex.
      main: '#ff5555',
    },
  },
});

const AccordionButton = ({ title, children, disabled }) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <ThemeProvider theme={theme}>
      <Accordion  disabled={false} expanded={expanded} color='primary' style={{ width: '95%',marginTop:'5px', backgroundColor:disabled?'rgba(212,31,28,0.07)':'rgba(29,129,39,0.07)' }} >
        <AccordionSummary   color='primary' aria-controls="panel1d-content" id="panel1d-header" onClick={() => setExpanded(disabled ? false : !expanded)}>
          {!disabled ? (expanded ? (<MahoeIcons
            name="chevron-down"
            size="24px"
            fill="rgb(29,129,39)"
          />) :
            (<MahoeIcons
              name="chevron-right"
              size="24px"
              fill="rgb(29,129,39)"
            />))
            :
            (disabled && (
              <MahoeIcons
                name="line"
                size="24px"
                fill="rgb(212,31,28)"
              />
            ))
          }
          <Typography style={{color:disabled?'rgb(212,31,28)':'rgb(29,129,39)'}}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails style={{backgroundColor:'rgba(29,129,39,0.07)'}}>
          <Typography  >
            {children}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </ThemeProvider>
  )
}

export default memo(AccordionButton)