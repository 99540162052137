import React from 'react'

function PreRegisterNoAccount({ affiliate, tryAgainAnalysis }) {

  const returnHome = () => {
    localStorage.clear()
    window.history.back()
  }
  
  return (
    <div className="card card-bootstrap text-center card-without-account">
      <div className='card-header title-without-account-card'>
        VOCÊ ESTÁ NO <span className="white-text">CAMINHO CERTO!</span>
      </div>

      <div className="card-body">
        <h5 className="card-title" style={{ fontSize: '1.6rem' }}>Olá senhor(a) {affiliate.name}</h5>
        <p className="card-text text-left white-text" style={{ width: '75%', fontSize: '1.5rem', padding: '25px 0 25px 0' }}>Para concluir seu cadastro, abra agora mesmo a sua <span className="black-text">conta digital</span> do Banco PAN e receba suas bonificações.</p>                      

        <div className="side-buttons-without-account text-left">
                    
          <div>            
            <span  style={{ textAlign: 'left' }}>Não possui conta?</span>
            <button className="btn btn-whitout-account" style={{ marginBottom: 15, marginTop: 5 }} onClick={() => window.open('https://bancopan.onelink.me/zIun/35f20bf8')}>
              Abrir minha conta
            </button>          
          </div>

          <div style={{ marginLeft: 'auto' }}>            
            <span  style={{ textAlign: 'left' }}>Já criou sua conta?</span>
            <button className="btn btn-whitout-account" style={{ marginBottom: 15, marginTop: 5 }} onClick={() => tryAgainAnalysis()}>Tentar novamente</button>          
          </div>                         

        </div>        

        <button className="btn btn-whitout-account" style={{ marginBottom: 15 }} onClick={() => returnHome()}>Retornar</button>
      </div>
    </div>
  )
}

export default PreRegisterNoAccount