import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import getUserData from '../../utils/checkUser';

import { Dialog } from '../../components/Alert/CustomAlert';
import mainApi from 'services/mainApi';
import MUIDataTable from 'mui-datatables';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { getDatesList, objectToParams } from 'utils/formatData';
import MuiDataTableOptions from 'utils/MuiDataTableOptions'
import LoadingGif from 'components/loadingGif';
import { CircularProgress, FormHelperText } from '@material-ui/core';
import Select from 'react-select'
import getTableConfig from 'utils/getTableConfig';
import getMuiTheme from 'utils/getMuiTheme';
import { introSystem } from 'utils/introToSystem';
import { translateSituationStatus } from 'utils/translate';
import MuiDatepickerMonth from 'components/MuiDatepickerMonth';
import moment from 'moment';
import { translateMonths } from 'utils/translate';
import { formatToBrWithHour } from 'utils/formaterDate';
import MahoeIcon from 'components/MahoeIcons';
import { pendingStatus, refusedStatus, situations, emptySelect, platforms, initialDataStatus, searchStatusOptions, consignadoProduct } from 'utils/CommonData';
import HelpAccountOpening from 'components/HelpAccountOpening/HelpAccountOpening';
import queryString from 'query-string'
import { TextField } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search';
import Switch from '../../components/Switch/Switch'
import { ClubeAmigoId } from 'utils/CommonData';
import { consignadoId } from 'utils/CommonData';
import { consignadoProducts } from 'utils/CommonData';
import { translateLevel } from 'utils/translate';
import { ISClubeAmigo } from "utils/environment";
import { Modal } from "react-bootstrap/";
import VoucherReportTable from 'components/VoucherReportTable';
import DetailsModal from './DetailsModal/DetailsModal';
import { maquininhaId } from 'utils/environment';

class ProposalStatus extends Component {
  constructor(props) {
    super(props);
    this.refTop = React.createRef()
    this.omnichannelPlatform = platforms.find(platform => platform.value === 'omnichannel')
    this.refSearch = React.createContext()
    this.state = {
      teamMembers: [],
      paymentData: [],
      extractData: [],
      rowsData: [],
      sortStates: {},
      loadingRowChildren: [],
      userData: {},
      totalDocs: 0,
      totalPages: 0,
      sort: '',
      page: 1,
      limit: 10,
      inUseFields: null,
      collapse: false,
      collapseRelatorio: false,
      level: null,
      loading: false,
      loadingTable: true,
      tableTitle: 'Status de Proposta',
      swalMessageShow: false,
      swalMessage: '',
      tableColumns: [],
      productList: [],
      partners: [],
      corporations: [],
      hasConsignado: false,
      time: new Date(),
      strictLevel: '',
      userData: '',
      noDataMessage: 'Escolha os filtros desejados e clique no botão "Pesquisar"',
      filterLabel: '',
      //filters
      product: emptySelect,
      selectedPartner: emptySelect,
      selectedCorp: emptySelect,
      date: new Date(),
      api: emptySelect,
      productName: emptySelect,
      situation: situations[0],
      status: emptySelect,
      selectedInitialDataStatus: emptySelect,
      selectedSwitch: searchStatusOptions[0],
      searchData: '',
      showVoucherReport: false,
      isLoadingVoucherReport: false,
      voucherReport: {},
      showDetailsModal: false,
      detailsModalData: {},
    };
  }


  showMessage = (type, title, message, bold) => {
    this.setState({
      swalMessageShow: true,
      swalMessage: [type, title, message, bold],
      loading: false
    });
  };

  productListHasConta = () => {
    const { productList, product } = this.state
    return productList.some(product => product.label === 'Conta Digital') && ['Conta Digital', 'Todos'].includes(product.label)
  }

  hasTableClientCode = () => {
    return this.state.paymentData.some(item => item.client)
  }

  getCorpPartners = async () => {
    try {
      const response = await mainApi.get('/partner/names');

      if (!Array.isArray(response?.data?.data?.partners)) return

      this.setState({
        partners: response.data.data.partners.map(partner => partner.name),
        partnersInput: response.data.data.partners.map(partner => ({ value: partner.id, label: partner.name }))
      })
    } catch (err) {
      console.log(err)
    }
  }

  getPaymentData = async (loadingTarget = 'loading') => {
    const { product, sort, selectedCorp, api, selectedInitialDataStatus, status, selectedPartner, limit, searchData, page, date, selectedSwitch, productName } = this.state
    const month = date
    const strictLevel = this.state.strictLevel === 'affiliates' ? this.state.strictLevel + '/' : ''
    const situation = this.state.situation.value
    const productId= product.value
    const partnerId = selectedPartner.value
    const corpName = selectedCorp.label !== 'Todos' ? selectedCorp.label : ''
    const selectDate = moment(month).format('MM-YYYY')
    const dadosIniciaisStatus = selectedInitialDataStatus.value

    const filters = {
      product: productId,
      situation,
      corpName,
      sort,
      status: status.value,
      api: api.value,
      dadosIniciaisStatus,
      agencyId: partnerId,
      search: searchData,
      searchParam: selectedSwitch.value,
      productName: productName.value
    }

    let params = objectToParams(filters, true)

    if (params[0] + params[1] === '&&')
      params = params.substring(1)

    const tableTitle = <>
      Status de Proposta{strictLevel === 'corporations' ? ' das Corporações' : strictLevel === 'affiliates' ? ' dos Afiliados' : ''}
      {' - '}<strong>{translateMonths(moment(date).format('MMMM'))}</strong>
    </>

    const filterLabel = (() => {
      const { selectedCorp, selectedPartner, product, api, situation, status, selectedInitialDataStatus, searchData, productName } = this.state
      const filters = [{ value: searchData, label: searchData }, productName, selectedCorp, selectedPartner, product, api, situation, status, selectedInitialDataStatus]
      return filters.reduce((acc, filter) => filter.value ? [...acc, filter.label] : acc, []).map(filter => (
        <span className='info-badge overflow-ellipsis proposal-status-filter-badge'>
          {filter}
        </span>
      ))
    })()

    this.setState({ [loadingTarget]: true, tableTitle, filterLabel });

    try {
      const response = await mainApi.get(
        `/${strictLevel}data?page=${page ? page : 1}&limit=${limit}&date=${selectDate}${params}`);

      let paymentData = [...response.data.datas.docs];

      paymentData.forEach(item => {
        item.situation = translateSituationStatus(item.situation);
      });

      this.setColumns({paymentData, product})
      this.setState({
        paymentData
      });

      let dates = getDatesList(response.data.startDate)
      this.setState({
        availableDates: dates,
        totalDocs: response.data.datas.totalDocs,
        totalPages: response.data.datas.totalPages,
        page: response.data.datas.page,
      })
    } catch (err) {
      console.log(err);
    }
    this.setState({ [loadingTarget]: false, loadingTable: false, time: new Date(), noDataMessage: '' });
  };

  getCorporationsNames = async () => {
    try {
      const response = await mainApi.get('/corporations/names');
      if (!Array.isArray(response?.data?.data?.corporations)) return

      this.setState({
        corporations: response.data.data.corporations.map(partner => ({ value: partner._id, label: partner.name }))
      })
    } catch (error) {
      console.log(error)
    }
  }

  componentDidMount = async () => {
    const { routeKey } = this.props;
    const consignadoId = '5e84924c43689f59668b273f'

    const strictLevel = this.props.history.location.pathname.includes('corporations') ? 'corporations'
      : this.props.history.location.pathname.includes('affiliates') ? 'affiliates'
        : ''

    const userData = await getUserData(this.props.history);
    if (strictLevel === 'corporations')
      await this.getCorporationsNames()
    if (!strictLevel)
      await this.getCorpPartners()

    const search = queryString.parse(this.props.location.search)

    const searchState = {}

    if (search?.label) {
      searchState.situation = search.label === 'conta_aberta' ? situations.find(item => item?.value === 'paid')
        : search.label === 'reprovada' ? situations.find(item => item?.value === 'refused')
          : situations.find(item => item?.value === 'pending')

      searchState.status = searchState.situation.value === 'pending' ? pendingStatus.find(item => item?.value === search.label)
        : searchState.situation.value === 'refused' ? refusedStatus.find(item => item?.value === search.label) : emptySelect

      if (search?.corporation) searchState.selectedCorp = this.state.corporations.find(corp => corp.value === search.corporation)
      if (search?.agency) searchState.selectedPartner = this.state.partnersInput.find(partner => partner.value === search.agency)

      searchState.api = this.omnichannelPlatform
    }

    this.setState({ date: moment(search.date).toDate() })

    const productList = userData.products
    const product = productList.length === 0
      ? [emptySelect]
      : productList.some(product => product.label === 'Conta Digital')
        ? productList.find(product => product.label === 'Conta Digital')
        : productList[0]

    if (userData) {
      this.setState({ level: userData.level, userData, strictLevel, ...searchState, productList, product });
      userData.products.forEach(product => {
        if (consignadoId == product.value)
          this.setState({ hasConsignado: true })
      })

      if (userData.level === 'seller' && ISClubeAmigo) this.setState({ situation: { value: 'all', label: 'Todos' } })

      window.digitalData = {
        'site': {
          'nome': 'Banco Pan'
        },
        'page': {
          'secao': 'IndiqueoPan',
          'estadoLogin': 'LG',
          'nome': 'BP:IndiqueOPan:PagamentoCorporacao',
        },
        'user': {
          'crmid': userData.id,
          'tipo': userData.brType
        }
      }

      var evt = new CustomEvent('event-view-start');
      document.body.dispatchEvent(evt);

      introSystem(routeKey)
    }

    this.setState({ loadingTable: false })

    this.setColumns({paymentData: [], product: ''})

    if (ISClubeAmigo && userData.level === 'seller') this.handleSearch()
  };

  setColumns = async ({paymentData, product}) => {
    const strictLevel = this.props.history.location.pathname.includes('corporations') ? 'corporations'
    : this.props.history.location.pathname.includes('affiliates') ? 'affiliates'
      : ''

    const userData = await getUserData(this.props.history);

    const tableColumns = this.state.strictLevel !== ''
    ? getTableConfig({level: strictLevel, isIntegratedPartner: this.hasTableClientCode(), strictId: userData?.corpId, loading: this.loadingTable, productId: this.state.product?.value })
    : getTableConfig({
      level: userData.level,
      hasManager: userData.hasManager,
      isIntegratedPartner: this.hasTableClientCode(),
      partnerFilter: this.state.partners,
      strictId: userData?.corpId,
      refreshTable: this.handleSearch,
      productId: this.state.product?.value
    })

    if (product?.value === maquininhaId) {

      if (tableColumns.some(column => column?.key === "detailsColumns")) {
        tableColumns.shift()
      }

      const formattedTableColumns = [
        {
          name: "",
          label: "",
          key: "detailsColumns",
          options: {

            setCellProps: (value, row) => ({ className: 'no-wrap' }),
            filter: false,
            sort: false,
            customBodyRender: (value, row) => {
              const details = paymentData[row.rowIndex]
              return (
                <a className='pointer primary-text' onClick={() => this.showDetailsModal(details)}>
                  Detalhes
                </a>
              )
            }
          }
        },
        ...tableColumns,

      ]

      return this.setState({ tableColumns: formattedTableColumns })
    }

    this.setState({ tableColumns })
  }

  showDetailsModal = (details) => {
    this.setState({ showDetailsModal: true, detailsModalData: details })
  }

  onMonthSelect = (selectedDate) => {
    if (selectedDate === this.state.date) return;
    this.setState({ date: selectedDate });
    ;
  };

  onPageChange = (newPage) => {
    const { totalPages } = this.state
    if (newPage >= 0 && newPage <= totalPages) {
      this.setState({ page: newPage }, () => {
        this.getPaymentData()
      })
    }
  }

  onSortChange = (value, type) => {
    const { date, page } = this.state

    const hasPlus = 'descending' === type ? '-' : ''
    this.setState({ sort: hasPlus + value }, () => this.getPaymentData('loading', date, page))
  }

  reload = async () => {
    await this.getPaymentData();
  }

  handleSearch = () => {
    const inputValue = Array.from(Array.from(this.refSearch.current.childNodes).find(tag => tag.tagName === 'DIV').childNodes).find(tag => tag.tagName === 'INPUT').value
    this.setState({ searchData: inputValue }, () => this.getPaymentData())
  }

  updateSwitch = (value) => {
    this.setState({ selectedSwitch: value })
  }

  showConsignadoProducts = () => {
    const { userData, product } = this.state

    const IsClubeAmigo = [ClubeAmigoId].includes(userData?.corpId)
    const IsConsignado = product?.value === consignadoId
    return IsClubeAmigo && IsConsignado
  }

  async getVoucher () {
    try {
      const response = await mainApi.get('/voucher/link')
      const link = response.data.data.link
      let a = document.createElement('a')

      a.href = link
      a.target = '_blank'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a);
    } catch (err) {
      console.log(err)
    }
  }

  async getVoucherReport () {
    this.setState({ isLoadingVoucherReport: true })
    try {
      const response = await mainApi.get('/voucher/report')
      const report = response.data.data

      this.setState({ showVoucherReport: true, voucherReport: report })

    } catch (err) {
      console.log(err)
    } finally {
      this.setState({ isLoadingVoucherReport: false })
    }
  }

  render() {
    const {
      paymentData,
      loading,
      loadingTable,
      swalMessageShow,
      swalMessage,
      totalDocs,
      page,
      tableColumns,
      product,
      productList,
      time,
      situation,
      api,
      status,
      userData,
      limit,
      strictLevel,
      corporations,
      selectedCorp,
      selectedPartner,
      partnersInput,
      date,
      selectedInitialDataStatus,
      selectedSwitch,
      tableTitle,
      noDataMessage,
      searchData,
      filterLabel,
      productName,
      showVoucherReport,
      voucherReport,
      isLoadingVoucherReport
    } = this.state

    const isOmnichannel = api.value === 'omnichannel' || api.label === 'Todos'
    const showStatus = this.productListHasConta() && ['pending', 'refused'].includes(situation.value) && isOmnichannel
    const showContaStatus = (['pending'].includes(situation.value) && isOmnichannel)
    const showInitialDataStatus = showStatus && showContaStatus && (status?.value === 'dados_iniciais' || status?.label === 'Todos')


    const tableOptions = MuiDataTableOptions.paymentCorp({
      customToolbar: () => { },
      customFilter: this.customFilter,
      onSortChange: this.onSortChange,
      onPageChange: this.onPageChange,
    })


    tableOptions.download = false
    tableOptions.search = false //ToDo
    tableOptions.filter = false
    tableOptions.rowsPerPageOptions = [10, 30, 75]
    tableOptions.rowsPerPage = limit
    tableOptions.count = totalDocs
    tableOptions.page = page - 1
    tableOptions.onTableChange = (action, tableState) => {
      switch (action) {
        case 'changePage':
          this.onPageChange(tableState.page + 1)
          break;
        case 'changeRowsPerPage':
          this.setState({ limit: tableState.rowsPerPage, page: 1 }, () =>
            this.getPaymentData());
          break;
        default:
          break;
      }
    }


    const rowStyle = { display: 'flex', flexWrap: 'wrap', margin: '0 0 0 1em' }
    const colStyle = { minWidth: 240, flexBasis: '30%', flexGrow: 1, textAlign: 'left', marginRight: '1em' }

    const FilterFields = {
      EmptyCol: <>
      <div style={colStyle}></div>
      </>,
      Product: <>
        <FormHelperText>Produto</FormHelperText>
        <Select
          className='custom-select'
          styles={{ menu: styles => ({ ...styles, zIndex: 999 }) }}
          options={productList}
          onChange={(e) => {
            let handlePlatform = {}
            if (situation.value === 'all')
              handlePlatform = { situation: situations[0] }
            this.setState({ product: e, status: emptySelect, selectedInitialDataStatus: emptySelect, api: emptySelect, ...handlePlatform })
          }}
          isDisabled={loadingTable || loading}
          value={product}
        >
        </Select>
      </>,
      TipoConsignado: <>
        <FormHelperText>Tipo de Consignado</FormHelperText>
        <Select
          className='custom-select'
          styles={{ menu: styles => ({ ...styles, zIndex: 999 }) }}
          options={[emptySelect, ...consignadoProducts]}
          value={productName}
          isDisabled={loadingTable || loading}
          onChange={(e) => {
            this.setState({productName: e})
          }}
        />
      </>,
      Situacao: <>
        <FormHelperText>Situação</FormHelperText>
        <Select
          className='custom-select'
          styles={{ menu: styles => ({ ...styles, zIndex: 999 }) }}
          options={(api.value === 'omnichannel' || product?.value === maquininhaId || (ISClubeAmigo && userData.level === 'seller')) ? [{ value: 'all', label: 'Todos' }, ...situations] : situations}
          isDisabled={loadingTable || loading}
          onChange={(e) => {
            this.setState({ situation: e, status: emptySelect, selectedInitialDataStatus: emptySelect })
          }}
          value={situation}
        >
        </Select>
      </>,
      DadosIniciais: <>
        <FormHelperText>Status de dados iniciais</FormHelperText>
        <Select
          className='custom-select'
          styles={{ menu: styles => ({ ...styles, zIndex: 999, width: 200 }) }}
          options={initialDataStatus}
          isDisabled={loadingTable || loading}
          onChange={(e) => {
            this.setState({ selectedInitialDataStatus: e, status: pendingStatus.find(status => status.value === 'dados_iniciais'), api: this.omnichannelPlatform })
          }}
          value={selectedInitialDataStatus}
        >
        </Select>
      </>
    }
    return (
      <div className='content'>
        <DetailsModal
          show={this.state.showDetailsModal}
          details={this.state.detailsModalData}
          onClose={() => this.setState({ showDetailsModal: false })}
        />

        <Dialog
          type={swalMessage[0]}
          show={swalMessageShow}
          title={swalMessage[1] || ''}
          onConfirm={() => this.setState({ swalMessageShow: false })}
          text={swalMessage[2]}
        />
        {(ISClubeAmigo && userData.level === 'seller') && (
          <>
            <Modal
              show={showVoucherReport}
              onHide={() => this.setState({ showVoucherReport: false, voucherReport: {} })}
            >
              <Modal.Body className='voucher-report-modal-wrapper' style={{display:'flex', justifyContent:'center', overflow: 'auto'}}>
                <div className='voucher-report-modal'>
                  <VoucherReportTable data={voucherReport?.dados} />
                </div>
              </Modal.Body>
            </Modal>
            <div className='proposal-status-voucher-buttons footer__buttons'>
              <button
                className='btn btn-mlm btn-secondary'
                onClick={() => this.getVoucherReport()}
                disabled={loadingTable || loading}
              >
                {isLoadingVoucherReport && <> <i aria-hidden="true" className="af-button-icon fa fa-spinner fa-spin"></i>{' '}</>}
                Relatório de vouchers
              </button>
              <button
                className='btn btn-mlm btn-round btn-fill btn-block'
                onClick={() => this.getVoucher()}
                disabled={loadingTable || loading}
              >
                Ir para recebimento
              </button>
            </div>
          </>
        )}
        {
          (ISClubeAmigo && userData.level === 'seller') && <h2 className='proposal-status__sub-title'>Pesquisa</h2>
        }
        <Row style={rowStyle}>
          <div style={colStyle}>
            <div style={{ minWidth: '100%', textAlign: 'left' }}>
              {FilterFields.Product}
            </div>
          </div>
          {ISClubeAmigo && userData.level !== 'corporation' && this.showConsignadoProducts() &&
            <div style={colStyle}>
              {FilterFields.TipoConsignado}
            </div>
          }
          {strictLevel === 'corporations' ?
            <div style={colStyle}>
              <div style={{ minWidth: '100%', textAlign: 'left' }}>
                <FormHelperText>Corporação</FormHelperText>
                <Select
                  className='custom-select'
                  styles={{ menu: styles => ({ ...styles, zIndex: 999 }) }}
                  isDisabled={loadingTable || loading}
                  options={[emptySelect, ...corporations]}
                  onChange={(e) => {
                    this.setState({ selectedCorp: e })
                  }}
                  value={selectedCorp}
                >
                </Select>
              </div>
            </div>
            : (strictLevel === '' && ["corporation", "subcorp"].includes(userData.level)) &&
            <div style={colStyle}>
              <div style={{ minWidth: 140, textAlign: 'left' }}>
                <FormHelperText>{translateLevel(userData.hasManager ? 'Parceiro' : 'Loja', userData?.corpId)}</FormHelperText>
                <Select
                  className='custom-select'
                  styles={{ menu: styles => ({ ...styles, zIndex: 999, width: 200 }) }}
                  options={[emptySelect, ...partnersInput]}
                  value={selectedPartner}
                  isDisabled={loadingTable || loading}
                  onChange={(e) => {
                    this.setState({ selectedPartner: e })
                  }}
                >
                </Select>
              </div>
            </div>
          }
          <div style={colStyle}>
            <FormHelperText>Data</FormHelperText>
            <MuiDatepickerMonth
              disabled={loadingTable || loading}
              date={date}
              onChange={(date, event) => this.onMonthSelect(date, event)}
              isClearable={false}
            />
          </div>
          {FilterFields.EmptyCol}
        </Row>
        <Row style={rowStyle}>
          {ISClubeAmigo && (
            <div style={colStyle}>
              {FilterFields.Situacao}
            </div>
          )}
          <div style={colStyle}>
            <FormHelperText>Parâmetro de pesquisa {selectedSwitch.label}</FormHelperText>
            <Switch
              values={searchStatusOptions}
              selectedSwitch={selectedSwitch}
              setSelectedSwitch={this.updateSwitch}
              disabled={loadingTable || loading}
            />
          </div>
          <div style={colStyle}>
            <FormHelperText>Pesquisar por {selectedSwitch.label}</FormHelperText>
            <TextField
              className={'input-background'}
              placeholder={`Insira um ${selectedSwitch.label}`}
              InputProps={{
                classes: {
                  input: "mui-input-control mui-input-small-font",
                  root: "mui-input-control",
                }
              }}
              InputLabelProps={{
                classes: {
                  root: "mui-input-control mui-label mui-input-small-font",
                },
              }}
              disabled={loadingTable || loading}
              fullWidth
              color="primary"
              variant="outlined"
              onKeyDown={(e) => {
                if (e.keyCode === 13)
                  if (!(loading || loadingTable)) this.handleSearch()
              }}
              ref={this.refSearch}
              style={{ fontSize: 13 }}
            />
          </div>
          <div style={colStyle}>
            {this.productListHasConta() &&
              <>
                <FormHelperText>Plataforma</FormHelperText>
                <Select
                  className='custom-select'
                  styles={{ menu: styles => ({ ...styles, zIndex: 999 }) }}
                  options={[emptySelect, ...platforms]}
                  value={api}
                  isDisabled={loadingTable || loading}
                  onChange={(e) => {
                    let handlePlatform = {}
                    if (situation.value === 'all')
                      handlePlatform = { situation: situations[0] }
                    this.setState({ api: e, status: emptySelect, selectedInitialDataStatus: emptySelect, ...handlePlatform })
                  }}
                />
              </>
            }
            {(!ISClubeAmigo || userData.level === 'corporation') && this.showConsignadoProducts() &&
              FilterFields.TipoConsignado
            }
          </div>
          {FilterFields.EmptyCol}
          {(!ISClubeAmigo || userData.level === 'corporation') && FilterFields.EmptyCol}
        </Row >
        {this.productListHasConta() && <>{FilterFields.EmptyCol} {FilterFields.EmptyCol}</>}

        <Row style={{ display: 'flex', flexWrap: 'wrap', margin: '0 0 1.5em 1em' }}>
          {!ISClubeAmigo && <div style={colStyle}>
            {FilterFields.Situacao}
          </div>}
          <div style={colStyle}>
            {showStatus &&
              <>
                <FormHelperText>Status da {['pending'].includes(situation.value) ? 'pendência' : situation.value === 'refused' && 'recusa'}</FormHelperText>
                <Select
                  className='custom-select'
                  styles={{ menu: styles => ({ ...styles, zIndex: 999, width: 200 }) }}
                  options={['pending', 'all'].includes(situation.value) ? pendingStatus : situation.value === 'refused' && refusedStatus}
                  isDisabled={loadingTable || loading}
                  onChange={(e) => {
                    this.setState({ status: e, selectedInitialDataStatus: emptySelect, api: this.omnichannelPlatform })
                  }}
                  value={status}
                >
                </Select>
              </>
            }
          </div>
          <div style={colStyle}>
            {(showInitialDataStatus) &&
              <>
                {FilterFields.DadosIniciais}
              </>
            }
          </div>
        </Row>
        <Row style={rowStyle}>
          <div className={`footer__buttons ${ISClubeAmigo ? 'proposal-status-filters__button' : ''}`} style={colStyle}>
            <button
              className='btn btn-mlm btn-round btn-fill btn-block'
              onClick={() => this.handleSearch()}
              disabled={loadingTable || loading}
            >
              Pesquisar
            </button>
          </div>
          {!ISClubeAmigo && (
            <>
            {FilterFields.EmptyCol}
            {FilterFields.EmptyCol}
            </>
          )}
        </Row>
        <Row style={{ width: '100%' }}>
          {(showInitialDataStatus) &&
            FilterFields.EmptyCol
          }
          <div
            style={{
              paddingTop: '1em',
              marginLeft: 'auto',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-end'
            }}
          >
            <span className='txtUpdateDashboard'>
              Última atualização:{' '}
              {loading ? 'Buscando...' : formatToBrWithHour(moment(time).toDate())}
            </span>

            <span
              onClick={this.reload}
              style={{
                cursor: 'pointer',
                marginLeft: '10px',
                marginRight: '10px',
              }}
            >
              <MahoeIcon name='reload' size='22px' fill='#039BE5' />
            </span>
          </div>
        </Row>
        <div className='container-fluid'>
          <Row>
            <div className='tabelavendascontainer'>
              <Col md={12} className='table'>
                <div className='card'>
                  {loadingTable ? (
                    <LoadingGif />
                  ) : (
                    <div>
                      <MuiThemeProvider theme={getMuiTheme}>
                        {noDataMessage ? // state change doesn't trigger a rerender of the table's options, so we render whole new one instead
                          <MUIDataTable
                            columns={tableColumns}
                            data={paymentData}
                            options={{
                              ...tableOptions,
                              textLabels: {
                                ...tableOptions.textLabels,
                                body: {
                                  ...tableOptions.textLabels.body,
                                  noMatch:
                                    <div className='table-no-data-message'>
                                      <>
                                        <SearchIcon style={{ height: 30, width: 30 }} />
                                        <h6>{noDataMessage}</h6>
                                      </>
                                    </div>
                                }
                              }
                            }}
                            title={
                              <h5 variant='title'>
                                {tableTitle}
                                <HelpAccountOpening />
                                {loading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}
                              </h5>
                            }
                          /> :
                          <MUIDataTable
                            columns={tableColumns}
                            data={paymentData}
                            options={tableOptions}
                            title={
                              <>
                                <h5 variant='title'>
                                  {tableTitle}
                                  <HelpAccountOpening />
                                  {loading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}
                                </h5>
                                <div style={{ display: 'flex', gap: '.5em', flexWrap: 'wrap', alignItems: 'center' }}>
                                  <h6><strong>Filtros</strong>:</h6> {filterLabel || 'Nenhum'}
                                </div>
                              </>
                            }
                          />}
                      </MuiThemeProvider>
                    </div>
                  )}
                </div>
              </Col>
            </div>
          </Row>
        </div>
      </div>
    );
  }
}

export default ProposalStatus;
