import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import Card from "components/Card/Card";
import mainApi from "services/mainApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import InputMask from "react-input-mask";
import { CopyToClipboard } from "react-copy-to-clipboard";
import QRCode from "qrcode.react";
import { TextField } from "@material-ui/core";
import dev from "utils/dev";
import { introSystem } from 'utils/introToSystem';
import getUserData from "utils/checkUser";
import dataTour from 'utils/dataTour'

import LinkVoltar from 'components/LinkVoltar'
import { translateLevel } from "utils/translate";
import { ClubeAmigoId } from "utils/CommonData";
import { ISClubeAmigo } from 'utils/environment'
import Swal from "sweetalert2";

const env = process.env.REACT_APP_ENV;

class Home extends Component {
  constructor(props) {
    super(props);
    let url;

    switch (env) {
      case "production":
        url = "https://afiliados.pan.agxsoftware.com";
        break;
      case "development":
        url = "https://homologacaoindiqueopan.agxsoftware.com";
        break;
      case "local":
        url = "http://10.10.0.215:3000";
        break;
      default:
        url = "http://10.10.0.215:3000";
    }

    this.state = {
      generatedLink: "",
      shareWithWhatsApp: "",
      sendingMail: false,
      phone: "",
      email: "",
      userData: {},
      url,
      categoryText: "",
      userId: undefined,
      agencyId: undefined,
    };
  }

  componentDidMount = async () => {
    const userData = await getUserData(this.props.history);
    const { agencyName, agencyId } = this.props.location.state ?? {}

    if (agencyName && agencyId) {
      let title = document.querySelector('#title-page-affiliate')
      title.innerHTML += ` ${agencyName}`
    }

    window.digitalData = {
      "site": {
        "nome": "Banco Pan"
      },
      "page": {
        "secao": "IndiqueoPan",
        "estadoLogin": "LG",
        "nome": "BP:IndiqueOPan:Convidar",
      },
      "user": {
        "crmid": userData.id,
        "tipo": userData.brType
      }
    }

    let categoryText = ''

    if (userData?.level === 'administrator') {
      categoryText += ' à sua loja.'
    } else if (userData?.level === 'corporation') {
      categoryText += ` a esta loja.`
    } else if (ISClubeAmigo) {
      categoryText = '.'
    } else {
      categoryText += ' à sua equipe.'
    }

    this.setState({ categoryText })

    var evt = new CustomEvent('event-view-start');
    document.body.dispatchEvent(evt);

    this.setState({ search: this.props.location.search, userData })
    this.selectAfiliateLink()

    const { routeKey } = this.props
    introSystem(routeKey)
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.search === null) this.setState({ search: this.props.location.search })
    if (prevState.search !== this.state.search)
      this.selectAfiliateLink()
  }

  selectAfiliateLink = async () => {
    const { agencyId } = this.props.location.state || {};
    const { userData } = this.state

    if (['corporation', 'subcorp', 'master', 'submaster'].includes(userData.level)) {
      if (agencyId) {

        this.generateAffiliateLink(agencyId, true);
        this.setState({ agencyId: agencyId });
        return;

      } else {

        this.generateAffiliateLink(userData.id);
        this.setState({ userId: userData.id });
        return;

      }
    } else if (userData.level === "administrator") {

      const response = await mainApi.get("/user/info");
      this.generateAffiliateLink(response.data.agencyId, true);
      this.setState({ agencyId: response.data.agencyId });
      return;

    } else if ([ClubeAmigoId].includes(userData.corpId) && !['supervisor'].includes(userData.level)) {
      this.generateAffiliateLink(userData.supervisorId);
      this.setState({ userId: userData.supervisorId });
      return
    }

    this.generateAffiliateLink(userData.id);
    this.setState({ userId: userData.id });
  }

  generateAffiliateLink = async (id, agency = false) => {
    const { url, userData } = this.state;
    let link = "";

    let origin = document.location.origin || url

    const supervisorId = ['supervisor'].includes(userData.level) ? userData.id : userData?.supervisorId
    const corpId = ['corporation'].includes(userData.level) ? userData.id : userData?.corpId

    if (agency) link = `${origin}/signup?agencyId=${id}`;
    else link = `${origin}/signup?invite=${id}&agencyId=${userData?.agencyId}&corpId=${corpId}&supervisorId=${supervisorId}`;

    const params = { link }
    if (ISClubeAmigo && corpId) params.corpId = corpId
    if (ISClubeAmigo && supervisorId) params.supervisorId = supervisorId
    if (ISClubeAmigo && userData?.agencyId) params.agencyId = userData?.agencyId
    if (ISClubeAmigo && id) params.invite = id

    try {
      const response = await mainApi.post("/invite/shorten", params);
      this.setState({ generatedLink: response?.data?.message || '' });
    } catch (err) {
      console.log(err)
    }
  };

  shareWithEmail = async () => {
    const { email, agencyId, userId, generatedLink, userData } = this.state;

    const supervisorId = ['supervisor'].includes(userData.level) ? userData.id : userData?.supervisorId
    const corpId = ['corporation'].includes(userData.level) ? userData.id : userData?.corpId

    let responseResult = false
    this.setState({ sendingMail: true });
    const data = {};
    data.email = email;
    data.agencyId = agencyId;
    data.userId = userId;
    data.link = generatedLink;
    data.supervisorId = supervisorId;
    data.corpId = corpId;


    try {
      const response = await mainApi.post("/invite/user", data);

      if (response) {
        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: response.data?.message || ''
        })
      }
      this.setState({ sendingMail: false });

      responseResult = true
    } catch (err) {
      if (err.response) {
        let errorMessage = ""
        if (typeof err.response.data === 'string') errorMessage = err.response.data
        if (typeof err.response.data === 'object' && typeof err.response.data.message === 'string') errorMessage = err.response.data.message

        Swal.fire({
          icon: "warning",
          title: "Atenção",
          text: errorMessage
        })

        dev.log(err.response);
      }

      this.setState({ sendingMail: false });
      responseResult = false
    }

    if (responseResult) {
      var eventDetail = { "detail": { "button": "Click:BP:IndiqueOPan:ConvidarCompartilharLinkEmail" } };
      var interactionClick = new CustomEvent("interactionClick", eventDetail);
      document.body.dispatchEvent(interactionClick);
    }
  };

  copyLinkAdobeAction() {
    var eventDetail = { "detail": { "button": "Click:BP:IndiqueOPan:ConvidarCopiarLink" } }
    var interactionClick = new CustomEvent("interactionClick", eventDetail)
    document.body.dispatchEvent(interactionClick);
  }

  shareWithWhatsAppAdobeAction() {
    var eventDetail = { "detail": { "button": "Click:BP:IndiqueOPan:ConvidarCompartilharLinkWApp" } };
    var interactionClick = new CustomEvent("interactionClick", eventDetail);
    document.body.dispatchEvent(interactionClick);
  }

  sendMailAdobeAction() {
    var eventDetail = { "detail": { "button": "Click:BP:IndiqueOPan:ConvidarCompartilharLinkEmail" } };
    var interactionClick = new CustomEvent("interactionClick", eventDetail);
    document.body.dispatchEvent(interactionClick);
  }

  composeWhatsMessage = (event) => {
    const { userData, generatedLink } = this.state
    console.log({ generatedLink })
    const rawPhone = event.target.value.replace(/\D/g, "");
    let shareWithWhatsApp;

    if (userData.corpName && userData.level === "supervisor") {

      shareWithWhatsApp = `https://api.whatsapp.com/send?phone=55${rawPhone}&text=Você foi convidado(a) por, ${userData && userData.name
        }, para fazer parte de sua equipe em ${userData && userData.corpName
        } ${ISClubeAmigo ? ',' : `(${userData.agencyName
          }),`} cadastre-se em: ${generatedLink}`;

    } else if (userData.corpName) {

      shareWithWhatsApp = `https://api.whatsapp.com/send?phone=55${rawPhone}&text=Você foi convidado(a) por, ${userData && userData.name
        }, para fazer parte de ${userData && userData.corpName
        } (${userData.agencyName
        }), cadastre-se em: ${generatedLink}`;

    } else {
      shareWithWhatsApp = `https://api.whatsapp.com/send?phone=55${rawPhone}&text=Você foi convidado(a) por, ${userData && userData.name
        }, para cadastrar-se como afiliado na plataforma MLM: ${generatedLink}`;
    }

    if (['corporation', 'subcorp'].includes(userData.level)) {
      shareWithWhatsApp = `https://api.whatsapp.com/send?phone=55${rawPhone}&text=Você foi convidado(a) por, ${userData && userData.name
        }, para ser um vendedor, cadastre-se em: ${generatedLink}`;
    }

    this.setState({
      phone: event.target.value,
      shareWithWhatsApp,
    });
  }

  isAdminOrCorp = () => {
    const { userData } = this.state
    return userData?.level === 'administrator' || userData?.level === 'corporation'
  }

  isSeller = () => {
    const { userData } = this.state
    return userData?.level === 'seller'

  }

  isCorporation = () => {
    const { userData } = this.state
    return userData?.level === 'corporation'
  }

  render() {
    const { generatedLink, phone, email, sendingMail, categoryText, userData } = this.state;
    let btnWhatsDisabled = true;
    if (phone) {
      const rawPhone = phone.replace(/\D/g, "");
      if (rawPhone.length === 11) {
        btnWhatsDisabled = false;
      } else btnWhatsDisabled = true;
    }
    let btnMailDisabled = true;

    if (email && !sendingMail) {
      // eslint-disable-line
      let validator = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (validator.test(String(email).toLowerCase())) btnMailDisabled = false;
    }

    return (
      <div className="content">
        <div className="container-fluid">
          {this.isCorporation() ? <LinkVoltar /> : ""}

          <div className="affiliate-grid">
            <div>
              <Card
                style={{ minHeight: "100%", marginBottom: 0 }}
                title={`Link para convidar novos ${translateLevel('seller', userData?.corpId)}es${categoryText}`}
                category={`O ${translateLevel('seller', userData?.corpId)} cadastrado via este link será adicionado${categoryText}`}
                content={
                  <>
                    <Row>
                      <Col md={12} xs={12}>
                        <Row data-intro={this.isAdminOrCorp() ? dataTour.affiliate.linkAdmin : dataTour.affiliate.link}>
                          {this.isSeller() && (
                            <p className="pl-3" style={{ fontWeight: 'bold', color: 'red' }}>Atenção: Esse convite não gera nenhum tipo de remuneração.</p>
                          )}
                          <div className="form-group col-lg-8 col-md-8 col-xs-12">
                            <input
                              className="form-control generated-link"
                              placeholder="O link aparecerá aqui, quando gerado."
                              value={generatedLink}
                              readOnly
                            />
                          </div>
                          <div className="form-group col-lg-4 col-md-4 col-xs-12 ">
                            <CopyToClipboard
                              text={this.state.generatedLink}
                              onCopy={this.copyLinkAdobeAction}
                            >
                              <button
                                className="btn btn-fill btn-block btn-round btn-mlm"
                                disabled={!this.state.generatedLink}
                              >
                                Copiar
                              </button>
                            </CopyToClipboard>
                          </div>
                        </Row>

                        <Row data-intro={dataTour.affiliate.whats}>
                          <div className="form-group col-lg-8 col-md-7 col-xs-12">
                            <InputMask
                              mask="(99) 99999-9999"
                              value={phone}
                              onChange={(event) => {
                                this.composeWhatsMessage(event)
                              }}
                            >
                              {() => (
                                <TextField
                                  label="Celular"
                                  InputProps={{
                                    classes: {
                                      input: "mui-input-control",
                                      root: "mui-input-control",
                                    },
                                  }}
                                  InputLabelProps={{
                                    classes: {
                                      root: "mui-input-control mui-label",
                                    },
                                  }}
                                  fullWidth
                                  variant="outlined"
                                />
                              )}
                            </InputMask>
                          </div>

                          <div className="form-group col-lg-4 col-md-5 col-xs-12 ">
                            {/* eslint-disable-next-line */}
                            <a
                              href={
                                !btnWhatsDisabled
                                  ? this.state.shareWithWhatsApp
                                  : "#"
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <button
                                onClick={this.shareWithWhatsAppAdobeAction}
                                className="btn btn-fill btn-block btn-round btn-mlm"
                                disabled={btnWhatsDisabled}
                              >
                                <FontAwesomeIcon
                                  icon={faWhatsapp}
                                  size="lg"
                                  style={{ marginRight: 2 }}
                                />
                                WhatsApp
                              </button>
                            </a>
                          </div>
                        </Row>
                        <Row data-intro={dataTour.affiliate.email}>
                          <div className="form-group col-lg-8 col-md-7 col-xs-12">
                            <TextField
                              label="Email"
                              InputProps={{
                                classes: {
                                  input: "mui-input-control",
                                  root: "mui-input-control",
                                },
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: "mui-input-control mui-label",
                                },
                              }}
                              variant="outlined"
                              value={email}
                              fullWidth
                              onChange={(event) => {
                                this.setState({
                                  email: event.target.value,
                                  userChanged: true,
                                });
                              }}
                              placeholder="Email"
                            />
                          </div>

                          <div className="form-group col-lg-4 col-md-5 col-xs-12 ">
                            <button
                              target="_blank"
                              rel="noopener noreferrer"
                              className="btn btn-fill btn-block btn-round btn-mlm"
                              onClick={this.shareWithEmail}
                              disabled={btnMailDisabled}
                            >
                              <FontAwesomeIcon
                                icon={faEnvelope}
                                size="lg"
                                style={{ marginRight: 2 }}
                              />
                              {sendingMail ? "Enviando..." : "Enviar Via Email"}
                            </button>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  </>
                }
              />
            </div>

            <div data-intro={dataTour.affiliate.qrCode}>
              <Card
                style={{
                  minHeight: "100%",
                  marginBottom: 0,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                content={
                  <div
                    style={{
                      flex: 1,
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    {this.state.generatedLink ? (
                      <>
                        <p>QRCode: </p>
                        <QRCode
                          renderAs="svg"
                          value={this.state.generatedLink}
                        />
                      </>
                    ) : null}
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
