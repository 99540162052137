import spinner from "assets/img/spinner.gif";
import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import QRCode from "qrcode.react";
import ReactDOM from "react-dom";

function createRootElement(id) {
  const rootElement = document.createElement("div");
  rootElement.id = id;
  return rootElement;
}

export default function Portal({ id, children }) {
  let element = document.getElementById(id);
  const [rootElement, setRootElement] = useState(element);

  useEffect(() => {
    const newRootElement = rootElement || createRootElement(id);

    // If there is no existing DOM element, add a new one.
    if (!rootElement) {
      document.body.appendChild(newRootElement);
    }

    setRootElement(newRootElement);

    // Remove root element from DOM on component unmount
    return () => newRootElement.innerHTML = '';
    //eslint-disable-next-line
  }, [id]);

  if (!rootElement) {
    return null;
  }

  return createPortal(children, rootElement);
}

function Preview(props) {
  const { url, show, onConfirm } = props;
  return (
    <Portal id="sweet-alert-portal">
      <SweetAlert
        title="Preview"
        show={show}
        confirmBtnCssClass={'btn-mlm btn-fill btn-round btn-sweet-borderfix'}
        confirmBtnText={"Fechar"}
        onConfirm={onConfirm}
      >
        <img style={{
          border: '2px solid #a3a3a3',
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
          borderRadius: '5px',
          padding: '15px',
          maxWidth: '85%',
          maxHeight: '85%'
        }} src={url} alt="" />
      </SweetAlert>
    </Portal>
  )
}

function History(props) {
  const { show, onConfirm, title, content } = props;
  return (
    <Portal id="sweet-alert-portal">
      <SweetAlert
        title={title}
        show={show}
        confirmBtnCssClass={'btn-mlm btn-fill btn-round btn-sweet-borderfix'}
        confirmBtnText={"Fechar"}
        onConfirm={onConfirm}
        style={{ width: "65em" }}
      >
        {content}
      </SweetAlert>
    </Portal>
  )
}

const printQrCode = ({ url, name }) => {
  const { width, height } = window.screen;
  var printWindow = window.open(
    "",
    "Imprimir Código QR",
    `width=${width},height=${height},top=200, left=580`
  );
  printWindow.document.write(`
  <html><head><title>Imprimir QR Code</title><link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" integrity="sha384-BVYiiSIFeK1dGmJRAkycuHAHRg32OmUcww7on3RYdg4Va+PmSTsz/K68vbdEjh4u" crossorigin="anonymous"></head><body>
  <div id='print-root'></div></body>`);
  let printComponent = (
    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', }}>
      <h3 style={{ marginBottom: 40 }}>QRCode para venda de {name}</h3>
      <QRCode value={url} renderAs="canvas" size={428} />
      <button style={{ width: '120px', marginTop: 40 }} className="btn" onClick={() => printWindow.print()}>
        Imprimir
      </button>
    </div>
  );
  ReactDOM.render(printComponent, printWindow.document.getElementById("print-root"));
};

const downloadQrCode = ({ name }) => {
  const canvas = document.querySelector('.download-qrcode').toDataURL()
  if (canvas) {
    const link = document.createElement('a');
    link.href = canvas
    link.download = 'QR Code ' + name + '.png'
    link.click();
  }
}

function QrCode({ url, show, name, onClose }) {
  return (
    <Portal id="sweet-alert-portal">
      <SweetAlert
        title="QR"
        showCloseButton={true}
        closeBtnStyle={{ border: 'none' }}
        show={show}
        customButtons={
          <div style={{ display: 'flex', gap: '1em', flexWrap: 'wrap' }}>
            <button
              className='btn btn-fill btn-mlm'
              style={{ flexGrow: 1, minWidth: 130 }}
              onClick={() => printQrCode({ url, name })}
            >
              Imprimir
            </button>
            <button
              className='btn btn-fill btn-mlm'
              style={{ flexGrow: 1, minWidth: 130 }}
              onClick={() => downloadQrCode({ name })}
            >
              Baixar PNG
            </button>
          </div>
        }
        confirmBtnCssClass='btn-mlm btn-fill btn-round btn-sweet-borderfix'
        confirmBtnText="Imprimir"
        cancelBtnCssClass="btn btn-fill btn-round btn-sweet-borderfix"
        cancelBtnText="Fechar"
        onCancel={onClose}
      >
        <QRCode
          value={url}
          renderAs="canvas"
          size={200}
        />
        <QRCode
          style={{display: 'none'}}
          value={url}
          className='download-qrcode'
          renderAs="canvas"
          size={428}
          bgColor='#FFFFFF00'
        />
      </SweetAlert>
    </Portal>
  )
}


function LoadingAlert(props) {
  const { show, title } = props;
  return (
    <Portal id="sweet-alert-portal">
      <SweetAlert
        show={show}
        title={title || "Trabalhando"}
        showConfirm={false}
        onConfirm={() => { }}
      >
        <img src={spinner} alt="" />
      </SweetAlert>
    </Portal>
  );
}

function ConfirmationAlert(props) {
  const {
    show,
    title,
    text,
    cancelBtnText,
    confirmBtnText,
    onCancel,
    onConfirm,
    confirmBtnCssClass,
    type,
    bold
  } = props;
  return (
    <Portal id="sweet-alert-portal">
      <SweetAlert
        type={type || 'info'}
        show={show}
        title={title || "Confirmar?"}
        confirmBtnCssClass={`btn-mlm btn-fill btn-round btn-sweet-borderfix ${confirmBtnCssClass ? confirmBtnCssClass : null
          }`}
        cancelBtnCssClass="btn btn-fill btn-round btn-sweet-borderfix"
        cancelBtnText={cancelBtnText || "Não, voltar"}
        confirmBtnText={confirmBtnText || "Sim"}
        showCancel
        onCancel={onCancel}
        onConfirm={onConfirm}
      >
        <>
          {text}
          <br />
          <strong>{bold}</strong>
        </>
      </SweetAlert>
    </Portal>
  );
}

function InputAlert(props) {
  const {
    show,
    title,
    text,
    confirmBtnText,
    cancelBtnText,
    onCancel,
    onConfirm,
    confirmBtnCssClass,
    needConfirmation,
    inputConfig
  } = props;

  const [inputText, setInputText] = useState("");
  const [confirmationInputText, setConfirmationInputText] = useState("");

  const [confirmationInputError, setConfirmationInputError] = useState("");

  const handleConfirm = () => {

    let pattern = inputConfig.pattern || "[^\n]+"
    const reg = new RegExp(pattern)
    if (reg.test(inputText)) {
      if (needConfirmation) {
        if (inputText !== confirmationInputText) {
          setConfirmationInputError(`${inputConfig.title}s devem ser iguais`);
          return
        }
      }
      onConfirm(inputText)
      setConfirmationInputError('')
    } else {
      setConfirmationInputError("Email inválido");
    }

  }
  return (
    <Portal id="sweet-alert-portal">
      <SweetAlert
        info
        show={show}
        title={title || "Insira o Texto"}

        confirmBtnCssClass={`btn-mlm btn-fill btn-round btn-sweet-borderfix ${confirmBtnCssClass ? confirmBtnCssClass : null
          }`}
        cancelBtnCssClass="btn btn-fill btn-round btn-sweet-borderfix"
        cancelBtnText={cancelBtnText || "Não, voltar"}
        confirmBtnText={confirmBtnText || "Confirmar"}
        showCancel
        onCancel={() => { setConfirmationInputError(''); onCancel() }}
        onConfirm={handleConfirm}

      >
        {text}
        <div className="form-group col-xs-12" style={{ marginTop: 20 }}>
          <TextField
            label={props.inputConfig.title}
            InputProps={{
              classes: {
                input: "mui-input-control",
                root: "mui-input-control"
              }
            }}
            InputLabelProps={{
              classes: { root: "mui-input-control mui-label" }
            }}
            variant="outlined"
            fullWidth
            value={inputText}
            error={Boolean(confirmationInputError)}
            onChange={(e) => setInputText(e.target.value)}
            readOnly
            placeholder={props.inputConfig.placeholder}
          />
        </div>
        {needConfirmation &&
          <div className="form-group col-xs-12" style={{ marginTop: 20 }}>
            <TextField
              label={"Confirme o " + props.inputConfig.title}
              InputProps={{
                classes: {
                  input: "mui-input-control",
                  root: "mui-input-control"
                }
              }}
              InputLabelProps={{
                classes: { root: "mui-input-control mui-label" }
              }}
              variant="outlined"
              fullWidth
              error={Boolean(confirmationInputError)}
              value={confirmationInputText}
              onChange={(e) => setConfirmationInputText(e.target.value)}
              onPaste={e => e.preventDefault()}
              readOnly
              placeholder={props.inputConfig.placeholder}
            />
            <p className="error-description">{confirmationInputError}</p>
          </div>
        }
      </SweetAlert>
    </Portal>
  );
}

function Dialog(props) {
  const {
    type,
    show,
    title,
    text,
    onConfirm,
    boldText,
    confirmBtnCssClass
  } = props;

  return (
    <Portal id="sweet-alert-portal">
      <SweetAlert
        type={type || "info"}
        show={show}
        title={title || "Atenção"}
        confirmBtnCssClass={`btn-fill btn-sweet-borderfix btn-mlm ${confirmBtnCssClass ? confirmBtnCssClass : ""
          }`}
        onConfirm={onConfirm}
      >
        <div className="joj">
          <p style={{ fontSize: 18 }}>{text}</p>
          {boldText ? (
            <>
              <strong style={{ fontSize: 16 }}>{boldText}</strong>
            </>
          ) : null}
        </div>
      </SweetAlert>
    </Portal>
  );
}

export { LoadingAlert, ConfirmationAlert, Dialog, InputAlert, Preview, QrCode, History };
