import React, { useEffect, useState } from 'react'
import { getPaymentStatus } from 'utils/formatData';
import HelpIcon from '@material-ui/icons/Help';
import { Modal } from 'react-bootstrap'
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from 'moment';
import { formatToBrWithHour } from 'utils/formaterDate';
import { isPhone } from 'utils/validate';
import MahoeIcon from '../MahoeIcons'

function ProposalStatusBadge ({row, value}) {
  const Status = getPaymentStatus(value)
  const [open, setOpen] = useState(false)
  const [whatsappLink, setWhatsappLink] = useState('')
  const [isCopied, setIsCopied] = useState(false)
  const [message, status, date, url] = row.rowData.slice(-4)
  const link = { message, status, date, url }

  useEffect(() => {
    if (!link) return

    const phone = row.rowData.find(data => isPhone(data))
    if (!phone) return

    setWhatsappLink(`https://api.whatsapp.com/send?phone=55${phone}&text=${link.message}`)
  }, [link])

  if (row.rowData.length >= 4) {
    function closeLinkModal () {
      setOpen(false)
    }

    function openLinkModal (e) {
      setOpen(true)
    }

    function handleCopy () {
      setIsCopied(true)
      setTimeout(() => {
        setOpen(false)
        setIsCopied(false)
      }, 1000)
    }

    return (
      <>
        <div className='label-block__wrapper'>
          {Status}
          {['reenvio_fotos', 'fotos'].includes(value) &&
            <MahoeIcon
              aria-describedby={"iconTooltip"}
              name="error"
              size="1.2em"
              className='label-block__action'
              fontSize={'large'}
              onClick={(e) => {
                openLinkModal(true)
              }}
              onClick={() => setOpen(prev => !prev)}
            />
          }
        <Modal
          show={open}
          onHide={closeLinkModal}
        >
        <Modal.Header closeButton>
          <Modal.Title>Reenviar link</Modal.Title>
        </Modal.Header>
          <Modal.Body>
            <div style={{padding: '1em'}}>
              <p><strong>Data do envio do SMS</strong>: {formatToBrWithHour(link.date)}</p>
              <p><strong>Status do envio do SMS</strong>: {link.status ? 'Enviado' : 'Não enviado'}</p>
              <p><strong>Mensagem</strong>: {link.message}</p>
            </div>
          </Modal.Body>
          <Modal.Footer >
            <div className='footer__buttons'>
              <button
                style={{margin: 0}}
                className="btn btn-fill btn-mlm btn-mlm-sm"
                onClick={closeLinkModal}
              >
                <a href={whatsappLink} target='_blank' style={{ color: 'white' }}>
                  <span><WhatsAppIcon /></span> Reenviar via whats
                </a>
              </button >
              <CopyToClipboard
                text={link.url}
              >
                <button
                  className="btn btn-mlm-sm btn-secondary"
                  onClick={handleCopy}
                >
                  <span><FileCopyOutlinedIcon /></span> {isCopied ? 'Link copiado' : 'Copiar link'}
                </button>
              </CopyToClipboard>
            </div>
          </Modal.Footer>
        </Modal>
        </div>
      </>
    )
  } else {
    return Status
  }
}

export default ProposalStatusBadge