  import React, { Component } from 'react';
import { Details, Image, TitleText, CorpInfo, Wrapper, HistoryContainer } from './styled'
import Card from 'components/Card/CleanCard';
import queryString from "query-string";
import mainApi from "services/mainApi";
import getUserData from "utils/checkUser";
import { bolleanToYesNo } from "utils/translate"
import { formatToBackendFilter, formatToBr } from 'utils/formaterDate';
import MuiSelect from 'components/Selects/MuiSelect';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Storefront, InfoOutlined, Search } from "@material-ui/icons";
import Modal from "components/historyOperatorModal";
import BigTooltip from "components/BigTooltip";
import { LoadingAlert } from 'components/Alert/CustomAlert';
import BackLink from 'components/LinkVoltar'

class OperatorCorpDetails extends Component {
  constructor(props) {
    super(props)

    this.state = {
      queryStrings: {},
      userData: {},
      corporation: {},
      corporationUser: {},
      products: [],
      showHistoryModal: false,
      historyTableRows: [],
      open: false,
      loading: true
    }
  }

  setUserData = async () => {
    const userData = await getUserData(this.props.history);

    this.setState({ userData });
  };

  setQueryString = async () => {
    const queryStrings = await queryString.parse(this.props.location.search);

    this.setState({ queryStrings });
  };

  getCorpData = async () => {
    const { userData, queryStrings } = this.state;

    let endpoint = `${userData?.hasSupport?'':'/internal'}/operator/corporation/${queryStrings.id}`;

    try {
      const response = await mainApi.get(endpoint)

      let corporation = response.data.corporation;
      let  corporationUser = response.data.corporation.user;

      this.setState({
        corporation,
        corporationUser,
        loading: false
      });
    } catch (e) {
      this.setState({
        loading: false
      })
      console.log(e)
    }
  };

  setProducts = async () => {
    const { userData, queryStrings } = this.state;

    let endpoint = `${userData?.hasSupport?'':'/internal'}/operator/corporation/${queryStrings.id}/enable/products`;

    try {
      const response = await mainApi.get(endpoint);
      const allProducts = response.data.data.products;

      const remainedProducts = allProducts.filter((product) => product.enabled)
      this.setState({
        products: remainedProducts
      })


    } catch (err) {
      if (err.response) console.log(err.response.data);
    }
  };

  listUsers = () => {
    const { corporation } = this.state
    this.props.history.push(`/admin/operator/listUsers?corporation=${corporation._id}` )
  }


  isCorban() {
    if (this.state.corporation.hasManager !== undefined && this.state.corporation.hasManager !== null) {
      return this.state.corporation.hasManager ? " Corban" : " Corporação"
    }
    return "n/a"
  }

  handleClose = () => {
    this.setState({
      open: false
    })
  }

  handleOpen(value) {
    let filteredArray
    const { corporation } = this.state
    switch (value) {
      case 'percentage':
        filteredArray = corporation.updates ? corporation.updates.filter(v => v.type == "commission") : []
        break;
      case 'valuePerAccount':
        filteredArray = corporation.updates ? corporation.updates.filter(v => v.type == "valuePerAccount") : []
        break;
    }

    this.setState({
      historyTableRows: filteredArray,
      open: true
    })
  }

  componentDidMount = async () => {
    await this.setQueryString()
    await this.setUserData()
    await this.setProducts()
    this.getCorpData()

  }

  render() {
    const { corporation, corporationUser, loading,userData } = this.state

    return (
      <Wrapper className="content">
        <Modal open={this.state.open} rows={this.state.historyTableRows} handleClose={this.handleClose}></Modal>
        <LoadingAlert
          show={loading}
        />
        < BackLink />
        <Details>
          <Card style={{marginBottom:15}} contentStyle={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", height: "100%"}} noTitle noFooter>
            <Image logo={corporation.logo} />
            <TitleText>
              <Storefront />
              {this.isCorban()}
            </TitleText>
          </Card>
          <Card style={{marginBottom:15}} title={corporation.name || "n/a"} noFooter>
            <CorpInfo>
              <b>Nome Fantasia:</b> {corporation.socialName || "n/a"}<br />
              <b>Descrição:</b> {corporation.description || "n/a"}<br />
              <b>CNPJ:</b> {corporation.cnpj || "n/a"}<br />
              <b>Telefone:</b> {corporation.phone || "n/a"}<br />
              <b>E-mail:</b> {corporationUser.email || "n/a"}<br />
              <b>Bonificação:</b> {corporation.percentage ? (<BigTooltip title="Ver histórico de edições." placement="top"><InfoOutlined onClick={() => { this.handleOpen("percentage") }} style={{ cursor: "pointer" }} /></BigTooltip>) : ""} {corporation.percentage ? `${corporation.percentage} %` : "n/a"}<br />
              <b>Bonificação Conta Digital:</b> {corporation.valuePerAccount ? (<BigTooltip title="Ver histórico de edições." placement="top"><InfoOutlined onClick={() => { this.handleOpen("valuePerAccount") }} style={{ cursor: "pointer" }} /></BigTooltip>) : ""} {corporation.valuePerAccount ? `R$ ${corporation.valuePerAccount}` : "n/a"}<br />
              <b>Bloqueado:</b> {bolleanToYesNo(corporationUser.blocked) || "n/a"} <br />
              <b>Criado Em:</b> {formatToBr(corporationUser.createdAt) || "n/a"} <br />
              <b>Atualizado Em:</b> {formatToBr(corporationUser.updatedAt) || "n/a"} <br />
              <b>Ultimo Login:</b> {formatToBr(corporationUser.lastLogin) || "n/a"} <br />

              {!userData?.hasSupport && <button className="btn btn-fill btn-mlm button-users-from-corp" onClick={this.listUsers}><Search style={{fontSize: "30px"}}/>Listar usuários de {corporation.socialName}</button>}
            </CorpInfo>
          </Card>
          <Card style={{marginBottom:15}} title="Produtos">
            {
              this.state.products.map((product,index) => (
                <div key={index}><b>{product.name}</b> <br /></div>
              ))
            }
          </Card>
        </Details>
      </Wrapper>
    )
  }
}

export default OperatorCorpDetails