import React, { Component } from "react";
import Card from 'components/Card/Card'
import { Row, Col } from 'react-bootstrap'
import getUserData from "utils/checkUser.js";
import { LoadingAlert } from 'components/Alert/CustomAlert'
import MahoeIcon from "components/MahoeIcons";
import config from "utils/serverVars";
import "../assets/css/styles.css";
import { introSystem, resetTour } from 'utils/introToSystem';
import dataTour from 'utils/dataTour'

import "../assets/css/styles.css";

class Help extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      userData: null,
      emailHelp: null
    }
  }      

  setEmail(email) {
    this.setState({
      emailHelp: email,
    });
  }
  
  mountUserData = async () => {
    const userData = await getUserData(this.props.history);

    this.setState({ userData })
  }  

  restartTour = async () => {
    const { userData } = this.state

    const history = this.props.history

    await resetTour();

    if(['corporation', 'subcorp', 'master', 'submaster', 'administrator'].includes(userData.level)) history.push("/admin/dashboard")
    else history.push("/admin/Home")
  }

  componentDidMount = async () => {
    await this.mountUserData()
    
    const { routeKey } = this.props
    const { userData } = this.state          

    window.digitalData = {
      "site": {
          "nome": "Banco Pan"
      },
      "page": {
          "secao": "IndiqueoPan",
          "estadoLogin": "LG",
          "nome": "BP:IndiqueOPan:AjudaContato",
      },
      "user": {
          "crmid": userData.id,
          "tipo": userData.brType
      }
    }    
    
    var evt = new CustomEvent('event-view-start');
    document.body.dispatchEvent(evt);

    if (['corporation', 'subcorp', 'master', 'administrator', 'submaster'].includes(userData.level)) {
      this.setState({ emailHelp: config.CONTACT_PJ })
    } else {
      this.setState({ emailHelp: config.CONTACT_PF })
    }

    introSystem(routeKey)
  }

  render() {
    const { loading, emailHelp } = this.state
    
    return (
      <div className="content">
        <div className="container-fluid">

          <LoadingAlert show={loading} />          
          <Row>            
            {/* 
            <Col lg={6} md={6} sm={12}>
              <div data-intro={dataTour.help.resetHelp}>
                <Card        
                  style={{ minHeight: 220, padding: 10 }}        
                  title="Precisa de ajuda?"
                  category="Tutorial para rever a base do sistema."
                  content={
                    <>
                      <p className="text-center padding-helper">Caso deseje rever o tutorial do sistema clique no botão abaixo.</p>
                      <button
                        onClick={() => this.restartTour()}
                        className="btn btn-mlm btn-round btn-fill btn-block ">
                        Rever tutorial
                      </button>
                    </>
                  }
                />
              </div>
            </Col> 
            */}

            <Col lg={6} md={12} sm={12}>
              <div
                className="card"
                data-intro={dataTour.help.email}
                style={{
                  padding: 10,
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  minHeight: 220
                }}
              >
                <MahoeIcon
                  name="mail"
                  size="78px"
                  fill="rgba(65, 65, 65)"
                  className="icon-email-help"
                />

                <div className="modal-help-body">
                  <span className="icon-text-help"></span>
                </div>

                <p className="text-email-contact">{emailHelp}</p>

                <div className="modal-help-body">
                  <span className="icon-text-help">
                    Atendimento exclusivo via e-mail.
                    <br />
                    Resposta em até 72 horas.
                  </span>
                </div>
              </div>
            </Col>

          </Row>
        </div>
      </div>
    )
  }
}

export default Help;
