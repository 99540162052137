import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import MahoeIcon from "components/MahoeIcons";
import { StatsCard } from "components/StatsCard/StatsCard.jsx";
import { Card } from "components/Card/Card.jsx";
import { Doughnut, Line, Chart, Bar, HorizontalBar } from "react-chartjs-2";
import Funnel from "components/Funnel";
import getUserData from "utils/checkUser";
import { numToBRL, getMonthAndYear } from "utils/formatData";
import { formatToBrWithHour } from "utils/formaterDate";
import formater from "utils/formater";
import mainApi from "services/mainApi";
import LoadingCover from "components/LoadingCover";
import { introSystem } from "utils/introToSystem";
import dataTour from "utils/dataTour";
import Select from "react-select";
import { contaId } from "utils/CommonData";
import { translateMonths } from "utils/translate";
import moment from "moment";
import { Popper, Fade, Paper } from "@material-ui/core";
import labelValue from "utils/labelWithValue";
import ClicksChart from "./ClicksChart";

import {
  graphOptions,
  getRealProductsNames,
  getColors,
  weeklyInitialState,
} from "./common";

import "chartjs-plugin-datalabels";

// date-picker
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptbr from "date-fns/locale/pt-BR";
import { getMonthById } from "utils/formatData";
import TopFive from "./TopFive";
registerLocale("pt-BR", ptbr);

const colorByEvent = {
  "BP:APP:Onboarding:HoraDasFotos": "rgba(255, 99, 132, 0.8)",
  "BP:APP:Onboarding:VamosTirarUmaSelfie": "rgba(54, 162, 235, 0.8)",
  "BP:APP:Onboarding:DadosIniciais": "rgba(255, 206, 86, 0.8)",
  "BP:APP:Onboarding:MaisSobreVoce": "rgba(75, 192, 192, 0.8)",
  "PRD:BP:APP:Onboarding:RetornoContaAbertaSucesso": "rgba(153, 102, 255, 0.8)",
  "BP:APP:Onboarding:OndeVoceMora": "rgba(255, 159, 64, 0.8)",
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      links: 0,
      average: 0,
      sold: 0,
      commission: 0,
      daylySum: 0,
      pendingCommissionConta: 0,
      paidCommissionConta: 0,
      agencyCommissionConta: 0,
      totalCorpsWithConta: 0,
      clicked: 0,
      loading: true,
      graphData: {},
      graphLegend: {},
      products: {},
      rankCorporation: null,
      CorpOrAgencieRanq: "",

      SelectProducts: null,
      filterProduct: null,
      filterProductLabel: null,

      weekly: {},
      optionsWeekly: {},
      affiliatesNumbers: {},
      appsFlyerData: {},
      appsFlyerGraphData: {},
      situation: {},
      updatedAt: Date.now(),

      showContaDigitalCards: false,

      selectDate: new Date(),
      openPopper: false,
      anchorEl: null,
      isNotSeller: false,
      isSupAndSeller: false,
      isCorp: false,
      graphics: {
        weekly: null,
        products: null,
      },
    };
  }

  getStats = async () => {
    const { filterProduct, showContaDigitalCards, selectDate } = this.state;

    this.getAffiliateCounts();

    let query = selectDate
      ? `?date=${moment(selectDate).format("YYYY-MM-DD")}&`
      : "?";

    if (filterProduct) query += `productId=${filterProduct}`;

    if (!filterProduct && showContaDigitalCards)
      this.setState({ showContaDigitalCards: false });

    try {
      const response = await mainApi.get(`/dashboard${query}`);

      this.setState({ ...response.data.calculations });

      this.getGraphData(response.data.calculations.graphics.products);
    } catch (err) {
      if (err.response) console.log(err.response.data);
    }

    if (filterProduct && filterProduct === contaId) {
      this.setState({ showContaDigitalCards: true });

      try {
        const appsflyerDataResponse = await mainApi.get(
          `/appsflyer/data${query}`
        );
        const appsFlyerData = appsflyerDataResponse.data.data.events;
        const installs = appsflyerDataResponse.data.data.installs || 0;
        const startedSignup =
          appsflyerDataResponse.data.data.startedSignup || 0;
        const signup = appsflyerDataResponse.data.data.signup || 0;

        this.setAppsFlyerGraphData(appsFlyerData);
        this.setState({ appsFlyerData: { installs, startedSignup, signup } });
      } catch (err) {
        console.log(err);
      }
    } else this.setState({ showContaDigitalCards: false });
  };

  getClicksData = async () => {
    const { filterProduct, selectDate } = this.state;

    let query = selectDate
      ? `?date=${moment(selectDate).format("YYYY-MM-DD")}&`
      : "?";

    if (!filterProduct) return

    query += `productId=${filterProduct}`;

    try {
      const response = await mainApi.get(`/clicks/dashboard${query}`);

      this.setState({
        clicked: response.data.data.clicked,
        weekly: response.data.data.weekly,
      });

      this.getWeekData(response.data.data.weekly);
    } catch (err) {
      if (err.response) console.log(err.response.data);
    }
  };

  getAffiliateCounts = async () => {
    const { userData, selectDate } = this.state;

    const query = selectDate
      ? `?date=${moment(selectDate).format("YYYY-MM-DD")}`
      : "";

    if (userData.level !== "master" && userData.level !== "submaster") return;
    try {
      const response = await mainApi.get("/affiliates/count" + query);
      const affiliates = response.data.totals;

      this.setState({ affiliatesNumbers: affiliates });
    } catch (err) {
      if (err.response) console.log(err.response.data);
    }
  };

  getProducts = async () => {
    const { userData, filterProduct, filterProductLabel } = this.state;

    let endpoint = "";

    if (["master", "submaster"].includes(userData.level))
      endpoint = "/master/products";
    else if (["corporation", "subcorp"].includes(userData.level))
      endpoint = `/corporation/${userData.id}/products`;
    else endpoint = "/products";

    try {
      const response = await mainApi.get(endpoint);
      const products =
        endpoint == "/master/products" || endpoint == "/products"
          ? response.data.products
          : response.data.data.products;

      const productsToSelect = [];
      // console.log("Products",products)
      products.forEach((product) => {
        productsToSelect.push({
          value: product._id || product.id,
          label: product.name,
        });
      });

      this.setState({ SelectProducts: productsToSelect });

      if (!filterProduct && !filterProductLabel) {
        this.setState({
          filterProduct: productsToSelect[0].value,
          filterProductLabel: productsToSelect[0].label,
        });
      }
    } catch (err) {
      if (err.response) console.log(err.response.data);
    }
  };

  getRankCorporation = async () => {
    this.setState({ rankCorporation: null });
    const { filterProduct, userData, selectDate } = this.state;

    if (
      !["corporation", "master", "subcorp", "submaster"].includes(
        userData.level
      )
    )
      return;

    if (["corporation", "subcorp"].includes(userData.level))
      this.setState({ CorpOrAgencieRanq: "lojas" });
    if (["master", "submaster"].includes(userData.level))
      this.setState({ CorpOrAgencieRanq: "corporações" });

    const query = selectDate
      ? `?date=${moment(selectDate).format("YYYY-MM-DD")}`
      : "";

    try {
      const response = await mainApi.get(
        `/corporation/product/rank/${filterProduct}${query}`
      );
      const corporations = response.data.corporations;

      const labels = [];
      const clicks = [];

      if (corporations.length > 0) {
        corporations.forEach((item) => {
          labels.push(item._id);
          clicks.push(item.clicks);
        });
      }

      const chartData = {
        labels: [...labels],
        datasets: [
          {
            label: "Quantidade de clicks",
            data: [...clicks],
            backgroundColor: [
              "#039be582",
              "#039be582",
              "#039be582",
              "#039be582",
              "#039be582",
            ],
            borderColor: [
              "#039be582",
              "#039be582",
              "#039be582",
              "#039be582",
              "#039be582",
            ],
            borderWidth: 1,
          },
        ],
      };

      this.setState({ rankCorporation: chartData });
    } catch (err) {
      if (err.response) console.log(err.response.data);
    }
  };

  getGraphData = (data) => {
    var newData = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
          borderWidth: 0,
          weight: 10,
          pointStyle: "rectRounded",
        },
      ],
    };

    Object.keys(data).forEach((key) => {
      newData.labels.push(...getRealProductsNames([key]));
      newData.datasets[0].data.push(data[key].toFixed(2));
      newData.datasets[0].backgroundColor.push(...getColors([key]));
    });

    this.setState({ graphData: newData });
  };

  setAppsFlyerGraphData = (data) => {
    var appsFlyerData = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
          borderWidth: 0,
          weight: 10,
          pointStyle: "rectRounded",
        },
      ],
    };

    data.forEach((key) => {
      appsFlyerData.labels.push(key._id.split(":Onboarding")[1].substr(1));
      appsFlyerData.datasets[0].backgroundColor.push(colorByEvent[key._id]);
      if (key._id == "PRD:BP:APP:Onboarding:RetornoContaAbertaSucesso") {
        appsFlyerData.datasets[0].data.push(this.state.situation.quantity.paid);
      } else {
        appsFlyerData.datasets[0].data.push(key.count);
      }
    });
    this.setState({ appsFlyerGraphData: appsFlyerData });
  };

  getWeekData = (data) => {
    try {
      let weekly = weeklyInitialState;
      for (let i = 0; i <= 6; i++) {
        weekly.datasets[0].data[i] = 0;
      }
      const keys = Object.keys(data);
      for (let item of keys) {
        const day = formater.getWeekDay(item);
        if (day) weekly.datasets[0].data[day - 1] = data[item];
      }
      this.setState({ weekly });

    } catch (err) {
      console.error("COULD NOT UPDATE: ", err)
    }
  };

  reload = async () => {
    await this.getDashboard();

    this.setState({ updatedAt: Date.now() });
  };

  getDashData = async () => {
    const { routeKey } = this.props;
    const userData = await getUserData(this.props.history);
    window.digitalData = {
      site: {
        nome: "Banco Pan",
      },
      page: {
        secao: "IndiqueoPan",
        estadoLogin: "LG",
        nome: "BP:IndiqueOPan:VisaoGeral",
      },
      user: {
        crmid: userData.id,
        tipo: userData.brType,
      },
    };

    var evt = new CustomEvent("event-view-start");
    document.body.dispatchEvent(evt);

    if (userData) {
      console.log(userData.level);
      const aux = ["corporation", "master", "subcorp", "submaster"].includes(
        userData.level
      );
      const auxSupAndSeller = [
        "supervisor",
        "seller",
        "administrator",
      ].includes(userData.level);
      const auxCorp = ["corporation", "subcorp"].includes(userData.level);
      this.setState(
        {
          isNotSeller: aux,
          isSupAndSeller: auxSupAndSeller,
          isCorp: auxCorp,
          userData,
        },
        async () => {
          await this.getDashboard();

          introSystem(routeKey);
        }
      );
      Chart.defaults.global.defaultFontColor = "#616161";
      Chart.defaults.global.defaultFontFamily =
        "'Montserrat','Roboto','Helvetica Neue',Arial,sans-serif";
      Chart.defaults.scatter.scales.xAxes[0].color = "blue";
    }
  };

  getDashboard = async () => {
    this.setState({ loading: true });
    await this.getProducts();

    await Promise.all([
      this.getStats(),
      this.getClicksData(),
      this.getRankCorporation(),
    ]);
    this.setState({ loading: false });
  };

  componentDidMount = () => {
    this.getDashData();
  };

  changeSelectProduct = async (event) => {
    this.setState(
      { filterProduct: event.value, filterProductLabel: event.label },
      async () => {
        // if (event.value) await this.getRankCorporation()
        if (event.value) await this.getDashboard();
      }
    );
  };

  setSelectedDate = (date) =>
    this.setState({ selectDate: date }, () => this.getDashboard());

  handleMouse = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
      openPopper: true,
    });
  };

  getColMD = () => {
    const { showContaDigitalCards, isSupAndSeller } = this.state;
    if (showContaDigitalCards) {
      if (isSupAndSeller) return 8;
      else return 5;
    } else {
      if (isSupAndSeller) return 12;
      else return 8;
    }
  };

  render() {
    const {
      links,
      average,
      sold,
      commission,
      pendingCommissionConta,
      paidCommissionConta,
      agencyCommissionConta,
      totalCorpsWithConta,
      clicked,
      weekly,
      daylySum,
      graphData,
      loading,
      affiliatesNumbers,
      situation,
      userData,
      showContaDigitalCards,
      appsFlyerGraphData,
      appsFlyerData,
      filterProduct,
      rankCorporation,
      CorpOrAgencieRanq,
      selectDate,
      openPopper,
      anchorEl,
      isNotSeller,
      isSupAndSeller,
      isCorp,
      updatedAt,
    } = this.state;

    const time = formatToBrWithHour(moment(updatedAt).toDate());
    const isAGX =userData? userData.id === '5f69347d48c1d60004a8916f': false

    
    return (
      <>
        {loading && <LoadingCover dashboard />}

        <div className="content" style={{ marginTop: "-35px" }}>
          <Grid fluid style={{ paddingRight: 15 }}>
            <Row className="refresh-page-and-search-dash">
              <Col className="form-group" md={4} lg={4}>
                <Select
                  className="custom-select"
                  placeholder="Selecione um Produto"
                  value={{
                    value: this.state.filterProduct,
                    label: this.state.filterProductLabel,
                  }}
                  options={this.state.SelectProducts}
                  onChange={(event) => this.changeSelectProduct(event)}
                />
              </Col>

              <Col className="form-group" md={4} lg={3}>
                <DatePicker
                  className="datepicker-theThird"
                  dateFormat="MM/yyyy"
                  locale="pt-BR"
                  // isClearable
                  customInput={
                    <div className="div-datepicker">
                      <div className="col-6">
                        <span className="placeholder-datepicker">
                          {selectDate ? "Mês selecionado:" : "Selecione um mês"}
                        </span>
                      </div>
                      <div className="col-3">
                        <p className="date-datepicker">
                          {selectDate ? getMonthAndYear(selectDate) : ""}
                        </p>
                      </div>
                      <div
                        className="col-3"
                        style={{ alignSelf: "center" }}
                        onMouseEnter={(event) => this.handleMouse(event)}
                        onMouseLeave={() =>
                          this.setState({ openPopper: false })
                        }
                      >
                        {selectDate ? (
                          <></>
                        ) : (
                            <MahoeIcon
                              aria-describedby={"iconTooltip"}
                              name="info"
                              size="23px"
                              fill="#039BE5"
                            />
                        )}
                      </div>
                    </div>
                  }
                  selected={selectDate}
                  showMonthYearPicker
                  onChange={(date, event) => this.setSelectedDate(date, event)}
                  maxDate={new Date()}
                  minDate={new Date("2020-01-01")}
                />
              </Col>

              <Popper
                id={"iconTooltip"}
                open={openPopper}
                anchorEl={anchorEl}
                transition
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper
                      style={{
                        backgroundColor: "#414141",
                        padding: "0px 10px",
                      }}
                    >
                      <p className="tooltipText-datepicker">
                        Selecione o mês que deseja ver os dados.
                      </p>
                    </Paper>
                  </Fade>
                )}
              </Popper>

              <Col>
                <div
                  style={{
                    margin: "20px 0",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <span className="txtUpdateDashboard">
                    Última atualização:{" "}
                    {this.state.loading ? "Buscando..." : time}
                  </span>

                  <span
                    onClick={this.reload}
                    style={{
                      cursor: "pointer",
                      marginLeft: "10px",
                      marginRight: "10px",
                      zIndex: 999,
                    }}
                  >
                    <MahoeIcon name="reload" size="22px" fill="#039BE5" />
                  </span>
                </div>
              </Col>
            </Row>
            {selectDate ? (
              <Row>
              <div style={{ paddingLeft: '15px' }}>
                <p>Dados referentes ao mês de <strong>{translateMonths(moment(selectDate).format('MMMM'))}</strong></p>
              </div>
            </Row>
            ) : null}
            <Row>
              {showContaDigitalCards ? (
                <>
                  <Col lg={4} sm={6}>
                    <div>
                      <StatsCard
                        bigIcon={
                          <MahoeIcon
                            name="growing-up"
                            size="25px"
                            fill="rgb(128, 210, 99)"
                          />
                        }
                        statsText={loading ? "n/a" : appsFlyerData.installs}
                        statsValue="Iniciou instalação"
                        statsIcon={<i className="fa fa-calendar-o" />}
                        statsIconText="Iniciou instalação do app"
                      />
                    </div>
                  </Col>

                  <Col lg={4} sm={6}>
                    <div>
                      <StatsCard
                        bigIcon={
                          <MahoeIcon
                            name="growing-up"
                            size="25px"
                            fill="rgb(128, 210, 99)"
                          />
                        }
                        statsText={
                          loading ? "n/a" : appsFlyerData.startedSignup
                        }
                        statsValue="Falta completar dados do app"
                        statsIcon={<i className="fa fa-info-circle" />}
                        statsIconText="Faltou completar dados no app"
                      />
                    </div>
                  </Col>

                  <Col lg={4} sm={6}>
                    <div>
                      <StatsCard
                        bigIcon={
                          <MahoeIcon
                            name="growing-up"
                            size="25px"
                            fill="rgb(128, 210, 99)"
                          />
                        }
                        statsText={loading ? "n/a" : situation.quantity.paid}
                        statsValue="Contas abertas"
                        statsIcon={<i className="fa fa-clock-o" />}
                        statsIconText="Contas abertas"
                      />
                    </div>
                  </Col>
                </>
              ) : (
                  userData &&
                  ["master", "submaster"].includes(userData.level) &&
                  Object.keys(affiliatesNumbers).length > 0 && (
                    <>
                      <Col lg={4} sm={6}>
                        <div>
                          <StatsCard
                            bigIcon={
                              <MahoeIcon
                                name="person"
                                size="25px"
                                fill="rgb(136, 136, 136)"
                              />
                            }
                            statsText={
                              loading
                                ? "n/a"
                                : affiliatesNumbers?.approved
                                  ? affiliatesNumbers?.approved
                                  : 0
                            }
                            statsValue="Cadastro de afiliados aprovados"
                            statsIcon={<i className="fa fa-calendar-o" />}
                            statsIconText="Atual"
                          />
                        </div>
                      </Col>

                      <Col lg={4} sm={6}>
                        <div>
                          <StatsCard
                            bigIcon={
                              <MahoeIcon
                                name="question"
                                size="25px"
                                fill="rgb(255,218,140)"
                              />
                            }
                            statsText={
                              loading
                                ? "n/a"
                                : affiliatesNumbers?.pending
                                  ? affiliatesNumbers?.pending
                                  : 0
                            }
                            statsValue="Cadastro de afiliados pendentes"
                            statsIcon={<i className="fa fa-calendar-o" />}
                            statsIconText="Atual"
                          />
                        </div>
                      </Col>

                      <Col lg={4} sm={6}>
                        <div>
                          <StatsCard
                            bigIcon={
                              <MahoeIcon
                                name="rounded-close"
                                size="25px"
                                fill="rgb(170,57,57)"
                              />
                            }
                            statsText={
                              loading
                                ? "n/a"
                                : affiliatesNumbers?.refused
                                  ? affiliatesNumbers?.refused
                                  : 0
                            }
                            statsValue="Cadastro de afiliados recusados"
                            statsIcon={<i className="fa fa-calendar-o" />}
                            statsIconText="Atual"
                          />
                        </div>
                      </Col>
                    </>
                  )
                )}
              {!showContaDigitalCards && (
                <>
                  <Col lg={4} sm={6}>
                    <div>
                      <StatsCard
                        bigIcon={
                          <MahoeIcon
                            name="coins"
                            size="25px"
                            fill="rgb(128, 210, 99)"
                          />
                        }
                        statsText={
                          loading
                            ? "n/a"
                            : sold === 0
                              ? "R$ 0,00"
                              : numToBRL(sold)
                        }
                        statsValue="Total Vendido"
                        statsIcon={<i className="fa fa-calendar-o" />}
                        statsIconText="Atual"
                      />
                    </div>
                  </Col>

                  <Col lg={4} sm={6}>
                    <div data-intro={dataTour.dashboard.bonification}>
                      <StatsCard
                        bigIcon={
                          <MahoeIcon
                            name="wallet"
                            size="25px"
                            fill="rgb(128, 210, 99)"
                          />
                        }
                        statsText={
                          loading
                            ? "n/a"
                            : commission === 0
                              ? "R$ 0,00"
                              : numToBRL(commission)
                        }
                        statsValue={"Bonificação total"}
                        statsIcon={<i className="fa fa-info-circle" />}
                        statsIconText=" Links com ao menos uma venda"
                      />
                    </div>
                  </Col>

                  <Col lg={4} sm={6}>
                    <div data-intro={dataTour.dashboard.ticketMedio}>
                      <StatsCard
                        bigIcon={
                          <MahoeIcon
                            name="growing-up"
                            size="25px"
                            fill="rgb(128, 210, 99)"
                          />
                        }
                        statsText={
                          loading
                            ? "n/a"
                            : average === 0
                              ? "R$ 0,00"
                              : numToBRL(average)
                        }
                        statsValue="Ticket médio(Bonificação)"
                        statsIcon={<i className="fa fa-clock-o" />}
                        statsIconText="Atual"
                      />
                    </div>
                  </Col>
                  <Col lg={4} sm={6}>
                    <div>
                      <StatsCard
                        bigIcon={
                          <MahoeIcon
                            name="coins"
                            size="25px"
                            fill="rgb(128, 210, 99)"
                          />
                        }
                        statsText={
                          loading
                            ? "n/a"
                            : sold === 0
                              ? "R$ 0,00"
                              : numToBRL(sold)
                        }
                        statsValue={
                          loading
                            ? "n/a"
                            : `Propostas pagas : ${situation?.quantity?.paid
                              ? situation?.quantity?.paid
                              : 0
                            }`
                        }
                        statsIcon={<i className="fa fa-calendar-o" />}
                        statsIconText="Atual"
                      />
                    </div>
                  </Col>

                  <Col lg={4} sm={6}>
                    <div>
                      <StatsCard
                        bigIcon={
                          <MahoeIcon
                            name="coins"
                            size="25px"
                            fill="rgb(128, 210, 99)"
                          />
                        }
                        statsText={
                          loading
                            ? "n/a"
                            : situation?.money?.pending === 0
                              ? "R$ 0,00"
                              : numToBRL(situation?.money?.pending)
                        }
                        statsValue={
                          loading
                            ? "n/a"
                            : `Propostas pendentes : ${situation?.quantity?.pending
                              ? situation?.quantity?.pending
                              : 0
                            }`
                        }
                        statsIcon={<i className="fa fa-calendar-o" />}
                        statsIconText="Atual"
                      />
                    </div>
                  </Col>

                  <Col lg={4} sm={6}>
                    <div>
                      <StatsCard
                        bigIcon={
                          <MahoeIcon
                            name="coins"
                            size="25px"
                            fill="rgb(128, 210, 99)"
                          />
                        }
                        statsText={
                          loading
                            ? "n/a"
                            : situation?.money?.refused === 0
                              ? "R$ 0,00"
                              : numToBRL(situation?.money?.refused)
                        }
                        statsValue={
                          loading
                            ? "n/a"
                            : `Propostas recusadas : ${situation?.quantity?.refused
                              ? situation?.quantity?.refused
                              : 0
                            }`
                        }
                        statsIcon={<i className="fa fa-calendar-o" />}
                        statsIconText="Atual"
                      />
                    </div>
                  </Col>
                </>
              )}
              {!isSupAndSeller && showContaDigitalCards ? (
                <>
                  <Col lg={4} sm={6}>
                    <StatsCard
                      bigIcon={
                        <MahoeIcon
                          name="coins"
                          size="25px"
                          fill="rgb(128, 210, 99)"
                        />
                      }
                      statsText={
                        loading
                          ? "n/a"
                          : pendingCommissionConta === 0
                            ? "R$ 0,00"
                            : numToBRL(pendingCommissionConta)
                      }
                      statsValue="Bonificação Total Pendente"
                      statsIcon={<i className="fa fa-calendar-o" />}
                      statsIconText="Atual"
                    />
                  </Col>
                  <Col lg={4} sm={6}>
                    <StatsCard
                      bigIcon={
                        <MahoeIcon
                          name="coins"
                          size="25px"
                          fill="rgb(128, 210, 99)"
                        />
                      }
                      statsText={
                        loading
                          ? "n/a"
                          : paidCommissionConta === 0
                            ? "R$ 0,00"
                            : numToBRL(paidCommissionConta)
                      }
                      statsValue={"Bonificação Total (Conta Aberta)"}
                      statsIcon={<i className="fa fa-info-circle" />}
                      statsIconText=" Links com ao menos uma venda"
                    />
                  </Col>

                  <Col lg={4} sm={6}>
                    {isCorp ? (
                      <StatsCard
                        bigIcon={
                          <MahoeIcon
                            name="coins"
                            size="25px"
                            fill="rgb(128, 210, 99)"
                          />
                        }
                        statsText={
                          loading
                            ? "n/a"
                            : agencyCommissionConta === 0
                              ? "R$ 0,00"
                              : numToBRL(agencyCommissionConta)
                        }
                        statsValue={"Bonificação Total Lojas"}
                        statsIcon={<i className="fa fa-info-circle" />}
                      />
                    ) : (
                        <StatsCard
                          bigIcon={
                            <MahoeIcon
                              name="store"
                              size="25px"
                              fill="rgb(128, 210, 99)"
                            />
                          }
                          statsText={loading ? "n/a" : totalCorpsWithConta}
                          statsValue={"Corporações que vendem conta digital"}
                          statsIcon={<i className="fa fa-info-circle" />}
                        />
                      )}
                  </Col>
                </>
              ) : null}

              {isSupAndSeller ? (
                showContaDigitalCards ? (
                  <>
                    <Col lg={4} sm={6}>
                      <StatsCard
                        bigIcon={
                          <MahoeIcon
                            name="coins"
                            size="25px"
                            fill="rgb(128, 210, 99)"
                          />
                        }
                        statsText={
                          loading
                            ? "n/a"
                            : pendingCommissionConta === 0
                              ? "R$ 0,00"
                              : numToBRL(pendingCommissionConta)
                        }
                        statsValue="Bonificação Total Pendente"
                        statsIcon={<i className="fa fa-calendar-o" />}
                        statsIconText="Atual"
                      />
                    </Col>
                    <Col lg={4} sm={6}>
                      <StatsCard
                        bigIcon={
                          <MahoeIcon
                            name="coins"
                            size="25px"
                            fill="rgb(128, 210, 99)"
                          />
                        }
                        statsText={
                          loading
                            ? "n/a"
                            : paidCommissionConta === 0
                              ? "R$ 0,00"
                              : numToBRL(paidCommissionConta)
                        }
                        statsValue={"Bonificação Total (Conta Aberta)"}
                        statsIcon={<i className="fa fa-info-circle" />}
                        statsIconText=" Links com ao menos uma venda"
                      />
                    </Col>
                  </>
                ) : (
                    <>
                      <Col lg={4} sm={6}>
                        <StatsCard
                          bigIcon={
                            <MahoeIcon
                              name="resize"
                              size="25px"
                              fill="rgb(136, 136, 136)"
                            />
                          }
                          statsText={loading ? "n/a" : links}
                          statsValue="Links gerados"
                          statsIcon={<i className="fa fa-refresh" />}
                          statsIconText="Atual"
                        />
                      </Col>
                    </>
                  )
              ) : (
                  <>
                    <Col lg={4} sm={6}>
                      <StatsCard
                        bigIcon={
                          <MahoeIcon
                            name="resize"
                            size="25px"
                            fill="rgb(136, 136, 136)"
                          />
                        }
                        statsText={loading ? "n/a" : links}
                        statsValue="Links gerados"
                        statsIcon={<i className="fa fa-refresh" />}
                        statsIconText="Atual"
                      />
                    </Col>
                  </>
                )}

              <Col lg={4} sm={6}>
                <StatsCard
                  bigIcon={
                    <MahoeIcon
                      name="touch"
                      size="25px"
                      fill="rgb(136, 136, 136)"
                    />
                  }
                  statsText={loading ? "n/a" : clicked}
                  statsValue="Número de cliques"
                  statsIcon={<i className="fa fa-clock-o" />}
                  statsIconText="Atual"
                />
              </Col>
              {isAGX && <Col lg={4} sm={6}>
                <StatsCard
                  bigIcon={
                    <MahoeIcon
                      name="wallet"
                      size="25px"
                      fill="rgb(47, 131, 209)"
                    />
                  }
                  statsText={loading ? "n/a" : daylySum}
                  statsValue={showContaDigitalCards ? "Contas abertas hoje" : "Propostas aprovadas hoje"}
                  statsIcon={<i className="fa fa-clock-o" />}
                  statsIconText="Atual"
                />
              </Col>}
            </Row>

            <Row>
              <Col md={this.getColMD()}>
                <div data-intro={dataTour.dashboard.chartClicks}>
                  <ClicksChart data={weekly} bigger={showContaDigitalCards} />
                </div>
              </Col>
              {filterProduct && isNotSeller ? (
                <>
                  <Col md={showContaDigitalCards ? 3 : 4}>
                    <TopFive
                      data={rankCorporation}
                      name={CorpOrAgencieRanq}
                      category={this.state.filterProductLabel.toLowerCase()}
                    />
                  </Col>
                </>
              ) : (
                  <></>
                )}
              {showContaDigitalCards && (
                <Col md={4}>
                  <Card
                    title="Eventos"
                    category="Quantidade de eventos registrados"
                    content={
                      <div style={{ pointerEvents: "none" }}>
                        {appsFlyerGraphData.labels &&
                          appsFlyerGraphData.labels.length ? (
                            <Funnel
                              data={appsFlyerGraphData}
                              height={300}
                              options={{
                                ...graphOptions,
                                ...labelValue,
                                sort: "desc",
                                responsive: true,
                                maintainAspectRatio: false,
                                plugins: { datalabels: false },
                              }}
                            />
                          ) : (
                            <div className="search-top-5-loading">
                              <h4>
                                {loading
                                  ? "Buscando..."
                                  : "Nenhum evento encontrado!"}
                              </h4>
                            </div>
                          )}
                      </div>
                    }
                  />
                </Col>
              )}
            </Row>
          </Grid>
        </div>
      </>
    );
  }
}

export default Dashboard;
