import styled from 'styled-components';
import logoPlaceholder from 'assets/img/logo-placeholder.png'

export const Wrapper = styled.div`
`

export const Details = styled.div`
  display:grid;
  grid-template-columns:29% 40% 29%;
  column-gap: 1%;
  padding:20px;

@media(max-width: 1750px){
  display:flex;
  flex-direction:column;
}
`

export const Image = styled.div`
  background-image: url(${props => props.logo || logoPlaceholder});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width:400px;
  height:300px;
`

export const TitleText = styled.h1`
  margin:0%;
  font-size: 24px;
  margin-top: 5px;
  width: 100%;
  text-align: center;
  border: solid black 1px;
  border-radius: 5px;
  border-color: #DDD;
  color: black;
  padding-top: 10px;
  padding-bottom: 10px;
`

export const CorpInfo = styled.div`
  font-size:18px;
  line-height: 30px;

  .button-users-from-corp{
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 100%;
    overflow: hidden;

  }
`

export const HistoryContainer = styled.div`
  width:400px;
  height:400px;
  background-color:white;
`

export const Table = styled.div`
  width:400px;
  height:400px;
  background-color:black;
`