import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Chart from 'chart.js';
// eslint-disable-next-line
import 'chartjs-plugin-funnel'
import { DriveEtaRounded } from '@material-ui/icons';
import { appsFlyerStatus, revertEventStatus } from '../../utils/translate'

const Funnel = ({ data, height, options, isMaster, isOmniChannel, date, filter }) => {
  const chartRef = React.createRef()
  const [context, setContext] = useState()

  useEffect(() => {
    if (chartRef.current) {
      const opts = { ...options, sort: 'desc', bottomWidth: 250, plugins: { datalabels: false } }
      setContext(chartRef?.current?.getContext("2d"))
      if (context) {
        new Chart(context, { type: 'funnel', data, options: opts })
      }
    }
  }, [data, options, height, context])

  const caption = data.labels.map(label => {
    const dataPosition = data.labels.indexOf(label)
    const labelData = data.data[dataPosition]
    const isRedirect = label === 'Acessos (Cliente)' ? false : isOmniChannel

    let query = `proposal-status?label=${revertEventStatus(label)}&date=${date}`

    if (filter.level && filter.value) query += `&${filter.level}=${filter.value}`


    return (
      <Link
        className={`graph-caption ${isRedirect ? 'redirect-caption' : 'no-pointer-events'}`}
        to={isRedirect ? `${isMaster ? 'corporations/' : ''}${query}`  : undefined}
        style={{ display: 'flex', alignItems: 'center', gap: '.5em' }}
      >
        <div style={{ height: '11px', width: '22px', background: data.datasets[0].backgroundColor[dataPosition] }}></div>
        <div
          className='flex-space-between'
        >
          <h5>
            {isRedirect ? label : appsFlyerStatus(label)}:
          </h5>
          <h6>{labelData}</h6>
        </div>

      </Link>
    )
  })

  //if(!data || !data.datasets || !data.datasets.labels) return <em>Nenhum dado para ser exibido</em>
  return (

    <div style={{ minHeight: height }}>
      <div style={{ height: height / 2, pointerEvents: 'none' }}>
        <canvas height={height / 2} ref={chartRef} id="ChartJs-Funnel-Chart" />
      </div>
      <div style={{ minHeight: height / 2 , display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', maxWidth: 325, margin: '0 auto'}}>
        {caption}
      </div>

    </div>
  )
}

export default Funnel