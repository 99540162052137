import React from 'react';
import { Link } from 'react-router-dom';
import { createMuiTheme } from "@material-ui/core/styles";
import BigTooltip from "components/BigTooltip"
import { IconButton } from "@material-ui/core"
import {  Email } from "@material-ui/icons"
import mainApi from 'services/mainApi'
import swal from 'components/Alert/alert'
import Toogle from 'react-toggle'
import { formatToBrWithHour, formatToBr } from 'utils/formaterDate'

const columns = (handleBlockUser) => (
[
  {
    name: "name",
    label: "Nome",
    options: {     
      sort: true,
      hint: "Nome do usuário"
    }
  },  
  {
    name: "email",
    label: "Email",
    options: {     
      sort: true,
      hint: "Email do sub usuário"
    }
  },
  {
    name: "emailSent",
    label: "Email enviado",
    options: {     
      sort: true,
      hint: "Email já enviado",
      customBodyRender: (value) => {        
        return value ? (
          "SIM"
        ) : (
          "NÃO"
        );
      }
    }    
  }, 
  {
    name: "_id",
    label: "Reenviar ",
    options: {     
      sort: false,
      customBodyRender: (value) => {        
        return (
          <BigTooltip title="Enviar email novamente." placement="top">
          <IconButton
            style={{ transform: 'scale(1.25)' }}
            onClick={(event) => inviteByEmail(value)}               
          >
            <Email />
          </IconButton>
        </BigTooltip>
        )          
      }      
    }
  },
  {
    name: "firstLogin",
    label: "Primeiro acesso",
    options: {
      sort: true,
      hint: "Usuário já realizou o primeiro acesso à plataforma",
      customBodyRender: (value, a) => {
        return value ? (
          formatToBr(value)
        ) : (
          "N/A"
        );
      }
    }
  },
  {
    name: "createdAt",
    label: "Convidado em",
    options: {
      sort: true,
      hint: "Quando o usuário foi convidado à plataforma",
      customBodyRender: (value) => {
        return value ? (
          formatToBr(value)
        ) : (
          "N/A"
        );
      }
    }
  },
  // {
  //   name: "products",
  //   label: "Produtos",
  //   options: {     
  //     sort: false,
  //     hint: "Email do afiliado",
  //     customBodyRender: (value) => {
  //       return (
  //         "Ver Mais"
  //       )
  //     }
  //   }
  // },
  {
    name: "support",
    label: "Suporte",
    options: {     
      sort: true,
      hint: "Usuário de atendimento",
      customBodyRender: (value) => {        
        return value ? (
          "SIM"
        ) : (
          "NÃO"
        );
      }
    }    
  }, 
  {
    name: "blocked",
    label: "Bloqueado",
    options: {     
      sort: false,
      customBodyRender: (value, rowIndex) => {  
        const ID = rowIndex.rowData[3]
        return (
          <Toogle
            checked={value}
            className="custom-toggle-class"
            onChange={() => {
              handleBlockUser(ID)                
            }}
          />
        )
      }      
    }
  },
  {
    name: "_id",
    label: " ",
    options: {     
      sort: false,
      customBodyRender: (value) => {        
        return (
          <Link to={`/admin/update/subuser/${value}`}>Editar</Link>
        )          
      }      
    }
  },
])

const getMuiTheme = () => {
  createMuiTheme({
    typography: {
      fontFamily: '"Montserrat"',
      fontSize: 14
    },
    overrides: {
      typography: {
        fontFamily: '"Montserrat"',
        fontSize: 14
      },
      MUIDataTable: {
        root: {
          fontSize: 44
        }
      },
      MuiTypography: {
        root: {
          fontFamily: '"Montserrat"',
          fontSize: 14
        }
      },
      MUIDataTableBodyCell: {
        root: {
          fontSize: 14,
          fontFamily: "Montserrat",
          lineHeight: "20px",
          fontWeight: "500",
          color: "#616161",
          textAlign: "left"
        }
      },
      MUIDataTableHeadCell: {
        root: {
          fontSize: 14,
          fontFamily: "Montserrat",
          lineHeight: "20px",
          fontWeight: "600",
          color: "#414141",
          textAlign: "left"
        }
      }
    }
  });
}

const inviteByEmail = async (id) => {    


  try {
    const response = await mainApi.post(`/sub/send/${id}/mail`)
    swal.basic('success', "Sucesso!", response.data.message)

  } catch(err) {
    // if(!err.response) return swal.basic('warning', "Atenção!", "Falha ao se comunicar com o servidor.")
    swal.basic('warning', "Atenção!", err.response.data.message)
  }    

}

export { columns, getMuiTheme }

