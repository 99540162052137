import React, { useEffect } from "react";
import queryString from 'query-string'

import TeamListManagement from 'components/TeamCorp/TeamListManagement'
import Sellerslist from 'components/TeamCorp/SellersList'
import dev from "utils/dev"
import getUserData from "utils/checkUser"

const Teams = ({ location, ...rest }) => {    

  useEffect(() => {                    

    async function setUserData() {
      const userData = await getUserData(location);             

      window.digitalData = {
        "site": {
          "nome": "Banco Pan"
        },
        "page": {
          "secao": "IndiqueoPan",
          "estadoLogin": "LG",
          "nome": "BP:IndiqueOPan:Equipe",
        },
        "user": {
          "crmid": userData.id,
          "tipo": userData.brType
        }
      }
            
      var evt = new CustomEvent('event-view-start');
      document.body.dispatchEvent(evt);
    }

    setUserData()
    // console.log({id: userData.id, type: userData.brType})        
  }, [])
    
  const search = queryString.parse(location.search);
  const agencyId = search.agencyId
  const agencyName = search.agencyName
  const userLevel = localStorage.getItem("@uxtech-mlm/UserLevel")
  const userAgency = localStorage.getItem('@uxtech-mlm/AgencyId')
  const userAgencyName = localStorage.getItem('@uxtech-mlm/AgencyName')

  if(!userLevel) return <></>

  if(userLevel === "administrator"){
    return <Sellerslist {...rest} admin agencyId={userAgency} agencyName={userAgencyName} />
  }
  
  if(agencyId && agencyId.length >= 8) return <Sellerslist {...rest} agencyId={agencyId} agencyName={agencyName}/>
  else return <TeamListManagement {...rest}/>

}

export default Teams