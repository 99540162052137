import { createMuiTheme } from "@material-ui/core/styles";
import config from 'utils/serverVars';

const getMuiTheme = ({team}) =>
  createMuiTheme({
    typography: {
      fontFamily: config.STYLE.font_family,
      fontSize: 14
    },
    palette: {
      primary: { 500: config.COLORS.primary_hover_color },
      secondary: {
        main: config.COLORS.secondary_color,
      },
    },
    overrides: {

      MuiFormControl: {
        root: { '&disabled': { background: "#eeeeee" } },
        disabled: {
        }
      },

      MuiSelect: {
        root: {
          fontWeight: '500',
          marginTop: '-2px',
          fontFamily: config.STYLE.font_family
        }
      },

      MuiFormLabel: {
        root: {
          fontWeight: '500',
          marginTop: '-2px',
          fontFamily: config.STYLE.font_family
        }
      },
      typography: {
        fontFamily: config.STYLE.font_family,
        fontSize: 14
      },
      MuiPaper: {
        rounded: {
          borderRadius: "16px"
        },
      },
      MUIDataTable: {
        root: {
          fontSize: 44
        },
        responsiveScroll: {
          maxHeight: "40px"
        },
        responsiveScrollMaxHeight: {
          borderRadius: "16px",
          maxHeight: 'none !important'
        }
      },
      MuiTypography: {
        root: {
          fontFamily: config.STYLE.font_family,
          fontSize: 14
        }
      },
      MUIDataTableToolbar: {
        root: {
          "@media (max-width: 600px)": {
            textAlign: "center"
          }
        },
        left: {
          width: team ? '100%' : 'auto',
        },
        actions: {
          display: "flex",
          flexWrap: "wrap",
          flexGrow: "1",
          justifyContent: "flex-end",
          "@media (max-width: 600px)": {
            justifyContent: "center"
          }
        }
      },
      MUIDataTableBodyCell: {
        root: {
          fontSize: 14,
          fontFamily: "Montserrat",
          lineHeight: "20px",
          fontWeight: "500",
          color: "#616161",
          textAlign: "left",
          paddingRight: 6,
        }
      },
      MUIDataTableHeadCell: {
        root: {
          fontSize: 14,
          fontFamily: "Montserrat",
          lineHeight: "20px",
          fontWeight: "600",
          color: "#414141",
          textAlign: "left",
          paddingRight: 6
        },
        hintIconAlone: { color: 'rgba(0,0,0, 0.4)', fontSize: '1rem' }
      },
      MUITableCell: {
        footer: {
          border: "none",
        }
      },
      MuiSvgIcon: {
        root: {
          fontSize: '1.1rem !important',
          width: '13px'
        }
      },
      MuiTablePagination: {
        toolbar: {
          overflow: 'auto'
        },
        root: {
          overflow: 'auto'
        }
      }
    }
  });

export default getMuiTheme;
