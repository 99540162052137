import React, { useEffect, useState, useCallback } from 'react'
import { Grid, Row, Col } from 'react-bootstrap';
import queryString from 'query-string'
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import AgenciesModal from './AgenciesModal'
import getMuiTheme from 'utils/getMuiTheme'

import mainApi from "services/mainApi";
import { LoadingAlert, Dialog, ConfirmationAlert } from 'components/Alert/CustomAlert';
import { tableFunctions } from './tableFunctions';
import MuiDataTableOption from 'utils/MuiDataTableOptions'
import getUserData from "utils/checkUser";
import BackLink from 'components/LinkVoltar'
import { objectToParams } from 'utils/formatData'
import { translateMonths } from 'utils/translate';
import moment from 'moment'
import DatePicker from "react-datepicker";
import { getMonthAndYearEN } from "utils/formatData";
import { translateLevelAction } from 'utils/translate';
import { useHistory } from 'react-router-dom';
import { ClubeAmigoId } from 'utils/CommonData';



const SupervisorList = ({ location }) => {
  const search = queryString.parse(location.search);
  let supervisorId = search.supervisorId

  const [pagination, setPagination] = useState({ totalDocs: 0, page: 0 })
  const [searchParams, setSearchParams] = useState({ page: 1, name: '', date: getMonthAndYearEN(new Date()) })
  const [seller, setSeller] = useState('')
  const [teamMembers, setTeamMembers] = useState([])
  const [supervisor, setSupervisor] = useState({})
  const [showLoading, setShowLoading] = useState(true)
  const [alert, setAlert] = useState({ show: false, title: '', type: '', text: '' })
  const [confirmation, setConfirmation] = useState({ show: false, title: '', text: '' })
  const [showSupervision, setShowSupervision] = useState(false)
  const [agencyId, setAgencyId] = useState('')
  const [showAgencies, setShowAgencies] = useState({ show: false })
  const [agencies, setAgencies] = useState([])
  const [userData, setUserData] = useState({})
  const [searchedDate, setSearchedDate] = useState(new Date())
  const history = useHistory()

  async function updateUserData() {
    const userData = await getUserData(history)
    setUserData(userData)
  }
  useEffect(() => {
    updateUserData()
  }, [])

  useEffect(() => {
    if (userData.level && ['corporation', 'subcorp'].includes(userData.level)) {
      getAgencies()
    }
  }, [userData.level])

  useEffect(() => {
    getTeamMembers(searchParams)
  }, [searchParams, searchedDate])

  const changePage = (page) => setSearchParams((prevState) => ({ ...prevState, page: page + 1 }))

  const handleAlert = (show, type, title, text, func) => {
    const onClose = func ? func : onCloseAlert
    setAlert({
      show,
      type,
      title,
      text,
      onClose
    })
  }

  const onCloseAgencies = () => setShowAgencies({ show: false })

  const onCloseConfirm = () => setConfirmation({ show: false, title: '', text: '' })

  const onCloseAlert = () => {
    setAlert({ show: false, title: '', type: '', text: '' })
    getTeamMembers(searchParams)
  }

  const onBackPage = () => {
    setAlert({ show: false, title: '', type: '', text: '' })
    window.history.back()
  }

  const showSupervisionModal = (sellerId) => {
    setSeller(sellerId)
    setShowSupervision((prevState) => !prevState)
  }

  const showRemoveConfirmation = (sellerId) => {
    setConfirmation({
      show: true,
      title: `Remover ${translateLevelAction('supervisor', userData?.corpId)}!`,
      text: `Clique sim para remover ${translateLevelAction('supervisor', userData?.corpId)}`,
      onConfirm: () => handleRemoveSupervisor(sellerId)
    })
  }

  const showBlockConfirmation = (sellerId, isBlock) => {
    const title = isBlock ? 'Desbloquear usuário!' : 'Bloquear usuário!'
    const text = isBlock ? 'Clique em sim para desbloquear o usuário.' : 'Clique em sim para bloquear o usuário.'
    setConfirmation({
      show: true,
      title,
      text,
      onConfirm: () => handleBlockUser(sellerId)
    })
  }

  const showLevelConfirmation = () => {
    setConfirmation({
      show: true,
      title: 'Alterar nível?',
      text: 'Clique sim para alterar o supervisor para vendedor',
      onConfirm: () => handleChangeLevel()
    })
  }

  const handleRemoveSupervisor = async (sellerId) => {
    onCloseConfirm()
    setShowLoading(true)
    try {
      const response = await mainApi.patch('/team/unsupervise', {
        seller: sellerId
      })
      handleAlert(true, 'success', 'Sucesso!', response.data.message)
    } catch (error) {

    }
    setShowLoading(false)
  }

  const buttonToEditSeller = (sellerId) => <button className='anchor-button' onClick={() => showSupervisionModal(sellerId)}>Gerenciar</button>

  const getTeamMembers = useCallback(async (search) => {
    setShowLoading(true)

    const params = objectToParams(search)

    try {

      const response = await mainApi.get(`/team/agencies/supervisors/${supervisorId}${params}`)
      const team = response.data.data.team.docs

      const formatArray = []
      team.forEach((item) => {
        formatArray.push({ ...item, phone: item.cellphone ? item.cellphone : item.telephone })
      })

      setSupervisor(response.data.data.supervisor)
      setAgencyId(response.data.data.supervisor.agencyId)
      setTeamMembers(formatArray)
      setPagination({ totalDocs: response.data.data.team.totalDocs, page: response.data.data.page - 1 })
    } catch (error) {
      const response = error.response
      if (!response?.status) {
        handleAlert(true, 'warning', 'Atenção!', 'Não foi possível se comunicar com o servidor, tente mais tarde!')
      }
      else if (500 === response.status) {
        handleAlert(true, 'danger', 'Atenção!', response.data.message)
      }
      else {
        handleAlert(true, 'info', 'Atenção!', response.data.message)
      }
    }
    setShowLoading(false)
  }, [])

  const getAgencies = async () => {
    try {
      const response = await mainApi.get(`/team/list/agencies`)
      setAgencies(response.data.data.agencies)
    } catch (error) {
      const response = error.response
      if (!response?.status) {
        handleAlert(true, 'warning', 'Atenção!', 'Não foi possível se comunicar com o servidor, tente mais tarde!')
      }
      else if (500 === response.status) {
        handleAlert(true, 'danger', 'Atenção!', response.data.message)
      }
      else {
        handleAlert(true, 'info', 'Atenção!', response.data.message)
      }
    }
  }

  const handleChangeLevel = async () => {
    onCloseConfirm()
    setShowLoading(true)
    try {
      const response = await mainApi.patch(`/team/supervisor/${supervisor._id}/change`)
      handleAlert(true, 'success', 'Sucesso!', response.data.message, onBackPage)

    } catch (error) {
      const response = error.response
      if (!response?.status) {
        handleAlert(true, 'warning', 'Atenção!', 'Não foi possível se comunicar com o servidor, tente mais tarde!')
      }
      else if (500 === response.status) {
        handleAlert(true, 'danger', 'Atenção!', response.data.message)
      }
      else {
        handleAlert(true, 'info', 'Atenção!', response.data.message)
      }
    }
    setShowLoading(false)
  }

  const handleChangeAgency = async (newAgencyId, sellerId) => {
    onCloseAgencies()
    setShowLoading(true)
    try {
      const response = await mainApi.patch(`/team/agencies/${agencyId}/sellers`, {
        newAgencyId, sellerId

      })
      handleAlert(true, 'success', 'Sucesso!', response.data.message)
    } catch (error) {
      const response = error.response
      if (!response?.status) {
        handleAlert(true, 'warning', 'Atenção!', 'Não foi possível se comunicar com o servidor, tente mais tarde!')
      }
      else if (500 === response.status) {
        handleAlert(true, 'danger', 'Atenção!', response.data.message)
      }
      else {
        handleAlert(true, 'info', 'Atenção!', response.data.message)
      }
    }
    setShowLoading(false)
  }

  const handleBlockUser = async (userId) => {
    onCloseConfirm()
    setShowLoading(true)
    try {
      const response = await mainApi.patch(`/team/user/block/${agencyId}`, {
        userId
      })
      handleAlert(true, 'success', 'Sucesso!', response.data.message)
    } catch (error) {
      const response = error.response
      if (!response?.status) {
        handleAlert(true, 'warning', 'Atenção!', 'Não foi possível se comunicar com o servidor, tente mais tarde!')
      }
      else if (500 === response.status) {
        handleAlert(true, 'danger', 'Atenção!', response.data.message)
      }
      else {
        handleAlert(true, 'info', 'Atenção!', response.data.message)
      }
    }
    setShowLoading(false)
  }

  const onSortChange = useCallback((value, type) => {
    const hasPlus = 'descending' === type ? '-' : ''
    setSearchParams((prevState) => ({
      ...prevState,
      sort: hasPlus + value,
    }))
  }, [])

  const showAgenciesModal = (sellerId) => {
    setShowAgencies({ show: true, sellerId })
  }

  const buttonChangeAgency = (sellerId) => <button className='anchor-button' onClick={() => showAgenciesModal(sellerId)} >Trocar</button>

  const option = MuiDataTableOption.sellerList({ pagination, changePage, onSortChange })
  const columns = tableFunctions.columns({ buttonToEditSeller, showRemoveConfirmation, buttonChangeAgency, showBlockConfirmation, strictId: userData?.corpId, level: userData?.level })

  const handleDate = (date) => {
    setSearchParams((prevState) => ({
      ...prevState,
      date: getMonthAndYearEN(date)
    }))
    setSearchedDate(date)
  }

  const ISClubeAmigo = [ClubeAmigoId].includes(userData?.corpId)

  return (
    <>
      <LoadingAlert
        show={showLoading}
      />

      <Dialog
        show={alert.show}
        type={alert.type}
        title={alert.title}
        text={alert.text}
        onConfirm={alert.onClose ? alert.onClose : () => { }}
      />

      <AgenciesModal
        onClose={onCloseAgencies}
        show={showAgencies.show}
        actualAgency={agencyId}
        agencies={agencies}
        sellerId={showAgencies.sellerId}
        onSelect={handleChangeAgency}
      />

      <ConfirmationAlert
        show={confirmation.show}
        title={confirmation.title}
        text={confirmation.text}
        onConfirm={confirmation.onConfirm ? confirmation.onConfirm : () => { }}
        onCancel={onCloseConfirm}
      />



      <div className="content">
        <Grid fluid>
          <Row style={{ marginBottom: '10px' }}>
            <Col lg={2} md={2} sm={2}>
              < BackLink />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="content-fluid">
                <MuiThemeProvider theme={getMuiTheme({ team: true })}>
                  <MUIDataTable
                    className="Supervisor-Table"
                    title={supervisor.name ?
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <h3>{supervisor.name}</h3>
                          {
                            !ISClubeAmigo &&
                            <FontAwesomeIcon
                              style={{ cursor: 'pointer', marginLeft: '10px', height: '100%', width: '30px' }}
                              icon={faTrashAlt}
                              color='red'
                              onClick={showLevelConfirmation}
                            />
                          }
                        </div>
                        <p className="mb-0" style={{ fontSize: 18, display: 'flex' }}>Dados referentes ao mês de
                          <DatePicker
                            id="data-tour-user-list-master-filter-register-initialDate"
                            className="datepicker-theThird"
                            dateFormat="MM/yyyy"
                            locale="pt-BR"
                            customInput={
                              <em className="bold">
                                {translateMonths(moment(searchedDate || new Date()).format('MMMM'))}
                              </em>
                            }
                            popperClassName='Supervisor-Table-popper'
                            selected={searchedDate}
                            showMonthYearPicker
                            onChange={(date, event) => handleDate(date)}
                            maxDate={new Date()}
                            minDate={new Date("2020-01-01")}
                          />
                        </p>
                      </div>
                      :
                      ''}
                    data={teamMembers}
                    columns={columns}
                    options={option}
                  />
                </MuiThemeProvider>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    </>
  )
}

export default SupervisorList