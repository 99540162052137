import React, { useLayoutEffect, useState } from 'react'
import MahoeIcon from "components/MahoeIcons";
import getUserDataByLocalStorage, { setUserData } from "utils/checkUser";
import mainApi from 'services/mainApi';
import { NavLink } from 'react-router-dom';

export const SupportGoBack = () => {

  const [user, setUser] = useState()

  useLayoutEffect(() => {
    const getUserData = async () => {
      try {
        const response = await getUserDataByLocalStorage()
        if (response) {
          setUser(response)
        }
      } catch (err) {
        console.log(err)
      }
    }

    getUserData()
  }, [])

  const handleReturnAccessSupport = async () => {
    if (user && user.supportToken) {
      try {
        const id = user.id
        const response = await mainApi.post('/operator/user/go-back', {
          token: user.supportToken
        })

        if (response) {
          localStorage.clear();
          await setUserData(response.data)

          window.location.replace(` /admin/operator/user/details?id=${id}`)

        }

      } catch (err) {
        console.log(err)
      }
    }
  }
  
  return (
    <li key="exit">
      <NavLink
        key={999}
        className="nav-link"
        activeClassName="active"
        to="#"
        onClick={(e) => {
          e.preventDefault()
          handleReturnAccessSupport()
        }}
      >
        <MahoeIcon
          name="exit"
          size="20px"
          fill="rgba(255,255,255,0.8)"
        />

        <p>Voltar</p>
      </NavLink>
    </li>
  )
}