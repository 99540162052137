import { bolleanToYesNo } from 'utils/translate'
import formater from 'utils/formater'
import { formatToBrConditional } from 'utils/formaterDate'
import { translateStatus, translateLevel } from 'utils/translate'

export const formatUserData = ({ key, value, strictId }) => {
  if (String(key).toLowerCase().includes('id') || ['name', 'reprovedSituation', 'analysisSituation'].includes(key) || typeof value === 'object') return

  switch (key) {
    case 'level':
      return {
        key: 'Nível',
        value: translateLevel(value, strictId)
      }
    case 'affiliate':
      return {
        key: 'Mar Aberto',
        value: bolleanToYesNo(value)
      }
    case 'blocked':
      return {
        key: 'Bloqueado',
        value: bolleanToYesNo(value)
      }
    case 'cpf':
      return {
        key: 'CPF',
        value: formater.toCPF(value)
      }
    case 'email':
      return {
        key: 'Email',
        value
      }
    case 'reCode':
      return {
        key: 'RE',
        value
      }
    case 'createdAt':
      return {
        key: 'Criado Em',
        value: formatToBrConditional(value)
      }
    case 'updatedAt':
      return {
        key: 'Atualizado Em',
        value: formatToBrConditional(value)
      }
    case 'agencyName':
      return {
        key: 'Loja',
        value
      }
    case 'code':
      return {
        key: 'Código',
        value
      }
    case 'cellphone':
      return {
        key: "Celular",
        value: formater.formatPhone(value)
      }
    case 'cnpj':
      return {
        key: "CNPJ",
        value: formater.toCNPJ(value)
      }
    case 'resetPassword':
      return {
        key: "Resetando senha",
        value: bolleanToYesNo(value)
      }
    case 'analysis':
      return {
        key: 'Status',
        value: translateStatus(value)
      }
    case 'lastAttempt':
      return {
        key: 'Última tentativa',
        value: formatToBrConditional(value)
      }
    case 'lastLogin':
      return {
        key: 'Último Login',
        value: formatToBrConditional(value)
      }
    case 'firstLogin':
      return {
        key: 'Primeiro acesso',
        value: formatToBrConditional(value)
      }
    case 'partner':
      return {
        key: 'Parceiro',
        value: bolleanToYesNo(value)
      }
    case 'approvedAt':
      return {
        key: 'Aprovação',
        value: formatToBrConditional(value)
      }
    case 'refusalNote':
    case 'nota':
      return {
        key: 'Motivo da recusa',
        value
      }
    case 'telephone':
      return {
        key: 'Telefone',
        value: formater.formatTelephone(value)
      }
    case 'unlink':
      return {
        key: 'Desvinculado',
        value: value ? 'Sim' : 'Não'
      }
    case 'supervising':
      return {
        key: 'Supervisionando',
        value: value + ` usuário${value !== 1 ? 's' : ''}`
      }
    default:
      return { key, value }
  }
}

export const formatMasterData = ({ key, value }) => {
  switch (key) {
    case 'name':
      return {
        key: "Nome",
        value
      }
    case 'cnpj':
      return {
        key: "CNPJ",
        value: formater.toCNPJ(value)
      }
    default:
      return { key, value }
  }

}

export const formatCorporationData = ({ key, value }) => {
  switch (key) {
    case 'name':
      return {
        key: "Nome",
        value
      }
    case 'cnpj':
      return {
        key: "CNPJ",
        value: formater.toCNPJ(value)
      }
    case 'socialName':
      return {
        key: "Nome fantasia",
        value
      }
    case 'hasManager':
      return {
        key: "Corban",
        value: bolleanToYesNo(value)
      }
    default:
      return { key, value }
  }

}

export const formatAgencyData = ({ key, value }) => {
  switch (key) {
    case 'code':
      return {
        key: "Código",
        value
      }
    case 'agencyName':
      return {
        key: "Nome",
        value
      }
    default:
      return { key, value }
  }

}
