import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap/";
import mainApi from "services/mainApi";
import getStatusCodeType from "utils/getStatusType";

import { LoadingAlert, Dialog } from "./Alert/CustomAlert";


class NewCorpEmailModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      swalMessage: [],
      swalShowMessage: false,
      swalShowLoading: false,
    };
  }

  showMessage = (type, title, message, bold) => {
    this.setState({
      swalShowMessage: true,
      swalMessage: [type, title, message, bold],
      loading: false
    });
  };  

  resetMail = async () => {
    const {corporation} = this.props
    const {email} = this.state
    this.setState({swalShowLoading: true})

    const data = {corpUserId: corporation.user._id, email}
    try{
      const response = await mainApi.patch("/corpuser/reset/password", data)
      this.setState({swalShowLoading: false})
      this.showMessage('success', 'Email resetado com sucesso!', response.data.message)

    } catch(err) {
      this.setState({swalShowLoading: false})
      if (!err.response) {
        if (err.code === "ECONNABORTED") {
          this.showMessage('error', 'O email não foi resetado', 'Tempo de espera atingido.');
        }
        else{
          this.showMessage(
            'error',
            'O email não foi resetada',
            'Não foi possível resetar o email, tente novamente mais tarde.'
            `(código de erro: ${err.response.status})`
          );
          return;
        }
      }
      if (err.response.status >= 500) {
        let status = getStatusCodeType(err.response.status)
        this.showMessage(
          status,
          'O email não foi resetada',
          'Não foi possível resetar o email, tente novamente mais tarde.'
        );
        return;
      }
      if(err.response.data.error === 'invalid-fields'){
        let fields = err.response.data.fields
        while(fields.length > 0){
          const field = fields.pop()
          switch(field.name){
            case 'email': {
              this.showMessage(
                "warning",
                "Email inválido",
                field.message
              );
              return
            }
            case 'corpUserId': {
              this.showMessage(
                "warning",
                "Usuário inválido",
                field.message
              );
              return
            }     
            default:
              break;       
          }
        }
      }
      this.showMessage('warning', 'Não foi possivel resetar a email', err.response.data.message)
    } 
  }    

  render() {
    const { corporation, show, onHide } = this.props;
    const { email, swalShowMessage, swalMessage, swalShowLoading} = this.state;
    return (
      <>
        
        <Dialog
          type={swalMessage[0]}
          show={swalShowMessage}
          title={swalMessage[1] || ''}
          onConfirm={() => {
            this.setState({swalShowMessage: false})
            if(swalMessage[0] === 'success'){
              onHide()
            }
          }}
          text={swalMessage[2]}
          boldText={swalMessage[3]}
        />
        <LoadingAlert show={swalShowLoading} />
        <Modal
          show={show}
          onHide={onHide}
          dialogClassName="modal-90w"
          aria-labelledby="agency-data"
        >
          <Modal.Header closeButton>
            <Modal.Title id="agency-data-title">Adicione um novo email para {corporation.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ display: "flex", padding: "2" }}>
              <input
                className="form-control modal-input"
                value={
                  email ||
                  ""
                }
                onChange={(event) => {this.setState({email: event.target.value})}}
              />
                <button className="btn btn-fill btn-mlm" onClick={this.resetMail}>
                  Enviar Email
                </button>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-fill btn-danger" onClick={onHide}>
              Fechar
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default NewCorpEmailModal;
