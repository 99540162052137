import swal from 'components/Alert/alert';
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

function ModalTermFGTS({ show, handleClose }) {

  const [checked, setchecked] = useState(false)
  const [error, setError] = useState(false)

  return (
    <Modal show={show} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <Modal.Header style={{ display: 'flex', justifyContent: 'center' }}>
        <Modal.Title>
          Aditivo quanto a bonificação
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modalTermsFGTS">
        Prezado Lojista PAN e afiliado PJ! A remuneração nesta plataforma para o produto FGTS será efetuada por indicação (3% sobre o valor contratado)
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '2rem' }}>
          <input id='checkbox' type="checkbox" style={{ borderColor: error && 'red' }} onClick={() => { setchecked(!checked); setError(false) }} value={checked} />
          <strong style={{ marginLeft: 15, cursor: 'pointer', color: error && 'red' }} onClick={() => { setchecked(!checked); document.querySelector('#checkbox').checked = !checked; setError(false) }}> Eu li e concordo com o texto acima</strong>
        </div>
      </Modal.Body>
      <Modal.Footer >
        <button disabled={!checked} className="btn btn-fill btn-mlm" onClick={() => checked ? handleClose() : setError(true)}> Enviar</button>
      </Modal.Footer>
    </Modal >
  )
}

export default ModalTermFGTS
