import React, { memo } from 'react'
import { TextField } from "@material-ui/core"

import { Error, Wrapper } from './styled'

function InputMuiField({ label, chidren, value, error, wrapperStyle, ...rest}) {

  return (
    <Wrapper style={wrapperStyle}>
      <TextField
        InputProps={{
          classes: {
            input: "mui-input-control",
            root: "mui-input-control",
          },
        }}
        className="input-background"
        InputLabelProps={{
          classes: {
            root: "mui-input-control mui-label",
          },
        }}
        fullWidth
        color="primary"
        variant="outlined"
        label={label}
        value={value}
        error={error}
        { ...rest }
        >
        {chidren}
      </TextField>
      
      <Error hasError={!!(error)}>{error}</Error>
    </Wrapper>
  )
}

export default memo(InputMuiField)