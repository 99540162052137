import React from 'react'

import { Dialog, List, DialogTitle, ListItem, ListItemText } from '@material-ui/core'
import { levelsBelow } from 'utils/CommonData'

function ManagerSelectDialog ({setOpen, open, level, setLevelFilter, onSelect}) {
  const levels = levelsBelow(level).filter(level => level.value !== 'all')

  return (
    <Dialog onClose={() => setOpen(false)} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Selecione um nível de gerente para prosseguir</DialogTitle>
      <List>
        {levels.map(level =>
          <ListItem className='hover-card' onClick={() => {
            setLevelFilter(level)
            onSelect()
          }}>
            <ListItemText primary={level.label} />
          </ListItem>
        )}

      </List>
    </Dialog>
  )
}

export default ManagerSelectDialog
