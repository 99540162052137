/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import NotificationSystem from "react-notification-system";

import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";

import { style } from "variables/Variables.jsx";
import refreshUserToken from "utils/refreshUserToken";

import getRoutesByLevel from "routes.js";

import Provider from "context/provider";

import getUserData from "utils/checkUser";
import AlertLogo from 'components/AlertLogo';
import AlertUser from 'components/AlertUser';
import { isMobile, isTablet } from 'react-device-detect';
import { closeIntro } from 'utils/introToSystem';
import Portal from 'components/Alert/CustomAlert';
import SupportNotify from "components/Support/SupportNotify";

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowHeight: 0,
      _notificationSystem: null,
      userData: {},
      color: "black",
      hasImage: true,
      routes: [],
      fixedClasses: "dropdown show-dropdown open"
    };

    /* document.getElementsByClassName('content')*/
  }

  handleNotificationClick = (position) => {
    var color = Math.floor(Math.random() * 4 + 1);
    var level;
    switch (color) {
      case 1:
        level = "success";
        break;
      case 2:
        level = "warning";
        break;
      case 3:
        level = "error";
        break;
      case 4:
        level = "info";
        break;
      default:
        break;
    }
    this.state._notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-gift" />,
      message: <div></div>,
      level: level,
      position: position,
      autoDismiss: 15,
    });
  };

  getRoutes = (routes) => {
    const routeArrays = routes.map((prop, key) => {
      if (prop.insideLinks) {
        return prop.insideLinks.map((link, i) => {
          return (
            <Route
              exact={link.exactly}
              path={link.layout + link.path}
              render={(props) => (
                <link.component
                  {...props}
                  name={link.name}
                  handleClick={this.handleNotificationClick}
                  routeKey={link.key}
                />
              )}
              key={prop.key + "-sub-" + i}
            />
          )
        })
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            exact={prop.exactly}
            path={prop.layout + prop.path}
            render={(props) => (
              <prop.component
                {...props}
                handleClick={this.handleNotificationClick}
                routeKey={prop.key}
              />
            )}
            key={key}
          />
        );
      } else {
        return null;
      }
    });

    return routeArrays.flat(3)
  };

  getButton = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin" && prop.addButton) {
        return (
          <button
            id="data-tour-sub-users-create"
            onClick={() => this.pushToCreateSubUser()}
            className="btn btn-mlm btn-round btn-fill mb-3">
            Novo usuário
          </button>
        )
      } else {
        return <></>
      }
    })
  }

  getBrandText = () => {
    const { routes } = this.state;
    const path = this.props.location.pathname;
    for (let i = 0; i < routes.length; i++) {
      if (
        path.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
      if (routes[i].insideLinks) {
        let selectedName
        routes[i].insideLinks.forEach(link => {
          if (path.indexOf(link.layout + link.path) !== -1)
            selectedName = link.prefix ? `${link.prefix} ${link.name}` : link.name;
        })
        if (selectedName) return selectedName
      }
    }
    return "Carregando...";
  };

  getButton = () => {
    const { routes } = this.state;

    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1 && routes[i].buttons
      ) {
        return routes[i].buttons;
      }
    }
    return;
  }

  handleColorClick = (color) => {
    this.setState({ color: color });
  };
  handleHasImage = (hasImage) => {
    this.setState({ hasImage: hasImage });
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show-dropdown open" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };

  refreshToken = async (sessionTime) => {
    setInterval(() => {
      refreshUserToken(this.props.history, sessionTime)
    }, 10000);
  };

  updateDimensions = () => {
    const content = document.getElementsByClassName("content");

    this.setState({
      windowHeight: window.innerHeight,
    });

    if (content[0]) {
      content[0].style.minHeight = `${window.innerHeight - 140}px`;
    }
  };

  getRouteKeys = () => {
    const { routes } = this.state;

    const paths = []

    routes.forEach(values => {
      const temp = {
        path: values.layout + values.path,
        key: values.key
      }
      paths.push(temp)
    })

    return paths
  }

  finPathnameInRoutes = (paths) => {
    const { pathname } = this.props.location;

    return paths.find(element => element.path === pathname)
  }

  closeIntroSystem = () => {
    const paths = this.getRouteKeys()
    const routeKey = this.finPathnameInRoutes(paths)

    closeIntro(routeKey.key)
  }

  introjsActive = () => {
    const hasIntro = document.querySelectorAll('.introjs-overlay');

    return hasIntro.length > 0
  }

  componentDidMount = async () => {
    try {
      const userData = await getUserData(this.props.history);
      const routes = getRoutesByLevel(userData);
      this.setState({ routes, userData });

      const sessionTime = userData.sessionTime;
      refreshUserToken(this.props.history, sessionTime);
      this.refreshToken(sessionTime);
    } catch (err) {
      if (!err.response || !err.response.data) {
        this.props.history.push("/");
      } else {
        this.props.history.push("/");
      }
    }

    this.updateDimensions();
    if (!isTablet && !isMobile) window.addEventListener("resize", this.updateDimensions);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateDimensions);
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      window.innerWidth < 993 &&
      prevProps.history.location.pathname !== prevProps.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
    if (prevProps.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }

    if (prevProps.history.location.pathname !== prevProps.location.pathname) {
      this.updateDimensions();
    }
  }

  render() {

    const isAuth = !!localStorage.getItem('@uxtech-mlm/UserId')

    if (!isAuth) return null

    const normalRoutes = this.state.routes.filter((item) => {
      return !item.additional;
    });

    return (
      <Provider>
        <div className="wrapper">

          <Portal id="button-portal">
            <button onClick={this.closeIntroSystem} className="close-intro">X</button>
          </Portal>

          <NotificationSystem ref="notificationSystem" style={style} />

          <Sidebar
            {...this.props}
            routes={normalRoutes}
            color={this.state.color}
            user={this.state.userData}
          />
          <div id="main-panel" className="main-panel" ref="mainPanel">
            {
              this.state.userData?.supportAccess &&
              <SupportNotify />
            }
            <AdminNavbar
              {...this.props}
              brandText={this.getBrandText(this.props.location.pathname)}
              buttons={this.getButton()}
            />

            {Object.keys(this.state.userData).length > 0 && (
              <>
                <AlertLogo userData={this.state.userData} />
                <AlertUser userData={this.state.userData} />
              </>
            )}

            <Switch>
              {this.getRoutes(this.state.routes)}

              <Route
                path="/admin*"
              >
                <Redirect to="/admin/dashboard" />
              </Route>
            </Switch>
            <Footer />
          </div>
        </div>
      </Provider>
    );
  }
}

export default Admin;
