import React, { useState, useEffect, createRef, useCallback } from 'react'
import { Grid, Row, Col } from 'react-bootstrap';
import moment from 'moment'
import Select from "react-select";
import DatePicker from "react-datepicker";
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider } from "@material-ui/core/styles";

import SupervisorsModal from "./SupervisorsModal";
import { LoadingAlert, Dialog, ConfirmationAlert } from 'components/Alert/CustomAlert';
import mainApi from "services/mainApi";

import AgenciesModal from './AgenciesModal'
import { objectToParams } from 'utils/formatData'
import getMuiTheme from 'utils/getMuiTheme'
import { tableFunctions, levelOptions } from './tableFunctions';
import MuiDataTableOption from 'utils/MuiDataTableOptions'
import { introSystem } from "utils/introToSystem"
import { Link, useHistory } from "react-router-dom"
import BackLink from 'components/LinkVoltar'
import MahoeIcon from "components/MahoeIcons";

// date-picker
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptbr from "date-fns/locale/pt-BR";
import { getMonthById } from "utils/formatData";
import { numToBRL, getMonthAndYear, getMonthAndYearEN } from "utils/formatData";
import { translateMonths } from 'utils/translate';
import { toTitleCase } from '../../utils/formatData'
import { translateLevel } from 'utils/translate';
import getUserData from 'utils/checkUser';
import { ClubeAmigoId } from 'utils/CommonData';
import { translateLevelAction } from 'utils/translate';


registerLocale("pt-BR", ptbr);

const SellersList = ({ agencyId, agencyName, admin, routeKey }) => {
  const [members, setMembers] = useState([])
  const [supervisors, setSupervisors] = useState([])
  const [seller, setSeller] = useState('')
  const [pagination, setPagination] = useState({ totalDocs: 0, page: 0 })
  const [showSupervision, setShowSupervision] = useState(false)
  const [searchParams, setSearchParams] = useState({ page: 1, date: getMonthAndYearEN(new Date()) })
  const [showLoading, setShowLoading] = useState(true)
  const [alert, setAlert] = useState({ show: false, title: '', type: '', text: '' })
  const [confirmation, setConfirmation] = useState({ show: false, title: '', text: '' })
  const [dateRange, setDateRange] = useState({})
  const [agencies, setAgencies] = useState([])
  const [showAgencies, setShowAgencies] = useState({ show: false })
  const [selectDate, setSelectDate] = useState(new Date())
  const [searchedDate, setSearchedDate] = useState(new Date())
  const [userData, setUserData] = useState({})
  const history = useHistory()

  const nameRef = createRef()
  const CPFRef = createRef()



  useEffect(() => {
    updateUserData()
    const handleUserData = async () => {
      const userData = await getUserData(history)
      setUserData(userData)
    }

    getSupervisor()
    if (!admin) {
      getAgencies()
    }

    introSystem(routeKey)
    handleUserData()
  }, [])

  useEffect(() => {
    getAgencyMembers(searchParams)
  }, [searchParams])

  async function updateUserData() {
    const userData = await getUserData(history)
    setUserData(userData)
  }

  const changePage = (page) => setSearchParams((prevState) => ({ ...prevState, page: page + 1 }))

  const supervisorToOption = (supervisors) => {
    const supervisorsOption = supervisors.map(supervisor => ({ value: supervisor._id, label: supervisor.name }))
    return [{ value: '', label: 'Todos' }, ...supervisorsOption]
  }

  const handleAlert = (show, type, title, text) => setAlert({ show, type, title, text })

  const onCloseAlert = () => {
    setAlert({ show: false, title: '', type: '', text: '' })
    getAgencyMembers(searchParams)
    getSupervisor()
  }

  const onCloseConfirm = () => setConfirmation({ show: false, title: '', text: '' })

  const onCloseAgencies = () => setShowAgencies({ show: false })

  const showRemoveConfirmation = (sellerId) => {
    setConfirmation({
      show: true,
      title: `Remover ${translateLevelAction('supervisor', userData?.corpId)}!`,
      text: `Clique sim para remover ${translateLevelAction('supervisor', userData?.corpId)}`,
      onConfirm: () => handleRemoveSupervisor(sellerId)
    })
  }

  const showBlockConfirmation = (sellerId, isBlock) => {
    const title = isBlock ? 'Desbloquear usuário!' : 'Bloquear usuário!'
    const text = isBlock ? 'Clique em sim para desbloquear o usuário.' : 'Clique em sim para bloquear o usuário.'
    setConfirmation({
      show: true,
      title,
      text,
      onConfirm: () => handleBlockUser(sellerId)
    })
  }

  const handleInitialDate = (date) => {
    setSearchParams((prevState) => ({ ...prevState, initialDate: date ? dateFormat(date) : '' }))
    setDateRange({ ...dateRange, initialDate: date })
  }

  const handleFinalDate = (date) => {
    setSearchParams((prevState) => ({ ...prevState, finalDate: date ? dateFormat(date) : '' }))
    setDateRange({ ...dateRange, finalDate: date })
  }

  const handleFilter = () => {
    setSearchedDate(selectDate)
    setSearchParams((prevState) => ({
      ...prevState,
      name: nameRef?.current?.value,
      cpf: CPFRef?.current?.value.replace(/\D/g, ''),
      date: getMonthAndYearEN(selectDate)
    }))
  }

  const onSortChange = useCallback((value, type) => {
    const hasPlus = 'descending' === type ? '-' : ''
    setSearchParams((prevState) => ({
      ...prevState,
      sort: hasPlus + value
    }))
  }, [])

  const dateFormat = (date) => moment(date).format('YYYY-MM-DD')

  const showSupervisionModal = (sellerId) => {
    setSeller(sellerId)
    setShowSupervision((prevState) => !prevState)
  }

  const showAgenciesModal = (sellerId) => {
    setShowAgencies({ show: true, sellerId })
  }

  const buttonToEditSeller = (sellerId) => <button className='anchor-button' onClick={() => showSupervisionModal(sellerId)}>Gerenciar</button>

  const buttonChangeAgency = (sellerId) => <button className='anchor-button' onClick={() => showAgenciesModal(sellerId)} >Trocar</button>

  const getAgencyMembers = useCallback(async (search) => {
    setShowLoading(true)

    const params = objectToParams(search)

    try {
      const response = await mainApi.get(`/team/agencies/${agencyId}/sellers${params}`)
      const data = response.data.data

      const formatArray = data.docs.map(item => (({ ...item, phone: item.cellphone ? item.cellphone : item.telephone })))

      setMembers(formatArray)
      setPagination({ totalDocs: data.totalDocs, page: data.page - 1 })
    } catch (error) {
      const response = error.response
      if (!response?.status) {
        handleAlert(true, 'warning', 'Atenção!', 'Não foi possível se comunicar com o servidor, tente mais tarde!')
      }
      else if (500 === response.status) {
        handleAlert(true, 'danger', 'Atenção!', response.data.message)
      }
      else {
        handleAlert(true, 'info', 'Atenção!', response.data.message)
      }
    }
    setShowLoading(false)
  }, [])

  const getSupervisor = async () => {
    try {
      const response = await mainApi.get(`/team/agencies/${agencyId}/supervisors`)
      setSupervisors(response.data.data.supervisors)
    } catch (error) {

    }
  }

  const getAgencies = async () => {
    try {
      const response = await mainApi.get(`/team/list/agencies`)
      setAgencies(response.data.data.agencies)
    } catch (error) {
      const response = error.response
      if (!response?.status) {
        handleAlert(true, 'warning', 'Atenção!', 'Não foi possível se comunicar com o servidor, tente mais tarde!')
      }
      else if (500 === response.status) {
        handleAlert(true, 'danger', 'Atenção!', response.data.message)
      }
      else {
        handleAlert(true, 'info', 'Atenção!', response.data.message)
      }
    }
  }

  const handleChangeSupervisor = async (supervisorId) => {
    setShowLoading(true)
    try {
      const response = await mainApi.patch(`/team/supervise`, {
        seller,
        supervisor: supervisorId
      })
      setShowLoading(false)
      handleAlert(true, 'success', 'Sucesso!', response.data.message)
      showSupervisionModal('')
    } catch (error) {

    }
  }

  const handleRemoveSupervisor = async (sellerId) => {
    onCloseConfirm()
    setShowLoading(true)
    try {
      const response = await mainApi.patch('/team/unsupervise', {
        seller: sellerId
      })
      handleAlert(true, 'success', 'Sucesso!', response.data.message)
    } catch (error) {
      const response = error.response
      if (!response?.status) {
        handleAlert(true, 'warning', 'Atenção!', 'Não foi possível se comunicar com o servidor, tente mais tarde!')
      }
      else if (500 === response.status) {
        handleAlert(true, 'danger', 'Atenção!', response.data.message)
      }
      else {
        handleAlert(true, 'info', 'Atenção!', response.data.message)
      }
    }
    setShowLoading(false)
  }

  const handleChangeLevel = async () => {
    setShowLoading(true)
    try {
      const response = await mainApi.patch(`/team/supervisor/${seller}/change`)
      handleAlert(true, 'success', 'Sucesso!', response.data.message)
      showSupervisionModal('')
    } catch (error) {
      const response = error.response
      if (!response?.status) {
        handleAlert(true, 'warning', 'Atenção!', 'Não foi possível se comunicar com o servidor, tente mais tarde!')
      }
      else if (500 === response.status) {
        handleAlert(true, 'danger', 'Atenção!', response.data.message)
      }
      else {
        handleAlert(true, 'info', 'Atenção!', response.data.message)
      }
    }
    setShowLoading(false)
  }

  const handleChangeAgency = async (newAgencyId, sellerId) => {
    onCloseAgencies()
    setShowLoading(true)
    try {
      const response = await mainApi.patch(`/team/agencies/${agencyId}/sellers`, {
        newAgencyId, sellerId
      })
      handleAlert(true, 'success', 'Sucesso!', response.data.message)
    } catch (error) {
      const response = error.response
      if (!response?.status) {
        handleAlert(true, 'warning', 'Atenção!', 'Não foi possível se comunicar com o servidor, tente mais tarde!')
      }
      else if (500 === response.status) {
        handleAlert(true, 'danger', 'Atenção!', response.data.message)
      }
      else {
        handleAlert(true, 'info', 'Atenção!', response.data.message)
      }
    }
    setShowLoading(false)
  }

  const handleBlockUser = async (userId) => {
    onCloseConfirm()
    setShowLoading(true)
    try {
      const response = await mainApi.patch(`/team/user/block/${agencyId}`, {
        userId
      })
      handleAlert(true, 'success', 'Sucesso!', response.data.message)
    } catch (error) {
      const response = error.response
      if (!response?.status) {
        handleAlert(true, 'warning', 'Atenção!', 'Não foi possível se comunicar com o servidor, tente mais tarde!')
      }
      else if (500 === response.status) {
        handleAlert(true, 'danger', 'Atenção!', response.data.message)
      }
      else {
        handleAlert(true, 'info', 'Atenção!', response.data.message)
      }
    }
    setShowLoading(false)
  }

  const option = MuiDataTableOption.sellerList({ pagination, changePage, onSortChange })
  const columns = tableFunctions.columns({ buttonToEditSeller, showRemoveConfirmation, admin, buttonChangeAgency, showBlockConfirmation, strictId: userData?.corpId })
  const IS_ClubeAmigo = [ClubeAmigoId].includes(userData?.corpId)


  return (
    <div className="content">
      <LoadingAlert
        show={showLoading}
      />

      <AgenciesModal
        onClose={onCloseAgencies}
        show={showAgencies.show}
        actualAgency={agencyId}
        agencies={agencies}
        sellerId={showAgencies.sellerId}
        onSelect={handleChangeAgency}
      />

      <SupervisorsModal
        supervisors={supervisors}
        show={showSupervision}
        onSelect={handleChangeSupervisor}
        onChangeLevel={handleChangeLevel}
        onClose={showSupervisionModal}
      />

      <Dialog
        show={alert.show}
        type={alert.type}
        title={alert.title}
        text={alert.text}
        onConfirm={onCloseAlert}
      />

      <ConfirmationAlert
        show={confirmation.show}
        title={confirmation.title}
        text={confirmation.text}
        onConfirm={confirmation.onConfirm ? confirmation.onConfirm : () => { }}
        onCancel={onCloseConfirm}
      />

      <Grid fluid>

        <div id="data-tour-user-list-master-filter">
          <Row style={{ marginBottom: '10px' }}>
            {
              Boolean(history.location.search) &&
              <Col lg={2} md={2} sm={2}>
                < BackLink />
              </Col>
            }
            {!IS_ClubeAmigo &&
              <Col lgOffset={Boolean(history.location.search) ? 8 : 10} lg={2} md={4} sm={10} className="margin-filter-user-list">
                <Link
                  id="data-tour-user-list-master-invite-button"
                  to={{
                    pathname: admin ? '/admin/affiliate' : `/admin/affiliate/invite`,
                    search: admin ? '' : `?agencyId=${agencyId}&agencyName=${agencyName}`,
                    state: admin ? undefined : { agencyId, agencyName }
                  }}
                  className='btn btn-fill btn-mlm btn-mlm-sm btn-team-list'>
                  Convidar
                </Link>
              </Col>}
          </Row>
          <Row style={{ marginBottom: '10px' }}>
            <Col lg={3} md={6} className="margin-filter-user-list">
              <input
                ref={nameRef}
                placeholder="Pesquisar por nome"
                className="input-search-user-list"
                style={{ background: 'white', width: '100%', cursor: 'text' }}
              />
            </Col>

            <Col lg={3} md={6} className="margin-filter-user-list">
              <input
                ref={CPFRef}
                placeholder="Pesquisar por CPF"
                className="input-search-user-list"
                style={{ background: 'white', width: '100%', cursor: 'text' }}
              />
            </Col>

            <Col lg={3} md={6} className="margin-filter-user-list">
              <Select
                placeholder={`Filtrar por ${translateLevelAction('supervisor', userData?.corpId)}`}
                styles={{ menu: styles => ({ ...styles, zIndex: 999 }) }}
                options={supervisorToOption(supervisors)}
                onChange={(event) =>
                  setSearchParams({ ...searchParams, supervisor: event.value })
                }
              />
            </Col>

            <Col lg={3} md={6} className="margin-filter-user-list">
              <Select
                placeholder="Filtrar por nível"
                styles={{ menu: styles => ({ ...styles, zIndex: 999 }) }}
                options={levelOptions(userData?.corpId)}
                onChange={(event) =>
                  setSearchParams({ ...searchParams, level: event.value })
                }
              />
            </Col>
          </Row>

          <Row style={{ marginBottom: '10px' }} >
            <Col lg={3} md={6} className="margin-filter-user-list">
              <DatePicker
                id="data-tour-user-list-master-filter-register-initialDate"
                className="date-picker"
                dateFormat='dd/MM/yyyy'
                placeholderText="Data cadastro inicial"
                locale='pt-BR'
                showYearDropdown
                showMonthDropdown
                isClearable
                selected={dateRange.initialDate}
                onChange={(date) => handleInitialDate(date)}
                selectsStart
                startDate={dateRange.initialDate}
                endDate={dateRange.finalDate}
                maxDate={new Date()}
              />
            </Col>

            <Col lg={3} md={6} className="margin-filter-user-list" style={{ height: 38 }} >
              <DatePicker
                id="data-tour-user-list-master-filter-register-finalDate"
                className="date-picker"
                dateFormat='dd/MM/yyyy'
                placeholderText="Data cadastro final"
                locale='pt-BR'
                showYearDropdown
                showMonthDropdown
                selected={dateRange.finalDate}
                onChange={(date) => handleFinalDate(date)}
                selectsEnd
                isClearable
                startDate={dateRange.initialDate}
                endDate={dateRange.finalDate}
                minDate={dateRange.initialDate}
                maxDate={new Date()}
              />
            </Col>

            <Col lg={3} md={6} className="margin-filter-user-list">
              <DatePicker
                id="data-tour-user-list-master-filter-register-initialDate"
                className="datepicker-theThird"
                dateFormat="MM/yyyy"
                locale="pt-BR"
                customInput={
                  <div className="div-datepicker" style={{ height: 38 }}>
                    <div className="col-6">
                      <span className="placeholder-datepicker">
                        {selectDate ? "Mês selecionado:" : "Selecione um mês"}
                      </span>
                    </div>
                    <div className="col-3">
                      <p className="date-datepicker">
                        {selectDate ? getMonthAndYear(selectDate) : ""}
                      </p>
                    </div>
                    <div
                      className="col-3"
                      style={{ alignSelf: "center" }}
                      onMouseEnter={(event) => this.handleMouse(event)}
                    >
                    </div>
                  </div>
                }
                selected={selectDate}
                showMonthYearPicker
                onChange={(date, event) => setSelectDate(date)}
                maxDate={new Date()}
                minDate={new Date("2020-01-01")}
              />
            </Col>
            <Col lg={3} md={6} className="margin-filter-user-list">
              <button
                id="data-tour-user-list-master-button"
                className='btn btn-fill btn-mlm btn-mlm-sm btn-team-list'
                onClick={handleFilter}>
                Filtrar por {translateLevel('seller', userData?.corpId)}
              </button>
            </Col>
          </Row>
        </div>

        <Row>
          <Col lg={12}>
            <MuiThemeProvider theme={getMuiTheme({ team: true })}>
              <MUIDataTable
                className='SellersTable'
                title={
                  <div className="Header-table">
                    <h4 style={{ margin: 0, paddingRight: 50 }}>{toTitleCase(agencyName)}</h4>
                    <p className="mb-0" style={{ fontSize: 18 }}>Dados referentes ao mês de <em className="bold">{translateMonths(moment(searchedDate).format('MMMM'))}</em></p>
                  </div>
                }
                data={members}
                columns={columns}
                options={option}
              />
            </MuiThemeProvider>
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

export default SellersList