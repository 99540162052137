import React, { Component, createRef } from "react";

import "assets/css/styles.css";
import SigninForm from "components/SigninForm/SigninForm";
import config from "utils/serverVars";
import queryString from 'query-string'
import ModalBonification from 'components/ModalBonification'
import clubeAmigoBanner from 'assets/img/clubeAmigo/clubeAmigoBanner.png'
import campanhaWhatsappBanner from 'assets/img/pan_cover_campanha_whatsapp.png'
import TermsModal from "components/TermsModal/TermsModal";
import { ISClubeAmigo } from "utils/environment";

class Signin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banner: config.IMG_PF,
      loginType: "cnpj",
      open: false
    };

    this.bannerRef = createRef()
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props !== nextProps || this.state !== nextState;
  }

  componentDidMount = () => {
    const search = queryString.parse(this.props.location.search);
    if (search?.pricetable)
      this.setState({ open: search?.pricetable === "show" })
  }

  render() {
    const {
      open
    } = this.state
    let banner, logo;
    let path = this.props.location.pathname.replace("/", "");

    const campanha = localStorage.getItem('@uxtech-mlm/campanha')
    if (campanha && !this.props.location.search) this.props.history.push({ search: `?campanha=${campanha}` })

    const search = queryString.parse(this.props.location.search);

    const SelectCampaign =  search?.campanha || search?.campaign || campanha

    const IS_CONSIGNADO = SelectCampaign === "indiqueopanconsignado"
    const IS_WHATSAPP = SelectCampaign === "whatsapp"
    const IS_CNPJ = path === "cnpj"

    if (ISClubeAmigo) {
      banner = clubeAmigoBanner
      logo = path === "cnpj" ? config.MINIMAL_LOGO : config.LOGO_ALT;
    }
    else if (path === "cnpj") {
      banner = config.IMG_PJ;
      logo = config.MINIMAL_LOGO;
    } else {
      banner = config.IMG_PF;
      logo = config.LOGO_ALT;
    }

    if (IS_CONSIGNADO && config.IMG_CAMPANHA_PF && !IS_CNPJ) {
      banner = config.IMG_CAMPANHA_PF
    }

    if(IS_WHATSAPP) banner = campanhaWhatsappBanner

    localStorage.setItem("@uxtech-mlm/campanha", SelectCampaign || '');

    return (
      <>
        <ModalBonification show={open} onClose={() => { this.setState({ open: false }) }} />
        <div className="grid-parent">
          <div
            className={
              config.CLIENT === "pan"
                ? "kv-informative"
                : "kv-informative agx-bg"
            }
          >
            {config.CLIENT === "pan" && (
              <>
                <img className="login-pan-logo" alt="logo do banco pan" src={logo}></img>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                  <img id="banner" ref={this.bannerRef} className="people-signin" alt="logo do banco pan" src={banner} useMap={ISClubeAmigo ? "#workmap" : undefined} />
                  {ISClubeAmigo && <TermsModal classNameLink={'clubeAmigo_terms'} />}
                  {/* <a >Consulte aqui o regulamento</a> */}
                  {/* {(IS_CONSIGNADO && !IS_CNPJ) && <p className="link-sign-in"><a  className='link-style' onClick={() =>{this.setState({open:true})}} >Clique aqui</a>  e consulte a tabela de bonificação.</p>} */}
                </div>
              </>
            )}
          </div>
          <div className="kv-form-login">
            <SigninForm {...this.props} loginType={path} />
          </div>
        </div>
      </>
    );
  }
}

export default Signin;
