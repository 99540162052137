import { numToBRL } from "utils/formatData"
import { pixType } from 'utils/translate'
import formater from 'utils/formater'
import { translateLevel } from "utils/translate";

export const agenciesHeader = (hasManager, strictId) => [
  {
    name: "name",
    label: "Loja", 
    options: {
      filter: true,
      sort: true,
      hint: "Nome da loja.",
    }
  },
  {
    name: "administrator.name", 
    label: `Nome do ${translateLevel("partner", strictId)}.`,
    options: {
      filter: true,
      sort: true,
      display: hasManager ? true : 'excluded',
      hint: `Nome do ${translateLevel("partner", strictId)}.`
    }
  },
  {
    name: "administrator.code", // Mudar para RE ???
    label: "Código do parceiro",
    options: {
      filter: true,
      display: hasManager ? true : 'excluded',
      sort: true,
      hint: "Código do parceiro"
    }
  },
  {
    name: 'administrator.pix.type',
    label: 'Tipo PIX',
    options: {
      filter: false,
      sort: false,
      hint: 'Tipo de chave pix utilizado',
      customBodyRender: (value) => value ? pixType(value) : 'n/a'
    }
  },
  {
    name: 'administrator.pix.value',
    label: 'Chave PIX',
    options: {
      filter: false,
      sort: false,
      hint: `Chave pix do ${translateLevel("seller", strictId)}`,
      customBodyRender: (value) => value ? value : 'n/a'
    }
  },
  {
    name: "count",
    label: "Qtde. Vendas",
    options: {
      filter: true,
      sort: true,
      hint: "Quantidade total de propostas feita pela loja.",
    }
  },
  {
    name: "commission",
    label: "Bonificação",
    options: {
      filter: true,
      sort: true,
      hint: "Bonificação total da loja.",
      customBodyRender: value => numToBRL(value)
    }
  },
  {
    name: "total",
    label: "Vendido",
    options: {
      filter: true,
      sort: true,
      hint: "Valor total vendido pela loja.",
      customBodyRender: (value) => numToBRL(value)
    },
  },
];

export const adminHeader = (isConta, hasManager, strictId) => [
  {
    name: "_id.name",
    label: translateLevel("seller", strictId),
    options: {
      filter: true,
      sort: true,
      hint: `Nome do ${translateLevel("seller", strictId)}.`,
    }
  },
  {
    name: "_id.cpf",
    label: "CPF",
    options: {
      filter: true,
      sort: true,
      hint: `CPF do ${translateLevel("seller", strictId)}.`,
      customBodyRender: (value) => {
        if (!value) return 'n/a'
        return formater.toCPF(value)
      }
    }
  },
  {
    name: '_id.pix.type',
    label: 'Tipo PIX',
    options: {
      filter: false,
      sort: false,
      hint: 'Tipo de chave pix utilizado',
      customBodyRender: (value) => value ? pixType(value) : 'n/a'
    }
  },
  {
    name: '_id.pix.value',
    label: 'Chave PIX',
    options: {
      filter: false,
      sort: false,
      hint: `Chave pix do ${translateLevel("seller", strictId)}`,
      customBodyRender: (value) => value ? value : 'n/a'
    }
  },
  {
    name: "count",
    label: "Qtde. Vendas",
    options: {
      filter: true,
      sort: true,
      hint: "Quantidade total de propostas feita pela loja.",
    }
  },
  {
    name: "commission",
    label: `Bonificação ${translateLevel("seller", strictId)}`,
    options: {
      filter: true,
      sort: true,
      hint: `Bonificação total do ${translateLevel("seller", strictId)}.`,
      customBodyRender: value => numToBRL(value)
    }
  },
  {
    name: "agencyCommission",
    label: hasManager ? `Bonificação ${translateLevel("partner", strictId)}` : "Bonificação loja",
    options: {
      filter: true,
      sort: true,
      hint: `Bonificação total do  ${translateLevel("partner", strictId)}.`,
      customBodyRender: value => numToBRL(value)
    }
  },
  {
    name: "total",
    label: "Vendido",
    options: {
      filter: true,
      sort: true,
      display: isConta ? "excluded" : true,
      hint: `Valor total vendido pelo  ${translateLevel("seller", strictId)}.`,
      customBodyRender: (value) => numToBRL(value)
    },
  },
]