import React, { Component } from 'react'
import MahoeIcons from "components/MahoeIcons"
import { formatToBr } from "utils/formaterDate"
import { Title } from "./styled"
import { Row, Col } from "react-bootstrap";


export class CardContact extends Component {

  constructor(props) {
    super(props)
    this.state = {

    }
  }

  render() {
    const {
      createdAt,
      name,
      message,
      imported
    } = this.props.data

    const {
      onDelete,
      onClick
    } = this.props

    const {
      open
    } = this.state

    return (
      <div className='card card-contact'>
        <header>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '.5rem',
            fontSize: 14
          }}>
            {/* <MahoeIcons
              name={message ? 'info' : 'document-lines'}
              fill={message ? '#E32000' : '#414141'}
              style={{ width: 26, height: 26 }}
            /> */}
            <h4
              className="title-card-contact"
              style={{ color: message ? '#E32000' : '#414141' }}
            >
              {name}
            </h4>
            <MahoeIcons
              name='delete'
              fill={message ? '#E32000' : '#414141'}
              onClick={() => onDelete && onDelete()}
              style={{ width: 22, height: 22, cursor: 'pointer' }}
            />
          </div>

        </header>
        <div className="text-card-contact">
          <p><b>Data: </b>{createdAt ? formatToBr(createdAt) : 'n/a'}</p>
          {message
            ? <>
              <p><b>Atenção: </b>{message}</p>
            </>
            : !imported
              ? <p><b>Status: </b>Processando</p>
              : <>
                <p><b>Status: </b>Concluído</p>
                <p><b className="link-style" onClick={() => {onClick && onClick()}}>Mais detalhes </b></p>
              </>
          }
        </div>

      </div>
    )
  }
}


export default CardContact