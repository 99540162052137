import React, { PureComponent } from "react";
import loadingGif from "assets/img/loading.gif";

class LoadingCover extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {useGif, dashboard} = this.props
    return (
      <div className={dashboard ? "loading-cover-dashboard" : "loading-cover"}>
        {useGif ? <img src={loadingGif} alt="" className="loading-gif" /> : <h3>Carregando...</h3>}
      </div>
    );
  }
}

export default LoadingCover;
