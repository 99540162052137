import React from 'react'
import { createMuiTheme } from "@material-ui/core/styles";

const columns = ({corpId, setCorpId, isCorp, hasManager, setAgencies, loadingTable}) => {
  return [
  {
    name: "_id",
    label: "ID",
    options: {
      display: 'excluded',
      hint: 'Identificador do usuário.'
    }
  },
  {
    name: "code",
    label: "Código",
    options: {
      sort: true,
      display: corpId || isCorp ? true : 'excluded',
      hint: 'Código do parceiro.',
      customBodyRender: (value) => {
        if (!value) return 'n/a'
        else return value
      }
    }
  },
  {
    name: "name",
    label: "Nome",
    options: {
      sort: true,
      hint: 'Nome da corporação.',
      customBodyRender: (value, rowIndex) => {
        const ID = rowIndex.rowData[0]

        if (corpId || isCorp) return value
        return (
          <button
            onClick={() => {
              setCorpId(ID)
              setAgencies([])
            }}
            className="link-style"
            disabled={loadingTable}
            style={{textAlign: 'left'}}
          >
            {value}
          </button>)
      }
    }
  },
  {
    name: "agencies",
    label: "Parceiros",
    options: {
      sort: true,
      display: corpId || isCorp ? 'excluded' : true,
      hint: 'Quantidade de parceiros.'
    }
  },
  {
    name: "links",
    label: "Links",
    options: {
      sort:  corpId,
      hint: "Quantidade de links."
    }
  },
  // {
  //   name: "clicks",
  //   label: "Clicks",
  //   options: {     
  //     sort: true,
  //     hint: "Quantidade clicks."      
  //   }
  // }, 
  {
    name: "datas.converted",
    label: "C. aprovadas",
    options: {
      sort: corpId,
      hint: 'Quantidade de contas abertas.'     
    }
  },
  {
    name: "datas.pending",
    label: "C. pendentes",
    options: {
      sort: corpId,
      hint: "Quantidade de contas pendentes."
    }
  },
  {
    name: "datas.refused",
    label: "C. recusadas",
    options: {
      sort: corpId,
      hint: "Quantidade de contas recusadas."
    }
  },
  {
    name: "superintendent.name",
    label: "Superintendente",
    options: {
      sort: true,
      display: !corpId || isCorp ? 'excluded' : true,
      hint: 'Superintendente da loja.',
      customBodyRender: (value) => {
        if (!value) return 'n/a'
        else return value
      }
    }
  },
  {
    name: "private.name",
    label: "G. private",
    options: {
      sort: true,
      display: !corpId || isCorp ? 'excluded' : true,
      hint: 'Gerente privado da loja.',
      customBodyRender: (value) => {
        if (!value) return 'n/a'
        else return value
      }
    }
  },
  {
    name: "exclusive.name",
    label: "G. exclusive",
    options: {
      sort: true,
      display: !corpId || isCorp ? 'excluded' : true,
      hint: 'Gerente exclusive da loja.',
      customBodyRender: (value) => {
        if (!value) return 'n/a'
        else return value
      }
    }
  },
  {
    name: "executive.name",
    label: "Executivo",
    options: {
      sort: true,
      display: corpId || isCorp ? true : 'excluded',
      hint: 'Executivo.',
      customBodyRender: (value) => {
        if (!value) return 'n/a'
        else return value
      }
    }
  }
]}

const options = ({ totalDocs, download, limit, onSortChange, setPage, downloadCsv, count }) => {

  return {
    count: count,
    download: download,
    rowsPerPage: limit,
    selectableRows: false,
    filterType: "dropdown",
    responsive: "scrollMaxHeight",
    serverSide: true,
    pagination: true,
    print: false,
    filter: false,
    search: false,
    rowsPerPageOptions: [],
    textLabels: {
      body: {
        noMatch: "Desculpe, nenhum dado corresponde a pesquisa"
      },
      pagination: {
        next: "Próxima Página",
        previous: "Pagina Anterior",
        rowsPerPage: "Linhas por página:",
        displayRows: "de"
      },
      toolbar: {
        search: "Procurar",
        downloadCsv: "Download CSV",
        print: "Imprimir",
        viewColumns: "Mostrar Colunas",
        filterTable: "Filtrar Tabela"
      },
      filter: {
        title: "FILTROS",
        reset: "limpar",
        all: "Todos"
      },
      viewColumns: {
        title: "Mostrar Colunas"
      }
    },

    onColumnSortChange: onSortChange,

    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          setPage(tableState.page + 1)
          break;
        default:
          break;
      }
    },

    onDownload: () => {
      downloadCsv()
      return false
    }
  }
}

export { columns, options }