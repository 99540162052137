import React, { useState } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import Skeleton from '@material-ui/lab/Skeleton'

const RichTextEditor = ({ onEditorChange, value, disabled, id, rest, customMinHeight, className, init }) => {
  const heightValidation = customMinHeight && !isNaN(customMinHeight) && customMinHeight >= 0 ? customMinHeight : 350;

  const [loading, setLoading] = useState(true)

  return (
    <div style={{ position: 'relative', minHeight: heightValidation }}>
      {loading && <Skeleton animation="wave" style={{ height: 584, position: 'absolute', width: '100%', top: -140, left: 0 }} />}
      <Editor
        {...rest}
        id={id}
        className={className}
        apiKey={process.env.REACT_APP_TINY_KEY ? process.env.REACT_APP_TINY_KEY : 'e3olzzcx116dbu1wxh1x6doukq7ani51drh2zrsvsqy1wjxw'}
        onEditorChange={onEditorChange}
        disabled={disabled}
        value={value}
        onBeforeSetContent={() => setLoading(false)}
        init={{
          ...init,
          selector: 'textarea',
          content_style: `img {
          width: 600px;
          height: auto;
        }`,
          width: '100%',
          autoresize: true,
          height: '40vh',
          // min_height: heightValidation,
          menubar: disabled ? false : 'insert',
          language: 'pt_BR',
          placeholder: 'Digite aqui...',
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          image_title: true,
          automatic_uploads: true,
          toolbar: disabled
            ? ''
            // eslint-disable-next-line no-multi-str
            : 'undo redo | formatselect | bold italic backcolor | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | removeformat | help | image | tinydrive'
        }}
      />
    </div>
  )
}

export default RichTextEditor
