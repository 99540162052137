import moment from 'moment'
const env = process.env.REACT_APP_ENV

export const formatToBr = (value) => {

  if(value) {    
    const formatedDate = moment(value).add(3, 'hour').format('DD/MM/YYYY');
    return formatedDate === "Invalid date" ? value : formatedDate
  }
}

export const formatToBrWithHour = (value) => {

  if(value) {
    return moment(value).format('DD/MM/YYYY HH:mm');
  }
}

export const formatToBrConditional = (value) => {
  if(env === "production"){
    if(value) {    
      const formatedDate = moment(value).add(3, 'hour').format('DD/MM/YYYY HH:mm');
      return formatedDate === "Invalid date" ? value : formatedDate
    }
  }else{
    if(value) {
      return moment(value).format('DD/MM/YYYY HH:mm');
    }
  }

}

export const formatToBackendFilter = (value, hour) => {

  if(value) {
    return moment(value).format(`YYYY-MM-DDT${hour}`)
  }
}

export const formatToBackEnd = (value) => {

  if(value) {
    return moment(value).format(`YYYY-MM-DD`)
  }
}

export const formatYearMonth = (date) => {
  if(date) {
    return moment(date).format(`YYYY-MM`)
  }  
}

export const returnNameMonth = date => {

  if(date) {
    return moment(date).format('MMMM')
  }
}

export const monthYear = (value) => {

  if(value) {
    return moment(value).format(`YYYY-MM`)
  }
}

export const differenceBetweenDatesInTime = (date, startDate) => {    
  if(date) {               
    let duration =  moment.duration(moment(startDate).diff(date));                               

    let hours = parseInt(duration.asHours());  
    let minutes = parseInt(duration.asMinutes())%60;  
    let seconds = parseInt(duration.seconds());

    return Math.abs(hours) + ' horas '+ Math.abs(minutes) +' minutos e ' + Math.abs(seconds)
  }
}