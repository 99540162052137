import React, { useState, useEffect } from 'react'

import {Wrapper, Title, Content, Text, SecondText} from './styled'
import { Link } from 'react-router-dom'
import mainApi from 'services/mainApi'
import MahoeIcon from "components/MahoeIcons";
import { ISClubeAmigo } from "utils/environment";

function FinishedSignup({email, id, ...rest}) {

    const [attempts, setAttempts] = useState(Number(localStorage.getItem("@uxtech-mlm/Attempts")) || 0)
    const [remaningTime, setRemaningTime] = useState(0)
    const [loading, setLoading] = useState(false)
    const [, setTimer] = useState(0)

    useEffect(() => {
      setRemaningTime(Date.now() + (attempts * 10000))

    }, [])

    setInterval(() => { setTimer(remaningTime - Date.now())}, 1000)

    const resendMail = async () => {
      setLoading(true)
      try{
        await mainApi.get('/email/resend/' + id)
      }catch(err) {}
      setLoading(false)

      setAttempts(attempts + 1)
      localStorage.setItem("@uxtech-mlm/Attempts", attempts + 1)
      setRemaningTime(Date.now() + ((attempts + 1) * 10000))
      if(attempts >= 5) setAttempts(5)
    }

    const countdown = Math.ceil((remaningTime - Date.now()) / 1000)

    return (
        <Wrapper>
            <Content style={{ margin: 0, padding: 0 }}>
              <div className="text-center">
                <Title color="black">
                  Cadastro
                  <span style={{ color: "#03a9f4" }}> Finalizado</span>

                  <MahoeIcon name="picto" fill="#fe5630" size="10px" />
                </Title>
              </div>

              <SecondText bg>Obrigado por se cadastrar no <SecondText color="#03a9f4" bg bold>{ISClubeAmigo ? 'Clube Amigo PAN' : 'Programa Indique o PAN'}!</SecondText></SecondText><br/><br/>
              <div>
                <Text>Enviamos um email para: <Text bold>{<p style={{ maxWidth: '90vw' }} className='overflow-ellipsis'>{email}</p>}</Text></Text><br/>
              </div>
              <Text color='gray'>(Verifique a caixa de spam)</Text><br/><br/>

              <div className="text-center my-3">
                <Text sm>Não recebeu o email?</Text><br/>
                <button className="btn btn-fill btn-primary mb-6 mt-3" style={{ fontSize: 14, minWidth: 280 }} onClick={resendMail} disabled={countdown > 0}>
                  {loading ?
                    "Enviando.."
                  :
                    <>
                      {countdown <= 0 ?
                        "CLIQUE AQUI PARA RE-ENVIAR O EMAIL"
                        :
                        `TENTAR NOVAMENTE EM ${countdown} SEGUNDOS`
                      }
                    </>
                  }
                </button>

                <div>
                  <Link className="signup-link " to="/">
                      Voltar ao login
                  </Link>
                </div>
              </div>

            </Content>
        </Wrapper>
    )
}

export default FinishedSignup