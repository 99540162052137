export const servers = {
  local: 'http://192.168.15.12:5001',
  agx: 'https://mlmserver.herokuapp.com',
  custom: 'http://10.10.0.99:5002',
  zarco: 'http://10.10.0.234:7000',
  janderson: 'http://10.10.0.111:5003',
  localhost: 'http://127.0.0.1:3000',
  production: "https://mlmproductionserver.herokuapp.com",
  development: "https://api.homologacaoindiqueopan.agxsoftware.com",
}
