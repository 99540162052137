import React, { Component } from "react";
import "assets/css/styles.css";

import ResetPasswordForm from "components/ResetPasswordForm";
import queryString from "query-string";
import config from "utils/serverVars";
import clubeAmigoBanner from 'assets/img/clubeAmigo/clubeAmigoBanner.png'
import TermsModal from "components/TermsModal/TermsModal";
import { ISClubeAmigo } from "utils/environment";
import campanhaWhatsappBanner from 'assets/img/pan_cover_campanha_whatsapp.png'

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banner: config.IMG_PF,
      loginType: "cnpj"
    };
  }

  render() {
    const queryStrings = queryString.parse(this.props.location.search);
    const passwordResetToken = queryStrings.passwordResetToken;
    let banner, logo;
    let path = this.props.location.pathname.split("/")[1];

    if (ISClubeAmigo) {
      banner = clubeAmigoBanner
      logo = path === "cnpj" ? config.MINIMAL_LOGO :config.LOGO_ALT;
    }
    else if (path === "cnpj") {
      banner = config.IMG_PJ;
      logo = config.MINIMAL_LOGO;
    } else {
      path = "";
      banner = config.IMG_PF;
      logo = config.LOGO_ALT;
    }

    const campanha = localStorage.getItem('@uxtech-mlm/campanha')
    const IS_CONSIGNADO = campanha === "indiqueopanconsignado"
    const IS_WHATSAPP = campanha === "whatsapp"

    if(IS_CONSIGNADO) banner = config.IMG_CAMPANHA_PF
    if(IS_WHATSAPP) banner = campanhaWhatsappBanner

    return (
      <div className="grid-parent">
        <div className={ config.CLIENT === 'pan' ? 'kv-informative' : 'kv-informative agx-bg'}>
          {config.CLIENT === 'pan' && <>
            <img alt="Logo Banco PAN" className="login-pan-logo" src={logo}></img>
            <div className="clubeAmigo_continer">
              <img alt="Foto de pessoas" className="people" src={banner}></img>
              {ISClubeAmigo && <TermsModal classNameLink={'clubeAmigo_terms'} />}
            </div>
          </>}
        </div>
        <div className="kv-form-login">
          <ResetPasswordForm
            history={this.props.history}
            {...this.props}
            passwordResetToken={passwordResetToken}
            path={path}
          />
        </div>
      </div>
    );
  }
}

export default ResetPassword;
