import { ClubeAmigoId } from './CommonData';
import { toTitleCase } from './formatData'

const bolleanToYesNo = (value) => {
  if (value === true) return "Sim";
  if (value === false) return "Não";
};

const booleanAffiliate = (value) => {
  if (value === true) return "Mar Aberto";
  if (value === false) return "Lojista";
};

const booleanPartner = (value) => {
  if (value === true) return "Parceiro";
  return value;
}

const errorType = (value) => {
  switch (value) {
    case 'not-specified':
      return "Não especificado"
    case 'api-error':
      return "Erro de API"
    default:
      return "Não especificado"
  }
}

// const typeUser = (value) => {
//   switch (value) {
//     case 'master':
//       return "Master";
//     case 'submaster':
//       return "Sub Master";
//     case 'corporation':
//       return "Corporação";
//     case 'subcorp':
//       return "Sub Corporação";
//     case 'private':
//       return "Gerente Privado";
//     case 'exclusive':
//       return "Gerente Exclusivo";
//     case 'superintendent':
//       return "Superintendente";
//     case 'executive':
//       return "Gerente Executivo";
//     case "seller":
//       return "Vendedor";
//     case "administrator":
//       return "Administrador";
//     case "supervisor":
//       return "Supervisor";
//     case 'agency':
//       return 'Loja'
//     default:
//       break;
//   }
// };

const translateStatus = (value) => {
  switch (value) {
    case "approved":
      return "Aprovado";
    case "pending":
      return "Pendente";
    case "refused":
      return "Reprovado";
    case "noaccount":
      return "Sem conta";
    default:
      return value
  }
};

const translateSituationStatus = (status) => {
  switch (status) {
    case "paid":
      return "Pago";
    case "pending":
      return "Análise";
    case "refused":
      return "Recusado";
    case "accountPending":
      return "Conta em análise";
    case "accountOpen":
      return "Conta aberta";
    case "accountRefused":
      return "Conta recusada";
    default:
      return status;
  }
};

const situationStatusToSystem = (status) => {
  switch (status) {
    case "Pago":
      return "paid";
    case "Análise":
      return "pending";
    case "Recusado":
      return "refused";
    case "Conta em análise":
      return "accountPending";
    case "Conta aberta":
      return "accountOpen";
    case "Conta recusada":
      return "accountRefused";
    default:
      return "";
  }
};

const translateMonths = (month) => {
  if (!month) return "";

  month = month.toLowerCase()

  switch (month) {
    case "january":
      return "Janeiro";
    case "february":
      return "Fevereiro";
    case "march":
      return "Março";
    case "april":
      return "Abril";
    case "may":
      return "Maio";
    case "june":
      return "Junho";
    case "july":
      return "Julho";
    case "august":
      return "Agosto";
    case "september":
      return "Setembro";
    case "october":
      return "Outubro";
    case "november":
      return "Novembro";
    case "december":
      return "Dezembro";
    default:
      return toTitleCase(month)
  }
};

const translateProduct = (product) => {
  switch (product) {
    case "cartao":
      return "Cartão Consignado";
    case "novo":
      return "Novo Empréstimo";
    case "saque":
      return "Saque Complementar";
    case "port":
      return "Portabilidade";
    case "refin":
      return "Refinanciamento";
    case "conta":
      return "Conta Digital";
    case "fgts":
      return "Antecipação FGTS";
    default:
      return product;
  }
};

const productTosystem = (product) => {
  switch (product) {
    case "Cartão Consignado":
      return "cartao";
    case "Novo Empréstimo":
      return "novo";
    case "Saque Complementar":
      return "saque";
    case "Portabilidade":
      return "port";
    case "Refinanciamento":
      return "refin";
    case "Conta Digital":
      return "conta";
    case "Antecipação FGTS":
      return "fgts";
    case "Todos":
      return "";
    default:
      return product;
  }
};

export const typeManager = (value) => {
  if (!value) return;

  switch (value) {
    case "private":
      return "Gerente Private";
    case "exclusive":
      return "Gerente Exclusive";
    case "superintendent":
      return "Superintendente";
    case "executive":
      return "Executivo Corban";
    default:
      return value;
  }
};

export const translatePhone = (value) => {
  switch (value) {
    case "cellphone":
      return "Celular";
    case "secondCellphone":
      return "Celular";
    case "telephone":
      return "Telefone";
    default:
      return value;
  }
};

export const translateLevel = (value, strictId) => {
  switch (value) {
    case 'master': {
      return 'Master'
    }
    case 'submaster': {
      return 'SubMaster'
    }
    case 'superintendent': {
      return 'Superintendente'
    }
    case 'private': {
      return 'Gerente Private'
    }
    case 'exclusive': {
      return 'Gerente Exclusive'
    }
    case 'executive': {
      return 'Executivo Corban'
    }
    case 'corporation': {
      return 'Corporação'
    }
    case 'subcorp': {
      return 'SubCorporação'
    }
    case 'partner': {
      if (strictId === ClubeAmigoId) return 'Gerente'
      return 'Parceiro'
    }
    case 'agency': {
      if (strictId === ClubeAmigoId) return 'Gerente'
      return 'Loja'
    }
    case 'administrator': {
      if (strictId === ClubeAmigoId) return 'Gerente'
      return 'Administrador'
    }
    case 'supervisor': {
      if (strictId === ClubeAmigoId) return 'Consultor'
      return 'Supervisor'
    }
    case 'seller': {
      if (strictId === ClubeAmigoId) return 'Indicador'
      return 'Vendedor'
    }
    case 'operator': {
      return 'Operador'
    }
    case 'affiliate': {
      return 'Afiliados Mar Aberto (PF)'
    }
    case 'agx': {
      return 'Agx'
    }

    default: {
      return value
    }
  }
};

export const translateLevelAction = (value, strictId) => {
  switch (value) {
    case 'administrator': {
      if (strictId === ClubeAmigoId) return 'Gerência'
      return 'Administração'
    }
    case 'supervisor': {
      if (strictId === ClubeAmigoId) return 'Consultor'
      return 'Supervisão'
    }
    default: {
      return value
    }
  }
};

export const translateCommunicationAdmin = (value, strictId) => {
  switch (value) {
    case 'supervisor': {
      if (strictId === ClubeAmigoId) return 'Gerente'
      return 'Administrador'
    }
    case 'seller': {
      if (strictId === ClubeAmigoId) return 'Consultor'
      return 'Administrador'
    }
    default: {
      return 'Administrador'
    }
  }
}

const userProperties = (value) => {
  switch (value) {
    case 'name':
      return 'Nome'
    case 'level':
      return 'Nível'
    case 'email':
      return 'Email'
    case 'cpf':
      return 'CPF'
    case 'cnpj':
      return 'CNPJ'
    case 'corporationCNPJ':
      return 'CNPJ'
    case 'partner':
      return 'Parceiro'
    case 'affiliate':
      return 'Afiliado'
    case 'blocked':
      return 'Bloqueado'
    case 'cellphone':
      return "Celular"
    case 'lastAttempt':
      return 'Última tentativa'
    case 'lastLogin':
      return 'Último Login'
    case 'emailSent':
      return 'Email Enviado'
    case 'agencyName':
      return 'Nome da Agência'
    case 'corporationName':
      return 'Nome da Corporação'
    case 'loginType':
      return 'Tipo de Login'
    case 'createdAt':
      return 'Criado em'
    case 'updatedAt':
      return 'Atualizado em'
    case 'login':
      return 'Login'
    case 'code':
      return 'Código'
    case 'approvedAt':
      return 'Aprovação'
    case 'type':
      return 'Tipo'
  }
}

const errorPriority = (value) => {
  switch (value) {
    case "low":
      return "Baixa"
    case "normal":
      return "Normal"
    case "high":
      return "Alta"
    case "urgent":
      return "Urgente"
  }
}

const pixType = (value) => {
  switch (value) {
    case 'email':
      return 'Email'
    case 'cellphone':
      return 'Celular'
    case 'cpf':
      return 'CPF'
    case 'randomkey':
      return 'Chave aleatória'
    case 'cnpj':
      return 'CNPJ'
    default:
      return value
  }
}

const shotenerProps = (value) => {
  switch (value) {
    case 'LOJA':
      return 'Loja'
    case 'NOME':
      return 'Nome'
    case 'CORP':
      return 'Corporação'
    case 'BLOQUEADO':
      return 'Bloqueado'
    case 'LINK_ORIGINAL':
      return 'Link original'
    case 'CODE':
      return 'Código da Loja'
    case 'CONTAS_RECUSADAS':
      return 'Contas Recusadas'
    case 'CONTAS_PAGAS':
      return 'Contas Abertas'
    case 'CONTAS_PENDENTES':
      return 'Contas Pendentes'
    case 'NUMERO_DE_CLICKS':
      return 'Número de Clicks'
    default:
      return value

  }
}

const eventStatus = (value) => {
  if (!value) return ' '

  switch (value) {
    case 'clicks':
      return 'Acessos (Cliente)'
    case 'contato':
      return 'Contato'
    case 'dados_iniciais':
      return 'Dados iniciais (Cliente)'
    case 'endereco':
      return 'Endereço'
    case 'informacoes_adicionais':
      return 'Info adicionais'
    case 'documentos':
      return 'Documentos'
    case 'permissoes':
      return 'Permissões'
    case 'revisar':
      return 'Revisar'
    case 'fotos':
      return 'Fotos (Cliente)'
    case 'todas_fotos':
      return 'Fotos | Reenvio de fotos (Cliente)'
    case 'reenvio_fotos':
      return 'Reenvio de fotos (Cliente)'
    case 'pendente':
      return 'Pendente'
    case 'em_analise':
      return 'Em análise (Banco)'
    case 'andamento':
      return 'Andamento'
    case 'finalizado':
      return 'Finalizado'
    case 'reprovada':
        return 'Reprovada (Banco)'
    case 'envio_sms':
      return 'Envio SMS'
    case 'aprovada':
      return 'Baixar app (Cliente)'
    case 'conta_aberta':
      return 'Contas abertas (Banco)'
    default:
      return value
  }
}

const appsFlyerStatus = (value) => {
  if (!value) return ''
  switch (value) {
    case 'DadosIniciais':
      return 'Dados Iniciais'
    case 'OndeVoceMora':
      return 'Onde Você Mora'
    case 'MaisSobreVoce':
      return 'Mais Sobre Você'
    case 'HoraDasFotos':
      return 'Hora das Fotos'
    case 'VamosTirarUmaSelfie':
      return 'Vamos Tirar Uma Selfie'
    case 'RetornoContaAbertaSucesso':
      return 'Retorno Conta Aberta Sucesso'
    default:
      return value
  }
}

const revertEventStatus = (value) => {
  if (!value) return ''
  switch (value) {
    case 'Dados iniciais (Cliente)':
      return 'dados_iniciais'
    case 'Fotos (Cliente)':
      return 'fotos'
    case 'Reenvio de fotos (Cliente)':
      return 'reenvio_fotos'
    case 'Em análise (Banco)':
      return 'em_analise'
    case 'Baixar app (Cliente)':
      return 'aprovada'
    case 'Reprovada (Banco)':
      return 'reprovada'
    case 'Contas abertas (Banco)':
      return 'conta_aberta'
    default:
      return value
  }
}

export {
  bolleanToYesNo,
  booleanAffiliate,
  booleanPartner,
  translateStatus,
  translateSituationStatus,
  translateProduct,
  productTosystem,
  situationStatusToSystem,
  translateMonths,
  userProperties,
  errorPriority,
  errorType,
  pixType,
  shotenerProps,
  eventStatus,
  revertEventStatus,
  appsFlyerStatus
};
