import React, { Component, createRef } from "react";
import { Grid, Row, Col, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Card from "components/Card/Card";
import CardCSV from "components/Card/CardCSV";
import MahoeIcons from "components/MahoeIcons";
import Pagination from "@material-ui/lab/Pagination";
import InfoCard from "components/Card/InfoCard";
import InputMuiField from "components/InputMuiField";
import { TextField, CircularProgress } from "@material-ui/core";
import { objectToParams, numToBRL, getMonthAndYear } from "utils/formatData";
import { Wrapper } from "./styled";
import mainApi from "services/mainApi";
import to from "await-to-js";
import { LoadingAlert } from "components/Alert/CustomAlert";
import { Backup } from "@material-ui/icons";
import swal from "components/Alert/alert";
import moment from 'moment'
import socketIo from 'socket.io-client'
import { servers } from 'utils/config'
import UnimportantText from "components/UnimportantText";

//AVAILABLE ICONS: coins, wallet, growing-up, resize, 
//                 person, touch, chart-pie, bank-check, id,
//                 store, exit, house, people, link-default,
//                 square-pin, add, picto, question, mail, phone, rounded-close
//                 filter, info, lamp, document, person-checked, link-add, person-add

const appEnv = process.env.REACT_APP_ENV
let baseUrl = servers[appEnv] || servers['local'];


class ImportAgency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectDate: new Date(),
      openPopper: false,
      anchorEl: null,
      search: "",
      csvList: undefined,
      uploading: false,
      pagination: { page: 1, totalPages: 1 },
      dashboard: { success: 0, errors: 0, docs: 0 },
      csvLoading: false,
      percentage: 0,
      fileName: '',
      step: '',
      showCsvList: false
    };
    this.inputRef = createRef();

    this.searchRef = createRef();
    this.io = undefined;
  }

  setSelectedDate = (date) =>
    this.setState({ selectDate: date } /*, () => this.getStats()*/);

  handleMouse = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
      openPopper: true,
    });
  };



  handleFile = async (e) => {
    // this.setState({ uploading: true });

    const file = e.target.files[0];
    
    if (!file) swal.basic("info", "Arquivo não selecionado!", "Selecione um arquivo CSV.")

    this.setState({ uploading: true })
    
    const data = new FormData();

    data.append("csv", file);

    const [err, response] = await to(
      mainApi.post("/import/partners", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    );

    if (err && err.response) {
      swal.basic("warning", "Temos um Problema", err.response.data.message);
      this.setState({ uploading: false })
      return;
    }
    const message = response?.data?.message || "";
    if (response?.data) swal.basic("success", "Parceiros cadastrados!", message) ;

    this.getImportedFiles()

    if(this.inputRef.current) this.inputRef.current.value = ''
    
    this.setState({ uploading: false })
  };

  getImportedFiles = async () => {
    const { selectDate, search, pagination } = this.state

    const params = objectToParams({ date: moment(selectDate).format('YYYY-MM-DD'), name: search, page:pagination.page })
    
    try {
      const response = await mainApi.get(`/import/list/partner${params}`)

      this.setState({
        pagination: { page: response.data.data.page, totalPages: response.data.data.totalPages },
        csvList: response.data.data.docs,
        showCsvList: true
      })

    } catch (error) {
      console.warn(error)
    }
  }

  changePage = (event, value) => this.setState({ pagination: { page: value, totalPages: this.state. pagination.totalPages } }, () => this.getImportedFiles())

  getDashboardImport = async () => {
    try {
      const response = await mainApi.get('/import/list/dashboard')
      this.setState({ dashboard: { success: response.data.data.success, errors: response.data.data.error, docs: response.data.data.totalDocs } })
    } catch (error) {
      console.warn(error)
    }
  }

  componentDidMount() {
    this.getImportedFiles()
    this.getDashboardImport()
    this.io = socketIo(baseUrl)

    this.io.on(`importPartner${localStorage.getItem("@uxtech-mlm/UserId")}`, (data) => {
      this.setState({ percentage: data.percentage, fileName: data.fileName, step: data.text, csvLoading: data.csvLoading })
      if(data.reload) {
        setTimeout(() => {
          this.getDashboardImport()
          this.getImportedFiles()
        }, 1000)
      } 
    })
  }

  componentWillUnmount() {
    if(this.io){
      this.io.close(()=> console.log('closed'))
    }
  }

  render() {
    const {
      selectDate,
      openPopper,
      anchorEl,
      searchRef,
      search,
      csvList,
      uploading,
      pagination,
      dashboard,
      csvLoading,
      percentage,
      fileName,
      step
    } = this.state;

    return (
      <>
        <LoadingAlert show={uploading} title="Cadastrando Lojas" />
        <Grid fluid style={{ paddingInline: "15px" }}>
          <Row className="log-cards" style={{ margin: 0 }}>
            <Col style={{ paddingInline: "15px" }} lg={4} md={4} sm={12}>
              <InfoCard
                name={dashboard.errors}
                description="Erros"
                icon="person-delete"
                iconColor="#ff0000"
                nameColor="red"
              ></InfoCard>
            </Col>
            <Col style={{ paddingInline: "15px" }} lg={4} md={4} sm={12}>
              <InfoCard
                name={dashboard.success}
                description="Sucessos"
                icon="person-checked"
                iconColor="#009900"
                nameColor="green"
              ></InfoCard>
            </Col>
            <Col style={{ paddingInline: "15px" }} lg={4} md={4} sm={12}>
              <InfoCard
                name={dashboard.docs}
                description="Arquivos"
                icon="document-lines"
              ></InfoCard>
            </Col>
          </Row>
          {/* <Card
          content={
            <>
              <Row>
                <Col md={2}>
                  <a className='anchor-button' href='https://agxsoftwarecloud.s3.amazonaws.com/1604405797163-5eff6fb353c796cf959172f5.csv' >Baixar exemplo</a>
                </Col>
                
              </Row>
            </>
          }
        ></Card> */}

          <Row className="filters" style={{ margin: 0 }}>
            <Col lg={6}>
              <TextField
                fullWidth
                label="Pesquisa por nome"
                inputRef={this.searchRef}
                color="primary"
                variant="outlined"
                style={{ backgroundColor: "white" }}
              />

            </Col>
            <Col lg={4}>
              <DatePicker
                className="datepicker-theThird"
                dateFormat="MM/yyyy"
                locale="pt-BR"
                isClearable
                customInput={
                  <div className="div-datepicker">
                    <div className="col-6">
                      <span className="placeholder-datepicker">
                        {selectDate ? "Mês selecionado:" : "Selecione um mês"}
                      </span>
                    </div>
                    <div className="col-3">
                      <p className="date-datepicker">
                        {selectDate ? getMonthAndYear(selectDate) : ""}
                      </p>
                    </div>
                    <div
                      className="col-3"
                      style={{ alignSelf: "center" }}
                      onMouseEnter={(event) => this.handleMouse(event)}
                      onMouseLeave={() => this.setState({ openPopper: false })}
                    >
                      {selectDate ? (
                        <></>
                      ) : (
                          <MahoeIcons
                            aria-describedby={"iconTooltip"}
                            name="info"
                            size="23px"
                            fill="#039BE5"
                          />
                        )}
                    </div>
                  </div>
                }
                selected={selectDate}
                showMonthYearPicker
                onChange={(date, event) => this.setSelectedDate(date, event)}
                maxDate={new Date()}
                minDate={new Date("2020-01-01")}
              />
            </Col>
            <Col md={2}>

              <button
                className="btn btn-fill btn-mlm"
                style={{ width: "100%" }}
                onClick={(e) => {
                  this.setState({ search: this.searchRef.current.value }, () => {
                    this.getImportedFiles()
                  })
                }}
              >
                Filtrar
              </button>
            </Col>
          </Row>
          <Row style={{ marginRight: 15, marginLeft: 15, marginTop: 10 }}>
            <Col lg={6} lgOffset={3}>

              <button
                type="button"
                className='btn btn-fill btn-mlm'
                style={{ width: "100%", display: 'flex', alignContent: 'center', justifyContent: 'center' }}
                onClick={() => {
                  this.inputRef.current.click();
                }}
              >
                <Backup style={{ marginRight: '5px' }} /> Importar
              </button>
              <input
                type="file"
                style={{ display: "none" }}
                ref={this.inputRef}
                onChange={this.handleFile}
              ></input>
            </Col>
          </Row>
          <Row style={{ marginRight: 15, marginLeft: 15, marginTop: 10 }}>
            <Col>
              <Grid fluid>
                <Row>
                  <Col lg={10} md={10} sm={10}>
                    <h4 className="title" style={{ marginTop: 15 }}>
                      Listagem de CSVs importados
                  </h4>
                  </Col>
                  <Col lg={2} md={2} sm={2} style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div style={{ justifyContent: 'center', display: 'flex', paddingBottom: "10px" }}>
                      <a href="https://agxsoftwarecloud.s3.amazonaws.com/model.csv"  download className="btn  btn-mlm btn-mlm-sm btn-small-extract">Baixar Modelo CSV</a>
                      <a />
                    </div>
                  </Col>
                </Row>
                <Row >
                  {csvLoading ? (
                    <Col lg={3} md={4} sm={12}>
                      <CardCSV data={{ percentage, fileName, step }} isLoading />
                    </Col>
                  ) : ''
                  }
                  {csvList && csvList.length > 0 ?
                    csvList.map((csv) => (
                      <Col lg={3} md={4} sm={12} key={csv._id}>
                        <CardCSV data={{ ...csv }} />
                      </Col>
                    )) : (
                      <Col style={{ display: "flex", justifyContent: "center" }}>
                        {csvLoading ? <CircularProgress /> : <UnimportantText text="Nenhum CSV importado para a data selecionada."/>}
                      </Col>
                    )
                  }
                </Row>

                <div
                  style={{
                    display: "grid",
                    justifyItems: "center",
                    margin: "10px",
                  }}
                >
                  <Pagination
                    count={pagination.totalPages}
                    page={pagination.page}
                    showFirstButton
                    showLastButton
                    onChange={this.changePage}
                  />
                </div>
              </Grid>
            </Col>
          </Row>
        </Grid>
      </>
    );
  }
}

export default ImportAgency;
