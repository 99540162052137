import Swal from 'sweetalert2';

function closeSwal() {
  Swal.close();
}

const basic = (icon, title, text) => {
  Swal.fire({
    icon,
    title,
    text,
    customClass: {
      confirmButton: 'btn btn-success w-100',
      cancelButton: 'btn btn-danger'
    }
  })
}

const confirm = (icon, title, text, confirm) => {
  Swal.fire({
    icon,
    title,
    text,    
    closeOnClickOutside: false,    
    customClass: {
      confirmButton: 'btn btn-success w-100',
      cancelButton: 'btn btn-danger'
    }
  })
  .then((result) => {
    if (result.isConfirmed) {
      confirm()
    }
  }) 
}

const confirmNegate = (icon, title, text, confirm) => {
  Swal.fire({
    icon,
    title,
    text,
    showCancelButton: true,
    cancelButtonText: 'Cancelar',
    closeOnClickOutside: false,    
    customClass: {
      confirmButton: 'btn btn-success w-100',
      cancelButton: 'btn btn-danger w-100'
    }
  })
  .then((result) => {
    if (result.isConfirmed) {
      confirm()
    }
  }) 
}

const text = (title, text) => {
  Swal.fire({
    title,
    text,
    customClass: {
      confirmButton: 'btn btn-success w-100',
      cancelButton: 'btn btn-danger'      
    }
  })
}

const loading = (show) => {  

  if(show) {
    Swal.fire({
      title: 'Carregando!',
      onBeforeOpen: () => {
        Swal.showLoading()
      }
    })
  } else {
    closeSwal();
  }
}

const swal = {
  loading,
  basic,
  text,
  confirm,
  confirmNegate
}

export default swal