import React from 'react'
import BasicPopover from 'components/BasicPopover/BasicPopover'
import { formatToBrConditional } from 'utils/formaterDate'

export const columns = [
  {
    name: "id",
    label: "ID",
    options: {
      display: false,
      setCellProps: () => ({ className: 'no-wrap' }),
      hint: "Identificador do Email",
    }
  },
  {
    name: "subject",
    label: "Assunto",
    options: {
      sort: false,
      setCellProps: () => ({ className: 'no-wrap' }),
      customBodyRender: (value) => {
        if (!value) return '-'
        return value
      }
      // hint: "E-mail ao qual foi enviada"
    }
  },
  {
    name: "email",
    label: "E-mail",
    options: {
      sort: false,
      setCellProps: () => ({ className: 'no-wrap' }),
      hint: "E-mail ao qual foi enviada",
      customBodyRender: (value) => {
        if (!value) return '-'
        return String(value).length > 30 ? (
          <BasicPopover
            noWrap={true}
            text={value}
            buttonText={`${String(value).slice(0, 30)}...`}
          />
        ) : (
          value
        )
      }
    }
  },
  {
    name: "sent",
    label: "Enviado",
    options: {
      sort: false,
      setCellProps: () => ({ className: 'no-wrap' }),
      hint: "E-mail foi enviado",
      customBodyRender: (value, rowIndex) => {
        return value ? "Sim" : "Não"
      }
    }
  },
  {
    name: "sending",
    label: "Enviando",
    options: {
      sort: false,
      setCellProps: () => ({ className: 'no-wrap' }),
      hint: "Está no processo de envio",
      customBodyRender: (value, rowIndex) => {
        return value ? "Sim" : "Não"
      }
    }
  },
  {
    name: "error",
    label: "Erro",
    options: {
      sort: false,
      setCellProps: () => ({ className: 'no-wrap' }),
      hint: "Ocorreu um erro",
      customBodyRender: (value, rowIndex) => {
        return value ? "Sim" : "Não"
      }
    }
  },
  {
    name: "tries",
    label: "N° Tentativas",
    options: {
      sort: false,
      setCellProps: () => ({ className: 'no-wrap' }),
      hint: "Número de tentativas de envio",
      customBodyRender: (value) => {
        if (!value) return '-'
        return value
      }
    }
  },
  {
    name: "sentAt",
    label: "Data de envio",
    options: {
      sort: false,
      setCellProps: () => ({ className: 'no-wrap' }),
      hint: "Data no qual o e-mail foi enviado",
      customBodyRender: (value, rowIndex) => {
        if (!value) return '-'
        return formatToBrConditional(value)
      }
    }
  },
]

export const options = {
  selectableRows: false,
  rowsPerPage: 10,
  filterType: "dropdown",
  responsive: "scrollMaxHeight",
  serverSide: true,
  pagination: true,
  print: false,
  filter: false,
  download: false,
  viewColumns: false,
  search: false,
  rowsPerPageOptions: [10, 15, 30, 50],
  textLabels: {
    body: {
      noMatch: "Desculpe, nenhum dado corresponde a pesquisa"
    },
    pagination: {
      next: "Próxima Página",
      previous: "Pagina Anterior",
      rowsPerPage: "Linhas por página:",
      displayRows: "de"
    },
    toolbar: {
      search: "Procurar",
      viewColumns: "Mostrar Colunas",
      filterTable: "Filtrar Tabela"
    },
    filter: {
      title: "FILTROS",
      reset: "limpar",
      all: "Todos"
    },
    viewColumns: {
      title: "Mostrar Colunas"
    }
  },
}