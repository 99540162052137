import styled from 'styled-components'

export const Wrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;  
  flex-direction: column;
`

export const InfoCardContent = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`
