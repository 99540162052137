import React, { useState, useEffect, useRef } from 'react'
import RichTextEditor from '../../components/RichTextEditor'

import { Multiselect } from "multiselect-react-dropdown";
import InputMuiField from "components/InputMuiField";

import { Grid, Row, Col } from 'react-bootstrap'
import Button from "components/Buttons/Button";
import swal from "components/Alert/alert";
import { Error } from './styled'


import { subLevels } from 'utils/CommonData'
import getUserData from "utils/checkUser";

import mainApi from "services/mainApi";
import { translateLevel } from 'utils/translate';


const Notification = (props) => {
  const [richText, setRichText] = useState('')
  const [levels, setLevels] = useState([])
  const [selectedLevels, setSelectedLevels] = useState([])
  const [title, setTitle] = useState('')
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)

  const selectRef = useRef(null)

  function handleRichText(value) {
    if (value) {
      setRichText(value)
    }
  }

  function resetFields() {
    setTitle('')
    setErrors({})
    setRichText('')
    selectRef.current.resetSelectedValues();
    setSelectedLevels([])
  }

  async function getLevels() {
    try {
      const userData = await getUserData(props.history);
      let levels = subLevels(userData.level)

      levels = levels.map(level => {
        return { label: translateLevel(level.value, userData.corpId), value: level.value }
      })

      setLevels(levels)
    } catch (err) {
      console.log(err)
    }
  }

  const multiSelectStyle = {
    chips: {
      background: "#b0bfb4",
      height: "20px",
      borderRadius: "2px",
      fontSize: "10px",
    },
    searchBox: {
      outline: "none",
      paddingLeft: "12px",
      paddingTop: "10px",
      paddingBottom: "10px",
      backgroundColor: 'white',

    },
    multiselectContainer: {
      zIndex: '20',
    }
  }

  function handleErrorsResponse(response) {
    const auxErrors = {};
    if (!response) return;

    response.forEach((item) => {
      auxErrors[item.name] = item.message;
    });

    setErrors(auxErrors)
  };

  async function sendNotification() {
    setLoading(true)
    const data = {}

    if (title.length) {
      data['title'] = title
    }

    if (richText !== '') {
      data['message'] = richText
    }

    if (selectedLevels.length) {
      data['levels'] = selectedLevels.map(level => level.value)
    }

    try {
      const response = await mainApi.post("/notification", data);
      resetFields()
      swal.basic("success", "Sucesso", response.data.message)
    } catch (err) {
      console.log(err)
      if (err.response?.data?.data && err.response.data.data.invalid) handleErrorsResponse(err.response.data.data.invalid)
      swal.basic("warning", "Atenção!", err.response.data.message);
    }
    setLoading(false)
  }

  useEffect(() => {
    getLevels()
  }, [])

  return (
    <div className="content">
      <div className="container-fluid">
        <Row>
          <Col lg={6} md={6} sm={12} xs={12}>
            <InputMuiField
              label="Título"
              onChange={(event) => setTitle(event.target.value)}
              error={errors.title}
              value={title}
            >
              Título
            </InputMuiField>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12} style={{ marginBottom: '20px' }}>
            <Multiselect
              options={levels}
              displayValue="label"
              placeholder="Selecione os níveis"
              selectedValues={selectedLevels}
              onSelect={(value) => { setSelectedLevels(value) }}
              onRemove={(value) => { setSelectedLevels(value) }}
              hidePlaceholder={true}
              avoidHighlightFirstOption={true}
              emptyRecordMsg=""
              closeIcon={'cancel'}
              style={multiSelectStyle}
              ref={selectRef}
            />
            {errors.levels && <Error hasError={!!(errors.levels)}>{errors.levels}</Error>}
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <RichTextEditor
              value={richText}
              onEditorChange={(value) => setRichText(value)}
            />
            {errors.message && <Error hasError={!!(errors.message)}>{errors.message}</Error>}
          </Col>
        </Row>

        <Row style={{ marginTop: '20px' }}>
          <Col lg={4} md={12} sm={12} xs={12}>
            <Button
              onClick={() => { sendNotification() }}
              disabled={loading}
            >
              Enviar notificação
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Notification