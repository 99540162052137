import moment from 'moment'

const downloadCsv = (csv, filePrefix, date = moment().format('YYYY-MM')) => {

    let file = "text/CSV;charset=utf-8,%EF%BB%BF" + encodeURI(csv);
    let filename = `${filePrefix}_${date}`;

    let a = document.createElement('a');

    a.href = "data:" + file;
    a.download = filename + ".csv";

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

export {downloadCsv}