import React from 'react'
import { createMuiTheme } from "@material-ui/core/styles";
import { formatToBr } from 'utils/formaterDate';
import { numToBRL, toTitleCase } from 'utils/formatData'
import { Button } from '@material-ui/core';

import spinner from "assets/img/loading.gif"

const tableFunctions = {
  columns: ({ copyShorten, showQrCode, corporation, showClick }) => [
    {
      name: "_id",
      label: "ID",
      options: {
        display: false,
        viewColumns: false,
      }
    },
    {
      name: "campaign",
      label: "Campanha",
      options: {     
        sort: true,
        // hint: "Nome da campanha.",
        customBodyRender: (value) => toTitleCase(value)
      }
    },
    {
      name: "clickNumber",
      label: "Qtd. de clicks",
      options: {
        // hint: "Quantidade de clicks no link da campanha.",
        sort: false,
        customBodyRender: (value, tableInfo) => {
          const { rowData, columnIndex } = tableInfo;
          if (typeof value === 'number')
            return value
          if (value === 'loading')
            return <img style={{height: 14, width: 14}} src={spinner} alt="" />
          return <button onClick={() => showClick(rowData[0])} className='anchor-button'>Ver</button>
        }
      }
    },
    {
      name: "department",
      label: "Dpt.",
      options: {     
        sort: true,
        // hint: "Departamento qual a campanha foi direcionada.",
        customBodyRender: (value) => toTitleCase(value)
      }
    },
    {
      name: 'agencyName',
      label: 'Loja',
      options: {
        sort: true,
        display: corporation && true,
        viewColumns: corporation && true,
        // hint: "Nome da loja que criou a campanha.",
        customBodyRender: (value) => toTitleCase(value)
      }
    },
    {
      name: "createdAt",
      label: "Inserção",
      options: {     
        sort: true,
        // hint: "Data de criação da campanha.",
        customBodyRender: (value) => {
          return formatToBr(value)
        }
      }
    },
    {
      name: "datas.converted",
      label: "Qtd. Aprovado",
      options: {     
        sort: true,
        // customBodyRender: (value) => value || '0'
        // hint: "Quantidade de clicks no link da campanha.",
      }
    },
    {
      name: "datas.paid",
      label: "Vl. Aprovado",
      options: {     
        sort: true,
        // hint: "Valor em real pago referente ao mês definido.",
        customBodyRender: (value) => numToBRL(value)
      }
    },
    {
      name: "datas.pending",
      label: "Vl. Análise",
      options: {     
        sort: true,
        // hint: "Valor em real pendente referente ao mês definido",
        customBodyRender: (value) => numToBRL(value)
      }
    },
    {
      name: "datas.refused",
      label: "Vl. Recusado",
      options: {     
        sort: true,
        // hint: "Valor em real recusado referente ao mês definido",
        customBodyRender: (value) => numToBRL(value)
      }
    },
    // {
    //   name: "datas.converted",
    //   label: "Conversões",
    //   options: {
    //     sort: true,
    //     // hint: "Quantidade de links convertidos.",
    //   }
    // },
    {
      name: "code",
      label: "Link",
      options: {     
        sort: false,
        customBodyRender: (value) => (
        <>
          <input type="text" value={value} disabled/>
          <button onClick={(event) => {            
            copyShorten(event, value)                                   
          }} 

          className='anchor-button'>
            Copiar
          </button>
        </>
        )
      }
    },
    {
      name: "code",
      label: "QR code",
      options: {     
        sort: false,
        customBodyRender: (value, tableInfo) => {
          const { rowData, columnIndex } = tableInfo;
          return (<button onClick={() => showQrCode(value, rowData[1])} className='anchor-button'>Ver</button>
          )
        } 
      }
    }
  ]
}

const getMuiTheme = () => {
  createMuiTheme({
    typography: {
      fontFamily: '"Montserrat"',
      fontSize: 14
    },
    overrides: {
      typography: {
        fontFamily: '"Montserrat"',
        fontSize: 14
      },
      MUIDataTable: {
        root: {
          fontSize: 44
        }
      },
      MuiTypography: {
        root: {
          fontFamily: '"Montserrat"',
          fontSize: 14
        }
      },
      MUIDataTableBodyCell: {
        root: {
          fontSize: 14,
          fontFamily: "Montserrat",
          lineHeight: "20px",
          fontWeight: "500",
          color: "#616161",
          textAlign: "left"
        }
      },
      MUIDataTableHeadCell: {
        root: {
          fontSize: 14,
          fontFamily: "Montserrat",
          lineHeight: "20px",
          fontWeight: "600",
          color: "#414141",
          textAlign: "left"
        }
      }
    }
  });
}


export { tableFunctions, getMuiTheme }