/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Grid } from "react-bootstrap";

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <Grid fluid>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}} className="copyright pull-right">
            <span>

          <a className="optanon-show-settings optanon-toggle-display" style={{cursor: 'pointer'}}>Cookies que utilizamos</a>
            </span>

          <span className="copyright pull-right">
            {new Date().getFullYear()}{" "}
            <a href="https://www.agxsoftware.com/">
              AGX Software ©
            </a>
          </span>
          </div>      
        </Grid>
      </footer>
    );
  }
}

export default Footer;
