import styled from  'styled-components'

export const Wrapper = styled.div`
    margin: 0;
    display: flex;    
    flex-direction: column;    
    justify-content: flex-start;
    padding: 5%;
    font-family: var(--font-family);
    height: 100%;
`

export const Content = styled.div`
    margin: 10px;
    padding: 10px;    
    margin: 15px;    
    width: 100%;    
    color: #FCFCFC;
    text-align: center;
`

export const Title = styled.h2`    
    font-size: 28px;
    font-weight: bold;
    margin: 30px 0;    
    color: ${props => props ? props.color : "black"};
`

export const SecondText = styled.span `
    font-size: 22px;
    color: ${props => props.color ? props.color : '#212121'};
    font-weight: ${props => props.bold ? 600 : 400}
`

export const Text = styled.span`
    font-size: 16px;
    color: ${props => props.color ? props.color : '#212121'};
    font-weight: ${props => props.bold ? 600 : 400}
`