import React, { PureComponent } from "react";
import { Modal, Row, Col } from "react-bootstrap/";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Button from "components/CustomButton/CustomButton";

import { TextField } from "@material-ui/core";
import InputMask from "react-input-mask";
import mainApi from "services/mainApi";
import { ConfirmationAlert, Dialog } from "./Alert/CustomAlert";
import InputMuiField from 'components/InputMuiField';

const env = process.env.REACT_APP_ENV;

const MuiTextField = (props) => (
  <TextField
    InputProps={{
      classes: {
        input: "mui-input-control",
        root: "mui-input-control",
      },
    }}
    InputLabelProps={{
      classes: {
        root: "mui-input-control mui-label ",
      },
    }}
    fullWidth
    variant="outlined"
    {...props}
  >
    {props.children}
  </TextField>
);

class InviteModal extends PureComponent {
  constructor(props) {
    super(props);
    let url;
    switch (env) {
      case "production":
        url = "https://afiliados.pan.agxsoftware.com";
        break;
      case "development":
        url = "https://homologacaoindiqueopan.agxsoftware.com";
        break;
      case "local":
        url = "http://10.10.0.141:3000";
        break;
      default:
        url = "http://10.10.0.141:3000";
    }
    this.state = {
      url,
      phone: "",
      rawPhone: "",
      email: "",
      shareWithWhatsApp: "",
      generatedLink: "",
      loadingMail: false,
      swalMessage: {
        Confirmation: false,
        Dialog: false,
        title: '',
      },
    };
    this.cpfRef = React.createRef();
  }

  setMessage = (options) => {
    const { Confirmation, Dialog, type, title, message, callback } = options;
    this.setState({
      swalMessage: {
        Confirmation: Confirmation || false,
        Dialog: Dialog || false,
        type,
        title,
        message,
        callback,
      },
    });
  };

  seachAndUpgrade = async (confirm) => {
    const { agency } = this.props;
    if (this.cpfRef?.current) {
      const cpf = this.cpfRef.current.value.replace(/\D/g, "");
      try {
        const response = await mainApi.post(
          "/invite/administrator",
          { cpf, agencyId: agency, confirm }
        );
        const { agencyName, userName } = response.data.data;
        if (!confirm) {
          this.setMessage({
            Confirmation: true,
            type: "info",
            title: "Confirmar",
            message: `Deseja tornar ${userName} administrador de ${agencyName} ?`,
            callback: () => {
              this.seachAndUpgrade(true);
            },
          });
          return
        }

        this.setMessage({
          Dialog: true,
          type: "success",
          title: "Sucesso!",
          message: `${userName} agora é Administrador de ${agencyName} !`,
          callback: () => {
            this.cpfRef.current.value = ''
          },
        });

      } catch (err) {
        if(err.response?.data?.message){
          this.setMessage({
            Dialog: true,
            type: "warning",
            title: "Atenção!",
            message: err.response.data.message,
            callback: () => {},
        })}
    }}
  };

  sentEmail = async () => {
    const { inviteToken } = this.props
    const { email } = this.state

    this.setState({ loadingMail: true })
    try {
      const response = await mainApi.post('invite/administrator/mail',
        { email, agencyToken: inviteToken },
      )
      this.setMessage({
        Dialog: true,
        type: "success",
        title: "Sucesso!",
        message: response.data.message,
        callback: () => {},
      });
    } catch(err) {
      if(err.response?.data?.message){
        this.setMessage({
          Dialog: true,
          type: "warning",
          title: "Atenção!",
          message: err.response.data.message,
          callback: () => {},
      })}
    }
    this.setState({ loadingMail: false })

  }

  linkWhatsCompose = (event) => {
    const { userData, inviteToken, agencyName } = this.props
    const { url } = this.state

    const rawPhone = event.target.value.replace( /\D/g, "" );

    let shareWithWhatsApp;

    if(['corporation', 'subcorp', 'master', 'submaster'].includes(userData.level)) {
      shareWithWhatsApp = `https://api.whatsapp.com/send?phone=55${rawPhone}&text=Você foi convidado(a) por ${
        userData && userData.name
      }, para ser um administrador da loja ${agencyName}, cadastre-se em: ${url}/signup?agencyToken=${inviteToken}`;
    }

    this.setState({
      phone: event.target.value,
      rawPhone,
      shareWithWhatsApp
    });
  }

  textField = (label) => {
    return (
      <TextField
        label={label}
        InputProps={{
          classes: {
            input: "mui-input-control",
            root: "mui-input-control",
          },
        }}
        InputLabelProps={{
          classes: {
            root: "mui-input-control mui-label",
          },
        }}
        fullWidth
        variant="outlined"
      />
    )
  }

  render() {
    const { inviteToken, show, onHide } = this.props;
    const { url, swalMessage, phone, email, shareWithWhatsApp, rawPhone, loadingMail } = this.state;

    return (
      <>
        <ConfirmationAlert
          type={swalMessage.type}
          show={swalMessage.Confirmation}
          title={swalMessage.title || ''}
          onConfirm={() => {
            this.setMessage({Confirmation: false});
            swalMessage.callback();
          }}
          onCancel={() => {
            this.setMessage({Confirmation: false});
          }}
          text={swalMessage.message}
        />

        <Dialog
          type={swalMessage.type}
          show={swalMessage.Dialog}
          title={swalMessage.title || ''}
          onConfirm={() => {
            this.setMessage({Dialog: false});
            swalMessage.callback();
          }}
          text={swalMessage.message}
        />

        <Modal
          show={show}
          onHide={onHide}
          dialogClassName="modal-90w"
          aria-labelledby="agency-data"
        >
          <Modal.Header closeButton>
            <Modal.Title id="agency-data-title">
              Associar um administrador
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>

            <Row className="pb-2" style={{ marginLeft: "0" }}>
              <h6 className="pl-3">Convidar um administrador</h6>
              <Col md={9}>
                <input
                  style={{ marginLeft: 0 }}
                  className="form-control modal-input"
                  value={`${url}/signup?agencyToken=${inviteToken}` || ""}
                  readOnly
                />
              </Col>

              <Col md={3}>
                <CopyToClipboard
                  text={`${url}/signup?agencyToken=${inviteToken}`}
                >
                  <Button bsStyle="primary" fill style={{ width: "100%" }}>
                    Copiar
                  </Button>
                </CopyToClipboard>
              </Col>
            </Row>

            <Row className="pb-2" style={{ marginLeft: "0" }}>
              <h6 className="pl-3">Convidar por whatsapp</h6>
              <Col md={9}>

                <InputMask
                  mask="(99) 99999-9999"
                  value={phone}
                  onChange={(event) => { this.linkWhatsCompose(event) }}
                >
                  {() => (
                    this.textField("Celular")
                  )}
                </InputMask>

              </Col>

              <Col md={3}>
                <Button
                  disabled={rawPhone.length < 11}
                  bsStyle="primary"
                  fill
                  style={{ width: "100%" }}
                  onClick={() => window.open(shareWithWhatsApp, '_blank')}>
                  Enviar
                </Button>
              </Col>
            </Row>

            <Row className="pb-2" style={{ marginLeft: "0" }}>
              <h6 className="pl-3">Convidar por email</h6>
              <Col md={9}>
                <InputMuiField
                  label="Email"
                  value={email}
                  onChange={(text) => {
                    this.setState({ email: text.target.value })
                  }}
                >
                  Email
                </InputMuiField>
              </Col>

              <Col md={3}>
                <Button
                  bsStyle="primary"
                  fill
                  onClick={() => this.sentEmail()}
                  disabled={loadingMail}
                  style={{ width: "100%" }}
                >
                  {loadingMail ? "Enviando.." : "Enviar" }
                </Button>
              </Col>
            </Row>

            <Row className="pb-2" style={{ marginLeft: "0" }}>
              <h6 className="pl-3">atribuir a um usuário já existente: </h6>
              <Col md={9}>
                <InputMask
                  mask="999.999.999-99"
                  placeholder="Insira um CPF"
                  maskChar=""
                  pattern="[0-9]*"
                  inputMode="numeric"
                  className="form-control input-login modal-input"
                >
                  {() => <MuiTextField label="CPF" inputRef={this.cpfRef} />}
                </InputMask>
              </Col>

              <Col md={3}>
                <Button
                  bsStyle="primary"
                  fill
                  block
                  onClick={() => this.seachAndUpgrade(false)}
                >
                  Buscar
                </Button>
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <button className="btn btn-fill" style={{ width: 115, backgroundColor: "#CBCBCB", border: 0 }} onClick={() => {
              this.setState({ email: '' })
              onHide()
            }}>
              Cancelar
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default InviteModal;
