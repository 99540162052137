import React from 'react'

const disabledDashboard = {
  path: "#",
  name: "Visão Geral",
  icon: "chart-pie",
  component: () => <></>,
  layout: "",
  disabled: true,
  key: 999
};

const disabledCaptacao = {
  path: "#",
  name: "Captação",
  icon: "link-default",
  component: () => <></>,
  layout: "",
  disabled: true,
  key: 998
};

const disabledMeios = {
  path: "#",
  name: "Meios",
  icon: "bank-check",
  component: () => <></>,
  layout: "",
  disabled: true,
  key: 997
};

const disabledTools = {
  path: "#",
  name: "Ferramentas",
  icon: "store",
  component: () => <></>,
  layout: "",
  disabled: true,
  key: 996
};

const disabledFunil = {
  path: "#",
  name: "Funil",
  icon: "filter",
  component: () => <></>,
  layout: "",
  disabled: true,
  key: 995
};

const disabledBD = {
  path: "#",
  name: "B.D.",
  icon: "document",
  component: () => <></>,
  layout: "",
  disabled: true,
  key: 994
};

const disabledStores = {
  path: "#",
  name: "Lojas",
  icon: "store",
  component: () => <></>,
  layout: "",
  disabled: true,
  key: 994
};

const disabledIntern = {
  path: "#",
  name: "Equipe interna",
  icon: "people",
  component: () => <></>,
  layout: "",
  disabled: true,
  key: 993
};

const disabledExternal = {
  path: "#",
  name: "Equipe externa",
  icon: "people",
  component: () => <></>,
  layout: "",
  disabled: true,
  key: 889
};

const disabledIntregations = {
  path: "#",
  name: "Integrações",
  icon: "link-add",
  component: () => <></>,
  layout: "",
  disabled: true,
  key: 992
};

const disabledIA = {
  path: "#",
  name: "IA",
  icon: "lamp",
  component: () => <></>,
  layout: "",
  disabled: true,
  key: 992
};

const disabledDecision = {
  path: "#",
  name: "Decisão",
  icon: "person-checked",
  component: () => <></>,
  layout: "",
  disabled: true,
  key: 991
};

const disabledAlert = {
  path: "#",
  name: "Alerta",
  icon: "info",
  component: () => <></>,
  layout: "",
  disabled: true,
  key: 990
};

export default [
  disabledDashboard,
  disabledCaptacao,
  disabledMeios,
  disabledTools,
  disabledFunil,
  disabledBD,
  disabledStores,
  disabledIntern,
  disabledExternal,
  disabledIntregations,
  disabledIA,
  disabledDecision,
  disabledAlert
]