import agxMinimalLogo from "assets/img/agx/minimal-agx-logo.png"
import logo_alt from "assets/img/agx/agx-white-logo.png"
import logoAgx from "assets/img/agx/logo-agx.png";


const config = {
  CLIENT: "agx",
  NAME: "AGX Software",
  PAGE_TITLE: "Programa de Indicações AGX Software",
  SIGNUP_TITLE: "Cadastre-se e faça parte do ",
  SIGNUP_TITLE_ACCENT: "Programa de indicações AGX",
  SIGNUP_FIRST_STEP_TITLE: "Dados Bancarios",
  SIGNUP_FIRST_STEP_BODY: "Para fazer parte do programa é necessário ter uma conta. Após inserir o seus dados bancarios clique em “Usar essa conta”.",
  SIGNUP_LINK_TEXT: "",
  SIGNUP_SECOND_STEP_TITLE: "Te conhecendo melhor",
  SIGNUP_SECOND_STEP_BODY: "Agora conclua seu cadastro inserindo seus dados.", 
  IMG_PF: '',
  IMG_PJ: '',
  LOGO: logoAgx,
  PRODUCT_LOGO: logoAgx,
  MINIMAL_LOGO: agxMinimalLogo,
  LOGO_ALT: logo_alt,
  CONTACT_PF: 'suporte@agxsoftware.com',
  CONTACT_PJ: 'suporte@agxsoftware.com',
  COLORS: {
    primary_color: '#212121',
    primary_hover_color: '#313131',
    primary_pressed_color: '#414141',
    primary_clear_color: 'rgba(32, 32, 32, 0.07)',
    secondary_color: '#002457',
    danger_color: '#FF3545',
    danger_pressed_color: '#FF3545',
    dark_color: '#121212',
  },
  STYLE: {
    picto: null,
    font_family: '"Roboto"',
    button_border_radius: '4px'
  },
  hideLogs: ['production', 'development']
}


export default config