import React, { Component } from "react";
import "assets/css/styles.css";

import kvInformativePf from "assets/img/cpf-person.png";
import kvInformativePj from "assets/img/cnpj-people.png";
import logoP from "assets/img/pan-logo-p.png";
import logo from "assets/img/pan_white_logo.png";

import config from 'utils/serverVars';

import NewPasswordForm from "components/NewPasswordForm";
import ModalTermFGTS from "components/ModalTermFGTS/ModalTermFGTS";
import getUserData from "utils/checkUser";
import { FGTSCorpId } from "utils/CommonData";
import clubeAmigoBanner from 'assets/img/clubeAmigo/clubeAmigoBanner.png'
import TermsModal from "components/TermsModal/TermsModal";
import { ISClubeAmigo } from "utils/environment";
import campanhaWhatsappBanner from 'assets/img/pan_cover_campanha_whatsapp.png'

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banner: config.IMG_PF,
      loginType: "cnpj",
      term: true,
      userData: {}
    };
  }

  requestUserData = async () => {
    const userData = await getUserData(this.props.history)
    this.setState({ userData })
  }


  closeterms = () => {
    this.setState({ term: false })
  }

  componentDidMount = () => {
    this.requestUserData()
  }

  render() {
    const {
      userData
    } = this.state
    const campanha = localStorage.getItem('@uxtech-mlm/campanha')
    let banner, panLogo;
    let path = this.props.location.pathname.split("/")[1];
    const IS_WHATSAPP = campanha === "whatsapp"
    if (ISClubeAmigo) {
      banner = clubeAmigoBanner
      panLogo = logo;
    }
    else if (path === "cnpj") {
      banner = config.IMG_PJ;
      panLogo = logoP;
    } else if (path === "cpf") {
      banner = config.IMG_PF;
      panLogo = logo;
    } else {
      banner = config.IMG_PF;
      panLogo = logo;
      path = ''
    }
    if(IS_WHATSAPP) banner = campanhaWhatsappBanner
    return (
      <>
        {
          (userData?.corpId === FGTSCorpId && !userData?.firstLogin && ['administrator'].includes(userData?.level)) &&
          <ModalTermFGTS
            show={this.state.term}
            handleClose={this.closeterms}
          />
        }
        <div className="grid-parent">
          <div className={config.CLIENT === 'pan' ? 'kv-informative' : 'kv-informative agx-bg'}>
            {config.CLIENT === 'pan' &&
              <>
                <img className="login-pan-logo" src={panLogo} alt='' />
                <div className="clubeAmigo_continer">
                  <img className="people" src={banner} alt='' />
                  {ISClubeAmigo && <TermsModal classNameLink={'clubeAmigo_terms'} />}
                </div>
              </>}
          </div>
          <div className="kv-form-login">
            <NewPasswordForm
              history={this.props.history}
              {...this.props}
              path={path}
            />
          </div>
        </div>
      </>
    );
  }
}

export default ResetPassword;
