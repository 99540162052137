import React from "react";

import InteractiveMap from "components/InteractiveMap";
import "assets/css/styles.css";
import getUserData from "utils/checkUser.js";

export default class views extends React.Component {
  
  componentDidMount = async () => {
    const userData = await getUserData(this.props.history);    

    window.digitalData = {
      "site": {
          "nome": "Banco Pan"
      },
      "page": {
          "secao": "IndiqueoPan",
          "estadoLogin": "LG",
          "nome": "BP:IndiqueOPan:Mapa",
      },
      "user": {
          "crmid": userData.id,
          "tipo": userData.brType
      }
    }
    
    var evt = new CustomEvent('event-view-start');
    document.body.dispatchEvent(evt);
  }

  render() {
    return (
      <>      
        <div className="content">          
            <InteractiveMap routeKey={this.props.routeKey} />
        </div>
      </>
    );
  }
}
