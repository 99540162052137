import React, { Component } from "react";
import { Grid } from "react-bootstrap";

import mainApi from "services/mainApi";
import getUserData from "utils/checkUser";
import { CircularProgress } from "@material-ui/core";

import MUIDataTable from "mui-datatables";
import getMuiTheme from "utils/getMuiTheme";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { LoadingAlert } from "components/Alert/CustomAlert";
import { Link } from 'react-router-dom';
import { introSystem } from 'utils/introToSystem';
import { translateLevel } from "utils/translate";

export default class TeamListManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      agencies: [],
      userData: {},
      page: 1,
      totalPages: 1,
      totalDocs: 1,
      limit: 10,
      swalShowLoading: true
    };
  }

  getUser = async () => {
    const userData = await getUserData(this.props.history);

    this.setState({ userData });
  };

  setQuery = () => {
    const { page, limit } = this.state

    let query = ''

    query += `?page=${page}`

    if(limit) query += `&limit=${limit}`

    return query
  }

  getAgencies = async () => {
    const query = this.setQuery()

    this.setState({ swalShowLoading: true })
    const response = await mainApi.get(`/agencies${query}`);
    const agencies = response.data.agencies.docs;

    this.setState({
      agencies,
      page: response.data.agencies.page,
      totalDocs: response.data.agencies.totalDocs,
      totalPages: response.data.agencies.totalPages
    });

    this.getAgenciesOperations();
    this.setState({ swalShowLoading: false })
  };

  getAgenciesOperations = async () => {
    const { agencies } = this.state

    const query = this.setQuery()

    const validate = agencies
    const response = await mainApi.get(`/agencies/operations${query}`);

    response.data.agencies.docs.forEach((values, index) => {
      const data = {
        administrator: values.administrator,
        supervisors: values.supervisors,
        sellers: values.sellers
      };

      validate[index] = Object.assign(validate[index], data)
    });

    this.setState({ agencies: validate })
  }

  fixContentHeigth = () => {
    const content = document.getElementsByClassName("content");
    if (content[0]) {
      content[0].style.minHeight = `${window.innerHeight - 140}px`;
    }
  }

  componentDidMount = async () => {

    this.fixContentHeigth()

    await this.getUser();

    const { routeKey } = this.props
    const { userData } = this.state

    window.digitalData = {
      "site": {
          "nome": "Banco Pan"
      },
      "page": {
          "secao": "IndiqueoPan",
          "estadoLogin": "LG",
          "nome": "BP:IndiqueOPan:Equipe",
      },
      "user": {
          "crmid": userData.id,
          "tipo": userData.brType
      }
    }

    var evt = new CustomEvent('event-view-start');
    document.body.dispatchEvent(evt);

    await this.getAgencies();
    introSystem(routeKey);
  };

  changePage(newPage) {
    const { totalPages } = this.state
    if (newPage >= 0 && newPage < totalPages) {
      this.setState({ page: newPage + 1 }, () => {
        this.getAgencies()
      })
    }
  }

  changeLimit(newLimit) {
    this.setState({ limit: newLimit }, () => {
      this.changePage(0)
    })
  }

  loadingBodyRender = (dataIndex, rowIndex) => {
    return (
      <>
        {dataIndex !== undefined ?
          dataIndex
        :
        <CircularProgress
          size={24}
          style={{
            marginLeft: 15,
            position: "relative",
            top: 4
          }}
        />
        }
      </>
    )
  }

  render() {
    const { agencies, totalDocs, swalShowLoading, userData } = this.state;

    const options = {
      selectableRows: false,
      rowsPerPage: 10,
      filterType: "dropdown",
      responsive: "scrollMaxHeight",
      count: totalDocs,
      serverSide: true,
      pagination: true,
      print: false,
      filter: false,
      search: false,
      download: false,
      rowsPerPageOptions: [10, 15, 30],
      textLabels: {
        body: {
          noMatch: "Desculpe, nenhum dado corresponde a pesquisa",
        },
        pagination: {
          next: "Próxima Página",
          previous: "Pagina Anterior",
          rowsPerPage: "Linhas por página:",
          displayRows: "de",
        },
        toolbar: {
          search: "Procurar",
          downloadCsv: "Download CSV",
          print: "Imprimir",
          viewColumns: "Mostrar Colunas",
          filterTable: "Filtrar Tabela",
        },
        filter: {
          title: "FILTROS",
          reset: "limpar",
          all: "Todos",
        },
        viewColumns: {
          title: "Mostrar Colunas",
        },
      },
      onTableChange: (action, tableState) => {
        switch (action) {
          case 'changePage':
            this.changePage(tableState.page)
            break;
          case 'changeRowsPerPage':
            this.changeLimit(tableState.rowsPerPage)
            break;
          default:
            break;
        }
      }
    }

    const columns = [
      {
        name: '_id',
        label: '#',
        options: {
          display: false,
          viewColumns: false,
          filter: false
        }
      },
      {
        name: 'name',
        label: 'Nome'
      },
      {
        name: 'administrator',
        label: translateLevel('administrator', userData?.corpId),
        options: {
          sort: false,
          hint:  `Quantidade de ${translateLevel('administrator', userData?.corpId)}`,
          customBodyRender: (dataIndex, rowIndex) => {
            const admName = rowIndex.rowData[2];

            return (
              <>
                {
                  dataIndex !== undefined ?
                    <>
                      {typeof admName === "object" ? (
                        admName.name
                      ) : (
                        admName
                      )}
                    </>
                  :
                    <CircularProgress
                      size={24}
                      style={{
                        marginLeft: 15,
                        position: "relative",
                        top: 4
                      }}
                    />
                }
              </>
            );
          }
        }
      },
      {
        name: `supervisors`,
        label:  translateLevel('supervisor', userData?.corpId),
        options: {
          sort: false,
          hint: `Quantidade de ${translateLevel('supervisor', userData?.corpId)}se`,
          customBodyRender: this.loadingBodyRender
        }
      },
      {
        name: 'sellers',
        label: translateLevel('seller', userData?.corpId),
        options: {
          sort: false,
          hint: `Quantidade de ${translateLevel('seller', userData?.corpId)}es`,
          customBodyRender: this.loadingBodyRender
        }
      },
      {
        name: 'actions',
        label: 'Ações',
        options: {
          sort: false,
          hint: "Clique para gerenciar a equipe da loja correspondente",
          customBodyRender: (dataIndex, rowIndex) => {
            return (
              <Link to={`/admin/teams?agencyId=${rowIndex.rowData[0]}`}>Gerenciar</Link>
            )
          }
        }
      }
    ]

    return (
      <>
        <LoadingAlert show={swalShowLoading} />

        <div className="content">
          <Grid fluid>

            <MuiThemeProvider theme={getMuiTheme}>
              <MUIDataTable
                columns={columns}
                options={options}
                data={agencies}
              />
            </MuiThemeProvider>

          </Grid>
        </div>
      </>
    );
  }
}