import React from 'react'

import { Wrapper, Title, Description, Percentage, CenterFlex, Number, WrapperColor } from './styled'

function PercentageCard({ title, secondTitle, number, secondNumber, color, secondColor, description, percentage }) {

  return (
    
    <Wrapper>

      <WrapperColor color={color} radius="top">
        <div className="text-center">
          <Title>{title || "Agosto"}</Title>
        </div>              
        <CenterFlex>
          <div className="flex-column p-3">
            <Description>Contas abertas</Description>
            <CenterFlex>
              <Number>{ number || 0 }</Number>
            </CenterFlex>
          </div>
          
          <div className="flex-column p-3">
            <Description>Crescimento</Description>
            <CenterFlex>
              <Percentage percentage={percentage}>{percentage?.toFixed(2) || 0 }%</Percentage>
            </CenterFlex>
          </div>        
        </CenterFlex>

      </WrapperColor>

      <WrapperColor color={secondColor} radius="bottom">        
        <div className="text-center">
          <Title>{secondTitle || "Setembro"}</Title>
        </div>              

        <CenterFlex>
          <div className="flex-column p-3">
            <Description>Contas abertas</Description>
            <CenterFlex>
              <Number>{ secondNumber || 0}</Number>
            </CenterFlex>
          </div>        
        </CenterFlex>      
      </WrapperColor>      

    </Wrapper>
  )
}

export default PercentageCard