import React from 'react'
import { createMuiTheme } from "@material-ui/core/styles";
import { formatToBr } from 'utils/formaterDate';
import formaters from 'utils/formater'
import { numToBRL, firstAndLastWord } from 'utils/formatData'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import Toogle from 'react-toggle'
import formater from 'utils/formater'
import BasicPopover from 'components/BasicPopover/BasicPopover';
import { shortenValue } from 'utils/formatData';
import { translateLevel } from 'utils/translate';
import { translateLevelAction } from 'utils/translate';
import { ClubeAmigoId } from 'utils/CommonData';
import { Link } from 'react-router-dom'
import { ISClubeAmigo } from "utils/environment";

const tableFunctions = {
  columns: ({ buttonToEditSeller, showRemoveConfirmation, admin, buttonChangeAgency, showBlockConfirmation, strictId, level }) => [
    {
      name: "_id",
      label: "ID",
      options: {
        display: false,
      }
    },
    {
      name: "supervisorId",
      label: "SupervisorId",
      options: {
        display: false,
      }
    },
    {
      name: "name",
      label: "Nome",
      options: {
        setCellProps: () => ({ className: 'no-wrap' }),
        hint: "Nome do vendedor",
        sort: true,
        customBodyRender: (value) => {
          const formatedValue = firstAndLastWord(value)
          const shortenName = shortenValue(formatedValue)

          return shortenName ?
            <BasicPopover
              noWrap={true}
              text={formatedValue}
              buttonText={shortenName}
            />
            : formatedValue
        }
      }
    },
    {
      name: "reCode",
      label: "RE",
      options: {
        display: [ClubeAmigoId].includes(strictId),
        setCellProps: () => ({ className: 'no-wrap' }),
        sort: false,
        hint: "RE",
        customBodyRender: (value) => value || 'n/a'
      }
    },
    {
      name: "cpf",
      label: "CPF",
      options: {
        setCellProps: () => ({ className: 'no-wrap' }),
        sort: false,
        hint: "CPF do vendedor",
        customBodyRender: (value) => value ? formaters.toCPF(String(value)) : 'n/a'
      }
    },
    {
      name: "level",
      label: "Nível",
      options: {
        sort: false,
        hint: "Nível do afiliado no sistema",
        customBodyRender: (value) => {
          return translateLevel(value, strictId)
        }
      }
    },
    {
      name: "phone",
      label: "Telefone",
      options: {
        sort: false,
        hint: "Telefone do afiliado",
        setCellProps: () => ({ className: 'no-wrap' }),
        customBodyRender: (value) => {

          if (!value) return "n/a"
          return formater.formatPhone(value)
        }
      }
    },
    {
      name: "email",
      label: "Email",
      options: {
        sort: false,
        hint: "Email do afiliado",
        setCellProps: () => ({ className: 'no-wrap' }),
        customBodyRender: (value) => {
          const shortenEmail = shortenValue(value, 20)

          return shortenEmail ?
            <BasicPopover
              noWrap={true}
              text={value}
              buttonText={shortenEmail}
            />
            : value
        }
      }
    },
    {
      name: "createdAt",
      label: "Cadastro",
      options: {
        sort: true,
        hint: "Data que o afiliado realizou o cadastro.",
        customBodyRender: (value) => {
          return formatToBr(value)
        }
      }
    },
    {
      name: "datas.converted",
      label: "Qtde. vendido",
      options: {
        sort: true,
        hint: "Quantidade que o vendedor vendeu neste mês.",
        customBodyRender: (value, rowIndex) => {
          return value || 0
        }
      }
    },
    ISClubeAmigo ?
      {
        name: '',
        label: 'Relatório de vouchers',
        options: {
          customBodyRender: (value, rowIndex) => (
            ['seller'].includes(rowIndex?.rowData[5]) ? (
              <Link
                to={{
                  pathname: 'user-voucher-report',
                  state: rowIndex?.rowData[4]
                }}
              >
                Acessar
              </Link>
            ) : 'n/a'
          )
        }
      } : {
        name: '',
        options: {
          display: false
        }
      },
    {
      name: "supervisorName",
      label: translateLevelAction('supervisor', strictId),
      options: {
        sort: false,
        hint: `${translateLevel('supervisor', strictId)} do ${translateLevel('seller', strictId)}`,
        customBodyRender: (value, rowIndex) => {
          return value ?
            <>
              <Link to={`/admin/teams/supervisor?supervisorId=${rowIndex.rowData[1]}`}>{firstAndLastWord(value)}</Link>
              {
                ![ClubeAmigoId].includes(strictId) &&
                <FontAwesomeIcon onClick={() => showRemoveConfirmation(rowIndex.rowData[0])}
                  style={{ cursor: 'pointer', marginLeft: '5px' }}
                  icon={faTrashAlt}
                  color='red'
                />
              }
            </>
            : showTeamEdit(buttonToEditSeller, rowIndex)
        }
      }
    },
    {
      name: 'unlink',
      label: 'Desvinculado',
      options: {
        sort: false,
        setCellProps: () => ({ className: 'no-wrap' }),
        customBodyRender: (value) => {

          return value ? "Sim" : "Não"
        }
      }
    },
    {
      name: "blocked",
      label: "Bloq.",
      options: {
        sort: true,
        hint: `${translateLevel('supervisor', strictId)} do ${translateLevel('seller', strictId)}`,
        customBodyRender: (value, rowIndex) => {
          const id = rowIndex.rowData[0]

          return (
            <>
              <Toogle
                checked={value}
                onChange={() => showBlockConfirmation(id, value)}
                className='custom-toggle-class'
              />
            </>
          );
        },
      },
    },
    {
      name: "",
      label: "Trocar loja",
      options: {
        sort: false,
        display: (!admin && ![ClubeAmigoId].includes(strictId)) || ['corporation'].level,
        hint: `Clique no botão para trocar a loja do ${translateLevel('seller', strictId)}`,
        customBodyRender: (value, rowIndex) => {
          return !['administrator'].includes(rowIndex?.rowData[5]) ? buttonChangeAgency(rowIndex.rowData[0]) : '-'
        }
      }
    }
  ]
}

const getMuiTheme = () => {
  createMuiTheme({
    typography: {
      fontFamily: '"Montserrat"',
      fontSize: 14
    },
    overrides: {
      typography: {
        fontFamily: '"Montserrat"',
        fontSize: 14
      },
      MUIDataTable: {
        root: {
          fontSize: 44
        }
      },
      MuiTypography: {
        root: {
          fontFamily: '"Montserrat"',
          fontSize: 14
        }
      },
      MUIDataTableBodyCell: {
        root: {
          fontSize: 14,
          fontFamily: "Montserrat",
          lineHeight: "20px",
          fontWeight: "500",
          color: "#616161",
          textAlign: "left"
        }
      },
      MUIDataTableHeadCell: {
        root: {
          fontSize: 14,
          fontFamily: "Montserrat",
          lineHeight: "20px",
          fontWeight: "600",
          color: "#414141",
          textAlign: "left"
        }
      }
    }
  });
}

const showTeamEdit = (buttonToEditSeller, rowIndex) => {
  switch (rowIndex.rowData[5]) {
    case 'seller':
      return buttonToEditSeller(rowIndex.rowData[0])
    case 'supervisor':
      return <Link to={`/admin/teams/supervisor?supervisorId=${rowIndex.rowData[0]}`}>Gerenciar</Link>
    default:
      return 'n/a'
  }
}

const levelOptions = (strictId) => [
  { value: '', label: 'Todos' },
  { value: 'seller', label: translateLevel('seller', strictId) },
  { value: 'supervisor', label: translateLevel('supervisor', strictId) },
  // { value: 'administrator', label: 'Administrador' }
]

const typeAffiliate = [
  { value: '', label: 'Todos' },
  { value: 'true', label: 'Individual' },
  { value: 'false', label: 'Lojista' },
]

export { tableFunctions, getMuiTheme, levelOptions, typeAffiliate }

