const dictonary = {
  "C#": "ã",
  "C)": "é",
  "C:": "ú",
  "C'": "ç",
  "C!": "á",
  "C-": "í",
  "C*": "ê",
  "C4": "ô",
  "C3": "ó",
  "C\t": "É",
  "C\r": "Í",
  "C\u0013": "Ó",
  "C\u001a": "Ú",
  "C\u0003": "Ã",
  "\u0007": "Ç"
}

const dictKeys = Object.keys(dictonary)

export const normalize = (text) => {
  if(!text) return undefined

  let newText = text

  dictKeys.forEach(key => {
    newText = newText.replaceAll(key, dictonary[key])
  })
  
  return newText
}