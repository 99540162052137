import { Line } from "react-chartjs-2"
import Card from "components/Card/Card"
import React from "react"

import { graphOptions } from './common'
import MahoeIcon from "components/MahoeIcons";


function ClicksChart({ bigger, data, onReload }) {

  return (
    <Card
      style={{ minHeight: bigger ? 320 : 370 }}
      statsIcon="fa fa-history"
      id="chartHours"
      title="Status de Interação"
      category={"Performance Média de Clicks na Semana Atual"}
      content={
        data ?
          <Line
            data={data}
            height={300}
            options={{
              legend: {
                position: "top",
                align: "start",
                labels: { usePointStyle: true },
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      stepSize: 1,
                      beginAtZero: true,
                    },
                  },
                ],
              },
              responsive: true,
              maintainAspectRatio: false,
              ...graphOptions,
            }}
          />
          : (
            <div className="search-top-5-loading" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              {
                onReload
                  ? <MahoeIcon
                    aria-describedby={"iconTooltip"}
                    name="reload"
                    size="20px"
                    fill="#039BE5"
                    style={{ cursor: 'pointer' }}
                    onClick={() => onReload()}
                  />
                  : <MahoeIcon
                    aria-describedby={"iconTooltip"}
                    name="info"
                    size="20px"
                    fill="red"
                  />
              }
              <h4 style={{ marginTop: 10 }}>Falha ao buscar dados</h4>
            </div>
          )
      }
    />
  );
}

export default ClicksChart;
