import React, { useState, useEffect, useRef } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { ModalBodyWrapper } from './styled'
import { Grid, Row, Col } from 'react-bootstrap';
import { userProperties } from 'utils/translate'
import { Storefront } from "@material-ui/icons";
import mainApi from "services/mainApi";

function OperatorModal({ open, modalData, handleClose, customAction }) {

  const { id, corporationId, corporationName, level } = modalData

  let keysArray = Object.keys(modalData);

  const disabledElements = ["data", "id", "agencyId", "agencyCorporationId", "corporationHasManager", "corporationId"]

  const ignoredCorpIdLevels = ["Corporação"]
  const customBottom = () => {
    if(corporationId && !ignoredCorpIdLevels.includes(level)){
      return (
        <button 
          className="btn btn-fill btn-mlm user-search-corp"
          onClick={() => {customAction(corporationId); handleClose();}}>
          <Storefront style={{fontSize: "40px"}}/>
            {corporationName}
          </button>
      )
    }
  }

  return (
    <>
      <Modal
        show={open}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        bsSize="large"
      >
        <Modal.Header closeButton >
          <Modal.Title>{modalData.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <ModalBodyWrapper>
            {
              keysArray.map((element, index) => (
                modalData[element] && !disabledElements.includes(element) ?
                  <Row key={index} className="user-info-row">
                    <Col className="user-info-property" md={4}>
                      <b>{userProperties(element)}</b>
                      {/* {element} */}
                    </Col>
                    <Col md={8} className="user-info-value">
                      {modalData[element]}
                    </Col>
                  </Row> : ''
              ))}

            <Row className="user-info-row">
              { customBottom() }
            </Row>
          </ModalBodyWrapper>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-fill btn-mlm" onClick={handleClose}>
            Fechar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default OperatorModal