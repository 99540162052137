import React, { useState } from "react";
import { Button } from "react-bootstrap/";
import { Select, MenuItem } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { translateLevelAction, translateLevel } from "utils/translate";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import getUserData from "utils/checkUser";

const SupervisorsModal = ({
  show,
  supervisors = [],
  actualSupervisor = "",
  onSelect,
  onChangeLevel,
  onClose,
  ...rest
}) => {
  const availableSupervisors = supervisors.filter(
    (supervisor) => supervisor._id !== actualSupervisor
  );

  const [userData, setUserData] = useState({})
  const [selectedSupervisor, setSelectedSupervisor] = useState("none");
  const history = useHistory()

  async function updateUserData () {
    const userData =  await getUserData(history)
    setUserData(userData)
  }

  useEffect(() => {
    updateUserData()
  }, [])

  const DialogTitle = (props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            style={{
              position: "absolute",
              right: 1,
              top: 1,
              color: "#717171",
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };

  const close = () => {
    setSelectedSupervisor("none");
    onClose();
  };

  return (
    <Dialog
      onClose={close}
      aria-labelledby="customized-dialog-title"
      open={show}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="customized-dialog-title" onClose={close}>
        Mudar {translateLevelAction('supervisor', userData?.corpId)}
      </DialogTitle>
      <MuiDialogContent dividers>
        <Button
          fill
          block
          className="btn-fill btn-info"
          onClick={onChangeLevel}
        >
          Promover para {translateLevel('supervisor', userData?.corpId)}
        </Button>
        <br />
        <hr />

        <h4>Selecione um {translateLevel('supervisor', userData?.corpId)}:</h4>
        <br />
        <div>
          <Select
            onChange={(event) => {
              setSelectedSupervisor(event.target.value);
            }}
            value={selectedSupervisor}
            InputProps={{
              classes: {
                input: "mui-input-control",
                root: " mui-input-control",
              },
            }}
            InputLabelProps={{
              classes: {
                root: "mui-input-control mui-label ",
              },
            }}
            fullWidth
            variant="outlined"
          >
            <MenuItem key="supervisor-select-null" value="none">
              <em>Selecione um {translateLevel('supervisor', userData?.corpId)}...</em>
            </MenuItem>
            {availableSupervisors.map((supervisor) => (
              <MenuItem
                key={`supervisor-select-${supervisor._id}`}
                value={supervisor._id}
              >
                {supervisor.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <br />
        <Button
          fill
          block
          disabled={selectedSupervisor === "none"}
          className="btn-fill btn-mlm"
          onClick={() => onSelect(selectedSupervisor)}
        >
          Atribuir
        </Button>
      </MuiDialogContent>
    </Dialog>
  );
};

export default SupervisorsModal;
