import styled from 'styled-components'

export const LoadingLine = styled.div`
	height: 10px;
	width: 100%;
    background: #039be580;
    
    &:before{
        content: '';
	    display: block;
	    background-color: #039be5;
	    height: 10px;
	    width:${props => `${props.value}%`};
    }
` 