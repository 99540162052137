const corpDetailsCard = {
  image: "Logo da corporação.", 
  changeImage: "Clique caso deseje trocar a imagem da corporação.", 
  changeEmail: "Clique caso deseje resetar o email da corporação.",
  changePassword: "Clique caso deseje resetar a senha do email que esta cadastrado.",
  description: "É possível conferir os detalhes gerais sobre vendas e informações."    
}

const corpDetails = {  
  tableStore: "Nesta tabela é possível verificar dados, atribuir administrador e verificar os detalhes de cada loja. Para adicionar uma nova loja basta clicar no botão no canto superior direito e preencher as informações.",
  filters: "Caso deseje filtrar sua busca preencha os campos.",
  search: "Após preenchar os campos basta clicar no botão para pesquisar.",
  newStore: "Para adicionar uma nova loja basta clicar e preecher as informações."
}

const dashboard = {
  totalSell: "Total bruto de vendas do perfil.",
  ticketMedio: "Valor médio de vendas no período.",
  bonification: "Total gerado líquido, ou seja, aquilo que de fato foi recebido.",
  charts: "Gráficos que mostram o desempenho e vendas dos produtos.",
  chartClicks: "Gráfico mostrando as quantidades de clicks durante a semana."
}

const map = {
  mapDescription: "Ao clicar em cada um dos estados no mapa é possível verificar os dados e resultados baseado na região."
}

const paymentCorporation = {
  tableDesciption: "Informação de status e valores dos produtos, caso deseje mais informações basta passar o mouse sobre o ponto de interrogação ao lado do nome de cada coluna."
}

const paymentAffiliate = {
  tableDesciption: "Tabela com os valores a serem pagos aos afiliados, para saber mais sobre cada coluna basta passar o mouse por cima do icone de interrogação ao lado do nome da respectiva coluna."
}

const corpSelect = {
  description: "Informação geral das corporações cadastradas no sistema.",
  image: "Logo da corporação.",
  name: "Nome e descrição da corporação.",
  stores: "Quantidade de lojas.",
  links: "Links gerados.",
  total: "Valor total vendido.",
  seeMore: "Ver mais informações sobre a corporação."
}

const createCorp = {
  cnpj: "Para cadastrar uma corporação primeiro é necessário informar o CNPJ.",
  name: "Após informe o nome da corporação.",
  socialName: "Digite o nome fantasia da empresa.",
  description: "Informe a descrição referente a corporação.",
  email: "Informe o email para contato.",
  telephone: "Informe o telefone para contato.",
  percentage: "Informe a porcentagem que será destinada a corporação.",
  logo: "Clique para adicionar uma logo a corporação.",
  finisheButton: "Quando todos os dados estiverem preenchidos basta clicar no botão e cadastrar sua corporação."
}

const userListMaster = {
  table: "Tabela para filtrar todos os afiliados, caso precise mais informações sobre cada coluna basta passar o mouse sobre o icone de interrogação ao lado do nome de cada coluna, também é possível baixar do csv ao clicar no icone no canto superiror direito.",
  params: "É possível filtrar a tabela por vários parâmetros, com o intuito de tornar a busca mais precisa.",
  date: "Além dos parâmetros é possível filtrar a tabela por periodos entre datas.",
  initialRegister: "Caso deseje buscar os afiliados pela data de cadastro, informe a data inicial.",
  finalRegister: "Após informar a data inicial, informe a data final para concluir o filtro do período.",
  block: "Para buscar os afiliados bloqueados, marque esta opção.",
  initialAproved: "Caso deseje buscar os afiliados pela data de aprovação, informe a data inicial.",
  finalAproved: "Após informar a data inicial, informe a data final para concluir o filtro do período."
}

const help = {
  resetHelp: "Caso deseje resetar o o tour guiado pelas páginas basta clicar no botão rever tutorial.",
  email: "Caso necessite entrar em contato com a nossa equipe para sanar suas dúvidas basta enviar uma mensagem no email abaixo."
}

const payment = {
  table: "Tela com tabela de situação de pagamentos, é possivel verificar a informação de cada item passando o mouse por cima da interrogação ao lado do nome de cada coluna."
}

const teamListManegement = {
  table: "Tabela com informações para gerenciar as equipes. Ao clicar em gerenciar é possivel mudar o supervisor e os vendedores entre as equipes."
}

const home = {
  generateLink: "Primeiramente, para gerar o link e QRCode para você enviar ao cliente, clique no botão “Gerar Link”",
  send: "Agora que o link foi gerado, você pode escolher a forma de envio dele, seja copiando e colando para o seu cliente (Botão Copiar), enviando diretamente pelo whatsapp utilizando o número de celular dele com DDD (Botão Whatsapp) ou então imprimindo o QRCode (Botão Imprimir QRCode).",
  sendPart2: "Dica: Faça um teste com o seu próprio número de celular, para ver como que o seu cliente receberá esta mensagem",
  friend: "Caso tenha algum amigo para indicar e deseja mandar o link, selecione primeiramente o produto, preencha o CPF, CEP, número de celular com DDD e o e-mail do cliente. Após isso, clique no botão “Gerar Link”",
  sendFriend: "Com o link gerado, escolha a forma de envio, seja copiando e colando, via whatsapp ou imprindo o QRCode para o seu cliente",
  finish: "Pronto, agora é indicar o PAN e começar a ganhar"
}

const sellersList = {
  table: "Tabela para gerenciar as equipes, é possivel clicar em ver equipe para mudar supervisor e vendedores de equipe.",
  filter: "É possível realizar buscas baseado em alguns paramêtros.",  
  button: "Após preencher os dados basta clicar para realizar a filtragem.",
  invite: "Clique caso deseje ser levado a página para convidar um novo vendedor para loja."
}

const corpProfile = {
  address: "Caso deseje alterar alguma informação do endereço basta preencher os campos e clicar em salvar.",
  email: "Caso precise alterar o email basta preencher o campo e clicar em salvar.",
  socialName: "Caso deseje alterar seu nome fantasia preencha o campo e clique em salvar."
}

const userProfile = {
  profilePhoto: "Caso deseje alterar a sua foto de perfil basta clicar no botão e selecionar a nova foto.",
  name: "Digite seu nome caso queira altera-lo.",
  email: "Digite seu email caso queira altera-lo.",
  cpf: "CPF não pode ser alterado.",
  telephone: "Caso deseja alterar seu telefone adicione o novo telefone.",
  cellphone: "Caso deseje alterar celular adicione um novo número.",
  emailMarketing: "Marque essa opção caso não queira receber mais emails de marketing.",
  save: "Após alterar os valores basta clicar no botão e salvar suas alterações.",
  changeEmail: "Caso queira solicitar uma troca de email clique no link abaixo.",
  address: "Caso queira alterar os dados do seu endereço basta mudar a informação e clicar em salvar.",
  bank: "Caso queira alterar os dados bancários basta clicar em mostrar informações, mudar os campos e clicar em salvar."
}

const agencyInfo = {
  address: "Caso queira alterar os dados do seu endereço basta mudar a informação e clicar em salvar.",  
  editButton: "Clique em editar para alterar os valores da comissão do vendedores e supervisores",
  saveButton: "Para salvar as novas comissões basta clicar em salvar",
  sellers: "Para alterar a bonificação dos vendedores altere o valor no campo",
  supervisors: "Para alterar a bonificação dos supervisores altere o valor no campo",
  bank: "Caso queira alterar os dados bancários basta clicar em mostrar informações, mudar os campos e clicar em salvar."
}

const supervisedTeam = {
  table: "Esta tela possui uma tabela para analisar informações da equipe. Caso precise de mais informações sobre cada coluna basta passar o mouse sobre o ponto de interrogação ao lado do nome da coluna.",
  addTeam: "Clique para convidar um novo vendedor a sua equipe.",
  addAgency: "Clique para convidar um novo vendedor a sua loja."
}

const extractMaster = {
  table: "Tabela mostrando as informações referentes a quem realizou as vendas, para mais informações da tabela basta passar o mouse sobre a interrogação ao lado do nome da coluna.",
  datepicker: "Clique na data para escolher o período dos valores a serem mostrados.",
  sells: "Total de vendas gerais entre todos os usuários do sistema.",
  corporation: "Total de vendas de todas as corporações cadastradas no sistema.",
  affiliates: "Total de vendas de todos os afiliados do sistema.",
  affiliateButton: "Botão para baixar o csv com os dados de vendas de todos os afiliados",
  corporationButton: "Botão para baixar o csv com os dados de vendas de todas as corporações"
}

const extractCorporation = {
  table: "Tabela mostrando as informações referentes a quem realizou as vendas, para mais informações da tabela basta passar o mouse sobre a interrogação.",
  datepicker: "Clique na data para escolher o período dos valores a serem mostrados.",
  sells: "Total de vendas gerais entre todos os afiliados da corporação.",
  agencie: "Total de vendas de todas as lojas cadastradas no sistema.",
  affiliates: "Total de vendas de todos os afiliados.",
  affiliateButton: "Botão para baixar o csv com os dados de vendas de todos os vendedores.",
  agencieButton: "Botão para baixar o csv com os dados de vendas de todas as lojas."
}

const disclosureMaterial = {  
  table: "Essa tela possui uma tabela com materiais para divulgação, é possível baixar os materiais clicando em baixar na linha específica.",
  newMaterial: "Clique para adicionar um novo material de divulgação."
}

const subUsers = {
  newUser: "Clique no botão para adicionar um novo sub usuário.",
  table: "Nessa tela é possível editar e visualizar os subusuários que poderam fazer login com o mesmo cnpj."
}                   

const corpCampaign = {
  table: "Listagem mostrando todas as campanhas com suas informações pertinentes, é possível copiar os links, ver os QR Codes e filtrar por parâmetros."
}

const affiliate = {
  link: "O vendedor cadastrado via este link será adicionado a sua equipe.",
  linkAdmin: "O vendedor cadastrado via este link será adicionado a sua loja.",
  whats: "Adicione um número para enviar o link por whatsapp.",
  email: "Adicione um email para enviar o link por email.",
  qrCode: "Mostre o qrcode para facilitar a disponibilização do link."
}

const dataTour = {
  corpDetailsCard,
  corpDetails,
  dashboard,
  map,
  paymentCorporation,
  paymentAffiliate,
  corpSelect,
  createCorp,
  userListMaster,
  help,
  payment,
  teamListManegement,
  home,
  sellersList,
  agencyInfo,
  userProfile,
  supervisedTeam,
  extractCorporation,
  extractMaster,
  disclosureMaterial,
  corpProfile,
  subUsers,
  corpCampaign,
  affiliate
}

export default dataTour