import React, { Component } from "react"

import Card from 'components/Card/Card'
import Select from "react-select"
import InputMuiField from 'components/InputMuiField'
import getUserData from "utils/checkUser"
import mainApi from "services/mainApi"
import { LoadingAlert, Dialog } from "../components/Alert/CustomAlert";
import LinkVoltar from 'components/LinkVoltar'

import { Col, Grid, Row } from 'react-bootstrap'
import { FormHelperText } from "@material-ui/core"
import Toogle from 'react-toggle'

class NewSubUser extends Component {

  constructor(props) {

    super(props)
    this.state = {
      name: '',
      email: '',
      selectedProducts: [],
      support: false,
      products: [],
      blocked: false,
      showBlocked: false,
      id: '',
      swalMessage: [],
      swalMessageShow: false,
      swalShowLoading: false,
      userData: null
    }

  }

  setUserData = async (id) => {
    if (id && id !== "subuser") {
      try {

        const response = await mainApi.get('/sub/' + id)
        const aux = response.data.data.subUser

        const productIds = aux.products.find(value => value._id)

        const auxSelectedProducts = []
        aux.products.forEach(product => {
          auxSelectedProducts.push({
            value: product._id || product.id,
            label: product.name
          })
        });

        this.setState({
          id: aux._id,
          name: aux.name,
          email: aux.email,
          selectedProducts: auxSelectedProducts,
          blocked: aux.blocked,
          support: aux.support
          // showBlocked: true
        })
      } catch (error) {
        console.log({ error })
      }
    }

    const userData = await getUserData(this.props.history);
    this.setState({ userData }, () => {
      this.getProducts()
    });


  };

  updateUser = async () => {
    const { name, email, selectedProducts, id, blocked, support } = this.state
    const productsIds = selectedProducts.map(product => product.value)

    this.setState({ swalShowLoading: true });
    try {
      const response = await mainApi.patch("/sub/update", {
        id,
        name,
        email,
        products: support ? undefined : productsIds,
        blocked,
        support
      })

      this.setState({
        swalMessage: ["success", `${response.data?.message}`],
        swalMessageShow: true,
        swalShowLoading: false
      })
    } catch (error) {
      this.setState({
        swalMessage: ["warning", `${error.response?.data?.message}`],
        swalMessageShow: true,
        swalShowLoading: false
      })
    }
  }

  showMessage = (type, message, boldMessage) => {
    this.setState({
      swalMessageShow: true,
      swalMessage: [type, message]
    });
  }

  newUser = async () => {
    const { name, email, selectedProducts, support } = this.state
    const productsIds = selectedProducts.map(product => product.value)
    this.setState({ swalShowLoading: true });
    try {
      const response = await mainApi.post("/sub/invite", {
        name,
        email,
        products: support ? undefined : productsIds,
        support
      })

      this.setState({
        swalMessage: ["success", `${response.data.message}`],
        swalMessageShow: true,
        swalShowLoading: false
      })
    } catch (error) {
      this.setState({
        swalMessage: ["warning", `${error.response.data.message}`],
        swalMessageShow: true,
        swalShowLoading: false
      })
    }
  }

  getProducts = async () => {
    const { userData } = this.state

    let endpoint = ""


    if (['master', 'submaster'].includes(userData.level)) endpoint = "/master/products"
    else if (["corporation", "subcorp"].includes(userData.level))
      endpoint = `/corporation/${userData.id}/products`
    else endpoint = "/products"

    try {
      const response = await mainApi.get(endpoint)
      const isCorp = ["/master/products", "/products"].includes(endpoint)
      const products = isCorp ? response.data.products : response.data.data.products;

      const productsToSelect = []
      products.forEach((product) => {

        productsToSelect.push({
          value: product._id || product.id,
          label: product.name
        })

      })

      this.setState({ products: productsToSelect })
    } catch (err) {
      if (err.response) console.log(err.response.data)
    }
  }

  handleSubmit = async () => {
    const { id } = this.state

    if (id) this.updateUser()
    else this.newUser()
  }

  componentDidMount() {
    const path = this.props.location.pathname

    const id = path.split("/")[path.split("/").length - 1]

    this.setUserData(id)
  }

  handleSelect = (select) => {

    this.setState({ selectedProducts: select ? select : [] })

  }

  render() {
    const {
      id,
      name,
      email,
      swalMessage,
      swalMessageShow,
      swalShowLoading,
      selectedProducts,
      products,
      support,
      userData
    } = this.state

    return (
      <div className="content">
        <Dialog
          type={swalMessage[0]}
          show={swalMessageShow}
          title={swalMessage[0] === "success" ? "Sucesso!" : "Erro"}
          onConfirm={() => {
            this.setState({ swalMessageShow: false });
            if (swalMessage[0] === "success") this.props.history.goBack()
          }}
          text={swalMessage[1]}
          boldText={swalMessage[2]}
        />
        <LoadingAlert show={swalShowLoading} />
        <Grid fluid>
          <LinkVoltar />
          <Row>
            <Col md={6}>
              <Card
                category="Preencha os dados para adicionar ou atualizar um usuário adicional"
                content={
                  <div className='form'>

                    <div >
                      <FormHelperText>Nome</FormHelperText>
                      <InputMuiField
                        placeholder="Insira o nome"
                        value={name}
                        onChange={(text) => {
                          this.setState({ name: text.target.value })
                        }}
                      >Nome</InputMuiField>
                    </div>

                    <div >
                      <FormHelperText>Email</FormHelperText>
                      <InputMuiField
                        placeholder="Insira o email"
                        value={email}
                        onChange={(text) => {
                          this.setState({ email: text.target.value })
                        }}
                      >Email</InputMuiField>
                    </div>

                    {
                      ['master'].includes(userData?.level) &&
                      <>
                        {
                          !id &&
                          <div className="mb-6">
                            <FormHelperText className="mb-1">Usuário de atendimento (Suporte)</FormHelperText>
                            <Toogle
                              checked={support}
                              className="subUser-toggle-class"
                              onChange={() => {
                                this.setState({ support: !support })
                              }}
                            />
                          </div>
                        }

                        {
                          !support &&
                          <div className="mb-4">
                            <FormHelperText>Produtos</FormHelperText>
                            <FormHelperText style={{ textAlign: 'end', fontWeight: 600, color: 'var(--primary-color)' }}>*O usuário poderá ver somente os produtos cadastrados*</FormHelperText>
                            <Select
                              value={selectedProducts}
                              onChange={this.handleSelect}
                              isMulti
                              name="colors"
                              options={products}
                              className="basic-multi-select"
                              classNamePrefix="select mySelect"
                              placeholder="Selecione os produtos"
                            />
                          </div>
                        }
                      </>
                    }


                    <div style={{ paddingLeft: 0, maxWidth: 150 }}>
                      <button
                        onClick={() => this.handleSubmit()}
                        className="btn btn-mlm btn-round btn-fill btn-block mb-3">
                        {id ? 'Atualizar' : 'Cadastrar'}
                      </button>
                    </div>

                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

export default NewSubUser