import React, { Component } from "react";

import { Wrapper, ContentIcon, Itens, Title, Item, NameContainer } from './styled'

const StatsCardDash = ({ color, statsText, active, onClick, map, statsValue }) => {  
  return (
    <Wrapper color={color} active={active} onClick={onClick}>        

        <div className="flex-column">
          <Title>{statsText}</Title>

          <ContentIcon>
            <Itens>
              {statsValue.map((item) => (
                <Item key={item.name}><strong>{item.name}: </strong>{item.value}</Item>
              ))}
            </Itens>
            <img style={{ zIndex: 1 }} className="flex-1" src={map} width="200px" height="150px"></img>
          </ContentIcon>
        </div>
    </Wrapper>
  )
}

class StatsCardDashClass extends Component {  

  render() {
    const { color, statsText, active, onClick, map, statsValue } = this.props        

    return (
      <Wrapper color={color} active={active} onClick={onClick}>        

          <div className="flex-column">
            <Title>{statsText}</Title>

            <ContentIcon>
              <Itens>
                {statsValue.map((item) => (
                  <Item><strong>{item.name}: </strong>{item.value}</Item>
                ))}
              </Itens>
              <img style={{ zIndex: 1 }} className="flex-1" src={map} width="200px" height="150px"></img>
            </ContentIcon>
          </div>
      </Wrapper>
    );
  }

}

export default StatsCardDash;