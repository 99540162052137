import React, { useState, useEffect, useRef, useContext } from 'react'

import { translateLevel } from 'utils/translate'
import formater from 'utils/formater'
import { formatToBrConditional } from 'utils/formaterDate'

import { Row, Col } from 'react-bootstrap'

import Button from "components/Buttons/Button";
import swal from "components/Alert/alert";
import Card from "components/Card/Card";
import CommunicationModal from 'components/CommunicationModal'
import MahoeIcon from "components/MahoeIcons";

import { Error } from './styled'
import { TextField } from "@material-ui/core"

import mainApi from "services/mainApi";

import Avatar from '@material-ui/core/Avatar';
import InboxIcon from '@material-ui/icons/Inbox';
import ForumIcon from '@material-ui/icons/Forum';

import getUserData from "utils/checkUser"

import Badge from '@material-ui/core/Badge';

import UserContext from "context/userContext";
import { isSupportMarAberto, statusCommunicationOptions } from 'utils/CommonData'
import Switch from 'components/Switch/Switch'
import spinner from "assets/img/spinner.gif";

const Communication = (props) => {
  const [errors, setErrors] = useState({})

  const [title, setTitle] = useState('')
  const [subtitle, setSubtitle] = useState('')
  const [message, setMessage] = useState('')
  const [tab, setTab] = useState(0)

  const [loading, setLoading] = useState(false)
  const [communications, setCommunications] = useState([])

  const [selected, setSelected] = useState()
  const [userData, setUserData] = useState({})

  const [selectedSwitch, setSelectedSwitch] = useState(statusCommunicationOptions[0])

  const feedRef = useRef(null)
  const containerRef = useRef(null)

  const contextType =  useContext(UserContext)
  const isSupport = isSupportMarAberto.includes(userData?.id)

  function handleErrorsResponse(response) {
    const auxErrors = {};
    if (!response) return;

    response.forEach((item) => {
      auxErrors[item.name] = item.message;
    });

    setErrors(auxErrors)
  };

  function resetFields() {
    setErrors({})
    setTitle('')
    setSubtitle('')
    setMessage('')
  }

  async function sendMessage() {
    if (!selected) return

    let data = {
      message: message,
      title: selected.title,
      subTitle: selected.subTitle
    }

    if(!loading){
      setLoading(true)
      try {
        const response = await mainApi.patch(`/communication/message/${selected._id}`, data);
        resetFields()
        getCommunication()
        setLoading(false)
      } catch (err) {
        setErrors({})
        if (err?.response?.data?.data) {
          handleErrorsResponse(err.response.data.data.invalid)
          swal.basic("warning", "Atenção!", err.response.data.message)
        }
        else if (err?.response?.data?.message) swal.basic("warning", "Atenção!", err.response.data.message)
        else if ( typeof err.response?.data === 'string')  swal.basic("warning", "Atenção!", err.response.data)
        else setErrors({message: "Não foi possível enviar a mensagem, tente novamente mais tarde."})
        setLoading(false)
      }
    }
  }

  async function getCommunicationUnread(){
    const {setUnreads} = contextType
    try {
      const response = await mainApi.get("/communication/read");
      setUnreads(response.data.data.read)
      localStorage.setItem("@uxtech-mlm/communication", response.data.data.read);
    } catch (err) {
      console.log(err)
    }
  }

  async function UserData() {
    const userData = await getUserData(props.history);
    setUserData(userData)
  };

  async function getCommunication() {

    setLoading(true)
    try {
      const response = await mainApi.get("/communication", {
        params: {
          finished: selectedSwitch?.value
        }
      })
      getCommunicationUnread()
      const communications = response.data.data.communications
      communications.sort(function (a, b) {

        if(!a.read && !b.read){
          if (a.updatedAt > b.updatedAt) {
            return -1;
          }
          if (a.updatedAt < b.updatedAt) {
            return 1;
          }
          return 0
        }

        if(a.read && b.read){
          if (a.updatedAt > b.updatedAt) {
            return -1;
          }
          if (a.updatedAt < b.updatedAt) {
            return 1;
          }
          return 0;
        }

        // if(!a.read || b.read){
        //   if (a.updatedAt > b.updatedAt) {
        //     return -1;
        //   }
        //   if (a.updatedAt < b.updatedAt) {
        //     return 1;
        //   }
        //   return 0;
        // }

        if(!a.read && b.read)
          return -1

        if(a.read && !b.read)
        return 1

        return 0;
      });

      setCommunications(communications)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  async function scrollTobottom() {
    let height = feedRef.current.offsetHeight
    let scrollHeight = feedRef.current.scrollHeight
    feedRef.current.scrollTop = scrollHeight - height;

  }

  useEffect(() => {
    if (feedRef && containerRef && selected) {
      scrollTobottom()
    }
  }, [selected])

  useEffect(() => {
    getCommunication()
    UserData()
  }, [])

  useEffect(() => {
    if (selected) {
      let auxCommunication = communications.find(communication =>
        selected._id === communication._id
      )

      if (auxCommunication) {
        setSelected(auxCommunication)
      }
    }
  }, [communications])

  useEffect(()=>{
    setSelected()
    getCommunication()
  },[selectedSwitch])

  function getCommunicationUser(communication) {
    if (userData.name === communication.recipient.name) {
      return communication.sender
    } else {
      return communication.recipient
    }
  }

  async function handleRead(communication){
    if (!communication) return
    if (communication.read) return

    try {
      const id = communication._id
      const response = await mainApi.patch(`/communication/${id}/read`)
      if(response) {
        getCommunicationUnread()
        getCommunication()
      }
    } catch (err) {
      console.log(err)
    }
  }

  async function handleFinish(communication){
    if (!communication) return
    if (communication.finished) return

    try {
      const id = communication._id
      const response = await mainApi.delete(`/communication/${id}`)
      if(response) {
        getCommunicationUnread()
        getCommunication()
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className="content communication-page">
      <div className="container-fluid">
        <div style={{ display: 'flex', gap: '.5em' }}>
          <div
            style={{ paddingRight: 0, minWidth: 300, maxWidth: 300 }}
            className={`communication-container ${tab !== 0 ? 'web-only' : ''}`}
          >
            <Card
              style={{ height: '100%' }}
              className='communication-aside'
              content={
                <>
                  <div className='communication-btn-message'>
                    {
                      !['master', 'submaster'].includes(userData?.level) && !isSupport &&
                      <CommunicationModal
                      onSend={() => {
                        getCommunication()
                      }} />
                    }
                    {
                      isSupport && (
                        <Switch
                          values={statusCommunicationOptions}
                          selectedSwitch={selectedSwitch}
                          setSelectedSwitch={setSelectedSwitch}
                          disabled={loading}
                        />
                      )
                    }
                  </div>
                  <div className='messages-list'>
                    { !loading ?
                      communications.length ?
                        communications.map(communication => (
                          <div key={communication._id}>
                            <Row className={`contact-container ${!communication.read?'contact-container-unread':''}`}
                              style={selected && communication._id === selected._id ? { backgroundColor: 'var(--primary-color)', color: 'white' } : {}}
                              onClick={() => {
                                setTab(1)
                                setSelected(communication)
                                handleRead(communication)
                              }}
                            >
                              <Col lg={3} md={2} sm={0} xs={0} className='contact-avatar-container'>
                                <Avatar
                                  src={getCommunicationUser(communication).image && `https://agxsoftwarecloud.s3.amazonaws.com/${getCommunicationUser(communication).image}`}
                                >
                                  {!getCommunicationUser(communication).image && formater.initials(getCommunicationUser(communication).name)}
                                </Avatar>
                              </Col>
                              <Col lg={8} md={9} sm={8} xs={8} style={{ paddingLeft: 0 }}>
                                <p className='contact-name' >{getCommunicationUser(communication).name}</p>
                                <p className='contact-level'>{translateLevel(getCommunicationUser(communication).level, userData?.corpId)}</p>
                              </Col>
                              <Col lg={1} md={1} sm={1} xs={1} className="flex-center">
                                {!communication.read && <Badge badgeContent={communication.messages?.filter((message)=>!message.read).length} className="badge-message"/>}
                              </Col>
                            </Row>
                          </div>
                        )) :
                        (
                          <div className='notification-empty'>
                            <InboxIcon
                              style={{
                                color: "#c4c4c4",
                                fontSize: 50,
                                marginBottom: 5
                              }}

                            />
                            <p style={{ textAlign: 'center' }}>Você não possui nenhuma mensagem</p>
                          </div>
                        )
                      :
                      <div className='notification-empty'>
                        <img src={spinner} alt="" />
                      </div>
                    }
                  </div>
                </>
              } />
          </div>
          <div
            className={`communication-container ${tab !== 1 ? 'web-only' : ''}`}
            style={{ paddingLeft: 0, flexGrow: 1 }}
          >
            <>
              <Col lg={12} md={12} sm={12} xs={12}>
                <Card
                  style={{ marginBottom: 10, height: '56vh' }}
                  ref={containerRef}
                  content={
                    <>{selected ? (<>
                      <div className='message-header'>
                        <div className='message-content'>
                          <p className="message-title">
                            {tab === 1 && (
                              <MahoeIcon
                                className='mobile-only go-back-arrow'
                                name='arrow-back'
                                fill='black'
                                onClick={() => { setTab(0) }}
                                style={{ cursor: 'pointer' }}
                              />
                            )}
                            {getCommunicationUser(selected).name}
                          </p>
                          {isSupport && <button
                            className="btn btn-mlm btn-message message-finish"
                            onClick={() => { handleFinish(selected)}}
                          >
                            Finalizar chamado
                          </button>}
                        </div>
                        <p className="message-subtitle">{translateLevel(getCommunicationUser(selected).level, userData?.corpId)}</p>
                        <div className='divider' />
                      </div>
                      <div className='messages-feed' ref={feedRef}>
                        {
                          selected.messages.map((message, index) => (

                            <div key={message._id} >
                              <div className="message-container">
                                <Row>
                                  <Col lg={1}>
                                    <Avatar src={message.image && `https://agxsoftwarecloud.s3.amazonaws.com/${message.image}`}>{!message.image && formater.initials(message.name)}</Avatar>
                                  </Col>
                                  <Col lg={11}>
                                    <div className="message-container-header">
                                      <div className="message-header-data">
                                        <p className='message-name'>{message.name}</p>
                                        <p className='message-level'>{translateLevel(message.level, userData?.corpId)}</p>
                                      </div>
                                      <div className='message-date-container'>
                                        <p className='message-date'>{formatToBrConditional(message.createdAt)}</p>
                                      </div>
                                    </div>
                                    <p className='message-text'>{message.message}</p>
                                  </Col>
                                </Row>
                                {index < selected.messages.length - 1 && <div className='divider' style={{ borderColor: '#e8e8e8' }} />}
                              </div>
                            </div>

                          ))
                        }
                      </div>
                    </>
                    ) : (
                        <div className='notification-empty'>
                          <ForumIcon
                            style={{
                              color: "#c4c4c4",
                              fontSize: 50,
                              marginBottom: 5
                            }}
                          />
                          <p style={{ textAlign: 'center' }}>Nenhuma mensagem selecionda</p>
                        </div>
                      )
                    }
                    </>
                  } />
              </Col>
              <Col lg={12} md={12} sm={12} xs={12}>
                <Card
                  content={
                    <>
                      <Row>
                        <Col lg={12} md={12} sm={12} xs={12}>
                          <TextField
                            label="Mensagem"
                            onChange={(event) => setMessage(event.target.value)}
                            error={errors.message}
                            value={message}
                            multiline
                            rows={4}
                            fullWidth
                            color="primary"
                            variant="outlined"
                            className="input-background"
                            disabled={selected ? false : true}
                            inputProps={{ maxLength: 1000 }}
                          >
                            Mensagem
                          </TextField>
                            {errors.message && <Error hasError={!!(errors.message)}>{errors.message}</Error>}
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '20px' }}>
                        <Col lg={4} md={12} sm={12} xs={12}>
                          <Button
                            onClick={() => {
                              sendMessage()
                            }}
                            disabled={selected ? false : true}
                          >
                            Enviar
                          </Button>
                        </Col>
                      </Row>
                    </>
                  } />
              </Col>
            </>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Communication