import React from 'react'

function TermsClubeAmigo() {
  return (
    <article className="terms-clube-amigo">
      <h2 className="terms-clube-amigo__title">REGULAMENTO DA CAMPANHA CLUBE AMIGO PAN</h2>

      <ol>
        <li className="terms-clube-amigo__subtitle">DA CAMPANHA
          <ol className="terms-clube-amigo__sub-items">
            <li>
                O presente regulamento dispõe acerca das condições da campanha “CLUBE AMIGO PAN” a ser
              promovida pelo BANCO PAN S.A, instituição financeira com sede na Avenida Paulista, nº 1.374, 16º Andar,
              São Paulo/SP, CEP 01310-300, inscrita no CNPJ sob o número 59.285.411/0001-13 (“PAN”). A Campanha
              permite que clientes já existentes indiquem um ou mais amigos para contratação das <strong>operações de
              empréstimo consignado margem livre, portabilidade de empréstimo consignado ou cartão de crédito
              consignado com saque complementar</strong> com o PAN, conforme as condições previstas neste Regulamento.
            </li>

            <li>
                A campanha é direcionada exclusivamente aos clientes com contrato de empréstimo ou cartão
              consignado ativo com o PAN, adimplentes com as suas obrigações, maiores de 18 (dezoito) anos ou
              emancipados.
            </li>

            <li>
                A campanha será realizada no período de 25 de outubro de 2021 a 31 de dezembro de 2021, podendo
              ser prorrogada a qualquer momento a critério dos realizadores.
            </li>

            <li>
                No período de 25 de outubro de 2021 a 30 de novembro de 2021 haverá a <strong>Blue Friday e toda a
              premiação</strong> pela indicação que efetivamente contratarem as operações de <strong>empréstimo consignado
              margem livre, portabilidade, cartão de crédito consignado com saque complementar ou empréstimo
              pessoal FGTS</strong>, considerada contratação/formalização com a assinatura do contrato e efetiva liberação do
              recurso ao cliente pelo PAN, obedecidos os critérios de elegibilidade do PAN, <strong>será em dobro</strong>.
            </li>
          </ol>
        </li>

        <li className="terms-clube-amigo__subtitle">DA PARTICIPAÇÃO
          <ol className="terms-clube-amigo__sub-items">
            <li>A participação nessa campanha é voluntária e não importa em nenhum ônus para o participante.</li>

            <li>
                Os participantes elegíveis receberão, por meio de um Gerente ou Consultor PAN, aquele que realizar o
              atendimento primeiramente, um link da campanha, que dará acesso ao ambiente em que as indicações
              poderão ser registradas.
            </li>

            <li>
                O participante poderá fazer a indicação de pessoas que tenham ou possam ter interesse na contratação
              dos produtos indicados no item 1.1 deste Regulamento, bastando que ele se cadastre na plataforma Clube
              amigo do PAN (<a  target="_blank" rel="noopener noreferrer" href="https://clube.pan.agxsoftware.com">clube.pan.agxsoftware.com</a>) gere o link que poderá compartilhar com o indicado
              para que seja realizada a contratação.

              <ol>
                <li>
                    Somente serão considerados, para fins de aplicação das condições estabelecidas neste
                  Regulamento, os contratos firmados com o PAN pelos amigos indicados, referentes às operações de
                  empréstimo consignado margem livre, portabilidade, cartão de crédito consignado com saque complementar
                  ou empréstimo pessoal FGTS e o link para acesso de indicação terá validade de 30 dias corridos.
                </li>
              </ol>
            </li>

            <li>
                O participante poderá indicar quantos amigos quiser, sendo de responsabilidade do participante
              certificar-se de que os amigos possuem efetivo interesse na contratação, antes de efetuar a sua indicação.
            </li>

            <li>
                Não será admitida a indicação de pessoa com dados coincidentes com os já indicados por qualquer
              Participante anteriormente. Na hipótese de haver a indicação de pessoas nessa situação, não serão
              consideradas para concessão do benefício estipulado para esta Campanha. O PAN manterá registro de todas
              as indicações.
            </li>

            <li>
                As indicações estarão sujeitas à validação prévia, a ser feita pelo PAN diretamente com o titular dos
              dados indicados.

              <ol>
                <li>
                    O processo de validação compreenderá no máximo, 2 (duas) tentativas de contato, que serão
                  feitas no prazo compreendido entre a data da indicação e os 30 (trinta) dias subsequentes,
                  podendo ser por telefone, SMS, WhatsApp ou e-mail.
                </li>

                <li>
                    Havendo contato efetivo com o indicado, caso ele se manifeste favoravelmente à indicação,
                  se tornará elegível à Campanha e terá sua contratação considerada para bonificação do
                  participante, se cumpridas as demais condições estabelecidas neste Regulamento;
                </li>

                <li>
                    Caso não seja possível o contato no prazo e nas formas assinaladas acima ou, ainda,
                  havendo contato efetivo com o indicado, caso ele se manifeste contra a indicação, a
                  indicação não será considerada para fins desta Campanha e seus dados serão mantidos pelo
                  prazo legal em local seguro, de acesso restrito, exclusivamente para fins de garantia do
                  cumprimento dos termos do Regulamento no que diz respeito aos direitos dos Participantes.
                </li>

                <li>O participante não fará jus a qualquer premiação se, por qualquer motivo, a indicação não for validada.</li>
              </ol>
            </li>

            <li>Não serão consideradas válidas as indicações feitas por quaisquer outros meios que não os previstos neste Regulamento.</li>

            <li>
                Somente serão consideradas válidas as indicações em que forem informados os dados completos e
              corretos dos amigos indicados (informar dados solicitados), sendo de total e exclusiva responsabilidade dos
              participantes e/ou indicados, conforme o caso, a inserção e/ou fornecimento de dados incompletos, incorretos
              ou inverídicos.
            </li>
          </ol>
        </li>

        <li className="terms-clube-amigo__subtitle">DOS CRITÉRIOS DE APURAÇÃO
          <ol className="terms-clube-amigo__sub-items">
            <li>
                A bonificação somente será devida após a efetiva formalização de qualquer das operações de
              <strong>empréstimo consignado margem livre, portabilidade, cartão de crédito consignado com saque
              complementar</strong> ou <strong>empréstimo pessoal FGTS</strong> entre o PAN e os amigos indicados pelos participantes.
            </li>

            <li>
                A formalização da contratação fica condicionada à análise de crédito, conforme critérios de contratação
              aplicados pelo PAN para os produtos especificados, e das demais condições de elegibilidade, não sendo
              devida a bonificação ao participante que indicar amigos não elegíveis à contratação ou ao participante que
              seja reconhecido como não elegível à participação na campanha, nos termos deste Regulamento.
            </li>

            <li> O valor da bonificação será calculada com base no número de contratos formalizados com o PAN, na forma descrita na cláusula quinta abaixo.</li>

            <li>Caso o cliente indique um amigo já indicado por outro participante, a bonificação será devida ao participante que indicou primeiro.</li>

          </ol>
        </li>

        <li className="terms-clube-amigo__subtitle"> DA DIVULGAÇÃO
          <ol className="terms-clube-amigo__sub-items">
            <li> A divulgação da bonificação será comunicada por meio da plataforma de indicações, SMS e e-mail aos participantes.</li>
          </ol>
        </li>

        <li className="terms-clube-amigo__subtitle">  DA PREMIAÇÃO E SUA DISTRIBUIÇÃO
          <ol className="terms-clube-amigo__sub-items">
            <li>
                A premiação desta campanha será concedida aos participantes que indicarem clientes que efetivamente
              contratarem as operações de <strong>empréstimo consignado margem livre, portabilidade, cartão de crédito
              consignado com saque complementar ou empréstimo pessoal FGTS</strong>, considerada
              contratação/formalização com a assinatura do contrato e efetiva liberação do recurso ao cliente pelo PAN,
              obedecidos os critérios de elegibilidade do PAN.
            </li>

            <li>
                A premiação consiste em 1 (um) voucher on-line, cujo valor será definido com base no tipo de contrato
              formalizado entre os amigos indicados e o PAN, de acordo com a forma de contemplação definida abaixo:

              <ul type="disc">
                <li> Contratação de Empréstimo Consignado Margem livre, Portabilidade ou Empréstimo Pessoal FGTS: R$50,00</li>
                <li> Contratação de cartão de crédito consignado com saque complementar: R$25,00</li>
              </ul>
            </li>

            <li>
                As bonificações estipuladas nesta Campanha não incluem eventuais despesas havidas pelos
              participantes para o seu resgate e recebimento dos produtos, tais como ligações telefônicas, transportes e
              deslocamentos, envio de correspondências, transferências, dentre outros, e em hipótese alguma poderão ser
              convertidos em dinheiro, depósitos em conta bancária, bonificações ou crédito para aquisição de outros
              produtos e serviços que não os especificados neste Regulamento.
            </li>

          </ol>
        </li>

        <li className="terms-clube-amigo__subtitle"> AVISO DE PRIVACIDADE
          <ol className="terms-clube-amigo__sub-items">
            <li>
                Os dados pessoais dos participantes serão tratados para fins de cumprimento dos termos e
              condições dispostas neste Regulamento, abrangendo seu compartilhamento com o administrador do Portal
              responsável pelo gerenciamento desta Campanha, bem como pelo parceiro responsável pela emissão das
              bonificações.

              <ol>
                <li> Os dados pessoais dos amigos indicados serão tratados para validação do interesse na participação desta Campanha, nos exatos termos previstos neste Regulamento. </li>

                <li> O PAN poderá, ainda, tratar os dados pessoais dos participantes para oferecer ofertas de produtos e serviços, bem como dos seus parceiros comerciais, via telefone, SMS, WhatsApp ou email </li>

                <li>
                    O PAN poderá, ainda, compartilhar os dados pessoais com terceiros e autoridades dentro das
                  hipóteses de cumprimento de obrigação legal, políticas públicas, proteção da vida e da saúde,
                  segurança, cumprimento do contrato e legítimo interesse.
                </li>

                <li>
                    Os dados pessoais dos indicados nas plataformas previstas neste Regulamento, cujo
                  cadastro não for validado, serão tratados exclusivamente para cumprimento de obrigação legal,
                  para assegurar a observância dos direitos dos participantes, durante o prazo de 5 (cinco) anos. Ao
                  término deste prazo, serão completamente excluídos, por meio de métodos de descarte seguro.
                </li>

                <li>
                    Os dados coletados em razão desta Campanha estarão armazenados em ambiente seguro e
                  controlado pelo prazo de duração da relação jurídica que o originou e, além desta, será considerado
                  o prazo exigido por lei, considerando: (i) O prazo mínimo de 6 (seis) meses, nos termos do Marco
                  Civil da Internet; e (ii) O prazo mínimo de 5 (cinco) anos, nos termos do Código de Defesa do
                  Consumidor.
                </li>

                <li>
                    Os dados pessoais cadastrais e navegacionais dos participantes somente serão acessados
                  por profissionais devidamente autorizados, respeitando os princípios de proporcionalidade,
                  necessidade e relevância para os objetivos desta Campanha, além do compromisso de
                  confidencialidade e preservação da privacidade dos Titulares de dados.
                </li>

                <li>
                    Considerando que nenhum sistema de segurança é infalível, o PAN se exime de quaisquer
                  responsabilidades por eventuais danos e/ou prejuízos decorrentes de falhas, vírus ou invasões do
                  banco de dados do Portal, salvo nos casos em que tiver dolo ou culpa.
                </li>

                <li> O participante tem o direito de exigir a exibição, retificação ou ratificação dos dados pessoais que lhe dizem respeito, por meio do chat na plataforma AGX.</li>

                <li>
                    É assegurado, ainda, a qualquer participante ou indicado o direito de solicitar, a qualquer
                  tempo, a exclusão de seus dados pessoais, por meio dos canais supra indicados. Todavia, ficam
                  cientes de que isso provocará a sua automática exclusão da Campanha, com a invalidação das
                  indicações registradas, caso se trate de participante. Ficarão ressalvadas, neste caso, as hipóteses
                  de tratamento necessárias ao cumprimento de exigências legais.
                </li>

                <li>
                    Os dados dos participantes poderão ser armazenados em servidor próprio do próprio PAN
                  localizado no Brasil, bem como em ambiente de uso de recursos ou servidores na nuvem (cloud
                  computing), o que enseja, neste último caso, transferência ou processamento dos dados fora do
                  Brasil, cumprindo disposições sobre transferência internacional de Dados conforme a Lei Geral de
                  Proteção de Dados ou demais normas aplicáveis.
                </li>
              </ol>
            </li>

          </ol>
        </li>

        <li className="terms-clube-amigo__subtitle"> CONSIDERAÇÕES GERAIS
          <ol className="terms-clube-amigo__sub-items">
            <li> A simples participação na campanha já significa a aceitação total de todos os itens deste Regulamento, que poderá ser alterado a qualquer momento a critério dos realizadores.</li>

            <li>
              Este Regulamento estará disponível no site do PAN <a target="_blank" rel="noopener noreferrer" href="https://clube.pan.agxsoftware.com">clube.pan.agxsoftware.com</a> e será enviado
              aos participantes por meio de SMS e e-mail, garantindo a todos o conhecimento pleno das suas condições.
            </li>

            <li> A Campanha poderá ser prorrogada ou cancelada a qualquer momento, a critério do PAN, não sendo devida qualquer indenização aos participantes, a qualquer título. </li>

            <li> Os benefícios distribuídos são de uso exclusivo, pessoal e intransferível, não podendo ser cedidos ou transferidos a terceiros ou convertidos, total ou parcialmente, em dinheiro. </li>

            <li>Ocorrendo motivo de força maior que o justifique, o PAN reserva-se no direito de modificar os critérios de análise e apuração da pontuação, mediante simples comunicado aos participantes. </li>

            <li>
                Ao aceitarem as condições do presente os participantes manifestam concordância ao uso da sua imagem,
              som de voz e nome em vídeos, fotos cartazes, anúncios de jornais e revistas, e em qualquer outro meio
              utilizado pelo PAN para divulgação da sua participação e da conquista do benefício, pelo prazo de 1 (um) ano
              após o encerramento da campanha, sem qualquer natureza de divulgação comercial.

              <ol>
                <li>Não obstante a autorização acima, o PAN não tem nenhuma obrigação de usar ou publicar a imagem, som de voz e nome dos participantes. </li>
              </ol>
            </li>

            <li> A reprodução e representação de todos ou de parte dos sinais distintivos que fazem parte da Campanha são estritamente proibidas, uma vez que são de titularidade do PAN ou a ela estão licenciados. </li>

            <li>
                A reprodução de conteúdo relativos à Campanha, incluindo por meio de compartilhamento em redes
              sociais, tais como, mas não se limitando a imagens, dados, sons de voz e outros conteúdos protegidos por
              direitos personalíssimos ou por propriedade intelectual, realizada por terceiros não autorizados, constituem ou
              podem constituir infração passível de sanções cíveis, penais e/ou administrativas.
            </li>

            <li> As questões não previstas neste Regulamento ou qualquer dúvida que possa surgir serão dirimidas pelo PAN e suas decisões são soberanas e irrecorríveis. </li>

            <li>
                Resta esclarecido que o PAN, a qualquer tempo e ao seu exclusivo critério, poderá efetuar todo e
              qualquer tipo de ação preventiva ou corretiva para manter o bom funcionamento de qualquer ferramenta
              tecnológica relacionada à Campanha, motivo pelo qual, desde já, os participantes isentam-na de qualquer
              responsabilidade em decorrência de eventual impossibilidade de participação advinda de tais atos.
            </li>

            <li>
                Os participantes autorizam a utilização de seus endereços físicos, eletrônicos, telefones, perfis de
              usuários para mensagerias por meio de internet e SMS e demais dados informados com o propósito de
              formação de cadastro, reforço de mídia publicitária e divulgação da própria Campanha, nos limites do código
              de defesa do consumidor e da Lei Geral de Proteção de Dados Pessoais, sendo expressamente vedada a
              comercialização ou a cessão, ainda que a título gratuito, desses dados.
            </li>

            <li>
                O PAN processa e armazena os dados de tráfego e de conexão ao hotsite do Programa,
              principalmente, a identificação (endereço IP) do dispositivo utilizado pelo Participante com o objetivo de gerar
              estatísticas de acesso ao hotsite, garantir a segurança do Programa, assim como verificar a correta
              participação, garantindo sua conformidade em relação a este Regulamento, e na prevenção ou detecção de
              acessos automáticos em seus sistemas, levando assim à exclusão do Participante envolvido nesta atividade.
            </li>

            <li>
                Fica acordado que os dados existentes nos sistemas de informação do PAN possuem valor probatório
              em relação à data e hora de conexão, aos elementos de conexão e das informações resultantes do
              processamento de dados relativos a este Programa.
            </li>

            <li>
                O Programa não será responsável por problemas, falhas ou funcionamento técnico, de qualquer tipo,
              em redes de computadores, servidores ou provedores, equipamentos de computadores, celulares,
              smartphones, tablets, hardware ou software, ou erro, interrupção, defeito, intervenção, intrusão externa e não
              autorizada, atraso ou falha em operações ou transmissões para o correto processamento de seus sistemas,
              incluindo, mas não se limitando, congestionamento na Internet, vírus, falha de programação (bugs) ou
              violação por terceiros (hackers).
            </li>

            <li>
                A presente Campanha não se enquadra nas modalidades de sorteio, vale-brinde, concurso ou operação
              assemelhada reguladas pelo Decreto nº 70.951/1972, Lei nº 5.768/1971 e Portaria nº 41/2008 do Ministério
              da Fazenda, não estando, portanto, sujeita à autorização prévia estabelecida na Lei nº 5.768/1971.
            </li>

            <li> PAN não se responsabiliza pela autenticidade dos dados fornecidos pelos Clientes Participantes. </li>

            <li>
                Ao aderir às condições deste programa, os participantes declaram, desde já, que estão cientes de todo
              o conteúdo deste Regulamento, e que concordam expressamente com todas as cláusulas e condições nele
              estabelecidas.
            </li>

          </ol>
        </li>
      </ol>

    </article>
  )
}

export default TermsClubeAmigo
