import React, { useLayoutEffect, useState } from 'react'
import mainApi from 'services/mainApi'
import { MuiTableConfig } from 'utils/CommonData'
import getMuiTheme from 'utils/getMuiTheme'
import Card from './Card/Card'
import { MuiThemeProvider } from 'material-ui/styles'
import MUIDataTable from 'mui-datatables'
import { IconButton, MenuItem } from 'material-ui'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Menu, Tooltip } from '@material-ui/core'
import spinner from "assets/img/loading.gif"
import moment from 'moment'

import CopyToClipboard from 'react-copy-to-clipboard';
import UnimportantText from './UnimportantText'
import format from 'utils/formater'

// eslint-disable-next-line
Array.prototype.insert = function (index, item) {
  this.splice(index, 0, item);
};


function LinkActions({ code, shortenBaseUrl }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const url = shortenBaseUrl + '/' + code
  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}

        PaperProps={{
          style: {
            minWidth: '15ch',
          },
        }}
      >
        <span role='menuitem' className="mui-menuitem">
          <a className="mui-menuitem-text" key={code} href={url} target="_blank" rel="noopener noreferrer" onClick={handleClose}>
            Abrir
          </a>
        </span>
        <CopyToClipboard text={url}>
          <MenuItem onClick={handleClose}>Copiar</MenuItem>
        </CopyToClipboard>
      </Menu></>)
}


function UserLinks({ userLevel }) {
  const [links, setLinks] = useState([])
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(5)
  const [pagination, setPagination] = useState({})
  async function getUserLinks(selectedPage=1, selectedLimit=limit) {
    try {
      const response = await mainApi.get(`/links?page=${selectedPage}&limit=${selectedLimit}`)
      const shortenBaseUrl = response.data.data.shortenBaseUrl
      const links = response.data.data.links.docs
      const linksWithActions = links.map(link => { return { ...link, type: link.staticLink ? 'Loja' : link.specific ? 'Específico' : 'Genérico', actions: <LinkActions code={link?.shorten.code} shortenBaseUrl={shortenBaseUrl} /> } })
      setLinks(linksWithActions)

      const linksPagination = { ...response.data.data.links, docs: undefined }
      setPagination(linksPagination)

    } catch (err) {
    }
  }

  function updateClicks ({id, value}) {
    setLinks(prev => prev.map(link => link._id !== id ? link :
      ({...link, shorten: {...link.shorten, clicks: value}})
    ))
  }

  const showClick = async (id) => {
    updateClicks({id, value: 'loading'})
    try {
      const response = await  mainApi.get(`/links/${id}/click`)
      updateClicks({id, value:  response.data.data.clicks})
    } catch (e) {
      console.log(e)
      updateClicks({id, value: undefined})
    }
  }

  useLayoutEffect(() => {
    getUserLinks()
  //eslint-disable-next-line
  }, [])

  const columns = [
    {
      name: "_id",
      label: "ID",
      options: {
        display: false,
        viewColumns: false,
      }
    },
    {
      name: "type",
      label: "Tipo",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "product.name",
      label: "Produto",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "createdAt",
      label: "Criado em",
      options: {
        customBodyRender: value => { if (value?.trim()) return moment(value).format('HH:mm DD/MM/YYYY'); else return "n/a" },
        filter: false,
        sort: false,
      }
    },
    {
      name: "shorten.code",
      label: "Código",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "cpf",
      label: "CPF (específico)",
      options: {
        customBodyRender: value => { if (value?.trim()) return format.toCPF(value); else return <UnimportantText text="Link Genérico" /> },
        filter: false,
        sort: false,
      }
    },
    {
      name: "shorten.clicks",
      label: "Cliques",
      options: {
        filter: false,
        sort: false,
        sort: false,
        customBodyRender: (value, tableInfo) => {
          const { rowData, columnIndex } = tableInfo;
          if (typeof value === 'number')
            return value
          if (value === 'loading')
            return <img style={{height: 14, width: 14}} src={spinner} alt="" />
          return <button onClick={() => showClick(rowData[0])} className='anchor-button'>Ver</button>
        }
      },
    },
    {
      name: "actions",
      label: "Ações",
      options: {
        filter: false,
        sort: false,
      }
    },

  ];
  if (userLevel && !['seller', 'supervisor'].includes(userLevel)) columns.insert(2, {
    name: "user.name",
    label: "Criado por",
    options: {
      customBodyRender: value => { return <Tooltip title={value || ''}><span>{format.toJustFirstName(value)}</span></Tooltip> },
      filter: false,
      sort: false,
    }
  })
  return (
        <MuiThemeProvider theme={getMuiTheme}>
          <MUIDataTable
            title={"Links"}
            columns={columns}
            data={links}
            options={{
              ...MuiTableConfig,
              download: false,
              filter: false,
              search: false,
              print: false,
              sort: false,
              viewColumns: false,
              rowsPerPageOptions: [5, 15, 30],
              rowsPerPage: limit,
              serverSide: true,
              count: pagination.totalDocs || 0,
              page: page || 0,
              onTableChange: (action, tableState) => {
                switch (action) {
                  case "changePage":
                    setPage(tableState.page)
                    getUserLinks(tableState.page + 1)
                    break;
                  case "changeRowsPerPage":
                    setPage(0)
                    setLimit(tableState.rowsPerPage)
                    getUserLinks(1, tableState.rowsPerPage)
                    break;
                  default: break;
                }
              },
            }}
          />
        </MuiThemeProvider>
  )
}

export default UserLinks
