import styled from 'styled-components'

export const Grid = styled.div `
  display: grid;
  grid-template-columns: 390px auto auto auto auto auto;
  grid-gap: 20px;
  
  padding: 0 15px 15px 15px;

  @media (max-width: 768px) {
    display: block;
  }
`

export const Grid2 = styled.div `
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 20px;    

  @media (max-width: 768px) {
    display: block;
  }
`

export const Column2 = styled.div `
  grid-template-columns: auto auto;
`

export const MapItem = styled.div `
  background-color: #FFFFFF;  
  border: 1px solid #E0E0E0;
  border-radius: 15px;
  position: relative;

  grid-column: 3 / 7;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1258px) {
    grid-column: 1 / 7;
  }
`

export const TableItem = styled.div `
  grid-column: 1 / 7;
`

export const Row = styled.div `
  grid-column: 1 / 7;
`

export const Description = styled.h2 `
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  margin: 0;
`

export const Search = styled.div `
  grid-column: 1/2;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`

export const SecondSearch = styled.div `
  grid-column: 3/4;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`

export const RegionsIten = styled.div `
  grid-column: 1 / 3;

  @media (max-width: 1258px) {
    grid-column: 1 / 7;
  }
`