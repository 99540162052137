import React, { useState } from 'react'
import MahoeIcon from "components/MahoeIcons"
import { getMonthAndYear } from "utils/formatData"
import { Popper, Fade, Paper } from '@material-ui/core';

// date-picker
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptbr from 'date-fns/locale/pt-BR'

registerLocale('pt-BR', ptbr)

function MuiDatepickerMonth({ onChange, date, isClearable, disabled, ...rest }) {

  const [openPopper, setOpenpopper] = useState(false)
  const [anchorEl, setAnchorEl] = useState()

  function handleMouse(event) {
    setAnchorEl(event.currentTarget)
    setOpenpopper(true)
  }

  return (
    <>
      <DatePicker
        className="datepicker-theThird h-48-px"
        dateFormat='MM/yyyy'
        locale='pt-BR'
        isClearable={isClearable !== undefined?isClearable:true}
        {...rest}
        customInput={(
          <div className={disabled ? 'div-datepicker div-datepicker-disabled' : 'div-datepicker'}>
            <div className='col-6'>
              <span className='placeholder-datepicker'>{date ? 'Mês selecionado:' : 'Selecione um mês'}</span>
            </div>
            <div className='col-3'>
              <p className='date-datepicker'>{date ? getMonthAndYear(date) : ''}</p>
            </div>
            <div
              className='col-3'
              style={{ alignSelf: 'center' }}
              onMouseEnter={(event) => handleMouse(event)}
              onMouseLeave={() => setOpenpopper(false)}
            >
              {date ? <></> : <MahoeIcon aria-describedby={'iconTooltip'} name="info" size="23px" fill="#039BE5" />}
            </div>
          </div>
        )}
        selected={date}
        disabled={disabled}
        showMonthYearPicker
        onChange={onChange}
        maxDate={new Date()}
        minDate={new Date('2020-01-01')}
      />

      <Popper
        id={'iconTooltip'}
        open={openPopper}
        anchorEl={anchorEl}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper style={{ backgroundColor: '#414141', padding: '0px 10px' }}>
              <p className='tooltipText-datepicker'>Selecione o mês que deseja ver os dados.</p>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  )
}

export default MuiDatepickerMonth