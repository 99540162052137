import React, { Component } from "react";

import "assets/css/styles.css";
import ConfirmedEmail from "components/ConfirmedEmail";
import config from "utils/serverVars";
import queryString from "query-string";
import mainApi from "services/mainApi";
import clubeAmigoBanner from 'assets/img/clubeAmigo/clubeAmigoBanner.png'
import TermsModal from "components/TermsModal/TermsModal";
import { ISClubeAmigo } from "utils/environment";
import campanhaWhatsappBanner from 'assets/img/pan_cover_campanha_whatsapp.png'

class ConfirmEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banner: config.IMG_PF,
      loginType: "cnpj",
      title: '',
      message: ''
    };
  }

  handleEmailConfirmation = async (token) => {

    try {
      const response = await mainApi.post('/users/email/confirm', {
        confirmEmailToken: token
      })

      this.setState({
        title: 'Email confirmado!',
        message: response.data.message
      })

    } catch (exception) {

      if (exception.response?.status === 409) {
        this.setState({
          title: 'Email já confirmado!',
          message: exception.response.data.message
        })
      }
      else if (exception.response?.status === 404) {
        this.setState({
          title: 'Email não encontrado!',
          message: exception.response.data.message
        })
      } else {
        this.setState({
          title: 'Não foi possível confirmar email!',
          message: 'Verifique sua internet ou tente novamente mais tarde.'
        })
      }
    }
  }


  componentDidMount() {
    const search = queryString.parse(this.props.location.search);
    this.handleEmailConfirmation(search?.token)
    this.setState({ token: search.token });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props !== nextProps || this.state !== nextState;
  }

  render() {
    const { title, message } = this.state
    let banner, logo;
    let path = this.props.location.pathname.replace("/", "");

    if (ISClubeAmigo) {
      banner = clubeAmigoBanner
      logo = path === "cnpj" ? config.MINIMAL_LOGO : config.LOGO_ALT;
    }
    else if (path === "cnpj") {
      banner = config.IMG_PJ;
      logo = config.MINIMAL_LOGO;
    } else {
      banner = config.IMG_PF;
      logo = config.LOGO_ALT;

      const campanha = localStorage.getItem('@uxtech-mlm/campanha')
      const IS_CONSIGNADO = campanha === "indiqueopanconsignado"
      const IS_WHATSAPP = campanha === "whatsapp"

      if (IS_CONSIGNADO) banner = config.IMG_CAMPANHA_PF
      if(IS_WHATSAPP) banner = campanhaWhatsappBanner
    }



    return (
      <>

        <div className="grid-parent">
          <div
            className={
              config.CLIENT === "pan"
                ? "kv-informative"
                : "kv-informative agx-bg"
            }
          >
            {config.CLIENT === "pan" && (
              <>
                <img className="login-pan-logo" alt="logo do banco pan" src={logo}></img>
                <div className="clubeAmigo_continer">
                  <img className="people" alt="logo do banco pan" src={banner}/>
                  {ISClubeAmigo && <TermsModal classNameLink={'clubeAmigo_terms'} />}
                </div>

              </>
            )}
          </div>
          <div className="kv-form-login">
            <ConfirmedEmail
              title={title}
              message={message}
            />
          </div>
        </div>
      </>
    );
  }
}

export default ConfirmEmail;