import styled from 'styled-components'

export const Title = styled.strong`
  margin-left: 10px;
  padding-top: 5px;
  font-size: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width:73%;
  max-height: 33px;
`