import React, { Component } from "react";
import { Row } from "react-bootstrap";

import GenerateLink from "components/GenerateLink";
import getUserData from "utils/checkUser";
import { Dialog } from "components/Alert/CustomAlert";
import CampaignList from "components/CampaignList";
import UserLinks from "components/UserLinks";
import UserLinksTabs from "components/UserLinksTabs";
import Totals from "components/UserLinksTabs/Totals";
import Card from "components/Card/Card";

class SellAsAgency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: {},
      swalMessage: [],
      swalMessageShow: false,
      reloadCampaign: false,
    };
  }

  showMessage = (type, message, boldMessage) => {
    this.setState({
      swalMessageShow: true,
      swalMessage: [type, message],
    });
  };

  reloadListCampaign = () => {
    this.setState({ reloadCampaign: !this.props.reloadCampaign });
  };

  componentDidMount = async () => {
    const userData = await getUserData(this.props.history);
    this.setState({ userData });
  };

  render() {
    const {
      userData,
      swalMessage,
      swalMessageShow,
      reloadCampaign,
    } = this.state;

    return (
      <>
        <Dialog
          type={swalMessage[0]}
          show={swalMessageShow}
          title={
            swalMessage[0] === "success"
              ? "Sucesso!"
              : swalMessage[0] === "warning"
              ? "Ops!"
              : "Erro"
          }
          onConfirm={() => {
            this.setState({ swalMessageShow: false });
          }}
          text={swalMessage[1]}
          boldText={swalMessage[2]}
        />
        <div className="content">
          <div className="container-fluid">
            <Row>
              <GenerateLink
                {...this.props}
                title={`Link para ${userData.agencyName}`}
                agencyId={userData.agencyId}
                size={6}
                messageHandler={this.showMessage}
              />

              <GenerateLink
                {...this.props}
                title={`Link para campanha`}
                size={6}
                campaignLink
                messageHandler={this.showMessage}
                reloadCampaign={this.reloadListCampaign}
              />
            </Row>
            <Row>
              {userData.level === "administrator" ? (
                <UserLinksTabs
                  userLevel={userData.level}
                  reloadCampaign={this.reloadListCampaign}
                  isReload={reloadCampaign}
                />
              ) : (
                <UserLinks userLevel={userData.level} />
              )}
            </Row>
          </div>
        </div>
      </>
    );
  }
}

export default SellAsAgency;
