import styled from 'styled-components'

export const WrapperGrid = styled.ul `

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;  
  grid-auto-rows: min-content;
  gap: 10px;

  height: 55vh;

  padding: 0;  
  list-style: none;
  overflow-y: auto;
`

export const DDDsGrid = styled.div `
  display: grid;
  grid-template-columns: repeat(auto-fill, 30px);  
  text-align: center;
  gap: 10px;
`

export const ItemDDD = styled.div `
  background-color: #039BE5;
  padding: 5px;
  border-radius: 5px;
`

export const ListItem = styled.li `  

  border: 2px solid ${props => props.color ? props.color : '#28a745'};
  border-radius: 5px;
  color: white;
  text-align: center;
  background-color: ${props => props.color ? props.color : '#28a745'};
  padding: 5px 0;  
  cursor: pointer;
  font-size: 13px;

  &:hover {
    background-color: ${props => props.colorHover ? props.colorHover : '#218838'};
    border-color: ${props => props.colorHover ? props.colorHover : '#1e7e34'};
  }
`

export const WrapperStates = styled.div `
  border: 2px solid #039BE5;
  border-radius: 10px;
`

export const SpanState = styled.div `
  background-color: ${ props => props.bgColor ? props.bgColor : 'black' };

  color: white;
  font-weight: bold;
  display: inline-block;
  border-radius: 5px;
  margin-left: 5px;
  padding: 0 5px;
`