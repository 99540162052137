import React, { Component } from "react";
import { Col, Label } from "react-bootstrap";
import { numToBRL, logoUrl, toTitleCase } from "../../../utils/formatData";
import { Table } from "./styled"
import formater from '../../../utils/formater' 
import Skeleton from '@material-ui/lab/Skeleton';
import mainApi from "services/mainApi";
import LinkButton from "components/LinkButton";
import MahoeIcon from "components/MahoeIcons";


const CorbanOrCorpBadge = ({hasManager}) => (
  <Label bsStyle={hasManager ? "primary" : "warning"}>{hasManager ? "Corban" : "Corporação"}</Label>
)
export default class Corp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datasToTable: ''
    };
  }

  getValue =  () => {
    let value = (Math.random() * 20000) + 500
    return parseFloat(value.toFixed(2))
  }

  getDatas = async () => {
    const { data } = this.props
    
    try {
      const response = await mainApi.get(`/corporation/datas/${data._id}`);
      this.setState({ datasToTable: response.data.data.datas });
    } catch (error) {
      console.warn(error)
    }
  }

  componentDidMount () {
    this.getDatas()
  }

  render() {
    const {data, dataIntro, onClick} = this.props   
    const { datasToTable } = this.state 

    return (      
      <Col lg={12} md={12} sm={12} data-intro={dataIntro ? dataIntro.description : null}>     
        <div className="corp-card-list" style={{ border: '1px solid #c5c5c5' }}>
            <div className="img-container">
              <img   
                data-intro={dataIntro ? dataIntro.image : null}
                className="corp-card-logo"            
                src={ logoUrl(data.logo) }
                alt='Logo corporation'
                onClick={onClick}
              />
              </div>            

            <div className="card-body" style={{ marginBottom: 0 }} data-intro={dataIntro ? dataIntro.name : null}>
              <LinkButton style={{fontSize: data.name.length > 36 ? 16 : 18}} onClick={onClick}><b>{data.name}</b></LinkButton>
              <p className="card-text description-card-corp">{data.description}</p>
              <div className="mb-7px">
              <CorbanOrCorpBadge hasManager={data.hasManager}/> {" "}
              <span className="card-text description-card-corp">{data.hasManager ? "Parceiros" : "Lojas"}: {data.agencies} - SubCorps: {data.subcorps}</span>
              </div>
              <p style={{ fontSize: 14, color: 'rgba(0,0,0,0.70)', margin: 0 }}>{formater.toCNPJ(data.cnpj)} - {data.email}</p>
              <p style={{ fontSize: 14, color: '#000' }}>{`Código Corban: ${data.corbanCode || 'Não atribuido'}`}</p>
            </div>

          { datasToTable ? (
            <Table style={{ margin: '20px 0px', width: '100%' }} >
              <thead>
                <tr>
                  <th style={{ padding: 0 }}>Produto</th>
                  <th style={{ padding: 0 }} style={{textAlign: 'center'}}><MahoeIcon name="link-default" fill="#039BE5" size="18px"/></th>
                  <th style={{ padding: 0 }} style={{textAlign: 'center'}}><MahoeIcon name="checked" fill="#13dd13" size="18px"/></th>
                  <th style={{ padding: 0 }} style={{textAlign: 'center'}}><MahoeIcon name="clock" fill="#e99c0d" size="22px"/></th>
                  <th style={{ padding: 0 }} style={{textAlign: 'center'}}><MahoeIcon name="rounded-close" fill="#c20909" size="18px"/></th>
                </tr>
              </thead>
              { datasToTable.map((data, index) => (
                <tbody key={data.product}>
                  <tr>
                    <td style={{ padding: '5px 0', fontSize: 13 }}>{toTitleCase(data.name)}</td>
                    <td style={{ padding: '5px 0', fontSize: 13 }} style={{textAlign: 'center'}}>{data.links}</td>
                    <td style={{ padding: '5px 0', fontSize: 13 }} style={{textAlign: 'center'}}>{data.paid}</td>
                    <td style={{ padding: '5px 0', fontSize: 13 }} style={{textAlign: 'center'}}>{data.pending}</td>
                    <td style={{ padding: '5px 0', fontSize: 13 }} style={{textAlign: 'center'}}>{data.refused}</td>
                  </tr>
                </tbody>)
                )}
            </Table>
          ) :
          (
            <div className="skeleton-table">
              <Skeleton animation="wave" style={{ height: '100%' }}/>
              <Skeleton animation="wave" style={{ height: '100%' }}/>
              <Skeleton animation="wave" style={{ height: '100%' }}/>
              <Skeleton animation="wave" style={{ height: '100%' }}/>
              <Skeleton animation="wave" style={{ height: '100%' }}/>
            </div>
          )
          }
        </div>            
      </Col>
    );
  }
}