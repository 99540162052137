import React, { Component, createRef } from "react";

import "assets/css/styles.css";

import InputMask from "react-input-mask";
import { Link } from "react-router-dom";
import Button from "components/CustomButton/CustomButton.jsx";
import { Dialog } from "../../components/Alert/CustomAlert";

import mainApi from "services/mainApi";
import renderHTML from "react-render-html";
import { TextField } from "@material-ui/core";
import { productOrder } from '../../utils/CommonData'

import config from "utils/serverVars";
import clubeAmigoLogo from 'assets/img/clubeAmigo/clubeAmigoLogo.png'
import { ISClubeAmigo } from "utils/environment";
class SigninForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: "",
      password: "",
      email: "",
      btnTitle: "Entrar",
      logo: "",
      loading: false,
      swalMessageShow: false,
      swalMessage: "",
      needCaptcha: false,
      captchaSvg: null,
      captcha: "",
      sendEmail: true,
      showCorpInputs: true
    };

    this.captchRef = createRef()
  }

  showMessage = (type, message) => {
    this.setState({
      swalMessageShow: true,
      swalMessage: [type, message],
      btnTitle: "Entrar",
      loading: false,
    });
  };

  componentDidMount() {
    let loginType = this.props.loginType || 'CPF';
    window.digitalData = {
      "site": {
          "nome": "Banco Pan"
      },
      "page": {
          "secao": "IndiqueoPan",
          "estadoLogin": "NL",
          "nome": `BP:IndiqueOPan:Home${loginType.toUpperCase()}`
      },
    }

    var evt = new CustomEvent('event-view-start');
    document.body.dispatchEvent(evt);

    document.addEventListener(
      "keydown",
      this.handleKeyPressedOnDocument,
      false
    );
  }

  componentWillUnmount() {
    document.removeEventListener(
      "keydown",
      this.handleKeyPressedOnDocument,
      false
    );
  }

  getProducts = async (userData) => {
    let endpoint = "";

    if (["master", "submaster"].includes(userData.level))
      endpoint = "/master/products";
    else if (["corporation", "subcorp"].includes(userData.level))
      endpoint = `/corporation/${userData._id}/products`
    else endpoint = "/products";


    try {
      const response = await mainApi.get(endpoint);
      const products = endpoint == "/master/products" || endpoint == "/products" ? response.data.products : response.data.data.products;

      const productsToSelect = products.map(product => ({
        value: product._id || product.id,
        label: product.name
      })
      )

      localStorage.setItem("@uxtech-mlm/products", JSON.stringify(productsToSelect));

    } catch (err) {
      if (err.response) console.log(err.response.data);
    }
  };

  handleSubmit = async () => {

    var eventDetail = {"detail": {"button": "Click:BP:IndiqueOPan:Login" }}
    var interactionClick = new CustomEvent("event-login", eventDetail)
    document.body.dispatchEvent(interactionClick);

    this.setState({ btnTitle: "Carregando...", loading: true });
    localStorage.clear();
    const { password, login, email, needCaptcha, captcha, sendEmail } = this.state;

    const rawLogin = login.replace(/\D/g, "");

    const data = { password, email };

    if (needCaptcha) {
      if (!captcha) {
        this.showMessage('warning', "Insira o captcha");
        return;
      } else {
        data.captcha = captcha.toLowerCase();
      }
    }

    try {
      const response = await mainApi.post("/internal/signin", data);
      const { token, refreshToken, expiresIn, user } = response.data;

      let sessionTime = expiresIn * 60 * 1000;

      localStorage.setItem("@uxtech-mlm/UserToken", "Bearer " + token);
      localStorage.setItem("@uxtech-mlm/UserLevel", user.level);
      this.getProducts(user)

      if(user.analysis && user.analysis !== 'approved' && user.affiliate === true) {
        localStorage.setItem("@uxtech-mlm/UserAnalysis", user.analysis);

        if (user.resetPassword) {
          this.props.history.push("/password/change");
        } else {
          this.props.history.push("/preregister");
        }

        return;
      }

      localStorage.setItem("@uxtech-mlm/UserRefreshToken", refreshToken);
      localStorage.setItem("@uxtech-mlm/UserSessionTime", sessionTime);
      localStorage.setItem("@uxtech-mlm/LastTokenRefresh", Date.now());
      localStorage.setItem("@uxtech-mlm/UserId", user._id);
      localStorage.setItem("@uxtech-mlm/UserResetPassword", user.resetPassword);
      localStorage.setItem("@uxtech-mlm/UserName", user.name);
      localStorage.setItem("@uxtech-mlm/UserLevel", user.level);
      localStorage.setItem("@uxtech-mlm/finishedTour", user.finishedTour);
      localStorage.setItem("@uxtech-mlm/Affiliate", user.affiliate);
      localStorage.setItem("@uxtech-mlm/hasManager", user.hasManager);
      localStorage.setItem("@uxtech-mlm/isIntegratedPartner", user.isIntegratedPartner)
      localStorage.setItem("@uxtech-mlm/linkGenerationTerms", user.linkGenerationTerms)
      localStorage.setItem("@uxtech-mlm/hasSupport", Boolean(user.support));

      if (email) localStorage.setItem("@uxtech-mlm/Email", email)
      if (user.socialName) localStorage.setItem("@uxtech-mlm/SocialName", user.socialName);
      localStorage.setItem("@uxtech-mlm/finishedPagesTour", user.finishedPagesTour);

      if(user.agencyId) localStorage.setItem("@uxtech-mlm/AgencyId", user.agencyId);
      if(user.agencyName) localStorage.setItem("@uxtech-mlm/AgencyName", user.agencyName);
      if(user.corporationName) localStorage.setItem("@uxtech-mlm/CorpName", user.corporationName);
      if(user.corporationSlug) localStorage.setItem("@uxtech-mlm/CorporationSlug", user.corporationSlug);
      if(user.supervisorId) localStorage.setItem("@uxtech-mlm/SupervisorId", user.supervisorId);


      eventDetail = {"detail": {"crmid": user._id }}

      interactionClick = new CustomEvent("event-login-success", eventDetail)
      document.body.dispatchEvent(interactionClick);

      if (user.resetPassword) {
        this.props.history.push("/password/change");
        return;
      }

      if (['corporation', 'subcorp', 'master', 'submaster'].includes(user.level)) {

        this.props.history.push("/admin/dashboard");
      } else if (['private', 'executive', 'exclusive', 'superintendent'].includes(user.level)) {

        this.props.history.push("/admin/profile");
      } else {
        this.props.history.push("/admin/operator/home");
      }
      return;
    } catch (err) {
      if (!err.response) {
        if (err.code === "ECONNABORTED") {
          this.showMessage("error", "Tempo máximo de espera atingido.");
          return;
        } else {
          this.showMessage(
            "error",
            "Não foi possível se comunicar com o servidor, verifique sua internet e tente novamente."
          );
          return;
        }
      }

      if (err.response?.status === 429 &&  typeof err.response?.data === 'string'){
        this.showMessage("warning", err.response.data);
      }

      if (err.response.status === 422 && err.response.data.captcha) {
        this.setState({
          needCaptcha: true,
          captchaSvg: err.response.data.captcha,
          btnTitle: "Entrar",
          loading: false,
        });
        this.showMessage("warning", err.response.data.message);
        return;
      }

      if (err.response && err.response.data.error) {
        if (err.response.data.error === "wrong-password") {
          this.showMessage('warning', "Senha incorreta.");
        }
      }
      if (err.response && err.response.data.message) {
        this.showMessage("warning", err.response.data.message);
      }
    } finally {
      this.resetCaptcha()
    }
  };

  resetCaptcha() {
    this.setState({ captcha: '' });
  }

  handleKeyPressedOnDocument = (event) => {
    if (event.keyCode === 13 && !this.state.swalMessageShow) {
      this.handleSubmit();
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { loginType } = this.props

    if(loginType !== prevProps.loginType) {
      this.resetInputLogin()
    }
  }

  showLoginByCode(bool) {

    this.setState({ showCorpInputs: bool, sendEmail: bool })
    this.resetInputLogin()
  }

  resetInputLogin() {
    this.setState({ login: '' })
  }

  render() {
    const {
      loading,
      swalMessageShow,
      swalMessage,
      needCaptcha,
      captchaSvg,
      showCorpInputs,
      sendEmail
    } = this.state;
    let logo;

    const { loginType } = this.props;
    if (ISClubeAmigo) logo = clubeAmigoLogo
    else if (loginType === "cnpj") logo = config.LOGO;
    else logo = config.PRODUCT_LOGO;

    const date = new Date();

    let loginMask,
      placeholder = "",
      showEmailField = false;

    if (loginType === "cnpj") {
      loginMask = "99.999.999/9999-99";
      placeholder = "Insira o CNPJ";
      showEmailField = true
      if(!sendEmail)
        loginMask = "9999";
    } else {
      loginMask = "999.999.999-99";
      placeholder = "Insira o CPF";
      showEmailField = false
    }



    let swalTitle = "";

    if (swalMessage[0] === "success") swalTitle = "Sucesso!";
    else if (swalMessage[0] === "warning") swalTitle = "Temos um problema!";
    else if (swalMessage[0] === "error") swalTitle = "Ocorreu um erro!";
    else if (swalMessage[0] === "info") swalTitle = "Cadastro Indisponível";

    return (
      <>
        <Dialog
          type={swalMessage[0]}
          show={swalMessageShow}
          title={swalTitle}
          onConfirm={() => this.setState({ swalMessageShow: false })}
          text={swalMessage[1]}
        />
        <div className="kv-form-container">
          <div className="login-elements">
            <img
              className={
                config.CLIENT === "agx" ? "brand-logo-agx" : "brand-logo"
              }
              alt="Logo Banco Pan"
              src={logo}
            />

            <div className={loginType === "cnpj" ? "signin-helpers hide-element" : "signin-helpers"}>
              <h5>Já possui cadastro?</h5>
              <p>Entre com seus dados</p>
            </div>

            <div className="form-group">
              <TextField
                InputProps={{
                  classes: {
                    input: "mui-input-control",
                    root: "mui-input-control",
                  },
                }}
                InputLabelProps={{
                  classes: {
                    root: "mui-input-control mui-label",
                  },
                }}
                fullWidth
                variant="outlined"
                label="Email"
                type="email"
                value={this.state.email}
                onChange={(event) => {
                  this.setState({ email: event.target.value });
                }}
              />
            </div>

            <div className="form-group">
              <TextField
                InputProps={{
                  classes: {
                    input: "mui-input-control",
                    root: "mui-input-control",
                  },
                }}
                InputLabelProps={{
                  classes: {
                    root: "mui-input-control mui-label",
                  },
                }}
                fullWidth
                variant="outlined"
                label="Senha"
                type="password"
                value={this.state.password}
                onChange={(event) => {
                  this.setState({ password: event.target.value });
                }}
              />
            </div>

            {needCaptcha && (
              <div className="captcha-wrapper">
                <p>Insira o texto da imagem:</p>
                <div className="captcha-box">{renderHTML(captchaSvg)}</div>
                <input
                  ref={this.captchRef}
                  value={this.state.captcha}
                  placeholder="Insira o captcha aqui"
                  onChange={(e) => {
                    this.setState({ captcha: e.target.value });
                  }}
                ></input>
              </div>
            )}
            <Button
              fill
              block
              className="btn-login"
              onClick={() => {
                this.handleSubmit()
              }}
              disabled={loading}
            >
              {this.state.btnTitle}
            </Button>
          </div>

          <a
            className="agx-link"
            href="https://www.agxsoftware.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            AGX Software © {date.getFullYear()}
          </a>
        </div>
      </>
    );
  }
}

export default SigninForm;
