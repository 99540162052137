import styled from 'styled-components'

export const Wrapper = styled.div `    
        
`

export const WrapperColor = styled.div `
  background-color: ${props => props.color ? props.color : 'grey'};

  padding: 15px;

  border-${ props => props.radius }-left-radius: 15px;
  border-${ props => props.radius }-right-radius: 15px;  
`

export const CenterFlex = styled.div `
  display: flex;
  justify-content: center;
  align-itens: center;  

  border-${props => props.radius}-left-radius: 15px;
  border-${props => props.radius}-right-radius: 15px;  

  flex: 1;
  font-size: 35px;
`

export const Description = styled.p `  
  font-size: 14px;
  text-align: center;

  margin: 0;
`

export const Title = styled.h1 `
  margin: 0;
  font-size: 30px;
  
  letter-spacing: 2px;
  font-weight: bolder;
`

export const Number = styled.h1 `
  font-size: 40px;
  margin: 0 10px 10px 10px;
  font-weight: bolder;  
`

export const Percentage = styled.h1 `
  font-size: 40px;
  margin: 0 10px;  
  font-weight: bolder;
  color: ${props => props.percentage > 0 ? '#008603' : '#fe829d'};
`