import React from 'react'
import { Description } from './style'

const DocumentationDescription = ({ name, type, required, text, example }) => {
  
  return (
    <Description >
      <td style={{ padding: '10px 10px 10px 15px' }}>
        <span>{name}</span>
        <div >{required ? 'required' : ''}</div>
      </td>
      <td style={{ padding: '10px 10px 10px 15px' }}>
        <span>{type}</span>
        <p >{text}</p>
        {
          example ? (
            <span>Possíveis valores: [{String(example).split(',').join(', ')}]</span>
          ) 
          : 
          <></>
        }
      </td>
    </Description>
  )
}

export default DocumentationDescription