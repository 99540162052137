import React, { Component } from "react";

import "assets/css/styles.css";
import SignupForm from "components/SignupForm";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MahoeIcon from "components/MahoeIcons";

import queryString from "query-string";
import getMuiTheme from "utils/getMuiTheme";
import PlatformSelectModal from "components/PlatformSelectModal";
import { isMobile } from "react-device-detect";
import config from "utils/serverVars";
import { Dialog } from "components/Alert/CustomAlert";

import FinishedSignup from 'components/FinishedSignup';
import ModalBonification from 'components/ModalBonification'
import AfiliadosIntructions from "./AfiliadosIntructions";
import ClubeAmigoIntructions from "./ClubeAmigoIntructions";
import { ISClubeAmigo } from "utils/environment";


const linkIOS = "https://apps.apple.com/br/app/pan-cart%C3%B5es/id1410400504";
const linkAndroid =
  "https://bancopan.onelink.me/zIun?pid=Consig_QR&af_channel=Afiliados&c=Consignado_AutoContrata%C3%A7%C3%A3o&af_web_dp=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dbr.com.bancopan.cartoes%26hl%3Dpt_BR";

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowHeight: 0,
      windowWidth: 0,
      showPlatformModal: false,
      accountLink: undefined,
      finishedSignup: false,
      email: null,
      id: null,
      open: false
    };
  }

  updateDimensions = () => {
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    });
  };

  togglePlatformModal = () => {
    this.setState({ showPlatformModal: !this.state.showPlatformModal });
  };

  componentDidMount() {

    if (isMobile)
      this.setState({
        accountLink: linkAndroid,
      });
    else
      this.setState({
        accountLink: undefined,
      });

    const queryStrings = queryString.parse(this.props.location.search)
    const IsInvite = queryStrings.invite || queryStrings.agencyId || queryStrings.agencyToken || queryStrings.managerToken
    if(!IsInvite && ISClubeAmigo){
      this.props.history.push("/");
    }
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  getwindowHeight = () => {
    let height = this.state.windowHeight;
    if (height < 500) height = 500;
    return height;
  };

  getwindowHeighForMobile = () => {
    let height = this.state.windowHeight;
    let width = this.state.windowWidth;

    if (width > 940) height = 0;

    return height;
  };

  finishSignup = (email, id) => {
    localStorage.setItem("@uxtech-mlm/Attempts", 0)
    this.setState({ email, id, finishedSignup: true })
  }

  render() {
    const queryStrings = queryString.parse(this.props.location.search);
    const invite = queryStrings.invite;
    const agencyId = queryStrings.agencyId;
    const agencyToken = queryStrings.agencyToken;
    const managerToken = queryStrings.managerToken
    const { showPlatformModal, accountLink, finishedSignup, email, id, open } = this.state;

    let inviteCode = "";
    let mode = "";
    let isCorpInvite = false;
    let isSellerInvite = false;
    if (invite) {
      inviteCode = invite;
      isSellerInvite = true;
      mode = "common-invite";
    } else if (agencyId) {
      inviteCode = agencyId;
      mode = "agency-invite";
      isCorpInvite = true;
    } else if (agencyToken) {
      inviteCode = agencyToken;
      mode = "admin-invite";
      isCorpInvite = true;
    } else if (managerToken) {
      inviteCode = managerToken
      mode = "managerToken"
      isCorpInvite = true;
    }


    if (queryStrings && Object.keys(queryStrings).length >= 1 && !mode.trim()) {
      if (!queryStrings.unlockPan && !ISClubeAmigo) {
        return (
          <Dialog type='warning' title="Link Inválido" text="Link de convite inválido! Verifique seu link e tente novamente mais tarde." onConfirm={()=>{}} />
        )
      }
    }

    return (
      <div>
        <ModalBonification show={open} onClose={() => { this.setState({ open: false }) }} />
        <PlatformSelectModal
          show={showPlatformModal}
          ios={linkIOS}
          android={linkAndroid}
          onHide={this.togglePlatformModal}
        />
        <div
          className="signup-grid"
          // style={{ minHeight: this.getwindowHeight() }}
          style={{ maxHeight: '100%' }}
        >
          <div
            className={config.CLIENT === "agx" ? "agx-bg" : "signup-tips"}
            style={{ minHeight: this.getwindowHeighForMobile() }}
          >
            {config.CLIENT === "pan" && (
              <>
                {(isCorpInvite) ?
                  <></>
                  :
                  <img className="login-pan-logo" alt="logo do banco pan" src={config.LOGO_ALT} />
                }

                {!isCorpInvite && (!isSellerInvite || ISClubeAmigo) ?
                  <>
                    {ISClubeAmigo
                      ? <ClubeAmigoIntructions />
                      : <AfiliadosIntructions
                        accountLink={accountLink}
                        togglePlatformModal={this.togglePlatformModal}
                        onOpen={() => {
                          this.setState({ open: true })
                        }}
                      />
                    }
                    {this.state.windowWidth < 940 && (
                      <div className="Signup-arrow" style={{ textAlign: "center" }}>
                        <MahoeIcon
                          name="arrow-go"
                          fill="#FFF"
                          size="32px"
                          className="go-arrow"
                          style={{ margin: 5 }}
                          onClick={() => (window.location.href = "#signup")}
                        />
                      </div>
                    )}
                  </>
                  :
                  <>
                    <div className="brand-logo-corporation-container">
                      <img className="brand-logo-corporation" alt="logo do produto" src={config.PRODUCT_LOGO_WHITE}></img>
                    </div>

                    {this.state.windowWidth < 940 && (
                      <div style={{ textAlign: "center", marginTop: -120 }}>
                        <MahoeIcon
                          name="arrow-go"
                          fill="#FFF"
                          size="32px"
                          className="go-arrow"
                          style={{ margin: 5 }}
                          onClick={() => (window.location.href = "#signup")}
                        />
                      </div>
                    )}
                  </>
                }
              </>
            )}
          </div>
          <div className="form-login" id="signup"
            style={{ minHeight: this.getwindowHeight(), overflow: 'auto' }}
          >
            <MuiThemeProvider theme={getMuiTheme({ team: false })}>
              {finishedSignup ?
                <FinishedSignup email={email} id={id} />
                :
                <SignupForm
                  {...this.props}
                  inviteCode={inviteCode}
                  mode={mode}
                  isCorpInvite={isCorpInvite}
                  unlockPan={queryStrings.unlockPan}
                  finishSignup={this.finishSignup}
                />
              }

            </MuiThemeProvider>
          </div>
        </div>
      </div>
    );
  }
}

export default Signup;
