import React, { Component } from 'react';

import { Alert } from 'react-bootstrap';
import UserContext from "context/userContext";

import mainApi from 'services/mainApi'
import { translateLevel } from 'utils/translate';

export default class AlertLogo extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: [],
    }
  }

  async openAlert(){
    const { userData } = this.props
    const { data } = this.state

    if((userData.level === 'seller' && userData.corpName) || userData.level === 'supervisor' || userData.level === 'administrator') {
      try {
        const response = await mainApi.get("/check", {
          headers: { Authorization: userData.token },
        });

        data.push({
          show: response.data.data.isZero,
          message: `Esta loja possui bonificação igual a 0%. ${translateLevel('seller', userData?.corpId)}es, ${translateLevel('supervisor', userData?.corpId)} e ${translateLevel('administrator', userData?.corpId)} não receberão bonificação pelas vendas realizadas.`
        })
      } catch(err) {
        console.log(err.response);
      }

    } else if(['corporation', 'subcorp'].includes(userData.level)) {

      try {
        const response = await mainApi.get("/corporation/check", {
          headers: { Authorization: userData.token },
        });

        let auxMessageData = ''

        if(response.data.data.agencies.length > 1) auxMessageData = response.data.data.agencies.slice(0, response.data.data.agencies.length - 1).join(', ')

        this.setState({
          data: [{
            show: response.data.data.isCommissionZero,
            message: response.data.data.agencies.length > 1 ?
            `Existem ${response.data.data.agencies.length} lojas com bonificação igual a 0.`
              : `A loja ${response.data.data.agencies[0]} possui bonificação igual a 0.`
          }]
        })

      } catch(err) {
        console.log(err.response);
      }

    }

  }

  closeAlert = (info) => {
    this.setState({ data: this.state.data.filter(item => item !== info) })
  }

  static contextType = UserContext

  async componentDidMount() {
    this.openAlert()
  }

  render() {
    const { data } = this.state

    return(
      <>
        {data.filter(info => info.show).map((info, index) => (
          <Alert bsStyle="warning" className="alert-logo" key={index}>
            <div style={{ display: 'flex' }}>
              <span style={{ flexGrow: 1, color: 'black' }}>
                <strong>Atenção! </strong>
                {info.message}
              </span>

              <button className="button-alert" onClick={() => this.closeAlert(info)}>
                <span style={{ color: 'black' }}>X</span>
              </button>
            </div>
          </Alert> )
        )}
      </>
    )
  }
}
