import React, { Component } from "react"
import { Row, Col } from "react-bootstrap"
import Button from "components/CustomButton/CustomButton.jsx"
import MuiMonthSelect from "components/MuiMonthSelect";
import { getDatesList } from "utils/formatData";
import mainApi from "services/mainApi";
import SweetAlert from "sweetalert2";
import Select from "react-select";
import { contaId } from "utils/CommonData";
import getUserData from "utils/checkUser";
import crypto from 'crypto'

class DownloadPaymentReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      fileIsReady: false,
      selectedCorp: '',
      selectedSituation: '',
      selectedPartner: '',
      selectedProduct: '',
      corporations: [],
      partners: [],
      products: [],
      selectedDate: {
        label: '',
        value: ''
      },
      userData: null,
      checkQuery: '',
      status: 'Carregando...'
    }
  }

  handleClick = async (event, howToBeSent) => {

    event.preventDefault()

    if(howToBeSent == 'email') {

      SweetAlert.fire({
        title: 'Atenção?',
        text: (howToBeSent === 'email')  ? 'O relatório será enviado para o e-mail cadastrado em sua conta! Confirma?' : 'O download pode',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Cancelar'
      }).then(async (result) =>{

        if (result.isConfirmed) {
          await this.start(howToBeSent)
        }

      })

    }

    else {

      if(!this.state.isLoading) {

        await this.start(howToBeSent)

      }


    }

  }

  start = async (howToBeSent) => {

    const { selectedCorp, selectedAgency, selectedSituation, selectedPartner, selectedProduct } = this.state

    if (!this.state.selectedDate || !this.state.selectedDate.value) {
      SweetAlert.fire('Aviso', 'Por favor selecione uma data', 'warning')
      return
    }

    if (!selectedProduct || !selectedProduct.value) {
      SweetAlert.fire('Aviso', 'Por favor selecione um produto', 'warning')
      return
    }

    if (!selectedSituation || !selectedSituation.value) {
      SweetAlert.fire('Aviso', 'Por favor selecione um tipo', 'warning')
      return
    }

    const [month, year] = this.state.selectedDate.value.split('-')
    const situation = selectedSituation.value
    const productId = selectedProduct.value

    let query = '&report=payment'
    const body = {
      report: 'payment'
    }

    if (month) {
      body.month = month
      query += `&month=${month}`
    }
    if (year) {
      body.year = year
      query += `&year=${year}`
    }
    if (situation) {
      body.situation = situation
      query += `&situation=${situation}`
    }
    if (productId) {
      body.productId = productId
      query += `&productId=${productId}`
    }
    if(['master', 'submaster'].includes(this.state.userData?.level)){
      if (!!selectedCorp && selectedCorp.value !== 'all') {
        if (selectedCorp.value) {
          body.corporationId = selectedCorp.value
          query += `&corporationId=${selectedCorp.value}`
        }
      }
    }else if(['corporation', 'subcorp'].includes(this.state.userData?.level) && howToBeSent === 'download'){
      if (!!selectedPartner && selectedPartner.value !== 'all') {
        if (selectedPartner.value) {
          body.agencyId = selectedPartner.value
          query += `&agencyId=${selectedPartner.value}`
        }
      }
    }


    if(howToBeSent) {
      body.howToBeSent = howToBeSent
      query += `&howToBeSent=${howToBeSent}`
    }

    this.setState({ query })

    try {

      if (['master', 'submaster','corporation', 'subcorp', 'administrator'].includes(this.state.userData?.level) && howToBeSent === 'download') {

        let alreadyHasFile = await this.checkForGeneratedFiles()

        if(!alreadyHasFile && !this.state.isLoading) {

          this.setState({ isLoading: true, status: 'Preparando...' })

          const generateRequestResponse = await mainApi.post(`/reports/payment`, body)

          let responseData = generateRequestResponse?.data?.data

          if(responseData?.generatedReportRequestId) {
            this.setState({ query: query + `&generatedReportRequestId=${responseData.generatedReportRequestId}` })
          }

          this.waitForFileToBeReady()

        }


      }
      else{
        const response = await mainApi.post(`/reports/payment`, body);

        SweetAlert.fire('Sucesso!', response.data?.message, 'success')
      }

    } catch (err) {
      SweetAlert.fire('Ops!', err?.data?.message || "Erro Desconhecido", 'warning')
      console.warn(err);
      this.setState({ isLoading: false})
      return
    }

  }

  clearIntervals = () => {
    var interval_id = window.setInterval('', 9999)
    for (var i = 1; i < interval_id; i++) window.clearInterval(i)
  }

  waitForFileToBeReady = async () => {
    this.clearIntervals()
    let loading = true
    setInterval(async () => {
      if(loading) loading = await this.checkForGeneratedFiles()
      else this.clearIntervals()
    }, 5000)
  }

  checkForGeneratedFiles = async () => {
    if(this.state.query) {
      let response = await mainApi.get(`/reports/check?${this.state.query}`)
      let responseData = response?.data?.data
      let { fileUrl, loading, status }  = responseData
      this.setState({ isLoading: loading, status })
      if(fileUrl) {
        this.downloadFile(fileUrl)
        this.setState({ query: '', isLoading: false })
      }
      return loading
    }
    return false
  }

  downloadFile = (fileUrl) => {
    let a = document.createElement("a")
    a.href = fileUrl
    // a.target = '_blank'
    a.download = fileUrl
    document.body.appendChild(a)
    a.click()
    // window.open(fileUrl, "_self")
  }

  onDateSelect = date => {
    this.setState({ selectedDate: date })
  };


  getCorporationsNames = async () => {
    const { userData } = this.state

    if (!['master', 'submaster'].includes(userData?.level)) return

    try {
      const response = await mainApi.get('/corporations/names');
      let aux = response?.data.data.corporations.map(partner => { return { label: partner.name, value: partner._id } })
      aux.unshift({ label: "Todas as Corporações", value: 'all' })
      this.setState({
        corporations: aux
      })
    } catch (error) {

    }
  }

  getCorpPartners = async () => {
    const { userData } = this.state

    if (!['corporation', 'subcorp'].includes(userData?.level)) return

    try {
      const response = await mainApi.get('/partner/names');
      let aux = response?.data.data.partners.map(partner => { return { label: partner.name, value: partner.id } })
      aux.unshift({ label: "Todos os Parceiros", value: 'all' })
      this.setState({
        partners: aux
      })
    } catch (error) {

    }
  }

  getSituation = () => {
    let situation = []
    const { selectedProduct } = this.state

    if (selectedProduct && contaId === selectedProduct.value) {
      situation = [
        { label: 'Contas Abertas', value: 'paid' },
        { label: 'Contas Pendentes', value: 'pending' },
        { label: 'Contas Recusadas', value: 'refused' }
      ]
    }
    if (selectedProduct && contaId !== selectedProduct.value) {
      situation = [
        { label: 'Aprovado', value: 'paid' },
        { label: 'Análise', value: 'pending' },
        { label: 'Recusado', value: 'refused' }
      ]
    }

    return situation
  }

  setUserData = async () => {
    const userData = await getUserData(this.props.history)
      this.setState({
        products: userData.products,
      })
      this.setState({ userData })
  }

  componentDidMount = async () => {
    await this.setUserData()
    this.getCorporationsNames()
    this.getCorpPartners()
  }



  render() {
    const {
      corporations,
      selectedCorp,
      selectedSituation,
      userData,
      partners,
      selectedPartner,
      products,
      selectedProduct,
      isLoading
    } = this.state

    const IS_MASTER = ['master', 'submaster'].includes(userData?.level)
    const IS_CORP = ['corporation', 'subcorp'].includes(userData?.level)
    return (
      <div className="content">
        <div className="container-fluid">
          <Row>
            <Col md={6}>
              <div className="card" style={{ padding: '50px 20px 50px 20px' }}>
                <Row style={{ width: '100%', margin: 0 }}>
                  <Col md={12} style={{ paddingBottom: 15, paddingRight: 0, paddingLeft: 0 }}>
                    <MuiMonthSelect
                      disabled={isLoading}
                      className="custom-select"
                      month={this.selectedDate}
                      size="lg"
                      onMonthSelect={this.onDateSelect}
                      availableDates={getDatesList('2020-08')}
                    />
                  </Col>

                  <Col md={12} style={{ paddingBottom: 15, paddingRight: 0, paddingLeft: 0 }}>
                    <Select
                      disabled={isLoading}
                      className="custom-select"
                      placeholder="Selecione um Produto"
                      options={products}
                      value={selectedProduct}
                      onChange={(product) => {
                        this.setState({
                          selectedProduct: product,
                          selectedSituation: ''
                        })
                      }}
                    />
                  </Col>

                  <Col md={12} style={{ paddingBottom: 15, paddingRight: 0, paddingLeft: 0 }}>
                    <Select
                      disabled={isLoading}
                      className="custom-select"
                      placeholder="Selecione um Tipo"
                      options={this.getSituation()}
                      value={selectedSituation}
                      noOptionsMessage={() => {
                        if (!selectedProduct) return "Selecione um produto"
                      }}
                      onChange={(situation) => {
                        this.setState({
                          selectedSituation: situation
                        })
                      }}
                    />
                  </Col>

                  {
                    IS_MASTER &&
                    <Col md={12} style={{ paddingBottom: 15, paddingRight: 0, paddingLeft: 0 }}>
                      <Select
                        disabled={isLoading}
                        className="custom-select"
                        placeholder="Selecione uma Corporação"
                        options={corporations}
                        value={selectedCorp}
                        onChange={(corp) => {
                          this.setState({
                            selectedCorp: corp
                          })
                        }}
                      />
                    </Col>
                  }

                  {
                    IS_CORP &&
                    <Col md={12} style={{ paddingBottom: 15, paddingRight: 0, paddingLeft: 0 }}>
                      <Select
                        className="custom-select"
                        placeholder="Selecione um Parceiro"
                        options={partners}
                        value={selectedPartner}
                        onChange={(partner) => {
                          this.setState({
                            selectedPartner: partner
                          })
                        }}
                      />
                    </Col>
                  }
                </Row>
                <div style={{ margin: 0 }} className='footer__buttons'>
                  <Button bsStyle="primary" fill onClick={(event) => this.handleClick(event, 'email')}>
                    <i aria-hidden="true" className="af-button-icon fa fa-envelope"></i>&nbsp;
                    Solicitar por e-mail
                  </Button>
                  {
                    isLoading ?
                    <Button disabled={isLoading} bsStyle="primary" fill onClick={(event) => this.handleClick(event, 'download')}>
                      <i aria-hidden="true" className="af-button-icon fa fa-spinner fa-spin"></i> {this.state.status}
                      </Button> :
                    <Button bsStyle="seondary" fill onClick={(event) => this.handleClick(event, 'download')}>
                      <i aria-hidden="true" className="af-button-icon fa fa-download"></i>&nbsp;
                      Baixar Relatório
                    </Button>
                  }
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default DownloadPaymentReport;
