import React, { useState, useEffect } from 'react'
import { Grid, Col, Row, Modal } from 'react-bootstrap'
import Moment from 'moment'

import mainApi from "services/mainApi";

import MahoeIcon from 'components/MahoeIcons'
import { ConfirmationAlert, Dialog, LoadingAlert } from 'components/Alert/CustomAlert'

import DatePicker from 'react-datepicker'
import { getMonthAndYear } from 'utils/formatData'
import Select from 'react-select'

import getUserData from 'utils/checkUser'
import dev from "utils/dev";
import './styles.css'



function RequestCSVModal({ visible, handleClose, history, productId }) {
  const [userData, setUserData] = useState({})

  const [selectDate, setSelectDate] = useState()
  const [selectType, setSelectType] = useState()
  const [extractTypes, setExtractTypes] = useState([])
  const [csv, setCSV] = useState('')

  const [swalMessageShow, setSwalMessageShow] = useState(false)
  const [swalShowLoading, setSwalShowLoading] = useState(false)
  const [swalAlertShow, setSwalAlertShow] = useState(false)
  const [swalText, setSwalText] = useState('')
  const [swalType, setSwalType] = useState('warning')
  const [swalTitle, setSwalTitle] = useState('')

  // const IS_MASTER = ["master", "submaster"].includes(userData?.level)
  const IS_PARTNER = (["administrator"].includes(userData?.level))


  const getExtractTypes = () => {
    let options = []
    if (["master", "submaster"].includes(userData?.level))
      options.push(
        { label: 'Extrato Corporações', value: 'corporation' },
        { label: 'Extrato Afiliados', value: 'affiliate' }
      )
    if (["corporation", "subcorp"].includes(userData?.level)) {
      options.push({ label: 'Extrato Lojas', value: 'agency' })
      if (!userData?.hasManager) options.push({ label: 'Extrato Vendedores', value: 'seller' })
    }
    if (['administrator'].includes(userData?.level))
      options.push(
        { label: 'Extrato Vendedores', value: '/corp/extract/csv/sellers' }
      )
    setExtractTypes(options)
  }

  const UserData = async () => {
    const userData = await getUserData(history);
    setUserData(userData)
  }

  const handleRequestCSV = async () => {
    if (!productId) return
    const year = Moment(selectDate).format('YYYY')
    const month = Moment(selectDate).format('MM')
    let query = `?year=${year}&month=${month}&productId=${productId}`
    const body = {
      report: 'extract',
      year: year,
      month: month,
      productId: productId
    }
    if (selectType?.value) {
      body.extractType = selectType.value
      query += `&extractType=${selectType.value}`
    }

    setSwalShowLoading(true)
    try {
      const route = `/reports/extract`
      const response = await mainApi.post(route, body);

      console.log(response)
      setSwalType('success')
      setSwalTitle('Sucesso')
      setSwalText(response?.data.message)
      setSwalShowLoading(false)
      setSwalAlertShow(true)
    } catch (err) {
      setSwalType('warning')
      setSwalTitle('atenção')
      setSwalText(err?.message)
      setSwalShowLoading(false)
      setSwalAlertShow(true)
      dev.log(err);
    }

  }

  const handleAdminCsv = async () => {
    let responseResult
    let query = ''
    query += `?date=${Moment(selectDate).format('YYYY-MM')}`
    query += `&product=${productId}`

    try {
      const response = await mainApi.get(
        `/admin/extract/sellers/extract${query}`
      );

      setCSV(response.data.csv)
      if (response.data.csv) {
        createDownloadLink('sellers', response.data.csv);
      } else {
        setSwalAlertShow(true)
        setSwalTitle('Documento não disponível')
        setSwalText('O documento solicitado nao possui nenhum registro.')
      }

      responseResult = true
    } catch (err) {
      responseResult = false
      console.log(err);
    }

    if (responseResult) {
      var eventDetail = { "detail": { "button": "Click:BP:IndiqueOPan:BaixarExtratoVendedores" } };
      var interactionClick = new CustomEvent("interactionClick", eventDetail);
      document.body.dispatchEvent(interactionClick);
    }
  }

  const createDownloadLink = (name, csv) => {

    let file = "text/CSV;charset=utf-8,%EF%BB%BF" + encodeURI(csv);
    let filename = `${name}_${Moment(selectDate).format('YYYY-MM')}`;

    let a = document.createElement('a');

    a.href = "data:" + file;
    a.download = filename + ".csv";

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    this.setState({ csv: '' })
  }

  useEffect(() => {
    UserData()
  }, [])

  useEffect(() => {
    if (!userData) UserData()
    getExtractTypes()
  }, [userData])

  useEffect(() => {
    setSelectDate()
    setSelectType()

  }, [visible])

  return (
    <>
      <Dialog
        className="dialog"
        type={swalType}
        show={swalAlertShow}
        title={swalTitle}
        onConfirm={() => { setSwalAlertShow(false) }}
        text={swalText}
      />
      <LoadingAlert show={swalShowLoading} title="Carregando..." />
      <ConfirmationAlert
        type={'warning'}
        show={swalMessageShow}
        title={"Atenção"}
        onConfirm={() => {
          handleRequestCSV()
          setSwalMessageShow(false)
        }}
        onCancel={() => {
          setSwalMessageShow(false)
        }}
        text={swalText}
      />
      <Modal
        show={visible}
        bsSize="md"
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title>Solicitação de extrato</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "23vh", overflowY: "auto" }}>
          <Grid fluid style={{ paddingRight: 15 }}>
            <Row style={{ marginBottom: '10px' }}>
              <Col className="form-group" md={10} lg={10}>
                <DatePicker
                  className="datepicker-theThird"
                  dateFormat="MM/yyyy"
                  locale="pt-BR"
                  isClearable={true}
                  customInput={
                    <div className="div-datepicker">
                      <div className="col-6">
                        <span className="placeholder-datepicker">
                          {selectDate ? "Mês selecionado:" : "Selecione o mês do extrato a ser solicitado"}
                        </span>
                      </div>
                      <div className="col-3">
                        <p className="date-datepicker">
                          {selectDate ? getMonthAndYear(selectDate) : ""}
                        </p>
                      </div>
                      <div
                        className="col-3"
                        style={{ alignSelf: "center" }}
                      >
                        {selectDate ? (
                          <></>
                        ) : (
                          <MahoeIcon
                            aria-describedby={"iconTooltip"}
                            name="info"
                            size="23px"
                            fill="#039BE5"
                          />
                        )}
                      </div>
                    </div>
                  }
                  selected={selectDate}
                  showMonthYearPicker
                  onChange={date => {
                    setSelectDate(date)
                  }}
                  minDate={new Date('12-12-2019')}
                  maxDate={new Date()}
                />
              </Col>
            </Row>
            {
              !IS_PARTNER &&
              <Row style={{ marginBottom: '10px' }}>
                <Col className="form-group" md={10} lg={10}>
                  <Select
                    className="custom-select"
                    placeholder="Selecione o Tipo de Extrato"
                    options={extractTypes}
                    value={selectType}
                    onChange={(event) => {
                      setSelectType(event)
                    }}
                  />
                </Col>
              </Row>
            }

          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn  btn-mlm btn-mlm-sm" onClick={handleClose}> Fechar </button>
          {
            IS_PARTNER
              ? <button
                id="data-tour-extract-master-button-affiliate"
                onClick={() => {
                  if (!selectDate) {
                    setSwalType('warning')
                    setSwalTitle('Atenção')
                    setSwalText('Selecione um mês!')
                    setSwalAlertShow(true)
                    return
                  } else {
                    handleAdminCsv()
                  }
                }}
                className="btn btn-fill btn-mlm btn-mlm-sm btn-small-extract"
              >
                Baixar extrato
               </button>

              : <button
                id="data-tour-extract-master-button-affiliate"
                onClick={() => {
                  if (!selectDate) {
                    setSwalType('warning')
                    setSwalTitle('Atenção')
                    setSwalText('Selecione um mês!')
                    setSwalAlertShow(true)
                    return
                  }
                  if (!selectType && !IS_PARTNER) {
                    setSwalType('warning')
                    setSwalTitle('Atenção')
                    setSwalText('Selecione um tipo de extrato!')
                    setSwalAlertShow(true)
                    return
                  }
                  setSwalTitle('Atenção')
                  setSwalText('O relatório será enviado para o e-mail cadastrado em sua conta irreversivelmente. Confirma?')
                  setSwalMessageShow(true)
                }}
                className="btn btn-fill btn-mlm btn-mlm-sm btn-small-extract"
              >
                Solicitar extrato
         </button>}
        </Modal.Footer>

      </Modal>
    </>
  )
}

export default RequestCSVModal
