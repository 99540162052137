import React, { Component } from 'react';

import { Alert } from 'react-bootstrap';
import UserContext from "context/userContext";

export default class AlertLogo extends Component {
  constructor(props) {
    super(props)

    this.state = {
      show: true
    }
  }

  openAlert(){
    const { avatar } = this.context
    const { userData } = this.props

    return (avatar === 'not-found' || avatar === undefined) && ['corporation', 'subcorp'].includes(userData.level)
  }

  closeAlert = () => {
    this.setState({ show: false })
  }

  static contextType = UserContext

  render() {                   
    const { show } = this.state

    return(      
      <>      
        {(this.openAlert() && show === true) && (
          <Alert bsStyle="warning" className="alert-logo">
            <div style={{ display: 'flex' }}>
              <span style={{ flexGrow: 1, color: 'black' }}><strong>Atenção!</strong> Adicione uma imagem ao seu perfil.</span>

              <button className="button-alert" onClick={this.closeAlert}>
                <span style={{ color: 'black' }}>X</span>
              </button>
            </div>
          </Alert>                      
        )}
      </>      
    )    
  }
}
