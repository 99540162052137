import React from "react";
import logoPlaceholder from "../assets/img/logo-placeholder.png";

const awsUrl = 'https://agxsoftwarecloud.s3.amazonaws.com'

const removeLastSlashes = url => {
  while(url.substring(url.length-1) === '/') {
    url = url.substring(0, url.length-1)
  }
  return url
}

const removeEmptyProps = object => {
  const keys = Object.keys(object)
  const values = Object.values(object)
  const newObject = {}

  for (let index = 0 ; index < keys.length; index++) {
    let value = values[index]
    if (!!value) {
      let name = keys[index]
      newObject[name] = value
    }
  }

  return newObject
}

const logoUrl = (image) => image ? removeLastSlashes(awsUrl) + '/' + image : logoPlaceholder

const getMonthAndYear = date => {
  date = new Date(date)
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  return `${month < 10 ? '0'+month : month}/${year}`
}

const getMonthAndYearEN = date => {
  date = new Date(date)
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  return `${year}-${month < 10 ? '0'+month : month}`
}


const objectToParams = (params, additional) => {
  if(!params) return ''
  let stringParams = additional ? '&' : '?'
  const values = Object.values(params)
  const keys = Object.keys(params)
  for (let index = 0 ; index < values.length; index++) {
    if (values[index] && values[index] !== '') {
      let value = typeof(values[index]) === 'string' ? values[index]?.trim() : values[index]
      stringParams = index === 0 ?
        `${stringParams?.trim()}${keys[index]}=${value}` :
        `${stringParams?.trim()}&${keys[index]}=${value}`
    }
  }

  return stringParams
}

const numToBRL = number => {
  // numToBRL(4.3) >>> R$ 4,30
  if (number === 0) return "R$ 0,00";
  if (number === null) return "n/a";
  if (!number) return "n/a";
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL"
  }).format(number);
};

const firstAndLastWord = string => {
  if (!string || !string.trim()) return "n/a";
  let words = string.split(" ");
  if (words.length === 1) return words[0];
  return `${words[0]} ${words[words.length - 1]}`;
};


const dateToUS = string => {
  if (!string || !string.trim()) return "";
  let words = string.split("/");
  if (words.length < 3) return string;
  return `${words[2]}-${words[1]}-${words[0]}`;
};

const brlDate = date => {
  date = new Date(date);
  let monthNames = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro"
  ];
  return (
    date.getDate() +
    " de " +
    monthNames[date.getMonth()] +
    " de " +
    date.getFullYear()
  );
};

const brlSmallDate = date => {
  date = new Date(date);
  return `${date.getDate()}/${String("00" + (date.getMonth() + 1)).slice(
    -2
  )}/${date.getFullYear()}`;
};

const getMonthById = id => {
  let monthNames = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro"
  ];
  return monthNames[id];
};

const getDatesList = start => {
  let firstDate;
  if(!start)
    firstDate = new Date();
  else
    firstDate = new Date(start);
  let date = new Date();
  let limit = [date.getMonth(), date.getFullYear()];
  let startDate = [firstDate.getMonth(), firstDate.getFullYear()];

  let datesList = [];

  console.log({limit, startDate})

  for (let i = Number(startDate[1]); i <= Number(limit[1]); i++) {
    if (i === limit[1] && i === Number(startDate[1])) { // começa e termina no mesmo ano
      for (let j = startDate[0]; j <= limit[0]; j++) {
        datesList.push({
          label: `${getMonthById(j)} de ${i}`,
          value: `${j+1}-${i}`
        });
      }
    } else if (i !== limit[1] && i === Number(startDate[1])) {
      for (let j = Number(startDate[0]); j <= 11; j++) {
        datesList.push({
          label: `${getMonthById(j)} de ${i}`,
          value: `${j+1}-${i}`
        });
      }
    } else if (i === limit[1] && i !== Number(startDate[1])) {
      for (let j = 0; j <= limit[0]; j++) {
        datesList.push({
          label: `${getMonthById(j)} de ${i}`,
          value: `${j+1}-${i}`
        });
      }
    } else {
      for (let j = 0; j <= 11; j++) {
        datesList.push({
          label: `${getMonthById(j)} de ${i}`,
          value: `${j+1}-${i}`
        });
      }
    }
  }

  return datesList.reverse();
};

const convertStringIntoArray = (value) => {
  if(value) {

    return value.split(',').map(Number)
  } else {
    return []
  }
}

function toTitleCase(str) {
  if(!str) return undefined
  return str.replace(
      /\w\S*/g,
      function(txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
  );
}

function shortenValue (value, length = 25) {
  return value.length < length || value.length <= length + 3
    ? ''
    : value.slice(0, length) + '...'
}

const getPaymentStatus = status => {
  switch (status) {
    case "Pago":
    case "aprovado":
      return <span className="label label-success label-block">Aprovado</span>;
    case "conta_aberta":
      return <span className="label label-success label-block">Conta aberta</span>;
    case "aprovada":
      return <span className="label label-warning label-block">Baixar App</span>;
    case "Análise":
    case 'analise':
      return <span className="label label-warning label-block">Análise</span>;
    case "Recusado":
      return <span className="label label-danger label-block">Recusado</span>;
    case "Conta aberta":
      return <span className="label label-success label-block">Conta aberta</span>;
    case "Conta pedente":
      return <span className="label label-warning label-block">Conta pendente</span>;
    case "Conta recusada":
      return <span className="label label-danger label-block">Conta recusada</span>;
    case 'dados_iniciais':
      return <span className="label label-warning label-block">Dados iniciais</span>
    case 'selfie':
      return <span className="label label-warning label-block">Selfie</span>
    case 'reprovado':
      return <span className="label label-danger label-block">Reprovado</span>
    case 'reprovada':
      return <span className="label label-danger label-block">Reprovada</span>
    case 'contato':
      return <span className="label label-warning label-block">Contato</span>
    case 'endereco':
      return <span className="label label-warning label-block">Endereço</span>
    case 'informacoes_adicionais':
      return <span className="label label-warning label-block">Info adicionais</span>
    case 'documentos':
      return <span className="label label-warning label-block">Documentos</span>
    case 'permissoes':
      return <span className="label label-warning label-block">Permissões</span>
    case 'revisar':
      return <span className="label label-warning label-block">Revisar</span>
    case 'fotos':
      return <span className="label label-warning label-block">Fotos</span>
    case 'reenvio_fotos':
      return <span className="label label-warning label-block">Reenvio de fotos</span>
    case 'pendente':
      return <span className="label label-warning label-block">Pendente</span>
    case 'em_analise':
      return <span className="label label-warning label-block">Em análise</span>
    case 'andamento':
      return <span className="label label-warning label-block">Andamento</span>
    case 'finalizado':
      return <span className="label label-warning label-block">Finalizado</span>
    case 'envio_sms':
      return <span className="label label-warning label-block">Envio SMS</span>
    case 'inativa':
      return <span className="label label-disabled label-block">Já existente</span>
    case 'sobrescrita':
      return <span className="label label-disabled label-block">Inativa</span>
    case 'refused':
      return <span className="label label-danger label-block">Reprovada</span>
    default:
      break;
  }
};

const getIndexOfTable = (name, table) => {
  return table.findIndex(object => object.name == name)
}

const getMinimizedCPF = (cpf) => {
  const length = cpf.length
  return cpf !== 'N/A' ? `***.***.${cpf.slice(length-5,length-2)}-${cpf.slice(length-2,length)}` : cpf
}


export {
  dateToUS,
  numToBRL,
  brlDate,
  brlSmallDate,
  firstAndLastWord,
  getMonthById,
  getDatesList,
  convertStringIntoArray,
  toTitleCase,
  objectToParams,
  getPaymentStatus,
  getMonthAndYear,
  logoUrl,
  getIndexOfTable,
  removeEmptyProps,
  getMonthAndYearEN,
  getMinimizedCPF,
  shortenValue
};
