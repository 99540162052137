import styled from 'styled-components'

/* { marginLeft: "10px", paddingTop: "5px", fontSize: "20px" } */
export const Title = styled.strong `
    margin-left:10px;
    margin-top:5px;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis; 
    max-height: 20px;
    max-width:70%;
    white-space: nowrap; 
`