import React, { Component } from 'react'
import TableMui from 'components/TableMui'
import moment from 'moment'
export class HistoryTable extends Component {

  constructor(props) {
    super(props)
   
  }

  render() {
    const { rows } = this.props
    const columns = [
        {
          name: 'userName',
          label: 'Usuário',
        },
        {
          name: 'userEmail',
          label: 'Email ',
        },
        {
          name: 'pastValue',
          label: 'Valor Anterior',
        },
        {
          name: 'newValue',
          label: 'Valor Atual',
        },
        {
          name: 'createdAt',
          label: 'Data da alteração',
          options: {     
            customBodyRender: (value) => (
            <>
           { moment(value).format('DD/MM/YYYY LT')}
            </>
            )
          }
        },
    ]

    const options = {
      download: false,
      print: false,
      sort: false,
      filter: false,
      search: false,
      selectableRows: 'none',
      elevation: 0,
      serverSide: false,
      pagination: false,
      viewColumns: false,
      textLabels: {
        body: {
          noMatch: "Não há histórico de edições"
        },
      }
        
    }
     
    return (
      <TableMui
        title={null}
        data={rows}
        columns={columns}
        options={options}
      />
    )
  }
}

export default HistoryTable