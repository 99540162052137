import React, { Component } from "react";

import "assets/css/styles.css";

import config from "utils/serverVars";

import { CircularProgress } from "@material-ui/core";
import clubeAmigoLogo from 'assets/img/clubeAmigo/clubeAmigoLogo.png'
import { ISClubeAmigo } from "utils/environment";


class ConfirmedEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logo: ""
    };
  }

  render() {
    const { title, message } = this.props

    let logo = ISClubeAmigo ? clubeAmigoLogo : config.PRODUCT_LOGO;

    const date = new Date();

    return (
      <>
        <div className="kv-form-container">
          <div className="login-elements">
            <img
              className={
                config.CLIENT === "agx" ? "brand-logo-agx" : "brand-logo"
              }
              alt="Logo Banco Pan"
              src={logo}
            />

          </div>
          { !title && !message ?
              <div style={{ alignContent: 'center' }}>
                <CircularProgress
                  size={50}
                />
              </div>
            :
            <>
              <h3 style={{ color: '#039BE5', fontWeight: 'bold' }}>{title}</h3>
              <h5>{message}</h5>
            </>
          }

          <div style={{ margin: "5% 0" }}>
            <a className="signup-link " href="/">
              Voltar ao login
            </a>
          </div>
          <a
            className="agx-link"
            href="https://www.agxsoftware.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            AGX Software © {date.getFullYear()}
          </a>
        </div>
      </>
    );
  }
}

export default ConfirmedEmail;