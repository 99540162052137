import React from 'react'
import getMuiTheme from 'utils/getMuiTheme';
import { MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import { numToBRL } from 'utils/formatData';
import MuiDataTableOptions from 'utils/MuiDataTableOptions';
import SearchIcon from '@material-ui/icons/Search';

const tableColumns = [
  {
    name: "lacto",
    label: "Lacto",
    options: {
      setCellProps: () => ({ className: 'no-wrap' }),
      filter: false,
      sort: false,
      hint: "Operação",
    },
  },
  {
    name: "data",
    label: "Data",
    options: {
      setCellProps: () => ({ className: 'no-wrap' }),
      filter: false,
      sort: false,
      hint: "Data de resgate",
    },
  },
  {
    name: "tipo",
    label: "Tipo",
    options: {
      setCellProps: () => ({ className: 'no-wrap' }),
      filter: false,
      sort: false,
      hint: "Tipo do voucher",
    },
  },
  {
    name: "valor",
    label: "Valor",
    options: {
      setCellProps: () => ({ className: 'no-wrap' }),
      filter: false,
      sort: false,
      hint: "Valor do voucher",
      customBodyRender: (value) => {

        return value === 0 ? "R$ 0,00" : numToBRL(value);
      },
    },
  },
]

function VoucherReport ({ data, borderless = true, showNotFetchedMessage } ) {
  const defaultTableOptions = MuiDataTableOptions.voucherReport()
  const tableOptions = {
    ...defaultTableOptions,
    textLabels: {
      ...defaultTableOptions.textLabels,
      body: {
        ...defaultTableOptions.textLabels.body,
        noMatch:
          <div className='table-no-data-message'>
            <>
              <SearchIcon style={{ height: 30, width: 30 }} />
              <h6>{'Insira o CPF do Indicador desejado e clique no botão "Pesquisar"'}</h6>
            </>
          </div>
      }
    }
  }

  return (
    <MuiThemeProvider theme={() => getMuiTheme({ borderless: borderless })}>
      <MUIDataTable
        columns={tableColumns}
        data={data}
        options={showNotFetchedMessage ? tableOptions : defaultTableOptions}
        title={
          <h5 variant='title'>
            Relatório de vouchers
          </h5>
        }
      />
    </MuiThemeProvider>
  )
}

export default VoucherReport
