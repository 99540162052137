import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import mainApi from "../services/mainApi";
import Select from "react-select";
import { LoadingAlert, Dialog } from "../components/Alert/CustomAlert";
import { TextField } from "@material-ui/core";
import checkUser from "utils/checkUser";

export default class PaymentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banks: [],
      code: null,
      agency: "",
      account: "",
      type: "",
      swalMessage: [],
      hide: true,
      userData: {},
      swalMessageShow: false,
      swalShowLoading: false,
      loadingPayment: false
    };
  }

  getBanks = async () => {
    const response = await mainApi("/resources/banks");
    let newBanks = [];    
    response.data.banks.forEach(item => {
      let bank = {};
      bank.label = `${item.code} - ${item.name}`;
      bank.value = item.code;
      newBanks.push(bank);
    });
    this.setState({ banks: newBanks });
  };  

  getUserBank = async () => {
    const { isAgency } = this.props;
    const { banks, hide, userData } = this.state;

    this.setState({ hide: !this.state.hide });

    if (!hide) {
      this.setState({ code: null, type: null, account: null, agency: null });
      return;
    }
    this.setState({
      account: "Carregando...",
      agency: "Carregando...",
      loadingPayment: true
    });
    try {
      let data = {};
      if (userData.level === "administrator" && isAgency)
        data.agencyId = userData.agencyId;
      let endpoint;
      if (isAgency) endpoint = "/agency/bank";
      else endpoint = "/user/bank";

      const response = await mainApi.get(
        endpoint,
        data
      );
      this.setState({ ...response.data.bank });
      if (response.data.bank.type === "corrente")
        this.setState({ type: { label: "Corrente", value: "corrente" } });
      if (response.data.bank.type === "poupanca")
        this.setState({ type: { label: "Poupança", value: "poupanca" } });
      let code = { label: "", value: String(response.data.bank.code) };
      let bank = banks.find(
        bank => Number(bank.value) === Number(response.data.bank.code)
      );
      code.label = bank.label;
      this.setState({ code, loadingPayment: false });
    } catch (err) {
      this.setState({ account: "", agency: "", loadingPayment: false });
    }
  };

  updateUserBank = async () => {
    const { isAgency } = this.props;
    const { code, agency, account, type, userData } = this.state;
    this.setState({ swalShowLoading: true });
    
    let data = {};
    let responseResult = false

    if (code) data.code = code.value;
    if (agency) data.agency = agency;
    if (account) data.account = account;
    if (type) data.type = type.value;

    if (userData.level === "administrator" && isAgency)
      data.agencyId = userData.agencyId;

    try {
      let level;
      if (userData.level === "administrator" && isAgency)
        level = "agency"
      else
        level = "user"

      const response = await mainApi.put(`/${level}/bank`, data);
      this.setState({
        swalShowLoading: false,
        swalMessage: ["success", response.data.message],
        swalMessageShow: true
      });

      responseResult = true            
    } catch (err) {
      if (!err.response) {
        this.setState({
          swalShowLoading: false,
          swalMessage: ["error", "Erro desconhecido"],
          swalMessageShow: true
        });
        return;
      }
      if (err.response.status >= 400 && err.response.status < 500) {
        const fields = err.response.data.fields;
        if (!Array.isArray(fields)) {
          return;
        }
        while (fields.length > 0) {
          let field = fields.pop();
          switch (field) {
            case "account": {
              this.setState({
                accountMessage: field.message || "Insira uma conta."
              });
              break;
            }
            case "agency": {
              this.setState({ agencyMessage: "Insira uma Agência." });
              break;
            }
            case "code": {
              this.setState({
                codeMessage: field.message || "Escolha um Banco"
              });
              break;
            }
            case "type": {
              this.setState({
                typeMessage: field.message || "Escolha um tipo de conta"
              });
              break;
            }
            default:
              break
          }
        }
      }
      this.setState({
        swalShowLoading: false,
        swalMessage: ["warning", err.response.data.message],
        swalMessageShow: true
      });

      responseResult = false
    }

    if(responseResult) {      
      var interactionClick = new CustomEvent("event-store-bank-save-success")
      document.body.dispatchEvent(interactionClick);
    }
  };

  async getUserData () {
    const userData = await checkUser(this.props.history)
    this.setState({userData})

  }

  componentDidMount() {
    this.getUserData()
    this.getBanks();
  }
  onBankChange = value => {
    this.setState({ code: value });
  };
  onTypeChange = value => {
    this.setState({ type: value });
  };

  render() {
    const {
      banks,
      code,
      agency,
      type,
      account,
      hide,
      userData,
      swalMessage,
      swalMessageShow,
      swalShowLoading,
      loadingPayment,
      accountMessage,
      agencyMessage,
      codeMessage,
      typeMessage
    } = this.state;
    const { isAgency } = this.props;

    let codeStyle = {
      control: (provided, state) => ({
        ...provided,
        borderColor: codeMessage ? "#f44336" : "hsl(0,0%,90%)"
      })
    };
    let typeStyle = {
      control: (provided, state) => ({
        ...provided,
        borderColor: typeMessage ? "#f44336" : "hsl(0,0%,90%)"
      })
    };
    const canEditBank = function check (){
      const isCorpOrAgency = Boolean(["corporation", "administrator", "subcorp"].indexOf(userData.level) + 1 ) && (isAgency || ['corporation', 'subcorp'].includes(userData.level))       
      if(userData.affiliate){
        return false
      }else {
      if(isCorpOrAgency){
        return false
      }}
      return true
    }()

    const disableInputs = hide || loadingPayment

    return (
      <>
        <Dialog
          type={swalMessage[0]}
          show={swalMessageShow}
          title={swalMessage[0] === "success" ? "Sucesso!" : "Erro"}
          onConfirm={() => this.setState({ swalMessageShow: false })}
          text={swalMessage[1]}
        />
        <LoadingAlert show={swalShowLoading} />
        <Col>
          <Row>
            <Col md={7}>
              <div className="form-group">
                <TextField
                  label="Agência"
                  InputProps={{
                    classes: {
                      input: "mui-input-control",
                      root: "mui-input-control"
                    }
                  }}
                  InputLabelProps={{
                    classes: { root: "mui-input-control mui-label" }
                  }}
                  variant="outlined"
                  error={Boolean(agencyMessage)}
                  helperText={agencyMessage}
                  value={hide ? "*******" : agency}
                  disabled={disableInputs}
                  pattern="[0-9]*"
                  inputMode="numeric"
                  fullWidth
                  onChange={event => {
                    let agency = event.target.value.replace(/\D/g, "");
                    if(canEditBank) this.setState({ agency });
                  }}
                  placeholder="Sua Agência com Dígito"
                />
              </div>
            </Col>
            <Col md={5}>
              <div className="form-group">
                <Select
                  className="custom-select"
                  onChange={canEditBank ? this.onBankChange : () =>{}}
                  styles={codeStyle}
                  value={code}
                  isDisabled={disableInputs}
                  options={banks}
                  placeholder="Selecione um Banco"
                />
                <span className="select-error-text">{codeMessage}</span>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={7}>
              <div className="form-group">
                <TextField
                  label="Conta"
                  InputProps={{
                    classes: {
                      input: "mui-input-control",
                      root: "mui-input-control"
                    }
                  }}
                  InputLabelProps={{
                    classes: { root: "mui-input-control mui-label" }
                  }}
                  variant="outlined"
                  value={hide ? "*******" : account}
                  disabled={disableInputs}
                  pattern="[0-9]*"
                  inputMode="numeric"
                  error={Boolean(accountMessage)}
                  helperText={accountMessage}
                  fullWidth
                  onChange={event => {
                    let account = event.target.value.replace(/\D/g, "");
                    if(canEditBank) this.setState({ account })
                  }}
                  placeholder="Sua Conta com Dígito"
                />
              </div>
            </Col>
            <Col md={5}>
              <div className="form-group">
                <Select
                  className="custom-select "
                  onChange={canEditBank ? this.onTypeChange : ()=>{}}                  
                  styles={typeStyle}
                  isDisabled={disableInputs}
                  value={type}
                  options={[
                    { label: "Corrente", value: "corrente" },
                    { label: "Poupança", value: "poupanca" }
                  ]}
                  placeholder="Tipo de Conta"
                />
                <span className="select-error-text">{typeMessage}</span>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={canEditBank ? 7 : 12} className="form-group" style={{ textAlign: "center" }}>
              <button
                className="btn btn-fill btn-round btn-block btn-mlm"
                onClick={this.getUserBank}
              >
                <span className="save-text">
                  {hide ? "Mostrar informações" : "Esconder Informações"}
                </span>
              </button>
            </Col>
            {canEditBank ? (
              <Col
                md={5}
                className="form-group"
                style={{ textAlign: "center" }}
              >
                <button
                  className="btn btn-fill btn-round btn-block btn-mlm"
                  disabled={hide || loadingPayment}
                  onClick={this.updateUserBank}
                >
                  <span className="save-text">Salvar</span>
                </button>
              </Col>
            ) : null}
          </Row>
        </Col>
      </>
    );
  }
}
