import React from 'react'
import MahoeIcon from "components/MahoeIcons"
import config from "utils/serverVars"
import renderHTML from 'react-render-html';
const goLink = "https://go.onelink.me/zIun/986f79e5";

function AfiliadosIntructions({ accountLink, togglePlatformModal, onOpen }) {
  return (
    <>
      <div className="signup-title">
        <h3 style={config.SIGNUP_SUBTITLE ? { marginBottom: 0 } : {}}>
          {renderHTML(config.SIGNUP_TITLE)}
          <span className="primary-text-color">
            {config.SIGNUP_TITLE_ACCENT}
          </span>
          <MahoeIcon name="picto" fill="#03A9F4" size="8px" />
        </h3>
        {
          config.SIGNUP_SUBTITLE && (
            <h5>
              {renderHTML(config.SIGNUP_SUBTITLE)}
            </h5>
          )
        }
      </div>
      <div className="signup-steps">
        <div className="signup-step">
          <div className="signup-number">
            <span>1</span>
            <MahoeIcon name="picto" fill="#FF5630" size="10px" />
          </div>
          <div className="signup-step-content flex-column">
            <div>
              <h4>{config.SIGNUP_FIRST_STEP_TITLE}</h4>
              <p>{renderHTML(config.SIGNUP_FIRST_STEP_BODY)}</p>
            </div>
            <div className="signup-step-link">
              {accountLink ? (
                <a
                  href={goLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {config.SIGNUP_LINK_TEXT}
                </a>
              ) : (
                <button
                  className="link-style"
                  onClick={togglePlatformModal}
                >
                  {config.SIGNUP_LINK_TEXT}
                </button>
              )}
            </div>
          </div>
        </div>
        <br />
        <div className="signup-step">
          <div className="signup-number">
            <span>2</span>
            <MahoeIcon name="picto" fill="#FF5630" size="10px" />
          </div>
          <div className="signup-step-content flex-column">
            <div>
              <h4>{config.SIGNUP_SECOND_STEP_TITLE}</h4>
              <p>{config.SIGNUP_SECOND_STEP_BODY}</p>
            </div>
            {
              config.SIGNUP_SECOND_LINK_TEXT &&
              <div className="signup-step-link">
                <a className='no-absolut link-style' onClick={onOpen} >Clique aqui</a>  e consulte a tabela de bonificação.
              </div>
            }
          </div>
        </div>
      </div>

    </>
  )
}

export default AfiliadosIntructions
