const company = process.env.REACT_APP_CLIENT
const env = process.env.REACT_APP_ENV

let config
switch(company){
  case "pan":
    config  = require("./_pan").default
    break
  case "agx": 
    config  = require("./_agx").default
    break
  default: 
    config  = require("./_agx").default
  }
config.env = env

export default config