const MonthsWithId = [
  { label: "Janeiro", value: 0 },
  { label: "Fevereiro", value: 1 },
  { label: "Março", value: 2 },
  { label: "Abril", value: 3 },
  { label: "Maio", value: 4 },
  { label: "Junho", value: 5 },
  { label: "Julho", value: 6 },
  { label: "Agosto", value: 7 },
  { label: "Setembro", value: 8 },
  { label: "Outubro", value: 9 },
  { label: "Novembro", value: 10 },
  { label: "Dezembro", value: 11 },
];

const SITUATIONS = [
  { label: "Análise", value: "pending" },
  { label: "Aprovado", value: "paid" },
  { label: "Recusado", value: "refused" },
];

const PRODUCTS = [
  { value: "cartao", label: "Cartão Consignado" },
  { value: "novo", label: "Novo Empréstimo" },
  { value: "saque", label: "Saque Complementar" },
  { value: "port", label: "Portabilidade" },
  { value: "refin", label: "Refinanciamento" },
  { value: "conta", label: "Conta Digital" },
];

const MuiTableConfig = {
  elevation: 0,
  size: "small",
  filterType: "dropdown",
  responsive: "scrollMaxHeight",
  selectableRows: "none",
  rowsPerPage: 25,
  rowsPerPageOptions: [25],
  downloadOptions: {
    filename: "Relatorio.csv",
    filterOptions: {
      useDisplayedColumnsOnly: true,
    },
  },
  textLabels: {
    body: {
      noMatch: "Nenhum dado encontrado.",
    },
    pagination: {
      next: "Próxima Página",
      previous: "Pagina Anterior",
      rowsPerPage: "Linhas por página:",
      displayRows: "de",
    },
    toolbar: {
      search: "Procurar",
      downloadCsv: "Download CSV",
      print: "Imprimir",
      viewColumns: "Mostrar Colunas",
      filterTable: "Filtrar Tabela",
    },
    filter: {
      title: "FILTROS",
      reset: "limpar",
      all: "Todos",
    },
    viewColumns: {
      title: "Mostrar Colunas",
    },
  },
};

const levelsBelow = (level) => {
  if (!level) return [];

  // const all = { label: "Todos os Níveis", value: "all" };
  const superintendent = { label: "Superintendente", value: "superintendent" };
  const gprivate = { label: "Gerente Private", value: "private" };
  const exclusive = { label: "Gerente Exclusive", value: "exclusive" };
  const executive = { label: "Executivo Corban", value: "executive" };

  switch (level.toLowerCase()) {
    case "submaster":
    case "master":
      return [ superintendent, gprivate, exclusive, executive];
    case "superintendent":
      return [ gprivate, exclusive, executive];
    case "private":
      return [ exclusive, executive];
    case "corporation":
    case "subcorp":
    case "exclusive":
      return [executive];
    case "executive":
      return [];
    default:
      return [];
  }
};

const subLevels = (level) => {
  if (!level) return [];

  switch (level.toLowerCase()) {
    case 'master':
      return [
        { label: 'SubMaster', value: 'submaster' },
        { label: 'Corporação', value: 'corporation' },
        { label: 'SubCorporação', value: 'subcorp' },
        { label: 'Administrador', value: 'administrator' },
        { label: 'Supervisor', value: 'supervisor' },
        { label: 'Vendedor', value: 'seller' },
        { label: 'Afiliados Mar Aberto (PF)', value: 'affiliate' }]
    case 'submaster':
      return [
        { label: 'Corporação', value: 'corporation' },
        { label: 'SubCorporação', value: 'subcorp' },
        { label: 'Administrador', value: 'administrator' },
        { label: 'Supervisor', value: 'supervisor' },
        { label: 'Vendedor', value: 'seller' },
        { label: 'Afiliados Mar Aberto (PF)', value: 'affiliate' }]
    case 'corporation':
      return [
        { label: 'SubCorporação', value: 'subcorp' },
        { label: 'Administrador', value: 'administrator' },
        { label: 'Supervisor', value: 'supervisor' },
        { label: 'Vendedor', value: 'seller' }]
    case 'subcorp':
      return [
        { label: 'Administrador', value: 'administrator' },
        { label: 'Supervisor', value: 'supervisor' },
        { label: 'Vendedor', value: 'seller' }]
    case 'administrator':
      return [
        { label: 'Supervisor', value: 'supervisor' },
        { label: 'Vendedor', value: 'seller' }]
    case 'supervisor':
      return [
        { label: 'Vendedor', value: 'seller' }]
    case 'seller':
      return []
    default:
      return []
  }
  // - Master
  // - Submaster
  //    - Corporation
  //    - Subcorp
  //     - Administrator
  //       - Supervisor
  //         - Seller
}

const dashboardFilter = (level) => {
  switch (level) {
    case 'master':
    case 'submaster':
    // case 'executive':
    // case 'superintendent':
    // case 'exclusive':
    // case 'private':
      return 'corporation'
    case 'corporation':
    case 'subcorp':
      return 'agency'
    // case 'administrator':
    //   return 'seller'
    default:
      return null
  }
}

const states = [
  { label: "AC", value: "AC" },
  { label: "AL", value: "AL" },
  { label: "AP", value: "AP" },
  { label: "AM", value: "AM" },
  { label: "BA", value: "BA" },
  { label: "CE", value: "CE" },
  { label: "DF", value: "DF" },
  { label: "ES", value: "ES" },
  { label: "GO", value: "GO" },
  { label: "MA", value: "MA" },
  { label: "MT", value: "MT" },
  { label: "MS", value: "MS" },
  { label: "MG", value: "MG" },
  { label: "PA", value: "PA" },
  { label: "PB", value: "PB" },
  { label: "PR", value: "PR" },
  { label: "PE", value: "PE" },
  { label: "PI", value: "PI" },
  { label: "RJ", value: "RJ" },
  { label: "RN", value: "RN" },
  { label: "RS", value: "RS" },
  { label: "RO", value: "RO" },
  { label: "RR", value: "RR" },
  { label: "SC", value: "SC" },
  { label: "SP", value: "SP" },
  { label: "SE", value: "SE" },
  { label: "TO", value: "TO" },
];

const PIXOPTIONS = [
  { value: "email", label: "Email" },
  { value: "cellphone", label: "Celular" },
  { value: "cpf", label: "CPF" },
  { value: "cnpj", label: "CNPJ" },
  { value: "randomkey", label: "Chave aleatória" }
]

const dddOfUF = (uf) => {
  if (!uf) return [];
  const ufs = {
    AC: ["68"],
    AL: ["82"],
    AP: ["96"],
    AM: ["92", "97"],
    BA: ["71", "73", "74", "75", "77"],
    CE: ["85", "88"],
    DF: ["61"],
    ES: ["27", "28"],
    GO: ["62", "64"],
    MA: ["98", "99"],
    MT: ["65", "66"],
    MS: ["67"],
    MG: ["31", "32", "33", "34", "35", "37", "38"],
    PA: ["91", "93", "94"],
    PB: ["83"],
    PR: ["41", "42", "43", "44", "45", "46"],
    PE: ["81", "87"],
    PI: ["86", "89"],
    RJ: ["21", "22", "24"],
    RN: ["84"],
    RS: ["51", "53", "54", "55"],
    RO: ["69"],
    RR: ["95"],
    SC: ["47", "48", "49"],
    SP: ["11", "12", "13", "14", "15", "16", "17", "18", "19"],
    SE: ["79"],
    TO: ["63"],
  };
  if (!ufs[uf]) return [];
  return ufs[uf].map((u) => ({ value: u, label: u }));
};


const UFddd = {
  AC: ["68"],
  AL: ["82"],
  AP: ["96"],
  AM: ["92", "97"],
  BA: ["71", "73", "74", "75", "77"],
  CE: ["85", "88"],
  DF: ["61"],
  ES: ["27", "28"],
  GO: ["62", "64"],
  MA: ["98", "99"],
  MT: ["65", "66"],
  MS: ["67"],
  MG: ["31", "32", "33", "34", "35", "37", "38"],
  PA: ["91", "93", "94"],
  PB: ["83"],
  PR: ["41", "42", "43", "44", "45", "46"],
  PE: ["81", "87"],
  PI: ["86", "89"],
  RJ: ["21", "22", "24"],
  RN: ["84"],
  RS: ["51", "53", "54", "55"],
  RO: ["69"],
  RR: ["95"],
  SC: ["47", "48", "49"],
  SP: ["11", "12", "13", "14", "15", "16", "17", "18", "19"],
  SE: ["79"],
  TO: ["63"],
}

const getStateFullName = (state) => {
  switch (state) {
    case "AC":
      return "Acre";
    case "AL":
      return "Alagoas";
    case "AP":
      return "Amapá";
    case "AM":
      return "Amazonas";
    case "BA":
      return "Bahia";
    case "CE":
      return "Ceará";
    case "DF":
      return "Distrito Federal";
    case "ES":
      return "Espírito Santo";
    case "GO":
      return "Goiás";
    case "MA":
      return "Maranhão";
    case "MT":
      return "Mato Grosso";
    case "MS":
      return "Mato Grosso do Sul";
    case "MG":
      return "Minas Gerais";
    case "PA":
      return "Pará";
    case "PB":
      return "Paraíba";
    case "PR":
      return "Paraná";
    case "PE":
      return "Pernambuco";
    case "PI":
      return "Piauí";
    case "RJ":
      return "Rio de Janeiro";
    case "RN":
      return "Rio Grande do Norte";
    case "RS":
      return "Rio Grande do Sul";
    case "RO":
      return "Rondônia";
    case "RR":
      return "Roraima";
    case "SC":
      return "Santa Catarina";
    case "SP":
      return "São Paulo";
    case "SE":
      return "Sergipe";
    case "TO":
      return "Tocantins";
    default:
      return state;
  }
};

const funnelAppsflyerLabels = [
  "DadosIniciais",
  "OndeVoceMora",
  "MaisSobreVoce",
  "HoraDasFotos",
  "VamosTirarUmaSelfie",
  "RetornoContaAbertaSucesso",
]

const funnelOmnichannelLabels = [
  'clicks',
  'dados_iniciais',
  // 'contato',
  // 'endereco',
  // 'informacoes_adicionais',
  // 'documentos',
  // 'permissoes',
  // 'revisar',
  // 'envio_sms',
  'fotos',
  "reenvio_fotos",
  // 'todas_fotos',
  'em_analise',
  'aprovada',
  'conta_aberta',
  'reprovada'
]

const situations = [
  { value: 'paid', label: 'Aprovadas' },
  { value: 'pending', label: 'Pendentes' },
  { value: 'refused', label: 'Recusadas' }
]

const pendingStatus = [
  { value: '', label: 'Todos' },
  { value: 'dados_iniciais', label: 'Dados iniciais (Cliente)' },
  { value: 'fotos', label: 'Fotos (Cliente)' },
  { value: 'reenvio_fotos', label: 'Reenvio de fotos (Cliente)' },
  { value: 'em_analise', label: 'Em análise (Banco)' },
  { value: 'aprovada', label: 'Baixar app (Cliente)' },
]

const refusedStatus = [
  { value: '', label: 'Todos' },
  { value: 'inativa', label: 'Já existente (Banco)' },
  { value: 'reprovada', label: 'Reprovadas (Banco)'},
  { value: 'sobrescrita', label: 'Inativa'},
]

const initialDataStatus = [
  { value: '', label: 'Todos' },
  { value: 'contato', label: 'Contato' },
  { value: 'endereco', label: 'Endereço' },
  { value: 'informacoes_adicionais', label: 'Info adicionais' },
  { value: 'documentos', label: 'Documentos' },
  { value: 'permissoes', label: 'Permissões' },
  { value: 'revisar', label: 'Revisar' },
  { value: 'envio_sms', label: 'Envio SMS' }
]

const searchStatusOptions = [
  { value: 'cpf', label: 'CPF' },
  { value: 'phone', label: 'Telefone' },
  { value: 'email', label: 'E-mail' }
]

const statusCommunicationOptions = [
  { value: false, label: 'Abertos' },
  { value: true, label: 'Finalizados' }
]

const searchLoginOptions = [
  { value: 'cpf', label: 'CPF', mask:'999.999.999-99' },
  { value: 'cnpj', label: 'CNPJ', mask:'99.999.999/9999-99' },
  { value: 'code', label: 'Código', mask:'999999'}
]

const productOrder = ['Conta Digital', 'Empréstimo Consignado']

const emptySelect =  { value: '', label: 'Todos' }

const searchblockOptions = [
  { value: true, label: 'Bloqueado'},
  { value: false, label: 'Não Bloqueado'}
]

const searchSignupTypeOptions = [
  { value: true, label: 'Mar Aberto'},
  { value: false, label: 'Convite'}
]

const signUpStatus = [
  { label: "Em análise", value: "pending" },
  { label: "Aprovado", value: "approved" },
  { label: "Recusado", value: "refused" },
];

const platforms = [{ value: 'appsflyer', label: 'AppsFlyer' }, { value: 'omnichannel', label: 'Omnichannel (Novo)' }]

const consignadoProducts = [
  { value: 'novo', label: 'Margem Livre' },
  { value: 'cartao', label: 'Cartão com Saque Complementar' },
  { value: 'port', label: 'Portabilidade' },
  { value: 'refin', label: 'Refinanciamento' },
  { value: 'fgts', label: 'EP FGTS' },
  ]


const contaId = "5f5777c4e2fd425e4bd7da5a";
const consignadoId = "5e84924c43689f59668b273f";
const affiliateCorpId = "5df91854f1653e422bfecb2c";
const filteredAgencies = [
  "5f5784f9d2af340004bc0f2b",
  "5f526169f542db6ead376604",
];
const agxSubmasterId = "5f69347d48c1d60004a8916f"
const CVCId = "5fc53c28d970390004295c70"

const _agencyId = '5df91854f1653e422bfecb2d'
const isSupportMarAberto = ['60771f79a5a30d0003b5dbf7', '60d5dfa126eb292b585ff596', '60d614f7e04d030004dab297']


// Clube Amigo
const ClubeAmigoId = '6123a88237f59238e00925c4'
const ClubeAmigoDomains = ["clube.pan.agxsoftware.com", "clubeamigo.agxsoftware.com"]
const ClubeAmigoTitle = "Clube Amigo | Banco PAN"


// Projeto Lojista
const FGTSCorpId = '6125561ee155b03138e9277b'

export {
  MonthsWithId,
  MuiTableConfig,
  getStateFullName,
  agxSubmasterId,
  SITUATIONS,
  PRODUCTS,
  contaId,
  affiliateCorpId,
  filteredAgencies,
  levelsBelow,
  subLevels,
  states,
  dddOfUF,
  PIXOPTIONS,
  CVCId,
  UFddd,
  _agencyId,
  isSupportMarAberto,
  funnelAppsflyerLabels,
  funnelOmnichannelLabels,
  initialDataStatus,
  pendingStatus,
  refusedStatus,
  situations,
  emptySelect,
  platforms,
  productOrder,
  searchStatusOptions,
  statusCommunicationOptions,
  dashboardFilter,
  searchLoginOptions,
  searchblockOptions,
  searchSignupTypeOptions,
  signUpStatus,
  ClubeAmigoId,
  consignadoProducts,
  consignadoId,
  FGTSCorpId,
  ClubeAmigoDomains,
  ClubeAmigoTitle
};