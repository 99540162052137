import styled from 'styled-components'

export const Card = styled.div`
  width:100%;
  height:100%;
  background-color: #FFFFFF;  
  border: 1px solid #E0E0E0;
  border-radius: 20px;
  overflow-x:auto;
`

export const Content = styled.div `
  background-color: transparent;
  box-shadow: none;
  border-radius: 0; 
  padding: 15px 30px 15px;
  width:100%;
  height:fit-content;
`

export const Title = styled.div`
  font-weight:bold;
  font-size:24px;
  width:100%;
  height:fit-content;
  border-bottom: 1px solid #EEE;
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 15px;
  word-break: break-all;
`
export const Footer = styled.div`
  width:100%;
  height:fit-content;
  padding-right: 45px;
  padding-left: 45px;
  padding-bottom: 15px;
  padding-top: 15px;
  display:flex;
  justify-content:flex-end;
  align-items:center;
`