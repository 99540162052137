import React, { Component } from "react"
import { Content, Title, Card, Footer } from "./styled"
// import { Grid, Row, Col } from "react-bootstrap"
import MahoeIcons from "components/MahoeIcons"

class CleanCard extends Component {

	constructor(props) {
		super(props)
	}

	render() {

		const { title, titleStyle, style, contentStyle, footerStyle, noTitle, noFooter, endButton, buttonStyle, buttonClass, buttonText } = this.props

		return (
			<Card style={style}>
				{!noTitle ? 
					<Title style = {titleStyle}>
						{title}
					</Title> 
					: 
					<></>
				}
					
				<Content style = {contentStyle}>
					{this.props.children}
				</Content>

				{!noFooter ?
					<Footer style = {footerStyle}>
						{ endButton ? (<button style={buttonStyle} className={buttonClass}>{buttonText}</button>) : "" }
					</Footer>
					:
					<></>
				}
			</Card>
		)
	}
}

export default CleanCard