import React, { createRef, useEffect, useRef, useState } from "react";

import to from "await-to-js";
import moment from "moment";
import mainApi from "services/mainApi";
import InputMuiField from "components/InputMuiField";
import { Col, Row } from "react-bootstrap";
import MuiSelect from "components/Selects/MuiSelect";
import getUserData from "utils/checkUser";
import { levelsBelow } from "utils/CommonData";
import ManagersTable from "./ManagersTable";
import Axios from "axios";
import { downloadCsv } from "utils/fileDownload";
import Button from "components/Buttons/Button";
import { states } from "utils/CommonData";
import { dddOfUF } from "utils/CommonData";
import MuiDatepickerMonth from "components/MuiDatepickerMonth";
import { CircularProgress } from "@material-ui/core";
import { typeManager } from '../../utils/translate.js'
import ManagerSelectDialog from "components/ManagerSelectDialog/ManagerSelectDialog";

const CancelToken = Axios.CancelToken;
let cancelRequest;

const fetchUserData = async (setUserData, history) => {
  const userData = await getUserData(history);
  setUserData(userData);
};

const Loading = () => (
  <CircularProgress
    size={24}
    style={{
      marginLeft: 15,
      position: "relative",
      top: 4,
    }}
  />
);

function ManagersTotals({ history }) {
  const local = history.location.pathname.split('/')[history.location.pathname.split('/').length - 1]
  const pathLevel = {en: local.slice(0, local.length - 1), pt: local === 'managers' ? 'gerentes' : local === 'corps' ? 'corporações' : ''}
  const searchRef = useRef(null);
  const [openManagerSelect, setOpenManagerSelect] = useState(false)
  const [data, setData] = useState([]);
  const [date, setDate] = useState(new Date());
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("")
  const [tableTitle, setTableTitle] = useState(pathLevel.pt);
  const [context, setContext] = useState(pathLevel.en === 'corp' ? 'corporation' : pathLevel.en); // manager, corporation or agency
  const [userId, setUserId] = useState();
  const [loading, setLoading] = useState(true);
  const [corporations, setCorporations] = useState([]);
  const [managers, setManagers] = useState([])
  const [corpId, setCorpId] = useState();
  const [userData, setUserData] = useState({});
  const [pagination, setPagination] = useState({});
  const [uf, setUf] = useState({
    label: "Todas as UFs",
    value: "all",
  });
  const [ddd, setDDD] = useState({
    label: "Todos os DDDs",
    value: "all",
  });
  const [levelFilter, setLevelFilter] = useState({
    label: "",
    value: "",
  });
  const [selectedCorp, setSelectedCorp] = useState(null)
  const [hiddenColumns, setHiddenColumns] = useState({
    level: !(
      levelFilter.value === "all" &&
      !["corporation", "agency"].includes(context)
    ),
    corporations: context === "corporation",
    agency: context === "agency",
  });
  const month = moment(date).format('MMMM')


  const getCorporations = async () => {
    const [err, response] = await to(mainApi.get('/corps/manager'));

    if (err || !response) {
      // setTimeout(getCorporations, 2000);
    }
    if (!response) return;
    const corpsResponse = response.data.data.corporations
    setCorporations(corpsResponse);
  };


  const getManagersTotals = async (options) => {
    setOpenManagerSelect(false)
    if (cancelRequest) cancelRequest();

    const { limit, download, page = 1 } = options || {};
    let query = `?limit=10&page=${page}`;


    if (context === 'manager' && !levelFilter.value) {
      setLoading(false)
      return setOpenManagerSelect(true)
    }

    if (search) query = query + `&name=${encodeURIComponent(search)}`;
    if (download) query = query + `&download=true`;
    if (uf && uf.value !== "all") query = query + `&uf=${uf.value}`;
    if (date) query = query + `&date=${moment(date).format("YYYY-MM")}`;
    if (ddd && ddd.value !== "all") query = query + `&ddd=${ddd.value}`;
    if (sort) query = query + `&sort=${sort}`
    if (userId) query = query + `&belowId=${userId}`
    if (corpId) query = query + `&corpId=${corpId}`
    if (levelFilter && levelFilter.value !== "all")
      query = query + `&level=${levelFilter.value}`;

    let err, response;

    setLoading(true);
    if (["corporation", "agency"].includes(context) || userId) {
      [err, response] = await to(
        mainApi.get(`/managers/${context}${query}`, {
          cancelToken: new CancelToken((c) => (cancelRequest = c)),
        })
      );
    } else {
      [err, response] = await to(
        mainApi.get(`/list/managers${query}`, {
          cancelToken: new CancelToken((c) => (cancelRequest = c)),
        })
      );
    }
    if (err) {
      console.log(err);
      return;
    }


    let data
    if (download && context === 'manager') data = response.data.data
    else data = response.data.data[context]

    if (download && data?.csv) {
      const downloadData = data.csv;
      downloadCsv(downloadData, `${levelFilter.label}`, moment(date).format('MM-YYYY'));
      setLoading(false);
      return;
    }

    if (context === 'manager'){
      setManagers(data.docs)

      data.docs = data.docs.map(item => ({
        ...item,
        level: typeManager(item.level),
      }))
    }

    setData(data.docs);
    setPagination({ ...data, docs: undefined });
    setHiddenColumns({
      level: !(
        levelFilter.value === "all" &&
        !["corporation", "agency"].includes(context)
      ),
      corporations: context === "corporation",
      agency: context === "agency",
    });
    setLoading(false);

  };

  useEffect(() => {
    if (userData.level) {
      getManagersTotals();
        setData([])
    }
  }, [search, userId, corpId, levelFilter, ddd, uf, date, sort, userData]);

  useEffect(() => {
    let title
    switch (context) {
      case "corporation":
        title = "Corporações"
        break;
      case "agency":
          title = `Lojas`
          break;
      default:
        title = "Gerentes"
    }
    if (searchRef.current)
      searchRef.current.value = ''
    setSelectedCorp(null)
    setSearch('')
    setDDD({
      label: "Todos os DDDs",
      value: "all",
    })
    setUf({
      label: "Todos as UFs",
      value: "all",
    })
    setTableTitle(title)

  }, [context])

  useEffect(()=>{
    let managerName
    if(userId && managers && context =='agency'){
      const manager = (managers.find((manager) => manager._id === userId))
      managerName = `${manager.name} - `
    }

    if(corpId && corporations && context =='agency'){
      const corpName = (corporations.find((corp) => corp.value === corpId))
      if(corpName) setTableTitle (`Lojas: ${managerName ? managerName : ''} ${corpName.label}`)
    }
  },[corpId])

  useEffect(()=>{
    if(userId && managers && context =='corporation'){
      const managerName = (managers.find((manager) => manager._id === userId))
      if(managerName) setTableTitle (`Corporações: ${managerName.name}`)
    }
  },[userId, tableTitle])

  useEffect(() => {
    fetchUserData(setUserData, history);

  }, [fetchUserData]);

  useEffect(()=> {
    if(!userData.level) return
    if(userData.level === "corporation"){
      setContext("agency")
    }
    else getCorporations();
  }, [userData])

  useEffect(() => {
    if(pathLevel.en === 'corp'){
      if(corporations && corporations.length == 1){
        // setContext("agency")
        setCorpId(corporations[0].value)
        setSelectedCorp(corporations[0])
      }else{
        setContext("corporation")
      }
    }
      getManagersTotals()
  }, [corporations, userData]);

  const handleBack = () => {
    if (context === "corporation") {
      setContext("manager");
      setUserId("");
    }
    if (context === "agency") {
      setContext("corporation");
      setCorpId("");
    }
  };

  const onSortChange = (value, type) => {
    const hasPlus = 'descending' === type ? '-' : ''
    setSort(hasPlus + value)
  }

  function getTitleItem(item) {
    if (item.value !== 'all')
      return '- ' + item.label
    return ''
  }

  const title = <h5>
    {tableTitle} {getTitleItem(levelFilter)} {getTitleItem(uf)} {getTitleItem(ddd)} {loading && <Loading />}
    </h5>

  const handleManagerSelectDialog = () => {
    setOpenManagerSelect(false)
    getManagersTotals({ download: true })
  }

  return (
    <div className="content">
      <ManagerSelectDialog
        open={openManagerSelect}
        setOpen={setOpenManagerSelect}
        level={userData.level}
        setLevelFilter={setLevelFilter}
        onSelect={handleManagerSelectDialog}
      />
      <Row style={{ marginBottom: -15 }}>
        {context === "corporation" &&
        <Col md={6}>
          <MuiSelect
            options={[{
                label: "Todos as corporações",
                value: "all",
              },
              ...corporations
            ]}
            placeholder="Filtrar corporação"
            disabled={corporations.length === 0}
            value={selectedCorp}
            onChange={(v) => {
              setSelectedCorp(v)
              setSearch(v.value === 'all' ? '' : v.label)
            }}
            disabled={loading}
          />
        </Col>}
        {context !=='corporation' &&
        <Col md={7}>
          <div className="flex-row negative-margin" >
            <InputMuiField
              label={`Pesquisar por ${context === 'corporation' ? 'corporação' : context === 'agency' ? 'loja' : 'gerente'}`}
              wrapperStyle={{ minWidth: "60%" }}
              inputRef={searchRef}
              onChange={(e) => {
                if (e.target.value === "") setSearch("");
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter')
                  setSearch(searchRef.current.value);
                }
              }
              disabled={loading}
            />
            <Button
              style={{ width: "35%", minWidth: 120, marginLeft: 10 }}
              onClick={() => {
                if (searchRef.current) setSearch(searchRef.current.value);
              }}
              disabled={loading}
            >
              Pesquisar
            </Button>
          </div>
        </Col>}
      </Row>
      <br/>{/* Pois é tbm não sei, mas não tire*/}
      <Row>
        <Col md={4}>
          <MuiDatepickerMonth
            isClearable={false}
            onChange={(e) => {
              setDate(e);
            }}
            date={date}
            disabled={loading}
          />
        </Col>
        {(context === 'manager' || context === 'root'  && !["corporation", "executive"].includes(userData.level)) &&
        <Col md={3}>
          <MuiSelect
            options={levelsBelow(userData.level)}
            placeholder="Filtrar nível"
            value={levelFilter.value ? levelFilter : undefined}
            onChange={(v) => {
              setLevelFilter(v);
            }}
            isDisabled={loading}
          />
        </Col>}
        <Col md={2}>
          <MuiSelect
            options={[
              {
                label: "Todas as UFs",
                value: "all",
              },
              ...states,
            ]}
            placeholder="Filtrar UF"
            value={uf}
            onChange={(v) => {
              setUf(v);
              setDDD({
                label: "Todos os DDDs",
                value: "all",
              });
            }}
            isDisabled={loading}
          />
        </Col>
        <Col md={3}>
          <MuiSelect
            options={[
              {
                label: "Todos os DDDs",
                value: "all",
              },
              ...dddOfUF(uf.value),
            ]}
            value={ddd}
            placeholder="Filtrar DDD"
            onChange={(v) => {
              setDDD(v);
            }}
            isDisabled={loading}
          />
        </Col>
      </Row>
      <ManagersTable
        data={data}
        title={title}
        onUserSelect={(u) => {
          switch (context) {
            case "corporation":
              setCorpId(u);
              setContext("agency");
              break;
            default:
              setContext("corporation");
              setUserId(u);
              break;
          }
        }}
        loading={loading}
        level={userData.level}
        pagination={pagination}
        context={context}
        fetchData={getManagersTotals}
        handleBack={handleBack}
        showBack={(pathLevel.en === 'corp' && context === 'corporation') || (pathLevel.en === 'manager' && context === 'manager') ? false : true}
        hideColumns={hiddenColumns}
        onSortChange={onSortChange}
        month={month}
      />
    </div>
  );
}

export default React.memo(ManagersTotals);
