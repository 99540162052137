import { Dialog } from "../../../components/Alert/CustomAlert";
import React, { useState, useEffect } from 'react'
import mainApi from 'services/mainApi'
import spinner from "assets/img/loading.gif"

const initalSwalState = {
  type: '',
  show: false,
  title: '',
  text: ''
}

function ClaimVoucher ({ id, voucherStatus = 'n/a', refreshTable }) {
  const [swalMessage, setSwalMessage] = useState(initalSwalState)
  const [isLoading, setIsLoading] = useState(false)
  const [waitingForRefresh, setWaitingForRefresh] = useState(false)

  async function requestVoucherClaiming () {
    setIsLoading(true)
    try {
      const response = await mainApi.post('/voucher/claim', {proposalCode: id })
      refreshTable()
      setWaitingForRefresh(true)
    } catch (err) {
      setSwalMessage({
        show: true,
        type: 'warning',
        title: 'Atenção',
        text: err.response?.data?.message || 'Ocorreu um problema ao resgatar o voucher. Tente novamente mais tarde.'
      })
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (waitingForRefresh) setWaitingForRefresh(false)
  }, [voucherStatus, id])

  return (
    <>
      <Dialog
        {...swalMessage}
        onConfirm={() => setSwalMessage(initalSwalState)}
      />
        {(isLoading || waitingForRefresh) ? (
           <img style={{height: 14, width: 14}} src={spinner} alt="" />
        ) : (
          voucherStatus !== 'resgatar' ? (
            <span>{voucherStatus}</span>
          ) : (
            <a className='pointer' onClick={requestVoucherClaiming}>{voucherStatus}</a>
          )
        )}
    </>
  )
}

export default ClaimVoucher