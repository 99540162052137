import React from "react";
import MUIDataTable from "mui-datatables";
import { MuiTableConfig } from "utils/CommonData";
import getMuiTheme from "utils/getMuiTheme";
import { MuiThemeProvider } from "material-ui/styles";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Card from "components/Card/Card";

import { masterCorporationTableHeader } from "./managersTotalsTableConfig";
import { IconButton, Tooltip } from "@material-ui/core";
import MahoeIcons from "components/MahoeIcons";
import LinkButton from "components/LinkButton";

function ManagersTable({
  data,
  level,
  pagination,
  fetchData,
  hideColumns,
  onUserSelect,
  title,
  handleBack,
  showBack,
  context,
  onSortChange,
  month,
  loading
}) {
  const { limit, totalDocs, page } = pagination;

  const additionalTableConfig = {
    download: false,
    filter: false,
    search: false,
    print: false,
    sort: true,
    viewColumns: false,
    rowsPerPageOptions: [10],
    rowsPerPage: 10,
    serverSide: true,
    count: totalDocs || 0,
    page: page - 1 || 0,
    onColumnSortChange: onSortChange,
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          fetchData({ ...pagination, page: tableState.page + 1 });
          break;
        case "changeRowsPerPage":
          fetchData({ ...pagination, limit: tableState.rowsPerPage });
          break;
        case "filterChange":
          console.log(tableState)
          break
        default:
          break;
      }
    },
    customToolbar: () => {
      return (
        <>
          <Tooltip
            placement="top"
            title="Download CSV"
            aria-label="download-csv"
          >
            <IconButton
              disabled={loading}
              style={{ marginTop: 4, height: "38px", padding: "12px" }}
              onClick={() => {
                fetchData({ ...pagination, download: true });
              }}
            >
              <CloudDownloadIcon />
            </IconButton>
          </Tooltip>
        </>
      );
    },
  };

  return (
    <Card
      title={
        <>
        {showBack &&
          <LinkButton onClick={handleBack}>
            <MahoeIcons name="arrow-back" fill="#0277bd" size={16} style={{margin: '-4px 2px'}} /> Voltar
          </LinkButton>}

          <h3 style={{textTransform: 'capitalize'}}><strong>{month ? month : ''}</strong></h3>
        </>
      }
      content={
        <MuiThemeProvider theme={getMuiTheme}>
          <MUIDataTable
            title={title}
            columns={masterCorporationTableHeader(level, {
              onUserSelect,
              hideColumns,
              context
            })}
            data={data}
            options={{
              ...MuiTableConfig,
              ...additionalTableConfig,
            }}
          />
        </MuiThemeProvider>
      }
    ></Card>
  );
}

export default React.memo(ManagersTable);
