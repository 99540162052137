import React, { Component } from "react";
import { Row } from "react-bootstrap";
import GenerateLink from "components/GenerateLink";
import { Dialog } from "components/Alert/CustomAlert";
import { introSystem } from 'utils/introToSystem';
import UserLinks from "components/UserLinks";
import dataTour from 'utils/dataTour'

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: {},
      swalMessage: [],
      swalMessageShow: false
    };
  }

  showMessage = (type, message, boldMessage) => {
    this.setState({
      swalMessageShow: true,
      swalMessage: [type, message]
    });
  };

  componentDidMount() {
    const { routeKey } = this.props

    introSystem(routeKey)
  }

  handleChangePage = () => {
    this.props.history.push("/admin/divulgacao")
  }

  render() {
    const { swalMessage, swalMessageShow, userData } = this.state;
    return (
      <>
        <Dialog
          type={swalMessage[0]}
          show={swalMessageShow}
          title={swalMessage[0] === "success" ? "Sucesso!" : swalMessage[0] === "warning" ? "Ops!" : "Erro"}
          onConfirm={() => {
            this.setState({ swalMessageShow: false });
          }}
          text={swalMessage[1]}
          boldText={swalMessage[2]}
        />
        <div className="content">
          <div className="container-fluid">
            <Row>
              <GenerateLink
                {...this.props}
                firstDataIntro={dataTour.home.generateLink}
                secondDataIntro={dataTour.home.send}
                secondPart2DataIntro={dataTour.home.sendPart2}
                title="Gerar Link"
                size={6}
                messageHandler={this.showMessage}
              />
              <GenerateLink
                {...this.props}
                firstDataIntro={dataTour.home.friend}
                secondDataIntro={dataTour.home.sendFriend}
                finalDataIntro={dataTour.home.finish}
                title="Tem um cliente específico?"
                specific
                size={6}
                messageHandler={this.showMessage}
              />
            </Row>
            <UserLinks userLevel={userData.level}/>
          </div>
        </div>
      </>
    );
  }
}

export default Home;
