import React, { useEffect, useState } from "react"
import to from 'await-to-js'
import moment from 'moment'

import getUserData from "utils/checkUser"
import CampaignList from 'components/CampaignList'
import mainApi from "services/mainApi"
import MahoeIcon from "components/MahoeIcons"
import MuiSelect from 'components/Selects/MuiSelect'
import DatePicker from "react-datepicker"
import { translateMonths } from "utils/translate";
import { Row, Col } from 'react-bootstrap'
import "react-datepicker/dist/react-datepicker.css"

import { getMonthAndYear } from "utils/formatData"
import { introSystem } from "utils/introToSystem"
import { FormHelperText } from "@material-ui/core"

const CorpCampaign = ({ routeKey, history }) => {
  const [userData, setUserData] = useState({})
  const [loadingData, setLoadingData] = useState(false);
  
  const [selectedProduct, setSelectedProduct] = useState('')
  const [products, setProducts] = useState([])
  const [selectDate, setSelectDate] = useState(new Date())

  const getProducts = async () => {
    const [err, response] = await to(mainApi.get(`/products`))

    if (err || !response) return

    const products = response.data.products
    const productsToSelect = products.map(item => ({ value: item._id, label: item.name }))
    setProducts(productsToSelect)
  }

  useEffect(() => {
    const userData = getUserData(history);
    setUserData({ userData });    
    introSystem(routeKey)
    getProducts()
  }, []) 
  
  return (
    <div className="content">
      <div className="container-fluid" style={{ backgroundColor: '#fff', borderRadius: '16px', paddingTop: '30px' }}  >
        <Row>
          <Col md={6}>
            <div style={{paddingRight:15}}>
              <p>Dados referentes ao mês de <strong>{translateMonths(moment(selectDate).format('MMMM'))}</strong></p>
            </div>
          </Col>
          <Col md={3} >
          < FormHelperText>Filtrar por mês</FormHelperText>
            <DatePicker
              className={`datepicker-theThird ${loadingData ? 'datepicker-theThird-disabled' : ''}`}
              dateFormat="MM/yyyy"
              locale="pt-BR"
              disabled={loadingData}
              customInput={
              <div className="div-datepicker" style={{height:'initial'}}>
                <div className="col-6">
                  <span className="placeholder-datepicker">
                    {selectDate ? "Mês selecionado:" : "Selecione um mês"}
                  </span>
                </div>
                <div className="col-3">
                  <p className="date-datepicker">
                    {selectDate ? getMonthAndYear(selectDate) : ""}
                  </p>
                </div>
                <div
                  className="col-3"
                  style={{ alignSelf: "center" }}
                  onMouseEnter={(event) => this.handleMouse(event)}
                  onMouseLeave={() =>
                    this.setState({ openPopper: false })
                  }
                >
                  {selectDate ? (
                    <></>
                  ) : (
                    <MahoeIcon
                      aria-describedby={"iconTooltip"}
                      name="info"
                      size="23px"
                      fill="#039BE5"
                    />
                  )}
                </div>
              </div>
              }
              selected={selectDate}
              showMonthYearPicker
              onChange={(date, event) => setSelectDate(date)}
              maxDate={new Date()}
              minDate={new Date("2020-01-01")}
            />
          </Col>
          <Col md={3}>
            <FormHelperText>Filtrar por produto</FormHelperText>
            <MuiSelect          
              sm
              onChange={(event) => setSelectedProduct(event.value)}
              placeholder="Selecionar o produto"
              options={[ {value: "", label: "Todos os produtos"}, ...products ] || []}
              defaultValue={{value: "", label: "Todos os produtos"}}
            /> 
          </Col>
        </Row>
        <Row>
          <CampaignList 
            corporation 
            product={selectedProduct}
            date={selectDate}
          />
        </Row>
      </div>
    </div>
  )
  
}

export default CorpCampaign;