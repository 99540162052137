import { Modal } from "react-bootstrap/";
import React, { useEffect, useState } from 'react'
import image from '../../assets/img/FLUXO-CG-PAN.jpg'
import MahoeIcon from '../MahoeIcons'

function HelpAccountOpening() {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const cycle = new Image()

    cycle.src = image
  }, [])

  return (
    <span>
      <MahoeIcon
        aria-describedby={"iconTooltip"}
        name="question"
        size="1em"
        fill="#03A9F4"
        style={{cursor: 'pointer'}}
        onClick={() => setOpen(prev => !prev)}

      />
      <Modal
        show={open}
        onHide={() => setOpen(prev => !prev)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Ciclo da Proposta</Modal.Title>
        </Modal.Header>
        <Modal.Body  style={{display:'flex', justifyContent:'center'}}>
          <img style={{maxWidth: '400px', width: '90vw'}} src={image} />
        </Modal.Body>
      </Modal>
    </span>
  )
}

export default HelpAccountOpening