import React, {Component} from 'react'
import {LoadingLine} from './styled'

export class Loader extends Component{

    constructor(props){
        super(props)
    }

    render(){
        const {
            percentage
        } = this.props

        return (
            <LoadingLine value={percentage}>
            </LoadingLine>
        )
    }
}

export default Loader