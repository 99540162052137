import React, { Component } from 'react'
import mainApi from "services/mainApi";
import Card from 'components/Card/CleanCard'
import { Wrapper, Title, Filters, Table, ErrorRow, PaginationContainer, IconStyle, IconButton, ArrowContainer, ArrowsContainer, NothingFound } from './styled'
import { LoadingAlert } from 'components/Alert/CustomAlert';
import { Storefront, Warning, ReportProblemOutlined, ErrorOutline, Person, Store, ChevronRight, ChevronLeft } from "@material-ui/icons";
import { Grid, Row, Col } from 'react-bootstrap'
import Moment from 'moment'
import BigTooltip from "components/BigTooltip";
import { errorPriority } from "utils/translate"
import Select from 'react-select'
import moment from 'moment'
import { errorType } from 'utils/translate'

//datepicker
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptbr from 'date-fns/locale/pt-BR'
registerLocale('pt-BR', ptbr)

class OperatorErrorPanel extends Component {
  constructor(prop) {
    super(prop)

    this.state = {
      errors: [],
      logs: [],
      page: 1,
      pageLimit: 1,
      option: true,
      startDate: null,
      endDate: null,
      selectedPriority: null,
      selectedType: null,
      todayErrorCount: 0,
      todayLogCount: 0
    }
  }

  createQuery() {
    const { page, startDate, endDate, selectedPriority, selectedType } = this.state
    let query = ""

    if (startDate) query += `&start=${moment(startDate).format("YYYY-MM-DD")}`
    if (endDate) query += `&end=${moment(endDate).format("YYYY-MM-DD")}`
    if (page) query += `&page=${page}`
    if (selectedPriority) query += `&priority=${selectedPriority}`
    if (selectedType) query += `&type=${selectedType}`

    return query
  }

  setStartDate = (date, event) => {
    const verifyInput = this.verifyLengthInputDatePicker(event, date)
    if (verifyInput) this.setState({ startDate: date, loading: true, page: 1 }, () => this.loadLists())
  }

  setEndDate = (date, event) => {
    const verifyInput = this.verifyLengthInputDatePicker(event, date)
    if (verifyInput) this.setState({ endDate: date, loading: true, page: 1 }, () => this.loadLists())
  }

  verifyLengthInputDatePicker = (event, date) => {
    const valueInput = event.target.value

    if (valueInput === undefined) return true
    else if (valueInput && valueInput.length > 9) return true
    else if (date === null) return true
    else return false
  }

  clearStates() {
    this.setState({
      endDate: null,
      startDate: null
    })
  }

  getErrors = async () => {
    const query = await this.createQuery()
    const response = await mainApi.get(`/internal/operator/errors?${query}`)
    const errors = response.data.errorList.docs;
    const { totalPages, page } = response.data.errorList;
    this.setState({
      errors,
      pageLimit: totalPages,
      page: page
    })
  }

  getLogs = async () => {

    const query = await this.createQuery()

    const response = await mainApi.get(`/internal/operator/logs?${query}`)
    const logs = response.data.logs.docs;

    const { totalPages, page } = response.data.logs;

    this.setState(() => ({
      logs: logs,
      pageLimit: totalPages,
      page: page
    }))
  }

  priorityColor(priority) {
    // #E8EE47
    // #990000
    switch (priority) {
      case "low":
        return <ErrorOutline style={{ color: "green", fontSize: "50px" }} />
      case "normal":
        return <ReportProblemOutlined style={{ color: "#E8EE47", fontSize: "50px" }} />
      case "high":
        return <Warning style={{ color: "red", fontSize: "50px" }} />
      case "urgent":
        return <Warning style={{ color: "#990000", fontSize: "50px" }} />
    }
  }

  hasThings(v) {
    if (v.userId)
      return (
        <BigTooltip title={v.userName} placement="top">
          <IconStyle>
            <Person style={{ fontSize: "50px" }} />
          </IconStyle>
        </BigTooltip>
      )

    if (v.corporationId)
      return (
        <BigTooltip title={"Detalhes da corporação"} placement="top">
          <IconButton style={{ backgroundColor: "#03A9F4" }} onClick={() => this.props.history.push("/admin/operator/corporation/details?id=" + v.corporationId)}>
            <Storefront style={{ fontSize: "50px" }} />
          </IconButton>
        </BigTooltip>
      )

    if (v.agencyId)
      return (
        <BigTooltip title={v.agencyName} placement="top">
          <IconStyle>
            <Store style={{ fontSize: "50px" }} />
          </IconStyle>
        </BigTooltip>
      )
  }

  showLogs() {
    return (
      <>
        <Grid fluid style={{ margin: '0', padding: '0' }}>
          {this.state.logs.length ? (
            this.state.logs.map((log) => (
              <Col md={4} style={{ padding: 0, paddingRight: '5px', marginBottom: '1px' }}>
                <Card noFooter title={Moment(log.createdAt).format('DD/MM/YYYY LT')} contentStyle={{ lineHeight: '0px' }} >
                  <ErrorRow><strong>Arquivo:</strong> {log.file}</ErrorRow>
                  <ErrorRow><strong>Exceção:</strong> {log.exeption}</ErrorRow>
                  <ErrorRow><strong>Mensagem:</strong> {log.message}</ErrorRow>
                  {/* <ErrorRow><strong>Data:</strong> {Moment(log.createdAt).format('DD/MM/YYYY LT')}</ErrorRow> */}
                  <ErrorRow><strong>Usuário:</strong> {log.userName}</ErrorRow>
                  <ErrorRow><strong>Nível:</strong> {log.userLevel}</ErrorRow>
                </Card>
              </Col>
            ))
          ) : <div style={{ display: "flex", justifyContent: "center" }}><NothingFound>Não há entradas</NothingFound></div>}
        </Grid>
      </>
    )
  }

  showErrors() {
    return (
      this.state.errors.length ?
        this.state.errors.map(v => (
          <Card noTitle noFooter style={{ marginBottom: "3px" }}>
            <Row style={{ position: "relative" }}>
              <Col md={8} sm={8} xs={8} style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                <ErrorRow><b>Exceção:</b> {v.exception}</ErrorRow>
                <ErrorRow><b>Mensagem:</b> {v.message}</ErrorRow>
                <ErrorRow><b>Arquivo:</b> {v.filepath}</ErrorRow>
                <ErrorRow><b>Tipo do erro:</b> {errorType(v.errorType)}</ErrorRow>

              </Col>
              <Col md={4} sm={4} xs={4} style={{ height: "100%" }}>
                <Row style={{ justifyContent: "flex-end", display: "flex" }}>
                  {this.hasThings(v)}

                  <BigTooltip title={"Prioridade: " + errorPriority(v.priority)} placement="top">
                    <IconStyle>
                      {this.priorityColor(v.priority)}
                    </IconStyle>
                  </BigTooltip>

                </Row>
              </Col>
              <Row style={{ justifyContent: "flex-end", display: "flex", position: "relative" }}>
                <span style={{ position: "relative", right: "15px", bottom: "0", marginTop: "5px" }}>
                  {Moment(v.createdAt).format('DD/MM/YYYY LT')}
                </span>
              </Row>
            </Row>
          </Card>
        )) : <div style={{ display: "flex", justifyContent: "center" }}><NothingFound>Não há entradas</NothingFound></div>)

  }

  handleOptionChange(value) {
    this.clearStates()
    this.setState({
      option: value,
      loading: true,
      page: 1
    }, () => this.loadLists())
  }

  handlePriorityChange(value) {
    this.setState({
      selectedPriority: value,
      loading: true,
      page: 1
    }, () => this.loadLists())
  }

  handleTypeChange(value) {
    this.setState({
      selectedType: value,
      loading: true,
      page: 1
    }, () => this.loadLists())
  }

  async handlePageChange(action) {
    const { page, pageLimit, option } = this.state

    switch (action) {
      case 'foward':
        if (page <= pageLimit) {
          this.setState(() => ({ page: page + 1, loading: true }), async () => {
            await this.loadLists()
          })
        }
        break
      case 'backward':
        if (page > 1) {
          this.setState(() => ({ page: page - 1, loading: true }), async () => {
            await this.loadLists()
          })
        }
        break
    }
  }


  handleLimitChange(newLimit) {
    this.setState({ limit: newLimit }, () => {
      this.handlePageChange(0)
    })
  }

  loadLists = async () => {

    if (this.state.option) await this.getErrors()
    else await this.getLogs()

    await this.getQuantities()

    this.setState({
      loading: false
    })
  }

  getQuantities = async () => {
    const logsResponse = await mainApi.get(`/internal/operator/logs/count`);
    const errorResponse = await mainApi.get(`/internal/operator/errors/count`);

    this.setState({
      todayErrorCount: errorResponse.data.todayDocs[0] ? errorResponse.data.todayDocs[0].count : 0,
      todayLogCount:  logsResponse.data.todayDocs[0] ? logsResponse.data.todayDocs[0].total : 0
    })
  }

  componentDidMount = async () => {
    await this.loadLists()
    this.setState({
      loading: false
    })
  }

  render() {
    let selectOptions = [
      { value: true, label: "Erros" },
      { value: false, label: "Logs" }
    ]

    let selectPriority = [
      { value: null, label: "Todos" },
      { value: 'low', label: "Baixa" },
      { value: 'normal', label: "Normal" },
      { value: 'high', label: "Alta" },
      { value: 'urgent', label: "Urgente" }
    ]

    let selectErrorType = [
      { value: null, label: "Todos" },
      { value: 'not-specified', label: "Não especificado" },
      { value: 'api-error', label: "Erro de API" }
    ]

    const { showYearDropdown, showMonthDropdown, startDate, isClearable, selectsStart, endDate, pageLimit, option, todayErrorCount, todayLogCount } = this.state
    return (
      <Wrapper className="content" style={{ display: "block" }}>
        <Row style={{ marginBottom: "5px" }}>
          <Col md={6}><Card noHeader noFooter title="Erros hoje" contentStyle={{ fontSize: "36px", color: "red" }}>{todayErrorCount}</Card></Col>
          <Col md={6}><Card noHeader noFooter title="Logs hoje" contentStyle={{ fontSize: "36px", color: "red" }}>{todayLogCount}</Card></Col>
        </Row>
        <LoadingAlert show={this.state.loading} />
        <Filters>
          <Row style={{ display: "flex", alignItems: "flex-end" }}>
            <Col md={3}>
              <Select options={selectOptions}
                onChange={e => this.handleOptionChange(e.value)}
                defaultValue={{ value: true, label: "Erros" }}
              />
            </Col>
            {option ?
              <Col md={3}>
                <Select options={selectPriority}
                  onChange={e => this.handlePriorityChange(e.value)}
                  placeholder="Filtrar por prioridade"
                />
              </Col>
              : ""}
            {option ?
              <Col md={3}>
                <Select options={selectErrorType}
                  onChange={e => this.handleTypeChange(e.value)}
                  placeholder="Filtrar por tipo"
                />
              </Col>
              : ""}
            <Col lg={3}>
              <Row>
                <Col lg={12} style={{ display: "flex", justifyContent: "center" }}>
                  Pesquisa por data de ocorrência
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6}>
                  <DatePicker
                    id="data-tour-user-list-master-aproved-initialDate"
                    className="date-picker"
                    dateFormat='dd/MM/yyyy'
                    placeholderText="Inicio"
                    locale='pt-BR'
                    showYearDropdown
                    showMonthDropdown
                    selected={startDate}
                    onChange={(date, event) => this.setStartDate(date, event)}
                    isClearable
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    maxDate={new Date()}
                  />
                </Col>

                <Col lg={6} md={6}  >
                  <DatePicker
                    id="data-tour-user-list-master-aproved-finalDate"
                    className="date-picker"
                    dateFormat='dd/MM/yyyy'
                    placeholderText="Fim"
                    locale='pt-BR'
                    showYearDropdown
                    showMonthDropdown
                    selected={endDate}
                    onChange={(date, event) => this.setEndDate(date, event)}
                    isClearable
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    maxDate={new Date()}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Filters>
        {this.state.option
          ?
          (<Table>
            {this.showErrors()}
          </Table>)
          :
          this.showLogs()
        }

        <PaginationContainer>
          <ArrowsContainer id="teste">
            <ArrowContainer >
              <ChevronLeft onClick={() => { this.handlePageChange('backward') }} />
            </ArrowContainer>
            {`${this.state.page} . . . ${pageLimit}`}
            <ArrowContainer >
              <ChevronRight onClick={() => { this.handlePageChange('foward') }} />
            </ArrowContainer>
          </ArrowsContainer>
        </PaginationContainer>

        <div style={{ width: "100%", border: "solid 1px #DDD", marginTop: "10px" }}></div>
      </Wrapper>
    )
  }
}

export default  OperatorErrorPanel