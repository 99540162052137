import React, { Component, createRef } from "react";
import { Grid, Row, Col, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Card from "components/Card/Card";
import CardData from "components/Card/CardData";
import MahoeIcons from "components/MahoeIcons";
import InfoCard from "components/Card/InfoCard";
import CleanCard from "components/Card/CleanCard";
import { objectToParams, getMonthAndYear } from "utils/formatData";
import mainApi from "services/mainApi";
import to from "await-to-js";
import { LoadingAlert } from "components/Alert/CustomAlert";
import swal from "components/Alert/alert";
import moment from 'moment'
import socketIo from 'socket.io-client'
import { servers } from 'utils/config';
import Pagination from "@material-ui/lab/Pagination";
import { TextField, CircularProgress } from "@material-ui/core";
import { Backup, InfoOutlined } from "@material-ui/icons";
import { InfoCardContent } from './styled'
import ImportDataModal from 'components/ImportDataModal'

const appEnv = process.env.REACT_APP_ENV
let baseUrl = servers[appEnv] || servers['local'];


class ImportData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectDate: new Date(),
      openPopper: false,
      anchorEl: null,
      search: "",
      csvList: undefined,
      uploading: false,
      pagination: { page: 1, totalPages: 1 },
      dashboard: { success: 0, errors: 0, docs: 0 },
      csvLoading: false,
      percentage: 0,
      fileName: '',
      step: '',
      showCsvList: false,
      updatedAt: new Date(),
      openInfo: false
    };

    this.inputRef = createRef();
    this.searchRef = createRef();

    this.io = undefined;
  }

  setSelectedDate = (date) => this.setState({ selectDate: date })

  handleMouse = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
      openPopper: true,
    });
  };

  handleFile = async (e) => {

    const file = e.target.files[0];

    if (!file) swal.basic("info", "Arquivo não selecionado!", "Selecione um arquivo CSV.")

    this.setState({ uploading: true })

    const data = new FormData();

    data.append("csv", file);

    const [err, response] = await to(
      mainApi.post("/data/import", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    );

    if (err && err.response) {
      swal.basic("warning", "Temos um Problema", err.response.data.message);
      this.setState({ csvLoading: false, uploading: false })
      return;
    }
    const message = response?.data?.message || "";
    if (response?.data) swal.basic("success", "Propostas inseridas!", message);

    this.getImportedFiles()

    if (this.inputRef.current) this.inputRef.current.value = ''

    this.setState({ uploading: false })
  };

  getImportedFiles = async () => {
    const { selectDate, search, pagination } = this.state

    const params = objectToParams({ date: moment(selectDate).format('YYYY-MM-DD'), name: search, page: pagination.page })

    try {
      const response = await mainApi.get(`/data/import/list${params}`)

      this.setState({
        pagination: { page: response.data.data.page, totalPages: response.data.data.totalPages },
        csvList: response.data.data.docs,
        showCsvList: true
      })

    } catch (error) {
      console.warn(error)
    }
  }

  changePage = (event, value) => this.setState({ pagination: { page: value, totalPages: this.state.pagination.totalPages } }, () => this.getImportedFiles())

  getDashboardImport = async () => {
    try {
      const response = await mainApi.get('/data/import/dashboard')
      this.setState({ dashboard: { success: response.data.data.success, errors: response.data.data.error, docs: response.data.data.totalDocs } })
    } catch (error) {
      console.warn(error)
    }
  }

  convertTimeStamp = () => {
    const date = moment(this.state.updatedAt);

    return date.format("DD/MM/YYYY - HH:mm");
  };

  reload = async () => {
    await this.getImportedFiles();

    this.setState({ updatedAt: Date.now() });
  };

  componentDidMount() {
    this.getImportedFiles()
    this.getDashboardImport()
    this.io = socketIo(baseUrl)

    this.io.on(`importDatas${localStorage.getItem("@uxtech-mlm/UserId")}`, (data) => {
      this.setState({ percentage: data.percentage, fileName: data.fileName, step: data.text, csvLoading: data.csvLoading })
      if (data.reload) {
        setTimeout(() => {
          this.getDashboardImport()
          this.getImportedFiles()
        }, 2000)
      }
    })
  }

  componentWillUnmount(){
    if(this.io)
      this.io.close(()=> console.log('closed'))
  }

  handleModalClose = () => {
    this.setState({
      openInfo: false
    })
  }

  handleModalOpen = () => {
    this.setState({
      openInfo: true
    })
  }

  render() {
    const {
      selectDate,
      openPopper,
      anchorEl,
      searchRef,
      search,
      csvList,
      uploading,
      pagination,
      dashboard,
      csvLoading,
      percentage,
      fileName,
      step,
      openInfo
    } = this.state;

    const time = this.convertTimeStamp();
    return (
      <>
        <ImportDataModal show={openInfo} handleClose={this.handleModalClose}/>
        <LoadingAlert show={uploading} title="Inserindo propostas" />
        <Grid fluid style={{ paddingInline: "15px" }}>
          <Row className="log-cards" style={{ margin: 0 }}>
            <Col style={{ paddingInline: "15px" }} lg={4} md={4} sm={12}>
              <CleanCard noFooter noTitle contentStyle={{height:"90px", display:"flex", alignItems:"center"}} style={{borderRadius:"16px"}}>
                  <button className="link-style" onClick={this.handleModalOpen}>Clique para mais informações</button>
                  <InfoOutlined style={{ marginBottom: 'auto', fontSize:"34px"}} />
              </CleanCard>
            </Col>
            <Col style={{ paddingInline: "15px" }} lg={4} md={4} sm={12}>
              <InfoCard
                name={dashboard.success}
                description="Sucessos"
                icon="person-checked"
                iconColor="#009900"
                nameColor="green"
              ></InfoCard>
            </Col>
            <Col style={{ paddingInline: "15px" }} lg={4} md={4} sm={12}>
              <InfoCard
                name={dashboard.docs}
                description="Arquivos"
                icon="document-lines"
              ></InfoCard>
            </Col>
          </Row>

          <Row className="filters" style={{ margin: 0 }}>
            <Col lg={6}>
              <TextField
                fullWidth
                label="Pesquisa por nome"
                inputRef={this.searchRef}
                color="primary"
                variant="outlined"
                style={{ backgroundColor: "white" }}
              />

            </Col>
            <Col lg={4}>
              <DatePicker
                className="datepicker-theThird"
                dateFormat="MM/yyyy"
                locale="pt-BR"
                isClearable
                customInput={
                  <div className="div-datepicker">
                    <div className="col-6">
                      <span className="placeholder-datepicker">
                        {selectDate ? "Mês selecionado:" : "Selecione um mês"}
                      </span>
                    </div>
                    <div className="col-3">
                      <p className="date-datepicker">
                        {selectDate ? getMonthAndYear(selectDate) : ""}
                      </p>
                    </div>
                    <div
                      className="col-3"
                      style={{ alignSelf: "center" }}
                      onMouseEnter={(event) => this.handleMouse(event)}
                      onMouseLeave={() => this.setState({ openPopper: false })}
                    >
                      {selectDate ? (
                        <></>
                      ) : (
                          <MahoeIcons
                            aria-describedby={"iconTooltip"}
                            name="info"
                            size="23px"
                            fill="#039BE5"
                          />
                        )}
                    </div>
                  </div>
                }
                selected={selectDate}
                showMonthYearPicker
                onChange={(date, event) => this.setSelectedDate(date, event)}
                maxDate={new Date()}
                minDate={new Date("2020-01-01")}
              />
            </Col>
            <Col md={2}>

              <button
                className="btn btn-fill btn-mlm"
                style={{ width: "100%" }}
                onClick={(e) => {
                  this.setState({ search: this.searchRef.current.value }, () => {
                    this.getImportedFiles()
                  })
                }}
              >
                Filtrar
              </button>
            </Col>
          </Row>
          <Row style={{ padding: '0px 15px' }} >
            <Col  style={{ marginTop: 10 }} lgOffset={2} lg={4} md={6} sm={16} xs={12} >
                <a style={{ width: "100%" }} href="https://agxsoftwarecloud.s3.amazonaws.com/importacaoPropostas.csv" download className="btn btn-mlm ">Baixar Modelo CSV</a>
             </Col>
            <Col  style={{ marginTop: 10}} lg={4} md={6} sm={16} xs={12} >
              <button 
                type="button"
                className='btn btn-fill btn-mlm'
                style={{ width: "100%", display: 'flex', alignContent: 'center', justifyContent: 'center' }}
                onClick={() => {
                  console.log(this.inputRef.current)
                  this.inputRef.current.click();
                }}
              >
                <Backup style={{ marginRight: '5px' }} /> Importar
              </button>
              <input
                type="file"
                style={{ display: "none" }}
                ref={this.inputRef}
                onChange={this.handleFile}
              ></input>
            </Col>
          </Row>
          <Row style={{ marginRight: 15, marginLeft: 15, marginTop: 10 }}>
            <Col>
              <Grid fluid>
                <Row>
                  <Col style={{ display: 'flex' }} lg={10} md={10} sm={10}>
                    <h4 className="title" style={{ marginTop: 15, marginRight: 25 }}>
                      Listagem de CSVs importados
                  </h4>
                    <div
                      style={{
                        margin: "20px 0",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <span className="txtUpdateDashboard" style={{paddingTop:"2px"}}>  
                        Última atualização:{" "}
                        {this.state.loading ? "Buscando..." : time}
                      </span>

                      <span
                        onClick={this.reload}
                        style={{
                          cursor: "pointer",
                          marginLeft: "10px",
                          marginRight: "10px",
                          // zIndex: 99999,
                        }}
                      >
                        <MahoeIcons name="reload" size="22px" fill="#039BE5" />
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row >
                  {csvLoading ? (
                    <Col lg={3} md={4} sm={12}>
                      <CardData data={{ percentage, fileName, step }} isLoading />
                    </Col>
                  ) : ''
                  }
                  {csvList ?
                    csvList.map((csv) => (
                      <Col lg={3} md={4} sm={12} key={csv._id}>
                        <CardData data={{ ...csv }} />
                      </Col>
                    )) : (
                      <Col style={{ display: "flex", justifyContent: "center" }}>
                        <CircularProgress />
                      </Col>
                    )
                  }
                </Row>

                <div
                  style={{
                    display: "grid",
                    justifyItems: "center",
                    margin: "10px",
                  }}
                >
                  <Pagination
                    count={pagination.totalPages}
                    page={pagination.page}
                    showFirstButton
                    showLastButton
                    onChange={this.changePage}
                  />
                </div>
              </Grid>
            </Col>
          </Row>
        </Grid>
      </>
    );
  }
}

export default ImportData;
