import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";

import MahoeIcon from "components/MahoeIcons";
import getUserData from "utils/checkUser.js";

import config from "utils/serverVars";
import "../assets/css/styles.css";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailHelp: "",
    };
  }

  setEmail(email) {
    this.setState({
      emailHelp: email,
    });
  }
  
  componentDidMount = async () => {
    let userData = await getUserData(this.props.history);

    if (['corporation', 'subcorp', 'master', 'submaster'].includes(userData.level)) {
      this.setEmail(config.CONTACT_PJ);
    } else {
      this.setEmail(config.CONTACT_PF);
    }
  };

  render() {
    const { emailHelp } = this.state;

    return (
      <div className="content">
        <div className="container-fluid">
          <Row>
            <Col lg={6} md={12} sm={12}>            
              <div
                className="card"
                style={{
                  padding: 10,
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <MahoeIcon
                  name="mail"
                  size="78px"
                  fill="rgba(65, 65, 65)"
                  className="icon-email-help"
                />

                <div className="modal-help-body">
                  <span className="icon-text-help"></span>                  
                </div>

                <p className="text-email-contact">{emailHelp}</p>

                <div className="modal-help-body">
                  <span className="icon-text-help">
                    Atendimento exclusivo via e-mail.
                    <br />
                    Resposta em até 72 horas.
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Contact;
