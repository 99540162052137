import React, { Component } from "react"
import { Content } from "./styled"
import { Grid, Row, Col } from "react-bootstrap"
import MahoeIcons from "components/MahoeIcons"

class InfoCard extends Component {

	constructor(props) {
		super(props)
	}

	render() {

		const { name, description, icon, nameColor, iconColor } = this.props

		return (
			<Content>
				<Grid fluid className="card card-padding-form">
					<Row>
						<Col lg={10} md={10} xs={12}>
							<Row style={{minHeight:"45px", fontSize:"24px", alignItems:"top", display:"flex", color:nameColor}}><b>{name}</b></Row>
							<Row style={{minHeight:"30px"}}>{description}</Row>
						</Col>
						<Col lg={2} md={2} xsHidden style={{alignItems:"center", display:"flex", justifyItems:"end",padding:"0"}}>
							<MahoeIcons name={icon} fill={(iconColor || "#000000")} style={{ display:"flex", justifyItems:"end"}}/>
						</Col>
					</Row>
				</Grid>
			</Content>
		)
	}
}

export default InfoCard