import React, { Component } from "react";
import Corp from "components/Card/Corporation";
import mainApi from "services/mainApi";
import spinner from "assets/img/spinner.gif";
import getStatusCodeType from "utils/getStatusType";
import Select from "react-select";

import { Dialog } from "../components/Alert/CustomAlert";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFrown } from "@fortawesome/free-regular-svg-icons";
import LoadingGif from "components/loadingGif";
import { TextField, InputAdornment, Link } from "@material-ui/core";
import dev from "utils/dev";
import { Row, Col } from "react-bootstrap";

import { introSystem } from "utils/introToSystem";
import dataTour from "utils/dataTour";
import getUserData from "utils/checkUser";
import { Search } from "@material-ui/icons";
import MahoeIcon from "components/MahoeIcons";

export default class CorpSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      corpsData: [],
      swalMessage: [],
      swalMessageShow: false,
      error: false,
      firstLoad: true,
      clearSearch: false,
      page: 1,
      maxPages: 2,
      lastCheck: Date.now(),
      selectProducts: [],
      selectedProduct: {},
      totalDocs: "",
      inRequest: false
    };

    this.searchRef = React.createRef();
  }
  showMessage = (type, title, message, boldText) => {
    this.setState({
      swalMessageShow: true,
      swalMessage: [type, title, message, boldText],
      error: type === "error",
    });
  };

  getCorps = async (options = {}) => {
    if (options.clearSearch) {
      this.setState({ corpsData: [], firstLoad: true });
    }
    const { page } = this.state;

    this.setState({ loading: true });
    let search =
      this.searchRef.current && this.searchRef.current.value.length > 0
        ? this.searchRef.current.value
        : "";

    let product = "";

    try {
      if (this.state.selectedProduct.value)
        product = `&product=${this.state.selectedProduct.value}`;
      const searchQuery = search.length > 0 ? `&name=${search}` : "";
      const response = await mainApi.get(
        `/corporations?limit=10&page=${page}${searchQuery}${product}`
      );
      const newCorps = response.data.corporations.docs;
      const corporations = [...this.state.corpsData, ...newCorps];
      const totalPages = response.data.corporations.totalPages;
      this.setState({
        corpsData: corporations,
        firstLoad: false,
        maxPages: totalPages,
        totalDocs: response.data.corporations.totalDocs,
        inRequest: false
      });
    } catch (err) {
      if (!err.response) {
        if (err.message === "Network Error") {
          this.showMessage(
            "error",
            `Não foi possível carregar alterar a logo, tente novamente mais tarde.`
          );
          this.setState({ loading: false, inRequest: false });
          return;
        }
        if (err.code === "ECONNABORTED")
          this.showMessage(
            "error",
            "Tempo de espera atingido.",
            "Tente novamente mais tarde."
          );
        this.setState({ loading: false, inRequest: false });
        return;
      }
      let status = getStatusCodeType(err.response.status);
      if (err.response.message) {
        this.showMessage(status, err.response.message);
        this.setState({ loading: false, inRequest: false });
        return;
      }
      this.setState({ loading: false, inRequest: false });
    } finally {
      this.setState({ loading: false, inRequest: false });
    }
  };

  search = () => {
    const search = this.searchRef.current ? this.searchRef.current.value : "";

    if (!this.state.inRequest) {
      this.setState(
        {
          corpsData: [],
          page: 1,
          firstLoad: true,
          inRequest: true
        },
        () => {
          this.getCorps({ search });
        }
      );
    }
  };

  getNextPage = () => {
    const { page, maxPages } = this.state;
    if (page === maxPages) return;
    this.setState({ page: page + 1 }, this.getCorps);
  };

  getProducts = async () => {
    try {
      const response = await mainApi.get("/master/products");
      const products = response.data.products;

      const productsToSelect = [{ value: "", label: "TODOS" }];

      products.forEach((product) => {
        productsToSelect.push({
          value: product._id,
          label: product.name,
        });
      });

      this.setState({ selectProducts: productsToSelect });
    } catch (err) {
      if (err.response) console.log(err.response.data);
    }
  };

  changeSelectProduct = async (event) => {
    this.setState(
      { selectedProduct: { value: event.value, label: event.label } },
      () => this.search()
    );
  };

  componentDidMount = async () => {
    const userData = await getUserData(this.props.history);

    const { routeKey } = this.props;
    await this.getCorps();
    await this.getProducts();

    window.digitalData = {
      site: {
        nome: "Banco Pan",
      },
      page: {
        secao: "IndiqueoPan",
        estadoLogin: "LG",
        nome: "BP:IndiqueOPan:ListagemCorporacoes",
      },
      user: {
        crmid: userData.id,
        tipo: userData.brType,
      },
    };

    var evt = new CustomEvent("event-view-start");
    document.body.dispatchEvent(evt);

    document.addEventListener("scroll", (a, b) => {
      const { lastCheck, loading } = this.state;
      dev.log("Last Check: " + lastCheck);
      if (!loading && lastCheck && lastCheck > Date.now() - 400) {
        const offset = window.scrollY;
        const pageSize = document.body.scrollHeight;
        const clientHeight = document.body.clientHeight;
        const userPosition = ((offset + clientHeight) / pageSize) * 100;
        dev.log(
          "Offset: ",
          offset,
          "PageSize: ",
          pageSize,
          "UserPosition: ",
          userPosition.toFixed("2"),
          "%"
        );
        if (userPosition > 92) this.getNextPage();
      }
      const now = Date.now();
      this.setState({ lastCheck: now });
    });
    document.addEventListener(
      "keydown",
      (e) => this.onKeyDown(e, this.search),
      false
    );

    introSystem(routeKey);
  };

  componentWillUnmount() {
    document.removeEventListener(
      "keydown",
      (e) => this.onKeyDown(e, this.search),
      false
    );
  }

  onKeyDown(event, func) {
    const searchInput = document.getElementById("search-input");
    if (event.keyCode === 13) {
      if (searchInput && searchInput === document.activeElement) {
        func();
      }
    }
  }

  openCorp = (id) => {
    this.props.history.push("/admin/corporations/details?id=" + id);
  };

  render() {
    const {
      corpsData,
      loading,
      swalMessage,
      swalMessageShow,
      error,
      firstLoad,
      clearSearch,
      totalDocs,
    } = this.state;

    return (
      <>
        <Dialog
          type={swalMessage[0]}
          show={swalMessageShow}
          title={swalMessage[1] || ""}
          onConfirm={() => {
            this.setState({ swalMessageShow: false });
          }}
          text={swalMessage[2]}
          boldText={swalMessage[3]}
        />

        <div className="content container-fluid">
          <Row style={{ padding: 15, paddingBottom: 30 }}>
            <Col md={6} sm={6}>
              <TextField
                label="Nome da Corporação"
                className="input-background"
                InputProps={{
                  classes: {
                    root: "mui-input-control",
                    input: "mui-input-control",
                    notchedOutline: "mui-input-focus",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      {clearSearch ? (
                        <Link
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.searchRef.current.value = "";
                            this.setState(
                              { clearSearch: false, page: 1 },
                              () => {
                                this.getCorps({ clearSearch: true });
                              }
                            );
                          }}
                        >
                          Limpar Busca
                        </Link>
                      ) : (
                        ""
                      )}
                    </InputAdornment>
                  ),
                }}
                fullWidth
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length > 0 &&
                    !clearSearch
                  )
                    this.setState({ clearSearch: true });
                }}
                onBlur={() => {
                  if (
                    this.searchRef.current &&
                    this.searchRef.current.value === "" &&
                    clearSearch
                  ) {
                    this.setState({ clearSearch: false });
                  }
                }}
                inputRef={this.searchRef}
                id="search-input"
                InputLabelProps={{
                  classes: { root: "mui-input-control mui-label" },
                }}
                variant="outlined"
              />
            </Col>
            <Col md={1} sm={2}>
              <div className="rounded-div">
                <button
                  className="btn btn-mlm btn-round btn-fill btn-block "
                  onClick={this.search}
                >
                  <Search />
                </button>
              </div>
            </Col>
            <Col md={4} sm={6}>
              <Select
                className="custom-select"
                placeholder="Selecione um Produto"
                options={this.state.selectProducts}
                onChange={this.changeSelectProduct}
              />
            </Col>
          </Row>
          {firstLoad && loading ? (
            <div className="loading-table">
              <img src={spinner} alt="girando" />
              <h2>Carregando...</h2>
            </div>
          ) : error ? (
            <div className="card">
              <div className="no-seller-here">
                <span>
                  Não foi possível buscar as corporações, tente novamente mais
                  tarde.
                </span>
              </div>
            </div>
          ) : corpsData.length > 0 ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="column-icons-names">
                <h3 style={{ paddingLeft: "15px" }}>
                  Total encontrado: {totalDocs}
                </h3>
                <div>
                  <MahoeIcon name="link-default" fill="#039BE5" size="18px" />
                  <span style={{ verticalAlign: "super" }}>
                    Links Criados
                  </span>
                  <MahoeIcon name="checked" fill="#13dd13" size="18px" />
                  <span style={{ verticalAlign: "super" }}>
                    Propostas Aprovadas
                  </span>
                  <MahoeIcon name="clock" fill="#e99c0d" size="22px" />
                  <span style={{ verticalAlign: "super" }}>
                    Em Análise
                  </span>
                  <MahoeIcon name="rounded-close" fill="#c20909" size="18px" />
                  <span style={{ verticalAlign: "super" }}>
                    Propostas Recusadas
                  </span>
                </div>
              </div>
              <div className='corp-card-list-wrapper'>{corpsData.map((corporation, index) => (
                <Corp
                  dataIntro={index === 0 ? corporation.corpSelect : null}
                  data={corporation}
                  key={corporation._id}
                  onClick={() => {
                    this.openCorp(corporation._id);
                  }}
                />
              ))}</div>
              <div>{loading && !firstLoad ? <LoadingGif /> : null}</div>
            </div>
          ) : clearSearch ? (
            <div className="card">
              <div className="no-seller-here">
                <FontAwesomeIcon
                  icon={faFrown}
                  size="6x"
                  style={{ marginRight: 4 }}
                />
                <span>Nenhuma corporação encontrada.</span>
              </div>
            </div>
          ) : (
            <div className="card">
              <div className="no-seller-here">
                <FontAwesomeIcon
                  icon={faFrown}
                  size="6x"
                  style={{ marginRight: 4 }}
                />
                <span>
                  Você não tem nenhuma corporação cadastrada.{" "}
                  <button
                    className="link-style"
                    onClick={() => {
                      this.props.history.push("/admin/corporations/new");
                    }}
                  >
                    Clique aqui
                  </button>{" "}
                  para cadastrar uma corporação.
                </span>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}
