import styled, { keyframes } from 'styled-components'

export const Wrapper = styled.div `
  background-color: #FFFFFF;  
  border: 1px solid #E0E0E0;
  position: relative;

  padding: 15px;
  border-radius: 15px;

  border-left: 10px solid ${ props => props.color ? props.color : 'grey' };

  ${ props => props.active ? 
    `background-color: ${props.color};`
  : 
  ''}

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }

  &:hover {
    -webkit-box-shadow: 0px 10px 50px -19px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 10px 50px -19px rgba(0,0,0,0.75);
    box-shadow: 0px 10px 50px -19px rgba(0,0,0,0.75);    
    cursor: pointer;
  }

  &::before {
    content: "";
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    background: ${ props => props.color ? props.color : 'grey' };
    transition: 0.8s;
  }

  &:hover::before {
    width: 100%;
  }
  
`

export const Itens = styled.div `
  flex: 3;  

  z-index: 1;
`

export const Title = styled.p `
  font-weight: bold;

  font-size: 1.2em;
  letter-spacing: 5px;

  z-index: 1;
`

export const Item = styled.p `
  color: black;
  font-size: 0.9em;  

  padding: 0 5px;  

  z-index: 1;
`

export const NameContainer = styled.div `
  display: flex;
  flex-direction: row;
  padding: 5px;
  flex: 1;  

  z-index: 1;
`

export const ContentIcon = styled.div `
  display: flex;  
  align-items: center;
  justify-content: center;  

  z-index: 1;
`