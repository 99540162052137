import React, { Component } from "react";
import Card from "./Card/Card";
import MahoeIcon from "components/MahoeIcons";
import { Col, Row } from "react-bootstrap";
import InputMask from "react-input-mask";
import mainApi from "services/mainApi";
import { validateData } from "utils/validate"
import { LoadingAlert, Dialog } from "./Alert/CustomAlert";
import { Grid } from "react-bootstrap";
import getUserData from "utils/checkUser"
import queryString from 'query-string'
import MuiSelect from 'components/Selects/MuiSelect'
import LinkButton from 'components/LinkButton'
import MahoeIcons from 'components/MahoeIcons'
import Swal from 'sweetalert2'
import swal from 'components/Alert/alert'

import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment
} from "@material-ui/core";
import { numberLength } from "utils/numberMethods";
import to from "await-to-js";
import { ClubeAmigoId } from "utils/CommonData";

const CustomInputLabel = ({ children, ...rest }) => <InputLabel {...rest} style={{ textTransform: 'capitalize', fontSize: 16, fontWeight: 500, lineHeight: '10px' }}>{children}</InputLabel>

const MuiTextField = (props) => (
  <TextField
    InputProps={{
      classes: {
        input: "mui-input-control",
        root: "mui-input-control",
      },
    }}
    InputLabelProps={{
      classes: {
        root: "mui-input-control mui-label",
      },
      shrink: props.tel
    }}
    fullWidth
    variant="outlined"
    {...props}
  >
    {props.children}
  </TextField>
);

export default class AgencyManagement extends Component {

  constructor(props) {
    super(props)

    this.state = {
      agencyId: "",
      agencyName: "",
      agencyCommission: 0,
      city: "",
      zipcode: "",
      street: "",
      number: "",
      state: "",
      additional: "",
      neighborhood: "",
      reCode: undefined,
      invalidMessage: {},
      swalShowLoading: false,
      swalMessage: '',
      swalMessageShow: false,
      userData: {},
      ddds: [],
      ddd: "",
      email: "",
      executive: [],
      selectedExecutive: "",
      agencyTitle: '',
      administratorId: ''
    }

    this.numberRef = React.createRef();
    this.states = [
      "AC",
      "AL",
      "AP",
      "AM",
      "BA",
      "CE",
      "DF",
      "ES",
      "GO",
      "MA",
      "MT",
      "MS",
      "MG",
      "PA",
      "PB",
      "PR",
      "PE",
      "PI",
      "RJ",
      "RN",
      "RS",
      "RO",
      "RR",
      "SC",
      "SP",
      "SE",
      "TO",
    ];
  }

  handleCep = async event => {
    const searchCep = event.target.value;
    if (searchCep === this.state.zipcode) return;
    this.setState({ zipcode: searchCep });
    const rawCep = searchCep.replace(/\D/g, "");
    if (rawCep.length === 8) {
      try {
        const response = await mainApi.get(`/resources/cep/${rawCep}`);
        const {
          street,
          neighborhood,
          city,
          state,
          additional
        } = response.data.address;
        this.handleState(state)
        this.setState({ street, neighborhood, city, additional });
        this.numberRef.current.focus();
      } catch (error) { }
    }
  };

  handleState = async (state, callback) => {
    if (!state || state === this.state.state) return;
    this.setState({ state });
    if (state.length === 2) {
      try {
        const response = await mainApi.get(`/resources/ddds/${state}`);
        const ddds = response.data.ddds.map(ddd => ({ label: ddd, value: ddd }))

        if (ddds.length === 1) this.handleDDD(ddds[0].value)
        this.setState({ ddds }, () => { this.setState({ ddd: ddds.length === 1 ? ddds[0] : "" }) })
      } catch (error) { }
    }
    if (callback) callback()
  };

  handleDDD = async ddd => {
    const state = this.state.state;
    if (!ddd.value || ddd.value === this.state.ddd) return;
    this.setState({ ddd, selectedExecutive: "" });
    if (ddd.value.length === 2) {
      try {
        const response = await mainApi.get(`/managers?uf=${state}&ddd=${ddd.value}`);
        const managers = response.data.data.managers
        this.handleManagers(managers)
      } catch (error) { }
    }
  };


  handleManagers = managers => {
    const { update } = this.props

    const executive = [];
    managers.forEach(manager => {
      switch (manager.level) {
        case 'executive':
          executive.push({ value: manager.id, label: manager.name })
          break
        default:
          break
      }
    })

    if(update) this.setState({ executive: [{ value: 'no-manager', label: 'Sem gêrencia' } ,...executive ] })
    else this.setState({ executive })
  }

  createNewAgency = async () => {
    const {
      agencyName,
      agencyCommission,
      zipcode,
      street,
      number,
      state,
      phone,
      neighborhood,
      city,
      ddd,
      selectedExecutive,
      email,
      partnerName,
      reCode,
      agencyId,
    } = this.state;

    const address = {
      zipcode: zipcode ? zipcode.replace('-', '') : undefined,
      street,
      number,
      state,
      neighborhood,
      city
    }

    let responseResult = false

    this.setState({ invalidMessage: {}, swalShowLoading: true })

    const invalidFields = validateData({
      zipcode: zipcode ? zipcode.replace('-', '') : undefined,
      street,
      number,
      state,
      neighborhood,
      city,
      agencyName,
      agencyCommission
    })

    if (Object.keys(invalidFields).length !== 0) {
      return this.setState({
        swalShowLoading: false,
        swalMessage: ["warning", "Campos não foram preenchidos"],
        swalMessageShow: true,
        invalidMessage: invalidFields
      })
    }

    try {
      const response = await mainApi.post("/agencies",
        {
          name: agencyName,
          commission: agencyCommission,
          phone,
          ddd: ddd.value,
          email,
          partnerName,
          reCode,
          executiveId: selectedExecutive.value,
          address
        }
      );

      this.setState({
        swalShowLoading: false,
        swalMessage: ["success", response.data.message],
        swalMessageShow: true
      });

      responseResult = true
      this.clearStates();
    } catch (err) {
      if (err && err.response) {
        this.setState({
          swalShowLoading: false,
          swalMessage: ["warning", err.response.data.message],
          swalMessageShow: true
        });
        if (err.response.data.fields) {
          const invalidMessage = {};
          err.response.data.fields.forEach(field => {
            invalidMessage[field.name] = field.message
          })
          this.setState({ invalidMessage })
        }
      }
      responseResult = false
    }

    if (responseResult) {
      var eventDetail = { "detail": { "button": "Click:BP:IndiqueOPan:NovaLoja" } };
      var interactionClick = new CustomEvent("interactionClick", eventDetail);
      document.body.dispatchEvent(interactionClick);
    }
  };

  handleUpdatePartner = () => {
    Swal.fire({
      icon: 'warning',
      title: 'Atenção',
      html: `Tem certeza que deseja alterar o email deste parceiro?`,
      // showConfirmButton: buttons,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
      closeOnClickOutside: false,
      customClass: {
        confirmButton: 'btn btn-success w-100',
        cancelButton: 'btn btn-danger w-100'
      }
    })
      .then((result) => {
        if (result.isConfirmed) {
          this.updatePartner()
        }
      })
  }

  updatePartner = async () => {
    const { partnerId, email } = this.state
    if (!partnerId || !email) return
    const data = {
      email
    }
    try {
      const response = await mainApi.patch(`agency/manager/${partnerId}/email`, data)
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        html: `Email atualizado com sucesso.`,
        // showConfirmButton: buttons,
        closeOnClickOutside: false,
        customClass: {
          confirmButton: 'btn btn-success w-100',
        }
      })
    } catch (err) {
      console.log(err)
      let field = ''
      if (err.response?.data?.invalid?.length)
        field =  `(${err.response?.data?.invalid[0].name})`
      swal.basic(
        'warning',
        'Atenção',
        (err.response?.data?.message + field) || 'Ocorreu um problema'
      )
    }
  }

  updateAgency = async () => {
    const {
      agencyId,
      agencyName,
      additional,
      agencyCommission,
      zipcode,
      street,
      number,
      state,
      phone,
      neighborhood,
      city,
      ddd,
      email,
      invalidMessage,
      selectedExecutive,
    } = this.state;

    const address = {
      zipcode: zipcode ? String(zipcode).replace('-', '') : undefined,
      street,
      number,
      state,
      additional,
      neighborhood,
      city
    }

    let responseResult = false

    this.setState({ invalidMessage: {}, swalShowLoading: true })

    const invalidFields = validateData({
      zipcode: zipcode ? String(zipcode).replace('-', '') : undefined,
      street,
      number,
      state,
      neighborhood,
      city,
      agencyName,
      agencyCommission
    })

    if (Object.keys(invalidFields).length !== 0) {
      return this.setState({
        swalShowLoading: false,
        swalMessage: ["error", "Campos não foram preenchidos"],
        swalMessageShow: true,
        invalidMessage: invalidFields
      })
    }

    const data = {
      name: agencyName,
      commission: agencyCommission,
      phone,
      ddd: ddd.value,
      email,
      address
    }

    if(selectedExecutive.value) data.executiveId = selectedExecutive.value

    try {
      const response = await mainApi.patch(`/agency/${agencyId}`, data);

      this.setState({
        swalShowLoading: false,
        swalMessage: ["success", response.data.message],
        swalMessageShow: true
      });

      responseResult = true
      this.clearStates();
    } catch (err) {
      if (err && err.response) {
        this.setState({
          swalShowLoading: false,
          swalMessage: ["error", err.response.data.message],
          swalMessageShow: true
        });
        if (err.response.data.fields) {
          err.response.data.fields.forEach(field => {
            invalidMessage[field.name] = field.message
          })
        }
      }
      responseResult = false
    }

    if (responseResult) {
      var eventDetail = { "detail": { "button": "Click:BP:IndiqueOPan:NovaLoja" } };
      var interactionClick = new CustomEvent("interactionClick", eventDetail);
      document.body.dispatchEvent(interactionClick);
    }
  };

  clearStates = async () => {
    this.setState({
      agencyName: "",
      agencyCommission: 0,
      city: "",
      zipcode: "",
      street: "",
      number: "",
      state: "UF",
      additional: "",
      neighborhood: "",
      selectedExecutive: ""
    })
  }

  setAgency = async () => {
    const id = queryString.parse(this.props.history.location.search).id

    const [error, response] = await to(mainApi.get(`/agencies/${id}`))

    if (error) return

    const agency = response.data.agency
    const address = agency.address

    if (address) {
      this.handleState(address.state, () => {
        if (agency.ddd) this.handleDDD({ value: agency.ddd, label: agency.ddd })
        this.setState({
          ...address,
          phone: agency.phone,
          agencyId: agency._id,
          agencyName: agency.name,
          agencyTitle: agency.name,
          agencyCommission: agency.commission,
          selectedExecutive: agency.executive ? { value: agency.executive.id, label: agency.executive.name  } : '',
          email: agency.administrator.email,
          partnerName: agency.administrator.name,
          partnerId: agency.administratorId,
          reCode: agency.administrator.reCode
        })
      })
    }
    else {
      this.handleDDD({ value: agency.ddd, label: agency.ddd })
      this.setState({
        phone: agency.phone,
        agencyId: agency._id,
        agencyName: agency.name,
        agencyTitle: agency.name,
        agencyCommission: agency.commission,
        selectedExecutive: agency.executive ? { value: agency.executive.id, label: agency.executive.name  } : '',
        email: agency.administrator.email,
        partnerName: agency.administrator.name,
        partnerId: agency.administratorId,
        reCode: agency.administrator.reCode
      })
    }
  }

  setUserData = async () => {
    const userData = await getUserData(this.props.history);

    this.setState({ userData });
  };

  componentDidMount = () => {
    this.setUserData()
    if (this.props.update) {
      this.setAgency()
    }
  }

  setPhone = (event) => {
    this.setState({phone: event.target.value})
  }


  setName = (event) => {
    this.setState({ agencyName: event.target.value })
  }

  isAdmn = () => {
    const { userData } = this.state

    return userData.level === 'administrator'
  }

  setCommission = (event) => {
    if (
      event.target.value === "" ||
      Number(event.target.value)
    ) {
      let commission = numberLength(event.target.value);
      if (commission >= 100) commission = 100;
      if (commission < 0) commission = 0;

      this.setState({
        agencyCommission: commission,
      });
    }
  }

  setPhoneFunc = () => this.setPhone
  setNameFunc = () => this.setName
  setCommissionFunc = () => this.setCommission

  goTo = (url) => setTimeout(() => this.props.history.push(url), 200)

  render() {
    const {
      agencyName,
      agencyCommission,
      phone,
      ddd,
      ddds,
      city,
      email,
      partnerName,
      zipcode,
      street,
      number,
      state,
      additional,
      neighborhood,
      invalidMessage,
      swalShowLoading,
      swalMessage,
      swalMessageShow,
      userData,
      reCode,
      executive,
      selectedExecutive,
      agencyTitle
    } = this.state

    const IS_CLUBEAMIGO = userData?.corpId === ClubeAmigoId

    return (
      <div className="content">
        <LoadingAlert show={swalShowLoading} title="Carregando..." />

        <Dialog
          type={swalMessage[0]}
          show={swalMessageShow}
          title={swalMessage[0] === "success" ? "Sucesso!" : "Erro"}
          onConfirm={() => {
            this.setState({ swalMessageShow: false },
              () => { if (swalMessage[0] === 'success') this.goTo('/admin/corporation/details') });

          }}
          text={swalMessage[1]}
          boldText={swalMessage[2]}
        />

        <Grid fluid>
          <Card
            title={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {this.props.update && `Editar ${agencyTitle}`}
              {userData && ['corporation', 'subcorp'].includes(userData.level) ? (
                  <LinkButton style={{ maxWidth: 265, maxHeight: 38 }} onClick={() => this.goTo('/admin/corporation/details')}>
                    <MahoeIcons name="arrow-back" fill="#0277bd" size={16} style={{margin: '-4px 2px'}} /> Voltar
                  </LinkButton>
                ) : null}
              </div>
            }
            content={
              <form className="agency-form">
                <div>
                <h4 className="section-name" id="personal-data">
                  Informações do parceiro{" "}
                  <MahoeIcon name="picto" fill="#FF5630" size="8px" />
                </h4>
                <Row>
                      <Col lg={4} md={6} sm={8}>
                        <MuiTextField
                          label="Nome do parceiro"
                          className="form-control input-login"
                          value={partnerName || ''}
                          disabled={this.props.update}
                          error={Boolean(invalidMessage.partnerName)}
                          onChange={(event) => {
                            this.setState({ partnerName: event.target.value });
                          }}
                        />
                        <div className="invalid-feedback-signup">
                          {invalidMessage.email}
                        </div>
                      </Col>
                      <Col lg={4} md={6} sm={8}>
                        <MuiTextField
                          label="Email do parceiro"
                          className="form-control input-login"
                          value={email}
                          error={Boolean(invalidMessage.email)}
                          onChange={(event) => {
                            this.setState({ email: event.target.value });
                          }}
                        />
                        <div className="invalid-feedback-signup">
                          {invalidMessage.email}
                        </div>
                      </Col>
                     { 
                      IS_CLUBEAMIGO &&
                      <Col lg={4} md={6} sm={8}>
                          <MuiTextField
                            label="RE do parceiro"
                            className="form-control input-login"
                            disabled={this.props.update}
                            value={reCode}
                            error={Boolean(invalidMessage.reCode)}
                            onChange={(event) => {
                              this.setState({ reCode: event.target.value });
                            }}
                          />
                          <div className="invalid-feedback-signup">
                            {invalidMessage.reCode}
                          </div>
                        </Col>
                      }
                      {
                        this.props.update && (
                          <Col lg={4} md={6} sm={8}>
                            <button
                              type='button'
                              className="btn btn-mlm btn-fill btn-round btn-block"
                              onClick={this.handleUpdatePartner}
                            >
                                Atualizar Parceiro
                            </button>
                          </Col>
                        )
                      }
                    </Row>
                </div>
                <div>
                <h4 className="section-name" id="personal-data">
                  Informações da loja{" "}
                  <MahoeIcon name="picto" fill="#FF5630" size="8px" />
                </h4>
                  <Row>
                    <Col sm={12} md={5} lg={6}>
                      <MuiTextField
                        label="Nome"
                        className="form-control input-login"
                        error={Boolean(invalidMessage.agencyName)}
                        value={agencyName}
                        onChange={this.setNameFunc()}
                      />
                      <div className="invalid-feedback-signup">
                        {invalidMessage.agencyName}
                      </div>
                    </Col>
                    <Col sm={12} md={2} lg={3}>
                      <InputMask
                        mask="(99) 9999-9999"
                        maskChar="_"
                        value={phone}
                        onChange={this.setPhoneFunc()}
                      >
                        {() => (
                          <MuiTextField
                            label="Telefone Fixo"
                            error={Boolean(invalidMessage.phone)}
                            tel={phone ? true : false}
                          />
                        )}
                      </InputMask>
                      <div className="invalid-feedback-signup">
                        {invalidMessage.phone}
                      </div>
                    </Col>
                    <Col sm={12} md={4} lg={3} className="padding-new-store-input">
                      <TextField
                        label="Bonificação:"
                        type="number"
                        InputProps={{
                          classes: {
                            input: "mui-input-control",
                            root: "mui-input-control",
                          },
                          inputMode: "tel",
                          pattern: "[0-9]*",
                          startAdornment: (
                            <InputAdornment
                              style={{
                                position: "absolute",
                                marginLeft: Number.isInteger(
                                  Number(agencyCommission)
                                )
                                  ? 52
                                  : 52,
                              }}
                              position="start"
                            >
                              {" "}
                              %
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{
                          classes: {
                            root: "mui-input-control mui-label",
                          },
                        }}
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        inputProps={{
                          step: "0.1",
                        }}
                        variant="outlined"
                        value={agencyCommission}
                        fullWidth
                        onFocus={(element) => element.target.select()}
                        onChange={this.setCommissionFunc()}
                        placeholder="Ex: 12.05%"
                      />
                    </Col>
                  </Row>

                  <h4 className="section-name" id="personal-data">
                    Endereço <MahoeIcon name="picto" fill="#FF5630" size="8px" />
                  </h4>
                  <br />

                  <div className="form-group">
                    <Row>
                      <Col sm={12} md={6} lg={6}>
                        <InputMask
                          mask="99999-999"
                          maskChar=""
                          value={zipcode}
                          onChange={(event) => {
                            this.handleCep(event);
                          }}
                        >
                          {() => (
                            <MuiTextField
                              label="CEP"
                              error={Boolean(invalidMessage.zipcode)}
                            />
                          )}
                        </InputMask>
                        <div className="invalid-feedback-signup">
                          {invalidMessage.zipcode}
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className="form-group">
                    <Row>
                      <Col lg={9} md={9} sm={12} className='create-agency-fields'>
                        <MuiTextField
                          label="Logradouro"
                          className="form-control input-login"
                          value={street}
                          error={Boolean(invalidMessage.street)}
                          onKeyPress={this.handleKeyPressedOnStreet}
                          onChange={(event) => {
                            this.setState({
                              street: event.target.value,
                            })
                          }}
                        />
                        <div className="invalid-feedback-signup">
                          {invalidMessage.street}
                        </div>
                      </Col>

                      <Col lg={3} md={3} sm={12}>
                        <MuiTextField
                          label="Número"
                          className="form-control input-login"
                          error={Boolean(invalidMessage.number)}
                          pattern="[0-9]*"
                          inputMode="numeric"
                          type="tel"
                          value={number}
                          inputRef={this.numberRef}
                          onChange={(event) => {
                            let value = event.target.value.replace(/\D/g, "");
                            this.setState({ number: value });
                          }}
                        />
                        <div className="invalid-feedback-signup">
                          {invalidMessage.number}
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className="form-group">
                    <Row>
                      <Col lg={12} md={12} sm={12}>
                        <MuiTextField
                          label="Complemento (opcional)"
                          className="form-control input-login"
                          error={Boolean(invalidMessage.additional)}
                          value={additional}
                          onChange={(event) => {
                            this.setState({
                              additional: event.target.value,
                            });
                          }}
                        />
                        <div className="invalid-feedback-signup">
                          {invalidMessage.additional}
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className="form-group">
                    <Row>
                      <Col lg={7} md={4} sm={12} className='create-agency-fields'>
                        <MuiTextField
                          label="Bairro"
                          className="form-control input-login"
                          value={neighborhood}
                          error={Boolean(invalidMessage.neighborhood)}
                          onChange={(event) => {
                            this.setState({
                              neighborhood: event.target.value,
                            });
                          }}
                        />
                        <div className="invalid-feedback-signup">
                          {invalidMessage.neighborhood}
                        </div>
                      </Col>

                      <Col lg={3} md={4} sm={12} className='create-agency-fields'>
                        <MuiTextField
                          label="Cidade"
                          className="form-control input-login"
                          value={city}
                          error={Boolean(invalidMessage.city)}
                          onChange={(event) => {
                            this.setState({ city: event.target.value });
                          }}
                        />
                        <div className="invalid-feedback-signup">
                          {invalidMessage.city}
                        </div>
                      </Col>

                      <Col lg={2} md={4} sm={12} className='create-agency-fields'>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          error={Boolean(invalidMessage.state)}
                        >
                          <CustomInputLabel>UF</CustomInputLabel>
                          <Select
                            classes={{ root: "fix-height" }}
                            className="fix-height"
                            value={state}
                            onChange={(e) => this.handleState(e.target.value)}
                            label="UF"
                          >
                            <MenuItem value="">
                              <em>Nenhum</em>
                            </MenuItem>
                            {this.states.map((state) => (
                              <MenuItem value={state} key={state}>
                                {state}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <div className="invalid-feedback-signup">
                          {invalidMessage.state}
                        </div>
                      </Col>
                    </Row>
                  </div>

                  {userData.hasManager && <>
                  <h4 className="section-name" id="personal-data">
                    Gerência <MahoeIcon name="picto" fill="#FF5630" size="8px" />
                  </h4>
                  <br />
                  <div className="form-group">
                    <Row>
                      <Col lg={3} md={4} sm={4}>
                        <MuiSelect
                          value={ddd}
                          label='DDD de Operação'
                          placeholder='DDD de Operação'
                          options={ddds}
                          onChange={(value) => this.handleDDD(value)}
                          error={invalidMessage.state}
                        />
                      </Col>

                    </Row>

                  </div>
                  {/* <div className="form-group">
                    <Row>
                      <Col lg={6} md={6} sm={12} className='create-agency-fields'>
                        <MuiSelect
                          value={selectedPrivateManager}
                          label='Gerente Private'
                          placeholder='Gerente Private'
                          options={privateManager}
                          onChange={(value) => this.setState({ selectedPrivateManager: value })}
                          error={invalidMessage.privateId}
                        />
                      </Col>
                      <Col lg={6} md={6} sm={12}>
                        <MuiSelect
                          value={selectedExclusive}
                          label='Gerente Exclusive'
                          placeholder='Gerente Exclusive'
                          options={exclusive}
                          onChange={(value) => this.setState({ selectedExclusive: value })}
                          error={invalidMessage.exclusiveId}
                        />
                      </Col>
                    </Row>
                  </div> */}
                  <div className="form-group">
                    <Row>
                      <Col lg={6} md={6} sm={12} className='create-agency-fields'>
                        <MuiSelect
                          value={selectedExecutive}
                          placeholder='Executivo Corban'
                          label='Executivo Corban'
                          options={executive}
                          onChange={(value) => this.setState({ selectedExecutive: value })}
                          error={invalidMessage.executiveId}
                        />
                      </Col>
                      {/* <Col lg={6} md={6} sm={12}>
                        <MuiSelect
                          value={selectedSuperintendent}
                          label='Superintendente'
                          placeholder='Superintendente'
                          options={superintendent}
                          onChange={(value) => this.setState({ \: value })}
                          error={invalidMessage.superintendentId}
                        />
                      </Col> */}
                    </Row>
                  </div></>}

                  <button
                    type='button'
                    className="btn btn-mlm btn-fill btn-round btn-block button-create-corp"
                    onClick={(event) => {
                      event.preventDefault();
                      if (this.props.update)
                        this.updateAgency();
                      else
                        this.createNewAgency();
                    }}
                  >
                    {this.props.update ? "Atualizar Loja" : "Criar Loja"}
                  </button>
                </div>
              </form>
            }
          />
        </Grid>
      </div>
    );
  }
}