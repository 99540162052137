import React, { useReducer, useEffect, useState, useCallback } from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";

import MuiDataTableOption from "utils/MuiDataTableOptions";
import { tableFunctions } from "./tableColumns";
import getMuiTheme from "utils/getMuiTheme";
import { objectToParams } from "utils/formatData";
import mainApi from "services/mainApi";
import { LoadingAlert, QrCode } from "components/Alert/CustomAlert";
import moment from "moment";
import "moment/locale/pt-br"
import getUserData from "utils/checkUser";
import { useHistory } from "react-router-dom";

const initialState = {
  pagination: {
    totalDocs: 0,
    page: 0,
  },
  search: {
    page: 1,
    month: moment().format("YYYY-MM"),
    date: moment().format("YYYY-MM"),
    product: ''
  },
  limit: 50,
  showLoading: false,
  campaignName: "",
  campaign: [],
  qrcode: false,
  url: "",
  clicked: false,
  month: new Date(),
  name: ''
};

const reducer = (state, action) => {
  const search = state.search;
  const pagination = state.pagination;

  switch (action.type) {
    case "loading":
      return { ...state, showLoading: action.loading };
    case "changePage":
      return { ...state, search: { ...search, page: action.page + 1 } };
    case "campaignLinks":
      return {
        ...state,
        campaign: action.campaign,
        pagination: {
          ...pagination,
          page: action.page - 1,
          totalDocs: action.totalDocs,
        },
        search,
      };
    case "search":
      return {
        ...state,
        search: {
          ...search,
          page: 1,
          campaign: action.campaignName,
          search: action.search,
        },
      };
    case "month":
      return {
        ...state,
        month: action.month,
        search: {
          ...search,
          month: moment(action.month).format("YYYY-MM"),
        },
      };
    case "date":
      return {
        ...state,
        date: action.month,
        search: {
          ...search,
          date: moment(action.date).format("YYYY-MM"),
        },
      };
    case "product":
      return {
        ...state,
        search: {
          ...search,
          product: action.product
        },
      }
    case "qrCode":
      return { ...state, qrcode: action.qrcode, url: action.url, name: action.name };
    case "agency":
      return { ...state, agency: action.agency };
    case "sort":
      return {
        ...state,
        search: {
          ...search,
          sort: action.sort,
        },
      };
    case "clicked":
      return { ...state, clicked: action.clicked };
    case "setClickNumber":
      return { ...state, campaign: state.campaign.map(campaign =>
        campaign._id !== action.id
        ? campaign
        : { ...campaign, clickNumber: action.clickNumber }
        ) }
    default:
      return { ...state };
  }
};

const CampaignList = ({ corporation, isReload, reloadCampaign, product, date }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [userData, setUserData] = useState({})
  const history = useHistory()

  async function updateUserData () {
    const userData =  await getUserData(history)
    setUserData(userData)
  }

  useEffect(() => {
    updateUserData()
    getCampaignLinks(state.search);
  }, []);

  useEffect(() => {
    getCampaignLinks(state.search);
  }, [ state.search ]);

  useEffect(() => {

    dispatch({ type: "product", product });
  }, [ product ])

  useEffect(() =>{
    dispatch({ type: "date", date });
  },[date])

  useEffect(() => {
    if (isReload) {
      reloadCampaign();
      getCampaignLinks();
    }
  }, [isReload]);

  const getCampaignLinks = useCallback(async (search, product = '') => {
    let params = objectToParams(search);
    try {
      const response = await mainApi.get(`/link/campaign${params}`);
      const data = response.data.data;
      dispatch({
        type: "campaignLinks",
        campaign: data.docs,
        page: data.page,
        totalDocs: data.totalDocs,
      });
    } catch (error) {}
  }, []);

  const downloadCsv = async () => {
    dispatch({ type: "loading", loading: true });
    const params = objectToParams(state.search);
    try {
      const response = await mainApi.get(`/link/campaign/csv${params}`);
      const data = response.data.data.campaign;
      createDownloadLink(data);
    } catch (error) {}
    dispatch({ type: "loading", loading: false });
  };

  const createDownloadLink = (csv) => {
    let file = "text/CSV;charset=utf-8,%EF%BB%BF" + encodeURI(csv);
    let filename = `campaign_${moment().format("YYYY-MM")}`;

    let a = document.createElement("a");

    a.href = "data:" + file;
    a.download = filename + ".csv";

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const findSpanElement = () => {
    const alreadyCreated = document.querySelector("#copy-text-element");
    if (alreadyCreated) alreadyCreated.remove();

    const span = document.createElement("span");
    span.setAttribute("id", "copy-text-element");
    document.body.appendChild(span);
    return span;
  };

  const showCopyText = (event, element, text = "Copiado !") => {
    element.style.display = "block";
    element.style.top = event.pageY + 15 + "px";
    element.style.left = event.pageX + 15 + "px";
    element.innerHTML = text;
  };

  const hideCopyText = () => {
    const element = findSpanElement();
    element.remove();
  };

  const getMonth = () => {
    moment.locale('pt-BR')
    return moment(state.month, "YYYY-MM").format("MMMM")
  }

  const copyShorten = (event, shorten) => {
    const element = findSpanElement();
    showCopyText(event, element);

    const interval = setInterval(() => {
      document.addEventListener("mousemove", (event) => {
        showCopyText(event, element);
      });
    }, 100);

    dispatch({ type: "clicked", clicked: true });
    setTimeout(() => {
      dispatch({ type: "clicked", clicked: false });
      hideCopyText();
      clearInterval(interval);
    }, 2500);

    var textField = document.createElement("textarea");
    textField.innerText = shorten;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
  };

  const onSortChange = useCallback((value, type) => {
    const hasPlus = "descending" === type ? "-" : "";
    dispatch({ type: "sort", sort: hasPlus + value });
  }, []);

  const showClick = async (id) => {
    dispatch({
      type: "setClickNumber",
      id: id,
      clickNumber: 'loading'
    })

    try {
      const response = await  mainApi.get(`/links/${id}/click`)
      dispatch({
        type: "setClickNumber",
        id: id,
        clickNumber: response.data.data.clicks
      })

    } catch (e) {
      console.log(e)
      dispatch({
        type: "setClickNumber",
        id: id,
        clickNumber: undefined
      })
    }
  }

  const option = MuiDataTableOption.campaignLink({
    pagination: state.pagination,
    selectedMonth: state.month,
    changePage: (page) => dispatch({ type: "changePage", page }),
    search: (campaignName) => {
      if (corporation)
        return dispatch({ type: "search", search: campaignName });
      return dispatch({ type: "search", campaignName });
    },
    onSortChange: onSortChange,
    onMonthSelect: (month) => dispatch({ type: "month", month }),
    downloadCsv,
    corporation,
  });

  const columns = tableFunctions.columns({
    copyShorten,
    showQrCode: (url, name) => dispatch({ type: "qrCode", qrcode: true, url, name }),
    corporation,
    showClick,
    strictId: userData?.corpId
  });

  return (
    <>
      <LoadingAlert show={state.showLoading} />

      <QrCode
        show={state.qrcode}
        url={state.url}
        name={state.name}
        onClose={() => dispatch({ type: "qrCode", qrcode: false, url: "" })}
      />

      <MuiThemeProvider theme={getMuiTheme}>
        <MUIDataTable
          data={state.campaign}
          columns={columns}
          options={option}
        />
      </MuiThemeProvider>

      <div className="pt-4 text-center">
        {/* <Totals product={product} date={state.month}/> */}
      </div>
    </>
  );
};

export default CampaignList;
