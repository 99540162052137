import styled from 'styled-components'

export const Table = styled.table`
  width: 100px;
  height: 100px;

  thead {
    border-bottom: 1px #bababa solid;
  }

  tbody {
    border-bottom: 1px #bababa solid;
  }

  td, tr {
    padding: 10px 5px;
  }

  th {
    padding: 0px 20px;
  }
`