import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import getUserData from "utils/checkUser";
import { Dialog } from "components/Alert/CustomAlert";
import mainApi from "services/mainApi";
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider } from "@material-ui/core/styles";
import LoadingGif from "components/loadingGif";
import {
  CircularProgress,
  IconButton,
  TextField,
  Zoom,
  ButtonGroup,
  Button,
  Tooltip,
} from "@material-ui/core";
import { getMonthById, getDatesList } from "utils/formatData";
import { translateSituationStatus } from "utils/translate";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import getMuiTheme from "utils/getMuiTheme";
import { MuiTableConfig, SITUATIONS, PRODUCTS } from "utils/CommonData";

import { formatToBackEnd } from 'utils/formaterDate'

import MuiSelect from 'components/Selects/MuiSelect'
import getTableConfig from "utils/getTableConfig";
import ActionSearch from "@material-ui/icons/Search";
import ActionCancel from "@material-ui/icons/Cancel";
import moment from 'moment';
import DatePicker from "react-datepicker";

const DateButton =  ({ value, onClick, placeholder }) => {
   console.log(value)
  return(
  <div className='datepicker-campaign'>
    <button className="datepicker-button" onClick={onClick}>
      {value ? value : placeholder}
    </button>
  </div>
)}

class GeneratedLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentData: [],
      rowsData: [],
      availableDates: [],
      userData: {},
      pagination: {},
      loading: false,
      swalMessageShow: false,
      swalMessage: "",
      limit: 25,
      search: "",
      selectedSituation: { label: "Todos os Status", value: "all" },
      selectedProduct: { label: "Todos os Produtos", value: "all" },
      showSearchBar: false,
      activeSearch: undefined,
      products: [],

      startDate: moment().subtract(1, 'month').toDate(),
      endDate: new Date()
    };
  }

  showMessage = (type, title, message, bold) => {
    this.setState({
      swalMessageShow: true,
      swalMessage: [type, title, message, bold],
      loading: false,
    });
  };

  dateWrittenInFull = (date) => {
    date = new Date(date);
    let monthNames = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ];
    return (
      date.getDate() +
      " de " +
      monthNames[date.getMonth()] +
      " de " +
      date.getFullYear()
    );
  };

  getGeneratedLinksData = async (origin, options) => {

    let key;

    const {
      selectedSituation: situation,
      selectedProduct: product,
      activeSearch,
      search,
      startDate,
      endDate,
    } = this.state;

    let { month, limit, exportData } = options || {};

    if (!month) month = this.state.selectedDate.value;

    limit = 9999;

    if (origin === "component") key = "loadingTable";
    else key = "loading";
    this.setState({ [key]: true });

    const exportQuery = exportData ? `&export=1` : "";

    const situationQuery =
      situation && situation.value !== "all"
        ? `&situation=${situation.value}`
        : "";

    const productQuery =
      product && product.value !== "all" ? `&product=${product.value}` : "";

    const searchQuery =
      activeSearch && search && search.length > 0
        ? `&${activeSearch}=${search}`
        : "";

    let dateQuery = ``

    if(startDate && endDate) {
      dateQuery = `&start=${formatToBackEnd(startDate)}&end=${formatToBackEnd(endDate)}`
    }

    try {
      const response = await mainApi.get(
        `/data?limit=${limit}${situationQuery}${productQuery}${exportQuery}${searchQuery}${dateQuery}`
      );

      if (exportData) {
        this.saveCSV(response.data.csv, {
          month,
          situation: situation.label,
        });
        this.setState({ [key]: false });
        return;
      }else{
        this.setState({pagination: {...response.data.datas, docs: undefined}})
      }
      let paymentData = response.data.datas.docs;
      paymentData.forEach((item) => {
        item.situation = translateSituationStatus(item.situation);
      });
      this.setState({
        paymentData,
      });
      const dates = getDatesList(response.data.startDate);

      this.setState({ availableDates: dates });
    } catch (err) {
      console.log(err);
    }
    this.setState({ [key]: false });
  };

  saveCSV = (csv, data) => {
    let file = "text/CSV;charset=utf-8,%EF%BB%BF" + encodeURI(csv);
    const filename = `${data.month}-${data.situation}.csv`;
    let a = document.createElement("a");
    a.href = "data:" + file;
    a.download = filename + ".csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  getMonthByIndex = (index) => {
    const months = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ];

    return months[index - 1];
  };

  componentDidMount = async () => {
    const userData = await getUserData(this.props.history);
    if (userData) {
      let date = new Date();
      let month = {
        label: `${getMonthById(date.getMonth())} de ${date.getFullYear()}`,
        value: `${date.getMonth() + 1}-${date.getFullYear()}`,
      };
      this.setState({ selectedDate: month, level: userData.level, userData });
      this.getGeneratedLinksData("component", {
        limit: 25,
        month: month.value,
      });
    }
  };

  onDateSelect = (date) => {
    if (date.value === this.state.selectedDate.value) return;
    this.setState({ selectedDate: date });
    this.getGeneratedLinksData("table", { limit: 25, month: date.value });
  };

  onSituationChange = (selectedSituation) => {
    this.setState({ selectedSituation }, () =>
      this.getGeneratedLinksData("table")
    );
  };
  onProductChange = (selectedProduct) => {
    this.setState({ selectedProduct }, () =>
      this.getGeneratedLinksData("table")
    );
  };
  render() {
    const {
      paymentData,
      loading,
      loadingTable,
      swalMessageShow,
      swalMessage,
      pagination,
      selectedSituation,
      showSearchBar,
      selectedProduct,
      search,
      startDate,
      endDate,
      userData
    } = this.state;

    const affiliateTableHeader = getTableConfig({level: "meliuz", strictId: userData?.corpId});
    const tableOptions = {
      ...MuiTableConfig,
      serverside: false,
      onChangeRowsPerPage: (newLimit) => {
        this.setState({ limit: newLimit }, () => {
          this.getGeneratedLinksData("table", {
            limit: newLimit,
            month: this.state.selectedDate.value,
          });
        });
      },
      // onPageChange: (newPage) =>  {
      //   const { totalDocs } = this.state.pagination
      //   if (newPage >= 0 && newPage < totalPages) {
      //     this.setState({ page: newPage + 1 }, () => {
      //       this.getGeneratedLinksData('table', selectedDate.value, newPage)
      //     })
      //   }
      // },
      customToolbar: () => {
        return (
          <>
            <Zoom in={showSearchBar}>
              <div className="search-container">
                <TextField
                  value={search}
                  onChange={(e) => this.setState({ search: e.target.value })}
                  label="Buscar"
                  variant="outlined"
                />{" "}

                <ButtonGroup
                  disableElevation
                  color="primary"
                  variant="contained"
                  aria-label="contained primary button group"
                  size="large"
                >
                  <Tooltip
                    placement="top"
                    title="Buscar Registros por ID"
                    aria-label="search-number"
                  >
                    <Button
                      onClick={() => {
                        this.setState({ activeSearch: "number" }, () =>
                          this.getGeneratedLinksData("table")
                        );
                      }}
                    >
                      ID
                    </Button>
                  </Tooltip>
                  <Tooltip
                    placement="top"
                    title="Buscar Registros por Código do Cliente"
                    aria-label="search-code"
                  >
                    <Button
                      onClick={() => {
                        this.setState({ activeSearch: "code" }, () =>
                          this.getGeneratedLinksData("table")
                        );
                      }}
                    >
                      Código
                    </Button>
                  </Tooltip>
                  <Tooltip
                    placement="top"
                    title="Buscar Registros por Nome do Cliente"
                    aria-label="search-name"
                  >
                    <Button
                      onClick={() => {
                        this.setState({ activeSearch: "name" }, () =>
                          this.getGeneratedLinksData("table")
                        );
                      }}
                    >
                      Nome
                    </Button>
                  </Tooltip>
                </ButtonGroup>
              </div>
            </Zoom>
            <Tooltip
              placement="top"
              title={showSearchBar ? "Limpar Busca" : "Buscar Registros"}
              aria-label="search"
            >
              <IconButton
                style={{ height: "38px", padding: "9px" }}
                onClick={() =>
                  this.setState(
                    {
                      showSearchBar: !this.state.showSearchBar,
                      activeSearch: undefined,
                      search: "",
                    },
                    () => {
                      if (!this.state.showSearchBar)
                        this.getGeneratedLinksData("table");
                    }
                  )
                }
              >
                {showSearchBar ? <ActionCancel /> : <ActionSearch />}
              </IconButton>
            </Tooltip>
            <Tooltip
              placement="top"
              title="Download CSV"
              aria-label="download-csv"
            >
              <IconButton
                style={{ height: "38px", padding: "9px" }}
                onClick={() => {
                  this.getGeneratedLinksData("table", { exportData: true });
                }}
              >
                <CloudDownloadIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
      filter: false,
      print: false,
      viewColumns: false,
      download: false,
      search: false,
    };

    return (
      <div className="content">
        <Dialog
          type={swalMessage[0]}
          show={swalMessageShow}
          title={swalMessage[1] || ""}
          onConfirm={() => this.setState({ swalMessageShow: false })}
          text={swalMessage[2]}
        />

        <div className="container-fluid">
          <Row>
            <Col md={3}>
              <MuiSelect
                label='Filtrar por status: '
                value={selectedSituation}
                options={[
                  { label: "Todos os Status", value: "all" },
                  ...SITUATIONS,
                ]}
                onChange={this.onSituationChange}
              />
            </Col>

            <Col md={3}>
              <MuiSelect
                label='Filtrar por produto: '
                value={selectedProduct}
                options={[
                  { label: "Todos os Produtos", value: "all" },
                  ...PRODUCTS,
                ]}
                onChange={this.onProductChange}
              />
            </Col>

            <div>
              <Col md={3}>
                <p>Filtrar por data: </p>
                <DatePicker
                  className="datepicker-theThird pl-3 h-51-px"
                  dateFormat='dd/MM/yyyy'
                  placeholderText="Inicial"
                  locale='pt-BR'
                  showYearDropdown
                  showMonthDropdown
                  isClearable
                  selected={startDate}
                  onChange={(date) => this.setState({ startDate: date }, () => this.getGeneratedLinksData()) }
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  maxDate={new Date()}
                />
              </Col>

              <Col md={3} className="py-md-3" style={{ paddingTop: 34 }}>
                <DatePicker
                  className="datepicker-theThird pl-3 h-51-px"
                  dateFormat='dd/MM/yyyy'
                  placeholderText="Final"
                  locale='pt-BR'
                  showYearDropdown
                  showMonthDropdown
                  selected={endDate}
                  onChange={(date) => this.setState({ endDate: date }, () => this.getGeneratedLinksData()) }
                  selectsEnd
                  isClearable
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  maxDate={new Date()}
                />
              </Col>
            </div>
          </Row>

          <Row>
            <div className="tabelavendascontainer">
              <Col md={12} className="table">
                <div className="card">
                  {loadingTable ? (
                    <LoadingGif />
                  ) : (
                    <div>
                      <MuiThemeProvider theme={getMuiTheme}>
                        <MUIDataTable
                          columns={affiliateTableHeader}
                          data={paymentData}
                          options={tableOptions}
                          title={
                            <h4 variant="title">
                              Vendas Méliuz {this.state.tableTitle}
                              {loading && (
                                <CircularProgress
                                  size={24}
                                  style={{
                                    marginLeft: 15,
                                    position: "relative",
                                    top: 4,
                                  }}
                                />
                              )}
                            </h4>
                          }
                        />
                      </MuiThemeProvider>
                    </div>
                  )}
                </div>
              </Col>
            </div>
          </Row>
        </div>
      </div>
    );
  }
}

export default GeneratedLinks;
