import axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import { Dialog } from "components/Alert/CustomAlert";
import { servers } from 'utils/config'

const appEnv = process.env.REACT_APP_ENV
let baseUrl = servers[appEnv] || servers['local'];

const mainApi = axios.create({
  baseURL: baseUrl + "/server",
  timeout: 40000
});

mainApi.defaults.headers.post["Content-Type"] = "application/json";

const getToken = () => {
  const token = localStorage.getItem('@uxtech-mlm/UserToken')
  if (token && token !== null) return token
  return undefined
}

const getSupportData = () => {
  const token = localStorage.getItem('@uxtech-mlm/supportToken')
  const id = localStorage.getItem('@uxtech-mlm/supportId')

  return (token && id)
    ? {
      token,
      id
    }
    : undefined
}

mainApi.interceptors.request.use(config => {

  const token = getToken()
  const supportData = getSupportData()
  if (
    (!config.url.endsWith('login') ||
      !config.url.endsWith('refresh') ||
      !config.url.endsWith('signup')) && token
  ) {
    config.headers.Authorization = token
  }

  if (supportData) {
    config.headers['supportToken'] = supportData.token
    config.headers['supportId'] = supportData.id
  }

  return config
}, error => Promise.reject(error))

mainApi.interceptors.response.use(
  response => response,
  error => {
    const currentPath = document.location.pathname.split('/')[1]
    if (error.response && Number(error.response.status) === 401 && currentPath === 'admin') {
      ReactDOM.render(
        <Dialog
          show
          type="info"
          title="Sua sessão expirou!"
          text="Faça o login novamente."
          onConfirm={() => {
            localStorage.clear();
            document.location.href = "/";
          }}
        />,
        document.getElementById("exit-swal")
      );
    }
    return Promise.reject(error);
  }
);

export default mainApi;
