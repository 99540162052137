import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import TermsClubeAmigo from './TermsClubeAmigo'

function TermsModal({styleLink, classNameLink, ...rest }) {
  const [show, setShow] = useState(false)

  return (
    <>
      <Modal
        show={show}
        dialogClassName="modal-90w"
        aria-labelledby="agency-data"
        bsSize="large"

        onHide={() => {
          setShow(false)
        }}
        {...rest}
      >
        <Modal.Header closeButton>
          <Modal.Title id="agency-data-title">
            Regulamento
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ marginBottom: 0 }}>
          <div style={{ maxHeight: '50vh', overflow: "auto", marginBottom: 21 }}>
            <TermsClubeAmigo/>
          </div>
        </Modal.Body>
      </Modal>
      <a className={classNameLink} style={styleLink} onClick={() => setShow(true)}>Consulte aqui o regulamento</a>
    </>
  )
}

export default TermsModal
