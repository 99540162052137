import React, { Component } from 'react';

import DisclosureModal from 'components/DisclosureModal'
import Card from "components/Card/Card";
import LoadingGif from "components/loadingGif";
import { Col, Row } from "react-bootstrap";
import mainApi from "services/mainApi";
import getUserData from "utils/checkUser";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Preview } from "../components/Alert/CustomAlert";
import { formatSize } from "utils/numberMethods"
import { FormHelperText, Tooltip } from '@material-ui/core';
import UploadButton from 'components/UploadButton'
import { formatToBr } from 'utils/formaterDate';
import TableMui from 'components/TableMui'
import MuiSelect from 'components/Selects/MuiSelect'
import swal from 'components/Alert/alert'
import formater from 'utils/formater'

import { introSystem } from 'utils/introToSystem';
import SweetAlert from "sweetalert2";
import DisclosureMaterialCard from 'components/DisclosureMaterialCard/DisclosureMaterialCard.jsx';
import { translateLevel } from 'utils/translate';

export default class DisclosureMaterial extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isDesktop: false,
      filePreview: '',
      rows: [],
      preview: null,
      level: '',
      add: false,
      page: 1,
      totalPages: 1,
      limit: 10,
      userData: {},
      totalDocs: 1,
      name: '',
      loading: false,
      sort: '',
      SelectProducts: undefined,
      selectedProduct: undefined,
      selectedMaterial: undefined,
      materialList: []
    }

    this.updatePredicate = this.updatePredicate.bind(this);
  }

  showProducts = (material) => {
    const products = material.productInfo.map(product => product.name).join(', ')
    swal.text("Produtos", products)
  }

  downloadMaterial = async (content) => {
    let a = document.createElement('a');
    a.href = content.url
    a.download = `${content.name}.${content.format}`
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  editMaterial = async (content) => {
    if (content.edit) {
      this.setState({ selectedMaterial: content }, () => this.handleOpen())
      return
    }

    return (
      <Tooltip title="Sem permissão para editar">
        <EditOutlinedIcon color='disabled' style={{ cursor: 'pointer' }} />
      </Tooltip>
    )
  }

  deleteMaterial = async (id) => {

    SweetAlert.fire({
      title: 'Atenção!',
      text: 'Tem certeza que deseja remover esse Mateial?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar',
      customClass: {
        container: 'disclosure-alert'
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          this.setState({ loading: true })
          await mainApi.delete(`/material/${id}`)
          this.getMaterial()

        } catch (err) {
          console.log(err)
        }
        this.setState({ loading: false })
      }
    })

    this.setState({ loading: false })

    // await mainApi.delete(`/material/${id}`)

    // this.getMaterial()
  }

  setUserData = async () => {
    const userData = await getUserData(this.props.history);

    this.setState({ userData })
  }

  getMaterial = async () => {
    const { page, limit, userData, sort, selectedProduct } = this.state

    this.setState({ level: userData.level, loading: true })

    const hasProducts = selectedProduct ? `&product=${selectedProduct}` : ""

    const response = await mainApi.get(
      `/material?page=${page}&limit=${limit}${sort}${hasProducts}`)


    // console.log("Matrtiais",response.data);


    const auxRows = []

    response.data.data.materials.docs.forEach(material => {
      let productsNames = material.productInfo.map(prod => prod.name)

      auxRows.push([
        { id: material._id, edit: material.edit },
        { id: material._id, edit: material.edit, material: material },
        { url: material.url, format: material.format },
        material.name,
        String(material.format).toUpperCase(),
        formatSize(material.size),
        material.createdAt,
        productsNames,
        material.uploaderName,
        material.uploaderLevel,
        { url: material.url, format: material.format }
      ])
    })

    this.setState({
      rows: auxRows,
      totalPages: response.data.data.materials.totalPages,
      page: response.data.data.materials.page,
      totalDocs: response.data.data.materials.totalDocs,
      loading: false,
      materialList: response.data.data.materials.docs
    })
  }

  getProducts = async () => {
    const { userData } = this.state;

    let endpoint = "";

    if (["master", "submaster"].includes(userData.level))
      endpoint = "/master/products";
    else endpoint = "/products";

    try {
      const response = await mainApi.get(endpoint);
      const products = response.data.products;

      const productsToSelect = [{ value: "", label: "Todos os produtos" }];

      products.forEach((product) => {
        productsToSelect.push({
          value: product._id,
          label: product.name,
        });
      });


      this.setState({ SelectProducts: productsToSelect });
    } catch (err) {
      if (err.response) console.log(err.response.data);
    }
  };



  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 1000 });
  }

  handlePageChange(newPage) {
    const { totalPages } = this.state
    if (newPage >= 0 && newPage < totalPages) {
      this.setState({ page: newPage + 1 }, () => {
        this.getMaterial()
      })
    }
  }

  handleLimitChange(newLimit) {
    this.setState({ limit: newLimit }, () => {
      this.handlePageChange(0)
    })
  }

  handleOpen = () => { this.setState({ add: true }) }

  handleClose = () => {
    this.setState({ add: false, loading: true, selectedMaterial: undefined })
    this.getMaterial()
  }

  handleChange = (product) => {
    this.setState({
      selectedProduct: product.value
    }, () => {
      this.getMaterial()
    })
  }

  handleMaterialProducts = (e) => {
    e.preventDefault()
    console.log(e.target);
  }


  componentDidMount = async () => {
    this.setState({ loading: true })
    await this.setUserData()
    this.getMaterial()
    this.getProducts()

    this.updatePredicate()
    window.addEventListener("resize", this.updatePredicate);

    const { routeKey } = this.props;
    const { userData } = this.state

    window.digitalData = {
      "site": {
        "nome": "Banco Pan"
      },
      "page": {
        "secao": "IndiqueoPan",
        "estadoLogin": "LG",
        "nome": "BP:IndiqueOPan:Divulgacao",
      },
      "user": {
        "crmid": userData.id,
        "tipo": userData.brType
      }
    }

    var evt = new CustomEvent('event-view-start');
    document.body.dispatchEvent(evt);

    introSystem(routeKey)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  render() {
    const { rows, loading, isDesktop, preview, level, page, totalDocs, add, selectedMaterial, materialList } = this.state
    const columns = [
      {
        name: 'delete',
        label: 'Deletar',
        options: {
          sort: false,
          filter: false,
          display: true,
          customBodyRender: (value) => {
            if (value.edit) return <DeleteOutlineOutlinedIcon color='error' style={{ cursor: 'pointer', transform: "scale(2.0)", margin: '0 auto', display: 'block' }} onClick={() => this.deleteMaterial(value.id)} />
            return (
              <Tooltip title="Sem permissão para remover">
                <DeleteOutlineOutlinedIcon color='disabled' style={{ margin: '0 auto', display: 'block' }} />
              </Tooltip>
            )
          }
        }
      },
      {
        name: 'edit',
        label: 'Editar',
        options: {
          sort: false,
          filter: false,
          display: true,
          customBodyRender: (value) => {
            if (value.edit) return <EditOutlinedIcon style={{ cursor: 'pointer', transform: "scale(2.0)", margin: '0 auto', display: 'block' }} onClick={() => { this.setState({ selectedMaterial: value.material }, () => this.handleOpen()) }} />
            return (
              <Tooltip title="Sem permissão para editar">
                <EditOutlinedIcon color='disabled' style={{ margin: '0 auto', display: 'block' }} />
              </Tooltip>
            )
          }
        }
      },
      {
        name: 'preview',
        label: 'Preview',
        options: {
          sort: false,
          filter: false,
          customBodyRender: (value) => {
            if (value.format !== 'pdf') {
              return <VisibilityOutlinedIcon
                style={{ cursor: 'pointer', transform: "scale(2.0)", justifyContent: "center", margin: '0 auto', display: 'block' }}
                onClick={() => {
                  this.setState({ preview: value.url })
                }} />
            }
            return
          }
        }
      },
      {
        name: 'name',
        label: 'Nome',
        options: {
          sort: true,
          filter: false
        }
      },
      {
        name: 'format',
        label: 'Tipo'
      },
      {
        name: 'size',
        label: 'Tamanho',
        options: {
          filter: false,
        }
      },
      {
        name: 'createdAt',
        label: 'Dt. de criação',
        options: {
          sort: true,
          filter: false,
          customBodyRender: (value) => formatToBr(value)
        }
      },
      {
        name: 'productsId',
        label: 'Produtos',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value) => {
            if (value.length > 1) return <button className="link-style" onClick={() => swal.text("Produtos", formater.separeteValuesByComma(value))}>Ver Mais</button>
            else if (value.length > 0) return value
            return "Todos"
          }
        }
      },
      {
        name: 'uploaderName',
        label: 'Autor',
        options: {
          sort: true,
          filter: false,
        }
      },
      {
        name: 'uploaderLevel',
        label: 'Nível Autor',
        options: {
          sort: true,
          filter: false,
          customBodyRender: (value) => {
            return translateLevel(value)
          }
        }
      },
      {
        name: 'download',
        label: 'Baixar',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value) => {
            return <button
              className="btn btn-fill btn-mlm btn-mlm-sm btn-small-extract"
              onClick={() => this.downloadMaterial(value)}
            >
              Baixar
            </button>
          }
        }
      }
    ]
    const options = {
      download: false,
      print: false,
      sort: true,
      filter: false,
      search: false,
      selectableRows: 'none',
      elevation: 0,
      page: page - 1,
      count: totalDocs,
      rowsPerPageOptions: [10, 25, 50],
      serverSide: true,
      textLabels: {
        body: {
          noMatch: "Desculpe, nenhum dado corresponde a pesquisa",
        },
        pagination: {
          next: "Próxima Página",
          previous: "Pagina Anterior",
          rowsPerPage: "Linhas por página:",
          displayRows: "de",
        },
        toolbar: {
          search: "Procurar",
          viewColumns: "Mostrar Colunas",
          filterTable: "Filtrar Tabela",
        },
        filter: {
          title: "FILTROS",
          reset: "limpar",
          all: "Todos",
        },
        viewColumns: {
          title: "Mostrar Colunas",
        },
      },
      customToolbar: () => (
        // ['corporation', 'master', 'administrator', 'subcorp', 'submaster'].includes(level) ?
        ['corporation', 'master', 'submaster', 'subcorp'].includes(level) ?
          <UploadButton onClick={() => this.handleOpen()}>Adicionar</UploadButton>
          : null
      ),
      onColumnSortChange: (value, type) => {
        const hasPlus = 'descending' === type ? '-' : ''
        this.setState({ sort: `&sort=${hasPlus}${value}` }, () => {
          this.getMaterial()
        })
      },
      onTableChange: (action, tableState) => {
        switch (action) {
          case 'changePage':
            this.handlePageChange(tableState.page)
            break;
          case 'changeRowsPerPage':
            this.handleLimitChange(tableState.rowsPerPage)
            break;
          // case 'search':
          //   if(tableState.searchText?.length > 3)
          //     this.handleSearch(tableState.searchText)
          //   break;
          default:
            break;
        }
      }
    }

    return (
      <div className="content">
        <div className="container-fluid">
          <DisclosureModal open={add} onClose={() => this.handleClose()} selectOptions={this.state.SelectProducts} editMaterial={selectedMaterial} />
          <Row>
            <Col lg={4} md={4} sm={12}>
              <FormHelperText>Filtrar por produto</FormHelperText>
              <MuiSelect
                options={this.state.SelectProducts}
                onChange={this.handleChange}
                placeholder={"Filtrar por produto"}
                defaultValue={{ label: 'Todos os produtos', value: '' }}
              />
            </Col>
          </Row>
          {isDesktop ?
            <Row>
              <Preview
                url={preview}
                show={preview ? true : false}
                onConfirm={() => this.setState({ preview: null })}
              />

              <div className="tabelavendascontainer">
                <Col md={12} className="table">
                  <div className="card">
                    {loading ? <LoadingGif /> :
                      <TableMui
                        title={
                          <>
                            <h4 className="title">Materiais para divulgação</h4>
                            Aqui você encontra todos os tipos de materiais que você pode usar para divulgar este produto.
                          </>
                        }
                        data={rows}
                        columns={columns}
                        options={options}
                      />
                    }
                  </div>
                </Col>
              </div>
            </Row>
            :
            <Row>
              <Card
                title="Materiais de divulgação"
                category="Aqui você encontra todos os tipos de materiais que você pode usar para divulgar este produto."
                content={
                  <>
                    {loading ? <LoadingGif /> : <></>}
                    <div className="actions-line">
                      {/* {(['corporation', 'master', 'submaster', 'subcorp'].includes(level)) ? */}
                      {(['corporation', 'master', 'submaster', 'subcorp'].includes(level)) ?
                        <UploadButton onClick={() => this.handleOpen()}>Adicionar</UploadButton>
                        : <></>
                      }
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '2em' }}>
                      {
                        materialList.map(material =>
                          <DisclosureMaterialCard
                            material={material}
                            showProducts={this.showProducts}
                            editMaterial={this.editMaterial}
                            downloadMaterial={this.downloadMaterial}
                            deleteMaterial={this.deleteMaterial}
                          />
                        )
                      }
                    </div>
                    <div style={{ display: 'grid', justifyContent: 'center' }}>
                    </div>

                  </>
                }
              />
            </Row>
          }
        </div>
      </div>
    )
  }

}