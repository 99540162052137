import React from "react";
import { numToBRL, firstAndLastWord, getMinimizedCPF, shortenValue } from "./formatData";
import { translateLevel, translateProduct } from "./translate";
import { formatToBr } from "utils/formaterDate";
import formater from "utils/formater";
import format from 'utils/formater'
import { ClubeAmigoId, CVCId } from "./CommonData";
import { normalize } from "./normalizeString";
import BasicPopover from '../components/BasicPopover/BasicPopover'
import ProposalStatusBadge from "components/ProposalStatusBadge/ProprosalStatusBadge";
import ClaimVoucher from "views/ProposalStatus/ClaimVoucher/ClaimVoucher";
import { ISClubeAmigo } from "utils/environment";
import { maquininhaId } from "./environment";

// eslint-disable-next-line
Array.prototype.insert = function (index, item) {
  this.splice(index, 0, item);
};

const id = {
  name: "number",
  label: "ID",
  options: {
    setCellProps: () => ({ className: 'no-wrap' }),
    filter: false,
    sort: true,
    hint: "ID da Venda",
  },
};

const phone = {
  name: "phone",
  label: "Telefone",
  options: {
    setCellProps: () => ({ className: 'no-wrap' }),
    sort: false,
    filter: false,
    display: !ISClubeAmigo,
    customBodyRender: (value) => {
      const formatedValue = formater.formatPhone(value)
      const shortenValue = !formatedValue ? '' : '(**) ****-' + formatedValue.slice(-4)

      return shortenValue ?
        <BasicPopover
          noWrap={true}
          text={formatedValue}
          buttonText={shortenValue}
        />
        : formatedValue ? formatedValue : 'n/a'
    },
    hint: "Telefone do cliente",
  },
};

// const userId = localStorage.getItem("@uxtech-mlm/UserId");
const status = {
  name: "situation",
  label: "Status",
  options: {
    setCellProps: () => ({ className: 'no-wrap' }),
    filter: false,
    sort: false,
    customBodyRender: (value, row) => <ProposalStatusBadge value={value} row={row} /> ,
    hint: "Situação atual da venda"
  },
};

// const proposalDate = {
//   name: "proposalDate",
//   label: "Dt. Proposta",
//   options: {
//     filter: false,
//     sort: true,
//     customBodyRender: (value) => (value ? formatToBr(value) : "n/a"),
//     hint: "Data da criação da proposta",
//   },
// };

const agencyName = {
  name: 'agencyName',
  label: 'Loja',
  options: {
    setCellProps: () => ({ className: 'no-wrap' }),
    filter: false,
    sort: true,
    hint: "Nome da loja",
    customBodyRender: (value) => {
      const formatedValue = !value || String(value).includes('-') ? 'n/a' : normalize(value)
      const shortenName = shortenValue(formatedValue)

      return shortenName ?
        <BasicPopover
          noWrap={true}
          text={formatedValue}
          buttonText={shortenName}
        />
        : formatedValue
    }
  }
}

const clientName = {
  name: "name",
  label: "Cliente",
  options: {
    setCellProps: () => ({ className: 'no-wrap' }),
    filter: false,
    sort: false,
    hint: "Nome do cliente",
    customBodyRender: (value) => {
      const formatedValue = !value || String(value).includes('-') ? 'n/a' : normalize(value)
      const shortenName = shortenValue(formatedValue)

      return shortenName ?
        <BasicPopover
          noWrap={true}
          text={formatedValue}
          buttonText={shortenName}
        />
        : formatedValue
    }
  },
};

const clientCPF = {
  name: "cpf",
  label: "CPF Cliente",
  options: {
    setCellProps: () => ({ className: 'no-wrap' }),
    filter: false,
    sort: false,
    hint: "CPF do cliente",
    display: !ISClubeAmigo,
    customBodyRender: (value) =>
      value ? (
        <BasicPopover
          noWrap={true}
          text={format.toCPF(value)}
          buttonText={getMinimizedCPF(value)}
        />
      ) : 'n/a'
  },
};

const clientEmail = (prop) => {
  const { productId } = prop || {}

  return ({
    name: "email",
    label: "Email Cliente",
    options: {
      setCellProps: () => ({ className: 'no-wrap' }),
      filter: false,
      sort: false,
      hint: "Email do cliente",
      display: ISClubeAmigo || productId === maquininhaId ? false : true,
      customBodyRender: (value) =>
        value ? (
          <BasicPopover
            noWrap={true}
            text={value}
            buttonText="Ver Email"
          />
        ) : (
          "n/a"
        ),
    },
  })
}

const numProposta = (prop) => {
  const { productId } = prop || {}

  return {
    name: "nuproposta",
    label: "Número Proposta",
    options: {
      display: productId === maquininhaId,
      setCellProps: () => ({ className: 'no-wrap' }),
      filter: false,
      sort: true,
      hint: "Número da proposta",
      customBodyRender: (value, tableInfo) => {
        return value || 'n/a'
      }
    }

  }
}

const product = {
  name: "product",
  label: "Produto",
  options: {
    setCellProps: () => ({ className: 'no-wrap' }),
    filter: true,
    sort: true,
    customBodyRender: (value) => {
      return translateProduct(value);
    },
    hint: "Nome do produto",
  },
};

const paymentDate = {
  name: "paymentDate",
  label: "Dt. Aprovação",
  options: {
    setCellProps: () => ({ className: 'no-wrap' }),
    filter: false,
    sort: true,
    customBodyRender: (value) => (value ? formatToBr(value) : "n/a"),
    hint: "Data da Aprovação",
  },
};

const accountCreated = {
  name: "paymentDate",
  label: "Abertura de conta",
  options: {
    setCellProps: () => ({ className: 'no-wrap' }),
    filter: false,
    sort: true,
    customBodyRender: (value) => (value ? formatToBr(value) : "n/a"),
    hint: "Data da aberura de conta",
  },
};

const linkMessage = {
  name: 'link.message',
  options: {
    display: 'excluded'
  }
}

const linkStatus = {
  name: 'link.sent',
  options: {
    display: 'excluded'
  }
}

const linkDate = {
  name: 'link.sentAt',
  options: {
    display: 'excluded'
  }
}

const linkUrl = {
  name: 'link.url',
  options: {
    display: 'excluded'
  }
}

const createdAt = {
  name: "createdAt",
  label: "Inserção",
  options: {
    setCellProps: () => ({ className: 'no-wrap' }),
    filter: false,
    sort: true,
    hint: "Data da Inserção",
  },
};



const sellerTableHeader = ({ hasManager, strictId, refreshTable, productId }) => {
  const corpId = localStorage.getItem("@uxtech-mlm/CorpId");
  const isClubeAmigoUser = corpId === ClubeAmigoId
  const isCVC = corpId == CVCId

  const voucherClaim = {
    name: 'voucherStatus',
    label: 'Voucher',
    options: {
      setCellProps: () => ({ className: 'no-wrap' }),
      filter: false,
      sort: false,
      hint: 'Voucher da proposta',
      customBodyRender: (value, tableInfo) => {
        const { rowData, columnIndex } = tableInfo;
        const id = rowData[0]
        return <ClaimVoucher id={id} voucherStatus={value} refreshTable={refreshTable}  />
      }
    },
  }

  const columns = [
    id,
    status,
    product,
    clientName,
    agencyName,
    clientCPF,
    phone,
    clientEmail({productId}),
    numProposta({productId}),
    {
      name: "createdAt",
      label: "Inserção",
      options: {
        setCellProps: () => ({ className: 'no-wrap' }),
        sort: true,
        filter: false,
        display: isCVC ? 'excluded' : true,
        customBodyRender: (value) => (value ? formatToBr(value) : "n/a"),
        hint: "Data de inserção da venda do produto",
      },
    },
    paymentDate,
    {
      name: ISClubeAmigo ? "voucherValue" : "seller.sellerCommission",
      label: "Bonificação",
      options: {
        setCellProps: () => ({ className: 'no-wrap' }),
        filter: false,
        sort: true,
        display: (isCVC ? 'excluded' : true),
        customBodyRender: (value) => {

          return value === 0 ? "R$ 0,00" : numToBRL(value);
        },
        hint: "Valor final recebido pela venda",
      },
    },
  ];

  if (ISClubeAmigo) columns.push(voucherClaim)

  // os dados do link PRECISAM estar nas últimas 4 posições da row
  columns.push(linkMessage, linkStatus, linkDate, linkUrl)

  return columns;
};

const supervisorTableHeader = ({ hasManager, strictId, productId }) => {
  const corpId = localStorage.getItem("@uxtech-mlm/CorpId");

  const isCVC = corpId == CVCId
  const columns = [
    id,
    status,
    product,
    clientName,
    clientCPF,
    phone,
    clientEmail({productId}),
    numProposta({productId}),
    agencyName,
    {
      name: "seller.name",
      label: translateLevel('seller', strictId),
      options: {
        setCellProps: () => ({ className: 'no-wrap' }),
        filter: true,
        sort: true,
        display: isCVC ? 'excluded' : true,
        customBodyRender: (value) => {
          const formatedValue = firstAndLastWord(value)
          const shortenName = shortenValue(formatedValue)

          return shortenName ?
            <BasicPopover
              noWrap={true}
              text={formatedValue}
              buttonText={shortenName}
            />
            : formatedValue
        },
        hint: `Nome do ${translateLevel('seller', strictId)} que realizou a venda`,
      },
    },
    {
      name: "saleCommission",
      label: "Bonificação da venda",
      options: {
        setCellProps: () => ({ className: 'no-wrap' }),
        filter: false,
        sort: true,
        display: (isCVC ? 'excluded' : true) && !ISClubeAmigo,
        customBodyRender: (value) => {
          return numToBRL(value);
        },
        hint: "Bonificação total da venda",
      },
    },
    {
      name: ISClubeAmigo ? 'voucherValue' : "seller.sellerCommission",
      label: `Bonificação do ${translateLevel('seller', strictId)}`,
      options: {
        setCellProps: () => ({ className: 'no-wrap' }),
        filter: false,
        sort: true,
        display: isCVC ? 'excluded' : true,
        customBodyRender: (value) => {
          return numToBRL(value);
        },
        hint: `Bonificação destinado ao ${translateLevel('seller', strictId)}`,
      },
    },
    {
      name: "supervisorCommission",
      label: "Sua Bonificação",
      options: {
        setCellProps: () => ({ className: 'no-wrap' }),
        filter: false,
        sort: true,
        display: (isCVC ? 'excluded' : true) && !ISClubeAmigo,
        customBodyRender: (value) => {
          return numToBRL(value);
        },
        hint: "Bonificação destinada ao seu usuário",
      },
    },
    {
      name: "createdAt",
      label: "Inserção",
      options: {
        setCellProps: () => ({ className: 'no-wrap' }),
        sort: true,
        filter: false,
        customBodyRender: (value) => (value ? formatToBr(value) : "n/a"),
        hint: "Data de inserção da venda do produto",
      },
    },
    paymentDate,
    linkMessage, // os dados do link PRECISAM estar nas últimas 4 posições da row
    linkStatus,
    linkDate,
    linkUrl,
  ];

  return columns;
};

const administratorTableHeader = ({ hasManager, strictId, productId }) => {
  const corpId = localStorage.getItem("@uxtech-mlm/CorpId");

  const isCVC = corpId == CVCId

  const columns = [
    id,
    status,
    product,
    clientName,
    clientCPF,
    phone,
    clientEmail({productId}),
    numProposta({productId}),
    {
      name: "createdAt",
      label: "Inserção",
      options: {
        setCellProps: () => ({ className: 'no-wrap' }),
        sort: true,
        filter: false,
        customBodyRender: (value) => (value ? formatToBr(value) : "n/a"),
        hint: "Data de inserção da venda do produto",
      },
    },
    {
      name: "agencyTotal",
      label: "Bonificação Total",
      options: {
        setCellProps: () => ({ className: 'no-wrap' }),
        filter: false,
        sort: true,
        display: (isCVC ? 'excluded' : true) && !ISClubeAmigo,
        customBodyRender: (value) => {
          return numToBRL(value);
        },
        hint: "Bonificação da loja",
      },
    },
    {
      name: "agencyCommission",
      label: "Bonif. Loja",
      options: {
        setCellProps: () => ({ className: 'no-wrap' }),
        filter: false,
        sort: true,
        display: (isCVC ? 'excluded' : true) && !ISClubeAmigo,
        customBodyRender: (value, tableInfo) => {
          const { rowData, columnIndex } = tableInfo;
          if (!value) return "n/a";
          return `${numToBRL(value)}`;
        },
        hint: "Valor destinado à loja",
      },
    },
    {
      name: "agencyPercentage",
      options: {
        setCellProps: () => ({ className: 'no-wrap' }),
        filter: false,
        sort: false,
        display: "excluded",
      },
    },
    {
      name: "sellersPercentage",
      options: {
        setCellProps: () => ({ className: 'no-wrap' }),
        filter: false,
        sort: false,
        display: "excluded",
      },
    },
    {
      name: "supervisorName",
      label: translateLevel('supervisor', strictId),
      options: {
        setCellProps: () => ({ className: 'no-wrap' }),
        filter: true,
        sort: true,
        display: isCVC ? 'excluded' : true,
        customBodyRender: (value) => {
          const formatedValue = firstAndLastWord(value)
          const shortenName = shortenValue(formatedValue)

          return shortenName ?
          <BasicPopover
              noWrap={true}
              text={formatedValue}
              buttonText={shortenName}
            />
            : formatedValue
        },
        hint: `Nome do ${translateLevel('supervisor', strictId)}`,
      },
    },

    {
      name: "supervisorCommission",
      label: `Bonif. ${translateLevel('supervisor', strictId)}`,
      options: {
        setCellProps: () => ({ className: 'no-wrap' }),
        filter: false,
        sort: true,
        display: (isCVC ? 'excluded' : true) && !ISClubeAmigo,
        customBodyRender: (value, tableInfo) => {
          const { rowData, columnIndex } = tableInfo;
          if (!value) return "n/a";
          return `${numToBRL(value)}`;
        },
        hint: `Bonificação do ${translateLevel('supervisor', strictId)}`,
      },
    },
    {
      name: "supervisorPercentage",
      options: {
        setCellProps: () => ({ className: 'no-wrap' }),
        filter: false,
        sort: false,
        display: "excluded",
      },
    },
    {
      name: "sellerName",
      label: translateLevel('seller', strictId),
      options: {
        setCellProps: () => ({ className: 'no-wrap' }),
        filter: true,
        sort: true,
        display: isCVC ? 'excluded' : true,
        customBodyRender: (value) => {
          const formatedValue = firstAndLastWord(value)
          const shortenName = shortenValue(formatedValue)

          return shortenName ?
            <BasicPopover
              noWrap={true}
              text={formatedValue}
              buttonText={shortenName}
            />
            : formatedValue
        },
        hint: `Nome do ${translateLevel('seller', strictId)} que realizou a venda`,
      },
    },
    {
      name: ISClubeAmigo ? 'voucherValue' : "sellerCommission",
      label: `Bonif. ${translateLevel('seller', strictId)}`,
      options: {
        setCellProps: () => ({ className: 'no-wrap' }),
        filter: false,
        sort: true,
        display: isCVC ? 'excluded' : true,
        customBodyRender: (value, tableInfo) => {
          const { rowData, columnIndex } = tableInfo;
          if (!value) return "n/a";
          return numToBRL(value)
        },
        hint: `Bonificação do ${translateLevel('seller', strictId)}`,
      },
    },
    {
      name: "sellerPercentage",
      options: {
        setCellProps: () => ({ className: 'no-wrap' }),
        filter: false,
        sort: true,
        display: "excluded",
      },
    },
    paymentDate,
    linkMessage, // os dados do link PRECISAM estar nas últimas 4 posições da row
    linkStatus,
    linkDate,
    linkUrl,
  ];

  return columns;
};

const corporationTableHeader = ({ hasManager, isIntegratedPartner, partnerFilter, strictId, productId }) => {
  const columns = [
    id,
    {
      name: "administrator.code",
      label: "C. Parceiro",
      options: {
        setCellProps: () => ({ className: 'no-wrap' }),
        sort: true,
        filter: false,
        display: hasManager ? true : "excluded",
        customBodyRender: (value) => value || "n/a",
        hint: "Código do parceiro",
      },
    },
    {
      name: "agencyName",
      label: hasManager ? "Parceiro" : "Loja",
      options: {
        setCellProps: () => ({ className: 'no-wrap' }),
        sort: true,
        filter: hasManager ? true : false,
        display: (hasManager || ISClubeAmigo ? true : "excluded") ,
        customBodyRender: (value) => {
          const formatedValue = value || 'n/a'
          const shortenName = shortenValue(formatedValue)

          return shortenName ?
            <BasicPopover
              noWrap={true}
              text={formatedValue}
              buttonText={shortenName}
            />
            : formatedValue
        },
        hint: "Nome do parceiro",
        filterOptions: partnerFilter
      },
    }
  ]

  if (isIntegratedPartner) {
    columns.push({
      name: "client",
      label: "Cod. Cliente",
      options: {
        setCellProps: () => ({ className: 'no-wrap' }),
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return value || "n/a";
        },
        hint: "Código do Cliente informado no link da venda",
      },
    })
  }

  columns.push(status,
    {
      name: "createdAt",
      label: "Inserção",
      options: {
        setCellProps: () => ({ className: 'no-wrap' }),
        sort: true,
        filter: false,
        customBodyRender: (value) => (value ? formatToBr(value) : "n/a"),
        hint: "Data de inserção da venda do produto",
      },
    },
    product,
    clientName,
    clientCPF,
    phone,
    clientEmail({productId}),
    numProposta({productId}),
    {
      name: "corporationTotal",
      label: "Bonif. Corp.",
      options: {
        setCellProps: () => ({ className: 'no-wrap' }),
        sort: true,
        filter: false,
        customBodyRender: (value, tableInfo) => numToBRL(value),
        hint: "Bonificação referente aos ganhos da corporação",
        display: !ISClubeAmigo
      },
    },
    {
      name: "agencyTotal",
      label: hasManager ? "Bonif. Parceiro" : `Bonif. ${translateLevel('agency', strictId)}`,
      options: {
        setCellProps: () => ({ className: 'no-wrap' }),
        sort: true,
        filter: false,
        customBodyRender: (value, tableInfo) => {
          const { rowData, columnIndex } = tableInfo;
          if (!value) return "n/a";
          return numToBRL(value)
        },
        hint: hasManager ? "Bonificação do parceiro" : "Bonificação da Loja",
        display: !ISClubeAmigo
      },
    },
    {
      name: "agencyPercentage",
      options: {
        setCellProps: () => ({ className: 'no-wrap' }),
        filter: false,
        sort: false,
        display: "excluded",
      },
    },
    {
      name: "corporationCommission",
      label: "Saldo",
      options: {
        setCellProps: () => ({ className: 'no-wrap' }),
        sort: true,
        filter: false,
        customBodyRender: (value) => {
          return numToBRL(value);
        },
        hint: "Valor restante da venda",
        display: !ISClubeAmigo
      },
    },
    {
      name: 'voucherValue',
      label: `Bonif. ${translateLevel('seller', strictId)}`,
      options: {
        setCellProps: () => ({ className: 'no-wrap' }),
        filter: false,
        sort: true,
        display: !!ISClubeAmigo,
        customBodyRender: (value, tableInfo) => {
          const { rowData, columnIndex } = tableInfo;
          if (!value) return "n/a";
          return  numToBRL(value)
        },
        hint: `Bonificação do ${translateLevel('seller', strictId)}`,
      },
    },
    hasManager ? accountCreated : paymentDate,
    linkMessage, // os dados do link PRECISAM estar nas últimas 4 posições da row
    linkStatus,
    linkDate,
    linkUrl,
  )

  return columns;
};

const masterCorporationTableHeader = ({ corpFilter, showClientCode = false, productId }) => [
  {
    name: "number",
    label: "ID",
    options: {
      setCellProps: () => ({ className: 'no-wrap' }),
      filter: false,
      sort: true,
      hint: "ID da venda, letra 'M' para feitos manualmente,'A' para automaticamente, 'O' para Omnichannel",
      customBodyRender: (value, tableInfo) => {
        return value
      }
    },
  },
  status,
  {
    name: "createdAt",
    label: "Inserção",
    options: {
      setCellProps: () => ({ className: 'no-wrap' }),
      sort: true,
      filter: false,
      customBodyRender: (value) => (value ? formatToBr(value) : "n/a"),
      hint: "Data de inserção da venda do produto",
    },
  },
  {
    name: "corbanCode",
    label: "Código Corban",
    options: {
      setCellProps: () => ({ className: 'no-wrap' }),
      filter: false,
      sort: true,
      customBodyRender: (value) => {
        const formatedValue = value || 'Não atribuido'
        const shortenName = shortenValue(formatedValue)

        return shortenName ?
          <BasicPopover
            noWrap={true}
            text={formatedValue}
            buttonText={shortenName}
          />
          : formatedValue
      },
      hint: "Código da corporação a qual foi realizada a venda",
    },
  },
  {
    name: "corpName",
    label: "Corp.",
    options: {
      setCellProps: () => ({ className: 'no-wrap' }),
      filter: false,
      sort: true,
      customBodyRender: (value) => {
        const formatedValue = value || 'n/a'
        const shortenName = shortenValue(formatedValue)

        return shortenName ?
          <BasicPopover
            noWrap={true}
            text={formatedValue}
            buttonText={shortenName}
          />
          : formatedValue
      },
      hint: "Nome da corporação a qual foi realizada a venda",
      filterOptions: corpFilter
    },
  },
  product,
  {
    name: "client",
    label: "Cod. Cliente",
    options: {
      setCellProps: () => ({ className: 'no-wrap' }),
      display: showClientCode ? true : 'excluded',
      filter: false,
      sort: true,
      customBodyRender: (value) => {
        return value || "n/a";
      },
      hint: "Código do Cliente informado no link da venda",
    },
  },
  clientName,
  clientCPF,
  phone,
  clientEmail({productId}),
  numProposta({productId}),
  {
    name: "value",
    label: "Valor Venda",
    options: {
      setCellProps: () => ({ className: 'no-wrap' }),
      sort: true,
      filter: false,
      customBodyRender: (value) => {
        return numToBRL(value);
      },
      hint: "Valor da venda realizada",
    },
  },
  {
    name: "corporationTotal",
    label: "Bonif. Corp.",
    options: {
      setCellProps: () => ({ className: 'no-wrap' }),
      sort: true,
      filter: false,
      customBodyRender: (value, tableInfo) => {
        const { rowData, columnIndex } = tableInfo;
        if (!value) return "n/a";
        return `${numToBRL(value)}`;
      },
      hint: "Bonificação referente aos ganhos da corporação",
    },
  },
  {
    name: "corporationPercentage",
    options: {
      setCellProps: () => ({ className: 'no-wrap' }),
      filter: false,
      sort: false,
      display: "excluded",
    },
  },
  {
    name: "corporationCommission",
    label: "Restante",
    options: {
      setCellProps: () => ({ className: 'no-wrap' }),
      sort: true,
      filter: false,
      customBodyRender: (value) => {
        return numToBRL(value);
      },
      hint: "Valor restante da venda",
    },
  },
  paymentDate,
  linkMessage, // os dados do link PRECISAM estar nas últimas 4 posições da row
  linkStatus,
  linkDate,
  linkUrl,
];

const affiliateTableHeader = [
  {
    name: "number",
    label: "ID",
    options: {
      setCellProps: () => ({ className: 'no-wrap' }),
      filter: false,
      sort: true,
      hint: "ID da venda, letra 'M' para feitos manualmente,'A' para automaticamente, 'O' para Omnichannel",
      customBodyRender: (value, tableInfo) => {
        return value
      }
    },
  },
  status,
  {
    name: "sellerName",
    label: "Vendedor",
    options: {
      setCellProps: () => ({ className: 'no-wrap' }),
      filter: false,
      sort: true,
      customBodyRender: (value) => {
        const formatedValue = firstAndLastWord(value)
        const shortenName = shortenValue(formatedValue)

        return shortenName ?
          <BasicPopover
            noWrap={true}
            text={formatedValue}
            buttonText={shortenName}
          />
          : formatedValue
      },
      hint: "Nome do vendedor que realizou a venda",
    },
  },
  product,
  clientName,
  clientCPF,
  phone,
  clientEmail(),
  {
    name: "affiliateCommission",
    label: "Bonificação",

    options: {
      setCellProps: () => ({ className: 'no-wrap' }),
      filter: false,
      sort: true,
      customBodyRender: (value) => {
        return value === 0 ? "R$ 0,00" : numToBRL(value);
      },
      hint: "Valor recebido pelo afiliado ao realizar uma venda",
    },
  },
  paymentDate,
  linkMessage, // os dados do link PRECISAM estar nas últimas 4 posições da row
  linkStatus,
  linkDate,
  linkUrl,
];

const meliuzTableHeader = [
  id,
  status,
  {
    name: "code",
    label: "Cód. do Cliente",
    options: {
      setCellProps: () => ({ className: 'no-wrap' }),
      filter: false,
      sort: false,
    },
  },
  product,
  clientName,
  clientCPF,
  phone,
  clientEmail(),
  {
    name: "corporationCommission",
    label: "Bonificação",

    options: {
      setCellProps: () => ({ className: 'no-wrap' }),
      filter: false,
      sort: false,
      customBodyRender: (value) => {
        return value === 0 ? "R$ 0,00" : numToBRL(value);
      },
    },
  },
  createdAt,
  paymentDate,
  linkMessage, // os dados do link PRECISAM estar nas últimas 4 posições da row
  linkStatus,
  linkDate,
  linkUrl,
];

const partnerWithCodeHeader = [
  id,
  status,
  {
    name: "code",
    label: "Cód. do Cliente",
    options: {
      setCellProps: () => ({ className: 'no-wrap' }),
      filter: false,
      sort: false,
    },
  },
  {
    name: "createdAt",
    label: "Inserção",
    options: {
      setCellProps: () => ({ className: 'no-wrap' }),
      sort: true,
      filter: false,
      customBodyRender: (value) => {
        return formatToBr(value) || "n/a";
      },
      hint: "Data de inserção da venda do produto",
    },
  },
  product,
  clientName,
  clientCPF,
  phone,
  clientEmail(),
  {
    name: "corporationTotal",
    label: "Bonif. Corp.",
    options: {
      setCellProps: () => ({ className: 'no-wrap' }),
      sort: true,
      filter: false,
      customBodyRender: (value, tableInfo) => {
        const { rowData, columnIndex } = tableInfo;
        if (!value) return "n/a";
        return `${numToBRL(value)}`;
      },
      hint: "Bonificação referente aos ganhos da corporação",
    },
  },
  {
    name: "corporationPercentage",
    options: {
      setCellProps: () => ({ className: 'no-wrap' }),
      filter: false,
      sort: false,
      display: "excluded",
    },
  },
  {
    name: "corporationCommission",
    label: "Restante",
    options: {
      setCellProps: () => ({ className: 'no-wrap' }),
      sort: true,
      filter: false,
      customBodyRender: (value) => {
        return numToBRL(value);
      },
      hint: "Valor restante da venda",
    },
  },
  paymentDate,
  linkMessage, // os dados do link PRECISAM estar nas últimas 4 posições da row
  linkStatus,
  linkDate,
  linkUrl,
];

const getTableConfig = ({ level, hasManager, isIntegratedPartner, partnerFilter, strictId, refreshTable, productId = 'default' }) => {
  const config = {
    default: () => {
      switch (level) {
        case "seller": {
          return sellerTableHeader({ hasManager, strictId, refreshTable, productId });
        }
        case "supervisor": {
          return supervisorTableHeader({ hasManager, strictId, productId });
        }
        case "subcorp":
        case "corporation": {
          return corporationTableHeader({ hasManager, isIntegratedPartner, partnerFilter, strictId, productId });
        }
        case "administrator": {
          return administratorTableHeader({ hasManager, strictId, productId });
        }
        case "affiliate":
        case "affiliates": {
          return affiliateTableHeader;
        }
        case "meliuz": {
          return meliuzTableHeader;
        }
        case "partnerWithCode": {
          return partnerWithCodeHeader;
        }
        case "submaster":
        case "master":
        case "corporations": {
          return masterCorporationTableHeader({ partnerFilter, hasManager, productId });
        }
        default: {
          return [];
        }
      }
    }
  }

 return config[product] ? config[product]() : config['default']()

};



export default getTableConfig;
