import React from 'react';
import DashboardMeliuz from "views/DashboardMeliuz";
import UserProfile from "views/UserProfile.jsx";
import Map from "views/Map.jsx";
import SupervisedTeam from 'views/SupervisedTeam'
import TeamRoutes from "views/Teams";
import Affiliate from "views/Affiliate";
import Home from "views/Home.jsx";
import SellAsAgency from "views/SellAsAgency"
import CorpSelect from "views/CorpSelect"
import CreateCorp from "views/CreateCorp";
import CorpDetails from "views/CorpDetails";
import Help from 'views/Help'
import AgencyInfo from "views/AgencyInfo";
import ProposalStatus from 'views/ProposalStatus/ProposalStatus'
import UserListMaster from "views/UserListMaster/UserListMaster"

import CorpProfile from "views/CorpProfile";
import ExtractMaster from "views/extract/master";
import ExtractCorporation from "views/extract/corporation";
import Documentation from "views/Documentation";
import Contact from "views/Contact";
import MeliuzGeneratedLinks from 'views/meliuz/GeneratedLinks';
import SupervisorList from 'components/TeamCorp/SupervisorList';

import ManagersList from 'views/Managers/Approved/ManagerList'
import CreateManager from 'views/Managers/CreateManager'
import AgencyManagement from 'components/AgencyManagement'
import ManagerPending from 'views/Managers/Pending/ManagerPendingList'
import ManagerDashboard from 'views/Managers/Dashboard/ManagerDashboard'
import ManagerAgencyList from 'views/Managers/Links/ManagerAgencyList'
import ManagerAgencies from 'views/Managers/ManagerAgencies/ManagerAgencies'

import ImportAgency from 'views/ImportAgency/'
import SupportTicket from 'views/SupportTicket'
import ImportData from 'views/ImportData'

import SubUsers from 'views/SubUsers/SubUsers'
import NewSubUser from 'components/NewSubUser'

import DisclosureMaterial from 'views/DisclosureMaterial';
import CorporationCampaign from 'views/CorpCampaign'
import ManagersTotals from 'views/ManagersTotals';

import disabledRoutes from './disabledRoutes'
import ImportAppsFlyerPhone from 'views/ImportAppsFlyerPhones';
import PartnerDocumentation from 'views/PartnerDocumentation';

import OperatorPanel from 'views/OperatorPanel'
import OperatorCorpDetails from 'views/OperatorCorpDetails'
import OperatorErrorPanel from 'views/OperatorErrorPanel'
import OperatorHome from 'views/OperatorPanelHome'
import { CVCId, isSupportMarAberto, ClubeAmigoId } from 'utils/CommonData';

import Shortener from 'views/Shortener'
import ControlLink from 'views/ControlLink'
import SearchMenagers from 'views/SearchMenagers'

import Notification from 'views/Notification'
import Communication from 'views/Communication'

import PaymentReport from 'views/PaymentReport'
import NewPaymentReport from 'views/NewPaymentReport'
import DownloadPaymentReport from 'views/DownloadPaymentReport'
import DownloadClientReport from 'views/DownloadClientReport'

import DailyExtractMaster from 'views/DailyExtract/DailyExtractMaster'
import DailyExtractCorporation from 'views/DailyExtract/DailyExtractCorporation'
import NewDailyExtractCorporation from "views/DailyExtract/NewDailyExtractCorporation"
import NewestExtractMaster from "views/extract/NewestExtractMaster";

import ImportContactData from 'views/ImportContactData'
import DashboardOmnichannel from 'views/DashboardOmnichannel'

import { contaId, _agencyId } from 'utils/CommonData'
import OperatorUserDetails from 'views/OperatorUserDetails';
import { translateCommunicationAdmin } from 'utils/translate';
import UserVoucherReport from 'views/UserVoucherReport/UserVoucherReport';

const home = {
  path: "/home",
  name: "Home",
  icon: "house",
  component: Home,
  layout: "/admin",
  key: 1,
};

const dashboardMeliuz = {
  path: "/dashboard",
  name: "Visão Geral",
  icon: "chart-pie",
  component: DashboardMeliuz,
  layout: "/admin",
  key: 2,
};

const affiliate = {
  path: "/affiliate",
  name: "Convidar",
  icon: "link-default",
  component: Affiliate,
  layout: "/admin",
  key: 4
};

const map = {
  path: "/map",
  name: "Mapa",
  icon: "square-pin",
  component: Map,
  layout: "/admin",
  key: 5
};

const proposalStatus = {
  path: "/proposal-status",
  name: "Status de Proposta",
  icon: "wallet",
  component: ProposalStatus,
  layout: "/admin",
  key: 6
};

const profile = {
  path: "/profile",
  name: "Perfil",
  icon: "person",
  component: UserProfile,
  additional: true,
  layout: "/admin",
  key: 7
};

const corp = {
  exactly: true,
  path: "/corporations",
  name: "Corporações",
  icon: "store",
  component: CorpSelect,
  layout: "/admin",
  key: 8
};

const createCorp = {

  path: "/corporations/new",
  name: "Cad. Corporação",
  icon: "add",
  component: CreateCorp,
  layout: "/admin",
  key: 9
};

const corpDetails = {
  path: "/corporations/details",
  name: "Cad. Corporação",
  icon: "add",
  component: CorpDetails,
  layout: "/admin",
  additional: true,
  key: 10
};

const affiliateToAgency = {
  path: "/affiliate/invite",
  name: "Convidar para loja",
  icon: "link",
  component: Affiliate,
  layout: "/admin",
  additional: true,
  key: 11
};
const supervisorTeam = {
  path: "/team",
  name: "Equipe",
  icon: "people",
  component: SupervisedTeam,
  layout: "/admin",
  key: 12
};

const agencyInfo = {
  path: "/agency/details",
  name: "Loja e Bonificações",
  icon: "store",
  component: AgencyInfo,
  layout: "/admin",
  key: 14
};

const corpProfile = {
  path: "/corporation/profile",
  name: "Perfil da Corporação",
  icon: "id",
  component: CorpProfile,
  additional: true,
  layout: "/admin",
  key: 17
};

const corpAgecies = {

  path: "/corporation/details",
  name: "Lojas",
  icon: "store",
  component: CorpDetails,
  layout: "/admin",
  key: 18
};

const proposalStatusCorp = {
  path: "/corporations/proposal-status",
  prefix: 'Status de Proposta das',
  name: "Corporações",
  icon: "coins",
  component: ProposalStatus,
  layout: "/admin",
  key: 19
};

const proposalStatusAffiliates = {
  path: "/affiliates/proposal-status",
  prefix: 'Status de Proposta dos',
  name: "Afiliados",
  icon: "coins",
  component: ProposalStatus,
  layout: "/admin",
  key: 99
};

const extractMaster = {
  path: "/extract",
  name: "Extrato",
  icon: "bank-check",
  component: ExtractMaster,
  layout: "/admin",
  key: 21
};

const newestExtractMaster = {
  path: "/extract",
  name: "Extrato",
  icon: "bank-check",
  component: NewestExtractMaster,
  layout: "/admin",
  key: 21
};

const sellAsAgency = {
  path: "/sell/agency",
  name: "Link da Loja",
  icon: "link-default",
  component: SellAsAgency,
  layout: "/admin",
  key: 23
};
const documentation = {
  path: "/documentation",
  name: "Documentação API",
  icon: "question",
  component: Documentation,
  layout: "",
  key: 24
};

const voidPage = {
  path: "/user/void",
  name: "",
  icon: "question",
  component: null,
  layout: "",
  additional: true,
  key: 25
};

const contactPage = {
  path: "/contact",
  name: "Contato",
  icon: "message-chat",
  component: Contact,
  layout: "/admin",
  key: 26
};

const generatedLinks = {
  path: "/salles-meliuz",
  name: "Vendas Méliuz",
  icon: "link-default",
  component: MeliuzGeneratedLinks,
  layout: "/admin",
  key: 27
};

const teamAgency = {
  path: "/teams",
  name: "Gerenciar equipes",
  icon: "people",
  component: TeamRoutes,
  layout: "/admin",
  additional: true,
  key: 28
};

const helpPage = {
  path: "/help",
  name: "Ajuda",
  icon: "question",
  component: Help,
  layout: "/admin",
  key: 29
}

const listUsers = {
  path: "/userList",
  name: "Listagem de afiliados",
  icon: "people",
  component: UserListMaster,
  layout: "/admin",
  key: 30
}
const material = {
  path: "/disclosure",
  name: "Divulgação",
  component: DisclosureMaterial,
  icon: "document-lines",
  layout: "/admin",
  key: 32
}
const extractCorporation = {
  path: "/extract",
  name: "Extrato",
  icon: "bank-check",
  component: ExtractCorporation,
  layout: "/admin",
  key: 33
};

const newAgency = {
  path: "/create/agency",
  name: "Nova Loja",
  component: AgencyManagement,
  layout: "/admin",
  additional: true,
  key: 34
}

const updateAgency = {
  path: "/update/agency",
  name: "Atualizar Loja",
  component: (props) => <AgencyManagement {...props} update />,
  layout: "/admin",
  additional: true,
  key: 36
}

const supervisorCorpTeam = {
  path: "/teams/supervisor",
  name: "Gerenciar equipes",
  icon: "people",
  component: SupervisorList,
  layout: "/admin",
  additional: true,
  key: 37
}

const subUsers = {
  path: "/subuser",
  name: "Usuários Adicionais",
  icon: "people",
  component: SubUsers,
  layout: "/admin",
  key: 38,
  buttons: [
    {
      id: "data-tour-sub-users-create",
      title: "Novo usuário",
      path: "/admin/new/subuser",
      style: {
        margin: '0px',
        maxWidth: '150px',
        height: '32px',
        paddingTop: '4px'
      }
    }
  ]
}

const newSubUser = {
  path: "/new/subuser",
  name: "Novo usuário",
  layout: "/admin",
  component: NewSubUser,
  additional: true,
  key: 39
}

const corpCampaign = {
  path: '/corporation/campaign',
  name: 'Campanhas',
  icon: 'people',
  component: CorporationCampaign,
  layout: '/admin',
  key: 40
}

const managers = {
  path: '/managers',
  name: 'Gerentes',
  icon: 'people',
  component: ManagersList,
  layout: '/admin',
  key: 41,
  exactly: true,
  buttons: [
    {
      title: "Novo gerente",
      path: "/admin/create/managers",
      style: {
        marginRight: '10px',
        textAlign: 'center',
        maxWidth: '200px',
        height: '32px',
        paddingTop: '4px'
      }
    },
    {
      title: "Gerentes pendentes",
      path: "/admin/pending/managers",
      style: {
        margin: '0px',
        textAlign: 'center',
        maxWidth: '200px',
        height: '32px',
        paddingTop: '4px'
      }
    }
  ]
}

const createManager = {
  path: '/create/managers',
  name: 'Novo gerente',
  icon: 'people',
  component: CreateManager,
  additional: true,
  layout: '/admin',
  key: 42
}

const teamAgencyAdm = {
  path: "/teams",
  name: "Gerenciar equipes",
  icon: "people",
  component: TeamRoutes,
  layout: "/admin",
  additional: false,
  key: 43
};

const managersTotalCorps = {
  path: "/managers/report/corps",
  name: "Visão de Produção",
  icon: "people",
  component: ManagersTotals,
  layout: "/admin",
  disabled: false,
  key: 44
};
const managersTotalManagers = {
  path: "/managers/report/managers",
  name: "Visão de Gerentes",
  icon: "phonebook",
  component: ManagersTotals,
  layout: "/admin",
  disabled: false,
  key: 45
};

// No link //

const disabledLeads = {
  name: "Gestão de leads",
  icon: "coins",
  key: 49,
  insideLinks: [...disabledRoutes]
};

// No link //

const managersMap = {
  path: "/map",
  name: "Leads por estado",
  icon: "square-pin",
  component: Map,
  layout: "/admin",
  key: 50
};

const managerHistory = {
  path: "/pending/managers",
  name: "Gerentes pendentes",
  icon: "coins",
  component: ManagerPending,
  additional: true,
  layout: "/admin",
  key: 51
}

const managerDashboard = {
  path: "/managers/dashboard",
  name: "Dashboard",
  icon: "coins",
  component: ManagerDashboard,
  layout: "/admin",
  key: 55
}

const ManagerProd = {
  path: "/agency/managers",
  name: "Rel. produção",
  component: ManagerAgencyList,
  icon: "touch",
  layout: '/admin',
  key: 54
}

const managerAgencies = {
  path: '/support',
  name: 'Atribuição de parceiros',
  component: ManagerAgencies,
  icon: 'phone',
  additional: true,
  layout: "/admin",
  key: 59
}

const managersMenu = {
  name: "Controller",
  icon: "touch",
  key: 52,
  insideLinks: [managerDashboard, ManagerProd, managersTotalCorps, managersTotalManagers]
}

const managersController = {
  name: "Controller",
  icon: "touch",
  key: 65,
  insideLinks: [managers, managerDashboard, ManagerProd, managersTotalCorps, managersTotalManagers]
}

const searchMenagers = {
  path: "/search-managers",
  name: "Gerentes por Loja",
  icon: "store",
  component: SearchMenagers,
  layout: "/admin",
  key: 80
};

const masterController = {
  name: "Controller",
  icon: "touch",
  key: 53,
  insideLinks: [managers, managerDashboard, managersTotalCorps, managersTotalManagers, ManagerProd, searchMenagers]
}

const corpController = {
  name: "Controller",
  icon: "touch",
  key: 53,
  insideLinks: [managers, managerDashboard, managersTotalCorps, ManagerProd]
}

const importDataMenu = {
  path: "/data/import",
  name: "Importar Propostas",
  layout: "/admin",
  component: ImportData,
  icon: "coins",
  key: 56,
  buttons: [
    {
      title: "Importar",
      path: "/admin/data/import/requestFile",
      style: {
        marginRight: '10px',
        textAlign: 'center',
        maxWidth: '200px',
        height: '32px',
        paddingTop: '4px'
      }
    }
  ]
}

const paymentMenu = {
  name: "Status de Proposta",
  icon: "coins",
  key: 54,
  insideLinks: [proposalStatusCorp, proposalStatusAffiliates, importDataMenu]
}

const importAgencyMenu = {
  path: "/agency/import",
  name: "Importar",
  layout: "/admin",
  component: ImportAgency,
  icon: "coins",
  key: 55,
  buttons: [
    {
      title: "Importar",
      path: "/admin/agency/import/requestFile",
      style: {
        marginRight: '10px',
        textAlign: 'center',
        maxWidth: '200px',
        height: '32px',
        paddingTop: '4px'
      }
    }
  ]
}

const agencySubMenu = {
  name: 'Parceiros',
  icon: 'store',
  key: 56,
  insideLinks: [importAgencyMenu]
}

// const supportTicket = {
//   path: '/support',
//   name: 'Help Desk',
//   component: SupportTicket,
//   icon: 'phone',
//   layout: "/admin",
//   key: 57
// }

// const helpMenu = {
//   name: 'Contato',
//   icon: 'mail',
//   key: 58,
//   insideLinks: [supportTicket, helpPage]
// }

const importAppsFlyerPhone = {
  path: '/appsflyer/import',
  name: 'Importar Telefones AppsFlyer',
  component: ImportAppsFlyerPhone,
  icon: 'phone',
  layout: "/admin",
  key: 59
}

const partnerDocumentation = {
  path: '/partner/documentation',
  name: 'Documentação de parceiro',
  component: PartnerDocumentation,
  icon: 'question',
  layout: "/admin",
  key: 60
}

const operatorPanel = {
  path: "/operator/listUsers",
  name: "Operator Listar Usuarios",
  icon: "people",
  component: OperatorPanel,
  layout: "/admin",
  key: 61
}
const operatorCorpDetails = {
  path: "/operator/corporation/details",
  name: "Detalhes da Corporação",
  icon: "people",
  component: OperatorCorpDetails,
  additional: true,
  layout: "/admin",
  key: 62
}

const operatorUserDetails = {
  path: "/operator/user/details",
  name: "Detalhes do Usuário",
  icon: "people",
  component: OperatorUserDetails,
  additional: true,
  layout: "/admin",
  key: 162
}


const operatorErrorPanel = {
  path: "/operator/listError",
  name: "Listagem de Erros",
  icon: "people",
  component: OperatorErrorPanel,
  layout: "/admin",
  key: 63
}

const operatorHome = {
  path: "/operator/home",
  name: "Inicio",
  icon: "house",
  component: OperatorHome,
  layout: "/admin",
  key: 64
}

const updateSubUser = {
  path: "/update/subuser",
  name: "Atualizar usuário",
  layout: "/admin",
  component: NewSubUser,
  additional: true,
  key: 65
}

const masterImportAgency = {
  path: "/agency/import",
  name: "Importar",
  layout: "/admin",
  component: ImportAgency,
  additional: true,
  key: 66,
}

const shortener = {
  path: "/shortener",
  name: "Controle de links",
  icon: "link-default",
  component: Shortener,
  layout: "/admin",
  key: 66
}

const notification = {
  path: "/notification",
  name: "Notificações",
  icon: "info",
  component: Notification,
  layout: "/admin",
  key: 67
}

const teamAgencyAGX = {
  path: "/ed8fd809601c43959b9b90c01c8b859a",
  name: "Gerenciar equipes",
  icon: "people",
  component: TeamRoutes,
  layout: "/admin",
  disabled: false,
  additional: false,
  key: 43
};

const paymentReport = {
  path: "/payment-report",
  name: "Rel. de Pagamentos",
  icon: "coins",
  component: PaymentReport,
  layout: "/admin",
  key: 69
};

const controlLink = {
  path: "/control-link",
  name: "Controle de links",
  icon: "link-default",
  component: ControlLink,
  layout: "/admin",
  key: 70
}

const newExtractMaster = {
  path: "/extract",
  name: "Extrato",
  icon: "bank-check",
  component: DailyExtractMaster,
  layout: "/admin",
  key: 71
}

const newExtractCorporation = {
  path: "/extract",
  name: "Extrato",
  icon: "bank-check",
  component: DailyExtractCorporation,
  // additional: true,
  layout: "/admin",
  key: 72
};

const dailyExtractCorporation = {
  path: "/extract",
  name: "Extrato",
  icon: "bank-check",
  component: NewDailyExtractCorporation,
  layout: "/admin",
  key: 76
};

const newPaymentReport = {
  path: "/payment-report",
  name: "Rel. de Pagamentos",
  icon: "coins",
  component: NewPaymentReport,
  layout: "/admin",
  key: 73
};

const downloadClientReport = {
  path: "/download-client-report",
  name: "Rel. de Clientes",
  icon: "people",
  component: DownloadClientReport,
  layout: "/admin",
  key: 76
}

const downloadPaymentReport = {
  path: "/download-payment-report",
  name: "Rel. de Pagamentos",
  icon: "coins",
  component: DownloadPaymentReport,
  layout: "/admin",
  key: 75
}

const importContactData = {
  path: "/import-contact-data",
  name: "Importar Dados para Contato",
  icon: "phone",
  component: ImportContactData,
  layout: "/admin",
  key: 76
}

const dashboardOmnichannel = {
  path: "/dashboard",
  name: "Visão Geral",
  icon: "chart-pie",
  component: DashboardOmnichannel,
  layout: "/admin",
  key: 78
}

const clubeAmigoUserVoucherReport = {
  path: "/user-voucher-report",
  name: "Rel. de Vouchers",
  icon: "coins",
  component: UserVoucherReport,
  layout: "/admin",
  key: 78
};

const hiddenManagersTotals = {
  path: "/6ade47ecbcded3187b1b4683ac987658",
  name: "Visão de Produção",
  icon: "people",
  component: ManagersTotals,
  layout: "/admin",
  disabled: false,
  additional: true,
  key: 1001
};

const hiddenManagerProd = {
  path: "/8bafd00516b66a40f74b6fb4d0f0b756",
  name: "Rel. produção",
  component: ManagerAgencyList,
  icon: "touch",
  layout: '/admin',
  additional: true,
  key: 1002
}

const hiddenimportContactData = {
  path: "/edf0f6afab8b62c17b45550a3fdab1d1",
  name: "Importar Dados para Contato",
  icon: "phone",
  additional: true,
  component: ImportContactData,
  layout: "/admin",
  key: 1003
}

const hiddenDownloadClientReport = {
  path: "/2d061881fbd0c02c75bfe29d820e31ac",
  name: "Rel. de Clientes",
  icon: "people",
  component: DownloadClientReport,
  additional: true,
  layout: "/admin",
  key: 1004
}

const hiddenImportAgencyMenu = {
  path: "/e34cea042db61d78d462bb9afd4aa0bb",
  name: "Importar",
  layout: "/admin",
  component: ImportAgency,
  icon: "coins",
  key: 1006,
  additional: true
}


function getRoutesByLevel(userData) {

  const { level: userLevel, agencyId, hasManager, id: userId, isIntegratedPartner, corpId, products, hasSupport } = userData

  const isAGX = ((userLevel === 'submaster' && userId === '5f4fd423caf57500045449b3') || userId === '5f88b13974454e0004d0b163' || userId === '5f4fd423caf57500045449b5')

  const isCVC = corpId == CVCId
  const isAffiliate = agencyId === _agencyId
  const isClubeAmigo = corpId === ClubeAmigoId
  const corpAgenciesManager = { ...corpAgecies, name: hasManager ? 'Parceiros' : 'Lojas' }
  const updateAgencyManager = { ...updateAgency, name: hasManager ? 'Atualizar parceiro' : 'Atualizar loja' }
  const newAgencyManager = { ...newAgency, name: hasManager ? 'Novo parceiro' : 'Nova loja' }

  agencySubMenu.insideLinks.push({ ...corpAgenciesManager, name: "Cadastrados" })

  const isSupport = isSupportMarAberto.includes(userId)

  const IS_FGTS = corpId === '6125561ee155b03138e9277b'

  let hasConta = products?.reduce((acc, product) => {
    return product.value === contaId && acc
  }, true);

  const communication = {
    path: "/communication",
    name: `Fale c/ seu ${translateCommunicationAdmin(userLevel, corpId)}`,
    icon: "message-chat",
    component: Communication,
    layout: "/admin",
    key: 68
  }

  let routes = [];
  switch (userLevel) {
    case "seller":
      routes = [
        home,
        dashboardOmnichannel,
        material,
        proposalStatus,
        profile,
        !hasConta ? helpPage : voidPage,
        communication,
      ];
      break;

    case "supervisor":
      routes = [
        isClubeAmigo ? voidPage : home ,
        dashboardOmnichannel,
        material,
        supervisorTeam,
        proposalStatus,
        isClubeAmigo ? clubeAmigoUserVoucherReport : voidPage,
        profile,
        !hasConta ? helpPage : voidPage,
        communication
      ];
      break;

    case "administrator":
      routes = [
        ![ClubeAmigoId].includes(corpId) ? sellAsAgency: voidPage,
        dashboardOmnichannel,
        supervisorCorpTeam,
        material,
        teamAgencyAdm,
        ![ClubeAmigoId].includes(corpId) ? affiliate : voidPage,
        newExtractCorporation,
        ![ClubeAmigoId].includes(corpId) ? agencyInfo : voidPage,
        proposalStatus,
        downloadPaymentReport,
        isClubeAmigo ? clubeAmigoUserVoucherReport : voidPage,
        profile,
        // hasManager ? supportTicket : voidPage,
        !hasConta ? helpPage : voidPage,
        notification,
        communication
      ];
      break;

    case "corporation":
      routes = [
        isIntegratedPartner && !isClubeAmigo ? partnerDocumentation : voidPage,
        dashboardOmnichannel,
        material,
        corpCampaign,
        hasManager && !IS_FGTS? corpController : voidPage,
        { ...map, disabled: !isAGX },
        supervisorCorpTeam,
        affiliateToAgency,
        dailyExtractCorporation,
        proposalStatus,
        downloadPaymentReport,
        isClubeAmigo ? clubeAmigoUserVoucherReport : voidPage,
        teamAgency,
        corpProfile,
        hasManager && !IS_FGTS? agencySubMenu : corpAgenciesManager,
        hiddenImportAgencyMenu,
        newAgencyManager,
        updateAgencyManager,
        subUsers,
        newSubUser,
        updateSubUser,
        { ...managers, additional: true },
        createManager,
        managerHistory,
        // hasManager && !IS_FGTS? supportTicket : voidPage,
        !hasConta ? helpPage : voidPage,
        notification,
        communication,
        shortener
      ];
      break;

    case "subcorp":
      routes = [
        isIntegratedPartner ? partnerDocumentation : voidPage,
        dashboardOmnichannel,
        material,
        corpCampaign,
        hasManager ? corpController : voidPage,
        { ...map, disabled: !isAGX },
        supervisorCorpTeam,
        affiliateToAgency,
        dailyExtractCorporation,
        hasManager ? agencySubMenu : corpAgenciesManager,
        proposalStatus,
        downloadPaymentReport,
        isClubeAmigo ? clubeAmigoUserVoucherReport : voidPage,
        teamAgency,
        corpProfile,
        newAgencyManager,
        updateAgencyManager,
        createManager,
        { ...managers, additional: true },
        managerHistory,
        // hasManager ? supportTicket : voidPage,
        !hasConta ? helpPage : voidPage,
        notification,
        communication,
        shortener
      ];
      break;

    case "master":
      routes = [
        documentation,
        disabledLeads,
        masterController,
        dashboardOmnichannel,
        material,
        { ...map, disabled: !isAGX },
        affiliateToAgency,
        paymentMenu,
        downloadPaymentReport,
        newestExtractMaster,
        corp,
        createCorp,
        corpDetails,
        listUsers,
        { ...operatorPanel, name: 'Lista de Usuários' },
        operatorUserDetails,
        subUsers,
        newSubUser,
        updateSubUser,
        createManager,
        managerAgencies,
        { ...managers, additional: true },
        importAppsFlyerPhone,
        managerHistory,
        controlLink,
        !hasConta ? helpPage : voidPage,
        notification,
        communication,
      ];
      break;

    case "submaster":
      routes = [
        documentation,
        disabledLeads,
        masterController,
        dashboardOmnichannel,
        material,
        { ...map, disabled: !isAGX },
        affiliateToAgency,
        paymentMenu,
        downloadPaymentReport,
        clubeAmigoUserVoucherReport,
        newestExtractMaster,
        corp,
        createCorp,
        corpDetails,
        subUsers,
        newSubUser,
        updateSubUser,
        listUsers,
        { ...operatorPanel, name: 'Lista de Usuários' },
        operatorUserDetails,
        createManager,
        managerAgencies,
        { ...managers, additional: true },
        importAppsFlyerPhone,
        managerHistory,
        controlLink,
        !hasConta ? helpPage : voidPage,
        notification,
        communication
      ];
      break;

    case 'superintendent':
      routes = [
        disabledLeads,
        managerDashboard,
        ManagerProd,
        managersTotalCorps,
        managersTotalManagers,
        isClubeAmigo ? clubeAmigoUserVoucherReport : voidPage,
        profile,
        // supportTicket,
        !hasConta ? helpPage : voidPage,
      ]
      break;

    case 'operator':
      routes = [
        operatorHome,
        operatorCorpDetails,
        operatorErrorPanel,
        operatorPanel,
        operatorUserDetails,
        profile
      ]
      break

    default:
      routes = [
        managerDashboard,
        ManagerProd,
        managersTotalCorps,
        managersTotalManagers,
        profile,
        // supportTicket,
        !hasConta ? helpPage : voidPage,
      ]
  }

  if (userLevel === 'corporation' && userId === '5ed7a8c62b917900248c7c84') {
    routes = [
      dashboardMeliuz,
      generatedLinks,
      contactPage,
      newExtractCorporation,
      dailyExtractCorporation,
      subUsers,
      notification,
      communication
    ]
  }

  if (userLevel === 'submaster' && (userId === '5f69347d48c1d60004a8916f' || userId === '5f68f1e4e161890004d413c5' || userId === '5f63b503214844522004f46a'))
    routes = [
      documentation,
      disabledLeads,
      masterController,
      dashboardOmnichannel,
      material,
      { ...map, disabled: !isAGX },
      affiliateToAgency,
      paymentMenu,
      downloadPaymentReport,
      hiddenDownloadClientReport,
      newestExtractMaster,
      corp,
      createCorp,
      corpDetails,
      listUsers,
      { ...operatorPanel, name: 'Lista de Usuários' },
      operatorUserDetails,
      subUsers,
      newSubUser,
      updateSubUser,
      createManager,
      managerAgencies,
      { ...managers, additional: true },
      importAppsFlyerPhone,
      managerHistory,
      importContactData,
      !hasConta ? helpPage : voidPage,
      operatorCorpDetails,
      controlLink,
      notification,
      communication
    ]

  if (userLevel === 'submaster' && userId === '5f68f1e4e161890004d413c5') {
    routes = [
      documentation
    ]
  }

  if (isCVC && 'administrator' === userLevel)
    routes = [
      sellAsAgency,
      material,
      proposalStatus,
      profile,
      !hasConta ? helpPage : voidPage,
      notification,
      communication
    ]

  if (isAffiliate && 'seller' === userLevel)
    routes = [
      home,
      dashboardOmnichannel,
      material,
      proposalStatus,
      profile,
      !hasConta ? helpPage : voidPage,
      communication
    ]

  if (['seller', 'supervisor'].includes(userLevel) && (agencyId === '5fa6ad8b3d56db0004c98178')) {
    routes.push(affiliate)
  }

  if (['supervisor'].includes(userLevel) && (['5f4fd423caf57500045449b3', '601c4618321d1f0003e167e6'].includes(corpId))) {
    routes.push(affiliate)
  }

  // if (['seller'].includes(userLevel) && ([ClubeAmigoId].includes(corpId))) {
  //   routes.push(affiliate)
  // }

  if (['supervisor'].includes(userLevel) && ([ClubeAmigoId].includes(corpId))) {
    routes.unshift(affiliate)
  }

  if (['supervisor'].includes(userLevel) && ([ClubeAmigoId].includes(corpId))) {
    routes.push(notification)
  }

  if (userLevel === 'submaster' && hasSupport) {
    routes = [
      documentation,
      masterController,
      dashboardOmnichannel,
      material,
      { ...map, disabled: !isAGX },
      affiliateToAgency,
      paymentMenu,
      downloadPaymentReport,
      newestExtractMaster,
      corp,
      corpDetails,
      { ...operatorPanel, name: 'Lista de Usuários' },
      operatorUserDetails,
      createManager,
      managerAgencies,
      { ...managers, additional: true },
      importAppsFlyerPhone,
      managerHistory,
      controlLink,
      !hasConta ? helpPage : voidPage,
      notification,
      communication
    ];
  }

  if (isSupport && 'administrator' === userLevel) {
    routes = [
      communication
    ]
  }

  return routes;
}

export default getRoutesByLevel;
