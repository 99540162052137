import React, { useState, useEffect } from 'react'
import MahoeIcon from "components/MahoeIcons";

import Button from "components/Buttons/Button";

import { Grid, Row, Col } from 'react-bootstrap';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import mainApi from "services/mainApi";

import swal from "components/Alert/alert";
import InputMuiField from "components/InputMuiField";

import { Error } from './styled'
import { TextField } from "@material-ui/core"


import getUserData from "utils/checkUser"
import { _agencyId } from 'utils/CommonData'
import { translateLevel } from 'utils/translate';


const CommunicationModal = ({ onSend,history }) => {
  const [errors, setErrors] = useState({})

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('')
  const [subtitle, setSubtitle] = useState('')
  const [message, setMessage] = useState('')
  const [userAbove, setUserAbove] = useState()
  const [loading, setLoading] = useState(false)
  const [userData, setUserData] = useState({})
  const [isAffiliate, setIsAffiliate] = useState(false)

  function handleErrorsResponse(response) {
    const auxErrors = {};
    if (!response) return;

    response.forEach((item) => {
      auxErrors[item.name] = item.message;
    });

    setErrors(auxErrors)
  };

  async function UserData() {
    const userData = await getUserData(history);
    const isAffiliate = userData?.agencyId === _agencyId
    setIsAffiliate(isAffiliate)
    getAbove(isAffiliate)
    setUserData(userData)
  };


  function resetFields() {
    setErrors({})
    setTitle('')
    setSubtitle('')
    setMessage('')
  }

  async function createCommunication() {
    let data = {
      message: message,
      // title: title,
      // subTitle: subtitle
    }

    if (!loading) {
      setLoading(true)
      try {
        const response = await mainApi.post("/communication", data);
        resetFields()
        setOpen(false)
        onSend()
        swal.basic("success", "Sucesso", response?.data?.message)
        setLoading(false)
      } catch (err) {
        setErrors({})
        if (err.response?.data?.data?.invalid) handleErrorsResponse(err.response?.data?.data?.invalid)
        else if (err.response?.data?.message) setErrors({message: err.response?.data?.message})
        else if ( typeof err.response?.data === 'string') setErrors({message: err.response?.data})
        else setErrors({message: "Não foi possível enviar a mensagem, tente novamente mais tarde."})
        setLoading(false)
      }
    }
  }

  async function getAbove(isAffiliate) {
    try {
      let  query = ''
      if(isAffiliate)query += `?isAffiliate=${isAffiliate}`
      const response = await mainApi.get(`/user/above${query}`);
      setUserAbove(response.data.data.userAbove)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    UserData()
  }, [])

  return (
    <>
      <button
        className="btn btn-fill btn-mlm btn-message"
        onClick={() => { setOpen(true) }}
      >
        Nova mensagem
       </button>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false)
          resetFields()
        }}
        fullWidth
        maxWidth={'lg'}
      >
        <DialogTitle>
          <div className='notification-container-close-button'>
            <p style={{ width: "100%" }}>Nova mensagem</p>
            <div className='notification-close-button'>
              <MahoeIcon
                name="rounded-close"
                fill='black'
                size={30}
                onClick={() => { setOpen(false) }}
                style={{ cursor: 'pointer', marginRight: '-15px' }}
              />
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          {
            userAbove &&
            <div className='notification-header' style={{ marginBottom: 20 }}>
              <div className='notification-header-logo'>
                <img alt="" src={userAbove.image && `https://agxsoftwarecloud.s3.amazonaws.com/${userAbove.image}`} />
              </div>
              <div className='notification-header-text-container'>
                <p className='notification-header-text' >{userAbove.name}</p>
                <p className='notification-header-level'>{translateLevel(userAbove.level, userData?.corpId)}</p>
              </div>
            </div>}
          <Row>
            {/* <Col lg={6} md={6} sm={12} xs={12}>
              <InputMuiField
                label="Título"
                onChange={(event) => setTitle(event.target.value)}
                error={errors.title}
                value={title}
              >
                Título
              </InputMuiField>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <InputMuiField
                label="Subtítulo"
                onChange={(event) => setSubtitle(event.target.value)}
                error={errors.subtitle}
                value={subtitle}
              >
                Subtítulo
              </InputMuiField>
            </Col> */}
            <Col lg={12} md={12} sm={12} xs={12}>
              <TextField
                label="Mensagem"
                onChange={(event) => setMessage(event.target.value)}
                error={errors.message}
                value={message}
                multiline
                rows={4}
                fullWidth
                color="primary"
                variant="outlined"
                className="input-background"
                inputProps={{ maxLength: 1000 }}
              >
                Mensagem
              </TextField>
              {errors.message && <Error hasError={!!(errors.message)}>{errors.message}</Error>}
            </Col>
          </Row>

          <Row style={{ marginTop: '20px' }}>
            <Col lg={4} md={12} sm={12} xs={12}>
              <Button
                onClick={() => { createCommunication() }}
              >
                Enviar
              </Button>
            </Col>
          </Row>

        </DialogContent>
      </Dialog>
    </>
  )
}

export default CommunicationModal