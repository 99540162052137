import React, { useEffect, useState } from 'react'
import Chart from 'chart.js';
// eslint-disable-next-line
import 'chartjs-plugin-funnel'

const Funnel = ({data, height, options}) => {
    const chartRef = React.createRef()
    const [context, setContext] = useState()

    useEffect(() => {
        if(chartRef.current){
            const opts = {...options, sort: 'desc',bottomWidth:250, plugins: {datalabels: false}}
            setContext(chartRef?.current?.getContext("2d"))
            if(context){
                new Chart(context, {type: 'funnel', data, options: opts})
            }
        }
    }, [data, options, height, context])

    //if(!data || !data.datasets || !data.datasets.labels) return <em>Nenhum dado para ser exibido</em>
    return <div style={{height: height, pointerEvents: 'none'}}> <canvas height={height} ref={chartRef} id="ChartJs-Funnel-Chart"/> </div>
}

export default Funnel