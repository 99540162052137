import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-Direction: column;
  align-Items: flex-start;
  width: 100%;
  padding-Left: 30px;
  padding-right: ${props => props.noPadding ? '0px' : '40%' };

  h3 {
    margin-bottom: 20px;
    color: rgb(50, 50, 159);
    font-weight: 400;
  }

  p {
    color: rgb(51, 51, 51);
  }

  .theme {
    margin-bottom: 30px;
  } 

  h4 {
    margin: 0;
    margin-bottom: 10px;
  }

  h5 {
    color: rgba(38, 50, 56, 0.5);
    width: 95%;
    border-bottom: 1px solid rgba(38, 50, 56, 0.3);
    text-transform: uppercase;
    font-size: 1em;
  }

  .center-icon{
    width:100%;
    display:flex;
    justify-Content:center;
    align-Items: center;
  }

  @media(max-width: 1000px) {
    padding-right: 0;
  }
`

export const ApiCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgb(38, 50, 56); 
  color: #fff;
  padding: 40px;

  .route {
    background-color: rgb(17, 23, 26);
    width: 100%;
    height: 50px; 
    display: grid;
    grid-template-columns: 1fr auto;

    div {
      background-color: rgb(17, 23, 26);
      border: none;
      width: 100%;
      margin: 0;
      overflow: auto;
      /* word-break: keep-all; */
      white-space: nowrap;
      display: flex;
      align-items: center;
      
      &::-webkit-scrollbar-thumb {
        background-color: hsla(0,0%,100%,.16);
      }

      &::-webkit-scrollbar {
        width: 6px!important;
        height: 6px!important;
        border-radius: 10px;
      }
    }

    button {
      background-color: rgb(17, 23, 26);
      border: none;
      border-left: 1px solid #e2e2e2;
      outline: none;
      height: 75%;
      margin: auto;
      color: #e2e2e2;
    }
  }  

  .response {
    width: 100%;
    height: 100%;
    background-color: rgb(17, 23, 26);
    margin-top: 20px;
    padding: 30px;

    img {
      width: 100%;
      height: 100%;
    }
  }
`

export const Column = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.8fr;
  margin-bottom: 20px;

  @media(max-width: 1000px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr, 1fr;
    div {
      margin-top: 20px;
    }
  }
`

export const Loading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-Direction: column;
  justify-Content: center;
  align-Items: center;

  .loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 10px;
    height: 40px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`

export const Token = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  height: 100%;
  background-color: rgb(17, 23, 26);
  color: #fff;
  padding:10px;

  div {
      background-color: rgb(17, 23, 26);
      width: 100%;
      border: none;
      margin: 0;
      overflow: auto;
      /* word-break: keep-all; */
      white-space: nowrap;
      display: flex;
      align-items: center;
      
      &::-webkit-scrollbar-thumb {
        background-color: hsla(0,0%,100%,.16);
      }

      &::-webkit-scrollbar {
        width: 6px!important;
        height: 6px!important;
        border-radius: 10px;
      }

        button {
        background-color: rgb(17, 23, 26);
        border: none;
        border-left: 1px solid #e2e2e2;
        outline: none;
        height: 75%;
        margin: auto;
        color: #e2e2e2;
      }
    }
`

export const CallbackForm = styled.div`
  display:flex;
  grid-template-columns: 1fr !important;
  flex-direction: row;
  justify-Content:center;
  width:100%;
  
  .callback-form{
    display: block;
    width:100%;
  }

  span{
    margin: 0;
    padding-right:5px;
  }

  .row-url{
    display: grid;
    justify-content: center;
    align-items: center;
    width:100%;
    padding: 5px;
    grid-template-columns: 10% 10% 80%
  }
`

export const Params = styled.div`
  display: grid;
  grid-template-columns: 200px auto;
  align-Items: center;

  .input-padding{
    padding: 5px;
  }
`
