import React, { useState, useRef, useEffect } from "react";
import { TextField } from "@material-ui/core";
import { Grid, Row, Col } from "react-bootstrap";
import mainApi from "services/mainApi";
import SweetAlert from "sweetalert2";
import to from "await-to-js";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Card from "components/Card/Card";
import swal from 'components/Alert/alert'

import { shotenerProps } from 'utils/translate'
import getUserData from "utils/checkUser.js"
import spinner from "assets/img/spinner.gif";

import { formatToBrConditional } from 'utils/formaterDate'


const Shortener = (Props) => {
  const [searchedLink, setSearchedLink] = useState('')
  const [link, setLink] = useState('');
  const [shortenInfo, setShortenInfo] = useState();
  const [shortenKeys, setShortenKeys] = useState([]);
  const [blockedBy, setBlockedBy] = useState();
  const [unBlockedBy, setUnBlockedBy] = useState();
  const [loading, setLoading] = useState(false)
  const [userData, setUserData] = useState({})

  const inputRef = useRef(null)

  async function UserData() {
    const userData = await getUserData(Props.history);
    setUserData(userData)
  };

  async function handleLink() {
    if (link === searchedLink) return
    setShortenInfo()
    setLoading(true)
    try {
      const response = await mainApi.post(`/shorteninfo/inspect`, { link });
      let shortenInfo
      if (!response) {
        swal.basic(
          "error",
          "Não foi possível se comunicar com o servidor, tente novamente mais tarde."
        );
      } else {
        shortenInfo = response.data.data.shortenInfo
      }

      console.log(response.data.data.shortenInfo)

      if (!shortenInfo)
        swal.basic(
          "warning",
          "Ateção",
          "O link pesquisado não foi encontrado."
        )
      else {
        handleProps(shortenInfo)
      }

      setLoading(false)
    } catch (err) {
      setLoading(false)
      if (err?.response?.data?.message) {
        swal.basic(
          "warning",
          "Ateção",
          err.response.data.message
        )
      }
    } finally {
      setSearchedLink(link)
    }
  }

  useEffect(() => {
    handleLink()
  }, [searchedLink])

  function handleProps(shortenInfo) {
    setBlockedBy(shortenInfo.blockedBy);
    setUnBlockedBy(shortenInfo.unblockedBy);
    if (shortenInfo) setShortenKeys(Object.keys(shortenInfo).filter(key => ![
      '_id', 'DATA_CRIACAO', 'HASH', 'ID'
    ].includes(key)));
    setShortenInfo(shortenInfo);
    console.log(shortenInfo, shortenKeys)
  }

  async function handleBlock(_, commit) {
    if (commit) {
      const [err, response] = await to(mainApi.post(`/shorteninfo/block`, { shortenId: shortenInfo.ID }))
      console.log({ err, response })
      if (err) {
        SweetAlert.fire('Ops!', err?.data?.message || "Erro Desconhecido", 'warning')
        return
      }
      SweetAlert.fire('Sucesso!', response.data.message, 'success')
      setSearchedLink('')
      return;
    }
    SweetAlert.fire({
      icon: "question",
      title: "Bloqueio de link",
      text: `Tem certeza de que deseja cancelar o link de ${shortenInfo.LOJA} (${shortenInfo.CORP || ''})}?`,
      showCancelButton: true,
      confirmButtonText: "Sim, bloquear!",
      cancelButtonText: "Não, voltar",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) handleBlock(null, true);
    });
  }

  async function handleUnBlock(_, commit) {
    if (commit) {
      const [err, response] = await to(mainApi.post(`/shorteninfo/unblock`, { shortenId: shortenInfo.ID }))
      if (err) {
        SweetAlert.fire('Ops!', err?.data?.message || "Erro Desconhecido", 'warning')
        return
      }
      SweetAlert.fire('Sucesso!', response.data.message, 'success')
      setSearchedLink('')
      return;
    }
    SweetAlert.fire({
      icon: "question",
      title: "Desbloqueio de link",
      text: `Tem certeza de que deseja reativar o link de ${shortenInfo.LOJA}  (${shortenInfo.CORP || ''})}?`,
      showCancelButton: true,
      confirmButtonText: "Sim, desbloquear!",
      cancelButtonText: "Não, voltar",
      cancelButtonColor: "#d33",

    }).then((result) => {
      if (result.isConfirmed) handleUnBlock(null, true);
    });
  }

  useEffect(() => {
    UserData()
  }, [])

  useEffect(() => {
    console.log(shortenInfo)
  }, [shortenInfo])
  return (
    <div className="content card" style={{ margin: 10 }}>
      <div className="container-fluid ">
        <Grid fluid style={{ paddingInline: "15px" }}>
          <Row className="filters">
            <Col lg={6}>
              <TextField
                fullWidth
                label="Digite seu link"
                color="primary"
                variant="outlined"
                inputRef={inputRef}
                onChange={(e) => {
                  if (typeof e.target.value === 'string')
                  setLink(e.target.value);
                }}
                style={{ backgroundColor: "white" }}
              />
            </Col>
            <Col md={2}>
              <button
                className="btn btn-fill btn-mlm"
                style={{ width: "100%" }}
                onClick={handleLink}
              >
                Pesquisar
              </button>
            </Col>
          </Row>
          {
            Boolean(loading) &&
            <Row>
              <Col style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
                <img src={spinner} alt="" />
              </Col>
            </Row>
          }
          {
            Boolean(shortenInfo) &&
            <Row>
              <Col lg={3} md={4} sm={6} xs={12} style={{minWidth: 'fit-content'}}>
                <Card
                  style={{ marginTop: 30 }}
                  titleStyle={{ maxWidth: 500 }}
                  title={`${shortenInfo.LOJA} (${shortenInfo.CORP || ''})`}
                  hCenter={true}
                  content={
                    <>
                      <Table >
                        <TableBody>
                          {shortenKeys.map((key) => ((Boolean(shortenInfo[key]) || typeof shortenInfo[key] === 'number') && typeof shortenInfo[key] !== 'object') && (
                            <TableRow key={key}>
                              <TableCell style={{ fontWeight: 'bold' }}>{shotenerProps(key)}</TableCell>
                                <TableCell>{shortenInfo[key]}</TableCell>
                            </TableRow>
                          ))}
                          <TableRow key={'BLOQUEADO'}>
                            <TableCell style={{ fontWeight: 'bold' }}>{shotenerProps('BLOQUEADO')}</TableCell>
                            <TableCell>{shortenInfo.BLOQUEADO ? 'Sim' : 'Não'}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      {
                        Boolean(userData) && ['master', 'submaster'].includes(userData.level) &&
                        <Row style={{ marginTop: 10, paddingRight: 10 }}>
                          <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <button
                              className={`btn btn-fill btn-mlm ${!shortenInfo.BLOQUEADO && 'btn-mlm-danger'}`}
                              onClick={shortenInfo.BLOQUEADO ? handleUnBlock : handleBlock}
                            >
                              {shortenInfo.BLOQUEADO ? 'Desbloquear' : 'Bloquear'}
                            </button>
                          </Col>
                        </Row>
                      }
                    </>
                  }
                />
              </Col>
              {Boolean(userData) && !['corporation', 'subcorp'].includes(userData.level) &&
                <>
                  {Boolean(blockedBy && blockedBy.length) && <Col lg={3} md={4} sm={6} xs={12} style={{minWidth: 'fit-content'}}>
                    <Card
                      style={{ marginTop: 30 }}
                      title={`Histórico de bloqueios`}
                      hCenter={true}
                      content={
                        <>
                          <Table >
                            <TableHead>
                              <TableRow>
                                <TableCell>Usuário</TableCell>
                                <TableCell>Data de bloqueio</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {Boolean(blockedBy) && blockedBy.map(block =>
                                <TableRow>
                                  <TableCell>{block.name ? block.name : '-'}</TableCell>
                                  <TableCell>{formatToBrConditional(block.blockedAt)}</TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </>
                      }
                    />
                  </Col>}

                  {Boolean(unBlockedBy && unBlockedBy.length) && <Col lg={3} md={4} sm={6} xs={12} style={{minWidth: 'fit-content'}}>
                    <Card
                      style={{ marginTop: 30 }}
                      title={`Histórico de desbloqueios`}
                      hCenter={true}
                      content={
                        <>
                          <Table >
                            <TableHead>
                              <TableRow>
                                <TableCell>Usuário</TableCell>
                                <TableCell>Data de desbloqueio</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {unBlockedBy.map(block =>
                                <TableRow>
                                  <TableCell>{block.name ? block.name : '-'}</TableCell>
                                  <TableCell>{formatToBrConditional(block.unblockedAt)}</TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </>}
                    />
                  </Col>}
                </>
              }
            </Row>
          }
        </Grid>
      </div >
    </div >
  );
};

export default Shortener;
