import styled from 'styled-components'

export const Content = styled.div `
background-color: transparent;
box-shadow: none;
border-radius: 0; 

width:100%;
height:100%;

	.card-content{
		width:100%;
		height:100%;
		border-radius: 6px;
		background-color: white;
	}
`