import TermsModal from 'components/TermsModal/TermsModal'
import React from 'react'
import MahoeIcon from "components/MahoeIcons";

function ClubeAmigoIntructions() {

  return (
    <>

      <div className="clube-amigo-signup">
        {/* <h1>Amizade boa é aquela em que os dois lados saem ganhando!</h1> */}
        {/* <h2>Indique seus amigos e ganhe um prêmio por cada um que contratar os produtos do PAN.</h2> */}
        {/*
        <div>
          <div>
            <strong> R$50</strong>
            <p>Em voucher, por indicação que contratar o EP FGTS ou Empréstimo Consignado Margem livre do PAN</p>
          </div>

          <div>
            <strong>R$25</strong>
            <p>Em voucher, por indicação que contratar o Cartão Consignado com Saque Complementar do PAN</p>
          </div>
        </div>
         */}

        <h1>Você tá muito perto <wbr/>de ganhar seu prêmio!</h1>
        <h2>Para cada pessoa que contratar um dos produtos do PAN com a sua indicação, você recebe um prêmio.</h2>
        <div>
          <div>
            <span style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
              <strong>R$50</strong><span style={{ fontSize: 16 }}>EM<br/> VOUCHER</span>
            </span>
            <p>Por indicação que contratar o Empréstimo Pessoal FGTS ou Empréstimo Consignado Margem Livre do PAN.</p>
          </div>

          <div>
            <span style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
              <strong>R$25</strong><span style={{ fontSize: 16 }}>EM<br/> VOUCHER</span>
            </span>
            <p>Por indicação que contratar o Cartão Consignado com Saque Complementar do PAN.</p>
          </div>
        </div>

        <span className="arrow">
          <MahoeIcon
            name="arrow-go"
            fill="#FFF"
            size="32px"
            className="go-arrow"
            style={{ margin: 5 }}
            onClick={() => (window.location.href = "#signup")}
          />
        </span>

        <TermsModal />

      </div>
    </>
  )
}

export default ClubeAmigoIntructions
