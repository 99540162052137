import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleDown, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom';
import MahoeIcon from "components/MahoeIcons";

const disabledStyle = {
  color: 'rgba(1,1,1,1,0.3)',
  opacity: '0.4',
  cursor: 'not-allowed'
}

const activeRoute = (actualRoute, routeName) =>{    
  if(!actualRoute) return ""
  const actualRoutePaths = actualRoute.split('/')
  const routePaths = routeName.split('/')
  const params = routePaths.filter(path => path[0] === ':')
  let route = routeName;

  if (params.length > 0 && actualRoutePaths.length === routePaths.length && params) {
    params.forEach((path, index) => {
      const paramPos = routePaths.indexOf(path)
      route = route.replace(path, actualRoutePaths[paramPos])
    })
  }    

  return route === actualRoute
    ? "active"
    : "";
}

const removeBodyClick = () => {

  if (document.documentElement.classList.contains("nav-open")) {
    let node = document.getElementById("bodyClick");
    document.body.removeChild(node);
  }

};

export default function NavbarLink({ config, actualRoute}) {
  const { layout, path, disabled, key, icon, name} = config

  return (
      <li
        className={activeRoute(actualRoute, layout + path)}
        key={key}
      >
        <NavLink
          to={disabled ? '#' : layout + path}
          className="nav-link"
          activeClassName="active"

          onClick={() => {
            removeBodyClick()
          }}

          disabled={disabled}
          style={disabled ? disabledStyle : {}}
        >
          <MahoeIcon
            name={icon}
            size="20px"
            fill={
              activeRoute(actualRoute, layout + path) ===
                "active"
                ? "rgba(255,255,255,1)"
                : "rgba(255,255,255,0.8)"
            }
          />
          <p>{name}</p>
        </NavLink>
      </li>
  )
}
