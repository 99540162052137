import React, { Component } from 'react'
import MahoeIcons from 'components/MahoeIcons'

class LinkVoltar extends Component{  
  render(){

    return (
      <div style={{ marginBottom: '10px' }}>
        <button className="go-back-link" style={{ border: 0, backgroundColor: 'transparent' }} onClick={() => window.history.back()}>
          <MahoeIcons name='arrow-back' fill='#0277bd' style={{ width: '15px', height: '15px', margin: '-4px 2px' }} /> 
          Voltar
        </button>
      </div>
    )
  }
}

export default LinkVoltar