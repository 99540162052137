import React, { useState, useRef, useEffect, Component } from "react";
import { TextField } from "@material-ui/core";
import { Grid, Row, Col } from "react-bootstrap";
import mainApi from "services/mainApi";
import SweetAlert from "sweetalert2";
import to from "await-to-js";
import high from './../../assets/img/high.png';
import Select from "react-select";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Card from "components/Card/Card";
import swal from 'components/Alert/alert'

import { shotenerProps } from 'utils/translate'
import getUserData from "utils/checkUser.js"
import spinner from "assets/img/spinner.gif";

import Button from "components/CustomButton/CustomButton.jsx"
import MuiMonthSelect from "components/MuiMonthSelect";
import { getDatesList } from "utils/formatData";

import { formatToBrWithHour } from 'utils/formaterDate'
import { CardTitle } from "material-ui";


const Shortener = (Props) => {
  const [link, setLink] = useState('');
  const [searchedLink, setSearchedLink] = useState('')
  const [shortenInfo, setShortenInfo] = useState();
  const [shortenKeys, setShortenKeys] = useState([]);
  const [blockedBy, setBlockedBy] = useState();
  const [unBlockedBy, setUnBlockedBy] = useState();
  const [loading, setLoading] = useState(false)
  const [downloading, setDownloading] = useState(false)
  const [userData, setUserData] = useState({})
  const [status, setStatus] = useState()
  const [dateList, setDateList] = useState(getDatesList('2020-08'))
  const [products, setProducts] = useState()
  const [selectedProduct, setSelectedProduct] = useState()
  const [selectedDate, setSelectedDate] = useState(dateList[0])

  const top1000ReportButtonClick = async (howToBeSent) => {
    let result = { isConfirmed: true }
    if (howToBeSent === 'email')
      await SweetAlert.fire({
        title: 'Atenção?',
        text: 'O relatório será enviado para o e-mail cadastrado em sua conta irreversivelmente. Confirma?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, solicitar',
        cancelButtonText: 'Cancelar'
      }).then(async (v) => {
        result = v
      })

    if (result.isConfirmed) {
      if (!selectedDate || !selectedDate.value) {
        SweetAlert.fire('Aviso', 'Por favor selecione uma data', 'warning')
        return
      }

      if (!selectedProduct || !selectedProduct.value) {
        SweetAlert.fire('Aviso', 'Por favor selecione um produto', 'warning')
        return
      }

      const [month, year] = selectedDate.value.split('-')
      const productId = selectedProduct.value

      const body = { report: 'top-links' }
      let query = `&report=top-links`

      if (month) {
        body.month = month
        query += `&month=${month}`
      }
      if (year) {
        body.year = year
        query +=`&year=${year}`
      }
      if (productId) {
        body.productId = productId
        query += `&productId=${productId}`
      }
      if (howToBeSent) {
        body.howToBeSent = howToBeSent
        query += `&howToBeSent=${howToBeSent}`
      }

      try {
        if (howToBeSent === 'download') {
          let alreadyHasFile = await checkForGeneratedFiles(query)
          if(alreadyHasFile) {
            setDownloading(true)
            setStatus('Preparando...')

            const generateRequestResponse = await mainApi.post(`/reports/top-links`, body)
            let responseData = generateRequestResponse?.data?.data

            if(responseData?.generatedReportRequestId) {
              if (!body.generatedReportRequestId){
                query += `&generatedReportRequestId=${responseData.generatedReportRequestId}`
                body.generatedReportRequestId = responseData.generatedReportRequestId
              }
            }

            waitForFile(query)
          }
        } else {
          const response = await mainApi.post(`/reports/top-links`, body);
          SweetAlert.fire('Sucesso!', response.data?.message, 'success')
          console.log(response)
        }


      } catch (err) {
        SweetAlert.fire('Ops!', err?.data?.message || "Erro Desconhecido", 'warning')
        console.warn(err);
        return
      }

    }

  }

  useEffect(() => {
    handleLink()
  }, [searchedLink])

  async function checkForGeneratedFiles (query) {
    if(query) {
      let response = await mainApi.get(`/reports/check?${query}`)
      let responseData = response?.data?.data
      let { fileUrl, loading, status } = responseData
      setStatus(status)
      setDownloading(loading)

      if(fileUrl) {
        downloadFile(fileUrl)
        setLoading(false)
        query = ''
        loading = false
      }
      else{
        loading = true
      }
      return loading
    }

  }

  function downloadFile (fileUrl) {
    let a = document.createElement("a")
    a.href = fileUrl
    // a.target = '_blank'
    a.download = fileUrl
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a);
    // window.open(fileUrl, "_self")
    setLoading(false)
  }

  async function waitForFile(query) {
    clearInterval()
    let loading = true
    setInterval(async () => {
      if (loading) loading = await checkForGeneratedFiles(query)
      else clearInterval()
    }, 5000)
  }

  function clearInterval () {
    var interval_id = window.setInterval('', 9999)
    for (var i = 1; i < interval_id; i++) window.clearInterval(i)
  }

  const onDateSelect = date => {
    setSelectedDate(date)
    console.log(date)
  };

  const inputRef = useRef(null)

  async function UserData() {
    const userData = await getUserData(Props.history);
    setProducts(userData.products)
    setUserData(userData)
  };

  async function handleLink() {
    if (link === searchedLink) return
    setShortenInfo()
    setLoading(true)
    try {
      const response = await mainApi.post(`/shorteninfo/inspect`, { link });
      let shortenInfo
      if (!response) {
        swal.basic(
          "error",
          "Não foi possível se comunicar com o servidor, tente novamente mais tarde."
        );
      } else {
        shortenInfo = response.data.data.shortenInfo
      }

      if (!shortenInfo)
        swal.basic(
          "warning",
          "Ateção",
          "O link pesquisado não foi encontrado."
        )
      else {
        handleProps(shortenInfo)
      }

      setLoading(false)
    } catch (err) {
      setLoading(false)
      if (err?.response?.data?.message) {
        swal.basic(
          "warning",
          "Ateção",
          err.response.data.message
        )
      }
    } finally {
      setSearchedLink(link)
    }
  }

  function handleProps(shortenInfo) {
    setBlockedBy(shortenInfo.blockedBy);
    setUnBlockedBy(shortenInfo.unblockedBy);
    if (shortenInfo) setShortenKeys(Object.keys(shortenInfo).filter(key => ![
      '_id', 'DATA_CRIACAO', 'HASH', 'ID'
    ].includes(key)));
    setShortenInfo(shortenInfo);
  }

  async function handleBlock(_, commit) {
    if (commit) {
      const [err, response] = await to(mainApi.post(`/shorteninfo/block`, { shortenId: shortenInfo.ID }))
      console.log({ err, response })
      if (err) {
        SweetAlert.fire('Ops!', err?.data?.message || "Erro Desconhecido", 'warning')
        return
      }
      SweetAlert.fire('Sucesso!', response.data.message, 'success')
      setSearchedLink('')
      return;
    }
    SweetAlert.fire({
      icon: "question",
      title: "Bloqueio de link",
      text: `Tem certeza de que deseja cancelar o link de ${shortenInfo.LOJA} (${shortenInfo.CORP || ''})}?`,
      showCancelButton: true,
      confirmButtonText: "Sim, bloquear!",
      cancelButtonText: "Não, voltar",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) handleBlock(null, true);
    });
  }

  async function handleUnBlock(_, commit) {
    if (commit) {
      const [err, response] = await to(mainApi.post(`/shorteninfo/unblock`, { shortenId: shortenInfo.ID }))
      if (err) {
        SweetAlert.fire('Ops!', err?.data?.message || "Erro Desconhecido", 'warning')
        return
      }
      SweetAlert.fire('Sucesso!', response.data.message, 'success')
      setSearchedLink('')
      return;
    }
    SweetAlert.fire({
      icon: "question",
      title: "Desbloqueio de link",
      text: `Tem certeza de que deseja reativar o link de ${shortenInfo.LOJA}  (${shortenInfo.CORP || ''})}?`,
      showCancelButton: true,
      confirmButtonText: "Sim, desbloquear!",
      cancelButtonText: "Não, voltar",
      cancelButtonColor: "#d33",

    }).then((result) => {
      if (result.isConfirmed) handleUnBlock(null, true);
    });
  }

  useEffect(() => {
    UserData()
  }, [])

  useEffect(() => {
    console.log(shortenInfo)
  }, [shortenInfo])

  return (
    <div className="content card" style={{ margin: 10 }}>
      <div className="container-fluid ">
        <Grid fluid style={{ paddingInline: "15px" }}>
          <Row>
            <Col md={12}>
              <div className="card" style={{ padding: '0px 20px 50px 20px' }}>
                <h4 style={{ marginBottom: '20px' }}>
                  Top 1000 Links mais vendidos
                </h4>
                <Row>
                  <Col md={1} style={{ minWidth: 75, maxWidth: 75, marginBottom: '.5em', marginLeft: 10 }}>
                    <img className="img-rounded" src={high} style={{ maxHeight: '100%', maxWidth: '100%', marginLeft: 'auto', marginRight: 'auto' }} />
                  </Col>
                  <Col lg={2} md={4} style={{ minWidth: 205 }}>
                    <Row style={{ margin: '0 10px 10px 10px' }}>
                      <MuiMonthSelect className="custom-select" placeholder='Selecione o mês' month={selectedDate} size="lg" onMonthSelect={onDateSelect} availableDates={dateList} />
                    </Row>
                  </Col>
                  <Col lg={2} md={4} style={{ minWidth: 205 }}>
                    <Row style={{ margin: '0 10px 10px 10px' }}>
                      <Select
                        disabled={loading}
                        className="custom-select"
                        placeholder="Selecione um Produto"
                        options={products}
                        value={selectedProduct}
                        onChange={(product) => {
                          setSelectedProduct(product)
                        }}
                      />
                    </Row>
                  </Col>
                  <Col lg={6} md={12} style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                    <Col md={6} style={{maxWidth: '100%' , marginBottom: '.5em', flexGrow: 1 }}>
                      <Row style={{ margin: "0 10px" }}>
                        <Button bsStyle="primary" fill style={{ width: "100%" }} onClick={() => top1000ReportButtonClick('email')}>
                          <i aria-hidden="true" className="af-button-icon fa fa-envelope"></i>&nbsp;
                          Solicitar por e-mail
                        </Button>
                      </Row>
                    </Col>
                    <Col md={6} style={{maxWidth: '100%' , flexGrow: 1 }}>
                      <Row style={{ margin: "0 10px" }}>
                        <Button
                          bsStyle="primary"
                          fill style={{ width: "100%" }}
                          onClick={() => top1000ReportButtonClick('download')}
                          disabled={downloading}
                        >
                        {downloading
                          ? <> <i aria-hidden="true" className="af-button-icon fa fa-spinner fa-spin"></i> {status} </>
                          : <> <i aria-hidden="true" className="af-button-icon fa fa-download"></i> Baixar </>
                        }
                        </Button>
                      </Row>
                    </Col>
                  </Col>

                </Row>
              </div>
            </Col>
          </Row>

          <div className="card" style={{ padding: '0px 20px 50px 20px' }}>

            <h4 style={{ marginBottom: '20px' }}>
              Busca de Links
          </h4>

            <Row className="filters">

              <Col lg={6} style={{marginBottom: '.5em'}}>
                <TextField
                  fullWidth
                  label="Digite seu link"
                  color="primary"
                  variant="outlined"
                  inputRef={inputRef}
                  onChange={(e) => {
                    if (typeof e.target.value === 'string')
                    setLink(e.target.value);
                  }}
                  style={{ backgroundColor: "white" }}
                />
              </Col>
              <Col md={2}>
                <button
                  className="btn btn-fill btn-mlm"
                  style={{ width: "100%", minWidth: 150}}
                  onClick={handleLink}
                >
                  Pesquisar
              </button>
              </Col>
            </Row>
            {
              loading &&
              <Row>
                <Col style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
                  <img src={spinner} alt="" />
                </Col>
              </Row>
            }
            {
              Boolean(shortenInfo) &&
              <Row>
                <Col lg={3} md={4} sm={6} xs={12} style={{minWidth: 'fit-content'}}>
                  <Card
                    style={{ marginTop: 30 }}
                    titleStyle={{ maxWidth: 500 }}
                    title={`${shortenInfo.LOJA} (${shortenInfo.CORP || ''})`}
                    hCenter={true}
                    content={
                      <>
                        <Table >
                          <TableBody>
                            {shortenKeys.map((key) => (
                              (Boolean(shortenInfo[key]) || typeof shortenInfo[key] === 'number') && typeof shortenInfo[key] !== 'object') && (
                              <TableRow key={key}>
                                <TableCell style={{ fontWeight: 'bold' }}>{shotenerProps(key)}</TableCell>
                                <TableCell>{shortenInfo[key]}</TableCell>
                              </TableRow>
                            ))}
                            <TableRow key={'BLOQUEADO'}>
                              <TableCell style={{ fontWeight: 'bold' }}>{shotenerProps('BLOQUEADO')}</TableCell>
                              <TableCell>{shortenInfo.BLOQUEADO ? 'Sim' : 'Não'}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                        {
                          Boolean(userData) && ['master', 'submaster'].includes(userData.level) &&
                          <Row style={{ marginTop: 10, paddingRight: 10 }}>
                            <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <button
                                className={`btn btn-fill btn-mlm ${!shortenInfo.BLOQUEADO && 'btn-mlm-danger'}`}
                                onClick={shortenInfo.BLOQUEADO ? handleUnBlock : handleBlock}
                              >
                                {shortenInfo.BLOQUEADO ? 'Desbloquear' : 'Bloquear'}
                              </button>
                            </Col>
                          </Row>
                        }
                      </>
                    }
                  />
                </Col>
                {Boolean(userData && !['corporation', 'subcorp'].includes(userData.level)) &&
                  <>
                    {Boolean(blockedBy && blockedBy.length) && <Col lg={3} md={4} sm={6} xs={12} style={{ minWidth: 'fit-content' }}>
                      <Card
                        style={{ marginTop: 30 }}
                        title={`Histórico de bloqueios`}
                        titleStyle={{maxWidth: '80%', margin: '0 auto'}}
                        hCenter={true}
                        content={
                          <>
                            <Table >
                              <TableHead>
                                <TableRow>
                                  <TableCell>Usuário</TableCell>
                                  <TableCell>Data de bloqueio</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {blockedBy && blockedBy.map(block =>
                                  <TableRow>
                                    <TableCell>{block.name ? block.name : '-'}</TableCell>
                                    <TableCell>{formatToBrWithHour(block.blockedAt)}</TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </>
                        }
                      />
                    </Col>}

                    {Boolean(unBlockedBy && unBlockedBy.length) && <Col lg={3} md={4} sm={6} xs={12} style={{ minWidth: 'fit-content' }}>
                      <Card
                        style={{ marginTop: 30 }}
                        title={`Histórico de desbloqueios`}
                        hCenter={true}
                        content={
                          <>
                            <Table >
                              <TableHead>
                                <TableRow>
                                  <TableCell>Usuário</TableCell>
                                  <TableCell>Data de desbloqueio</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {unBlockedBy.map(block =>
                                  <TableRow>
                                    <TableCell>{block.name ? block.name : '-'}</TableCell>
                                    <TableCell>{formatToBrWithHour(block.unblockedAt)}</TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </>}
                      />
                    </Col>}
                  </>
                }
              </Row>
            }
          </div>
        </Grid>
      </div >
    </div >
  );
};

export default Shortener;
