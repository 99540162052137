/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Navbar } from "react-bootstrap";
import MahoeIcon from "../MahoeIcons";

import AdminNavbarLinks from "./AdminNavbarLinks.jsx";

class Header extends Component {
  constructor(props) {
    super(props);
    this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
    this.updatePredicate = this.updatePredicate.bind(this);
    this.state = {
      sidebarExists: false,
      isMobile: false
    };
  }
  mobileSidebarToggle(e) {
    if (this.state.sidebarExists === false) {
      this.setState({
        sidebarExists: true
      });
    }
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function() {
      this.parentElement.removeChild(this);
      if(Boolean(node)) node.remove()
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  }

  buttonFunction = (index) => {
    const { buttons, history } = this.props    

    if (buttons[index].path) history.push(buttons[index].path)
  }

  componentDidMount = async () => {
    this.updatePredicate()
    window.addEventListener("resize", this.updatePredicate); 

    var evt = new CustomEvent('event-view-start');
    document.body.dispatchEvent(evt);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isMobile: window.innerWidth < 1000 });
  }

  render() {
    const { isMobile } = this.state
    const { buttons, brandText } = this.props        

    return (      
      <Navbar fluid style={{ display: 'contents' }}>
        <Navbar.Header style={{ display: 'flex' }}>
          <div style={isMobile ? { display: 'flex', flexDirection: 'column', marginLeft: '10px' } : { marginLeft: '15px', marginRight: '25px' }}>
          <Navbar.Brand>
            
            <div style={{ border: 0, background: 'transparent' }}>
              <div style={{ alignItems: "baseline", display: "flex" }}>
                <div>
                  <div style={isMobile ? {} : {margin: 0, width: 'auto', marginLeft: '15px'}} id="title-page-affiliate">
                  {brandText}
                  </div>
                  <div style={{ margin: 0 }}>
                    <MahoeIcon
                      name="picto"
                      fill="#FF5630"
                      size="8px"
                      style={{ width: "auto", marginLeft: 10 }}
                    />
                  </div>
                </div>
              </div>
            </div>
                        
          </Navbar.Brand>
          { buttons 
            ? <div className="flex-navbar-buttons" style={isMobile ? { justifySelf: 'center', alignSelf: 'start', marginTop: '10px', marginLeft: '20px' } : { justifySelf: 'end', alignSelf: 'center' }}>
              {buttons.map((button, index) => (                
                <button
                  key={index}
                  id={button.id || ''}
                  onClick={() => this.buttonFunction(index)}
                  className={`btn btn-mlm btn-round btn-fill btn-block table-cell-button ${isMobile ? 'mb-3' : ''}`}
                  style={ button.style || {} }
                >
                  { button.title }
                </button>                
              ))}                
              </div>
            : <></> 
          }
          </div>
         { isMobile ? <Navbar.Toggle onClick={this.mobileSidebarToggle} />  : <></> }
        </Navbar.Header>        
        <Navbar.Collapse>
          <AdminNavbarLinks />
        </Navbar.Collapse>        
      </Navbar>            
    );
  }
}

export default Header;
