import styled from 'styled-components'

export const Description = styled.tr`

td {
  padding: '10px 10px 10px 15px';
}

div {
  color: rgb(212, 31, 28);
  font-size: 0.9em;
  font-weight: normal;
}

span {
  color: rgb(102, 102, 102);
  font-size: 13px;
}
`