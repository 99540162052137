import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { formatAgencyData } from './formatData'
import BasicPopover from 'components/BasicPopover/BasicPopover'

function AgencyDetails({ agency }) {
  if (!agency) return <></>

  return (
    <Row >
      <Col className="card mb-1" >
        <div className="content">
          <h5>Loja</h5>
          <table className='support-table'>
            <tbody>
              {Object.entries(agency)?.map(([key, value]) => {
                let item = formatAgencyData({ key, value })
                if (!item) return
                return (
                  <tr key={item?.key} >
                    <th>{item?.key}</th>
                    <td>
                      {(String(item?.value).length > 25)
                        ? <BasicPopover
                          noWrap={false}
                          text={String(item?.value)}
                          buttonText={`${String(item?.value).slice(0,25)}...`}
                        />
                        : String(item?.value)
                      }
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </Col>
    </Row>
  )
}

export default AgencyDetails
