import React from 'react';
import spinner from "assets/img/spinner.gif";

function LoadingGif() {
  return (
    <div className="loading-table">
    <img src={spinner} alt="" />
    <h2>Carregando...</h2>
  </div>
  )
}

export default LoadingGif
