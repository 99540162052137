import Card from "components/Card/Card";
import React from "react";
import { HorizontalBar } from "react-chartjs-2";
import { graphOptions } from "./common";
import MahoeIcon from "components/MahoeIcons";


function TopFive({ name, category, data, onReload }) {
  return (
    <Card
      title={`Top 5 ${name}`}
      category={`Produto: ${category}`}
      content={
        data ? (
          <HorizontalBar
            data={data}
            height={300}
            options={{
              legend: {
                position: "bottom",
                align: "start",
                labels: { usePointStyle: true },
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      fontColor: "black",
                      padding: -5,
                      mirror: true,
                      beginAtZero: true,
                    },
                    stacked: true,
                  },
                ],
                xAxes: [
                  {
                    stacked: true,
                  },
                ],
              },
              responsive: true,
              maintainAspectRatio: false,
              ...graphOptions,
            }}
          />
        ) : (
          <div className="search-top-5-loading" style={{ display: 'flex', flexDirection: 'column', justifyContent:'center'}}>
            {
              onReload
                ? <MahoeIcon
                  aria-describedby={"iconTooltip"}
                  name="reload"
                  size="20px"
                  fill="#039BE5"
                  style={{ cursor: 'pointer' }}
                  onClick={() => onReload()}
                />
                : <MahoeIcon
                  aria-describedby={"iconTooltip"}
                  name="info"
                  size="20px"
                  fill="red"
                />
            }
            <h4 style={{marginTop:10}}>Falha ao buscar dados</h4>
          </div>
        )
      }
    />
  );
}

export default TopFive;
