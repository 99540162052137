import { numToBRL } from "utils/formatData"
import { pixType } from 'utils/translate'
import formater from 'utils/formater'

export const agenciesHeader = (hasManager) => [
  {
    name: "name",
    label: "Loja", 
    options: {
      filter: true,
      sort: true,
      hint: "Nome da loja.",
    }
  },
  {
    name: "administrator.name",
    label: "Nome do parceiro",
    options: {
      filter: true,
      sort: true,
      display: hasManager ? true : 'excluded',
      hint: "Nome do parceiro"
    }
  },
  {
    name: "administrator.code",
    label: "Código do parceiro",
    options: {
      filter: true,
      display: hasManager ? true : 'excluded',
      sort: true,
      hint: "Código do parceiro"
    }
  }, 
  {
    name: 'administrator.pix.type',
    label: 'Tipo PIX',
    options: {
      filter: false,
      sort: false,
      hint: 'Tipo de chave pix utilizado',
      customBodyRender: (value) => value ? pixType(value) : 'n/a'
    }
  },
  {
    name: 'administrator.pix.value',
    label: 'Chave PIX',
    options: {
      filter: false,
      sort: false,
      hint: 'Chave pix do vendedor',
      customBodyRender: (value) => value ? value : 'n/a'
    }
  },
  {
    name: "count",
    label: "Qtde. Vendas",
    options: {
      filter: true,
      sort: true,
      hint: "Quantidade total de propostas feita pela loja.",
    }
  },
  {
    name: "commission",
    label: "Bonificação",
    options: {
      filter: true,
      sort: true,
      hint: "Bonificação total da loja.",
      customBodyRender: value => numToBRL(value)
    }
  },
  {
    name: "total",
    label: "Vendido",
    options: {
      filter: true,
      sort: true,
      hint: "Valor total vendido pela loja.",
      customBodyRender: (value) =>  numToBRL(value)
    },
  },
];

export const adminHeader = (isConta, hasManager) => [
  {
    name: "_id.name",
    label: "Vendedor", 
    options: {
      filter: true,
      sort: true,
      hint: "Nome da loja.",
    }
  },  
  {
    name: "_id.cpf",
    label: "CPF",
    options: {
      filter: true,      
      sort: true,
      hint: "Cpf do vendedor",
      customBodyRender: (value) => {
        if(!value) return 'n/a'
        return formater.toCPF(value)
      }
    }
  }, 
  {
    name: '_id.pix.type',
    label: 'Tipo PIX',
    options: {
      filter: false,
      sort: false,
      hint: 'Tipo de chave pix utilizado',
      customBodyRender: (value) => value ? pixType(value) : 'n/a'
    }
  },
  {
    name: '_id.pix.value',
    label: 'Chave PIX',
    options: {
      filter: false,
      sort: false,
      hint: 'Chave pix do vendedor',
      customBodyRender: (value) => value ? value : 'n/a'
    }
  },
  {
    name: "count",
    label: "Qtde. Vendas",
    options: {
      filter: true,
      sort: true,
      hint: "Quantidade total de propostas feita pela loja.",
    }
  },
  {
    name: "commission",
    label: "Bonificação vendedor",
    options: {
      filter: true,
      sort: true,
      hint: "Bonificação total do vendedor.",
      customBodyRender: value => numToBRL(value)
    }
  },
  {
    name: "agencyCommission",
    label: hasManager ? "Bonificação parceiro" : "Bonificação loja",
    options: {
      filter: true,
      sort: true,
      hint: "Bonificação total do parceiro.",
      customBodyRender: value => numToBRL(value)
    }
  },
  {
    name: "total",
    label: "Vendido",
    options: {
      filter: true,
      sort: true,
      display: isConta ? "excluded" : true,
      hint: "Valor total vendido pelo vendedor.",
      customBodyRender: (value) =>  numToBRL(value)
    },
  },
]