import React from 'react'
import { formatToBrConditional } from 'utils/formaterDate'
import swal from 'components/Alert/alert'
import formats from 'utils/formater'

import { CopyToClipboard } from "react-copy-to-clipboard"
import BigTooltip from "components/BigTooltip"
import { OpenInBrowser, FileCopy, WhatsApp, Email, DeleteForever } from "@material-ui/icons"
import { IconButton } from "@material-ui/core"

import { MANAGEROPTIONS } from '../values'
import mainApi from 'services/mainApi'

import { typeManager, translateLevel } from 'utils/translate'

const inviteByEmail = async (email, link, level) => {    

  const data = {
    link,
    email,
    level
  }
  
  try {

    const response = await mainApi.post('manager/send/email', data)
    swal.basic('success', "Sucesso!", response.data.message)

  } catch(err) {
    // if(!err.response) return swal.basic('warning', "Atenção!", "Falha ao se comunicar com o servidor.")
    swal.basic('warning', "Atenção!", err.response.data.message)
  }    

}

const setWhatsLink = (link, cellphone, manager) => {    
  const translateManager = MANAGEROPTIONS.find(element => element.value === manager).label

  if(cellphone) {
    const rawPhone = cellphone.replace(/\D/g, "");

    let linkText = `Você recebeu uma indicação para o cargo de ${translateManager} do Programa de Afiliados Banco PAN. Clique no link para concluir: ${link}`    
    let whatsappLink = `https://api.whatsapp.com/send?phone=55${rawPhone}&text=${encodeURIComponent(linkText)}`
    
    return whatsappLink
  }
}

const findSpanElement = () => {
  const alreadyCreated = document.querySelector("#copy-text-element")
  if(alreadyCreated) alreadyCreated.remove()

  const span = document.createElement('span')
  span.setAttribute("id", "copy-text-element")   
  document.body.appendChild(span) 
  return span
}

const showCopyText = (event, element, text = 'Copiado !') => {         
  element.style.display = 'block'    
  element.style.top = (event.pageY + 15) + 'px';
  element.style.left = (event.pageX + 15) + 'px';
  element.innerHTML = text           
}

const hideCopyText = () => {    
  const element = findSpanElement()
  element.remove()
}

export const options = {
  selectableRows: false,
  rowsPerPage: 25,      
  filterType: "dropdown",
  responsive: "scrollMaxHeight",  
  serverSide: true,
  pagination: true,
  print: false,
  filter: false,
  search: false,
  download: false,
  rowsPerPageOptions: [],
  textLabels: {
    body: {
      noMatch: "Desculpe, nenhum dado corresponde a pesquisa"
    },
    pagination: {
      next: "Próxima Página",
      previous: "Pagina Anterior",
      rowsPerPage: "Linhas por página:",
      displayRows: "de"
    },
    toolbar: {
      search: "Procurar",
      downloadCsv: "Download CSV",
      print: "Imprimir",
      viewColumns: "Mostrar Colunas",
      filterTable: "Filtrar Tabela"
    },
    filter: {
      title: "FILTROS",
      reset: "limpar",
      all: "Todos"
    },
    viewColumns: {
      title: "Mostrar Colunas"
    }
  }  
}

export const columns = ({ deleteUser }) => [
  {
    name: "_id",
    label: "ID",
    options: {
      display: false,
      hint: 'Identificador do usuário.',      
    }
  },
  {
    name: "name",
    label: "Nome",
    options: {     
      sort: true,
      hint: 'Nome do usuário.',      
    }
  },
  {
    name: "level",
    label: "Nível",
    options: {     
      sort: true,
      hint: 'Nível do usuário no sistema.',      
      customBodyRender: value => {
        return typeManager(value)
      }
    }
  },
  {
    name: "email",
    label: "Email",
    options: {     
      sort: true,
      hint: 'Email do usuário.',      
    }
  },
  {
    name: "cellphone",
    label: "Celular",
    options: {     
      sort: true,
      hint: 'Celular do usuário.',      
      customBodyRender: (value) => {
        if(!value) return 'n/a'

        return formats.formatPhone(value)
      }
    }
  },
  {
    name: "createdAt",
    label: "Data cadastro",
    options: {     
      sort: true,
      hint: 'Data de cadastro do usuário.',
      customBodyRender: (value) => {
        return value ? formatToBrConditional(value) : value
      }
    }
  },
  {
    name: "inviterUser",
    label: "Convidado por",
    options: {
      display: true,
      hint: 'Usuário que convidou o gerente.',
      customBodyRender: (value) => {
        return value.name ? `${value.name} (${translateLevel(value.level)})` : 'Master /\nSubMaster'
      }
    }
  },  
  {
    name: "ddds",
    label: "DDDs",
    options: {     
      sort: false,      
      hint: 'DDDs que usuário está destinado.',
      customBodyRender: (value) => {                
        if(value.length > 1) return <button className="link-style" onClick={() => swal.text("DDDs", formats.separeteValuesByComma(value))}>Ver Mais</button>
        else return value
      }
    }
  },
  {
    name: "ufs",
    label: "UFs",
    options: {
      sort: false,
      hint: 'Estados que usuário está destinado.',      
      customBodyRender: (value) => {
        if(value.length > 1) return <button className="link-style" onClick={() => swal.text("Estados", formats.separeteValuesByComma(value))}>Ver Mais</button>
        else return value
      }
    }
  },
  {
    name: "",
    label: "Excluir",
    options: {
      sort: false,
      hint: "Botão para excluir usuário.",
      customBodyRender: (value, datas) => {
        
        return (
          <BigTooltip title="Excluir usuário." placement="top">
            <IconButton      
              style={{ transform: 'scale(1.25)' }}            
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => deleteUser({ 
                userId: datas.rowData[0],
                userName: datas.rowData[1],
                userLevel: typeManager(datas.rowData[2])
              })}
            >
              <DeleteForever />
            </IconButton>
          </BigTooltip>
        )
      }
    }
  },
  {
    name: "link",
    label: "Compartilhar link",
    options: {
      sort: false,
      hint: "Perdeu o link do usuário? Reenvie através dos icones correspondentes abaixo.",

      customBodyRender: (value, rowIndex) => {                            

        const LEVEL = rowIndex.rowData[2]
        const EMAIL = rowIndex.rowData[3]
        const CELLPHONE = rowIndex.rowData[4]
        const WHATSLINK = setWhatsLink(value, CELLPHONE, LEVEL)

        return (
          <div className="flex-row">
            <div>
              <CopyToClipboard
                text={value}                                           
              >
                <BigTooltip 
                  title="Copiar link" 
                  placement="top" 
                  onClick={(event) => {
                    const element = findSpanElement()
                    showCopyText(event, element)

                    setInterval(() => {                  
                      document.addEventListener('mousemove', (event) => {                    
                        showCopyText(event, element)
                      })      
                    }, 100);

                    setTimeout(() => {
                      hideCopyText()
                    }, 3000)
                  }}
                >
                  <IconButton    
                    style={{ transform: 'scale(1.25)' }}                
                    disabled={value === ''}                            
                  >
                    <FileCopy />
                  </IconButton>
                </BigTooltip>                                     
              </CopyToClipboard>    
            </div>

            <div className="mx-2">
              <BigTooltip title="Abrir link em uma nova aba" placement="top">
                <IconButton      
                  style={{ transform: 'scale(1.25)' }}            
                  target="_blank"
                  rel="noopener noreferrer"                          
                  href={value}
                  disabled={value === ''}
                >
                  <OpenInBrowser />
                </IconButton>
              </BigTooltip>
            </div>

            <div className="mr-2">
              <BigTooltip title="Enviar convite por whats app" placement="top">
                <IconButton
                  style={{ transform: 'scale(1.25)' }}
                  target="_blank"
                  rel="noopener noreferrer"                          
                  href={WHATSLINK}
                  disabled={!CELLPHONE}                            
                >
                  <WhatsApp />
                </IconButton>
              </BigTooltip>
            </div>

            <div>
              <BigTooltip title="Enviar email novamente." placement="top">
                <IconButton
                  style={{ transform: 'scale(1.25)' }}
                  onClick={(event) => inviteByEmail(EMAIL, value, LEVEL)}               
                >
                  <Email />
                </IconButton>
              </BigTooltip>
            </div>
          </div> 
        )
      }
    }
  }   
]