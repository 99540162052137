import React, { Component } from "react";

import "assets/css/styles.css";

import Button from "components/CustomButton/CustomButton.jsx";
import mainApi from "services/mainApi";
import { Dialog, LoadingAlert } from "components/Alert/CustomAlert";
import { TextField } from "@material-ui/core";
import { Link } from "react-router-dom";
import config from "utils/serverVars";
import clubeAmigoLogo from 'assets/img/clubeAmigo/clubeAmigoLogo.png'
import { ISClubeAmigo } from "utils/environment";

class NewPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmation: "",
      password: "",
      btnTitle: "Atualizar",
      showSwal: false,
      showLoading: false,
      swalMessage: "",
      swalTitle: "",
      swalType: "",
      swalAction: () => {},
    };
  }

  componentDidMount() {
    const { passwordResetToken } = this.props;
    if (passwordResetToken && passwordResetToken.length > 20)
      this.setState({ passwordResetToken });
    document.addEventListener(
      "keydown",
      this.handleKeyPressedOnDocument,
      false
    );
  }

  componentWillUnmount() {
    document.removeEventListener(
      "keydown",
      this.handleKeyPressedOnDocument,
      false
    );
  }

  handleKeyPressedOnDocument = (event) => {
    const { showLoading } = this.state;
    if (event.keyCode === 13 && !showLoading) this.handleSubmit();
  };

  showMessage = (type, title, text, action) => {
    const func = () => {};
    const swalAction = action || func;

    this.setState({
      showSwal: true,
      swalType: type,
      swalMessage: text,
      btnTitle: "Atualizar",
      swalTitle: title,
      swalAction,
    });
  };

  handleSubmit = async () => {
    this.setState({ btnTitle: "Carregando...", showLoading: true });
    const { password, confirmation } = this.state;

    if (!password) {
      this.showMessage("warning", "Insira sua nova senha");
      this.setState({ btnTitle: "Atualizar", showLoading: false });
      return;
    }
    if (!confirmation) {
      this.showMessage("warning", "Confirme sua nova senha");
      this.setState({ btnTitle: "Atualizar", showLoading: false });
      return;
    }

    if (password !== confirmation) {
      this.showMessage("warning", "A senha e a confirmação não conferem!");
      this.setState({ btnTitle: "Atualizar", showLoading: false });
      return;
    }

    try {
      const response = await mainApi.post(
        "/password/new",
        { password, confirmation }
      );
      this.setState({ showLoading: false });
      localStorage.setItem("@uxtech-mlm/UserResetPassword", false);
      this.showMessage("success", "Sucesso", response.data.message, () => {
        const level = localStorage.getItem("@uxtech-mlm/UserLevel");
        const analysis = localStorage.getItem("@uxtech-mlm/UserAnalysis");
        if (['corporation', 'subcorp', 'master', 'submaster'].includes(level)) {
          document.location = "/admin/dashboard";
        } else if (analysis && analysis !== "approved" && level === "seller") {
          document.location = "/preregister"
        } else {
          document.location = "/admin/dashboard";
        }
      });
    } catch (err) {
      this.setState({ btnTitle: "Atualizar", showLoading: false });
      if (!err.response) return;
      if (err.response.status === 422) {
        this.showMessage("warning", "Senha Fraca!", err.response.data.message);
        this.setState({ btnTitle: "Atualizar", showLoading: false });
        return;
      }
      if (err.response.data.message) {
        this.showMessage("warning", "", err.response.data.message);
        this.setState({ btnTitle: "Atualizar", showLoading: false });
      }
    }
  };

  render() {
    const {
      showSwal,
      swalMessage,
      swalTitle,
      showLoading,
      swalType,
      swalAction,
    } = this.state;
    const { path } = this.props;
    const logo = ISClubeAmigo ? clubeAmigoLogo: config.PRODUCT_LOGO;

    return (
      <div className="form-container">
        <LoadingAlert show={showLoading} />
        <Dialog
          type={swalType}
          text={swalMessage}
          show={showSwal}
          title={swalTitle}
          onConfirm={() => {
            this.setState({ showSwal: false });
            swalAction();
          }}
        />
        <img className="brand-logo" src={logo} alt="Uxtech Logo"></img>
        <h4 style={{ textAlign: "center", color: "#4c4c4c" }}>
          Você deve alterar sua senha para continuar.
        </h4>
        <form>
          <div className="form-group">
            <TextField
              label="Senha"
              InputProps={{
                classes: {
                  input: "mui-input-control",
                  root: "mui-input-control",
                },
              }}
              InputLabelProps={{
                classes: {
                  root: "mui-input-control mui-label ",
                },
              }}
              fullWidth
              variant="outlined"
              type="password"
              onChange={(event) => {
                this.setState({ password: event.target.value });
              }}
            />
          </div>
          <div className="form-group">
            <TextField
              label="Confimação de senha"
              InputProps={{
                classes: {
                  input: "mui-input-control",
                  root: "mui-input-control",
                },
              }}
              InputLabelProps={{
                classes: {
                  root: "mui-input-control mui-label ",
                },
              }}
              fullWidth
              variant="outlined"
              type="password"
              onChange={(event) => {
                this.setState({ confirmation: event.target.value });
              }}
            />
          </div>
          <Button
            fill
            block
            className="btn-login"
            onClick={this.handleSubmit}
            disabled={showLoading}
          >
            {this.state.btnTitle}
          </Button>
        </form>
        <div style={{ margin: "5% 0" }}>
          <Link className="signup-link " to={`/${path}`}>
            Voltar ao login
          </Link>
        </div>
        <a
          className="agx-link"
          href="https://www.agxsoftware.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          AGX Software © {new Date().getFullYear()}
        </a>
        <span style={{ marginBottom: 20 }}>
            <a
              className="optanon-show-settings optanon-toggle-display"
              style={{ cursor: "pointer" }}
            >
              Cookies que utilizamos
            </a>
          </span>
      </div>
    );
  }
}

export default NewPasswordForm;
