import React, { useLayoutEffect, useState, useEffect } from 'react'
import { Grid, Row, Col } from 'react-bootstrap'
import queryString from "query-string";
import MuiSelect from 'components/Selects/MuiSelect'
import Select from "react-select";
import mainApi from 'services/mainApi'
import Card from 'components/Card/Card'
import { LoadingAlert, Dialog } from 'components/Alert/CustomAlert'
import swal from 'components/Alert/alert'

import LinkVoltar from 'components/LinkVoltar'
import { Link } from 'react-router-dom'
import { CircularProgress, FormControlLabel, Checkbox } from "@material-ui/core"

import { WrapperGrid, ListItem, DDDsGrid, ItemDDD, WrapperStates, SpanState } from './styled'

const styleHeight = {

  height: 38,
  borderRadius: 5,
  fontSize: 15,
  marginBottom: 0
}

export default function ManagerAgencies({ location }) {

  const [ corporations, setCorporations ] = useState([])

  const [ agencies, setAgencies ] = useState([])
  const [ searchedAgencies, setSearchedAgencies ] = useState([])
  const [ ufsAgencies, setUfsAgencies ] = useState([])
  const [ ufsChosenAgencies, setUfsChosenAgencies ] = useState([])
  const [ errors, setErrors ] = useState({})
  const [ nameAgency, setNameAgency ] = useState('')
  const [ ufSearch, setUfSearch ] = useState('all')

  const [ chosenAgencies, setChosenAgencies ] = useState([])
  const [ searchedChosenAgencies, setSearchedChosenAgencies ] = useState([])
  const [ hasChangeTitle, setHasChangeTitle ] = useState(true)
  const [ preselected, setPreselected ] = useState(true)
  const [ nameChosen, setNameChosen ] = useState('')
  const [ ufChosenSearch, setUfChosenSearch ] = useState('all')

  const [ manager, setManager ] = useState({})
  const [ selectedCorporation, setSelectedCorporation ] = useState({})

  const [ loadingAgency, setLoadingAgency ] = useState(false)
  const [ loadingChosen, setLoadingChosen ] = useState(false)
  const [ allLoading, setAllLoading ] = useState(false)

  const getAgencies = async (id, preselectedProp) => {

    let managerID = queryString.parse(location.search).id;
    try {
      const response = await mainApi.get(`/agencies/partners/${id}?managerId=${managerID}&preselected=${preselectedProp == null ? preselected : preselectedProp}`)
      const agencies = response.data.data.agency
      let ufs = new Set()

      setAgencies(agencies)

      if(agencies.length) {

        agencies.forEach(item => {
          if(item.state) ufs.add(item.state)
        })

        const finalUfs = [...ufs].map(item => ({ value: item, label: item }))
        setUfsAgencies(finalUfs)
      }
    } catch (err) {
    console.log(err)
    }
  }

  const getManager = async () => {

    let managerID = queryString.parse(location.search).id;

    try {
      const response = await mainApi.get(`/manager/partner/${managerID}`)
      const manager = response.data.data

      setManager(manager)
      setChosenAgencies(manager.agencies)

      let ufs = new Set()

      if(manager.agencies.length) {

        manager.agencies.forEach(item => {
          if(item.uf) ufs.add(item.uf)
        })

        const finalUfs = [...ufs].map(item => ({ value: item, label: item }))
        setUfsChosenAgencies(finalUfs)
      }

      if(hasChangeTitle) {
        changeTitleName(manager)
        setHasChangeTitle(false)
      }

    } catch (err) {
      console.log(err)
    }
  }

  function changeTitleName(manager) {

    let title = document.querySelector('#title-page-affiliate')
    title.innerHTML += ` para ${manager.name}`
  }

  const getCorporations = async () => {

    let managerID = queryString.parse(location.search).id;

    try {
      const response = await mainApi.get(`/corporations/partners?managerId=${managerID}`)
      const corporations = response.data.data.corporations

      if (corporations.length) {

        if(!selectedCorporation.value) {

          getAgencies(corporations[0].value)
          setSelectedCorporation({ label: corporations[0].label, value: corporations[0].value })
        } else {

          if(corporations[0].value == selectedCorporation.value) {

            getAgencies(corporations[0].value)
            setSelectedCorporation({ label: corporations[0].label, value: corporations[0].value })
          } else {

            getAgencies(selectedCorporation.value)
            setSelectedCorporation({ label: selectedCorporation.label, value: selectedCorporation.value })
          }
        }
      }

      setCorporations(corporations)
    } catch (err) {
      console.log(err)
    }
  }

  useLayoutEffect(() => {

    getManager()
    getCorporations()
  }, [])

  useEffect(() => {

    mainSearch(nameAgency, ufSearch)
  }, [ nameAgency, ufSearch, agencies ])

  useEffect(() => {

    mainSearchChosen(nameChosen, ufChosenSearch)
  }, [ nameChosen, ufChosenSearch, chosenAgencies ])

  function hasHalveOpacity(item) {
    const hasChosen = chosenAgencies.find(element => element.value == item.value);

    let returnClass = ''
    if (hasChosen) returnClass = 'halve-opacity'

    return returnClass
  }

  const atribbuteAgency = async (agencyId, remove) => {

    const data = {
      managerId: manager._id,
      agencyId
    }

    if(remove) {
      data.remove = true
      setLoadingChosen(true)
    } else {

      setLoadingAgency(true)
    }

    try {
      const response = await mainApi.patch(`/agencies/partner`, data)
      getManager()
      getAgencies(selectedCorporation.value)
    } catch(err) {
      console.log(err)
      setErrors({ message: err?.response?.data?.message})
    }

    if(remove) {
      setLoadingChosen(false)
    } else {

      setLoadingAgency(false)
    }
  }


  const atribbuteMultipleAgency = async (agencyId, remove) => {

    const data = {
      managerId: manager._id,
      agencyId
    }

    if(remove) data.remove = true

    try {
      await mainApi.patch(`/agencies/partner`, data)
    } catch(err) {
      console.log(err)
    }
  }

  function mainSearch(name, uf) {

    name = name.trim()
    const filterUF = uf === 'all' ? false : true
    const regex = new RegExp(`${name}`, 'i')
    let filterAgencies = []

    if(!filterUF && !name) {
      filterAgencies = agencies
    }

    if(!filterUF && name) {

      filterAgencies = agencies.filter(element => regex.test(element.label) )
    }

    if(filterUF && name) {

      filterAgencies = agencies.filter(element => regex.test(element.label)).filter(element => element.state === uf)
    }

    if(filterUF && !name) {

      filterAgencies = agencies.filter(element => element.state === uf)
    }

    setSearchedAgencies(filterAgencies)
  }

  function mainSearchChosen(name, uf) {

    name = name.trim()
    const filterUF = uf === 'all' ? false : true
    const regex = new RegExp(`${name}`, 'i')
    let filterAgencies = []

    if(!filterUF && !name) {
      filterAgencies = chosenAgencies
    }

    if(!filterUF && name) {

      filterAgencies = chosenAgencies.filter(element => regex.test(`${element.corpName} - ${element.agencyName}`) )
    }

    if(filterUF && name) {

      filterAgencies = chosenAgencies.filter(element => regex.test(`${element.corpName} - ${element.agencyName}`)).filter(element => element.uf === uf)
    }

    if(filterUF && !name) {

      filterAgencies = chosenAgencies.filter(element => element.uf === uf)
    }

    setSearchedChosenAgencies(filterAgencies)
  }

  const selectAllAgencies = async () => {

    setAllLoading(true)

    for (const element of searchedAgencies) {
      await atribbuteMultipleAgency(element.value)
    }

    getManager()
    getAgencies(selectedCorporation.value)

    setAllLoading(false)
  }

  const removeAllAgencies = async () => {

    setAllLoading(true)

    for (const element of searchedChosenAgencies) {
      await atribbuteMultipleAgency(element.id, true)
    }
    getManager()
    getAgencies(selectedCorporation.value)
    getCorporations()

    setAllLoading(false)
  }

  return (
    <div className="content">

      {<Dialog
        type={'warning'}
        show={Boolean(errors.message)}
        title={'Atenção'}
        onConfirm={() => {
          setErrors({})
        }}
        text={errors.message}
      />}

      <LinkVoltar />
      <LoadingAlert show={allLoading} />

      <Grid fluid>
        <Row className="mb-3">
          <Col md={4}>
            <WrapperStates className="p-2">
              <p>Estados atribuídos: <Link className="link-style" to={`/admin/create/managers?id=${manager?._id}`}>Ir para edição</Link></p>
              <DDDsGrid>
                {manager.ufs?.map(item => (
                  <ItemDDD>{item}</ItemDDD>
                ))}
              </DDDsGrid>
            </WrapperStates>
          </Col>

          <Col md={8}>
            <WrapperStates className="p-2">
              <p>DDDs atribuídos: <Link className="link-style" to={`/admin/create/managers?id=${manager?._id}`}>Ir para edição</Link></p>
              <DDDsGrid>
                {manager.ddds?.map(item => (
                  <ItemDDD>{item}</ItemDDD>
                ))}
              </DDDsGrid>
            </WrapperStates>
          </Col>
        </Row>

        <Row style={{display: 'flex', alignItems: 'center'}}>
          <Col md={4} sm={12}>
            <label>Escolha a corporação</label>
            <MuiSelect
              placeholder="Selecione uma corporação"
              options={corporations}
              value={selectedCorporation}
              onChange={(event) => {
                setSelectedCorporation({ value: event.value, label: event.label })
                getAgencies(event.value)
              }}
            />
          </Col>
          <Col md={4} sm={12}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={preselected}
                  onChange={(event) => {
                    setPreselected(event.target.checked)
                    getAgencies(selectedCorporation.value || corporations[0].value, event.target.checked)
                    getManager(event.target.checked)
                  }}
                  name="all"
                />
              }
              label="Filtrar por lojas atribuíveis"
            />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Card
              title={
                <>
                  <p>
                    Lojas da corporação {selectedCorporation.label || ''}
                    {loadingAgency && (
                      <CircularProgress
                        size={24}
                        style={{
                          marginLeft: 15,
                          position: "relative",
                          display: 'inline-block',
                          top: 4
                        }}
                      />
                    )}
                  </p>
                  <p>Quantidade de lojas: { searchedAgencies.length || 0 }</p>
                </>
                }
              content={
                <>
                  {selectedCorporation && (
                    <>
                      <Row>
                        <Col md={3} className="pr-1">
                          <button
                            style={ styleHeight }
                            onClick={() => {
                              swal.confirmNegate(
                                'warning',
                                'Atenção',
                                `Tem certeza que deseja atríbuir ${ searchedAgencies.length } lojas da corporação ${ selectedCorporation.label }?`, () => {
                                selectAllAgencies()
                              })
                            }}
                            className="btn btn-login">
                            Adicionar todos
                          </button>
                        </Col>

                        <Col md={6} className="pl-1">
                          <input
                            onChange={(e) => {
                              setNameAgency(e.target.value)
                            }}
                            placeholder="Pesquisar loja"
                            className="input-search-user-list"
                          />
                        </Col>

                        <Col md={3} className="pl-1">
                          <Select
                            placeholder="UF"
                            options={[ { value: 'all', label: 'Todos' }, ...ufsAgencies ]}
                            onChange={(event) => {
                              setUfSearch(event.value)
                            }}
                          />
                        </Col>
                      </Row>
                    </>
                  )}

                  <hr/>

                  <WrapperGrid>
                    {searchedAgencies.map(item => (
                      <ListItem
                        className={ hasHalveOpacity(item) }
                        onClick={() => atribbuteAgency(item.value)}
                        id={item.value}
                        key={item.value}>
                        {item.label}

                        {item.ddd && (
                          <SpanState bgColor={"#237888"}>
                            {item.ddd}
                          </SpanState>
                        )}

                        {item.state && (
                          <SpanState bgColor={"#DD8735"}>
                            {item.state}
                          </SpanState>
                        )}
                      </ListItem>
                    ))}
                  </WrapperGrid>
                </>
              }
            />
          </Col>

          <Col md={6}>
            <Card
              title={
                <>
                  <p>
                    Lojas atríbuidas
                    {loadingChosen && (
                      <CircularProgress
                        size={24}
                        style={{
                          marginLeft: 15,
                          position: "relative",
                          display: 'inline-block',
                          top: 4
                        }}
                      />
                    )}
                  </p>
                  <p>Quantidade de lojas atríbuidas: { searchedChosenAgencies.length || 0 }</p>
                </>
              }
              content={
                <>
                  <Row>
                    <Col md={3} className="pr-1">
                      <button
                        style={ styleHeight }
                        onClick={() => {
                          swal.confirmNegate(
                            'warning',
                            'Atenção',
                            `Tem certeza que deseja remover ${ searchedChosenAgencies.length } lojas do gerente ${manager.name}?`, () => {
                            removeAllAgencies()
                          })
                        }}
                        className="btn btn-login">
                        Remover todos
                    </button>
                    </Col>

                    <Col md={6} className="pl-1">
                      <input
                        onChange={(e) => {
                          setNameChosen(e.target.value)
                        }}
                        placeholder="Pesquisar loja"
                        className="input-search-user-list"
                      />
                    </Col>

                    <Col md={3} className="pl-1">
                      <Select
                        placeholder="UF"
                        options={[ { value: 'all', label: 'Todos' }, ...ufsChosenAgencies ]}
                        onChange={(event) => {
                          setUfChosenSearch(event.value)
                        }}
                      />
                    </Col>
                  </Row>

                  <hr/>

                  <WrapperGrid>
                    {searchedChosenAgencies.map(item => (
                      <ListItem
                        color="#dc3545"
                        colorHover="#c82333"
                        onClick={() => atribbuteAgency(item.id, true)}
                        key={item.id}>
                          {`${item.corpName} - ${item.agencyName}`}
                          {item.ddd && (
                            <SpanState bgColor={"#237888"}>
                              {item.ddd}
                            </SpanState>
                          )}
                          {item.uf && (
                            <SpanState bgColor={"#DD8735"}>
                              {item.uf}
                            </SpanState>
                          )}
                      </ListItem>
                    ))}
                  </WrapperGrid>
                </>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  )
}