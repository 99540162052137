import React, { Component, createRef } from "react";

import {
  LoadingAlert,
  ConfirmationAlert,
  Dialog,
} from "components/Alert/CustomAlert";
import { CopyToClipboard } from "react-copy-to-clipboard"

import mainApi from "services/mainApi";
import { affiliateCorpId } from "utils/CommonData"

import AgencyModal from "components/AgencyModal";
import InviteModal from "components/InviteModal";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { CircularProgress } from "@material-ui/core";
import { Link } from 'react-router-dom';

import Select from "react-select";
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider } from "@material-ui/core/styles";
import getMuiTheme from "utils/getMuiTheme";
import { Row, Col } from 'react-bootstrap';
import Toogle from 'react-toggle'
import { numToBRL, firstAndLastWord } from "utils/formatData";
import to from "await-to-js";
import { ClubeAmigoId } from "utils/CommonData";
import { translateLevel } from "utils/translate";
import { normalize } from "utils/normalizeString";
import { shortenValue } from "utils/formatData";
import { ISClubeAmigo } from 'utils/environment'

const blocked = [
  { value: '', label: 'Nenhuma opção' },
  { value: 'true', label: 'Bloqueado' },
  { value: 'false', label: 'Não bloqueado' },
]

export default class Agencies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSwal: false,
      swalShowLoading: false,
      swalMessageShow: false,
      showModal: false,
      showInviteModal: false,
      swalMessage: [],
      agency: {},
      agencyAddress: {},
      title: "Confirmação",
      text: "Tem certeza de que deseja transformar o vendedor em Supervisor?",
      sellerId: null,
      inviteToken: null,
      anchorEl: {},
      selectedAgency: null,
      selectedAgencyName: null,
      page: 1,
      name: ''
    };

    this.teamHeader = [
      "#",
      "Loja",
      "Administrador",
      "Sup.",
      "Vendedores",
      "Links Gerados",
      "Vendido",
      "Bonificação da loja",
      "Status",
      "Ações",
    ]

    this.nameRef = createRef()
    this.blockedRef = createRef()
  }

  inviteAgency = async (id, name) => {

    this.props.history.push({
      pathname: "/admin/affiliate/invite",
      search: "?agencyId=" + id + "&agencyName=" + name,
      state: {agencyId: id, agencyName: name}
    });

  };

  liberate = async (agencyId) => {
    const { reloadTable } = this.props;
    let responseResult = false

    try {
      this.setState({ swalShowLoading: true });

      const response = await mainApi.post("/agency/liberate", { agencyId });

      this.setState({
        swalMessage: [
          "success",
          response.data.message,
          () => {
            reloadTable();
          },
        ],
        swalShowLoading: false,
      });

      responseResult = true
    } catch (err) {
      if (!err.response) return ["warning", "Erro Desconhecido (ou Timeout)."];
      this.setState({
        swalMessage: [
          "warning",
          err.response.data.message || "Erro Desconhecido",
        ],
        swalShowLoading: false,
      });

      responseResult = false
    }

    if(responseResult) {
      var eventDetail = {"detail": {"button": "Click:BP:IndiqueOPan:TransformarEmVendedor" }}
      var interactionClick = new CustomEvent("interactionClick", eventDetail)
      document.body.dispatchEvent(interactionClick );
    }

    this.setState({ swalMessageShow: true });
  };

  getAgencyAddress = async (agencyId) => {
    this.setState({ swalShowLoading: true });
    try {
      const response = await mainApi.get(`/agency/${agencyId}/address`);
      this.setState({ swalShowLoading: false });
      return response.data.addresses[0];
    } catch (err) {
      this.setState({ swalShowLoading: false });
      return "Error";
    }
  };

  convertAgencyArrayToObject = (agencyInfo) => {

    const {data} = this.props
    const agency = data.filter(a => a._id = agencyInfo[0])[0]

    return agency;
  };

  editTeam = async (agencyId, agencyName) => {
      if (agencyId) {
        this.props.history.push(`/admin/teams?agencyId=${agencyId}&agencyName=${agencyName}`)
      }
  };

  toggleInviteModal = (inviteToken, agencyId, agencieName) => {
    if (!inviteToken) inviteToken = "";
    this.setState({
      inviteToken,
      selectedAgency: agencyId,
      selectedAgencyName: agencieName,
      showInviteModal: !this.state.showInviteModal,
    });
  };

  copyCode = (event) => {
    const element = this.findSpanElement()
    this.showCopyText(event, element)

    setInterval(() => {
      document.addEventListener('mousemove', (event) => {
        this.showCopyText(event, element)
      })
    }, 100);

    setTimeout(() => {
      this.hideCopyText()
    }, 3000)
  }

  hideCopyText = () => {
    const element = this.findSpanElement()
    element.remove()
  }

  findSpanElement = () => {
    const alreadyCreated = document.querySelector("#copy-text-element")
    if(alreadyCreated) alreadyCreated.remove()

    const span = document.createElement('span')
    span.setAttribute("id", "copy-text-element")
    document.body.appendChild(span)
    return span
  }

  showCopyText = (event, element, text = 'Copiado !') => {
    element.style.display = 'block'
    element.style.top = (event.pageY + 15) + 'px';
    element.style.left = (event.pageX + 15) + 'px';
    element.innerHTML = text
  }

  toggleModal = async (agency) => {

    this.setState({ agency: null, agencyAddress: null });
    if (agency) {
      const convertAgency = await this.convertAgencyArrayToObject(agency);

      this.setState({ agency: convertAgency });
      const agencyAddress = await this.getAgencyAddress(agency[0]);

      this.setState({ agencyAddress });
    }
    this.setState({ showModal: !this.state.showModal });
  };

  toggleBlock = async (blocked, agencyId) => {
    let responseResult = false

    try {
      this.setState({ swalShowLoading: true });

      let endpoint = null;

      blocked ? (endpoint = "/agency/unblock") : (endpoint = "/agency/block");

      const response = await mainApi.post(endpoint, { agencyId });
      this.setState({
        swalMessage: ["success", response.data.message],
        swalShowLoading: false,
      });

      responseResult = true
    } catch (err) {
      if (!err.response) return ["warning", "Erro Desconhecido (ou Timeout)."];
      this.setState({
        swalMessage: [
          "warning",
          err.response.data.message || "Erro Desconhecido",
        ],
        swalShowLoading: false,
      });

      responseResult = false
    }

    if(responseResult) {
      var eventDetail = {"detail": {"button": "Click:BP:IndiqueOPan:AlterarStatus" }};
      var interactionClick = new CustomEvent("interactionClick", eventDetail);
      document.body.dispatchEvent(interactionClick);
    }

    this.setState({ swalMessageShow: true });
  };

  handleClick = (event, agencyId) => {
    let anchorEl = this.state.anchorEl;
    anchorEl[agencyId] = event.currentTarget;
    this.setState({ anchorEl });
  };

  resendEmail = async (agencyId) => {
    const [err, response] = await to(mainApi.get(`/partner/password/reset/${agencyId}`))

    if(err){
      this.setState({swalMessage: ['warning', err.response.data.message]}, () => {
        this.setState({swalMessageShow: true})
      })
      return
    }

    this.setState({swalMessage: ['success', response.data.message]}, () => {
      this.setState({swalMessageShow: true})
    })
  };

  handleClose = (agencyId) => {
    let anchorEl = this.state.anchorEl;
    anchorEl[agencyId] = null;
    this.setState({ anchorEl });
  };


  componentDidMount(){
    const { userData } = this.props

    window.digitalData = {
      "site": {
          "nome": "Banco Pan"
      },
      "page": {
          "secao": "IndiqueoPan",
          "estadoLogin": "LG",
          "nome": "BP:IndiqueOPan:Lojas",
      },
      "user": {
          "crmid": userData.id,
          "tipo": userData.brType
      }
    }
    var evt = new CustomEvent('event-view-start');
    document.body.dispatchEvent(evt);
  }

  showConfirmation = (title, text, call) => {
    this.setState({ title, text, showSwal: true, confirmationCallback: call });
  };

  loadingBodyRender = (dataIndex, rowIndex) => {
    return (
      <>
        {dataIndex !== undefined ?
          dataIndex
        :
        <CircularProgress
          size={24}
          style={{
            marginLeft: 15,
            position: "relative",
            top: 4
          }}
        />
        }
      </>
    )
  }

  columns = (hasManager, level, data, IS_ClubeAmigo) => [
    {
      name: "_id",
      label: "ID",
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        hint: "Identificador do afiliado",
      },
    },
    {
      name: "liberated",
      options: {
        display: false,
        viewColumns: false,
        filter: false,
      },
    },
    {
      name: "name",
      label: hasManager ? "Parceiro" : "Loja",
      options: {
        setCellProps: () => ({ className: 'no-wrap' }),
        sort: false,
        hint: "Nome dos parceiros ou lojas.",
        customBodyRender: (value, rowIndex) => {
          const formatedValue = !value || String(value).includes('-') ? 'n/a' : normalize(value)
          const shortenName = shortenValue(formatedValue)

          const { level } = this.props

          const row = data[rowIndex.rowIndex]
          const id = row._id
          const isIntegration = row.integrationAgency
          const agencyName = row.name
          if(['corporation', 'subcorp'].includes(level) && !isIntegration) {
            return (
              <a
                className="link-style"
                onClick={() => {
                  this.handleClose(id);
                  this.editTeam(id, agencyName);
                }}
              >
                {shortenName || value}
              </a>
            )
          } else {
            return value
          }
        }
      },
    },
    {

      name: "reCode",
      label: "RE",
      options: {
        display:IS_ClubeAmigo,
        setCellProps: () => ({ className: 'no-wrap' }),
        sort: false,
        hint: "RE",
        customBodyRender: (value) => value || 'n/a'
      }
    },
    {
      name: "administrator",
      label: translateLevel('administrator', this.props.userData?.corpId),
      options: {
        sort: false,
        hint: `Nome do ${translateLevel('administrator', this.props.userData?.corpId)}`,
        customBodyRender: (dataIndex, rowIndex) => {

          const row = data[rowIndex.rowIndex]
          const id = row._id
          const agencieName = row.name;
          const blocked = row.blocked;
          const adminInviteToken = row.adminInviteToken;
          const adminCode = row.adminCode

          if(String(this.props.corporation._id) === String(affiliateCorpId)) return ''

          if (dataIndex && hasManager && adminCode) return (
            <div style={{ display: 'flex', margin: 0 }}>
              <CopyToClipboard text={adminCode}>
                <p className='admin-code' onClick={this.copyCode}>{adminCode}</p>
              </CopyToClipboard>
              - {firstAndLastWord(dataIndex)}
            </div>
          )

          if (dataIndex) return firstAndLastWord(dataIndex)

          return (
            <button
              disabled={blocked}
              onClick={() => {
                this.handleClose(id);
                this.toggleInviteModal(adminInviteToken, id, agencieName);
              }}
              className='anchor-button'
            >
              Atribuir Adminstração
            </button>
          );
        },
      },
    },
    {
      name: "users.supervisors",
      label: translateLevel('supervisor', this.props.userData?.corpId) + 'es',
      options: {
        sort: false,
        hint: `Quantidade de ${translateLevel('supervisor', this.props.userData?.corpId)} + 'es'`,
      },
    },
    {
      name: "users.sellers",
      label: translateLevel('seller', this.props.userData?.corpId) + 'es',
      options: {
        sort: false,
        hint: `Quantidade de ${translateLevel('seller', this.props.userData?.corpId)}es`,
      },
    },
    {
      name: ISClubeAmigo ? 'clicks' : 'links',
      label: ISClubeAmigo ? 'Convites' : 'Links gerados',
      options: {
        sort: false,
        hint: ISClubeAmigo ? 'Total de convites gerados' : "Total de links convertidos"
      },
    },
    {
      name: "datas.total",
      label: "Total Vendido",
      options: {
        sort: false,
        hint: "Total vendido pela loja.",
        customBodyRender: (value) => numToBRL(value)
      },
    },
    {
      name:  'datas.converted',
      label: ISClubeAmigo ? 'Vouchers' : 'Conversões',
      options: {
        sort: false,
        hint:  ISClubeAmigo ? 'Quantidades de vouchers gerados pela loja.' : 'Quantidade de vendas pela loja.'
      },
    },
    {
      name: ISClubeAmigo ? 'datas.voucherValue' : 'commission',
      label: ISClubeAmigo ? 'Premiação' : 'Bonificação',
      options: {
        sort: false,
        hint: ISClubeAmigo ? 'Bonificação destinada ao indicador' : 'Bonificação destinada à loja.',
        customBodyRender: (value) => ISClubeAmigo ? numToBRL(value) : value ?  value + '%' : 'Não Atribuida'
      },
    },
    {
      name: "blocked",
      label: "Bloqueado",
      options: {
        sort: false,
        hint: "Tipo do afiliado.",
        customBodyRender: (dataIndex, rowIndex) => {
          const row = data[rowIndex.rowIndex]
          const id = row._id;
          let blocked = row.blocked;
          if(String(this.props.corporation._id) === String(affiliateCorpId)) return ''
          return (
            <>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Toogle
                  checked={blocked}
                  className='custom-toggle-class'
                  onChange={() => {
                    if(!blocked) {
                      this.showConfirmation(
                        `Bloquear ${hasManager ? 'parceiro' : 'loja'}?`,
                        `Tem certeza de que deseja bloquear ${hasManager ? 'o parceiro' : 'a loja'} ${rowIndex.rowData[2]}? Os usuários pertencentes a ${hasManager ? 'este parceiro' : 'esta loja'} não conseguirão acessar suas contas.`,
                        () => { if(this.toggleBlock(blocked, id)) blocked = true; }
                      )
                    } else {
                      if(this.toggleBlock(blocked, id)) blocked = false;
                    }
                  }}
                />
              </div>
            </>
          );
        },
      },
    },
    {
      name: "sellersCommission",
      options: {
        display: false,
        viewColumns: false,
        filter: false,
      },
    },
    {
      name: "supervisorsCommission",
      options: {
        display: false,
        viewColumns: false,
        filter: false,
      },
    },
    {
      name: "commission",
      options: {
        display: false,
        viewColumns: false,
        filter: false,
      },
    },
    {
      name: "adminInviteToken",
      options: {
        display: false,
        viewColumns: false,
        filter: false
      },
    },
    {
      name: "adminCode",
      options: {
        display: false,
        viewColumns: false,
        filter: false
      }
    },
    {
      name: "",
      label: "Ações",
      options: {
        display: ['corporation', 'subcorp'].includes(level) && !ISClubeAmigo,
        sort: false,
        hint: "Ações",
        customBodyRender: (dataIndex, rowIndex) => {
          const { anchorEl, userData } = this.state;
          const { level } = this.props;

          const row = data[rowIndex.rowIndex]
          const id = row._id;
          const liberated = row.liberated;
          const agencyName = row.name;
          const admName = row.administrator ? row.administrator : 'N/A';
          const isIntegration = row.integrationAgency

          return (
            <>
              {(['corporation', 'subcorp'].includes(level)) ?
                isIntegration ?
                  <></> : (
                  <>
                    <button
                      className='anchor-button'
                      onClick={(event) => {
                        this.handleClick(event, id);
                      }}
                    >Ver mais</button>

                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl[id]}
                    keepMounted
                    open={Boolean(anchorEl[id])}
                    onClose={() => {
                       this.handleClose(id);
                    }}
                  >
                    {
                      (['corporation', 'subcorp'].includes(level) && !isIntegration) && (
                        <MenuItem>
                          <Link className='black-text' to={`/admin/update/agency?id=${id}`}>Editar usuário</Link>
                        </MenuItem>
                      )
                    }
                    {!liberated && !hasManager ? (
                      <MenuItem
                        onClick={() => {
                          this.showConfirmation(
                            `Remover ${admName} da administração ?`,
                            "Todo o estado atual será mantido, porém esta loja ficará inacessível até que um novo administrador seja cadastrado.",
                            async () => {
                              this.handleClose(id);
                              await this.liberate(id);
                            }
                          );
                        }}
                      >
                        Remover Administração
                      </MenuItem>
                    ) : null}


                    {
                      !IS_ClubeAmigo ?
                      <MenuItem
                      onClick={() => {
                        this.handleClose(id);
                        this.inviteAgency(id, agencyName);
                      }}
                    >
                      Convidar {translateLevel('seller', this.props.userData?.corpId)}es
                    </MenuItem>
                    : null
                    }
                    {!ISClubeAmigo && (
                      <MenuItem
                        onClick={() => {
                          this.handleClose(id);
                          this.editTeam(id, agencyName);
                        }}
                      >
                        Gerenciar Equipe
                      </MenuItem>
                    )}
                    {!liberated || hasManager ? (
                    <MenuItem
                      onClick={() => {
                        this.handleClose(id);
                        this.resendEmail(id, agencyName);
                      }}
                    >
                      Re-enviar email de convite
                    </MenuItem>
                    ) : null}
                  </Menu>
                </>
              ) : (
                <td>
                  {" "}
                  <button
                    className="btn btn-fill btn-mlm-sm btn-mlm"
                    onClick={() => {
                      this.handleClose(id);
                      this.toggleModal(rowIndex.rowData);
                    }}
                  >
                    Detalhes
                  </button>
                </td>
              )}
            </>
          );
        },
      },
    },
    {
      name: "integrationAgency",
      label: "isIntegration",
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        hint: "Loja de Integração",
      },
    },
  ]

  render() {
    const { data, level, totalDocs, searchButton, getCorpAgencies, userData, reloadTable, agenciesFooter, loadingAgencies } = this.props;

    const {
      agency,
      title,
      text,
      swalShowLoading,
      swalMessageShow,
      swalMessage,
      showModal,
      showInviteModal,
      inviteToken,
      selectedAgency,
      agencyAddress,
      confirmationCallback,
      selectedAgencyName
    } = this.state;

    const IS_ClubeAmigo = [ClubeAmigoId].includes(userData?.corpId)
    const hasManager = this.props.corporation.hasManager || userData.hasManager

    const options = {
      selectableRows: false,
      rowsPerPage: 10,
      filterType: "dropdown",
      responsive: "scrollMaxHeight",
      count: totalDocs,
      serverSide: true,
      pagination: true,
      print: false,
      filter: false,
      search: false,
      download: level === 'corporation' ? true : false,
      page: this.props.page - 1,
      rowsPerPageOptions: [10],
      textLabels: {
        body: {
          noMatch: "Desculpe, nenhum dado corresponde a pesquisa",
        },
        pagination: {
          next: "Próxima Página",
          previous: "Pagina Anterior",
          rowsPerPage: "Linhas por página:",
          displayRows: "de",
        },
        toolbar: {
          search: "Procurar",
          downloadCsv: "Download CSV",
          print: "Imprimir",
          viewColumns: "Mostrar Colunas",
          filterTable: "Filtrar Tabela",
        },
        filter: {
          title: "FILTROS",
          reset: "limpar",
          all: "Todos",
        },
        viewColumns: {
          title: "Mostrar Colunas",
        },
      },
      onColumnSortChange: (value, type) => {
        const { onSortChange } = this.props
        const hasPlus = 'descending' === type ? '-' : ''
        onSortChange(hasPlus + value)
      },
      onTableChange: (action, tableState) => {
        const { changePage, page } = this.props

        switch (action) {
          case 'changePage':
            changePage(tableState.page)
            break;
          case 'propsUpdate':
            if(page !== tableState.page + 1) tableState.page = 0;
            break;
          default:
            break;
        }
      },
      onDownload: () => {
        const { downloadCsv } = this.props

        downloadCsv()
        return false;
      }
    };

    return (
      <>
        <AgencyModal
          agency={{...agency}}
          address={{...agencyAddress}}
          show={showModal}
          onHide={() => this.toggleModal(null)}
          onSuccess={() => {
            reloadTable()
          }}
          level={level}
        />

        <InviteModal
          inviteToken={inviteToken}
          show={showInviteModal}
          userData={userData}
          onHide={this.toggleInviteModal}
          agency={selectedAgency}
          agencyName={selectedAgencyName}
        />

        <ConfirmationAlert
          show={this.state.showSwal}
          title={title}
          onCancel={() => {
            this.setState({ showSwal: false });
          }}
          onConfirm={() => {
            confirmationCallback();
            this.setState({ showSwal: false });
          }}
          text={text}
        />

        <Dialog
          type={swalMessage[0]}
          show={swalMessageShow}
          title={swalMessage[0] === "success" ? "Sucesso!" : "Temos um Problema!"}
          onConfirm={() => {
            if (swalMessage[2]) {
              swalMessage[2]();
            }
            getCorpAgencies()
            this.setState({ swalMessageShow: false });
          }}
          text={swalMessage[1]}
        />

        <LoadingAlert show={swalShowLoading} />

        <Row style={{paddingBottom: 15}}>

          <Col lg={3} md={6} className="margin-filter-user-list">
            <input
              ref={this.nameRef}
              id="data-tour-corp-details-filters"
              placeholder="Pesquisar por nome"
              className="input-search-user-list"
              style={{ background: "white", width: "100%" }}
            />
          </Col>

          <Col lg={2} md={6} className="margin-filter-user-list text-center">
            <Select
              ref={this.blockedRef}
              placeholder={`Bloqueio ${hasManager ? 'parceiros' : 'lojas'}` }
              styles={{ menu: styles => ({ ...styles, zIndex: 999 }) }}
              options={blocked}
            />
          </Col>

          <Col lg={3} md={6} className="margin-filter-user-list">
            <button className="btn btn-fill btn-block btn-round btn-mlm button-skinny-store-list" id="data-tour-corp-details-search" onClick={() => searchButton(this.nameRef.current.value, this.blockedRef.current.state.value)}>Pesquisar</button>
          </Col>

          <Col lg={4} md={6} className="margin-filter-user-list">
            <div style={{ paddingBottom: 15, display: 'flex', justifyContent: 'flex-end' }}>
              {userData && ["corporation", "subcorp"].includes(userData.level) && ![ClubeAmigoId].includes(userData?.corpId)? (
                <Link className="btn btn-fill btn-block btn-round btn-mlm button-skinny-store-list" to="/admin/create/agency" id="data-tour-corp-details-new-store">{hasManager ? "Novo parceiro" : 'Nova Loja' }</Link>
              ) : null}
            </div>
          </Col>
        </Row>

        <MuiThemeProvider theme={getMuiTheme}>
          <MUIDataTable
            className='withoutPadding'
            title={ hasManager ? "Parceiros" : "Lojas" }
            columns={this.columns(hasManager, level, data, IS_ClubeAmigo)}
            data={data}
            options={options}
          />
        </MuiThemeProvider>
        {(!loadingAgencies && ISClubeAmigo) && (
          <div className='total-footer'>
            <h2 className='total-footer__title'>TOTAL ACUMULADO DE TODO O PERÍODO</h2>
            <div className='total-footer__content'>
              <div className='total-footer__column'>
                <span className='total-footer__column-header'>{translateLevel('administrator', ClubeAmigoId)}</span>
                <p>{agenciesFooter.administrators || agenciesFooter.administrators === 0 ? agenciesFooter.administrators : 'n/a'}</p>
              </div>
              <div className='total-footer__column'>
                <span className='total-footer__column-header'>{translateLevel('supervisor', ClubeAmigoId)}es</span>
                <p>{agenciesFooter.supervisors || agenciesFooter.supervisors === 0 ? agenciesFooter.supervisors : 'n/a'}</p>
              </div>
              <div className='total-footer__column'>
                <span className='total-footer__column-header'>{translateLevel('seller', ClubeAmigoId)}es</span>
                <p>{agenciesFooter.sellers || agenciesFooter.sellers === 0 ? agenciesFooter.sellers : 'n/a'}</p>
              </div>
              <div className='total-footer__column'>
                <span className='total-footer__column-header'>Convites</span>
                <p>{agenciesFooter.clicks || agenciesFooter.clicks === 0 ? agenciesFooter.clicks : 'n/a'}</p>
              </div>
              <div className='total-footer__column'>
                <span className='total-footer__column-header'>Vouchers</span>
                <p>{agenciesFooter.converted || agenciesFooter.converted === 0 ? agenciesFooter.converted : 'n/a'}</p>
              </div>
              <div className='total-footer__column'>
                <span className='total-footer__column-header'>Premiação</span>
                <p>{numToBRL(agenciesFooter.voucherValue)}</p>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
