import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import MahoeIcon from "components/MahoeIcons";
import { StatsCard } from "components/StatsCard/StatsCard.jsx";
import { Card } from "components/Card/Card.jsx";
import { Doughnut, Pie, Line, Chart, Bar, HorizontalBar } from "react-chartjs-2";
import FunnelOmniChannel from "components/FunnelOmniChannel";
import getUserData from "utils/checkUser";
import { numToBRL, getMonthAndYear } from "utils/formatData";
import { formatToBrWithHour } from "utils/formaterDate";
import formater from "utils/formater";
import mainApi from "services/mainApi";
import LoadingCover from "components/LoadingCover";
import { introSystem } from "utils/introToSystem";
import dataTour from "utils/dataTour";
import Select from "react-select";
import { contaId, platforms } from "utils/CommonData";
import { translateMonths } from "utils/translate";
import moment from "moment";
import { Popper, Fade, Paper, FormHelperText } from "@material-ui/core";
import labelValue from "utils/labelWithValue";
import ClicksChart from "./ClicksChart";
import Skeleton from '@material-ui/lab/Skeleton';
import renderHTML from 'react-render-html';
import { eventStatus } from "utils/translate";
import  { ISClubeAmigo } from 'utils/environment';
import { maquininhaLabel, maquininhaId } from 'utils/environment';


import {
  graphOptions,
  getRealProductsNames,
  getColors,
  weeklyInitialState,
} from "./common";

import "chartjs-plugin-datalabels";

// date-picker
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptbr from "date-fns/locale/pt-BR";
import { getMonthById } from "utils/formatData";
import TopFive from "./TopFive";
import { funnelOmnichannelLabels, funnelAppsflyerLabels } from "utils/CommonData";
import { dashboardFilter } from "utils/CommonData";
import { emptySelect } from "utils/CommonData";
import { translateLevel } from "utils/translate";
import { consignadoProducts } from "utils/CommonData";
registerLocale("pt-BR", ptbr);

const colorByEvent = {
  "BP:APP:Onboarding:HoraDasFotos": "rgba(255, 99, 132, 0.8)",
  "BP:APP:Onboarding:VamosTirarUmaSelfie": "rgba(54, 162, 235, 0.8)",
  "BP:APP:Onboarding:DadosIniciais": "rgba(255, 206, 86, 0.8)",
  "BP:APP:Onboarding:MaisSobreVoce": "rgba(75, 192, 192, 0.8)",
  "PRD:BP:APP:Onboarding:RetornoContaAbertaSucesso": "rgba(153, 102, 255, 0.8)",
  "BP:APP:Onboarding:OndeVoceMora": "rgba(255, 159, 64, 0.8)",
};

const colorsToGraph = [
  "rgba(10, 80, 142, 0.8)",
  "rgba(153, 102, 255, 0.8)",
  "rgba(255, 206, 86, 0.8)",
  "rgba(255, 159, 64, 0.8)",
  "rgba(75, 192, 192, 0.8)",
  "rgba(255, 99, 153, 0.8)",
  "rgba(75, 192, 102, 0.8)",
  "rgba(228, 29, 41, 0.8)",
  "rgba(244, 6, 92, 0.8)",
  "rgba(173, 60, 155, 0.8)",
  "rgba(54, 162, 235, 0.8)",
  "rgba(176, 224, 230, 0.8)"
]

const accountOpeningMaster = [
  // '5f69347d48c1d60004a8916f',
  // '5df516d8dd6c1d76a762845d'
]

const accountOpeningCorporations = [
  // '5f9c2030fb723700045e214e', // Nome:Bevicred Informações Cadastrais LTDA                          CNPJ:07452085000198  SLUG:bevicred
  // '5fbe9c7d84ca8400046ae216', // Nome: JBF-3 PRESTADORA DE SERVICOS DE INFORMACOES CADASTRAIS LTDA  CNPJ:23141175000114  SLUG:jbf
  // '5f98304b63baed000430ec1e', // Nome:Lewe Intermediação de Negocios Eireli                         CNPJ:13054592000176  SLUG:lewe
  // '5fae948a86529b0004783496', // Nome:PCCA ANALISE CADASTRAL                                        CNPJ:12664356000109  SLUG:gvn
  // '5fe4bab13dab5800040c6406', // Nome:GLOBAL SOLUCOES LTDA                                          CNPJ:18833235000120  SLUG:globalsolucoes
  // '5f99731dbb2f610004af9de4', // Nome:Brasil Negócios Empresariais                                  CNPJ:22496728000199  SLUG:brasilnegocios
  // '601c4618321d1f0003e167e6',    // Prestho Digital LTDA
  // '5fac24b78fa66100049ee4eb',    // GPG PROMOTORA DE VENDAS - EIRELI
  // '5f4fd423caf57500045449b3',    // NOME:AGX Software                                                  CNPJ:15145064000158  SLUG:agx
  // '5f4fd423caf57500045449b5'
]

const accountOpeningAgencies = [
  // '607dbb23694cd800039d4782', // Nome: v4 Marketing Code: 65683
  // '609400b6891b950003f930bd', // Nome: 73203 - SILAS WESLEY DOS SANTOS TEIXEIRA Code: 44600
  // '605a91435b7c5f000310f3b2', // Nome: TOPS DO INSTA,                  Code: 20054
  // '600968445c0b8a00048fbcd6', // Nome: MARIANA PAIVA BARCELOS BORGES   Code: 3783
  // '60771a4fa5a30d0003b4663c', // Nome: faq.indique.vip | 9001          Code: 71749
  // '5fbff17723956b0004c2b1cf', // Nome: SP16-46852                      Code: 3537
  // '60099379a02c560003be1108', // Nome: SP11-38812,                     Code: 2420
  // '600b1c96ea4af50003713051', // Nome: Rodrigo Gomes Miranda           Code: 3180
  // '5ff8c083ff74fd00043d9d5b', // Nome: ANTONIO CARLOS GOMES DA SILVA   Code: 6950
  // '5fa6ad8b3d56db0004c98178'  // Nome: confiança                       Code: 3163
  // '5f4fd423caf57500045449b4'     // Nome: Matriz                          Code: 8003
  '5fac2cfe8fa66100049ee890',   // Code: 7355
  '60758ceb62ab360003fa890c',   // Code:79656
  '5f504686b1bfe50004796a0d',  // Nome: Jeiel Parceiro Code: 7916
]

const EnabledAccountOpening = [
  ...accountOpeningCorporations,
  ...accountOpeningAgencies,
  ...accountOpeningMaster
]

const EnableAccountOpeningDate = ('2021-06-08')
/**
 * Produtos Setados no dashboard
 *
 */
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //link
      loadingLink: true,
      links: null,

      //Calculation
      loadingCalculation: true,
      average: null,
      commission: null,
      situation: {},
      sold: null,
      soldAccopen: null,

      //Commission
      loadingCommission: true,
      pendingCommissionConta: null,
      paidCommissionConta: null,

      //Agency
      loadingAgency: true,
      agencyCommissionConta: null,

      //Corporation
      loadingCorporation: true,
      totalCorpsWithConta: null,

      //Daily
      loadingDailyData: true,
      dailySum: null,

      //Clicks
      loadingClicks: true,
      clicked: null,

      //ClicksGraph
      loadingClicksGraph: true,
      weekly: {},

      loading: false,
      loadingData: false,
      graphData: {},
      graphLegend: {},

      //ProductGraph
      loadingProductGraph: true,
      products: {},

      loadingRankCorporation: true,
      rankCorporation: null,
      CorpOrAgencieRanq: "",

      //Produtos setados
      SelectProducts: null,
      filterProduct: null,
      filterProductLabel: null,
      productName: emptySelect,

      //AffiliateCounts
      loadingAffiliateCounts: true,
      affiliatesNumbers: {},

      //Appsflyer
      loadingAppsFlyerInstall: true,
      appsFlyerInstall: null,

      loadingAppsFlyerEvents: true,
      appsFlyerEvents: null,

      loadingAppsFlyerData: true,
      appsFlyerData: null,


      loadingAppsflyer: true,
      appsFlyerGraphData: {},

      //AccountOpening
      loadingAccountOpening: true,
      accountOpeningGraph: {},
      accountOpeningGraphTotal: {},
      accountOpening: {},
      accountOpeningCard: {},
      newEvent: false,
      isMonthly: true,
      accountOpeningHasData: false,

      accountOpeningClicks: null,
      loadingAccountOpeningClicks: false,


      optionsWeekly: {},
      updatedAt: Date.now(),

      showContaDigitalCards: false,

      selectDate: new Date(),
      openPopper: false,
      anchorEl: null,
      isNotSeller: false,
      isSupAndSeller: false,
      isCorp: false,
      graphics: {
        weekly: null,
        products: null,
      },

      selectedPlataform: null,
      selectedLevelFilter: emptySelect,
      levelFilterOptions: [],
      vouchers: {},
      loadingVouchers: false,
      visualizationLevel: '',
      cards: {}
    };
  }

  getQuery = ({ noDate } = {}) => {
    const { filterProduct, productName, selectDate, selectedPlataform, selectedLevelFilter } = this.state;
    let query = '?'

    if (selectDate && !noDate) query += `date=${moment(selectDate).format("YYYY-MM")}`
    if (filterProduct) query += `&productId=${filterProduct}`;
    if (productName) query += `&productName=${productName.value}`
    if (selectedPlataform?.value === 'omnichannel' && filterProduct !== '5e84924c43689f59668b273f') query += '&omnichannel=true'
    if (selectedLevelFilter?.value) query += `&belowId=${selectedLevelFilter.value}`

    return query
  }

  getCalculations = async () => {
    const query = this.getQuery()
    this.setState({
      loadingCalculation: true,
      average: null,
      commission: null,
      situation: {},
      sold: null,
      soldAccopen: null,
    })
    try {
      const response = await mainApi.get(`/dashboard/datas/calculations${query}`)
      this.setState({ ...response.data.data.calculations });

    } catch (err) {
      if (err.response) console.log(err.response.data);
    }
    this.setState({ loadingCalculation: false })
  }

  getProductGraph = async () => {
    const query = this.getQuery()

    this.setState({
      loadingProductGraph: true,
      products: {},
    })
    try {
      const response = await mainApi.get(`/dashboard/datas/product${query}`)

      this.setState({ products: response.data.data.products });

      this.getGraphData(response.data.data.products)
    } catch (err) {
      if (err.response) console.log(err.response.data);
    }
    this.setState({ loadingProductGraph: false })
  }

  getCommissions = async () => {
    const query = this.getQuery()
    this.setState({
      loadingCommission: true,
      pendingCommissionConta: null,
      paidCommissionConta: null
    })
    try {
      const response = await mainApi.get(`/dashboard/datas/commissions${query}`)

      const {
        paid,
        pending
      } = response.data.data.commissions


      this.setState({
        pendingCommissionConta: pending,
        paidCommissionConta: paid
      });

    } catch (err) {
      if (err.response) console.log(err.response.data);
    }
    this.setState({ loadingCommission: false })
  }

  getAppsflyerInstalls = async () => {
    const { filterProduct } = this.state
    if (filterProduct !== contaId) return

    const query = this.getQuery()
    this.setState({
      loadingAppsFlyerInstall: true,
      appsFlyerInstall: null,
    })

    try {
      const response = await mainApi.get(`/appsflyer/install${query}`)

      if (response) {
        let appsFlyerInstall = response?.data?.data?.installs
        this.setState({
          appsFlyerInstall
        })
      }
    } catch (err) {
      console.log(err)
    }

    this.setState({ loadingAppsFlyerInstall: false })

  }

  getAppsflyerEvents = async () => {
    const { filterProduct } = this.state
    if (filterProduct !== contaId) return

    const query = this.getQuery()
    this.setState({
      loadingAppsFlyerEvents: true,
      appsFlyerEvents: null,
      appsFlyerGraphData:{}
    })

    try {

      const response = await mainApi.get(`/appsflyer/events${query}`)

      if (response) {
        let appsFlyerEvents = response?.data?.data?.events

        this.setState({
          appsFlyerEvents
        }, () => {
          this.setAppsFlyerGraphData(appsFlyerEvents)
        })
      }
    } catch (err) {
      console.log(err)
    }

    this.setState({ loadingAppsFlyerEvents: false })
  }

  getAppsflyerData = async () => {
    const { filterProduct } = this.state
    if (filterProduct !== contaId) return

    const query = this.getQuery()
    this.setState({
      loadingAppsFlyerData: true,
      appsFlyerData: null,
    })

    try {

      const response = await mainApi.get(`/appsflyer/data/${query}`)

      if (response) {
        let appsFlyerData = response?.data?.data?.startedSignup

        this.setState({
          appsFlyerData
        })
      }

    } catch (err) {
      console.log(err)
    }

    this.setState({ loadingAppsFlyerData: false })
  }

  getAgency = async () => {
    const query = this.getQuery()
    this.setState({
      loadingAgency: true,
      agencyCommissionConta: null,
    })

    try {
      const response = await mainApi.get(`/dashboard/agency${query}`)

      this.setState({ agencyCommissionConta: response.data.data.agenciesDatas });

    } catch (err) {
      if (err.response) console.log(err.response.data);
    }
    this.setState({ loadingAgency: false })
  }

  getClicks = async () => {
    const query = this.getQuery()
    this.setState({
      loadingClicks: true,
      clicked: null,
    })
    try {
      const response = await mainApi.get(`/dashboard/clicks${query}`)

      this.setState({ ...response.data.data });

    } catch (err) {
      if (err.response) console.log(err.response.data);
    }
    this.setState({ loadingClicks: false })
  }

  getAccountOpenningClicks = async () => {
    const query = this.getQuery()
    this.setState({
      loadingAccountOpeningClicks: true,
      accountOpeningClicks: null,
    })
    try {
      const response = await mainApi.get(`/dashboard/clicks/accountopening${query}`)

      this.setState({ accountOpeningClicks: response?.data?.data?.clicks?.month },() => {
        this.getAccountOpeningGraph()
      } );
    } catch (err) {
      if (err.response) console.log(err.response.data);
    }
    this.setState({ loadingAccountOpeningClicks: false })
  }

  getClicksGraph = async () => {
    const query = this.getQuery()

    this.setState({
      loadingClicksGraph: true,
      weekly: {},
    })
    try {
      const response = await mainApi.get(`/dashboard/clicks/graph${query}`)

      this.getWeekData(response.data.data.weekly)

    } catch (err) {
      if (err.response) console.log(err.response.data);
    }
    this.setState({ loadingClicksGraph: false })
  }

  getCorporations = async () => {
    const query = this.getQuery()
    this.setState({
      loadingCorporation: true,
      totalCorpsWithConta: null,
    })
    try {
      const response = await mainApi.get(`/dashboard/corporation${query}`)
      this.setState({ totalCorpsWithConta: response.data.data.corporations });

    } catch (err) {
      if (err.response) console.log(err.response.data);
    }
    this.setState({ loadingCorporation: false })
  }

  getDailyData = async () => {
    const query = this.getQuery({noDate: true})

    this.setState({
      loadingDailyData: true,
      dailySum: null
    })

    try {
      const response = await mainApi.get(`/dashboard/daily/datas${query}`)
      await this.setState({ ...response.data.data });
    } catch (err) {
      if (err.response) console.log(err.response.data);
    }
    this.setState({ loadingDailyData: false })
  }

  getTotalLinks = async () => {
    const query = this.getQuery()

    this.setState({
      loadingLink: true,
      links: null,
    })
    try {
      const response = await mainApi.get(`/dashboard/links/total${query}`)

      this.setState({ ...response.data.data });

    } catch (err) {
      if (err.response) console.log(err.response.data);
    }
    this.setState({ loadingLink: false })
  }

  getAffiliateCounts = async () => {
    const { userData, selectDate } = this.state;

    this.setState({
      loadingAffiliateCounts: true,
      affiliatesNumbers: {},
    })

    const query = selectDate
      ? `?date=${moment(selectDate).format("YYYY-MM-DD")}`
      : "";

    if (userData.level !== "master" && userData.level !== "submaster") return;
    try {
      const response = await mainApi.get("/affiliates/count" + query);
      const affiliates = response.data.totals;

      this.setState({ affiliatesNumbers: affiliates });
    } catch (err) {
      if (err.response) console.log(err.response.data);
    }
    this.setState({ loadingAffiliateCounts: false })
  };

  getRankCorporation = async () => {
    this.setState({ rankCorporation: null });
    const { filterProduct, userData, selectedLevelFilter } = this.state;
    const dashboardLevel = selectedLevelFilter.value ? dashboardFilter(userData.level) : userData.level

    this.setState({ visualizationLevel: dashboardLevel })

    if (
      !["corporation", "master", "subcorp", "submaster"].includes(
        dashboardLevel
      )
    )
      return;


    if (["corporation", "subcorp"].includes(dashboardLevel))
      this.setState({ CorpOrAgencieRanq: "lojas" });
    if (["master", "submaster"].includes(dashboardLevel))
      this.setState({ CorpOrAgencieRanq: "corporações" });

    const query = this.getQuery()
    // const query = selectDate
    //   ? `?date=${moment(selectDate).format("YYYY-MM-DD")}`
    //   : "";

    this.setState({ loadingRankCorporation: true })

    try {
      const response = await mainApi.get(
        `/corporation/product/rank/${filterProduct}${query}`
      );
      const corporations = response.data.corporations;

      const labels = [];
      const clicks = [];

      if (corporations.length > 0) {
        corporations.forEach((item) => {
          labels.push(item._id);
          clicks.push(item.clicks);
        });
      }

      const chartData = {
        labels: [...labels],
        datasets: [
          {
            label: "Quantidade total de clicks",
            data: [...clicks],
            backgroundColor: [
              "#039be582",
              "#039be582",
              "#039be582",
              "#039be582",
              "#039be582",
            ],
            borderColor: [
              "#039be582",
              "#039be582",
              "#039be582",
              "#039be582",
              "#039be582",
            ],
            borderWidth: 1,
          },
        ],
      };

      this.setState({ rankCorporation: chartData });
    } catch (err) {
      if (err.response) console.log(err.response.data);
    }
    this.setState({ loadingRankCorporation: false })
  };

  getGraphData = (data) => {
    var newData = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
          borderWidth: 0,
          weight: 10,
          pointStyle: "rectRounded",
        },
      ],
    };

    Object.keys(data).forEach((key) => {
      newData.labels.push(...getRealProductsNames([key]));
      newData.datasets[0].data.push(data[key].toFixed(2));
      newData.datasets[0].backgroundColor.push(...getColors([key]));
    });

    this.setState({ graphData: newData });
  };

  setAppsFlyerGraphData = (data) => {
    const { sold } = this.state
    //Fix width
    const width = 50 * data.length
    const dataWidth = []
    data.forEach((item, i) => {
      dataWidth.push(width - (50 * i))
    })

    var appsFlyerData = {
      labels: [],
      data: [],
      datasets: [
        {
          data: dataWidth,
          backgroundColor: [],
          borderWidth: 0,
          weight: 10,
          pointStyle: "rectRounded",
        },
      ],
    };

    // data.forEach((key) => {
    //   appsFlyerData.labels.push(key._id.split(":Onboarding")[1].substr(1));
    //   appsFlyerData.datasets[0].backgroundColor.push(colorByEvent[key._id]);
    //   if (key._id == "PRD:BP:APP:Onboarding:RetornoContaAbertaSucesso") {
    //     // appsFlyerData.datasets[0].data.push(this.state.situation.quantity.paid);
    //     appsFlyerData.data.push(this.state.situation.quantity.paid);
    //   } else {
    //     // appsFlyerData.datasets[0].data.push(key.count);
    //     appsFlyerData.data.push(key.count);
    //   }
    // });


    funnelAppsflyerLabels.forEach(key => {

      if (data.length) {

        let currentData = data.find(item => item._id.includes(key))

        if (currentData) {
          appsFlyerData.labels.push(key)

          if (currentData?._id == "PRD:BP:APP:Onboarding:RetornoContaAbertaSucesso") {
            // appsFlyerData.datasets[0].data.push(converted);
            // appsFlyerData.data.push(this.state.situation?.quantity.paid);
            appsFlyerData.data.push(sold <= 0 ? 0 : sold);
          } else {
            // appsFlyerData.datasets[0].data.push(key.count);
            appsFlyerData.data.push(currentData?.count);
          }
          appsFlyerData.datasets[0].backgroundColor.push(colorByEvent[currentData?._id]);
        }
      }
    })

    this.setState({ appsFlyerGraphData: appsFlyerData });
  };

  getWeekData = (data) => {
    try {
      let weekly = weeklyInitialState;
      for (let i = 0; i <= 6; i++) {
        weekly.datasets[0].data[i] = 0;
      }
      const keys = Object.keys(data);
      for (let item of keys) {
        const day = formater.getWeekDay(item);
        if (day) weekly.datasets[0].data[day - 1] = data[item];
      }
      this.setState({ weekly });

    } catch (err) {
      console.error("COULD NOT UPDATE: ", err)
    }
  };

  getAccountOpeningGraph = async () => {
    const { filterProduct, userData, accountOpeningClicks } = this.state;
    const query = this.getQuery()

    this.setState({
      loadingAccountOpening: true,
      appsFlyerGraphData: {},
    })

    if (filterProduct && filterProduct === contaId) {
      this.setState({ showContaDigitalCards: true });
    } else {
      this.setState({ showContaDigitalCards: false });
      return
    }

    try {
      const response = await mainApi.get(
        `/dashboard/accountopening${query}`
      )

      const accountOpening = { ...response?.data?.data?.accountOpening, clicks: accountOpeningClicks < 0 ? 0 : accountOpeningClicks }
      const accountOpeningCard = {
        dados_iniciais: accountOpening?.dados_iniciais < 0 ? 0 : accountOpening?.dados_iniciais,
        em_analise: accountOpening?.em_analise < 0 ? 0 : accountOpening?.em_analise,
        aprovada: accountOpening?.aprovada < 0 ? 0 : accountOpening?.aprovada,
        fotos: accountOpening?.fotos < 0 ? 0 : accountOpening?.fotos,
        reenvio_fotos: accountOpening?.reenvio_fotos < 0 ? 0 : accountOpening?.reenvio_fotos,
        todas_fotos: accountOpening?.todas_fotos < 0 ? 0 : accountOpening?.todas_fotos,
        reprovada: accountOpening?.reprovada < 0 ? 0 : accountOpening?.reprovada,
        conta_aberta: accountOpening?.conta_aberta < 0 ? 0 : accountOpening?.conta_aberta,
        clicks: accountOpeningClicks < 0 ? 0 : accountOpeningClicks
      }



      const lengthAccountOpening = funnelOmnichannelLabels.length

      const hasData = Object.values(accountOpening).some(item => item > 0)
      const accountOpeningHasData = EnabledAccountOpening.includes(userData?.id) || EnabledAccountOpening.includes(userData?.corpId) || EnabledAccountOpening.includes(userData?.agencyId)

      //Fix width
      const width = 100 * lengthAccountOpening
      const data = []
      funnelOmnichannelLabels.forEach((item, i) => {
        data.push(width - (100 * i))
      })

      var accountOpeningGraph = {
        labels: [],
        data: [],
        datasets: [
          {
            data: data,
            backgroundColor: colorsToGraph.slice(0, lengthAccountOpening),
            borderWidth: 0,
            weight: 10,
            pointStyle: "rectRounded",
          },
        ],
      };

      funnelOmnichannelLabels.forEach(label => {

        accountOpeningGraph.labels.push(label)
        accountOpeningGraph.data.push(!accountOpening[label] || accountOpening[label] < 0 ? 0 : accountOpening[label])

      })

      accountOpeningGraph.labels = accountOpeningGraph.labels.map(item => item ? eventStatus(item) : '')

      if (!hasData) accountOpeningGraph.labels = []

      this.setState({ accountOpeningGraph, accountOpening, accountOpeningCard, accountOpeningHasData })

    } catch (err) {
      console.log(err);
    }

    this.setState({ loadingAccountOpening: false })
  }

  handleEventGraph = ({ data, isPie  }) => {
    const { loading, loadingAccountOpening, newEvent, loadingAppsFlyerEvents, selectedPlataform } = this.state
    const IS_APPSFLYER = selectedPlataform?.value === 'appsflyer'
    const IS_OMNICHANNEL = selectedPlataform?.value === 'omnichannel'

    if (isPie && data?.labels && data?.labels?.length) {

      return (
        <Pie
          data={data}
          height={300}
          options={{
            ...graphOptions,
            // ...labelValue,
            sort: "desc",
            responsive: true,
            maintainAspectRatio: false,
            // plugins: { datalabels: false },
          }}
        />
      )
    }


    if (data?.labels && data?.labels?.length) {
      return (
        <FunnelOmniChannel
          data={data}
          filter={{ level: dashboardFilter(this.state.userData?.level), value: this.state.selectedLevelFilter?.value }}
          isMaster={["master", "submaster"].includes(this.state.userData.level)}
          isOmniChannel={IS_OMNICHANNEL}
          height={300}
          date={moment(this.state.selectDate).format("YYYY-MM")}
          options={{
            ...graphOptions,
            ...labelValue,
            sort: "desc",
            responsive: true,
            maintainAspectRatio: false,
            plugins: { datalabels: true },
            legend: {
              display: false
            }
          }}
        />
      )
    }

    return (
      <div className="search-top-5-loading">
        <h4>
          { loading || (loadingAppsFlyerEvents && IS_APPSFLYER) || (loadingAccountOpening && IS_OMNICHANNEL)
            ? "Buscando..."
            : "Nenhum evento encontrado!"}
        </h4>
      </div>
    )
  }

  reload = async () => {
    await this.getDashboard();

    this.setState({ updatedAt: Date.now() });
  };

  getDashData = async () => {
    const { routeKey } = this.props;
    const { selectDate, selectedLevelFilter, filterProduct, levelFilterOptions } = this.state
    const userData = await getUserData(this.props.history);
    let selectedPlataform = null

    if (!filterProduct) {
      //Appsflayer default até data de lançamento
      if(!moment(selectDate).isSameOrBefore(EnableAccountOpeningDate) && EnabledAccountOpening.includes(userData?.agencyId))
        selectedPlataform = platforms.find(platform => platform.value === 'omnichannel')
      else
        selectedPlataform =  platforms.find(platform => platform.value === 'appsflyer')

      this.setState({
        SelectProducts: userData.products,
        filterProduct: userData.products[0].value,
        filterProductLabel: userData.products[0].label,
        selectedPlataform
      })


      window.digitalData = {
        site: {
          nome: "Banco Pan",
        },
        page: {
          secao: "IndiqueoPan",
          estadoLogin: "LG",
          nome: "BP:IndiqueOPan:VisaoGeral",
        },
        user: {
          crmid: userData.id,
          tipo: userData.brType,
        },
      };

      var evt = new CustomEvent("event-view-start");
      document.body.dispatchEvent(evt);

    }
    const dashboardLevel = selectedLevelFilter.value ? dashboardFilter(userData.level) : userData.level

    if (dashboardLevel) {
      const aux = ["corporation", "master", "subcorp", "submaster"].includes(
        dashboardLevel
      );
      const auxSupAndSeller = [
        "supervisor",
        "seller",
        "administrator",
        "agency"
      ].includes(dashboardLevel);
      const auxCorp = ["corporation", "subcorp"].includes(dashboardLevel);
      this.setState(
        {
          isNotSeller: aux,
          isSupAndSeller: auxSupAndSeller,
          isCorp: auxCorp,
          userData,
        },
        async () => {
          await this.getDashboard();

          introSystem(routeKey);
        }
      );
      Chart.defaults.global.defaultFontColor = "#616161";
      Chart.defaults.global.defaultFontFamily =
        "'Montserrat','Roboto','Helvetica Neue',Arial,sans-serif";
      Chart.defaults.scatter.scales.xAxes[0].color = "blue";

      if (!levelFilterOptions.length) this.getFilterOptions(dashboardLevel)
    }
  };

  getVouchers = async () => {
    const query = this.getQuery()
    this.setState({ loadingVouchers: true })
    try {
       const response = await mainApi.get(`/dashboard/vouchers${query}`)
       const data = response.data.data
       this.setState({ vouchers: data })
    } catch (err) {
      console.log(err)
    } finally {
      this.setState({ loadingVouchers: false })
    }
  }

  getDashboard = async () => {
    const { filterProduct, selectedPlataform } = this.state
    this.setState({ loadingData: true })

    if (filterProduct && filterProduct === contaId) {
      this.setState({ showContaDigitalCards: true });
    } else {
      this.setState({ showContaDigitalCards: false });
    }

    const requests = [
      this.getCalculations(),
      // this.getProductGraph(),
      this.getCommissions(),
      this.getAgency(),
      this.getClicks(),

      this.getClicksGraph(),
      this.getCorporations(),
      this.getDailyData(),
      this.getTotalLinks(),

      this.getAffiliateCounts(),
      this.getRankCorporation(),
    ]

    switch (selectedPlataform?.value) {
      case 'omnichannel':
        requests.push(
          this.getAccountOpenningClicks(),
          this.getAccountOpeningGraph()
        )
        break;
      case 'appsflyer':
          requests.push(
            this.getAppsflyerData(),
            this.getAppsflyerEvents(),
            this.getAppsflyerInstalls(),

          )
          if (ISClubeAmigo) requests.push(this.getVouchers())
        break;
      default:
      break;
    }

    this.setState({ loadingData: true})

    await Promise.all(requests);

    this.setState({ loadingData: false })
  };

  componentDidMount = () => {
    this.getDashData();
  };

  changeSelectProduct = async (event) => {
    const { selectedPlataform } = this.state
    let newSelectedPlataform =  selectedPlataform

    if (event?.value !== contaId) newSelectedPlataform = {value: 'appsflyer', label: 'AppsFlyer'}

    this.setState(
      { filterProduct: event.value, filterProductLabel: event.label, selectedPlataform: newSelectedPlataform },
      async () => {
        // if (event.value) await this.getRankCorporation()
        if (event.value) await this.getDashboard();
      }
    );
  };

  changeTipoConsignado = async (event) => {
    this.setState(
      { productName: event },
      async () => {
        // if (event.value) await this.getRankCorporation()
        await this.getDashboard();
      }
    );
  }

  setSelectedDate = (date) =>{
    this.setState({
      selectDate: date
    }, () => this.getDashboard());
  }

  handleMouse = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
      openPopper: true,
    });
  };

  getColMD = () => {
    const { showContaDigitalCards, isSupAndSeller } = this.state;
    if (showContaDigitalCards) {
      if (isSupAndSeller) return 8;
      else return 5;
    } else {
      if (isSupAndSeller) return 12;
      else return 8;
    }
  };

  getFilterOptions = async (level) => {
    const filterOption = dashboardFilter(level)

    switch (filterOption) {
      case 'corporation':
        return this.getCorporationList()
      case 'agency':
        return this.getAgencyList()
      case 'seller':
        return this.getSellerList()
      default:
        return null

    }
  }

  getCorporationList = async () => {
    try {
      const response = await mainApi.get('/corporations/names');
      if (!Array.isArray(response?.data?.data?.corporations)) return
      const formattedCorporations = response.data.data.corporations.map(corp => ({ value: corp._id, label: corp.name }))

      this.setState({
        levelFilterOptions: [ emptySelect, ...formattedCorporations ]
      })
    } catch (error) {
      console.log(error)
    }
  }

  getAgencyList = async () => {
    try {
      const response = await mainApi.get('/resources/agencies');
      if (!Array.isArray(response?.data?.data?.agencies)) return

      this.setState({
        levelFilterOptions: [ emptySelect, ...response.data.data.agencies ]
      })
    } catch (error) {
      console.log(error)
    }
  }

  getSellerList = async () => {
    try {
      const response = await mainApi.get('/resources/sellers');
      if (!Array.isArray(response?.data?.data?.sellers)) return

      this.setState({
        levelFilterOptions: [ emptySelect, ...response.data.data.sellers ]
      })
    } catch (error) {
      console.log(error)
    }
  }

  getMiniCardText = ({card, product = 'default'}) => {
    const maquininha = this.state?.maquininha
    const {sold, situation} = this.state

    const productConfig = {
      [maquininhaId]: () => {
        switch (card) {
          case 'pagas':
            return (
              <div>
                {/* <div>
                  <span className='minimized-text'><strong>Total</strong>: <b>{numToBRL(sold)}</b> | {situation?.quantity?.paid || situation?.quantity?.paid === 0 ? situation?.quantity?.paid : ' - '}</span>
                </div> */}
                <span className='minimized-text'>Maquininha: {maquininha?.quantity?.paid?.maquininha || maquininha?.quantity?.paid?.maquininha === 0 ? maquininha?.quantity?.paid?.maquininha : ' - '}</span>
                <span className='minimized-text'>Conta digital: {maquininha?.quantity?.paid?.conta || maquininha?.quantity?.paid?.conta === 0 ? maquininha?.quantity?.paid?.conta : ' - '}</span>
              </div>
            )
          case 'pendentes':
            return (
              <div>
                {/* <div>
                  <span className='minimized-text'>Total: <b>{numToBRL(situation?.money?.pending)}</b> | {situation?.quantity?.pending || situation?.quantity?.pending === 0 ? situation?.quantity?.pending : ' - '}</span>
                </div> */}
                <span className='minimized-text'>Maquininha: {maquininha?.quantity?.pending?.maquininha || maquininha?.quantity?.pending?.maquininha === 0 ? maquininha?.quantity?.pending?.maquininha : ' - '}</span>
                <span className='minimized-text'>Conta digital: {maquininha?.quantity?.pending?.conta || maquininha?.quantity?.pending?.conta === 0 ? maquininha?.quantity?.pending?.conta : ' - '}</span>
              </div>
            )
          case 'recusadas':
            return (
              <div>
                {/* <div>
                  <span className='minimized-text'>Total: <b>{numToBRL(situation?.money?.refused)}</b> | {situation?.quantity?.refused || situation?.quantity?.refused === 0 ? situation?.quantity?.refused : ' - '}</span>
                </div> */}
                <span className='minimized-text'>Maquininha: {maquininha?.quantity?.refused?.maquininha || maquininha?.quantity?.refused?.maquininha === 0 ? maquininha?.quantity?.refused?.maquininha : ' - '}</span>
                <span className='minimized-text'>Conta digital: {maquininha?.quantity?.refused?.conta || maquininha?.quantity?.refused?.conta === 0 ? maquininha?.quantity?.refused?.conta : ' - '}</span>
              </div>
            )
        }
      },
      default: () => {
        switch (card) {
          case 'pagas':
            return `Propostas pagas: ${situation?.quantity?.paid || situation?.quantity?.paid === 0 ? situation?.quantity?.paid : ' - '}`
            case 'pendentes':
              return `Propostas pendentes: ${situation?.quantity?.pending || situation?.quantity?.pending === 0 ? situation?.quantity?.pending : ' - '}`
            case 'recusadas':
              return `Propostas recusadas: ${situation?.quantity?.refused || situation?.quantity?.refused === 0 ? situation?.quantity?.refused : ' - '}`
            default: return 'n/a'
        }
      }
    }

    return productConfig[product] ?  productConfig[product]() :  productConfig['default']()
  }

  getMiniCardValue = ({ card, product = 'default' }) => {
    const {sold, situation} = this.state

    const productConfig = {
      [maquininhaId]: () => {
        switch (card) {
          case 'pagas':
            return 'Aprovadas'
          case 'pendentes':
            return 'Pendentes'
          case 'recusadas':
            return 'Recusadas'
        }
      },
      default: () => {
        switch (card) {
          case 'pagas':
            return numToBRL(sold)
            case 'pendentes':
              return numToBRL(situation?.money?.pending)
            case 'recusadas':
              return numToBRL(situation?.money?.refused)
            default: return 'n/a'
        }
      }
    }

    return productConfig[product] ?  productConfig[product]() :  productConfig['default']()
  }

  render() {
    const {
      links,
      average,
      sold,
      soldAccopen,
      commission,
      pendingCommissionConta,
      paidCommissionConta,
      agencyCommissionConta,
      totalCorpsWithConta,
      clicked,
      weekly,
      dailySum,
      graphData,
      loading,
      affiliatesNumbers,
      situation,
      userData,
      showContaDigitalCards,
      appsFlyerGraphData,
      appsFlyerData,
      appsFlyerInstall,
      loadingAppsFlyerData,
      loadingAppsFlyerInstall,
      loadingAppsFlyerEvents,
      filterProduct,
      rankCorporation,
      CorpOrAgencieRanq,
      selectDate,
      openPopper,
      anchorEl,
      isNotSeller,
      isSupAndSeller,
      isCorp,
      updatedAt,
      loadingAgency,
      loadingCalculation,
      loadingCommission,
      loadingCorporation,
      loadingLink,
      loadingDailyData,
      loadingClicks,
      loadingAffiliateCounts,
      loadingAppsflyer,
      loadingAccountOpening,
      accountOpeningGraph,
      accountOpeningGraphTotal,
      loadingClicksGraph,
      loadingRankCorporation,
      loadingProductGraph,
      loadingData,
      newEvent,
      accountOpening,
      accountOpeningCard,
      accountOpeningHasData,
      isMonthly,
      accountOpeningClicks,
      loadingAccountOpeningClicks,
      selectedPlataform,
      SelectProducts,
      levelFilterOptions,
      selectedLevelFilter,
      loadingVouchers,
      vouchers,
      visualizationLevel,
      productName,
      filterProductLabel,
      cards,
    } = this.state;

    const time = formatToBrWithHour(moment(updatedAt).toDate());
    const isAGX = userData ? userData.id === '5f69347d48c1d60004a8916f' : false
    const IS_APPSFLYER = selectedPlataform?.value === 'appsflyer'
    const IS_OMNICHANNEL = selectedPlataform?.value === 'omnichannel'
    const IS_MAQUININHA = filterProduct === maquininhaId

    const filterLevel = dashboardFilter(userData?.level)
    const dashboardLevel = selectedLevelFilter.value ? filterLevel : userData?.level

    const ProductSelect = (className) => <Col md={4} lg={4} className={className}>
      <FormHelperText>Selecione um produto</FormHelperText>
      <Select
        className="custom-select"
        placeholder="Selecione um Produto"
        isDisabled={loadingData}
        value={{
          value: this.state.filterProduct,
          label: this.state.filterProductLabel,
        }}
        options={this.state.SelectProducts}
        onChange={(event) => this.changeSelectProduct(event)}
      />
    </Col>

    const TipoConsignadoSelect = (className) => <Col md={4} lg={4} className={className}>
      <FormHelperText>Tipo de Consignado</FormHelperText>
      <Select
        className='custom-select'
        styles={{ menu: styles => ({ ...styles, zIndex: 999 }) }}
        options={[emptySelect, ...consignadoProducts]}
        value={productName}
        isDisabled={loadingData}
        onChange={this.changeTipoConsignado}
      />
    </Col>

    return (
      <>
        {/* {loading && <LoadingCover dashboard />} */}

        <div className="content" style={{ marginTop: "-35px" }}>
          <Grid fluid style={{ paddingRight: 15 }}>
            <Row style={{ marginTop: 20, position: 'relative', zIndex: 2 }}>
              {
                (showContaDigitalCards && !!levelFilterOptions.length) &&
                <>
                  {ProductSelect()}
                </>
              }
            </Row>
            <Row className="refresh-page-and-search-dash form-group">
              {
               !(showContaDigitalCards && !!levelFilterOptions.length) &&
                <>
                  {ProductSelect('form-group')}
                  {ISClubeAmigo && TipoConsignadoSelect('form-group')}
                </>
              }

              <Col className="form-group" md={4} lg={4} >
                <FormHelperText>Selecione um mês</FormHelperText>
                <DatePicker
                  className={`datepicker-theThird ${loadingData ? 'datepicker-theThird-disabled' : ''}`}
                  dateFormat="MM/yyyy"
                  locale="pt-BR"
                  disabled={loadingData}
                  customInput={
                    <div className="div-datepicker">
                      <div className="col-6">
                        <span className="placeholder-datepicker">
                          {selectDate ? "Mês selecionado:" : "Selecione um mês"}
                        </span>
                      </div>
                      <div className="col-3">
                        <p className="date-datepicker">
                          {selectDate ? getMonthAndYear(selectDate) : ""}
                        </p>
                      </div>
                      <div
                        className="col-3"
                        style={{ alignSelf: "center" }}
                        onMouseEnter={(event) => this.handleMouse(event)}
                        onMouseLeave={() =>
                          this.setState({ openPopper: false })
                        }
                      >
                        {selectDate ? (
                          <></>
                        ) : (
                          <MahoeIcon
                            aria-describedby={"iconTooltip"}
                            name="info"
                            size="23px"
                            fill="#039BE5"
                          />
                        )}
                      </div>
                    </div>
                  }
                  selected={selectDate}
                  showMonthYearPicker
                  onChange={(date, event) => this.setSelectedDate(date, event)}
                  maxDate={new Date()}
                  minDate={new Date("2020-01-01")}
                />
              </Col>

              <Popper
                id={"iconTooltip"}
                open={openPopper}
                anchorEl={anchorEl}
                transition
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper
                      style={{
                        backgroundColor: "#414141",
                        padding: "0px 10px",
                      }}
                    >
                      <p className="tooltipText-datepicker">
                        Selecione o mês que deseja ver os dados.
                      </p>
                    </Paper>
                  </Fade>
                )}
              </Popper>
              {(!!levelFilterOptions.length || ['corporation'].includes(visualizationLevel)  ) &&
                <Col className="form-group relative" md={4} lg={4}>
                    <div>
                      <FormHelperText>Filtrar por {translateLevel(filterLevel, userData?.corpId)}</FormHelperText>
                      <Select
                        className='custom-select'
                        styles={{ menu: styles => ({ ...styles, zIndex: 999 }) }}
                        options={levelFilterOptions}
                        value={selectedLevelFilter}
                        isDisabled={loadingData}
                        onChange={(e) => {
                          this.setState({ selectedLevelFilter: e }, ()=>{
                            this.getDashData()
                            this.getDashboard()
                          })
                        }}
                      />
                    </div>
                </Col>
              }
              {
                showContaDigitalCards &&
                <Col className="form-group relative" md={4} lg={4}>
                  <div>
                    <FormHelperText className='label-badge no-wrap'>Selecione uma plataforma <span className='info-badge info-badge--new'>Novo</span></FormHelperText>
                    <Select
                      className='custom-select'
                      styles={{ menu: styles => ({ ...styles, zIndex: 999 }) }}
                      options={platforms}
                      value={selectedPlataform}
                      isDisabled={loadingData}
                      onChange={(e) => {
                        this.setState({ selectedPlataform: e },()=>{
                          this.getDashboard()
                        })
                      }}
                    />
                  </div>
                </Col>
              }
            </Row>
            {selectDate ? (
              <Row>
                <Col style={{ display: 'flex' }}>
                  <div style={{ paddingLeft: '15px' }}>
                    <p>Dados referentes ao mês de <strong>{translateMonths(moment(selectDate).format('MMMM'))}</strong></p>
                  </div>

                  <div
                    style={{
                      marginLeft: "auto ",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <span className="txtUpdateDashboard">
                      Última atualização:{" "}
                      {this.state.loading ? "Buscando..." : time}
                    </span>

                    <span
                      onClick={this.reload}
                      style={{
                        cursor: "pointer",
                        marginLeft: "10px",
                        marginRight: "10px",
                        zIndex: 998,
                      }}
                    >
                      <MahoeIcon name="reload" size="22px" fill="#039BE5" />
                    </span>
                  </div>
                </Col>
              </Row>
            ) : null}

            <Row>
              {showContaDigitalCards ? (
                IS_OMNICHANNEL?
                <>
                  <Col lg={4} sm={6}>
                    <StatsCard
                      loading={loading ||  loadingAccountOpeningClicks}
                      onReload={() => {  this.getAccountOpenningClicks() }}
                      bigIcon={
                        <MahoeIcon
                          name="touch"
                          size="25px"
                          fill="rgb(136, 136, 136)"
                        />
                      }
                      statsText={accountOpeningClicks || accountOpeningClicks > 0 ? accountOpeningClicks : (accountOpeningClicks === 0 && accountOpeningHasData) ? '0' : 'n/a'}
                      statsValue="Acessos ao formulário (Cliente)"
                      statsIcon={<i className="fa fa-clock-o" />}
                      statsIconText="Atual"
                      tooltip={
                        <p>Clientes que acessaram o formulário</p>
                      }
                    />
                  </Col>

                  <Col lg={4} sm={6}>
                    <div>
                      <StatsCard
                        loading={loading ||  loadingAccountOpening}
                        onReload={() => { this.getAccountOpeningGraph() }}
                        bigIcon={
                          <MahoeIcon
                            name="document-lines"
                            size="25px"
                            fill="rgb(136, 136, 136)"
                          />
                        }
                        statsText={accountOpeningCard?.dados_iniciais || accountOpeningCard?.dados_iniciais > 0 ? accountOpeningCard?.dados_iniciais : (accountOpeningCard?.dados_iniciais === 0 && accountOpeningHasData) ? '0' : 'n/a'}
                        tooltip={
                          <p>Clientes que estão preenchendo os dados iniciais</p>
                        }
                        statsValue="Dados iniciais (Cliente)"
                        statsIcon={<i className="fa fa-calendar-o" />}
                        statsIconText="Iniciou instalação do app"
                      />
                    </div>
                  </Col>

                  <Col lg={4} sm={6}>
                    <div>
                      <StatsCard
                        loading={loading || loadingAccountOpening}
                        onReload={this.getAccountOpeningGraph}
                        bigIcon={
                          <MahoeIcon
                            name="camera"
                            size="25px"
                            fill="rgb(136, 136, 136)"
                          />
                        }
                        statsComponent={
                          <div className="account-opening-container">
                            <div className="datails">
                              <span className="label">Fotos</span>
                              <span className="value">{accountOpeningCard?.fotos || accountOpeningCard?.fotos > 0 ? accountOpeningCard?.fotos : (accountOpeningCard?.fotos === 0 && accountOpeningHasData) ? '0' : 'n/a'}</span>
                            </div>
                            <div className="datails">
                              <span className="label">Reenvio</span>
                              <span className="value">{accountOpeningCard?.reenvio_fotos || accountOpeningCard?.reenvio_fotos > 0 ? accountOpeningCard?.reenvio_fotos : (accountOpeningCard?.reenvio_fotos === 0 && accountOpeningHasData) ? '0' : 'n/a'}</span>
                            </div>
                          </div>
                        }
                        statsValue={`Fotos | Reenvio de fotos (Cliente)`}
                        lineHeight={'unset'}
                        statsIcon={<i className="fa fa-calendar-o" />}
                        statsIconText="Atual"
                        tooltip={
                          <>
                            <p><strong>Fotos</strong>: Clientes enviando fotos</p>
                            <p><strong>Reenvio</strong>: Fotos ilegiveis, aguardando o reenvio</p>
                          </>
                        }
                      />
                    </div>
                  </Col>

                  <Col lg={4} sm={6}>
                    <div>
                      <StatsCard
                        // labelSize='0.75rem'
                        loading={loading || loadingAccountOpening}
                        onReload={() => {  this.getAccountOpeningGraph() }}
                        bigIcon={
                          <MahoeIcon
                            name="growing-up"
                            size="25px"
                            fill="rgb(128, 210, 99)"
                          />
                        }
                        statsText= {accountOpeningCard?.em_analise || accountOpeningCard?.em_analise > 0 ? accountOpeningCard?.em_analise : (accountOpeningCard?.em_analise === 0 && accountOpeningHasData) ? '0' : 'n/a'}
                        statsValue="Em análise (Banco)"
                        statsIcon={<i className="fa fa-info-circle" />}
                        statsIconText="Faltou completar dados no app"
                        tooltip={
                          <p>Aguardando aprovação do Banco</p>
                        }
                      />
                    </div>
                  </Col>

                  <Col lg={4} sm={6}>
                    <div>
                      <StatsCard
                        loading={loading || loadingAccountOpening}
                        onReload={this.getAccountOpeningGraph}
                        bigIcon={
                          <MahoeIcon
                            name="download"
                            size="25px"
                            fill="rgb(136, 136, 136)"
                          />
                        }
                        statsText={accountOpeningCard?.aprovada || accountOpeningCard?.aprovada > 0 ? accountOpeningCard?.aprovada : (accountOpeningCard?.aprovada === 0 && accountOpeningHasData) ? '0' : 'n/a'}
                        statsValue={`Falta finalizar no app (Cliente)`}
                        statsIcon={<i className="fa fa-calendar-o" />}
                        statsIconText="Atual"
                        tooltip={
                          <p>Aguardando cliente baixar o APP</p>
                        }
                      />
                    </div>
                  </Col>

                  <Col lg={4} sm={6}>
                    <div>
                      <StatsCard
                        loading={loading || loadingAccountOpening}
                        onReload={() => { this.getAccountOpeningGraph() }}
                        style={{border:"2px solid rgb(128, 210, 99)"}}
                        bigIcon={
                          <MahoeIcon
                            name="wallet"
                            size="25px"
                            fill="rgb(128, 210, 99)"
                          />
                        }
                        statsText={accountOpeningCard?.conta_aberta || accountOpeningCard?.conta_aberta > 0 ? accountOpeningCard?.conta_aberta : (accountOpeningCard?.conta_aberta === 0 && accountOpeningHasData) ? '0' : 'n/a'}
                        statsValue="Contas abertas (Banco)"
                        statsIcon={<i className="fa fa-clock-o" />}
                        statsIconText="Contas abertas (Banco)"
                        tooltip={
                          <p>Contas abertas com sucesso</p>
                        }
                      />
                    </div>
                  </Col>

                  <Col lg={4} sm={6}>
                    <div>
                      <StatsCard
                        loading={loading || loadingAccountOpening}
                        onReload={this.getAccountOpeningGraph}
                        bigIcon={
                          <MahoeIcon
                            name="rounded-close"
                            size="25px"
                            fill="rgb(170,57,57)"
                          />
                        }
                        statsText={
                          accountOpeningCard?.reprovada || accountOpeningCard?.reprovada > 0 ? accountOpeningCard?.reprovada : (accountOpeningCard?.reprovada === 0 && accountOpeningHasData) ? '0' : 'n/a'
                        }
                        statsValue={'Contas reprovadas (Banco)'}
                        statsIcon={<i className="fa fa-calendar-o" />}
                        statsIconText="Atual"
                        tooltip={
                          <p>Contas reprovadas pelo banco</p>
                        }
                      />
                    </div>
                  </Col>
                </>:
                <>
                  <Col lg={4} sm={6}>
                    <div>
                      <StatsCard
                        loading={loading || loadingAppsFlyerInstall }
                        onReload={this.getAppsflyerInstalls}
                        bigIcon={
                          <MahoeIcon
                            name="growing-up"
                            size="25px"
                            fill="rgb(128, 210, 99)"
                          />
                        }
                        statsText={appsFlyerInstall || 0}
                        statsValue={`Iniciou instalação`}
                        statsIcon={<i className="fa fa-calendar-o" />}
                        statsIconText="Atual"
                      />
                    </div>
                  </Col>

                  <Col lg={4} sm={6}>
                    <div>
                      <StatsCard
                        loading={loading || loadingAppsFlyerData}
                        onReload={this.getAppsflyerData}
                        bigIcon={
                          <MahoeIcon
                            name="document-lines"
                            size="25px"
                            fill="rgb(136, 136, 136)"
                          />
                        }
                        statsText={appsFlyerData || 0}
                        statsValue={`Falta completar dados`}
                        statsIcon={<i className="fa fa-calendar-o" />}
                        statsIconText="Atual"
                      />
                    </div>
                  </Col>

                  <Col lg={4} sm={6}>
                    <div>
                      <StatsCard
                        loading={loading || loadingCalculation }
                        style={{border:'2px solid rgb(128, 210, 99)'}}
                        onReload={this.getCalculations}
                        bigIcon={
                          <MahoeIcon
                            name="wallet"
                            size="25px"
                            fill="rgb(128, 210, 99)"
                          />
                        }
                        statsText={sold || 0}
                        statsValue="Contas abertas"
                        statsIcon={<i className="fa fa-calendar-o" />}
                        statsIconText="Atual"
                      />
                    </div>
                  </Col>

                </>
              ) : (
                userData &&
                !IS_MAQUININHA &&
                ["master", "submaster"].includes(dashboardLevel) &&
                Object.keys(affiliatesNumbers).length > 0 && (
                  <>
                    <Col lg={4} sm={6}>
                      <div>
                        <StatsCard
                          loading={loading || loadingAffiliateCounts}
                          onReload={this.getAffiliateCounts}
                          bigIcon={
                            <MahoeIcon
                              name="person"
                              size="25px"
                              fill="rgb(136, 136, 136)"
                            />
                          }
                          statsText={
                            loading
                              ? "n/a"
                              : affiliatesNumbers?.approved
                          }
                          statsValue="Afiliados aprovados"
                          statsIcon={<i className="fa fa-calendar-o" />}
                          statsIconText="Atual"
                        />
                      </div>
                    </Col>

                    <Col lg={4} sm={6}>
                      <div>
                        <StatsCard
                          loading={loading || loadingAffiliateCounts}
                          onReload={this.getAffiliateCounts}
                          bigIcon={
                            <MahoeIcon
                              name="question"
                              size="25px"
                              fill="rgb(255,218,140)"
                            />
                          }
                          statsText={
                            loading
                              ? "n/a"
                              : affiliatesNumbers?.pending
                          }
                          statsValue="Afiliados que ainda não abriram conta"
                          statsIcon={<i className="fa fa-calendar-o" />}
                          statsIconText="Atual"
                        />
                      </div>
                    </Col>

                    <Col lg={4} sm={6}>
                      <div>
                        <StatsCard
                          loading={loading || loadingAffiliateCounts}
                          onReload={this.getAffiliateCounts}
                          bigIcon={
                            <MahoeIcon
                              name="rounded-close"
                              size="25px"
                              fill="rgb(170,57,57)"
                            />
                          }
                          statsText={
                            loading
                              ? "n/a"
                              : affiliatesNumbers?.refused
                          }
                          statsValue="Afiliados recusados"
                          statsIcon={<i className="fa fa-calendar-o" />}
                          statsIconText="Atual"
                        />
                      </div>
                    </Col>
                  </>
                )
              )}
              {!showContaDigitalCards && (
                <>
                  {!ISClubeAmigo && <><Col lg={4} sm={6}>
                    <div>
                      <StatsCard
                        loading={loading || loadingCalculation}
                        onReload={this.getCalculations}
                        bigIcon={
                          <MahoeIcon
                            name="coins"
                            size="25px"
                            fill="rgb(128, 210, 99)"
                          />
                        }
                        statsText={
                          loading
                            ? "n/a"
                            :  numToBRL(IS_MAQUININHA ? cards?.paid?.receber : sold)
                        }
                        statsValue={IS_MAQUININHA ? 'Total vendido aprovado' : "Total vendido"}
                        statsIcon={<i className="fa fa-calendar-o" />}
                        statsIconText="Atual"
                      />
                    </div>
                  </Col>

                  <Col lg={4} sm={6}>
                    <div data-intro={dataTour.dashboard.bonification}>
                      <StatsCard
                        loading={loading || loadingCalculation}
                        onReload={this.getCalculations}
                        bigIcon={
                          <MahoeIcon
                            name="wallet"
                            size="25px"
                            fill="rgb(128, 210, 99)"
                          />
                        }
                        statsText={
                          loading
                            ? "n/a"
                            :  numToBRL(
                                IS_MAQUININHA ?
                                  ['seller', 'master'].includes(visualizationLevel) ? cards?.pending?.receber : cards?.paid?.repassar
                                : commission
                              )
                        }
                        statsValue={
                          IS_MAQUININHA ?
                            ['seller', 'master'].includes(visualizationLevel) ? 'Total vendido pendente' : 'Bonificação aprovada a repassar'
                          : "Bonificação total"
                        }
                        statsIcon={<i className="fa fa-info-circle" />}
                        statsIconText=" Links com ao menos uma venda"
                      />
                    </div>
                  </Col>

                  <Col lg={4} sm={6}>
                    <div data-intro={dataTour.dashboard.ticketMedio}>
                      <StatsCard
                        loading={loading || loadingCalculation}
                        onReload={this.getCalculations}
                        bigIcon={
                          <MahoeIcon
                            name={IS_MAQUININHA && ['seller', 'master'].includes(visualizationLevel) ? 'wallet' : "growing-up" }
                            size="25px"
                            fill="rgb(128, 210, 99)"
                          />
                        }
                        statsText={
                          loading
                            ? "n/a"
                            : numToBRL(
                              IS_MAQUININHA ?
                                ['seller', 'master'].includes(visualizationLevel) ? cards?.refused?.receber : commission
                              : average
                              )
                        }
                        statsValue={
                          IS_MAQUININHA ?
                            ['seller', 'master'].includes(visualizationLevel) ? 'Total vendido recusado' : 'Bonificação líquida'
                          : "Ticket médio (Bonificação)"}
                        statsIcon={<i className="fa fa-clock-o" />}
                        statsIconText="Atual"
                      />
                    </div>
                  </Col>
                  </>}
                  {(!ISClubeAmigo || ['corporation', 'agency', 'supervisor', 'administrator'].includes(visualizationLevel)) &&
                    <>
                      <Col lg={4} sm={6}>
                        <div>
                          <StatsCard
                            // smallText={IS_MAQUININHA}
                            loading={loading || loadingCalculation}
                            onReload={this.getCalculations}
                            bigIcon={
                              <MahoeIcon
                                name="coins"
                                size="25px"
                                fill="rgb(128, 210, 99)"
                              />
                            }
                            statsText={
                              loading
                                ? "n/a"
                                : this.getMiniCardValue({ product: filterProduct, card: 'pagas' })
                            }
                            statsValue={
                              loading
                                ? "n/a"
                                :  this.getMiniCardText({ product: filterProduct, card: 'pagas', situation })
                            }
                            minorStats={`${userData?.level !== 'seller' && !IS_MAQUININHA ? ` (${situation?.users?.paid || 0} indicador${situation?.users?.paid !== 1 ? 'es' : ''})` : ''}`}
                            subStats={ISClubeAmigo && `Ticket médio: ${numToBRL(sold / situation?.quantity?.paid)}`}
                            statsIcon={<i className="fa fa-calendar-o" />}
                            statsIconText="Atual"
                          />
                        </div>
                      </Col>

                      <Col lg={4} sm={6}>
                        <div>
                          <StatsCard
                            // smallText={IS_MAQUININHA}
                            loading={loading || loadingCalculation}
                            onReload={this.getCalculations}
                            bigIcon={
                              <MahoeIcon
                                name="coins"
                                size="25px"
                                fill="rgb(128, 210, 99)"
                              />
                            }
                            statsText={
                              loading
                                ? "n/a"
                                : this.getMiniCardValue({ product: filterProduct, card: 'pendentes' })
                            }
                            statsValue={
                              loading
                                ? "n/a"
                                : this.getMiniCardText({ product: filterProductLabel, card: 'pendentes', situation })
                            }
                            minorStats={`${userData?.level !== 'seller' && !IS_MAQUININHA ? ` (${situation?.users?.pending || 0} indicador${situation?.users?.pending !== 1 ? 'es' : ''})`  : ''}`}
                            subStats={ISClubeAmigo && `Ticket médio: ${numToBRL(situation?.money?.pending / situation?.quantity?.pending)}`}
                            statsIcon={<i className="fa fa-calendar-o" />}
                            statsIconText="Atual"
                          />
                        </div>
                      </Col>

                      <Col lg={4} sm={6}>
                        <div>
                          <StatsCard
                            // smallText={IS_MAQUININHA}
                            loading={loading || loadingCalculation}
                            onReload={this.getCalculations}
                            bigIcon={
                              <MahoeIcon
                                name="coins"
                                size="25px"
                                fill="rgb(128, 210, 99)"
                              />
                            }
                            statsText={
                              loading
                                ? "n/a"
                                :  this.getMiniCardValue({ product: filterProduct, card: 'recusadas' })
                            }
                            statsValue={
                              loading
                                ? "n/a"
                                : this.getMiniCardText({ product: filterProduct, card: 'recusadas', situation })
                            }
                            minorStats={`${userData?.level !== 'seller' && !IS_MAQUININHA ? ` (${situation?.users?.refused || 0} indicador${situation?.users?.refused !== 1 ? 'es' : ''})`  : ''}`}
                            subStats={ISClubeAmigo && `Ticket médio: ${numToBRL(situation?.money?.refused / situation?.quantity?.refused)}`}
                            statsIcon={<i className="fa fa-calendar-o" />}
                            statsIconText="Atual"
                          />
                        </div>
                      </Col>
                    </>
                  }
                  {ISClubeAmigo &&
                  <>
                    <Col lg={4} sm={6}>
                      <div>
                        <StatsCard
                          loading={loading || loadingVouchers}
                          onReload={this.getVouchers}
                          bigIcon={
                            <MahoeIcon
                              name="coins"
                              size="25px"
                              fill="rgb(128, 210, 99)"
                            />
                          }
                          statsText={
                            loading
                              ? "n/a"
                              : numToBRL(vouchers?.claimed?.value)
                          }
                          statsValue={
                            loading
                              ? "n/a"
                              : `Vouchers resgatados: ${vouchers?.claimed?.count || 0}`
                          }
                          statsIcon={<i className="fa fa-calendar-o" />}
                          statsIconText="Atual"
                        />
                      </div>
                    </Col>

                    <Col lg={4} sm={6}>
                      <div>
                        <StatsCard
                          loading={loading || loadingVouchers}
                          onReload={this.getVouchers}
                          bigIcon={
                            <MahoeIcon
                              name="coins"
                              size="25px"
                              fill="rgb(128, 210, 99)"
                            />
                          }
                          statsText={
                            loading
                              ? "n/a"
                              : numToBRL(vouchers?.approved?.value)
                          }
                          statsValue={
                            loading
                              ? "n/a"
                              : `Vouchers aprovados: ${vouchers?.approved?.count ||  0}`
                          }
                          statsIcon={<i className="fa fa-calendar-o" />}
                          statsIconText="Atual"
                        />
                      </div>
                    </Col>

                    <Col lg={4} sm={6}>
                      <div>
                        <StatsCard
                          loading={loading || loadingVouchers}
                          onReload={this.getVouchers}
                          bigIcon={
                            <MahoeIcon
                              name="coins"
                              size="25px"
                              fill="rgb(128, 210, 99)"
                            />
                          }
                          statsText={
                            loading
                              ? "n/a"
                              : numToBRL(vouchers?.pending?.value)
                          }
                          statsValue={
                            loading
                              ? "n/a"
                              : `Vouchers pendentes: ${vouchers?.pending?.count || 0}`
                          }
                          statsIcon={<i className="fa fa-calendar-o" />}
                          statsIconText="Atual"
                        />
                      </div>
                    </Col>
                  </>
                }
                </>
              )}
              {!isSupAndSeller && showContaDigitalCards ? (
                <>
                  <Col lg={4} sm={6}>
                    <StatsCard
                      loading={loading || loadingCommission}
                      onReload={this.getCommissions}
                      bigIcon={
                        <MahoeIcon
                          name="coins"
                          size="25px"
                          fill="rgb(128, 210, 99)"
                        />
                      }
                      statsText={
                        loading
                          ? "n/a"
                          : numToBRL(pendingCommissionConta)
                      }
                      statsValue="Bonificação total pendente"
                      statsIcon={<i className="fa fa-calendar-o" />}
                      statsIconText="Atual"
                    />
                  </Col>
                  <Col lg={4} sm={6}>
                    <StatsCard
                      loading={loading || loadingCommission}
                      onReload={this.getCommissions}
                      bigIcon={
                        <MahoeIcon
                          name="coins"
                          size="25px"
                          fill="rgb(128, 210, 99)"
                        />
                      }
                      statsText={
                        loading
                          ? "n/a"
                          : numToBRL(paidCommissionConta)
                      }
                      statsValue={"Bonificação total (Conta aberta)"}
                      statsIcon={<i className="fa fa-info-circle" />}
                      statsIconText=" Links com ao menos uma venda"
                    />
                  </Col>

                  <Col lg={4} sm={6}>
                    {isCorp ? (
                      <StatsCard
                        loading={loading || loadingAgency}
                        onReload={this.getAgency}
                        bigIcon={
                          <MahoeIcon
                            name="coins"
                            size="25px"
                            fill="rgb(128, 210, 99)"
                          />
                        }
                        statsText={
                          loading
                            ? "n/a"
                            : numToBRL(agencyCommissionConta)
                        }
                        statsValue={"Bonificação Total Lojas"}
                        statsIcon={<i className="fa fa-info-circle" />}
                      />
                    ) : (
                      <StatsCard
                        loading={loading || loadingCorporation}
                        onReload={this.getCorporations}
                        bigIcon={
                          <MahoeIcon
                            name="store"
                            size="25px"
                            fill="rgb(128, 210, 99)"
                          />
                        }
                        statsText={loading ? "n/a" : totalCorpsWithConta}
                        statsValue={"Corporações que vendem conta digital"}
                        statsIcon={<i className="fa fa-info-circle" />}
                      />
                    )}
                  </Col>
                </>
              ) : null}

              {isSupAndSeller ? (
                showContaDigitalCards ? (
                  <>
                    <Col lg={4} sm={6}>
                      <StatsCard
                        loading={loading || loadingCommission}
                        onReload={this.getCommissions}
                        bigIcon={
                          <MahoeIcon
                            name="coins"
                            size="25px"
                            fill="rgb(128, 210, 99)"
                          />
                        }
                        statsText={
                          loading
                            ? "n/a"
                            : numToBRL(pendingCommissionConta)
                        }
                        statsValue="Bonificação Total Pendente"
                        statsIcon={<i className="fa fa-calendar-o" />}
                        statsIconText="Atual"
                      />
                    </Col>
                    <Col lg={4} sm={6}>
                      <StatsCard
                        loading={loading || loadingCommission}
                        onReload={this.getCommissions}
                        bigIcon={
                          <MahoeIcon
                            name="coins"
                            size="25px"
                            fill="rgb(128, 210, 99)"
                          />
                        }
                        statsText={
                          loading
                            ? "n/a"
                            : numToBRL(paidCommissionConta)
                        }
                        statsValue={"Bonificação Total (Conta Aberta)"}
                        statsIcon={<i className="fa fa-info-circle" />}
                        statsIconText=" Links com ao menos uma venda"
                      />
                    </Col>
                  </>
                ) : (
                  <>
                    <Col lg={4} sm={6}>
                      <StatsCard
                        loading={loading || loadingLink}
                        onReload={this.getTotalLinks}
                        bigIcon={
                          <MahoeIcon
                            name="resize"
                            size="25px"
                            fill="rgb(136, 136, 136)"
                          />
                        }
                        statsText={loading ? "n/a" : links}
                        statsValue={ISClubeAmigo && userData?.level !== 'seller' ? 'Indicadores ativados' :"Links gerados"}
                        statsIcon={<i className="fa fa-refresh" />}
                        statsIconText="Atual"
                      />
                    </Col>
                  </>
                )
              ) : (
                <>
                  <Col lg={4} sm={6}>
                    <StatsCard
                      loading={loading || loadingLink}
                      onReload={this.getTotalLinks}
                      bigIcon={
                        <MahoeIcon
                          name="resize"
                          size="25px"
                          fill="rgb(136, 136, 136)"
                        />
                      }
                      statsText={loading ? "n/a" : links}
                      statsValue={ISClubeAmigo && userData?.level !== 'seller' ? 'Indicadores ativados' :"Links gerados"}
                      statsIcon={<i className="fa fa-refresh" />}
                      statsIconText="Atual"
                    />
                  </Col>
                </>
              )}
              {showContaDigitalCards ?
               ((IS_APPSFLYER &&
                <Col lg={4} sm={6}>
                  <StatsCard
                    loading={loading || loadingClicks }
                    onReload={() => { this.getClicks() }}
                    bigIcon={
                      <MahoeIcon
                        name="touch"
                        size="25px"
                        fill="rgb(136, 136, 136)"
                      />
                    }
                    statsText={loading ? "n/a" : clicked}
                    statsValue="Número de cliques "
                    statsIcon={<i className="fa fa-clock-o" />}
                    statsIconText="Atual"
                  />
                </Col>)
                ):(
                <Col lg={4} sm={6}>
                  <StatsCard
                    loading={loading || loadingClicks}
                    onReload={this.getClicks}
                    bigIcon={
                      <MahoeIcon
                        name="touch"
                        size="25px"
                        fill="rgb(136, 136, 136)"
                      />
                    }
                    statsText={loading ? "n/a" : clicked}
                    statsValue="Número de cliques"
                    statsIcon={<i className="fa fa-clock-o" />}
                    statsIconText="Atual"
                  />
                </Col>
                )
              }
              {
                !['seller'].includes(userData?.level) && (
                  <Col lg={4} sm={6}>
                    <StatsCard
                      loading={loading || loadingDailyData}
                      onReload={this.getDailyData}
                      bigIcon={
                        <MahoeIcon
                          name="wallet"
                          size="25px"
                          fill="rgb(47, 131, 209)"
                        />
                      }
                      statsText={loading ? "n/a" : dailySum}
                      statsValue={showContaDigitalCards ? "Contas abertas hoje" : "Propostas aprovadas hoje"}
                      statsIcon={<i className="fa fa-clock-o" />}
                      statsIconText="Atual"
                    />
                  </Col>
                )
              }
            </Row>
            <Row>
              <Col md={this.getColMD()}>
                <div data-intro={dataTour.dashboard.chartClicks}>
                  {(loading || loadingClicksGraph)
                    ? <Skeleton className="card-stats" animation="wave" variant="rect" width={"100%"} height={"100%"} style={{ borderRadius: 16, marginBottom: 30, minHeight: 400 }} />
                    : <ClicksChart data={weekly} bigger={showContaDigitalCards} onReload={this.getClicksGraph} />}
                </div>
              </Col>
              {filterProduct && isNotSeller ? (
                (!loading && !loadingRankCorporation)
                  ? (
                    <>
                      <Col md={showContaDigitalCards ? 3 : 4}>
                        {/* {!rankCorporation
                          ? <Skeleton className="card-stats" animation="wave" variant="rect" width={"100%"} height={"100%"} style={{ borderRadius: 16, minHeight: 400 }} /> */}
                        {/* : */}
                        <TopFive
                          data={rankCorporation}
                          name={CorpOrAgencieRanq}
                          onReload={this.getRankCorporation}
                          category={this.state.filterProductLabel.toLowerCase()}
                        />
                        {/* } */}
                      </Col>
                    </>

                  ) : (
                    <Col md={showContaDigitalCards ? 3 : 4}>
                      <Skeleton className="card-stats" animation="wave" variant="rect" width={"100%"} height={"100%"} style={{ borderRadius: 16, minHeight: 400 }} />
                    </Col>
                  )
              ) : (
                <> </>
              )}
              {showContaDigitalCards && (
                <Col md={4}>
                  {
                    loading || (loadingAppsFlyerEvents && IS_APPSFLYER) || (loadingAccountOpening && IS_OMNICHANNEL)
                      ? <Skeleton className="card-stats" animation="wave" variant="rect" width={"100%"} height={"100%"} style={{ borderRadius: 16, minHeight: 400 }} />
                      : <Card
                        content={
                          <div >
                            <div style={{ marginBottom: 10 }} >
                              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap', gap: '10px' }}>
                                <h4 className="title">Eventos</h4>
                              </div>
                               <p className="category">Quantidade de pessoas em cada passo</p>
                            </div>
                            <div>
                              {
                                this.handleEventGraph({
                                  data: IS_OMNICHANNEL ? accountOpeningGraph : appsFlyerGraphData,
                                  isPie: false,
                                  IS_OMNICHANNEL: IS_OMNICHANNEL
                                })
                              }
                            </div>
                          </div>
                        }
                      />
                  }
                </Col>
              )}
            </Row>
          </Grid>
        </div>
      </>
    );
  }
}

export default Dashboard;