import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import {
  Content,
  MediumTitle,
  Line,
  DefaultText,
  SmallTitle,
  RowTemplate,
  ColumnText,
  SituationGrid,
} from './styled'

class ImportDataModal extends Component {
  constructor(props) {
    super(props)

    this.state = {

    }
  }

  render() {
    const { show, handleClose } = this.props
    const { } = this.state

    return (
      <Modal show={show} onHide={handleClose} bsSize="large" dialogClassName="import-data-modal">
        <Modal.Header>
          <Modal.Title>
            Informações sobre a importação
            <button className="btn btn-fill btn-mlm-danger" style={{ color: "black", position: "absolute", right: 5, top: 5 }} onClick={handleClose}>X</button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Content>
            {/* HEADERS */}
            <MediumTitle>Cabeçalho e dados</MediumTitle>
            <Line />
            <DefaultText>
              <p>A primeira linha do seu CSV deve <b>obrigatoriamente</b> possuir <b>todos</b> os campos com os nomes corretos e separados <b>1 por coluna</b>, como mostrados na tabela a seguir:</p>
              <RowTemplate>
                <ColumnText>PRODUTO</ColumnText>
                <ColumnText>SITUACAO</ColumnText>
                <ColumnText>DATA_PAGAMENTO</ColumnText>
                <ColumnText>VALOR</ColumnText>
                <ColumnText>ESTADO</ColumnText>
                <ColumnText>DDD</ColumnText>
                <ColumnText>NOME</ColumnText>
                <ColumnText>HASH</ColumnText>
                <ColumnText>DATA_PROPOSTA</ColumnText>
                <ColumnText>CG_PROPOSTA</ColumnText>
              </RowTemplate>
              <p><b>Todas as colunas são obrigatórias (mesmo que os valores sejam opcionais)</b></p>
            </DefaultText>
            <br />

            {/* DADOS OBRIGATÓRIOS */}
            <MediumTitle>Dados Obrigatórios</MediumTitle>
            <Line />
            <DefaultText>
              <SmallTitle>Coluna: PRODUTO</SmallTitle>
              <p>Possíveis valores:</p>
              <SituationGrid>
                <ul>
                  <li>cartao</li>  
                  <li>novo</li>
                  <li>portabidade</li>
                  <li>refinanciamento</li>
                  <li>fgts</li>
                </ul>                
                {/* <SituationRowGrid>
                  <ColumnText><b>Cartão</b></ColumnText>
                  <ColumnText>cartao</ColumnText>
                  <ColumnText>cartao consignado</ColumnText>
                </SituationRowGrid> */}
               {/* <SituationRowGrid>
                  <ColumnText><b>Novo</b></ColumnText>
                  <ColumnText>novo</ColumnText>
                  <ColumnText>novo emprestimo</ColumnText>
                  <ColumnText>margem livre</ColumnText>
                </SituationRowGrid>
                <SituationRowGrid>
                  <ColumnText><b>Portabilidade</b></ColumnText>
                  <ColumnText>port</ColumnText>
                  <ColumnText>portabidade</ColumnText>
                </SituationRow Grid>
                <SituationRowGrid>
                  <ColumnText><b>Refinanciamento</b></ColumnText>
                  <ColumnText>refin</ColumnText>
                  <ColumnText>refinanciamento</ColumnText>
                </SituationRowGrid>
                <SituationRowGrid>
                  <ColumnText><b>Saque</b></ColumnText>
                  <ColumnText>saque</ColumnText>
                  <ColumnText>saque complementar</ColumnText>
                </SituationRowGrid> */}
              </SituationGrid>
              <br/>
              <SmallTitle>Coluna: NOME</SmallTitle>
              <p>Possíveis valores:</p>
              <ul>
                <li>Exemplo de Nome</li>
              </ul>
              <br />
              <SmallTitle>Coluna: VALOR</SmallTitle>
              <p>O valor decimal deve ser separado por <b>ponto</b></p>
              <p>Possíveis valores:</p>
                <ul>
                  <li>2002</li>
                  <li>59.9</li>
                  <li>738</li>
                  <li>38</li>
                  <li>304.1</li>
                </ul>
              <br />

              
              <SmallTitle>Coluna: SITUACAO</SmallTitle>
              {/* <p>Neste campo deve ser escrito a situação do pagamento, a seguir temos os valores validos que podem ser utilizados:</p> */}
              <p>Possíveis valores:</p>
              <SituationGrid>
                <ul>
                  <li>analise</li>
                  <li>pago</li>
                  <li>recusado</li>
                </ul>
                {/* <SituationRowGrid>
                  <ColumnText><b>Em análise</b></ColumnText>
                  <ColumnText>análise</ColumnText>
                  <ColumnText>conta em análise</ColumnText>
                  <ColumnText>em análise</ColumnText>
                  <ColumnText>conta sem analise</ColumnText>
                  <ColumnText>em analise</ColumnText>
                  <ColumnText>pending</ColumnText>
                </SituationRowGrid>
                <SituationRowGrid>
                  <ColumnText><b>Pago</b></ColumnText>
                  <ColumnText>pago</ColumnText>
                  <ColumnText>conta aberta</ColumnText>
                  <ColumnText>aprovado</ColumnText>
                  <ColumnText>paid</ColumnText>
                </SituationRowGrid>
                <SituationRowGrid>
                  <ColumnText><b>Recusado</b></ColumnText>
                  <ColumnText>recusado</ColumnText>
                  <ColumnText>conta recusada</ColumnText>
                  <ColumnText>refused</ColumnText>
                </SituationRowGrid> */}
              </SituationGrid>
              <br />


              <SmallTitle>Coluna: DATA_PAGAMENTO</SmallTitle>
              <p>Mesmo com situação em análise, importá-lo com data de pagamento atualizará a situação pago.</p>
              {/* <p>Colocar uma data de pagamento em uma proposta recusada, este campo será ignorado.</p> */}
              <p>Possíveis valores:</p>
              <ul>
                <li>20/10/2020</li>
              </ul>
              <br />

              
              <SmallTitle>Coluna: HASH</SmallTitle>
              <p>Neste campo deve ser colocado o hash da indicação.</p>
              <p>Possíveis valores:</p>
              <ul>
                <li>5efcd202f100f570213df3fa</li>
              </ul>
            </DefaultText>
            <br />

            {/* DADOS OPCIONAIS */}
            <MediumTitle>Dados Opcionais</MediumTitle>
            <Line />
            <DefaultText>
              <SmallTitle>Coluna: ESTADO</SmallTitle>
              <p>Possíveis valores:</p>
              <ul>
                <li>SP</li>
                <li>PA</li>
                <li>RJ</li>
              </ul>
              <br />

              
              <SmallTitle>Coluna: DDD</SmallTitle>
              <p>Possíveis valores:</p>
              <ul>
                <li>15</li>
                <li>20</li>
              </ul>
              <br />

              <SmallTitle>Coluna: DATA_PROPOSTA</SmallTitle>
              <p><b>Dia/Mes/Ano</b></p>
              <p>Possíveis valores:</p>
              <ul>
                <li>20/10/2020</li>
              </ul>
            </DefaultText>
            <br />
            <MediumTitle>Atualizar dados</MediumTitle>
            <DefaultText>
              <SmallTitle>Coluna: CD_PROPOSTA</SmallTitle>
              <p>Para atualizar sua proposta adicione o código da proposta como valor da coluna CD_PROPOSTA</p>
              <p>Esta atualização só pode ser realizada com propostas criadas manualmente!</p>
              <p>Uma proposta criada manualmente pode ser idenficada por seu código com prefixo "M"</p>
              <p>Possíveis valores:</p>
              <ul>
                <li>M-9171</li>
              </ul>
            </DefaultText>
            <br />
            </Content>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-fill btn-mlm" onClick={handleClose}>Fechar</button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default ImportDataModal