import React, { Component } from 'react'
import { Modal } from 'react-bootstrap';
import HistoryTable from "components/HistoryTable"
import CleanCard from "components/Card/CleanCard"

class historyOperatorModal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { handleClose } = this.props

    return (
      <>
        <Modal 
          show={this.props.open} 
          bsSize="large"
          onHide={handleClose}
        >
          <Modal.Header>
          <Modal.Title>Teste</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{maxHeight:"580px", overflowY:"auto"}}>
                <HistoryTable rows={this.props.rows}  />
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-fill btn-mlm btn-mlm-sm" onClick={handleClose}> Fechar </button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default historyOperatorModal