import React, { Component } from "react";
import { Modal } from "react-bootstrap/";
import QRCode from "qrcode.react";

import appstore from "../assets/img/applestore.png";
import googleplay from "../assets/img/googleplay.png";

const panPlatformLink = "https://go.onelink.me/zIun/986f79e5";

class PlatformSelectModal extends Component {
  
  adobeAnalyticsAction(origin) {
    var eventDetail = {"detail": {"button": `Click:BP:Cadastro:BaixeApp:${origin}` }} 
    var interactionClick = new CustomEvent("event-download-app", eventDetail)
    document.body.dispatchEvent(interactionClick);    
  }

  render() {
    const { android, ios, show, onHide } = this.props;
    return (
      <>
        <Modal
          show={show}
          onHide={onHide}
          dialogClassName="modal-90w"
          aria-labelledby="agency-data"
        >
          <Modal.Body>
            <div className="platform-select-modal">
              <h4 className="modal-qr-title">Baixe o APP agora mesmo!</h4>
              <h5 className="modal-qr-text">
                Leia o QR Code com a camêra do seu celular, baixe o APP e faça
                sua conta.
              </h5>
              <QRCode
                className="qrcode-modal"
                value={panPlatformLink}
                renderAs="svg"
                size={128}
              />
              <p className="gotostore">Ou vá para sua loja:</p>
              <a href={ios} target="_blank" rel="noopener noreferrer" onClick={ () => this.adobeAnalyticsAction("AppStore")}>
                <img alt="Apple Store logo" src={appstore} className="available-on-img" />
              </a>
              <a href={android} target="_blank" rel="noopener noreferrer" onClick={ () => this.adobeAnalyticsAction("GooglePlay")}>
                <img alt="Google Play logo" src={googleplay} className="available-on-img" />
              </a>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-fill btn-danger" onClick={onHide}>
              Fechar
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default PlatformSelectModal;
