import React, { useState, useEffect } from 'react'
import InputMask from 'react-input-mask'
import { TextField } from '@material-ui/core'
import VoucherReportTable from 'components/VoucherReportTable';
import { useHistory } from 'react-router'
import mainApi from "services/mainApi"
import { Dialog } from "components/Alert/CustomAlert";

const initalSwalState = {
  type: '',
  show: false,
  title: '',
  text: ''
}


function UserVoucherReport () {
  const [swalMessage, setSwalMessage] = useState(initalSwalState)
  const [CPF, setCPF] = useState('')
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [showNotFetchedMessage, setShowNotFetchedMessage] = useState(true)
  const [data, setData] = useState()

  useEffect(() => {
    const CPF = history.location.state
    if (CPF) {
      getReport(null, {CPF})
      setCPF(CPF)
    }
  }, [history])

  async function getReport(e, propValue) {
    if (e) e.preventDefault()

    const params = {
      CPF: String(propValue?.CPF || CPF).replace(/\D/g, "")
    }
    if (!params.CPF) return

    setLoading(true)
    try {
      const response = await mainApi.get(`/voucher/report/cpf/${params.CPF}`)
      const data = response.data.data.dados
      setData(data)
      if (showNotFetchedMessage) setShowNotFetchedMessage(false)
    } catch (err) {
      setSwalMessage({
        show: true,
        type: 'warning',
        title: 'Atenção',
        text: err.messsage || err.response?.data?.message || 'Ocorreu um problema. Tente novamente mais tarde.'
      })
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='content user-voucher-report'>
      <Dialog
        {...swalMessage}
        onConfirm={() => setSwalMessage(initalSwalState)}
      />
      <form
        className='user-voucher-report__filters'
        onSubmit={getReport}
      >
        <InputMask
          mask={'999.999.999-99'}
          id='cpfInput'
          maskChar=''
          placeholder='Pesquise o CPF do indicador Clube Amigo'
          value={CPF}
          onChange={(event) => {
            setCPF(event.target.value)
          }}
        >
          {() => (
            <TextField
              className={'input-background'}
              label='CPF'
              InputProps={{
                classes: {
                  input: 'mui-input-control',
                  root: 'mui-input-control',
                },
              }}
              InputLabelProps={{
                classes: {
                  root: 'mui-input-control mui-label',
                },
              }}
              variant='outlined'
            />
          )}
        </InputMask>
        <div className='footer__buttons' >
          <button
            className='btn btn-mlm btn-round btn-fill btn-block'
            disabled={loading}
          >
            {loading && <> <i aria-hidden="true" className="af-button-icon fa fa-spinner fa-spin"></i>{' '}</>}
            Pesquisar
          </button>
        </div>
      </form>
      <div className='user-voucher-report__table'>
        <VoucherReportTable data={data} showNotFetchedMessage={showNotFetchedMessage} />
      </div>
    </div>
  )
}

export default UserVoucherReport
