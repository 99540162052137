import BasicPopover from 'components/BasicPopover/BasicPopover'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import getUserData from 'utils/checkUser'
import { formatUserData } from './formatData'

function UserDetails({ user }) {
  const [userData, setUserData] = useState('')
  const history = useHistory()

  const requestUserData = async () => {
    const userData = await getUserData(history)

    setUserData(userData)
  }

  useEffect(() => {
    requestUserData()
  }, [])

  return (
    <>
      <h3 className="header">{user?.name}</h3>
      <div className="p-3">
        <table className='support-table'>
          <tbody>
            {Object.entries({ ...user })?.map(([key, value]) => {
              let item = formatUserData({ key, value, strictId: userData?.corpId })
              if (!item) return
              return (
                <tr key={item?.key}>
                  <th>{item?.key}</th>
                  <td>
                    {(String(item?.value).length > 25)
                      ? <BasicPopover
                        noWrap={false}
                        text={String(item?.value)}
                        buttonText={`${String(item?.value).slice(0,25)}...`}
                      />
                      : String(item?.value)
                    }
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default UserDetails
