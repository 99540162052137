import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap/";
import mainApi from "services/mainApi";
import { LoadingAlert, Dialog } from "../components/Alert/CustomAlert";
import { Row, Col } from "react-bootstrap";
import { TextField, FormControl, InputAdornment } from "@material-ui/core";
import InputMask from "react-input-mask";
import { numberLength } from 'utils/numberMethods'

class AgencyModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      newCommission: 0,
      newName: '',
      editCommission: false,
      editAgencyName: false,
      swalMessage: [],
      swalMessageShow: false,
      swalShowLoading: false,
      zipcode: "",
      street: "",
      neighborhood: "",
      number: "",
      city: "",
      state: "",
      additional: "",
      cantUpdateAddress: true,
      addressInvalidFields: {},
      commission: '',
      commissionMessage: '',
      cantUpdateCommission: true,
      addressId: ''
    };

    this.numberRef = React.createRef();

    this.states = [
      "AC",
      "AL",
      "AP",
      "AM",
      "BA",
      "CE",
      "DF",
      "ES",
      "GO",
      "MA",
      "MT",
      "MS",
      "MG",
      "PA",
      "PB",
      "PR",
      "PE",
      "PI",
      "RJ",
      "RN",
      "RS",
      "RO",
      "RR",
      "SC",
      "SP",
      "SE",
      "TO",
    ];
  }

  setAgencyCommission = async (agencyId) => {
    const { commission } = this.state;
    this.setState({ swalShowLoading: true });

    try {
      const response = await mainApi.patch(
        `/corporation/agency/${agencyId}/commission`,
        { commission: Number(commission) }
      );
      this.setState({
        swalMessage: ["success", `${response.data.message}`],
        swalMessageShow: true,
        editCommission: false,
      });
    } catch (err) {
      this.setState({ swalShowLoading: false });
      if (!err.response) {
        if (err.status === 404) {
        }
      }
      this.setState({
        swalMessage: ["error", `${err.response.data.message}`],
        swalMessageShow: true,
        editCommission: false,
      });
      this.setState({ swalShowLoading: false });
    }
    this.setState({ swalShowLoading: false });
  };

  editAgencyName = () => {
    this.setState({ editAgencyName: true });
  };

  setAgencyName = async (agencyId) => {
    const { newName } = this.state;

    this.setState({ swalShowLoading: true });
    try {
      const response = await mainApi.patch(
        `/agency/${agencyId}`,
        { name: newName },
      )
      this.setState({
        swalMessage: ["success", `${response.data.message}`],
        swalMessageShow: true,
        editAgencyName: false
      })
    } catch (err) {
      this.setState({ swalShowLoading: false });
      this.setState({ swalShowLoading: false });
    }
    this.setState({ swalShowLoading: false });
  }

  updateAgencyAddress = async () => {
    const {
      addressId,
      street,
      zipcode,
      neighborhood,
      city,
      number,
      state,
      additional,
    } = this.state;

    const agency = this.props.agency

    const data = {
      street,
      zipcode: String(zipcode).replace(/\D/g, "") || zipcode,
      neighborhood,
      city,
      number,
      state,
      additional,
    };    

    this.setState({ swalShowLoading: true });
    try {
      if (addressId) {
        await mainApi.put(`/agency/${agency._id}/address/${addressId}`, data);        
      } else {
        await mainApi.post(
          `/addresses`,
          { ...data, agencyId: agency._id }
        );        
      }

      this.setState({
        swalMessage: ["success", "Endereço da loja atualizado com sucesso!"],
        swalMessageShow: true,
      });
    } catch (err) {
      if (!err.response) {
        if (err.message === "Network Error") {
          this.showMessage(
            "error",
            "Não foi possível se comunicar com o servidor, tente novamente mais tarde."
          );
          this.setState({ loading: false });
          return;
        }
        if (err.code === "ECONNABORTED")
          this.showMessage("error", "Tempo de espera atingido.");
        this.setState({ loading: false });
        return;
      }
      if (err.response.status === 422) {
        const invalid = {}
        err.response.data.fields.forEach(field => {
          invalid[field.name] = field.message
        })
        this.setState({ addressInvalidFields: invalid })
      }
      if (err.response.status === 400) {
        const invalid = {}
        err.response.data.fields.forEach(field => {
          invalid[field] = 'Campo obrigatório.'
        })
        this.setState({ addressInvalidFields: invalid })
      }

      this.setState({
        swalMessage: [
          "warning",
          "Temos um problema",
          err.response.data.message,
          ""
        ],
        swalMessageShow: true
      });
    }

    this.setState({ swalShowLoading: false })
  };

  handleCep = async event => {
    const searchCep = event.target.value;
    if (searchCep === this.state.zipcode) return;
    this.setState({ zipcode: searchCep });
    const rawCep = searchCep.replace(/\D/g, "");
    if (rawCep.length === 8) {
      try {
        const response = await mainApi.get(`/resources/cep/${rawCep}`);
        const {
          street,
          neighborhood,
          city,
          state,
          additional
        } = response.data.address;
        this.setState({ street, neighborhood, city, state, additional });
        this.numberRef.current.focus();
      } catch (error) { }
    }
  };

  clearProps = () => {
    this.setState({
      zipcode: '',
      city: '',
      neighborhood: '',
      number: '',
      street: '',
      state: '',
      addressId: '',
      editCommission: false,
      editAgencyName: false,
      cantUpdateAddress: true,
      cantUpdateCommission: true,
      commission: ''
    })
  }


  componentDidUpdate(prevProps) {
    const { address, show, agency } = this.props;

    if ((show !== prevProps.show) && show === false) {
      this.clearProps()
    }

    if (address?.zipcode !== prevProps.address.zipcode) {
      this.setState({
        zipcode: address.zipcode,
        city: address.city,
        neighborhood: address.neighborhood,
        number: address.number,
        street: address.street,
        state: address.state,
        addressId: address._id
      })
    }

    if (agency?.commission !== prevProps.agency.commission) {
      this.setState({ commission: String(agency.commission) })
    }
  }

  render() {
    const {
      agency,
      show,
      onHide,
      level,
      onSuccess,
    } = this.props;


    const {
      swalMessage,
      swalMessageShow,
      swalShowLoading,
      editAgencyName,
      newName,
      zipcode,
      street,
      number,
      additional,
      neighborhood,
      city,
      state,
      cantUpdateAddress,
      addressInvalidFields,
      commission,
      commissionMessage,
      cantUpdateCommission
    } = this.state;

    return (
      <>
        <Dialog
          type={swalMessage[0]}
          show={swalMessageShow}
          title={swalMessage[0] === "success" ? "Sucesso!" : "Erro"}
          onConfirm={() => {
            this.setState({ swalMessageShow: false });
            if (swalMessage[0] === "success") onSuccess();
          }}
          text={swalMessage[1]}
          boldText={swalMessage[2]}
        />
        <LoadingAlert show={swalShowLoading} />
        <Modal
          show={show}
          onHide={() => {
            this.clearProps()
            onHide();
          }}
          dialogClassName="modal-90w"
          aria-labelledby="agency-data"
        >
          <Modal.Header closeButton>
            <Modal.Title id="agency-data-title">
              {editAgencyName && ['corporation', 'subcorp'].includes(level) ? (
                <>
                  <input
                    style={{ margin: 5, height: 38, maxWidth: 268 }}
                    type="text"
                    onChange={(e) => {
                      this.setState({ newName: e.target.value })
                    }}
                    value={newName}
                  />
                  <Button
                    style={{ margin: 5 }}
                    className="btn btn-fill btn-mlm btn-mlm-sm"
                    onClick={() => {
                      this.setAgencyName(agency._id)
                    }}
                  >
                    Salvar
                  </Button>
                </>
              ) : (
                  <>
                    {agency ? agency.name : "..."}
                    {['corporation', 'subcorp'].includes(level) && agency.name !== "Matriz" && (
                      <button
                        className="link-style"
                        style={{ marginRight: 4 }}
                        onClick={() => {
                          this.editAgencyName();
                          this.setState({ newName: agency.name })
                        }}
                      >
                        Editar nome
                      </button>
                    )}
                  </>
                )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4 style={{ margin: '5px 0 15px 0' }}>Bonificação</h4>
            <Row>
              <Col md={6} lg={6}>
                <TextField
                  type="number"
                  label="Bonificação"
                  InputProps={{
                    classes: {
                      input: "mui-input-control",
                      root: "mui-input-control",
                    },
                    inputMode: "tel",
                    pattern: "[0-9]*",
                    startAdornment: (
                      <InputAdornment
                        style={{
                          position: "absolute",
                          marginLeft: Number.isInteger(
                            Number(commission)
                          )
                            ? 40
                            : 50,
                        }}
                        position="start"
                      >
                        {" "}
                          %
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    classes: {
                      root: "mui-input-control mui-label",
                    },
                  }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  inputProps={{
                    step: "0.1",
                  }}
                  variant="outlined"
                  value={commission}
                  fullWidth
                  error={Boolean(commissionMessage)}
                  onFocus={element => element.target.select()}
                  onChange={(event) => {
                    if ((event.target.value.length === 0) || Number(event.target.value)) {
                      let commissionCorp = numberLength(event.target.value);
                      if (commissionCorp >= 100) commissionCorp = 100;
                      if (commissionCorp < 0) commissionCorp = '';
                      this.setState({
                        commission: commissionCorp,
                        cantUpdateCommission: false
                      })
                    }
                  }}
                />
              </Col>
              <Col md={6} lg={6}>
                <div
                  className="form-group "
                  style={{
                    textAlign: "center",
                    marginLeft: 20,
                    marginRight: 20,
                  }}
                >
                  <button
                    className="btn btn-fill btn-block btn-round btn-mlm"
                    onClick={() => this.setAgencyCommission(agency._id)}
                    disabled={cantUpdateCommission}
                  >
                    <span className="save-text">Salvar</span>
                  </button>
                </div>
              </Col>
            </Row>
            {['corporation', 'master', 'submaster'].includes(level) &&
              <>
                <h4 style={{ margin: '5px 0 15px 0' }}>Gerentes</h4>
                <Row>
                  <Col style={{ marginLeft: 15, marginRight: 15 }} >
                    <Row>
                      <div className="form-group col-lg-12 col-md-12 col-xs-12">
                        <TextField
                          label="Diretor"
                          InputProps={{
                            classes: {
                              input: "mui-input-control",
                              root: "mui-input-control",
                            },
                          }}
                          InputLabelProps={{
                            classes: {
                              root: "mui-input-control mui-label",
                            },
                          }}
                          error={Boolean(addressInvalidFields?.zipcode)}
                          helperText={addressInvalidFields?.zipcode}
                          fullWidth
                          variant="outlined"
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="form-group col-lg-12 col-md-12 col-xs-12">
                        <TextField
                          label="Gerente Private"
                          InputProps={{
                            classes: {
                              input: "mui-input-control",
                              root: "mui-input-control",
                            },
                          }}
                          InputLabelProps={{
                            classes: {
                              root: "mui-input-control mui-label",
                            },
                          }}
                          error={Boolean(addressInvalidFields?.zipcode)}
                          helperText={addressInvalidFields?.zipcode}
                          fullWidth
                          variant="outlined"
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="form-group col-lg-12 col-md-12 col-xs-12">
                        <TextField
                          label="Gerente Exclusive"
                          InputProps={{
                            classes: {
                              input: "mui-input-control",
                              root: "mui-input-control",
                            },
                          }}
                          InputLabelProps={{
                            classes: {
                              root: "mui-input-control mui-label",
                            },
                          }}
                          error={Boolean(addressInvalidFields?.zipcode)}
                          helperText={addressInvalidFields?.zipcode}
                          fullWidth
                          variant="outlined"
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="form-group col-lg-12 col-md-12 col-xs-12">
                        <TextField
                          label="Executivo Corban"
                          InputProps={{
                            classes: {
                              input: "mui-input-control",
                              root: "mui-input-control",
                            },
                          }}
                          InputLabelProps={{
                            classes: {
                              root: "mui-input-control mui-label",
                            },
                          }}
                          error={Boolean(addressInvalidFields?.zipcode)}
                          helperText={addressInvalidFields?.zipcode}
                          fullWidth
                          variant="outlined"
                        />
                      </div>
                    </Row>
                  </Col>
                </Row>
              </>}
            <h4 style={{ margin: '5px 0 15px 0' }}>Endereço</h4>
            <Row>
              <Col style={{ marginLeft: 15, marginRight: 15 }} >
                <Row>
                  <div className="form-group col-lg-4 col-md-8 col-xs-12">
                    <InputMask
                      mask="99999-999"
                      value={zipcode}
                      onChange={(event) => {
                        this.handleCep(event)
                        this.setState({
                          cantUpdateAddress: false,
                        });
                      }}
                    >
                      {() => (
                        <TextField
                          label="CEP"
                          InputProps={{
                            classes: {
                              input: "mui-input-control",
                              root: "mui-input-control",
                            },
                          }}
                          InputLabelProps={{
                            classes: {
                              root: "mui-input-control mui-label",
                            },
                          }}
                          error={Boolean(addressInvalidFields?.zipcode)}
                          helperText={addressInvalidFields?.zipcode}
                          fullWidth
                          variant="outlined"
                        />
                      )}
                    </InputMask>
                  </div>
                </Row>
                <Row>
                  <Col md={7} lg={8}>
                    <div className="form-group">
                      <TextField
                        label="Logradouro"
                        InputProps={{
                          classes: {
                            input: "mui-input-control",
                            root: "mui-input-control",
                          },
                        }}
                        InputLabelProps={{
                          classes: {
                            root: "mui-input-control mui-label",
                          },
                        }}
                        variant="outlined"
                        value={street}
                        fullWidth
                        error={Boolean(addressInvalidFields?.street)}
                        helperText={addressInvalidFields?.street}
                        onChange={(event) => {
                          this.setState({
                            street: event.target.value,
                            cantUpdateAddress: false,
                          });
                        }}
                        placeholder="Logradouro"
                      />
                    </div>
                  </Col>
                  <Col md={5} lg={4}>
                    <div className="form-group">
                      <TextField
                        inputRef={this.numberRef}
                        label="Número"
                        InputProps={{
                          classes: {
                            input: "mui-input-control",
                            root: "mui-input-control",
                          },
                          type: "number",
                          inputMode: "tel",
                          pattern: "[0-9]*",
                        }}
                        InputLabelProps={{
                          classes: {
                            root: "mui-input-control mui-label",
                          },
                        }}
                        variant="outlined"
                        value={number}
                        fullWidth
                        error={Boolean(addressInvalidFields?.number)}
                        helperText={addressInvalidFields?.number}
                        onChange={(event) => {
                          this.setState({
                            number: event.target.value,
                            cantUpdateAddress: false,
                          });
                        }}
                        placeholder="Número"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={5}>
                    <div className="form-group">
                      <TextField
                        label="Complemento"
                        InputProps={{
                          classes: {
                            input: "mui-input-control",
                            root: "mui-input-control",
                          },
                        }}
                        InputLabelProps={{
                          classes: {
                            root: "mui-input-control mui-label",
                          },
                        }}
                        variant="outlined"
                        value={additional}
                        fullWidth
                        error={Boolean(addressInvalidFields?.additional)}
                        helperText={addressInvalidFields?.additional}
                        onChange={(event) => {
                          this.setState({
                            additional: event.target.value,
                            cantUpdateAddress: false,
                          });
                        }}
                        placeholder="Complemento"
                      />
                    </div>
                  </Col>
                  <Col md={7}>
                    <div className="form-group">
                      <TextField
                        label="Bairro"
                        InputProps={{
                          classes: {
                            input: "mui-input-control",
                            root: "mui-input-control",
                          },
                        }}
                        InputLabelProps={{
                          classes: {
                            root: "mui-input-control mui-label",
                          },
                        }}
                        variant="outlined"
                        value={neighborhood}
                        fullWidth
                        error={Boolean(addressInvalidFields?.neighborhood)}
                        helperText={addressInvalidFields?.neighborhood}
                        onChange={(event) => {
                          this.setState({
                            neighborhood: event.target.value,
                            cantUpdateAddress: false,
                          });
                        }}
                        placeholder="Bairro"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <div className="form-group">
                      <TextField
                        label="Cidade"
                        InputProps={{
                          classes: {
                            input: "mui-input-control",
                            root: "mui-input-control",
                          },
                        }}
                        InputLabelProps={{
                          classes: {
                            root: "mui-input-control mui-label",
                          },
                        }}
                        variant="outlined"
                        value={city}
                        fullWidth
                        error={Boolean(addressInvalidFields?.city)}
                        helperText={addressInvalidFields?.city}
                        onChange={(event) => {
                          this.setState({
                            city: event.target.value,
                            cantUpdateAddress: false,
                          });
                        }}
                        placeholder="Cidade"
                      />
                    </div>
                  </Col>

                  <Col md={3} lg={3}>
                    <FormControl variant="outlined">
                      <select
                        className="form-control select-focus-fix"
                        value={state}
                        onChange={(event) => {
                          this.setState({
                            state: event.target.value,
                            cantUpdateAddress: false,
                          });
                        }}
                      >
                        <option value="UF">UF</option>
                        {this.states.map((state) => (
                          <option value={state} key={state}>
                            {state}
                          </option>
                        ))}
                      </select>
                    </FormControl>
                  </Col>
                </Row>
                <Row>
                  <div
                    className="form-group "
                    style={{
                      textAlign: "center",
                      marginLeft: 20,
                      marginRight: 20,
                    }}
                  >
                    <button
                      className="btn btn-fill btn-block btn-round btn-mlm"
                      style={{ marginTop: 15 }}
                      onClick={this.updateAgencyAddress}
                      disabled={cantUpdateAddress}
                    >
                      <span className="save-text">Salvar</span>
                    </button>
                  </div>
                </Row>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            {level === "administrator" ? (
              <>
                <button
                  className="btn btn-fill btn-round btn-mlm"
                  style={{ marginRight: 4 }}
                  onClick={() => {
                    this.setState({ edit: !this.state.edit });
                  }}
                >
                  Editar
                </button>
                <button
                  className="btn btn-fill btn-success btn-round"
                  style={{ marginRight: 4 }}
                  onClick={this.saveAgencyInfo}
                >
                  Salvar
                </button>
              </>
            ) : null}
            <button className="btn btn-fill btn-mlm" onClick={() => {
              onHide()
              this.setState({ editAgencyName: false })
            }}>
              Fechar
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default AgencyModal;