import React, { useState, useEffect } from 'react'
import { Grid, Col, Row, Modal } from 'react-bootstrap'
import Moment from 'moment'

import mainApi from "services/mainApi";

import MahoeIcon from 'components/MahoeIcons'
import { ConfirmationAlert, Dialog, LoadingAlert } from 'components/Alert/CustomAlert'

import DatePicker from 'react-datepicker'
import { getMonthAndYear } from 'utils/formatData'
import Select from 'react-select'

import getUserData from 'utils/checkUser'
import dev from "utils/dev";
import './styles.css'
import { FormHelperText } from '@material-ui/core';



function NewRequestCSVModal({ visible, handleClose, history, productId, productList, updateSelectProduct, selectProduct }) {
  const [userData, setUserData] = useState({})

  const [selectDate, setSelectDate] = useState(new Date())
  const [selectType, setSelectType] = useState()
  const [extractTypes, setExtractTypes] = useState([])
  const [csv, setCSV] = useState('')
  const [queryState, setQueryState] = useState()

  const [swalMessageShow, setSwalMessageShow] = useState(false)
  const [swalShowLoading, setSwalShowLoading] = useState(false)
  const [swalAlertShow, setSwalAlertShow] = useState(false)
  const [swalText, setSwalText] = useState('')
  const [swalType, setSwalType] = useState('warning')
  const [swalTitle, setSwalTitle] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = useState('Carregando...')

  let counter = 0

  // const IS_MASTER = ["master", "submaster"].includes(userData?.level)
  const IS_PARTNER = (["administrator"].includes(userData?.level))

  const getExtractTypes = () => {
    let options = []
    if (["master", "submaster"].includes(userData?.level))
      options.push(
        { label: 'Extrato Corporações', value: 'corporation' },
        { label: 'Extrato Afiliados', value: 'affiliate' }
      )
    if (["corporation", "subcorp"].includes(userData?.level)) {
      options.push({ label: 'Extrato Lojas', value: 'agency' })
      if (!userData?.hasManager) options.push({ label: 'Extrato Vendedores', value: 'seller' })
    }
    if (['administrator'].includes(userData?.level))
      options.push(
        { label: 'Extrato Vendedores', value: '/corp/extract/csv/sellers' }
      )
    setExtractTypes(options)
    // if (options.length) setSelectType(options[0])
  }

  const UserData = async () => {
    const userData = await getUserData(history);
    setUserData(userData)
  }

  function buildQuery (howToBeSent) {
    let query = '&report=extract'
    const body = {
      report: 'extract'
    }

    const year = Moment(selectDate).format('YYYY')
    if (year) {
      query += `&year=${year}`
      body.year = year
    }

    const month = Moment(selectDate).format('MM')
    if (month) {
      query += `&month=${month}`
      body.month = month
    }

    if (productId) {
      query += `&productId=${productId}`
      body.productId = productId
    }

    if (selectType?.value) {
      query += `&extractType=${selectType.value}`
      body.extractType = selectType.value
    }

    if (howToBeSent) {
      query += `&howToBeSent=${howToBeSent}`
      body.howToBeSent = howToBeSent
    }
    setQueryState(query)

    return {query, body}
  }

  useEffect(() => {
    UserData()
  }, [])

  const handleRequestCSV = async (howToBeSent) => {
    let {query, body} = buildQuery(howToBeSent)

    try {
      if (howToBeSent === 'download') {
        let alreadyHasFile = await checkForGeneratedFiles(query)
        console.log('alreadyHasFile', alreadyHasFile)
        if(alreadyHasFile) {
          setIsLoading(true)
          setStatus('Preparando...')

          const generateRequestResponse = await mainApi.post(`/reports/extract`, body)
          let responseData = generateRequestResponse?.data?.data

          if(responseData?.generatedReportRequestId) {
            if (!body.generatedReportRequestId){
              setQueryState(query + `&generatedReportRequestId=${responseData.generatedReportRequestId}`)
              query += `&generatedReportRequestId=${responseData.generatedReportRequestId}`
              body.generatedReportRequestId = responseData.generatedReportRequestId
            }
          }

          waitForFile(query)
        }
      } else {
        const response = await mainApi.post(`/reports/extract`, body);
        setSwalType('success')
        setSwalTitle('Sucesso')
        setSwalText(response?.data.message)
        setSwalAlertShow(true)
      }
    } catch (err) {
      setSwalType('warning')
      setSwalTitle('atenção')
      setSwalText(err?.message)
      setSwalAlertShow(true)
      dev.log(err);
    }
    setIsLoading(false)

  }

  async function checkForGeneratedFiles (query) {

    if(query) {
      let response = await mainApi.get(`/reports/check?${query}`)
      let responseData = response?.data?.data
      let { fileUrl, loading, status } = responseData
      setStatus(status)
      setIsLoading(loading)
      if(fileUrl) {
        downloadFile(fileUrl)
        setIsLoading(false)
        setQueryState('')
        query = ''
        loading = false
      }
      else{
        loading = true
      }
      return loading
    }

  }

  function downloadFile (fileUrl) {
    let a = document.createElement("a")
    a.href = fileUrl
    // a.target = '_blank'
    a.download = fileUrl
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a);
    // window.open(fileUrl, "_self")
    setIsLoading(false)
  }

  async function waitForFile(query) {
    clearInterval()
    let loading = true
    setInterval(async () => {
      if (loading) loading = await checkForGeneratedFiles(query)
      else clearInterval()
    }, 5000)
  }

  function clearInterval () {
    var interval_id = window.setInterval('', 9999)
    for (var i = 1; i < interval_id; i++) window.clearInterval(i)
  }

  const handleAdminCsv = async () => {
    // let responseResult
    // let query = ''
    // query += `?date=${Moment(selectDate).format('YYYY-MM')}`
    // query += `&product=${productId}`

    // try {
    //   const response = await mainApi.get(
    //     `/admin/extract/sellers/extract${query}`
    //   );

    //   setCSV(response.data.csv)
    //   if (response.data.csv) {
    //     createDownloadLink('sellers', response.data.csv);
    //   } else {
    //     setSwalAlertShow(true)
    //     setSwalTitle('Documento não disponível')
    //     setSwalText('O documento solicitado nao possui nenhum registro.')
    //   }

    //   responseResult = true
    // } catch (err) {
    //   responseResult = false
    //   console.log(err);
    // }

    // if (responseResult) {
    //   var eventDetail = { "detail": { "button": "Click:BP:IndiqueOPan:BaixarExtratoVendedores" } };
    //   var interactionClick = new CustomEvent("interactionClick", eventDetail);
    //   document.body.dispatchEvent(interactionClick);
    // }
  }

  const createDownloadLink = (name, csv) => {

    let file = "text/CSV;charset=utf-8,%EF%BB%BF" + encodeURI(csv);
    let filename = `${name}_${Moment(selectDate).format('YYYY-MM')}`;

    let a = document.createElement('a');

    a.href = "data:" + file;
    a.download = filename + ".csv";

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    this.setState({ csv: '' })
  }

  useEffect(() => {
    if (!userData) UserData()
    getExtractTypes()
  }, [userData])

  useEffect(() => {
    setSelectDate(new Date())
    setSelectType()

  }, [visible])

  return (
    <>
      <Dialog
        className="dialog"
        type={swalType}
        show={swalAlertShow}
        title={swalTitle}
        onConfirm={() => { setSwalAlertShow(false) }}
        text={swalText}
      />
      <ConfirmationAlert
        type={'warning'}
        show={swalMessageShow}
        title={"Atenção"}
        onConfirm={() => {
          setSwalMessageShow(false)
        }}
        onCancel={() => {
          setSwalMessageShow(false)
        }}
        text={swalText}
      />
      <Modal
        show={visible}
        bsSize="md"
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Solicitação de extrato</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "40vh", overflowY: "auto" }}>
          <Grid fluid style={{ paddingRight: 15 }}>
            <Row style={{ marginBottom: '10px' }}>
              <Col className="form-group" md={10} lg={10}>
                <FormHelperText>Selecione o mês</FormHelperText>
                <DatePicker
                  className="datepicker-theThird"
                  dateFormat="MM/yyyy"
                  locale="pt-BR"
                  isClearable={true}
                  customInput={
                    <div className="div-datepicker">
                      <div className="col-6">
                        <span className="placeholder-datepicker">
                          {selectDate ? "Mês selecionado:" : "Selecione o mês"}
                        </span>
                      </div>
                      <div className="col-3">
                        <p className="date-datepicker">
                          {selectDate ? getMonthAndYear(selectDate) : ""}
                        </p>
                      </div>
                      <div
                        className="col-3"
                        style={{ alignSelf: "center" }}
                      >
                        {selectDate ? (
                          <></>
                        ) : (
                          <MahoeIcon
                            aria-describedby={"iconTooltip"}
                            name="info"
                            size="23px"
                            fill="#039BE5"
                          />
                        )}
                      </div>
                    </div>
                  }
                  selected={selectDate}
                  showMonthYearPicker
                  onChange={date => {
                    setSelectDate(date)
                  }}
                  minDate={new Date('12-12-2019')}
                  maxDate={new Date()}
                />
              </Col>
            </Row>
            {
              !IS_PARTNER &&
              <Row style={{ marginBottom: '10px' }}>
                <Col className="form-group" md={10} lg={10}>
                  <FormHelperText>Selecione o tipo de extrato</FormHelperText>
                  <Select
                    className="custom-select"
                    placeholder="Selecione o Tipo de Extrato"
                    options={extractTypes}
                    // defaultValue={extractTypes.length && extractTypes[0] ? extractTypes[0] : undefined}
                    value={selectType}
                    onChange={(event) => {
                      setSelectType(event)
                    }}
                  />
                </Col>
              </Row>
            }
            <Row>
            <Col className="form-group" md={10} lg={10}>
              <FormHelperText>Selecione um produto</FormHelperText>
              <Select
                className="custom-select"
                placeholder="Selecione um Produto"
                options={productList}
                value={selectProduct}
                onChange={(event) => updateSelectProduct(event)}
              />
            </Col>
            </Row>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <div className='footer__buttons'>
          {
            IS_PARTNER
              ? <button
                id="data-tour-extract-master-button-affiliate"
                onClick={() => {
                  if (!selectDate) {
                    setSwalType('warning')
                    setSwalTitle('Atenção')
                    setSwalText('Selecione um mês!')
                    setSwalAlertShow(true)
                    return
                  } else {
                    handleAdminCsv()
                  }
                }}
                className="btn btn-fill btn-mlm btn-mlm-sm btn-small-extract"
              >
                Baixar extrato
               </button>

              : <>
                <button
                  id="data-tour-extract-master-button-affiliate"
                  style={{marginTop: 0}}
                  onClick={() => {
                    if (!selectDate) {
                      setSwalType('warning')
                      setSwalTitle('Atenção')
                      setSwalText('Selecione um mês!')
                      setSwalAlertShow(true)
                      return
                    }
                    if (!selectType && !IS_PARTNER) {
                      setSwalType('warning')
                      setSwalTitle('Atenção')
                      setSwalText('Selecione um tipo de extrato!')
                      setSwalAlertShow(true)
                      return
                    }
                    if (!productId) {
                      setSwalType('warning')
                      setSwalTitle('Atenção')
                      setSwalText('Selecione um produto!')
                      setSwalAlertShow(true)
                      return
                    }
                    handleRequestCSV('email')
                    // setSwalTitle('Atenção')
                    // setSwalText('O relatório será enviado para o e-mail cadastrado em sua conta irreversivelmente. Confirma?')
                    // setSwalMessageShow(true)
                  }}
                  className="btn btn-fill btn-mlm btn-mlm-sm btn-small-extract"
                >
                  <i aria-hidden="true" className="af-button-icon fa fa-envelope"></i>&nbsp;
                  Receber extrato por e-mail
                </button>
                <button
                  id="data-tour-extract-master-button-affiliate"
                  style={{marginTop: 0}}
                  disabled={isLoading}
                  onClick={() => {
                    if (!selectDate) {
                      setSwalType('warning')
                      setSwalTitle('Atenção')
                      setSwalText('Selecione um mês!')
                      setSwalAlertShow(true)
                      return
                    }
                    if (!selectType && !IS_PARTNER) {
                      setSwalType('warning')
                      setSwalTitle('Atenção')
                      setSwalText('Selecione um tipo de extrato!')
                      setSwalAlertShow(true)
                      return
                    }
                    if (!productId) {
                      setSwalType('warning')
                      setSwalTitle('Atenção')
                      setSwalText('Selecione um produto!')
                      setSwalAlertShow(true)
                      return
                    }
                    handleRequestCSV('download')
                  }}
                  className="btn btn-fill btn-mlm btn-mlm-sm btn-small-extract"
                >
                  {isLoading
                    ? <> <i aria-hidden="true" className="af-button-icon fa fa-spinner fa-spin"></i> {status} </>
                    : <> <i aria-hidden="true" className="af-button-icon fa fa-download"></i> Baixar extrato </>
                  }
                </button>
              </>
          }
          </div>
        </Modal.Footer>

      </Modal>
    </>
  )
}

export default NewRequestCSVModal
