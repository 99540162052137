export const MANAGEROPTIONS = [
  { value: 'private', label: 'Gerente Private' },
  { value: 'exclusive', label: 'Gerente Exclusive' },
  { value: 'superintendent', label: 'Superintendente' },
  { value: 'executive', label: 'Executivo Corban' }
]

export const MASTEROPTIONS = [
  { value: 'private', label: 'Gerente Private' },
  { value: 'exclusive', label: 'Gerente Exclusive' },
  { value: 'superintendent', label: 'Superintendente' },
]

export const CORPOPTIONS = [
  { value: 'executive', label: 'Executivo Corban' }
]