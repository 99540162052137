import React, { Component } from "react"
import { Row, Col } from "react-bootstrap"
import Button from "components/CustomButton/CustomButton.jsx"
import MuiMonthSelect from "components/MuiMonthSelect";
import { getDatesList } from "utils/formatData";
import mainApi from "services/mainApi";
import SweetAlert from "sweetalert2";
import Select from "react-select";
import { contaId } from "utils/CommonData";
import getUserData from "utils/checkUser";
import crypto from 'crypto'
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { Modal } from 'react-bootstrap';

const columns = [
  { label: 'Situação', value: 'situation', checked: true },
  { label: 'Data de início da abertura', value: 'createdAt', checked: true },
  { label: 'Código da proposta', value: 'number', checked: true },
  { label: 'Nome', value: 'name' },
  { label: 'Telefone', value: 'phone' },
  { label: 'E-mail', value: 'email' },
]

class DownloadClientReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      fileIsReady: false,
      selectedCorp: '',
      selectedPartner: '',
      corporations: [],
      partners: [],
      selectedDate: {
        label: '',
        value: ''
      },
      userData: null,
      checkQuery: '',
      status: 'Carregando...',
      checkedColumns: {
        number: true,
        name: false,
        surname: false,
        phone: false,
        email: false,
        situation: true,
        createdAt: true,
      },
      showReportOptions: false,
      sendByEmail: false,
      query: '',
      body: {}
    }
  }

  handleClick = async (event, howToBeSent = this.state.sendByEmail ? 'email' : 'download') => {
    event.preventDefault()

    if (howToBeSent === 'email') {

      SweetAlert.fire({
        title: 'Atenção?',
        text: (howToBeSent === 'email') ? 'O relatório será enviado para o e-mail cadastrado em sua conta! Confirma?' : 'O download pode',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {

        if (result.isConfirmed) {
          await this.start(howToBeSent)
        }

      })

    }

    else {

      if (!this.state.isLoading) {

        await this.start(howToBeSent)

      }


    }

  }

  validate = ({ sendByEmail }) => {
    const { selectedDate } = this.state

    if (!selectedDate || !selectedDate.value) {
      SweetAlert.fire('Aviso', 'Por favor selecione uma data', 'warning')
      return
    }
    this.setState({ sendByEmail: sendByEmail, showReportOptions: true })
  }

  start = async (howToBeSent = this.state.sendByEmail ? 'email' : 'download') => {
    this.setState({ isLoading: true, status: 'Preparando...' })
    const { selectedCorp, selectedPartner } = this.state

    const [month, year] = this.state.selectedDate.value.split('-')

    let query = '&report=clients'
    const body = { report: 'clients' }


    if (month) {
      query += `&month=${month}`
      body.month = month
    }

    if (year) {
      query += `&year=${year}`
      body.year = year
    }

    if (['master', 'submaster'].includes(this.state.userData?.level)) {
      if (!!selectedCorp && selectedCorp.value !== 'all') {
        if (selectedCorp.value) {
          query += `&corporationId=${selectedCorp.value}`
          body.corporationId = selectedCorp.value
        }
        // if (selectedCorp.label) {
        //   query += `&corporation=${selectedCorp.label}`
        //   body.corporation = selectedCorp.label
        // }
      }
    } else if (['corporation', 'subcorp'].includes(this.state.userData?.level) && howToBeSent === 'download') {
      if (!!selectedPartner && selectedPartner.value !== 'all') {
        if (selectedPartner.value) {
          query += `&agencyId=${selectedPartner.value}`
          body.agencyId = selectedPartner.value
        }
        // if (selectedPartner.label) {
        //   query += `&agency=${selectedPartner.label}`
        //   body.agency = selectedPartner.label
        // } 
      }
    }

    if (howToBeSent) {
      query += `&howToBeSent=${howToBeSent}`
      body.howToBeSent = howToBeSent
    }

    query += '&fields='

    body.fields = Object.entries(this.state.checkedColumns).reduce((acc, field) => {
      if (field[1]) {
        query += `${acc.length !== 0 ? ',' : ''}${field[0]}`
      }
      return field[1] ? [...acc, field[0]] : acc
    }, [])

    this.setState({ body: body, query: query })

    try {

      if (['master', 'submaster', 'corporation', 'subcorp'].includes(this.state.userData?.level) && howToBeSent === 'download') {
        let alreadyHasFile = await this.checkForGeneratedFiles(query)

        if (alreadyHasFile) {

          this.setState({ isLoading: true, status: 'Preparando...' })

          const generateRequestResponse = await mainApi.post(`/reports/clients`, body)
          let responseData = generateRequestResponse?.data?.data

          if (responseData?.generatedReportRequestId) {
            this.setState({ query: query + `&generatedReportRequestId=${responseData.generatedReportRequestId}` })
          }
          this.waitForFileToBeReady()
        }
      }
      else {
        const response = await mainApi.post(`/reports/clients`, body);

        SweetAlert.fire('Sucesso!', response.data?.message, 'success')
      }
    } catch (err) {
      SweetAlert.fire('Ops!', err?.data?.message || "Erro Desconhecido", 'warning')
      console.warn(err);
      return
    }

  }

  clearIntervals = () => {
    var interval_id = window.setInterval('', 9999)
    for (var i = 1; i < interval_id; i++) window.clearInterval(i)
  }

  waitForFileToBeReady = async () => {
    this.clearIntervals()
    let loading = true
    setInterval(async () => {
      if (loading) loading = await this.checkForGeneratedFiles()
      else this.clearIntervals()
    }, 5000)
  }

  checkForGeneratedFiles = async (query) => {
    if (query || this.state.query) {
      let response = await mainApi.get(`/reports/check?${query || this.state.query}`)
      let responseData = response?.data?.data
      let { fileUrl, loading, status } = responseData

      this.setState({ isLoading: loading, status })
      if (fileUrl) {
        this.downloadFile(fileUrl)
        this.setState({ query: '', body: {}, isLoading: false })
        loading = false
      } else {
        loading = true
      }
      return loading
    }
   
  }

  downloadFile = (fileUrl) => {
    let a = document.createElement("a")
    a.href = fileUrl
    // a.target = '_blank'
    a.download = fileUrl
    document.body.appendChild(a)
    a.click()
    // window.open(fileUrl, "_self")
  }

  onDateSelect = date => {
    this.setState({ selectedDate: date })
  };


  getCorporationsNames = async () => {
    const { userData } = this.state

    if (!['master', 'submaster'].includes(userData?.level)) return

    try {
      const response = await mainApi.get('/corporations/names');
      let aux = response?.data.data.corporations.map(partner => { return { label: partner.name, value: partner._id } })
      aux.unshift({ label: "Todas as Corporações", value: 'all' })
      this.setState({
        corporations: aux
      })
    } catch (error) {

    }
  }

  getCorpPartners = async () => {
    const { userData } = this.state

    if (!['corporation', 'subcorp'].includes(userData?.level)) return

    try {
      const response = await mainApi.get('/partner/names');
      let aux = response?.data.data.partners.map(partner => { return { label: partner.name, value: partner.id } })
      aux.unshift({ label: "Todos os Parceiros", value: 'all' })
      this.setState({
        partners: aux
      })
    } catch (error) {

    }
  }

  setUserData = async () => {
    const userData = await getUserData(this.props.history)
    this.setState({ userData })
  }

  handleClose = async () => {
    this.setState({ showReportOptions: false })
  }

  componentDidMount = async () => {
    await this.setUserData()
    this.getCorporationsNames()
    this.getCorpPartners()
  }

  render() {
    const {
      corporations,
      selectedCorp,
      userData,
      partners,
      selectedPartner,
      isLoading,
      showReportOptions,
      checkedColumns,
      sendByEmail,
      query,
      body
    } = this.state


    const IS_MASTER = ['master', 'submaster'].includes(userData?.level)
    const IS_CORP = ['corporation', 'subcorp'].includes(userData?.level)
    return (
      <div className="content">
        <div className="container-fluid">
          <Row>
            <Col md={6}>
              <div className="card" style={{ padding: '50px 20px 50px 20px' }}>
                <Row style={{ width: '100%', margin: 0, display: 'flex', flexDirection: 'column' }}>
                  <Col md={12} style={{ paddingBottom: 15 }}>
                    <MuiMonthSelect
                      disabled={isLoading}
                      className="custom-select"
                      month={this.selectedDate}
                      size="lg"
                      onMonthSelect={this.onDateSelect}
                      availableDates={getDatesList('2020-08')}
                    />
                  </Col>

                  {
                    IS_MASTER &&
                    <Col md={12} style={{ paddingBottom: 15 }}>
                      <Select
                        disabled={isLoading}
                        className="custom-select"
                        placeholder="Selecione uma Corporação"
                        options={corporations}
                        value={selectedCorp}
                        onChange={(corp) => {
                          this.setState({
                            selectedCorp: corp
                          })
                        }}
                      />
                    </Col>
                  }

                  {
                    IS_CORP &&
                    <Col md={12} style={{ paddingBottom: 15 }}>
                      <Select
                        className="custom-select"
                        placeholder="Selecione um Parceiro"
                        options={partners}
                        value={selectedPartner}
                        onChange={(partner) => {
                          this.setState({
                            selectedPartner: partner
                          })
                        }}
                      />
                    </Col>
                  }

                  <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1em', justifyContent: 'center' }}>
                    <Col md={6} style={{ padding: 0, margin: 0, flexGrow: 1, flexBasis: 250 }}>
                      <Button bsStyle="primary" fill style={{ width: "100%", margin: 'auto', display: 'block' }} onClick={() => this.validate({ sendByEmail: true })}>
                        <i aria-hidden="true" className="af-button-icon fa fa-envelope"></i>&nbsp;
                        Solicitar por e-mail
                      </Button>
                    </Col>
                    <Col md={6} style={{ padding: 0, margin: 0, flexGrow: 1,  flexBasis: 250 }}>
                      {
                        isLoading ?
                          <Button disabled={isLoading} bsStyle="primary" fill style={{ width: "100%", margin: 'auto', display: 'block' }} onClick={() => this.validate({ sendByEmail: false })}>
                            <i aria-hidden="true" className="af-button-icon fa fa-spinner fa-spin"></i> {this.state.status}
                          </Button> :
                          <Button bsStyle="seondary" fill style={{ width: "100%", margin: 'auto', display: 'block' }} onClick={() => this.validate({ sendByEmail: false })}>
                            <i aria-hidden="true" className="af-button-icon fa fa-download"></i>&nbsp;
                            Baixar Relatório
                          </Button>
                      }
                    </Col>
                  </div>
                </Row>
              </div>
            </Col>
            <Modal
              show={showReportOptions}
              onHide={this.handleClose}
            >
              <Modal.Header>
                <Modal.Title>Itens do relatório</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ maxHeight: "580px", overflowY: "auto" }}>
                {columns.map(column => (
                  <Col lg={6} md={6} sm={6} xs={12} >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={column.checked || checkedColumns[column.value]}
                          disabled={column.checked}
                          onChange={() => {
                            this.setState({ checkedColumns: { ...checkedColumns, [column.value]: !checkedColumns[column.value] } })
                          }}
                          name={column.label}
                          color="primary"
                        />}
                      label={column.label}
                    />
                  </Col>
                ))}
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-mlm btn-mlm-sm" onClick={this.handleClose}> Cancelar </button>
                <button
                  className="btn btn-fill btn-mlm btn-mlm-sm"
                  onClick={(e) => {
                    this.setState({ showReportOptions: false })
                    this.handleClick(e)
                  }}
                >
                  {this.state.sendByEmail ? 'Solicitar por e-mail' : 'Baixar relatório'}
                </button>
              </Modal.Footer>
            </Modal>
          </Row>
        </div>
      </div>
    );
  }
}

export default DownloadClientReport;