import React, { Component } from "react"
import { Row, Col } from "react-bootstrap"
import Button from "components/CustomButton/CustomButton.jsx"
import MuiMonthSelect from "components/MuiMonthSelect";
import { getDatesList } from "utils/formatData";
import mainApi from "services/mainApi";
import SweetAlert from "sweetalert2";
import Select from "react-select";
import { contaId } from "utils/CommonData";
import getUserData from "utils/checkUser";


class PaymentCorp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCorp: '',
      selectedSituation: '',
      selectedPartner: '',
      selectedProduct: '',
      corporations: [],
      partners: [],
      products: [],
      selectedDate: {
        label: '',
        value: ''
      },
      userData: null
    }
  }

  handleClick = async () => {

    SweetAlert.fire({
      title: 'Atenção?',
      text: 'O relatório será enviado para o e-mail cadastrado em sua conta irreversivelmente. Confirma?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, solicitar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { selectedCorp, selectedSituation, selectedPartner, selectedProduct } = this.state

        if (!this.state.selectedDate || !this.state.selectedDate.value) {
          SweetAlert.fire('Aviso', 'Por favor selecione uma data', 'warning')
          return
        }

        if (!selectedProduct || !selectedProduct.value) {
          SweetAlert.fire('Aviso', 'Por favor selecione um produto', 'warning')
          return
        }

        if (!selectedSituation || !selectedSituation.value) {
          SweetAlert.fire('Aviso', 'Por favor selecione um tipo', 'warning')
          return
        }



        const [month, year] = this.state.selectedDate.value.split('-')
        const situation = selectedSituation.value
        const productId = selectedProduct.value
        let query = ''
        const body = {}

        if (month) {
          body.month = month
          query += `&month=${month}`
        }
        if (year) {
          body.year = year
          query += `&year=${year}`
        }
        if (situation) {
          body.situation = situation
          query += `&situation=${situation}`
        }
        if (productId) {
          body.productId = productId
          query += `&productId=${productId}`
        }
        if (!!selectedCorp && selectedCorp.value !== 'all') {
          if (selectedCorp.value) {
            body.selectedCorp = selectedCorp.value
            query += `&corporationId=${selectedCorp.value}`
          }
        }
        if (!!selectedPartner && selectedPartner.value !== 'all') {
          if (selectedPartner.value) {
            body.agencyId = selectedPartner.value
            query += `&agencyId=${selectedPartner.value}`
          }
        }
        try {
          const response = await mainApi.post(`/reports/payment`, body);
          SweetAlert.fire('Sucesso!', response.data?.message, 'success')
          console.log(response)

          // const response1 = await mainApi.get(`/reports/check`);
          // console.log(response1)

        } catch (err) {
          SweetAlert.fire('Ops!', err?.data?.message || "Erro Desconhecido", 'warning')
          console.warn(err);
          return
        }

      }
    })


  }

  onDateSelect = date => {
    this.setState({ selectedDate: date })
  };


  getCorporationsNames = async () => {
    const { userData } = this.state

    if (!['master', 'submaster'].includes(userData?.level)) return

    try {
      const response = await mainApi.get('/corporations/names');
      let aux = response?.data.data.corporations.map(partner => { return { label: partner.name, value: partner._id } })
      aux.unshift({ label: "Todas as Corporações", value: 'all' })
      this.setState({
        corporations: aux
      })
    } catch (error) {

    }
  }

  getCorpPartners = async () => {
    const { userData } = this.state

    if (!['corporation', 'subcorp'].includes(userData?.level)) return

    try {
      const response = await mainApi.get('/partner/names');
      let aux = response?.data.data.partners.map(partner => { return { label: partner.name, value: partner.id } })
      aux.unshift({ label: "Todos os Parceiros", value: 'all' })
      this.setState({
        partners: aux
      })
    } catch (error) {

    }
  }

  getSituation = () => {
    let situation = []
    const { selectedProduct } = this.state


    if (selectedProduct && contaId === selectedProduct.value) {
      situation = [
        { label: 'Contas Análise', value: 'pending' },
        { label: 'Contas Abertas', value: 'paid' }
      ]
    }
    if (selectedProduct && contaId !== selectedProduct.value) {
      situation = [
        { label: 'Aprovado', value: 'paid' },
        { label: 'Análise', value: 'pending' },
        { label: 'Recusado', value: 'refused' }
      ]
    }

    return situation
  }

  setUserData = async () => {
    const userData = await getUserData(this.props.history)
    this.setState({
      products: userData.products,
    })
    this.setState({ userData })
  }

  componentDidMount = async () => {
    await this.setUserData()
    this.getCorporationsNames()
    this.getCorpPartners()
  }



  render() {
    const {
      corporations,
      selectedCorp,
      selectedSituation,
      userData,
      partners,
      selectedPartner,
      products,
      selectedProduct
    } = this.state

    const IS_MASTER = ['master', 'submaster'].includes(userData?.level)
    const IS_CORP = ['corporation', 'subcorp'].includes(userData?.level)
    return (
      <div className="content">
        <div className="container-fluid">
          <Row>
            <Col md={6}>
              <div className="card" style={{ padding: '50px 20px 50px 20px' }}>
                <Row style={{ width: '100%', margin: 0 }} >
                  <Col md={12} style={{ paddingBottom: 15, paddingLeft: 0, paddingRight: 0 }}>
                    <MuiMonthSelect
                      className="custom-select"
                      month={this.selectedDate}
                      size="lg"
                      onMonthSelect={this.onDateSelect}
                      availableDates={getDatesList('2020-08')}
                    />
                  </Col>

                  <Col md={12} style={{ paddingBottom: 15, paddingLeft: 0, paddingRight: 0 }}>
                    <Select
                      className="custom-select"
                      placeholder="Selecione um Produto"
                      options={products}
                      value={selectedProduct}
                      onChange={(product) => {
                        this.setState({
                          selectedProduct: product,
                          selectedSituation:''
                        })
                      }}
                    />
                  </Col>

                  <Col md={12} style={{ paddingBottom: 15, paddingLeft: 0, paddingRight: 0 }}>
                    <Select
                      className="custom-select"
                      placeholder="Selecione um Tipo"
                      options={this.getSituation()}
                      value={selectedSituation}
                      noOptionsMessage={() => {
                        if (!selectedProduct) return "Selecione um produto"
                      }}
                      onChange={(situation) => {
                        this.setState({
                          selectedSituation: situation
                        })
                      }}
                    />
                  </Col>

                  {
                    IS_MASTER &&
                    <Col md={12} style={{ paddingBottom: 15 }}>
                      <Select
                        className="custom-select"
                        placeholder="Selecione uma Corporação"
                        options={corporations}
                        value={selectedCorp}
                        onChange={(corp) => {
                          this.setState({
                            selectedCorp: corp
                          })
                        }}
                      />
                    </Col>
                  }

                  {
                    IS_CORP &&
                    <Col md={12} style={{ paddingBottom: 15 }}>
                      <Select
                        className="custom-select"
                        placeholder="Selecione um Parceiro"
                        options={partners}
                        value={selectedPartner}
                        onChange={(partner) => {
                          this.setState({
                            selectedPartner: partner
                          })
                        }}
                      />
                    </Col>
                  }
                </Row>
                  <div className='footer__buttons'>
                    <Button bsStyle="primary" fill onClick={() => this.handleClick()}>Solicitar Relatório</Button>
                  </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default PaymentCorp;
