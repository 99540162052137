import React, { useState, useEffect } from 'react'
import MahoeIcon from "components/MahoeIcons";
import InboxIcon from '@material-ui/icons/Inbox';
import mainApi from "services/mainApi";
import renderHTML from 'react-render-html';

import { Grid, Row, Col } from 'react-bootstrap'

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "components/Buttons/Button";
import swal from "components/Alert/alert";

import { formatToBrConditional } from 'utils/formaterDate'
import RichTextEditor from '../../components/RichTextEditor'
import { translateLevel } from 'utils/translate';
import getUserData from 'utils/checkUser';
import { useHistory } from 'react-router-dom';

const NotificationList = () => {
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([])
  const [selectedNotification, setSelectedNotification] = useState()
  const [newNotifications, setNewNotifications] = useState(false)
  const [tab, setTab] = useState(0)
  const [userData, setUserData] = useState({})
  const history = useHistory()

  let disabled = false
  async function getNotifications() {
    try {
      const response = await mainApi.get("/notification");
      setNotifications(response.data.data.notifications ? response.data.data.notifications : [])
    } catch (err) {
      console.log(err)
    }
  }

  async function handleread(id) {
    try {
      const response = await mainApi.patch(`/notification/read/${id}`);
      getNotifications()
    } catch (err) {
      console.log(err)
    }
  }

  async function updateUserData () {
    const userData =  await getUserData(history)
    setUserData(userData)
  }

  useEffect(() => {
    getNotifications()
    updateUserData()
  }, [])

  useEffect(() => {
    let AuxNotif = notifications.find(notification => !notification.read)

    if (AuxNotif && !selectedNotification) {
      setSelectedNotification(AuxNotif)
      setOpen(true)
    }
    if (selectedNotification) {
      let selected = notifications.find(notification => notification._id === selectedNotification._id)
      setSelectedNotification(selected)
    }
    if (!AuxNotif) {
      setNewNotifications(false)
    } else {
      setNewNotifications(true)
    }
  }, [notifications])

  return (
    <>
      <MahoeIcon
        name={newNotifications ? 'notification' : 'notification-outlined'}
        fill="#FFF"
        size={30}
        onClick={() => { setOpen(true) }}
        style={{ cursor: 'pointer' }}
      />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth={'lg'}
      >
        <DialogTitle>
          <div className='notification-container-close-button'>
              <div className='notification-title mobile-only'>
                {tab === 1 ? (
                  <>
                    <MahoeIcon
                      className='mobile-only notification-title-icon'
                      name='arrow-back'
                      fill='black'
                      onClick={() => { setTab(0) }}
                      style={{ cursor: 'pointer' }}
                    />
                  <p>{selectedNotification.sender.name}</p>
                  </>
              ) : 'Notificações'}
              </div>
            <div className='web-only'>Notificações</div>
            <div className='notification-close-button'>
              <MahoeIcon
                name="rounded-close"
                fill='black'
                size={30}
                onClick={() => { setOpen(false) }}
                style={{ cursor: 'pointer', marginRight: '-15px' }}
              />
            </div>
          </div>
        </DialogTitle>
        <DialogContent
          style={{overflowY:'hidden'}}
        >
          <Row className='notification-container'>
            {!notifications.length ?
              <div className='notification-empty'>
                <InboxIcon
                  style={{
                    color: "#c4c4c4",
                    fontSize: 50,
                    marginBottom: 5
                  }}

                />

                <p>Você não possui nenhuma notificação</p>
              </div> :
              <>
                <div className={`notification-list-container ${tab === 1 ? 'web-only' : ''}`}>
                  {
                    notifications.length ?
                      notifications.map(notification => (
                        <div
                          key={notification._id}
                          className='notification-preview-container'
                          onClick={() => {
                            setSelectedNotification(notification)
                            setTab(1)
                          }}
                          style={
                            selectedNotification &&
                              selectedNotification._id === notification._id ?
                              {
                                backgroundColor: 'var(--primary-color)',
                                color: 'white'
                              } :
                              {}
                          }
                        >
                          <div className='notification-preview-title'>{notification.title}</div>
                          <div className='notification-preview-text'>{renderHTML(notification.message.slice(0, 15).includes('<p><img') ? '<p></p>' : notification.message)}</div>
                        </div>
                      ))
                      :
                      <></>
                  }
                </div>
                <div
                  className={`notification-content ${tab === 0 ? 'web-only' : ''}`}
                >
                  {selectedNotification &&
                    (
                      <div>
                        <div className='notification-header'>
                          <div className='notification-header-logo'>
                            <img alt="" src={`https://agxsoftwarecloud.s3.amazonaws.com/${selectedNotification.image}`} />
                          </div>
                          <div className='notification-header-text-container'>
                            <p className='notification-header-text' >{selectedNotification.sender.name}</p>
                            <p className='notification-header-level'>{translateLevel(selectedNotification.sender.level, userData?.corpId)}</p>
                          </div>
                          <div className='notification-header-text-container'>
                            <div className='notification-read-button'>
                              <button
                                className="btn  btn-mlm btn-notification"
                                onClick={() => { handleread(selectedNotification._id) }}
                                disabled={selectedNotification?.read}
                              >
                                Marcar como lida
                          </button>
                            </div>
                            <p className='notification-header-date'>{formatToBrConditional(selectedNotification.createdAt)}</p>
                          </div>
                        </div>
                        <div className='notification-body'>
                          {/* {renderHTML(selectedNotification.message)} */}
                          <RichTextEditor
                            value={selectedNotification.message}
                            customMinHeight={360}
                            disabled
                            init={{resize: false}}
                          />
                        </div>
                      </div>
                    )
                  }
                </div>
              </>
            }
          </Row>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default NotificationList