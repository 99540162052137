import React, { useLayoutEffect, useState } from 'react'
import MahoeIcon from "components/MahoeIcons";
import getUserDataByLocalStorage, { setUserData } from "utils/checkUser";
import mainApi from 'services/mainApi';

function SupportNotify({ history }) {
  const [user, setUser] = useState()

  useLayoutEffect(() => {
    const getUserData = async () => {
      try {
        const response = await getUserDataByLocalStorage()
        if (response) {
          setUser(response)
        }
      } catch (err) {
        console.log(err)
      }
    }

    getUserData()
  }, [])

  const handleReturnAccessSupport = async () => {
    if (user && user.supportToken) {
      try {
        const id = user.id
        const response = await mainApi.post('/operator/user/go-back', {
          token: user.supportToken
        })

        if (response) {
          localStorage.clear();
          await setUserData(response.data)

          window.location.replace(` /admin/operator/user/details?id=${id}`)

        }

      } catch (err) {
        console.log(err)
      }
    }
  }

  return (
    <div className="support-access-notify">
      <div>
        <MahoeIcon
          name="info"
          fill="#FF5630"
          size="22px"
          style={{ width: "auto", marginRight: 10 }}
        />
        <p>Você está acessando esse usuário de forma remota.</p>
      </div>
      <button
        className="link-style remove-button-focus-border"
        onClick={handleReturnAccessSupport}
      >
        Voltar meu usuário
      </button>
      <span className='support-access-notify-pixel-container'>
        <MahoeIcon
          className='support-access-notify-pixel'
          name="picto"
          fill="#FF5630"
          size="8px"
          style={{ width: "auto", marginLeft: 10 }}
        />
      </span>
    </div>
  )
}

export default SupportNotify
