import Card from "components/Card/Card";
import React from "react";
import { HorizontalBar } from "react-chartjs-2";
import { graphOptions } from "./common";

function TopFive({ name, category, data }) {
  return (
    <Card
      title={`Top 5 ${name}`}
      category={`Produto: ${category}}`}
      content={
        data ? (
          <HorizontalBar
            data={data}
            height={300}
            options={{
              legend: {
                position: "bottom",
                align: "start",
                labels: { usePointStyle: true },
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      fontColor: "black",
                      padding: -5,
                      mirror: true,
                      beginAtZero: true,
                    },
                    stacked: true,
                  },
                ],
                xAxes: [
                  {
                    stacked: true,
                  },
                ],
              },
              responsive: true,
              maintainAspectRatio: false,
              ...graphOptions,
            }}
          />
        ) : (
          <div className="search-top-5-loading">
            <h4>Buscando...</h4>
          </div>
        )
      }
    />
  );
}

export default TopFive;
