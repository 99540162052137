import React from 'react'
import { numToBRL } from "utils/formatData";
import LinkButton from 'components/LinkButton'

const masterCorporationTableHeader = (level, options) => [
  {
    name: "_id",
    label: "ID",
    options: {
      display: 'excluded'
    }
  },
  {
    name: "code",
    label: "Code",
    options: {
      sort: false,
      filter: false,
      display: options.context === "agency" ? true : 'excluded',
      hint: "Código da loja",
      customBodyRender: (value) => {
        if(!value) return 'n/a'

        return value
      }
    }
  },
  {
    name: "name",
    label: "Nome",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableInfo) => {
        const {onUserSelect} = options || {}
        const {rowData} = tableInfo
        if(options.context === "agency")
          return value
        return <LinkButton onClick={() => onUserSelect(rowData[0])}>{value}</LinkButton> || "n/a";
      },
      hint: "Nome da loja a qual foi realizada a venda"
    }
  },
  {
    name: "level",
    label: "Nível",
    options: {
      filter: true,
      sort: true,
      display: !(options.hideColumns.level),
      hint: "Nome da loja a qual foi realizada a venda"
    }
  },
  {
    name: "corporations",
    label: "Corporações",
    options: {
      sort: true,
      filter: false,
      display: !(options.hideColumns.corporations) && !(options.hideColumns.agency),
      hint: "Quantidade de corporações"
    }
  },
  {
    name: "agencies",
    label: "Parceiros",
    options: {
      sort: true,
      filter: false,
      display: !(options.hideColumns.agency),
      hint: "Quantidade de parceiros"
    }
  },
  {
    name: "paid",
    label: "C. Abertas",
    options: {
      sort: false,
      filter: false,
      display: options.context === 'manager' ? true : 'excluded',
      hint: "Quantidade de contas aprovadas"
    }
  },
  {
    name: "pending",
    label: "C. Pendentes",
    options: {
      sort: false,
      filter: false,
      display: options.context === 'manager' ? true : 'excluded',
      hint: "Quantidade de contas pendentes"
    }
  },
  {
    name: "refused",
    label: "C. Recusadas",
    options: {
      sort: false,
      filter: false,
      display: options.context === 'manager' ? true : 'excluded',
      hint: "Quantidade de contas parceiros"
    }
  },
  {
    name: "links",
    label: "Links Gerados",
    options: {
      sort: options.context !== 'corporation',
      filter: false,
      display: options.context !== 'manager' ? true : 'excluded',
      hint: "Quantidade de links gerados"
    }
  },
  // {
  //   name: "clicks",
  //   label: "Cliques",
  //   options: {
  //     sort: false,
  //     filter: false,
  //     hint: "Quantidade de cliques nos links gerados"
  //   }
  // },
  {
    name: "datas.converted",
    label: "C. aprovadas",
    options: {
      sort: options.context !== 'corporation',
      display: options.context !== 'manager' ? true : 'excluded',
      hint: 'Quantidade de contas abertas.'
    }
  },
  {
    name: "datas.pending",
    label: "C. pendentes",
    options: {
      sort:  options.context !== 'corporation',
      display: options.context !== 'manager' ? true : 'excluded',
      hint: "Quantidade de contas pendentes."
    }
  },
  {
    name: "datas.refused",
    label: "C. recusadas",
    options: {
      sort:  options.context !== 'corporation',
      display: options.context !== 'manager' ? true : 'excluded',
      hint: "Quantidade de contas recusadas."
    }
  },
  {
    name: "superintendent.name",
    label: "Superintendente",
    options: {
      sort: false,
      filter: false,
      display: options.context === "agency" && ["master", "submaster"].includes(level) ? true : 'excluded',
      hint: "Nome do Superintendente",
      customBodyRender: (value) => {
        if(!value) return 'n/a'

        return value
      }
    }
  },
  {
    name: "private.name",
    label: "G. Private",
    options: {
      sort: false,
      filter: false,
      display: options.context === "agency" && ["master", "submaster"].includes(level) ? true : 'excluded',
      hint: "Nome do gerente Private",
      customBodyRender: (value) => {
        if(!value) return 'n/a'

        return value
      }
    }
  },
  {
    name: "exclusive.name",
    label: "G. Execlusive",
    options: {
      sort: false,
      filter: false,
      display: options.context === "agency" && ["master", "submaster"].includes(level)  ? true : 'excluded',
      hint: "Nome do Exclusive",
      customBodyRender: (value) => {
        if(!value) return 'n/a'

        return value
      }
    }
  }
  ,
  {
    name: "executive.name",
    label: "Executivo Corban",
    options: {
      sort: false,
      filter: false,
      display: options.context === "agency" && level !== "executive" ? true : 'excluded',
      hint: "Nome do Executivo Corban",
      customBodyRender: (value) => {
        if(!value) return 'n/a'

        return value
      }
    }
  }
];

export { masterCorporationTableHeader }