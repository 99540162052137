import React from 'react'
import PropTypes from 'prop-types'

const defaultAvatar = "https://freesvg.org/img/abstract-user-flat-3.png"                                                                       
const ProfileAvatar = ({level, avatar}) => {
    const containerClass = ["corporation", "master", "subcorp", "submaster"].includes(level)
                           ? "corp-sidebar-logo-wrapper" : "user-avatar-container avatar-container"
    return (
        <div className={containerClass} style={{backgroundImage: `url('${avatar && avatar === 'not-found' ? defaultAvatar : avatar}')`}}></div>
    )
}

ProfileAvatar.propTypes = {
    level: PropTypes.string,
    avatar: PropTypes.string
}
export default ProfileAvatar