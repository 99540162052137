import styled from 'styled-components'

export const Content = styled.div`
  min-height: 500px;
  height: fit-content;
  word-break: break-word;
  text-align:justify;
  max-height: 75vh;
  overflow-y: auto;
  padding: 5px;

  /* Width */
  &::-webkit-scrollbar {
    width: 3px;  
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888; 
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
`

export const MediumTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin:0;
  margin-top: 5px;
  margin-bottom: 5px;
`
export const SmallTitle = styled.h3`
  font-size: 16px;
  font-weight: bold;
  margin:0;
  margin-top: 15px;
  margin-bottom: 5px;
  color:#555;
`
export const RowTemplate = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
`
export const ProductsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
`

export const ColumnText = styled.div`
  padding:2px;
  font-size: 16px;
  border: 1px solid black;
`

export const SituationGrid = styled.div`
  width:100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 5px;
`

export const SituationRowGrid = styled.div`
  grid-template-rows: auto auto auto auto auto auto auto auto;
`
export const DefaultText = styled.div`
  margin: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 16px;
`

export const Line = styled.div`
  border-top: 1px solid black;
`