/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import ReactDOM from "react-dom";
import HttpsRedirect from "react-https-redirect";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/animate.min.css";
import "./assets/sass/light-bootstrap-dashboard-react.scss?v=1.3.0";
import "./assets/css/demo.css";
import "./assets/css/pe-icon-7-stroke.css";
import "./assets/css/helper.css"
import config from "utils/serverVars";

import AdminLayout from "layouts/Admin.jsx";
import Signin from "views/Auth/Signin";
import ConfirmEmail from 'views/Auth/ConfirmEmail'
import Signup from "views/Auth/Signup/Signup";
import EmailChange from "views/Auth/EmailChange";
import NewPassword from "views/Auth/NewPassword";
import ResetPassword from "views/Auth/ResetPassword";
import EmailUnsubscribe from "views/EmailUnsubscribe"
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Documentation from "views/Documentation";
import setStyle from "utils/setStyle";
import PreRegister from 'views/PreRegister';
import InternalSignin from 'views/Auth/InternalSignin'
import TeamRoutes from "views/Teams"
import { ClubeAmigoTitle } from "utils/CommonData";
import { ISClubeAmigo } from "utils/environment";


const env = process.env.REACT_APP_ENV
let httpsDisabled = (env !== "production" && env !== "development");

document.title = ISClubeAmigo ? ClubeAmigoTitle : config.PAGE_TITLE



const theme = createMuiTheme({
  typography: {
    fontFamily: config.COLORS.primary_hover_color,
  },
  palette: {
    primary: { 500: config.COLORS.primary_hover_color },
    secondary: {
      main: "#f44336",
    },
    tertiary: {
      main: "#40ff99",
    }
  },
});



setStyle(config.COLORS, config.STYLE);
const _blackListUrl = [
  "https://mlmproductionweb.herokuapp.com",
  "https://mlmdevelopmentweb.herokuapp.com"
];

if (_blackListUrl.includes(window.location.origin)) {
  document.title = "Page Not Found."
  ReactDOM.render(<h3>Página não encontrada.</h3>, document.getElementById("root"));
} else {
  ReactDOM.render(
    <HttpsRedirect disabled={httpsDisabled}>
      <BrowserRouter>
        <MuiThemeProvider theme={theme}>
          <Switch>
            <Route
              path="/unsubscribe/:unsubscribeToken"
              exact
              component={EmailUnsubscribe}
            />
            <Route path="/" exact component={Signin} />
            <Route path="/cnpj" exact component={Signin} />
            <Route path="/internal" exact component={InternalSignin} />
            <Route path="/signup" component={Signup} />
            <Route path="/preregister" component={PreRegister} />
            <Route
              path="/admin"
              render={(props) => <AdminLayout {...props} />}
            />
            <Route path="/password/change" component={NewPassword} />
            <Route path="/*/password/change" component={NewPassword} />
            <Route path="/password/requestreset" component={ResetPassword} />
            <Route path="/*/password/requestreset" component={ResetPassword} />
            <Route
              path="/password/reset"
              render={(props) => <ResetPassword {...props} needCode={true} />}
            />
            <Route path="/email/change" component={EmailChange} />
            <Route path="/documentation" component={Documentation} />
            <Route path="/email/confirm" component={ConfirmEmail} />
            <Route path="/ed8fd809601c43959b9b90c01c8b859a" component={TeamRoutes} />
            <Route
              path="/"
              render={(props) => <Redirect to="/" {...props} />}
            />
          </Switch>
        </MuiThemeProvider>
      </BrowserRouter>
    </HttpsRedirect>,
    document.getElementById("root")
  );
}
