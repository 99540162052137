import styled from 'styled-components'

export const ApiCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  background-color: rgb(38, 50, 56); 
  color: #fff;
  padding: 40px;

  .route {
    background-color: rgb(17, 23, 26);
    width: 100%;
    height: 50px; 
    display: grid;
    grid-template-columns: 1fr auto;

    div {
      background-color: rgb(17, 23, 26);
      border: none;
      width: 100%;
      margin: 0;
      overflow: auto;
      /* word-break: keep-all; */
      white-space: nowrap;
      display: flex;
      align-items: center;
      padding: 0 0 0 10px;
      
      &::-webkit-scrollbar-thumb {
        background-color: hsla(0,0%,100%,.08);
      }

      &::-webkit-scrollbar {
        width: 6px!important;
        height: 6px!important;
        border-radius: 10px;
      }
    }

    button {
      background-color: rgb(17, 23, 26);
      border: none;
      border-left: 1px solid #e2e2e2;
      outline: none;
      height: 75%;
      margin: auto;
      color: #e2e2e2;
    }
  }  

  .response {
    width: 100%;
    height: auto;
    background-color: rgb(17, 23, 26);
    margin-top: 20px;
    padding: 30px;

    img {
      width: 100%;
      height: 100%;
    }
  }
`