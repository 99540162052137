import React, { Component } from "react";
import Card from "components/Card/Card";
import { Grid, Row, Col } from 'react-bootstrap';
import { columns } from './tableFunctions';
import mainApi from "services/mainApi";
import LoadingGif from "components/loadingGif";
import { introSystem } from "utils/introToSystem";
import getUserData from "utils/checkUser";
import MahoeIcons from "components/MahoeIcons"
import Pagination from '@material-ui/lab/Pagination';
import TableMui from 'components/TableMui'
import swal from 'components/Alert/alert'

class SubUsers extends Component {

  constructor(props) {
    super(props)

    this.state = {
      subUsers: null,
      totalDocs: 0,
      page: 1,
      totalPages: 1,
      userData: null,
      isDesktop: false,
      limit: 10,
      sort: '',
      userId: null
    }

    this.updatePredicate = this.updatePredicate.bind(this);
  }

  getQuery = () => {
    const { page, limit, sort } = this.state

    let query = `?page=${page}`

    if (limit !== 10) query += `&limit=${limit}`
    if (sort) query += `&sort=${sort}`

    return query
  }

  getSubUserList = async () => {
    const query = this.getQuery()

    try {
      const response = await mainApi.get("/sub" + query)
      const { docs, totalDocs, totalPages } = response.data.data.subUsers

      this.setState({ subUsers: docs, totalDocs, totalPages })

    } catch (err) {
      console.log(err)
    }
  }

  setUserData = async () => {
    const userData = await getUserData(this.props.history);

    this.setState({ userData })
  }

  changePage = (newPage) => {
    const { totalPages } = this.state
    if (newPage >= 0 && newPage < totalPages) {
      this.setState({ page: newPage + 1 }, () => {
        this.getSubUserList()
      })
    }
  }

  changeLimit = (newLimit) => this.setState({ limit: newLimit }, () => this.changePage(0))

  blockUser = async (id) => {
    const { userId, userData } = this.state

    try {
      const endpoint = ['master', 'submaster'].includes(userData.level) ? "/master/user/block/" : "/corporation/user/block/"

      const response = await mainApi.patch(`${endpoint}${userId}`)

      await this.getSubUserList()

      swal.basic('success', "Sucesso!", response.data.message)

    } catch (err) {

      swal.basic('warning', "Atenção!", err.response.data.message)
    }

  }

  handleBlockUser = async (id) => {
    swal.confirmNegate(null, "Bloquear", "Este usuário será bloqueado", () => { this.setState({ userId: id }, () => { this.blockUser() }) })
  }

  componentDidMount = async () => {
    const { routeKey } = this.props;

    await this.getSubUserList()
    await this.setUserData()

    this.updatePredicate()
    window.addEventListener("resize", this.updatePredicate);

    const { userData } = this.state

    window.digitalData = {
      "site": {
        "nome": "Banco Pan"
      },
      "page": {
        "secao": "IndiqueoPan",
        "estadoLogin": "LG",
        "nome": "BP:IndiqueOPan:Divulgacao",
      },
      "user": {
        "crmid": userData?.id,
        "tipo": userData?.brType
      }
    }

    var evt = new CustomEvent('event-view-start');
    document.body.dispatchEvent(evt);

    introSystem(routeKey);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 1000 });
  }



  render() {
    const { subUsers, isDesktop, totalDocs, page, limit, totalPages } = this.state

    const options = {
      selectableRows: false,
      rowsPerPage: limit,
      rowsPerPageOptions: [10, 25, 50],
      filterType: "dropdown",
      responsive: "scrollMaxHeight",
      count: totalDocs,
      serverSide: true,
      pagination: true,
      elevation: 0,
      page: page - 1,
      print: false,
      filter: false,
      search: false,
      onColumnSortChange: (value, type) => {
        const hasPlus = 'descending' === type ? '-' : ''
        this.setState({ sort: hasPlus + value }, () => {
          this.getSubUserList()
        })
      },
      textLabels: {
        body: {
          noMatch: "Desculpe, nenhum dado corresponde a pesquisa"
        },
        pagination: {
          next: "Próxima Página",
          previous: "Pagina Anterior",
          rowsPerPage: "Linhas por página:",
          displayRows: "de"
        },
        toolbar: {
          search: "Procurar",
          downloadCsv: "Download CSV",
          print: "Imprimir",
          viewColumns: "Mostrar Colunas",
          filterTable: "Filtrar Tabela"
        },
        filter: {
          title: "FILTROS",
          reset: "limpar",
          all: "Todos"
        },
        viewColumns: {
          title: "Mostrar Colunas"
        }
      },

      onTableChange: (action, tableState) => {
        switch (action) {
          case 'changePage':
            this.changePage(tableState.page)
            break;
          case 'changeRowsPerPage':
            this.changeLimit(tableState.rowsPerPage)
            break;
          default:
            break;
        }
      }

    }

    return (
      <div className="content">
        <Grid fluid>
          {subUsers ?
            <>
              <Row>
                <Col lg={12}>
                  {isDesktop
                    ?
                    <div className='card'>
                      <TableMui
                        title="Listagem de usuários adicionais"
                        options={options}
                        columns={columns(this.handleBlockUser)}
                        data={subUsers}
                      />
                    </div>
                    : <>
                      <Card
                        style={{ marginTop: '20px' }}
                        title="Listagem de usuários adicionais"
                        category="Primeiro acesso: usuário já realizou o primeiro acesso à plataforma."
                        content={
                          <>
                            <div style={{ display: 'grid', justifyItems: 'center' }}>
                              {subUsers.map(user => (
                                <div className="card" style={{ maxWidth: '18rem', padding: '10px', justifySelf: 'center' }}>
                                  <div style={{ justifyContent: 'center', display: 'flex', marginBottom: '10px' }}>
                                    <MahoeIcons name="person" fill="#414141" />
                                  </div>
                                  <div style={{ display: 'grid', justifyContent: 'center' }}>
                                    <p style={{ margin: '0px', justifySelf: 'start' }}>
                                      <strong>Nome: </strong>{user.name}
                                      <br />
                                      <strong>Email: </strong>{user.email}
                                      <br />
                                      <strong>Email enviado: </strong>{user.emailSent ? 'Sim' : 'Não'}
                                      <br />
                                      <strong>Primeiro acesso: </strong>{user.resetPassword ? 'Não' : 'Sim'}
                                    </p>
                                  </div>
                                  <div style={{ justifyContent: 'center', display: 'flex' }}>
                                    <button
                                      className="btn btn-fill btn-mlm btn-mlm-sm btn-small-extract"
                                      onClick={() => { }}
                                    >
                                      Editar
                                    </button>
                                  </div>
                                </div>
                              )
                              )}
                              <Pagination
                                count={totalPages}
                                defaultPage={page}
                                showFirstButton
                                showLastButton
                                onClick={(evt) => this.changePage(evt.target.value)}
                              />
                            </div>
                          </>
                        }
                      />
                    </>
                  }
                </Col>
              </Row>
            </>
            :
            <div className="card">
              <LoadingGif />
            </div>
          }
        </Grid>
      </div>
    )
  }
}

export default SubUsers