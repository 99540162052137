import pan_kv_pf from "assets/img/cpf-person.png"
import pan_consignado_pf from "assets/img/cpf-person-campanha.png"
import pan_kv_pj from "assets/img/cpf-person-original-laranja.png"
import pan_kv_login from 'assets/img/pan_login_cover.png'
import pan_minimal_logo from "assets/img/pan-logo-p.png"
import logo_alt from "assets/img/pan_white_logo.png"
import logoIndique from "assets/img/indiqueopan.png";
import logoIndiqueWhite from "assets/img/indiqueopan-white.png";
import logoInstitucional from "assets/img/pan_institucional.png";


const config = {
  CLIENT: "pan",
  NAME: "Banco PAN",
  PAGE_TITLE: "Afiliados | Banco PAN",
  SIGNUP_TITLE: "Fazer sua indicação no <span className='primary-text-color'>Indique o PAN</span> é muito fácil!",
  SIGNUP_TITLE_ACCENT: "",
  SIGNUP_SUBTITLE: "Saiba como fazer parte e ganhar um dinheiro extra indicando o Empréstimo Consignado PAN.",
  SIGNUP_FIRST_STEP_TITLE: "Abra sua conta digital grátis",
  SIGNUP_FIRST_STEP_BODY: "Para fazer parte do Indique o PAN e ganhar até R$ 200 por indicação*, você deve ter uma <strong>conta digital aberta</strong>. Para isso, <strong>basta baixar nosso app</strong> e fazer seu cadastro.",
  SIGNUP_LINK_TEXT: "Baixar o app e abrir conta",
  SIGNUP_SECOND_STEP_TITLE: "Cadastre-se",
  SIGNUP_SECOND_STEP_BODY: "Se já possui uma conta no PAN, basta inserir seus dados e juntar-se ao programa.", 
  SIGNUP_SECOND_LINK_TEXT: "<a className='no-absolut link-style' onClick={() =>{this.setState({open:true})}} >Clique aqui</a>  e consulte a tabela de bonificação.",
  IMG_PF: pan_kv_login,
  IMG_PJ: pan_kv_login,
  IMG_CAMPANHA_PF: pan_kv_login,
  LOGO: logoInstitucional,
  PRODUCT_LOGO: logoIndique,
  PRODUCT_LOGO_WHITE: logoIndiqueWhite,
  MINIMAL_LOGO: pan_minimal_logo,
  LOGO_ALT: logo_alt,
  CONTACT_PF: 'atendimento.afiliadospf@grupopan.com',
  CONTACT_PJ: 'atendimento.afiliadospj@grupopan.com',
  COLORS: {
    primary_color: '#039BE5',
    primary_hover_color: '#03a9f4',
    primary_pressed_color: '#0288d1',
    primary_clear_color: 'rgba(2, 137, 209, 0.04)',
    secondary_color: '#F44336',
    danger_color: '#FF3545',
    danger_pressed_color: '#FF3545',
    danger_hover_color: '#a80a0a',
    dark_color: '#414141'
  },
  STYLE: {
    picto:  '""',
    font_family: '"Montserrat"',
    button_border_radius: '16px'
  },
  hideLogs: ['production', 'development']
}


export default config