import React, { Component, createRef } from 'react';
import { mask, unMask } from "remask";
import getUserData from "utils/checkUser";
import mainApi from "services/mainApi";
import { columns, levelOptions, typeOptions } from './tableFunctions';
import { Grid, Row, Col } from 'react-bootstrap';
import { booleanAffiliate, bolleanToYesNo, booleanPartner } from 'utils/translate';
import Select from "react-select";
import { formatToBackendFilter, formatToBr } from 'utils/formaterDate';
import formater from 'utils/formater'
import { LoadingAlert, Dialog } from 'components/Alert/CustomAlert';
import { firstAndLastWord } from 'utils/formatData';
import { introSystem } from 'utils/introToSystem';
import TableMui from 'components/TableMui'
import OperatorModal from 'components/OperatorModal'
import Axios from "axios";
import queryString from "query-string";
import Switch from 'components/Switch/Switch'
import { searchLoginOptions, emptySelect, searchblockOptions, searchSignupTypeOptions } from "utils/CommonData";
import InputMask from "react-input-mask";


// date-picker
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptbr from 'date-fns/locale/pt-BR'
import { FormHelperText } from '@material-ui/core';
import InputMuiField from 'components/InputMuiField';
import { onlyDigits } from 'utils/numberMethods';
import { TextField } from '@material-ui/core';
import { signUpStatus } from 'utils/CommonData';
import { translateLevel } from 'utils/translate';
registerLocale('pt-BR', ptbr)

const CancelToken = Axios.CancelToken;
let cancelRequest;

class OperatorPanel extends Component {

  constructor(props) {
    super(props)

    this.state = {
      users: [],
      selectCorporations: [],
      selectAgencies: [],

      showAlert: {
        show: false,
        title: "",
        text: "",
        type: ""
      },

      agencySelectDisable: true,
      corporation: undefined,
      agency: undefined,
      userData: null,
      totalDocs: 0,
      page: 1,
      resetPageMui: false,
      name: undefined,
      email: undefined,
      loginType: null,
      level: emptySelect,
      type: null,
      blocked: emptySelect,
      loading: true,
      selectedSignUpStatus: emptySelect,

      openModal: false,
      modalData: {},

      blockInputs: false,

      selectedSwitch: searchLoginOptions[0],
      login: undefined,

      dateApprove: [undefined, undefined],
      openDatePickerApprove: false,

      dateSignup: [undefined, undefined],
      openDatePickerSignup: false,

      affiliate: emptySelect
    }

    this.agencyRef = createRef()
    this.loginRef = createRef()
    this.nameRef = createRef()
    this.emailRef = createRef()
  }

  showMessage = (type, message, boldMessage) => {
    this.setState({
      swalShowMessage: true,
      swalMessage: [type, message],
      btnTitle: "Entrar",
      loading: false
    });
  };

  setUserData = async () => {
    const userData = await getUserData(this.props.history)

    this.setState({ userData })
  }

  getUsers = async () => {
    const { userData, login, selectedSwitch, name, corporation, agency, dateApprove, dateSignup, email, level, blocked, affiliate, limit, page, sort, selectedSignUpStatus } = this.state
    const [startApprove, endApprove] = dateApprove
    const [startDate, endDate] = dateSignup

    try {
      if (cancelRequest) await cancelRequest()
      this.setState({ loading: true })

      const response = await mainApi.get(`${userData?.hasSupport ? '' : '/internal'}/operator/users`, {
        cancelToken: new CancelToken((c) => (cancelRequest = c)),
        params: {
          login: onlyDigits(login),
          loginType: onlyDigits(login) ? selectedSwitch.value : undefined,
          name,
          corporation: corporation?.value,
          agency: agency?.value,
          startApprove: formatToBackendFilter(startApprove, '00:00'),
          endApprove: formatToBackendFilter(endApprove, '23:59'),
          startDate: formatToBackendFilter(startDate, '00:00'),
          endDate: formatToBackendFilter(endDate, '23:59'),
          analysis: selectedSignUpStatus.value,
          email,
          level: level?.value,
          blocked: blocked?.value,
          affiliate: affiliate?.value,
          limit,
          page,
          sort
        }
      })

      const users = response.data.data.docs
      const totalDocs = response.data.data.totalDocs

      const formatedUser = []

      users.forEach(itens => {
        let login
        let loginType
        const temp = {
          id: itens._id,
          level: translateLevel(itens.level, this.state.userData?.corpId),
          name: firstAndLastWord(itens.name),
          email: itens.email,
          createdAt: formatToBr(itens.createdAt),
          blocked: itens.blocked || false,
          telephone: itens.telephone,
          cellphone: itens.cellphone,
          updatedAt: formatToBr(itens.updatedAt) || "Nunca",
          lastLogin: formatToBr(itens.lastLogin) || "Nunca",
          corporationId: itens.corporationId,
          corporationName: itens.corporationName,
          unlink: itens.unlink
        }

        switch (itens.level) {
          case "master":
            temp.cnpj = itens.cnpj ? mask(itens.cnpj, "99.999.999/9999-99") : undefined
            temp.login = itens.cnpj ? mask(itens.cnpj, "99.999.999/9999-99") : "-"
            temp.loginType = "CNPJ"
            break
          case "submaster":
            temp.login = itens.masterCNPJ ? mask(itens.masterCNPJ, "99.999.999/9999-99") : "-"
            temp.cnpj = itens.masterCNPJ ? mask(itens.masterCNPJ, "99.999.999/9999-99") : "-"
            temp.loginType = "CNPJ"
            break
          case "corporation":
            temp.cnpj = itens.cnpj ? mask(itens.cnpj, "99.999.999/9999-99") : undefined
            temp.login = itens.cnpj ? mask(itens.cnpj, "99.999.999/9999-99") : "-"
            temp.loginType = "CNPJ"
            temp.type = itens.corporationHasManager ? "CORBAN" : "Corporação"

            temp.corporationName = itens.corporationName
            temp.corporationId = itens.corporationId
            temp.corporationHasManager = itens.corporationHasManager
            break;
          case "subcorp":
            temp.cnpj = itens.corporationCNPJ ? mask(itens.corporationCNPJ, "99.999.999/9999-99") : "-"
            temp.login = itens.corporationCNPJ ? mask(itens.corporationCNPJ, "99.999.999/9999-99") : "-"
            temp.loginType = "CNPJ"

            temp.corporationName = itens.corporationName
            temp.corporationId = itens.corporationId
            break
          case "administrator":
            temp.partner = itens.partner ? booleanPartner(itens.partner) : undefined
            temp.agencyName = itens.agencyName
            temp.agencyId = itens.agencyId
            temp.agencyCorporationId = itens.agencyCorporationId

            if (itens.partner) {
              temp.code = itens.code || "-"
              temp.login = itens.code || "-"
              temp.loginType = "Código"
              temp.type = "Parceiro"
            }
            else {
              temp.cpf = itens.cpf ? formater.toCPF(itens.cpf) : "-"
              temp.login = itens.cpf ? formater.toCPF(itens.cpf) : "-"
              temp.loginType = "CPF"
              temp.type = "Lojista"
            }
            break
          case "private":
          case "superintendent":
          case "executive":
          case "exclusive":
            temp.cpf = itens.cpf ? formater.toCPF(itens.cpf) : "-"
            temp.login = itens.cpf ? formater.toCPF(itens.cpf) : "-"
            temp.loginType = "CPF"
            temp.type = "Gerente"
            break
          case "seller":
            temp.cpf = itens.cpf ? formater.toCPF(itens.cpf) : "-"
            temp.login = itens.cpf ? formater.toCPF(itens.cpf) : "-"
            temp.loginType = "CPF"
            temp.affiliate = bolleanToYesNo(itens.affiliate)
            temp.type = booleanAffiliate(itens.affiliate)
            temp.agencyName = itens.agencyName
            temp.agencyId = itens.agencyId
            temp.agencyCorporationId = itens.agencyCorporationId
            if (itens.affiliate) temp.approvedAt = formatToBr(itens.approvedAt) || "Não Aprovado"
            break
          case "supervisor":
            temp.cpf = itens.cpf ? formater.toCPF(itens.cpf) : "-"
            temp.login = itens.cpf ? formater.toCPF(itens.cpf) : "-"
            temp.loginType = "CPF"
            temp.agencyName = itens.agencyName
            temp.agencyId = itens.agencyId
            temp.agencyCorporationId = itens.agencyCorporationId
            break
        }
        temp.data = temp

        formatedUser.push(temp)
      })

      this.setState({
        users: formatedUser,
        totalDocs
      })
    } catch (err) {
      console.log(err)
    }
    this.setState({ loading: false })
  }

  changePage = (page) => {
    this.setState({ page: page + 1 }, () => this.getUsers())
  }

  setShowAlert = (type, show, title, text) => {
    this.setState({
      showAlert: {
        type,
        show,
        title,
        text
      }
    })
  }

  searchByCorporation = async (corporation) => {
    this.setState({ corporation })
    await this.resetAgencies()
    this.freeSelectAgencie()
    this.getAgencies(corporation.value)
  }

  freeSelectAgencie = () => {
    this.setState({ agencySelectDisable: false })
  }

  resetPageAndCallUsers = async () => {
    await this.changePage(0)
    // await this.getUsers()
  }

  getAgencies = async (id) => {
    const { userData } = this.state
    const FIRST_ITEM_SELECT = { value: '', label: 'Todas' }
    if (!id) {
      this.setState({ agencySelectDisable: true })
      this.resetAgencies()
      return
    }

    try {
      const response = await mainApi(`${userData?.hasSupport ? '' : '/internal'}/operator/corporation/list/agencies/${id}`)
      const agencies = [FIRST_ITEM_SELECT, ...response.data.agencies]

      this.setState({ selectAgencies: agencies, agency: FIRST_ITEM_SELECT })
    } catch (err) {
      console.log(err)
    }
  }

  resetAgencies = () => {
    this.agencyRef.current.state.value = ''
    this.setState({ agency: null })
  }

  getCorporations = async (corporation) => {
    const FIRST_ITEM_SELECT = { value: '', label: 'Todas' }
    const { userData } = this.state

    try {
      const response = await mainApi(`${userData?.hasSupport ? '' : '/internal'}/operator/corporations/list`)
      const tempData = response.data.corps
      const corporations = [FIRST_ITEM_SELECT, ...tempData]

      this.setState({ selectCorporations: corporations, corporation: FIRST_ITEM_SELECT })
    } catch (err) {
      console.log(err)
    }
  }

  searchSelectedCorp = (value) => {
    this.props.history.push("/admin/operator/corporation/details?id=" + value)
  }

  searchByAgencie = (value) => {
    this.setState({ agency: value })
  }

  handleOpen = (value) => {
    const newValue = Object.assign({}, value)

    this.props.history.push("/admin/operator/user/details?id=" + value)
    // if (value.level == "Corporação") {
    // }
    // else {
    //   this.setState({ openModal: true, modalData: newValue })
    // }
  }

  componentDidMount = async () => {
    const { routeKey } = this.props

    const { corporation } = queryString.parse(this.props.location.search)

    await this.setUserData()
    await this.getCorporations(corporation)

    // if(corporation) await this.setState({corporation})

    await this.getUsers()

    const { userData } = this.state

    window.digitalData = {
      "site": {
        "nome": "Banco Pan"
      },
      "page": {
        "secao": "IndiqueoPan",
        "estadoLogin": "LG",
        "nome": "BP:IndiqueOPan:ListagemAfiliados",
      },
      "user": {
        "crmid": userData.id,
        "tipo": userData.brType
      }
    }

    var evt = new CustomEvent('event-view-start');
    document.body.dispatchEvent(evt);

    introSystem(routeKey)
  }

  resetShowAlert = () => {
    this.setState({
      showAlert: {
        show: false,
        title: "",
        text: "",
        type: ""
      }
    })
  }

  handleClose = () => {
    this.setState({ openModal: false, modalData: {} })
  }

  resetFilters = () => {
    const { limit, sort } = this.state

    this.setState({
      login: undefined,
      selectedSwitch: searchLoginOptions[0],
      name: undefined,
      agency: undefined,
      email: undefined,
      corporation: emptySelect,
      dateApprove: [undefined, undefined],
      dateSignup: [undefined, undefined],
      level: emptySelect,
      blocked: emptySelect,
      affiliate: emptySelect,
      limit,
      page: 1,
      sort
    }, () => {
      if (this.loginRef?.current) this.loginRef.current.value = ''
      if (this.emailRef?.current?.children[0]?.children[0]) this.emailRef.current.children[0].children[0].value = ''
      if (this.nameRef?.current?.children[0]?.children[0]) this.nameRef.current.children[0].children[0].value = ''
      this.resetAgencies()
      this.resetPageAndCallUsers()
    })
  }

  render() {
    const {
      users,
      totalDocs,
      selectCorporations,
      selectAgencies,
      agencySelectDisable,
      loading,
      showAlert,
      selectedSwitch,
      login,
      dateApprove,
      openDatePickerApprove,
      dateSignup,
      openDatePickerSignup,
      blocked,
      value,
      affiliate,
      corporation,
      agency,
      level,
      name,
      email,
      selectedSignUpStatus
    } = this.state

    const options = {
      selectableRows: false,
      rowsPerPage: 10,
      filterType: "dropdown",
      responsive: "scrollMaxHeight",
      count: totalDocs,
      serverSide: true,
      pagination: true,
      print: false,
      filter: false,
      download: false,
      viewColumns: false,
      search: false,
      rowsPerPageOptions: [10, 15, 30, 50],
      textLabels: {
        body: {
          noMatch: "Desculpe, nenhum dado corresponde a pesquisa"
        },
        pagination: {
          next: "Próxima Página",
          previous: "Pagina Anterior",
          rowsPerPage: "Linhas por página:",
          displayRows: "de"
        },
        toolbar: {
          search: "Procurar",
          viewColumns: "Mostrar Colunas",
          filterTable: "Filtrar Tabela"
        },
        filter: {
          title: "FILTROS",
          reset: "limpar",
          all: "Todos"
        },
        viewColumns: {
          title: "Mostrar Colunas"
        }
      },
      onColumnSortChange: (value, type) => {
        const hasPlus = 'descending' === type ? '-' : ''
        this.setState({ sort: hasPlus + value }, () => {
          this.getUsers()
        })
      },
      onTableChange: (action, tableState) => {
        const { page } = this.state
        switch (action) {
          case 'changePage':
            this.changePage(tableState.page)
            break;
          case 'propsUpdate':
            if (page !== tableState.page + 1) tableState.page = 0;
            break;
          default:
            break;
        }
      },
      onChangeRowsPerPage: (number) => {
        this.setState({ limit: number }, this.getUsers)
      }
    }

    const newColumns = [
      ...columns,
      {
        name: "blocked",
        label: "Bloqueado",
        options: {
          sort: true,
          setCellProps: () => ({ className: 'no-wrap' }),
          customBodyRender: (value, rowIndex) => {
            const ID = rowIndex.rowData[0]

            return value ? "Sim" : "Não"
          }
        }
      },
      {
        name: 'unlink',
        label: 'Desvinculado',
        options: {
          sort: false,
          setCellProps: () => ({ className: 'no-wrap' }),
          customBodyRender: (value) => {

            return value ? "Sim" : "Não"
          }
        }
      },
      {
        name: "id",
        label: " ",
        options: {
          sort: true,
          setCellProps: () => ({ className: 'no-wrap' }),
          customBodyRender: (value, rowIndex) => {
            return (
              <button onClick={e => this.handleOpen(value)} className="link-style">Ver Mais</button>
            )
          }
        }
      }
    ]

    const [startDateApprove, endDateApprove] = dateApprove
    const [startDateSignup, endDateSignup] = dateSignup


    return (
      <div className="content">
        <OperatorModal customAction={this.searchSelectedCorp} handleClose={this.handleClose} modalData={this.state.modalData} open={this.state.openModal} onClose={() => this.handleClose()} />
        <LoadingAlert
          show={loading}
        />
        <Dialog
          show={showAlert.show}
          title={showAlert.title}
          text={showAlert.text}
          type={showAlert.type}

          onConfirm={() => this.resetShowAlert()}
        />

        <Grid fluid>
          <Row>
            <Col className="mb-4" lg={3} md={6} sm={12} xs={12}>
              <FormHelperText>Parâmetro de pesquisa {selectedSwitch.label}</FormHelperText>
              <Switch
                values={searchLoginOptions}
                selectedSwitch={selectedSwitch}
                setSelectedSwitch={(value) => {
                  this.loginRef.current.value = ''
                  this.setState({ selectedSwitch: value, login: null })
                }}
              />
            </Col>
            <Col lg={3} md={6} sm={12} xs={12}>
              <FormHelperText>Pesquisar por {selectedSwitch.label}</FormHelperText>
              <InputMask
                mask={selectedSwitch.mask}
                id="Input"
                ref={this.loginRef}
                onChange={(event) => {
                  this.setState({
                    login: event.target.value,
                  });
                }}
              >
                {() => (
                  <InputMuiField
                    placeholder={selectedSwitch.label}
                  />
                )}
              </InputMask>
            </Col>
            <Col className="mb-4" lg={3} md={6} sm={12} xs={12}>
              <FormHelperText>Pesquisar por Nome</FormHelperText>
              <TextField
                onChange={(e) => this.setState({ name: e.target.value })}
                placeholder="Nome"
                ref={this.nameRef}
                InputProps={{
                  classes: {
                    input: "mui-input-control",
                    root: "mui-input-control",
                  },
                }}
                className="input-background"
                InputLabelProps={{
                  classes: {
                    root: "mui-input-control mui-label",
                  },
                }}
                fullWidth
                color="primary"
                variant="outlined"
              />
            </Col>
            <Col className="mb-4" lg={3} md={6} sm={12} xs={12} >
              <FormHelperText>Pesquisar por E-mail</FormHelperText>
              <TextField
                onChange={(e) => this.setState({ email: e.target.value })}
                placeholder="E-mail"
                ref={this.emailRef}
                InputProps={{
                  classes: {
                    input: "mui-input-control",
                    root: "mui-input-control",
                  },
                }}
                className="input-background"
                InputLabelProps={{
                  classes: {
                    root: "mui-input-control mui-label",
                  },
                }}
                fullWidth
                color="primary"
                variant="outlined"
              />
            </Col>
          </Row>
          <Row>
            <Col className="mb-4" lg={3} md={6} sm={12} xs={12}>
              <FormHelperText>Filtrar por Nível</FormHelperText>
              <Select
                placeholder="Selecione um Nível"
                className='custom-select'
                styles={{ menu: styles => ({ ...styles, zIndex: 150 }) }}
                value={level}
                options={[emptySelect, ...(levelOptions(this.state.userData?.corpId))]}
                onChange={(event) => this.setState({ level: event })}
              />
            </Col>
            <Col className="mb-4" lg={3} md={6} sm={12} xs={12}>
              <FormHelperText>Filtrar por Corporação</FormHelperText>
              <Select
                placeholder="Selecione uma Corporação"
                className='custom-select'
                styles={{ menu: styles => ({ ...styles, zIndex: 150 }) }}
                options={selectCorporations}
                value={corporation}
                onChange={(event) => this.searchByCorporation(event)}
              />
            </Col>
            <Col className="mb-4" lg={3} md={6} sm={12} xs={12}>
              <FormHelperText>Filtrar por Loja</FormHelperText>
              <Select
                ref={this.agencyRef}
                isDisabled={agencySelectDisable}
                placeholder="Selecione uma Loja"
                className='custom-select'
                value={agency}
                styles={{ menu: styles => ({ ...styles, zIndex: 150 }) }}
                options={selectAgencies}
                onChange={(event) => this.searchByAgencie(event)}
              />
            </Col>
            <Col className="mb-4" lg={3} md={6} sm={12} xs={12}>
              <FormHelperText>Filtrar por Tipo de Cadastro</FormHelperText>
              <Select
                placeholder="Selecione Tipo de Cadastro"
                className='custom-select'
                value={affiliate}
                styles={{ menu: styles => ({ ...styles, zIndex: 150 }) }}
                options={[emptySelect, ...searchSignupTypeOptions]}
                onChange={(event) => this.setState({ affiliate: event, selectedSignUpStatus: emptySelect })}
              />
            </Col>
          </Row>
          <Row >
            <Col className="mb-4" lg={3} md={6} sm={12} xs={12}>
              <FormHelperText>Filtrar por Bloqueio</FormHelperText>
              <Select
                placeholder="Selecione Bloqueio"
                className='custom-select'
                value={blocked}
                styles={{ menu: styles => ({ ...styles, zIndex: 150 }) }}
                options={[emptySelect, ...searchblockOptions]}
                onChange={(event) => this.setState({ blocked: event })}
              />
            </Col>
            <Col className="mb-4" lg={3} md={6} sm={12} xs={12}>
              <FormHelperText>Filtrar por Data de cadastro</FormHelperText>
              <DatePicker
                className="date-picker custom-date-picker"
                selectsRange={true}
                placeholderText="Selecione um intervalo"
                locale='pt-BR'
                showYearDropdown
                showMonthDropdown
                onChange={(date, event) => {
                  let close = !date?.reduce((acc, value) => {
                    return acc && !!value
                  }, true)
                  this.setState({ openDatePickerSignup: close, dateSignup: date ? date : [undefined, undefined] })
                  if (!date) this.getUsers()
                }}

                value={startDateSignup && `${formatToBr(startDateSignup)} - ${endDateSignup ? formatToBr(endDateSignup) : ''}`}
                shouldCloseOnSelect={openDatePickerSignup}

                isClearable
                selected={startDateSignup}

                startDate={startDateSignup}
                endDate={endDateSignup}

                maxDate={new Date()}
              />
            </Col>
            <Col className="mb-4" lg={3} md={6} sm={12} xs={12}>
              <FormHelperText>Filtrar por Data de Aprovação</FormHelperText>
              <DatePicker
                className="date-picker custom-date-picker"
                selectsRange={true}
                placeholderText="Selecione um intervalo"
                locale='pt-BR'
                showYearDropdown
                showMonthDropdown
                onChange={(date, event) => {
                  let close = !date?.reduce((acc, value) => {
                    return acc && !!value
                  }, true)
                  this.setState({ openDatePickerApprove: close, dateApprove: date ? date : [undefined, undefined] }, () => {
                    if (!date) this.getUsers()
                  })

                }}

                value={startDateApprove && `${formatToBr(startDateApprove)} - ${endDateApprove ? formatToBr(endDateApprove) : ''}`}
                shouldCloseOnSelect={openDatePickerApprove}

                isClearable
                selected={startDateApprove}

                startDate={startDateApprove}
                endDate={endDateApprove}

                maxDate={new Date()}
              />
            </Col>
            {affiliate.value === true && <Col className="mb-4" lg={3} md={6} sm={12} xs={12}>
              <FormHelperText>Filtrar por Status de Cadastro</FormHelperText>
              <Select
                placeholder="Selecione Status de Cadastro"
                className='custom-select'
                value={selectedSignUpStatus}
                styles={{ menu: styles => ({ ...styles, zIndex: 150 }) }}
                options={[emptySelect, ...signUpStatus]}
                onChange={(event) => {
                  this.setState({ selectedSignUpStatus: event })
                }}
              />
            </Col>}
          </Row>
          <Row>
            <Col className="mb-4" lg={3} md={6} sm={12} xs={12}>
              <button
                className='btn btn-mlm btn-round btn-fill btn-block'
                onClick={() => this.resetPageAndCallUsers()}
              >
                Filtrar
              </button>
            </Col>
            <Col className="mb-4" lg={3} md={6} sm={12} xs={12}>
              <button
                className='btn btn-mlm btn-round btn-block'
                onClick={() => this.resetFilters()}
              >
                Limpar Filtros
              </button>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg={12}>
              <TableMui
                title={`Usuários encontrados: ${totalDocs}`}
                data={users}
                columns={newColumns}
                options={options}
              />
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

export default OperatorPanel