import Chart from 'chart.js'


const biggestLabel = (labels) => {
    let biggest = ''
    if(labels && labels.length > 1)
    labels.forEach(l => {
        if(l && l.length > biggest.length)
            biggest = l
    });
    return biggest.length
}

const biggestValue = (values) => {
    let biggest = ''
    if(values && values.length > 1)
    values.forEach(v => {
        const vstring = String(v)
        if(vstring && vstring.length > biggest.length)
            biggest = vstring
    });
    return biggest.length
}

const labelValue = {
    legend: {
        display: true,
        position: 'bottom',
        align: "center",
        labels: {
            fontSize: 14,
            boxWidth: 20,
            fontFamily: '"Courier New", Courier, monospace',
            generateLabels: function (chart) {
                var data = chart.data;
                if (data.labels.length && data.datasets.length) {
                    let biggestLabelSize = biggestLabel(data.labels)
                    let biggestValueSize = biggestValue(data.datasets[0].data)
                    return data.labels.map(function (label, i) {
                        var meta = chart.getDatasetMeta(0);
                        var ds = data.datasets[0];
                        var arc = meta.data[i];
                        var custom = arc && arc.custom || {};
                        var getValueAtIndexOrDefault = Chart.helpers.getValueAtIndexOrDefault;
                        var arcOpts = chart.options.elements.arc;
                        var fill = custom.backgroundColor ? custom.backgroundColor : getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor);
                        var stroke = custom.borderColor ? custom.borderColor : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);
                        var bw = custom.borderWidth ? custom.borderWidth : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);

                        // We get the value of the current label
                        var value = data.data? data.data[arc._index] : chart.config.data.datasets[arc._datasetIndex].data[arc._index];   
                        const gapLabel = biggestLabelSize - label.length
                        const gapValue = biggestValueSize - String(value).length
                        return {
                            // Instead of `text: label,`
                            // We add the value to the string
                            text: label + ": " + " ".repeat(gapLabel < 0 ? 0 : gapLabel ) + " ".repeat(gapValue < 0 ? 0 : gapValue) + value,
                            fillStyle: fill,
                            strokeStyle: stroke,
                            lineWidth: bw,
                            hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                            index: i
                        };
                    });
                } else {
                    return [];
                }
            }
        }
    }
}

export default labelValue