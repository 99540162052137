import React, { useMemo } from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

import { Wrapper, Error } from './styled'

function MuiSelect({ placeholder, options, onChange, error, inputRef, label, defaultInputValue, sm, ...rest }) {  
  
  const customStyles = useMemo(() => {
    return {
      container: styles => ({
        ...styles,
        border: error ? "1px solid #F44336" : 0,
        borderRadius: 4
      }),
      menu: styles => ({ ...styles, zIndex: 999 }),
      input: styles => ({
        ...styles,
        paddingTop: sm ? 0 : 10,
        marginBottom: -5,
        height: sm ? 28 : 48
      }),
      placeholder: styles =>
        ({
          ...styles,
          color: error ? "#F44336" : "grey",
          fontSize: "15px"
        })
    }
  }, []) 

  const animatedComponents = useMemo(() => makeAnimated(), []);
  
  return (
    <Wrapper>
      {label ? <p>{label}</p> : ''}
      <Select
        {...rest}
        noOptionsMessage={() => "Sem opções"}
        ref={inputRef}
        placeholder={placeholder}
        options={options}
        onChange={onChange}        
        components={animatedComponents}
        styles={customStyles}        
      />

      <Error hasError={!!(error)}>{error}</Error>
    </Wrapper>
  )
}

export default MuiSelect