import React from 'react'
import LinkButton from "../LinkButton";
import Popover from "@material-ui/core/Popover";


function BasicPopover({ text, buttonText, noWrap, isLink = true }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <LinkButton onClick={handleClick} noWrap={noWrap} isLink={isLink} style={{ fontSize: 'unset' }}>{buttonText}</LinkButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <p
          style={{
            margin: "1.1em",
            fontWeight: "500",
            fontFamily: "montserrat",
          }}
        >
          {text}
        </p>
      </Popover>
    </>
  );
}

export default BasicPopover