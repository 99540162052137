import styled from 'styled-components';

export const ModalBodyWrapper = styled.div`
  padding:15px;

  .user-info-row{
    display: flex; 
    margin-top: 5px; 
    font-size: 16px; 
    align-items: center;
  }

  .user-info-property{
    border: solid 4px grey; 
    border-radius: 40px;
  }

  .user-search-corp{
    display: flex;
    justify-content: space-evenly; 
    align-items: center;
    width:100%;
  }

  .user-info-value{
    word-break: break-all;
  }
`