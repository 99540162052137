import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import Select from "react-select";

const defaultStyles = theme => ({
  main: {
    display: "flex",
  },

  clearIcon: {
    "&:hover": {
      color: theme.palette.error.main
    }
  }
});

class MuiMonthSelect extends React.Component {

  onSearch = text => {
    this.props.onSearch(text);
  };

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyDown, false);
  }

  onKeyDown = event => {
    if (event.keyCode === 27) {
      this.props.onHide();
    }
  };

  onMonthChange = event => {
    const {onMonthSelect} = this.props
    onMonthSelect(event)
  }

  render() {
    const { classes, loading, month, availableDates, size = "md", ...rest } = this.props; 
    return (
        <div
          className={classes.main}
          ref={el => (this.rootRef = el)}
          {...rest}
        >
          <Select
            className={"React-select " + "React-select-" + size}
            value={month}
            onChange={this.onMonthChange}
            options={availableDates}
            placeholder="Selecione um Mês"
          ></Select>
          {loading && (
            <CircularProgress
              size={24}
              style={{
                marginLeft: 15,
                position: "relative",
                top: 4
              }}
            />
          )}
        </div>
    );
  }
}

export default withStyles(defaultStyles, { name: "MuiMonthSelect" })(
  MuiMonthSelect
);
