import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { formatToBr } from "utils/formaterDate";
import { ReactComponent as DeliveredIcon } from './assets/delivered-icon.svg'
import { ReactComponent as TruckIcon } from './assets/truck-icon.svg'
import { ReactComponent as OrderedIcon } from './assets/ordered-icon.svg'
import { numToBRL } from 'utils/formatData';
import maquininhaDireitaSrc from './assets/maquininha-direita.webp'

const CheckIcon = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="white"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>

const accountSituationMessages = {
  nova: 'Abriu nova conta digital',
  anterior: 'Usou conta digital existe',
  undefined: 'n/a'
}

const meiosDeOperacaoFormatted = {
  cartao: 'Cartão de crédito',
  boleto: 'Boleto'
}

function DetailsModal ({ show, details, onClose }) {
  const [deliveryStep, setDeliveryStep] = useState(0)

  useEffect (() => {
    function getDeliveryStep (details) {
      const deliveryStep =
        details?.arrivalDate ? 100 :
        details?.sentDate ? 100/2 :
        0

      setDeliveryStep(deliveryStep)
    }

    if (details) getDeliveryStep(details)
    if (!show && deliveryStep !== 0) setDeliveryStep(0)
  },[details, show])


  return (
    <Modal show={show} className='details-modal' onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Proposta {details?.number}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='details-modal__product-image-wrapper'>
          <img className='details-modal__product-image' src={maquininhaDireitaSrc} />
          {details?.step && <div className='details-modal__section details-modal__section--minus-image'>
            <h6>Etapa da proposta:</h6>
            <p>
              <span className={`details-modal__step-badge ${details?.step?.includes('finalizado') ? 'details-modal__step-badge--green' : ''}`}>
                {details?.step}
              </span>
              <span className='details-modal__additional-text'>Atualizado em: {formatToBr(details?.stepDate)}</span>
            </p>
          </div>}
          <div className='details-modal__section details-modal__section--minus-image'>
            <h6>Conta digital:</h6>
            <p>{accountSituationMessages[details?.contaDigital || 'undefined']}
              {details?.contaDigital === 'nova' && (
                <p><span className='details-modal__item-title'>Comissão da conta digital</span>: {numToBRL(details?.valueConta)}</p>
              )}
              {details?.contaDigital && (
                <p className='details-modal__additional-text'>Data da abertura da conta: {formatToBr(details?.contaDigitalDate)}</p>
              )}
            </p>
          </div>
          <div className='details-modal__section'>
            <div>
              <h6>Entrega da maquininha:</h6>
              <div className='details-modal__progress-bar'>
                <ProgressBar percent={deliveryStep}>
                  <Step>
                    {({ accomplished, index }) => (
                      <div
                        className={`indexedStep ${accomplished ? "accomplished" : null}`}
                      >
                        <div className='indexedStep__description-wrapper'>
                          {CheckIcon}
                          <OrderedIcon className='indexedStep__icon' />
                          <p className='indexedStep__description'>Pedido realizado</p>
                        </div>
                      </div>
                    )}
                  </Step>
                  <Step>
                    {({ accomplished, index }) => (
                      <div
                        className={`indexedStep ${accomplished ? "accomplished" : null}`}
                      >
                        <div className='indexedStep__description-wrapper'>
                          {CheckIcon}
                            <TruckIcon className='indexedStep__icon' />
                            <p className='indexedStep__description'>Em trânisto</p>
                          </div>
                      </div>
                    )}
                  </Step>
                  <Step>
                    {({ accomplished, index }) => (
                      <div
                        className={`indexedStep ${accomplished ? "accomplished" : null}`}
                      >
                        <div className='indexedStep__description-wrapper'>
                          {CheckIcon}
                            <DeliveredIcon className='indexedStep__icon' />
                            <p className='indexedStep__description'>Pedido entregue</p>
                          </div>
                      </div>
                    )}
                  </Step>
                </ProgressBar>
              </div>
              <p className='details-modal__additional-text center'><span className='details-modal__item-title'>Código de postagem</span>: {details?.postCode}</p>
              {details?.deliveryStatus && (
                <p><span className='details-modal__item-title'>Status da entrega</span>: {details?.deliveryStatus}</p>
              )}
              {details?.sentDate && (
                <p><span className='details-modal__item-title'>Data de envio</span>: {formatToBr(details?.sentDate)}</p>
              )}
              {details?.arrivalDate && (
                <p><span className='details-modal__item-title'>Data de entrega</span>: {formatToBr(details?.arrivalDate)}</p>
              )}
            </div>
          </div>
          <div className='details-modal__section'>
            <h6>Outras Informações</h6>
              {details?.nuproposta && <p><span className='details-modal__item-title'>ID do Pedido</span>: {details?.nuproposta}</p>}
              {details?.operation && <p><span className='details-modal__item-title'>Meio de operação</span>: {meiosDeOperacaoFormatted[details?.operation] || details?.operation}</p>}
              {details?.name && <p><span className='details-modal__item-title'>Cliente</span>: {details?.name}</p>}
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <p className='details-modal__additional-text'><span className='details-modal__item-title'>Código de Identificação</span>: {details?.linkId}</p>
      </Modal.Footer>
    </Modal>
  )
}

export default DetailsModal
