import styled from 'styled-components'

export const Error = styled.span `
  color: red;    
  padding: 5px;
  margin-bottom: ${props => props.hasError ? "5px" : "15px" };
`

export const Wrapper = styled.div `
  display: flex;
  justify-content: center;
  align-itens: center;  
  flex-direction: column;
`