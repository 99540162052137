import React, { Component } from 'react'
import UserContext from "./userContext";
import getUserData from "utils/checkUser.js";
import mainApi from "services/mainApi.js";

class Provider extends Component {
  
  state = {
    avatar: undefined,
    socialName: "",
    userNameContext: "",
    unreads:  localStorage.getItem("@uxtech-mlm/communication")
  };      

  componentDidMount = async () => {    
    await this.getAvatar();            
  }

  getAvatar = async () => {
    let userData = await getUserData(this.props.history);        

    let profilePhoto

    try {
      let endpoint = ['corporation', 'subcorp'].includes(userData.level) ? '/corporation/logo' : "/selfie"
      const response = await mainApi.get(endpoint);
      if (response.data.data.selfie && userData.level !== "corporation" && userData.level !== "subcorp") {
        
        profilePhoto = response.data.data.selfie;
        localStorage.setItem("@uxtech-mlm/UserProfilePhoto", profilePhoto);
      }
      if (response.data.data.logo && response.data.data.logo) {
        profilePhoto = response.data.data.logo
        localStorage.setItem("@uxtech-mlm/CorpLogo", profilePhoto);
      }      

      this.setState({avatar: profilePhoto})
    } catch (err) {
      if(err && err.response && err.response.data){
        if(err.response.data.code === 404){
          profilePhoto = 'not-found'
          localStorage.setItem("@uxtech-mlm/CorpLogo", profilePhoto);
        }
      }
    }          
    
    this.setState({avatar: profilePhoto})    
  };  

  setAvatar = async (photo) => {                
    await this.setState({
      avatar: photo,
    });    
    this.getAvatar();
  }  

  setSocialName = async (socialName) => {
    this.setState({ socialName })  
    
    localStorage.setItem("@uxtech-mlm/SocialName", socialName);
  }  

  setUserName = async (userNameContext) => {
    this.setState({ userNameContext })    

    localStorage.setItem("@uxtech-mlm/UserName", userNameContext);
  }
  
  setUnreads = (unreads) =>{
    this.setState({unreads})
    
    localStorage.setItem("@uxtech-mlm/communication", unreads);
  }

  render() {
    return (
      <UserContext.Provider
        value={{
          avatar: this.state.avatar,
          socialName: this.state.socialName,
          userNameContext: this.state.userNameContext,
          unreads: this.state.unreads,
          setAvatar: (photo) => this.setAvatar(photo),
          setSocialName: (name) => this.setSocialName(name),
          setUserName: (userNameContext) => this.setUserName(userNameContext),
          setUnreads: (unreads) => this.setUnreads(unreads) 
        }}    
      >
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export default Provider
