import React, { Component } from "react";

import "assets/css/styles.css";

import Button from "components/CustomButton/CustomButton.jsx";
import mainApi from "services/mainApi";
import { Dialog, LoadingAlert } from "./Alert/CustomAlert";
import { TextField } from '@material-ui/core';
import { Link } from "react-router-dom";
import config from "utils/serverVars";
import InputMask from "react-input-mask";
import clubeAmigoLogo from 'assets/img/clubeAmigo/clubeAmigoLogo.png'
import { ISClubeAmigo } from "utils/environment";

class NewPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmation: "",
      password: "",
      email: "",
      code: "",
      showSwal: false,
      swalMessage: "",
      swalTitle: '',
      swalType: "",
      passwordResetToken: "",
      btnNeedEmail: "Solicitar",
      btnContinue: "Continuar",
      needCode: false,
      showLoading: false,
      loadingTitle: "",
      onConfirm: () => { },
      isPartner: false,
      code: '',
      identificatorType: "cpf",
      placeholder: 'CPF',
      identificatorValue: "",
      email: '',
      mask: "999.999.999-99"
    };

    this.setState({ btnTitle: "Atualizar" });
  };

  linkResetPassword = () => {

    if (this.state.isPartner) return (
      <Link className="signup-link" onClick={() => this.setState({ isPartner: false, code: '' })}>
        Não sou parceiro!
      </Link>
    )
    else return (
      <Link className="signup-link " onClick={() => this.setState({ isPartner: true, code: '' })}>
        Esqueceu a senha de parceira(o)?
      </Link>
    )
  }

  handleResetRequest = async () => {
    const { identificatorType, identificatorValue, placeholder, email } = this.state;
    if (!identificatorValue) {
      this.showMessage("warning", "Atenção!", `Insira o ${placeholder} para continuar.`);
      return;
    }

    // eslint-disable-next-line
    // let validator = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // if (!validator.test(email)) {
    //   this.showMessage("warning", "Atenção!", "Email inválido.");
    //   return;
    // }

    try {
      this.setState({ showLoading: true, loadingTitle: 'Carregando' });

      const value = identificatorType !== 'code' ? identificatorValue.replace(/\D/g, "") : identificatorValue

      const response = await mainApi.post("/password/redefine", { identificatorType, identificatorValue: value, email });

      this.setState({ showLoading: false });

      this.showMessage('success', 'Email enviado!', response.data.message)


    } catch (err) {
      this.setState({ showLoading: false })
      if (!err.response) return;
      if (err.response.data) {
        const { message } = err.response.data;
        this.showMessage("warning", "Atenção!", message);
      }
    }
    this.setState({ showLoading: false, loadingTitle: 'Atualizar' })
  };

  showMessage = (type, title, text, callback) => {
    this.setState({
      showSwal: true,
      swalMessage: text,
      swalType: type,
      btnTitle: "Atualizar",
      swalTitle: title,
      onConfirm: callback
    });
  };

  handleReset = async () => {
    const { passwordResetToken, password, confirmation } = this.state;

    if (!password) {
      this.showMessage("warning", "Atenção", "Insira sua nova senha.");
      return;
    }
    if (!confirmation) {
      this.showMessage("warning", "Atenção", "Confirme sua nova senha.");
      return;
    }
    if (password !== confirmation) {
      this.showMessage("warning", "Atenção", "As senhas não combinam.");
      return;
    }

    try {
      await mainApi.post("password/reset", {
        passwordResetToken,
        password,
        confirmation
      });
      this.showMessage('success', "Sucesso", "Senha alterada com sucesso", () => { this.props.history.push('/') })
    } catch (err) {
      this.setState({ showLoading: false })
      if (!err.response) return;
      if (err.response.status === 422) {
        this.showMessage('warning', "Senha Fraca!", err.response.data.message)
        return;
      }
      if (err.response.data.error === "not-found") {

        this.showMessage(
          "danger",
          "Atenção",
          "Código de verificação não encontrado. Verifique se a senha já foi alterada."
        );
      }
    }
  };

  componentDidMount() {
    const { passwordResetToken } = this.props;
    if (passwordResetToken && passwordResetToken.length > 20)
      this.setState({ passwordResetToken });
    document.addEventListener(
      "keydown",
      this.handleKeyPressedOnDocument,
      false
    );
  }

  componentWillUnmount() {
    document.removeEventListener(
      "keydown",
      this.handleKeyPressedOnDocument,
      false
    );
  }

  handleKeyPressedOnDocument = event => {
    const { needCode, passwordResetToken, showLoading } = this.state;
    if (event.keyCode === 13 && !showLoading) {
      if (needCode || passwordResetToken)
        this.handleReset();
      else
        this.handleResetRequest();

    }
  };

  render() {
    const { needCode, showSwal, swalMessage, swalTitle, swalType, showLoading, loadingTitle, onConfirm, identificatorType, identificatorValue, placeholder, mask, email } = this.state;
    const { path } = this.props;

    const logo = ISClubeAmigo ? clubeAmigoLogo: config.PRODUCT_LOGO;

    let firstStep = "block";
    let secondStep = "none";

    if (!needCode) {
      if (this.props.needCode) {
        firstStep = "none";
        secondStep = "block";
      }
    }
    if (needCode) {
      secondStep = "block";
      firstStep = "none";
    }
    return (
      <div className="form-container">
        <Dialog
          type={swalType}
          text={swalMessage}
          show={showSwal}
          title={swalTitle}
          onConfirm={() => {
            this.setState({ showSwal: false }, onConfirm)
          }}
        />
        <LoadingAlert
          show={showLoading}
          title={loadingTitle}
          onConfirm={() => { this.setState({ showLoading: false }) }}
        />
        <img className="brand-logo" alt='Logo Banco Pan' src={logo}></img>
        <h4 style={{ textAlign: "center", color: "#4c4c4c" }}>
          Solicitação de troca de senha.
        </h4>
        <div style={{ display: `${firstStep}` }}>
          <div className="flex-full-center">
            <div className="p-3">
              <input
                className="mx-1 input-radio"
                type="radio"
                id="cpf"
                checked={identificatorType === 'cpf'}
                onChange={() => { this.setState({ identificatorType: 'cpf', placeholder: 'CPF', mask: "999.999.999-99", identificatorValue: '' }) }}
              />
              <label htmlFor="cpf" className="text-input-radio">
                CPF
              </label>
            </div>

            <div className="p-3">
              <input
                className="mx-1 input-radio"
                type="radio"
                id="cnpj"
                checked={identificatorType === 'cnpj'}
                onChange={() => { this.setState({ identificatorType: 'cnpj', placeholder: 'CNPJ', mask: "99.999.999/9999-99", identificatorValue: '' }) }}
              />
              <label htmlFor="cnpj" className="text-input-radio">
                CNPJ
              </label>
            </div>

            {ISClubeAmigo ? (
              <div className="p-3">
                <input
                  className="mx-1 input-radio"
                  type="radio"
                  id="re"
                  checked={identificatorType === 're'}
                  onChange={() => { this.setState({ identificatorType: 're', placeholder: 'RE', mask: "999999999", identificatorValue: '' }) }}
                />
                <label htmlFor="re" className="text-input-radio">
                  RE
                </label>
              </div>
            ) : (
              <div className="p-3">
                <input
                  className="mx-1 input-radio"
                  type="radio"
                  id="code"
                  checked={identificatorType === 'code'}
                  onChange={() => { this.setState({ identificatorType: 'code', placeholder: 'Código', mask: "**999999", identificatorValue: '' }) }}
                />
                <label htmlFor="code" className="text-input-radio">
                  Código
                </label>
              </div>
            )}
          </div>

          <div className="form-group">
            <InputMask
              mask={mask}
              id="Input"
              maskChar=""
              placeholder={placeholder}
              value={identificatorValue}
              onChange={(event) => {
                this.setState({
                  identificatorValue: event.target.value,
                });
              }}
            >
              {() => (
                <TextField
                  label={placeholder}
                  InputProps={{
                    classes: {
                      input: "mui-input-control",
                      root: "mui-input-control",
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: "mui-input-control mui-label",
                    },
                  }}
                  fullWidth
                  variant="outlined"
                />
              )}
            </InputMask>
          </div>
          {
            identificatorType === 'cnpj' && (
              <div className="form-group">
                <TextField
                  onChange={(event) => {
                    this.setState({
                      email: event.target.value,
                    });
                  }}
                  label={'Email (opcional)'}
                  InputProps={{
                    classes: {
                      input: "mui-input-control",
                      root: "mui-input-control",
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: "mui-input-control mui-label",
                    },
                  }}
                  fullWidth
                  variant="outlined"
                />
              </div>
            )
          }
          <Button
            fill
            block
            type='button'
            className="btn-login"
            onClick={this.handleResetRequest}
          >
            {this.loading || this.state.btnNeedEmail}
          </Button>
        </div>

        <form style={{ display: `${secondStep}` }}>
          <div className='form-group'>


            <TextField
              label="Senha"
              InputProps={{
                classes: {
                  input: "mui-input-control",
                  root: "mui-input-control"
                }
              }}
              InputLabelProps={{
                classes: {
                  root: "mui-input-control mui-label "
                }
              }}
              fullWidth
              variant="outlined"
              type="password"
              onChange={event => {
                this.setState({ password: event.target.value });
              }}
            />
          </div>
          <div className='form-group'>
            <TextField
              label="Confimação de senha"
              InputProps={{
                classes: {
                  input: "mui-input-control",
                  root: "mui-input-control"
                }
              }}
              InputLabelProps={{
                classes: {
                  root: "mui-input-control mui-label "
                }
              }}
              fullWidth
              variant="outlined"
              type="password"
              onChange={event => {
                this.setState({ confirmation: event.target.value });
              }}
            />
          </div>
          <Button fill block className="btn-login" onClick={this.handleReset} disabled={showLoading}>
            {this.state.btnContinue}
          </Button>
        </form>
        {/* <div style={{ margin: "5% 0" }}>
          {this.linkResetPassword()}
        </div> */}
        <div style={{ margin: "5% 0" }}>
          <Link className="signup-link " to={`/${path}`}>
            Voltar ao login
          </Link>
        </div>
        <a
          className="agx-link"
          href="https://www.agxsoftware.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          AGX Software © {new Date().getFullYear()}
        </a>
        <span style={{ marginBottom: 20 }}>
          <a
            className="optanon-show-settings optanon-toggle-display"
            style={{ cursor: "pointer" }}
          >
            Cookies que utilizamos
          </a>
        </span>
      </div>
    );
  }
}

export default NewPasswordForm;
