import React, { Component } from "react";

import "assets/css/styles.css";
import config from "utils/serverVars";

import Button from "components/CustomButton/CustomButton.jsx";
import mainApi from "services/mainApi";
import { Dialog } from "components/Alert/CustomAlert";
import queryString from "query-string";
import { TextField } from "@material-ui/core";

import clubeAmigoLogo from 'assets/img/clubeAmigo/clubeAmigoLogo.png'
import { ISClubeAmigo } from "utils/environment";

class EmailConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmation: "",
      password: "",
      btnTitle: "Confirmar",
      showSwal: false,
      swalMessage: "",
      swalTitle: "",
    };
  }

  showMessage = (title, text) => {
    this.setState({
      showSwal: true,
      swalMessage: text,
      btnTitle: "Confirmar",
      swalTitle: title,
    });
  };
  componentDidMount() {
    const search = queryString.parse(this.props.location.search);
    this.setState({ token: search.token });
  }
  handleSubmit = async () => {
    this.setState({ btnTitle: "Carregando..." });
    const { password, token } = this.state;

    if (!password) {
      this.showMessage("Atenção", "Insira sua senha!");
      return;
    }

    try {
      const response = await mainApi.patch("/email", {
        password,
        changeEmailToken: token,
      });

      this.showMessage("Sucesso", response.data.message);
      localStorage.setItem("@uxtech-mlm/UserResetPassword", false);
      setTimeout(() => {
        localStorage.clear();
        this.props.history.push("/");
      }, 2000);
    } catch (err) {
      if (!err.response) return;
      if (err.response.status === 422) {
        this.showMessage("Senha Fraca!", err.response.data.message);
      }
      if (err.response.data.message) {
        this.showMessage("Atenção", err.response.data.message);
      }
    }
    this.setState({ btnTitle: "Confirmar" });
  };

  render() {
    const { showSwal, swalMessage, swalTitle } = this.state;
    return (
      <div className="form-container">
        <Dialog
          type="warning"
          text={swalMessage}
          show={showSwal}
          title={swalTitle}
          onConfirm={() => {
            this.setState({ showSwal: false });
          }}
        />
        <img
          className="brand-logo"
          src={ISClubeAmigo ? clubeAmigoLogo : config.PRODUCT_LOGO}
          alt="Uxtech Logo"
        ></img>
        <h4 style={{ textAlign: "center", color: "#4c4c4c" }}>
          Insira sua senha para confirmar a alteração de Email
        </h4>
        <form onSubmit={(e) => {
          e.preventDefault()
          this.handleSubmit()
        }}>
          <div className="form-container">
            <TextField
              label="Confimação de senha"
              InputProps={{
                classes: {
                  input: "mui-input-control",
                  root: "mui-input-control",
                },
              }}
              InputLabelProps={{
                classes: {
                  root: "mui-input-control mui-label ",
                },
              }}
              fullWidth
              variant="outlined"
              type="password"
              className="form-control input-login"
              onChange={(event) => {
                if (event.target.value) this.setState({ password: event.target.value });
              }}
            />
          </div>
          <div className="form-container">
            <Button
              fill
              block
              className="btn-login"
              onClick={this.handleSubmit}
            >
              {this.state.btnTitle}
            </Button>
          </div>
          <div style={{ margin: "5% 0" }}>
            <a className="signup-link " href="/">
              Voltar ao login
            </a>
          </div>
          <a
            className="agx-link"
            href="https://www.agxsoftware.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            AGX Software © {new Date().getFullYear()}
          </a>
          <span style={{ marginBottom: 20 }}>
            <a
              className="optanon-show-settings optanon-toggle-display"
              style={{ cursor: "pointer" }}
            >
              Cookies que utilizamos
            </a>
          </span>
        </form>
      </div>
    );
  }
}

export default EmailConfirmation;
