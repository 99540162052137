import React, { Component } from 'react'

import MUIDataTable from "mui-datatables"
import { MuiThemeProvider } from "@material-ui/core/styles"
import getMuiTheme from 'utils/getMuiTheme'
import getUserData from "utils/checkUser";
import { LoadingAlert } from 'components/Alert/CustomAlert'
import MuiSelect from 'components/Selects/MuiSelect'

import InputMuiField from 'components/InputMuiField'
import { Grid, Row, Col } from 'react-bootstrap'
import { TableConfig, options } from './tableConfig'
import swal from 'components/Alert/alert'

import { MANAGEROPTIONS, MASTEROPTIONS } from '../values'
import mainApi from 'services/mainApi'

class ManagerList extends Component {

  constructor(props) {
    super(props)

    this.state = {
      managers: [],
      totalDocs: 0,
      page: 1,
      sort: '',
      name: '',
      manager: '',
      loading: true,
      isCorp: false,
      isMaster: false
    }
  }

  setQuery = () => {
    const { page, sort, name, manager } = this.state

    let query = ''

    query += `?page=${page}&limit=25`

    if(sort) query += `&sort=${sort}`
    if(name) query += `&name=${name}`
    if(manager) query += `&level=${manager}`

    return query
  }
  
  blockUser = async (id) => {
    try {
      const response = await mainApi.patch(`/master/user/block/${id}`)
      swal.confirm('success', "Sucesso!", response.data.message, () => this.getManagers())
    } catch(err) {
      swal.basic('warning', "Atenção!", err.response.data.message)
    }
  }

  updateManagerBlock = async (blocked, userId, level) => {          
    
    const data = {
      blocked,
      userId,
      level
    }

    this.setState({ loading: true })
    try {
      const response = await mainApi.put('/manager', data)

      swal.confirm('success', 'Sucesso', response.data.message, () => this.getManagers())
    } catch(err) {
      if(!err.response) swal.basic('warning', 'Atenção', "Falha ao se comunicar com o servidor.")
      swal.basic('warning', 'Atenção', err.response.data.message)
    }
    this.setState({ loading: false })

  }

  getManagers = async () => {
    
    const query = this.setQuery()

    this.setState({ loading: true })
    try {

      const response = await mainApi.get(`managers/accepted${query}`)
      const managers = response.data.data.managers.docs
      const totalDocs = response.data.data.managers.totalDocs

      this.setState({ managers, totalDocs })

    } catch(err) {
      console.log(err)
    }

    this.setState({ loading: false })
  }

  setUserData = async () => {
    const userData = await getUserData(this.props.history)

    const isCorp = ['corporation', 'subcorp'].includes(userData.level)
    const isMaster = [ 'master', 'submaster' ].includes(userData.level)

    this.setState({ isCorp, isMaster })
  }

  componentDidMount = async () => {
    await this.setUserData()
    await this.getManagers()
  }  

  changePage = (page) => {
    this.setState({ page: page + 1 }, () => this.getManagers())
  }

  onSortChange = (value) => {
    this.setState({ sort: value }, () => {
      this.changePage(0)
    })
  }

  resetManager = () => {
    this.setState({ manager: '' })
  }

  setName = value => {
    if(value.length > 3) this.setState({ name: value }, () => this.changePage(0))
    if(value.length === 0) this.setState({ name: value }, () => this.changePage(0))
  }

  setManager = value => {
    this.setState({ manager: value }, () => this.changePage(0))
  }  

  render() {
    const { managers, totalDocs, loading, isCorp, isMaster } = this.state    

    const finalOptions = options(totalDocs, this.state.page, this.onSortChange, this.changePage)

    return(
      <div className="content">
        <div className="container-fluid">

          <LoadingAlert
            show={loading}
          />

          <Grid fluid className="p-0">
            <Row className="mb-2">
              <Col md={4}>              
                <InputMuiField 
                  className="input-background"
                  label="Pesquisar por nome"
                  placeholder="Pesquisar por nome"
                  onChange={event => this.setName(event.target.value) }
                />   
              </Col>

              {
                isCorp
                ? <></> 
                : <Col md={4}>
                    <MuiSelect
                      placeholder="Pesquisar por gerente"
                      onChange={event => this.setManager(event.value)}           
                      options={isMaster ? MASTEROPTIONS : MANAGEROPTIONS}                  
                      inputRef={this.ManagerRef}
                    />
                  </Col>
              }

            </Row>

            <Row>
              <Col md={12}>
                <MuiThemeProvider theme={getMuiTheme}>
                  <MUIDataTable
                    title={`Total de gerentes cadastrados: ${totalDocs}`}
                    data={managers}
                    columns={
                      TableConfig(
                        isCorp,
                        this.updateManagerBlock,
                        this.blockUser
                      )
                    }
                    options={finalOptions}
                  />
                </MuiThemeProvider>
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
    )
  }
}

export default ManagerList