import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import mainApi from "services/mainApi";
import { Card } from "components/Card/Card.jsx";
import { Link } from 'react-router-dom';

export default class EmailUnsubscribe extends Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      unsubscribeError: false,
      unsubscribeTitle: 'Você se desinscreveu do recebimento de emails',
      unsubscribeContent: "Você não irá mais receber novos emails quando forem lançados.",      
    }
  }

  unsubscribeEmail = async (token) => {
    const data = { token }    

    this.setState({ loading: true })
    try {
      await mainApi.post('/mail/unsubscribe', data)                         
    } catch (err) {      
      this.setState({ 
        unsubscribeTitle: 'Algo deu errado!',
        unsubscribeContent: err.response.data.message,
        unsubscribeError: true        
      })
    }   
    this.setState({ loading: false }) 
  }

  componentDidMount(){
    const { unsubscribeToken } = this.props.match.params

    this.unsubscribeEmail(unsubscribeToken)
  }  

  render(){    
    const { unsubscribeTitle, unsubscribeContent, loading } = this.state

    if(loading) {
      return <div>Carregando</div>
    }

    return(        
      <Grid>
        <Row className="row-center-email">
          <Col>
            <div>
              <Card                                            
                hCenter
                title={unsubscribeTitle}
                content={
                  <>                  
                    <p>{unsubscribeContent}</p>
                    <p>Para voltar a receber os e-mails basta entrar em <Link to="/">configurações</Link> dentro da sua conta no plataforma e reativar o recebimento.</p>
                  </>
                } />                          
            </div>
          </Col>
        </Row>
      </Grid>    
    )  
  }  
}