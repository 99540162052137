import { introJs } from 'intro.js'
import mainApi from "services/mainApi";
import { convertStringIntoArray } from "utils/formatData";
import dataTour from 'utils/dataTour';
import { isMobile } from 'react-device-detect';

const introSystem = async (key) => {
  // introJs().exit();
  // showCloseButton(false);          
  // const finishedPages = localStorage.getItem("@uxtech-mlm/finishedPagesTour")  
  // const pagesTour = convertStringIntoArray(finishedPages)
  // const hasDuplicatedPage = pagesTour ? pagesTour.find(element => element === key) : undefined
  // if(hasDuplicatedPage === undefined) {
  //   if(isMobile) setTimeout(() => startIntro(key), 750)    
  //   else startIntro(key)
  // }
}

const resetTour = async () => {    

  const formData = new FormData();
  formData.append("resetTour", true);          

  introJs().exit();
  showCloseButton(false);
  
  try {
    await mainApi.patch("/profile", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });    

  } catch(err) {    
    console.log(err)
  } finally {
    localStorage.setItem("@uxtech-mlm/finishedPagesTour", '')    
  }
}

const tableSteps = (pageKey) => {    

  switch(pageKey) {    
    case  3: return [
      { intro: dataTour.sellersList.table },
      { element: document.querySelector("#data-tour-user-list-master-filter"), intro: dataTour.sellersList.filter },
      { element: document.querySelector("#data-tour-user-list-master-button"), intro: dataTour.sellersList.button },
      { element: document.querySelector("#data-tour-user-list-master-invite-button"), intro: dataTour.sellersList.invite }      
    ]
    case  6: return [{ intro: dataTour.payment.table }]
    case 12: return [
      { intro: dataTour.supervisedTeam.table },      
      { element: document.querySelector("#data-tour-supervised-team"), intro: dataTour.supervisedTeam.addTeam },
    ]
    case 18: return [
      { intro: dataTour.corpDetails.tableStore },
      { element: document.querySelector("#data-tour-corp-details-filters"), intro: dataTour.corpDetails.filters },
      { element: document.querySelector("#data-tour-corp-details-search"), intro: dataTour.corpDetails.search },
      { element: document.querySelector("#data-tour-corp-details-new-store"), intro: dataTour.corpDetails.newStore }
    ]
    case 19: return [{ intro: dataTour.paymentCorporation.tableDesciption }] 
    case 20: return [{ intro: dataTour.paymentAffiliate.tableDesciption }]
    case 21: return [
      { intro: dataTour.extractMaster.table },
      { element: document.querySelector("#data-tour-extract-master-date-picker"), intro: dataTour.extractMaster.datepicker },
      { element: document.querySelector("#data-tour-extract-master-total-commision"), intro: dataTour.extractMaster.sells },
      { element: document.querySelector("#data-tour-extract-master-total-corporation"), intro: dataTour.extractMaster.corporation },
      { element: document.querySelector("#data-tour-extract-master-total-affiliate"), intro: dataTour.extractMaster.affiliates },      
      { element: document.querySelector("#data-tour-extract-master-button-corporation"), intro: dataTour.extractMaster.corporationButton },                  
      { element: document.querySelector("#data-tour-extract-master-button-affiliate"), intro: dataTour.extractMaster.affiliateButton }
    ]
    case 28: return [{ intro: dataTour.teamListManegement.table }]    
    case 30: return [
      { intro: dataTour.userListMaster.table }, 
      { element: document.querySelector("#data-tour-user-list-master-filter"), intro: dataTour.userListMaster.params },

      { element: document.querySelector("#data-tour-user-list-master-filter-register-initialDate"), intro: dataTour.userListMaster.initialRegister },
      { element: document.querySelector("#data-tour-user-list-master-filter-register-finalDate"), intro: dataTour.userListMaster.finalRegister },
      { element: document.querySelector("#data-tour-user-list-master-filter-block"), intro: dataTour.userListMaster.block },
      { element: document.querySelector("#data-tour-user-list-master-aproved-initialDate"), intro: dataTour.userListMaster.initialAproved },
      { element: document.querySelector("#data-tour-user-list-master-aproved-finalDate"), intro: dataTour.userListMaster.finalAproved },
    ]
    case 32: return [
      { intro: dataTour.disclosureMaterial.table },      
      // { element: document.getElementsByClassName("card")[0].firstChild.firstChild.getElementsByTagName('div')[1].getElementsByTagName('button')[1]
      // , intro: dataTour.disclosureMaterial.newMaterial }      
    ]
    case 33: return [
      { intro: dataTour.extractCorporation.table },
      { element: document.querySelector("#data-tour-extract-corporation-date-picker"), intro: dataTour.extractMaster.datepicker },
      { element: document.querySelector("#data-tour-extract-corporation-total-commision"), intro: dataTour.extractCorporation.sells },
      { element: document.querySelector("#data-tour-extract-corporation-total-agencie"), intro: dataTour.extractCorporation.agencie },
      { element: document.querySelector("#data-tour-extract-corporation-total-sells"), intro: dataTour.extractCorporation.affiliates },      
      { element: document.querySelector("#data-tour-extract-corporation-button-agencie"), intro: dataTour.extractCorporation.agencieButton },                  
      { element: document.querySelector("#data-tour-extract-corporation-button-affiliate"), intro: dataTour.extractCorporation.affiliateButton }
    ]
    case 35: return [
      { intro: dataTour.subUsers.table },
      { element: document.querySelector("#data-tour-sub-users-create"), intro: dataTour.subUsers.newUser },
    ]
    case 36: return [
      { intro: dataTour.corpCampaign.table }
    ]
    case 38: return [
      { intro: dataTour.corpCampaign.table }
    ]
    default: return null
  }      
}

const closeIntro = (key) => {
  introJs()
  .onbeforeexit(() => {        
    updateStateTour(key)
  })  
  .exit()  
}

const showCloseButton = (value) => {
  // const closeButton = document.querySelector('.close-intro')
  // closeButton.style.display = 'none'     
}

const startIntro = (key) => { 
      
  showCloseButton(true)
  const steps = tableSteps(key)
  
  introJs()    
  .setOptions({
    'doneLabel': 'Finalizar',
    'prevLabel': 'Anterior',
    'nextLabel': 'Próximo',
    'skipLabel': 'Pular',            
    steps,    
    showProgress: true,    
    exitOnOverlayClick: false,
    showStepNumbers: true,
    disableInteraction: true
  })          
  .start()  
  .onbeforeexit(() => {    
    updateStateTour(key)
  })
}

const updateStateTour = (key) => {       

  const finishedPages = localStorage.getItem("@uxtech-mlm/finishedPagesTour")      
  const pagesTour = convertStringIntoArray(finishedPages)  

  const formData = new FormData();
  formData.append("completedPageTour", key)
  pagesTour.push(key)        

  try {
    mainApi.patch("/profile", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });

    localStorage.setItem("@uxtech-mlm/finishedPagesTour", pagesTour)      

  } catch(err) {
    console.log(err)
  }

  showCloseButton(false)  
}

export { introSystem, updateStateTour, resetTour, closeIntro }