import React, { useState, useRef, useLayoutEffect } from 'react'
import DatePicker from "react-datepicker";

import { Grid, Row, Col } from "react-bootstrap";
import { Modal } from 'react-bootstrap';

import CardContact from "components/Card/CardContact";
import MahoeIcons from "components/MahoeIcons";
import ImportContactModal from "components/ImportContactModal/ImportContactModal"
import { LoadingAlert } from "components/Alert/CustomAlert";
import swal from "components/Alert/alert";

import Pagination from "@material-ui/lab/Pagination";
import { CircularProgress } from "@material-ui/core";
import { Backup } from "@material-ui/icons";

import mainApi from "services/mainApi";
import to from "await-to-js";

import moment from 'moment'

import Select from "react-select";

import { objectToParams, getMonthAndYear } from "utils/formatData";
import { servers } from 'utils/config'

const appEnv = process.env.REACT_APP_ENV
let baseUrl = servers[appEnv] || servers['local'];

function ImportContactData() {

  const [uploading, setUploading] = useState(false)
  const [uploadingTitle, setUploadingTitle] = useState('')
  const [cvsLoading, setCvsLoading] = useState(false)
  const [updatedAt, setUpdatedAt] = useState(new Date())
  const [csvList, setCsvList] = useState()
  const [pagination, setPagination] = useState({ page: 1, totalPages: 1 })

  const [open, setOpen] = useState(false)
  const [fileSelect, setfileSelect] = useState()

  const [anchorEl, setAnchorEl] = useState()
  const [openPopper, setOpenPopper] = useState()
  const [selectDate, setSelectDate] = useState(new Date())

  const [detailsModal, setDetailsModal] = useState(false)
  const [selectedCSV, setSelectedCSV] = useState({})

  const inputRef = useRef(null);

  const delimiters = [
    { label: "Vírgula ( , )", value: "," },
    { label: "Ponto e vírgula ( ; )", value: ";" },
    { label: "Espaço", value: " " },
    { label: "/t", value: "\t" },
  ]

  const [delimiter, setDelimiter] = useState()

  const convertTimeStamp = () => {
    const date = moment(updatedAt);
    return date.format("DD/MM/YYYY - HH:mm");
  };

  const getImportedFiles = async (page) => {
    setCvsLoading(true)
    const params = objectToParams({ date: moment(selectDate).format('YYYY-MM'), sort: '', page: page || pagination.page, limit: 8, type: 'contact-data' })

    try {
      const response = await mainApi.get(`/import/${params}`)
      setPagination({ page: response.data.data.importRegisters.page, totalPages: response.data.data.importRegisters.totalPages })
      setCsvList(response.data.data.importRegisters.docs)
    } catch (error) {
      console.warn(error)
    }
    setCvsLoading(false)
  }

  const reload = async () => {
    await getImportedFiles();
    setUpdatedAt(Date.now())
  };

  const changePage = async (event, value) => {
    setPagination({
      page: value,
      totalPages: pagination.totalPages
    })
    getImportedFiles(value)
  }

  const handleFile = async (e) => {

    const file = e.target.files[0];

    if (!file) {
      swal.basic("info", "Arquivo não selecionado!", "Selecione um arquivo CSV.")
      return
    }
    setfileSelect(file)
    setOpen(true)
  };

  const importFile = async () => {
    if (!delimiter) {
      swal.basic("info", "Delimitador não selecionado!", "Selecione um delimitador.")
      return
    }

    const data = new FormData();
    data.append("csv", fileSelect);
    data.append("delimiter", delimiter.value);

    setUploading(true)
    setUploadingTitle("Inserindo Contatos")
    const [err, response] = await to(
      mainApi.post("/import/contact", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    );

    if (err && err.response) {
      swal.basic("warning", "Temos um Problema", err.response.data.message);
      setUploadingTitle("")
      setUploading(false)
      return;
    }
    const message = response?.data?.message || "";
    if (response?.data) swal.basic("success", "Importação de Contatos Solicitada!", message);
    handleClose()
    setUploadingTitle("")
    setUploading(false)
    getImportedFiles()

    if (inputRef.current) inputRef.current.value = ''
  }

  useLayoutEffect(() => {
    getImportedFiles()
  }, [])

  const time = convertTimeStamp()

  const handleClose = () => {
    setOpen(false)
    setfileSelect(undefined)
    setDelimiter()
    if (inputRef.current) inputRef.current.value = ''
  }

  const handleMouse = (event) => {
    setAnchorEl(event.currentTarget)
    setOpenPopper(true)
  };

  const deleteLogImport = async (id) => {
    setUploading(true)
    setUploadingTitle("Deletando Importação")
    try {
      const response = await mainApi.delete(`/import/${id}`)
      swal.basic("success", "Sucesso!", response?.data.message);
      getImportedFiles()
    } catch (err) {
      if (err && err.response) {
        swal.basic("warning", "Temos um Problema", err.response.data.message);
      }
      console.warn(err)
    }
    setUploadingTitle("")
    setUploading(false)
  }

  const handleDelete = (csv) => {
    swal.confirmNegate(
      'warning',
      "Atenção!",
      `Tem certeza que deseja deletar o registro da importação do arquivo ${csv?.name || ''}?`,
      () => deleteLogImport(csv?._id))
  }

  return (
    <>
      <Modal
        show={open}
        bsSize="lg"
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title>Importação de contatos</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "580px", minHeight: "350px", overflowY: "auto" }}>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <p>Selecione o delimitador utilizado no arquivo importado:</p>
              <div className="file-import">
                <MahoeIcons
                  name="document-lines"
                  size="15px"
                  fill="#000"
                />
                <span style={{ fontSize: '1em', marginLeft: '1em' }}>{fileSelect?.name}</span>
              </div>
            </Col>
            <Col className="form-group" md={6} lg={6}>
              <Select
                className={`custom-select`}
                value={delimiter}
                onChange={(item) => { setDelimiter(item) }}
                options={delimiters}
                placeholder="Selecione um Delimitador"
              />
              {/* {columns.map(column => (
              <Col  lg={6} md={6} sm={6} xs={12} >
                <FormControlLabel
                  control={
                  <Checkbox
                    checked={column.checked || columnsChecked[column.value]}
                    disabled={column.checked}
                    onChange={() => { setColumnsChecked({ ...columnsChecked, [column.value]: !columnsChecked[column.value] }) }}
                    name={column.label}
                    color="primary"
                  />}
                  label={column.label}
                /> */}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-mlm btn-mlm-sm" onClick={handleClose}> Cancelar </button>
          <button className="btn btn-fill btn-mlm btn-mlm-sm" onClick={importFile}> Importar </button>
        </Modal.Footer>
      </Modal>
      <ImportContactModal 
        csv={selectedCSV}
        open={detailsModal} 
        onClose={()=>{
          setDetailsModal(false)
        }}
      />
      <LoadingAlert show={uploading} title={uploadingTitle} />
      <Grid fluid style={{ paddingInline: "15px" }}>
        <Row style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: 15 }} >
          <Col style={{ display: 'flex', justifyContent: 'flex-end' }} lg={4} md={6} sm={24} xs={24}  >

          </Col>
        </Row>
        <Row className="filters" style={{ flexDirection: 'row', justifyContent: 'space-between',  marginRight: 15, marginLeft: 15 }}>
          <Col lg={4} md={6} sm={6} xs={24} style={{ marginTop: 15 }} >
            <DatePicker
              className="datepicker-theThird"
              dateFormat="MM/yyyy"
              locale="pt-BR"
              customInput={
                <div className="div-datepicker">
                  <div className="col-6">
                    <span className="placeholder-datepicker">
                      {selectDate ? "Mês selecionado:" : "Selecione um mês"}
                    </span>
                  </div>
                  <div className="col-3">
                    <p className="date-datepicker">
                      {selectDate ? getMonthAndYear(selectDate) : ""}
                    </p>
                  </div>
                  <div
                    className="col-3"
                    style={{ alignSelf: "center" }}
                    onMouseEnter={(event) => handleMouse(event)}
                    onMouseLeave={() => setOpenPopper(false)}
                  >
                    {selectDate ? (
                      <></>
                    ) : (
                      <MahoeIcons
                        aria-describedby={"iconTooltip"}
                        name="info"
                        size="23px"
                        fill="#039BE5"
                      />
                    )}
                  </div>
                </div>
              }
              selected={selectDate}
              showMonthYearPicker
              onChange={(date, event) => setSelectDate(date)}
              maxDate={new Date()}
              minDate={new Date("2020-01-01")}
            />
          </Col>
          <Col lg={4} md={6} sm={6} xs={24} style={{ marginTop: 15 }} >
            <button
              type="button"
              className='btn btn-fill btn-mlm'
              style={{ width: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              onClick={() => {
                console.log(inputRef.current)
                inputRef.current.click();
              }}
            >
              <Backup style={{ marginRight: '5px' }} /> Importar
            </button>
            <input
              type="file"
              style={{ display: "none" }}
              ref={inputRef}
              onChange={handleFile}
            ></input>
          </Col>
        </Row>
        <Col lg={4} md={12} sm={12} xs={24} style={{ marginTop: 15, marginRight: 15, marginLeft: 15 }} >
          <a  
            href="https://agxsoftwarecloud.s3.amazonaws.com/1617911056880-5df516d8dd6c1d76a762845d.csv" 
            download 
          >
            <button className="btn btn-mlm" style={{ width: "100%" }}>
              Baixar Modelo CSV
            </button>
          </a>
        </Col>
        <Row style={{ marginRight: 15, marginLeft: 15, marginTop: 10 }}>
          <Col>
            <Grid fluid>
              <Row>
                <Col style={{ display: 'flex' }} lg={10} md={10} sm={10}>
                  <h4 className="title" style={{ marginTop: 15, marginRight: 25 }}>
                    Listagem de CSVs importados
                  </h4>
                  <div
                    style={{
                      margin: "20px 0",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <span className="txtUpdateDashboard" style={{ paddingTop: "2px" }}>
                      Última atualização:{" "}
                      {uploading ? "Buscando..." : time}
                    </span>

                    <span
                      onClick={reload}
                      style={{
                        cursor: "pointer",
                        marginLeft: "10px",
                        marginRight: "10px",
                        // zIndex: 99999,
                      }}
                    >
                      <MahoeIcons name="reload" size="22px" fill="#039BE5" />
                    </span>
                  </div>
                </Col>
              </Row>
              <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                {csvList && !cvsLoading ?
                  csvList.map((csv) => (
                    <Col lg={3} sm={6} xs={12} key={csv._id} style={{ }}>
                      <CardContact 
                        data={{ ...csv }} 
                        onDelete={() => 
                          handleDelete(csv)
                        } 
                        onClick={()=>{
                          setSelectedCSV(csv)
                          setDetailsModal(true)
                        }}
                      />
                    </Col>
                  )) : (
                    <Col style={{ display: "flex", justifyContent: "center" }}>
                      <CircularProgress />
                    </Col>
                  )
                }
              </div>

              <div
                style={{
                  display: "grid",
                  justifyItems: "center",
                  margin: "10px",
                }}
              >
                <Pagination
                  count={pagination.totalPages}
                  page={pagination.page}
                  showFirstButton
                  showLastButton
                  onChange={changePage}
                />
              </div>
            </Grid>
          </Col>
        </Row>
      </Grid>
    </>
  )
}

export default ImportContactData
