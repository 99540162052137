import React, { Component } from "react";

import "assets/css/styles.css";
import config from "utils/serverVars";
import InternalForm from 'components/InternalForm'
import clubeAmigoBanner from 'assets/img/clubeAmigo/clubeAmigoBanner.png'
import TermsModal from "components/TermsModal/TermsModal";
import { ISClubeAmigo } from "utils/environment";
import campanhaWhatsappBanner from 'assets/img/pan_cover_campanha_whatsapp.png'

class Signin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banner: config.IMG_PF,
      loginType: "cnpj",
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props !== nextProps || this.state !== nextState;
  }

  render() {
    let banner, logo;

    if (ISClubeAmigo) {
      banner = clubeAmigoBanner
      logo = config.LOGO_ALT;
    } else {
      banner = config.IMG_PF;
      logo = config.LOGO_ALT;
    }


    const campanha = localStorage.getItem('@uxtech-mlm/campanha')
    const IS_CONSIGNADO = campanha === "indiqueopanconsignado"
    const IS_WHATSAPP = campanha === "whatsapp"
    if(IS_CONSIGNADO) banner = config.IMG_CAMPANHA_PF
    if(IS_WHATSAPP) banner = campanhaWhatsappBanner

    return (
      <>

        <div className="grid-parent">
          <div
            className={
              config.CLIENT === "pan"
                ? "kv-informative"
                : "kv-informative agx-bg"
            }
          >
            {config.CLIENT === "pan" && (
              <>
                <img className="login-pan-logo" alt="logo do banco pan" src={logo}></img>
                <div className="clubeAmigo_continer">
                  <img className="people" alt="logo do banco pan" src={banner}/>
                  {ISClubeAmigo && <TermsModal classNameLink={'clubeAmigo_terms'} />}
                </div>
              </>
            )}
          </div>
          <div className="kv-form-login">
            <InternalForm {...this.props} />
          </div>
        </div>
      </>
    );
  }
}

export default Signin;
