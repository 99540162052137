import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleDown, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom';
import MahoeIcon from "components/MahoeIcons";

const disabledStyle = {
  color: 'rgba(1,1,1,1,0.3)',
  opacity: '0.4',
  cursor: 'not-allowed'
}

const removeBodyClick = () => {

  if (document.documentElement.classList.contains("nav-open")) {
    let node = document.getElementById("bodyClick");
    document.body.removeChild(node);
  }

};

const activeRoute = (actualRoute, routeName) => {
  if (!actualRoute) return ""
  const actualRoutePaths = actualRoute.split('/')
  const routePaths = routeName.split('/')
  const params = routePaths.filter(path => path[0] === ':')
  let route = routeName;

  if (params.length > 0 && actualRoutePaths.length === routePaths.length && params) {
    params.forEach((path, index) => {
      const paramPos = routePaths.indexOf(path)
      route = route.replace(path, actualRoutePaths[paramPos])
    })
  }

  return route === actualRoute
    ? "active active-sub"
    : "";
}

const compactMenu = (key) => {
  let element = document.querySelector(`#compactMenu-${key}`)

  if (element.classList.contains('display-none')) {
    element.classList.remove('display-none')

  } else {
    element.classList.add('display-none')
    element.classList.add('slit-in-vertical')
  }
}

const changeArrow = (key) => {
  let arrowUp = document.querySelector(`#font-awesome-right-${key}`)
  let arrowDown = document.querySelector(`#font-awesome-down-${key}`)

  if (arrowUp.classList.contains('display-none')) {

    arrowDown.classList.add('display-none')
    arrowUp.classList.remove('display-none')

  } else {

    arrowDown.classList.remove('display-none')
    arrowUp.classList.add('display-none')

  }
}

export default function NavbarMenu({ index, config, actualRoute }) {
  const { insideLinks, disabled, key, icon, name } = config

  return (
    <li
      key={index}
    >
      <div
        className="nav-link nav-link-menu"
        onClick={() => {
          compactMenu(key)
          changeArrow(key)
        }}

        disabled={disabled}
        style={disabled ? disabledStyle : {}}
      >
        <MahoeIcon
          name={icon}
          size="20px"
          fill="rgba(255,255,255,0.8)"
        />
        <p>{name}</p>

        {insideLinks && (
          <>
            <div id={`font-awesome-down-${key}`} className="align-end flex-1 mr-2">
              <FontAwesomeIcon
                icon={faChevronCircleRight}
                size="sm"
              />
            </div>

            <div id={`font-awesome-right-${key}`} className="display-none align-end flex-1 mr-2">
              <FontAwesomeIcon
                icon={faChevronCircleDown}
                size="sm"
              />
            </div>
          </>
        )}
      </div>

      {insideLinks && (
        <ul className="display-none slit-in-vertical compact-ul-sidebar" id={`compactMenu-${key}`}>
          {insideLinks.map((item, i) => (
            <li
              className={activeRoute(actualRoute, item.layout + item.path)}

              key={i}
            >
              <NavLink
                to={item.disabled || disabled ? "#" : item.layout + item.path}
                className="nav-link py-2 pl-2"
                activeClassName="active"
                active={activeRoute(actualRoute, item.layout + item.path)}
                onClick={() => removeBodyClick()}
                disabled={item.disabled || disabled}
                style={item.disabled || disabled ? disabledStyle : {}}
              >
                <MahoeIcon
                  name={item.icon}
                  size="20px"
                  fill={
                    activeRoute(actualRoute, item.layout + item.path) ===
                      "active"
                      ? "rgba(255,255,255,1)"
                      : "rgba(255,255,255,0.8)"
                  }
                />
                <p>{item.name}</p>
              </NavLink>
            </li>
          ))}
        </ul>
      )}
    </li>
  )
}
