/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";

export class Card extends Component {
  render() {
    return (
      <div className={`card ${this.props.plain ? 'card-plain' : ''} ${this.props.className ? this.props.className : ''}`} style={this.props.style}>
        {
        this.props.title ||  this.props.category ?
        <div className={(this.props.paddingForm ? "card-padding-form" : "header") + (this.props.hCenter ? " text-center" : "")}>
          <h4 className="title" style={this.props.titleStyle || {}}>{this.props.title}</h4>
          <p className="category">{this.props.category}</p>
        </div>
        : <></>
        }
        <div
          style={{position: 'relative'}}
          className={
            "content" +
            (this.props.ctAllIcons ? " all-icons" : "") +
            (this.props.ctTableFullWidth ? " table-full-width" : "") +
            (this.props.ctTableResponsive ? " table-responsive" : "") +
            (this.props.ctTableUpgrade ? " table-upgrade" : "")
          }
        >
          {this.props.content}
          <div className="footer">
            {this.props.legend}
          </div>

        </div>
      </div>
    );
  }
}

export default Card;
