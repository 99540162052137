export default (status) => {
  switch(Number(status)){
    case 400:
    case 402:
    case 422:
    case 503:
      return "info";
    case 401:
    case 404: 
      return "warning";
    case 403:
    case 500:
      return "error";
    case 200:
    case 201:
      return "success";
    default:
      return "info";
  }
}