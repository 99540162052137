import React, { Component } from "react";
import { Col, Row, Button, Table, Label } from "react-bootstrap";
import NumberFormat from "react-number-format";
import InputMask from "react-input-mask";
import {
  LoadingAlert,
  Dialog,
  ConfirmationAlert,
} from "../components/Alert/CustomAlert";
import loadingGif from "assets/img/loading.gif";
import { numToBRL } from "../utils/formatData";
import { numberLength } from 'utils/numberMethods'

import logoPlaceholder from "../assets/img/logo-placeholder.png";
import Card from "../components/Card/Card";

import mainApi from "services/mainApi";
import NewCorpEmailModal from "./NewCorpEmailModal";
import getStatusCodeType from "utils/getStatusType";
import UserContext from "context/userContext";
import { IconButton, InputAdornment, TextField, FormControlLabel,CircularProgress } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';

import { Email, Lock, PhotoLibrary, StorefrontTwoTone, InfoOutlined, LockOutlined, LockOpenOutlined } from "@material-ui/icons"
import BigTooltip from "./BigTooltip";

import dataTour from "utils/dataTour";
import { introSystem } from 'utils/introToSystem';
import { affiliateCorpId } from 'utils/CommonData';
import InputMuiField from 'components/InputMuiField';

import { History } from "../components/Alert/CustomAlert";

import HistoryTable from "../components/HistoryTable"
import MahoeIcons from "components/MahoeIcons"
import { agxSubmasterId } from "utils/CommonData";
class CorpDetailsCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      swalMessage: ['', ''],
      swalMessageShow: false,
      swalShowLoading: false,
      edit: false,
      newCommission: 0,
      newValuePerAccount: 0,
      showNewPassModal: false,
      showNewEmailModal: false,
      showSwal: false,
      updateCorporationData: {},
      update: {},
      corpProducts: [],
  
      uploading: false,
      images: [],
      showEditHistory: false,
      history: false,
      rows: null,
      title:'',
      confimation:false

    };
  }

  static contextType = UserContext;

  updateLogo = async (e) => {
    const { corporation, userData, getCorpData } = this.props;
    const { setAvatar } = this.context;

    const file = e.target.files[0];
    this.setState({ uploading: true });
    let isCorretFiletype = Boolean(
      ["image/png", "image/jpeg", "image/jpg"].indexOf(file.type) + 1
    );
    if (!isCorretFiletype) {
      this.showMessage(
        "warning",
        "Formato Inválido",
        "Utilize algum dos formatos abaixo:",
        "png, jpg ou jpeg"
      );
      this.setState({
        swalShowLoading: false,
      });
      return;
    }

    const formData = new FormData();

    formData.append("logo", file);

    try {
      this.setState({ swalShowLoading: true });

      const response = await mainApi.put(
        `/corporation/${corporation._id}/logo`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );

      if (!['master', 'submaster'].includes(userData.level)) setAvatar(corporation.logo);

      this.setState({ corpsData: response.data.docs });
      this.showMessage("success", "Sucesso", "A logo foi alterada!");
      getCorpData()

    } catch (err) {
      if (!err.response) {
        if (err.message === "Network Error") {
          this.showMessage(
            "error",
            `Não foi possível carregar alterar a logo, tente novamente mais tarde.`
          );
          this.setState({ loading: false });
          return;
        }
        if (err.code === "ECONNABORTED")
          this.showMessage("error", "Tempo de espera atingido.");
        this.setState({ loading: false });
        return;
      }
      let status = getStatusCodeType(err.response.status);
      if (err.response.data.message) {
        this.showMessage(status, err.response.data.message);
        this.setState({ loading: false });
        return;
      }
    }
  };

  showMessage = (type, title, message, boldText) => {
    this.setState({
      swalMessageShow: true,
      swalMessage: [type, title, message, boldText],
      swalShowLoading: false,
    });
  };

  requestEmail = async () => {
    this.setState({ showNewEmailModal: true });
  };

  triggerInputFile = async (e) => {
    this.fileInput.click();
  };

  setCorpCommission = async () => {
    const { corporation, getCorpData } = this.props;
    const { newCommission } = this.state;
    this.setState({ swalShowLoading: true });
    try {
      const response = await mainApi.patch(
        `/corporation/${corporation._id}/commission`,
        { commission: newCommission }
      );
      this.setState({
        swalMessage: ["success", `${response.data.message}`],
        swalMessageShow: true,
        edit: false,
        update: {}
      });

      getCorpData()

    } catch (err) {
      console.log(err);
    }
    this.setState({ swalShowLoading: false });
  };

  setCorpValuePerAccount = async () => {
    const { corporation, getCorpData } = this.props;
    const { newValuePerAccount } = this.state;
    this.setState({ swalShowLoading: true });
    try {
      const response = await mainApi.patch(
        `/corporation/${corporation._id}/commission/account`,
        { value: newValuePerAccount }
      );
      this.setState({
        swalMessage: ["success", `${response.data.message}`],
        swalMessageShow: true,
        edit: false,
        update: {}
      });

      getCorpData()

    } catch (err) {
      console.log(err);
    }
    this.setState({ swalShowLoading: false });
  };

  handleFile = (event) => {
    let file = event.target.files[0];
    if (!file) return;
    let isCorretFiletype = Boolean(
      ["image/png", "image/jpeg", "image/jpg"].indexOf(file.type) + 1
    );
    if (isCorretFiletype) this.setState({ image: event.target.files[0] });
    else {
      this.setState({ image: null });
      return false;
    }
    return true;
  };

  resetPassword = async () => {
    const { corporation } = this.props;

    this.setState({ swalShowLoading: true });

    const data = {
      corpUserId: corporation.user._id,
      email: corporation.user.email,
    };
    try {
      const response = await mainApi.patch("/corpuser/reset/password", data);
      this.setState({ swalShowLoading: false });
      this.showMessage(
        "success",
        "Senha resetada com sucesso!",
        response.data.message
      );
    } catch (err) {
      this.setState({ swalShowLoading: false });

      if (!err.response) {
        if (err.code === "ECONNABORTED") { }
        else {
          this.showMessage(
            "error",
            "A senha não foi resetada",
            "Não foi possível resetar a senha, tente novamente mais tarde."`(código de erro: ${err.response.status})`
          );
        }
        return;
      }
      if (err.response.data.status >= 500) {
        let status = getStatusCodeType(err.response.status);
        this.showMessage(
          status,
          "A senha não foi resetada",
          "Não foi possível resetar a senha, tente novamente mais tarde."
        );
        return;
      }
      if (err.response.data.error === "invalid-fields") {
        let fields = err.response.data.fields;
        while (fields.length > 0) {
          const field = fields.pop();
          switch (field.name) {
            case "email": {
              this.showMessage("warning", "Email inválido", field.message);
              return;
            }
            case "corpUserId": {
              this.showMessage("warning", "Usuário inválido", field.message);
              return;
            }
            default:
              break;
          }
        }
      }
      this.showMessage(
        "warning",
        "Não foi possivel resetar a senha",
        err.response.data.message
      );
    }
  };


  updateCorporation = async () => {
    const { corporation, getCorpData } = this.props;
    const { setSocialName } = this.context
    const data = this.state.updateCorporationData
    try {
      const response = await mainApi.patch(`/corporation/${corporation._id}`, data)

      this.showMessage('success', 'Sucesso!', response.data.message)

      if (this.state.updateCorporationData.socialName) {
        setSocialName(this.state.updateCorporationData.socialName)
      }

      this.setState({
        updateCorporationData: {},
        update: {}
      })

      getCorpData()
    } catch (error) {
      if (!error.response) {
        this.showMessage('warning', 'Erro!', 'Não foi possível se comunicar com o servidor, tente mais tarde!')
      }
      else if (error.response.status === 422) {
        this.showMessage('info', 'Campo invalido!', error.response.data.message)
      }
      else if (error.response?.data?.message){
        this.showMessage('warning', 'Atenção', error.response?.data?.message)
      }
    }

    this.setState({
      swalShowLoading: false
    })
  }

  getProducts = async () => {
    const { corporation } = this.props

    try {
      const response = await mainApi.get(`/corporation/${corporation._id}/enable/products`)
      this.setState({
        corpProducts: response.data.data.products
      })
    } catch (error) {
      const response = error.response
      if (!response?.status) {
        this.showMessage('warning', 'Atenção!', 'Não foi possível se comunicar com o servidor, tente mais tarde!')
      }
      else if (500 === response.status) {
        this.showMessage('danger', 'Atenção!', response.data.message)
      }
      else {
        this.showMessage('info', 'Atenção!', response.data.message)
      }
    }
  }

  handleDisableProducts = async () => {
    this.setState({ swalShowLoading: true });
    const { corporation } = this.props
    const { corpProducts } = this.state

    const ids = corpProducts.filter(product => product.enabled !== true).map(product => product.id)

    try {
      const response = await mainApi.patch(`/corporation/${corporation._id}/disabled-products`, { ids })
      this.setState({ swalShowLoading: false });
      this.showMessage(
        "success",
        "Sucesso!",
        response.data.message
      );
    } catch (error) {
      const response = error.response
      if (!response?.status) {
        this.showMessage('warning', 'Atenção!', 'Não foi possível se comunicar com o servidor, tente mais tarde!')
      }
      else if (500 === response.status) {
        this.showMessage('danger', 'Atenção!', response.data.message)
      }
      else {
        this.showMessage('info', 'Atenção!', response.data.message)
      }
    }
  }

  getEditHistory = async (type) => {
    this.setState({ history: true })
    const { corporation } = this.props

    if (type == 'commission' ) this.setState({title:"Histórico de edição da Bonificação "})
    else if (type == 'valuePerAccount' ) this.setState({title:"Histórico de edição da Bonificação Conta Digital"})
    try {
      const response = await mainApi.get(`/corporation/${corporation._id}/${type}/updates`)
      const updates = response.data.data.updates
      this.setState({rows:updates})

    } catch (err) {
      console.error(err)
    }

  }

  integrateCorp = async () => {

    const { corporation, getCorpData } = this.props

    try {
      const response = await mainApi.patch(`/integrate/partner/${corporation._id}`)
      this.showMessage(
        "success",
        "Sucesso!",
        response.data.message
      );

      getCorpData({noLoading: true})
    } catch (err) {
      console.error(err)
      const response = err.response
      if (!response?.status) {
        this.showMessage('warning', 'Atenção!', 'Não foi possível se comunicar com o servidor, tente mais tarde!')
      }
      else if (500 === response.status) {
        this.showMessage('danger', 'Atenção!', response.data.message)
      }
      else {
        this.showMessage('info', 'Atenção!', response.data.message)
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {

    const { corporation, userData } = this.props;
    const { setAvatar } = this.context;

    if (
      Object.keys(prevProps.corporation).length <
      Object.keys(corporation).length
    ) {
      setAvatar(corporation.logo);
    }

    if (corporation._id !== prevProps.corporation._id) {
      if (['master', 'submaster'].includes(userData.level)) this.getProducts()
    }
  }

  componentDidMount = async () => {
    const { routeKey, userData } = this.props

    if (['corporation', 'subcorp'].includes(userData.level)) await introSystem(routeKey)
  }

  render() {
    const { corporation, userData, loadingCorp } = this.props;
    const {
      swalShowLoading,
      swalMessage,
      swalMessageShow,
      edit,
      showNewEmailModal,
      newCommission,
      update,
      updateCorporationData,
      corpProducts,
      newValuePerAccount,
      history,
      rows,
      title,
      confimation 
    } = this.state;

    const isMaster = ['master', 'submaster'].includes(userData.level)

    return (
      <>
        <ConfirmationAlert
          title='Liberação de API'
          confirmBtnText='Liberar'
          show={confimation}
          onCancel={() => {
            this.setState({ confimation: false });
          }}
          onConfirm={() => {
            this.setState({ confimation: false }, () => this.integrateCorp());
          }}
          text={`Tem certaza que deseja librarar a API de integração à ${ corporation.name }?`}
          bold="Esta ação não poderá ser desfeita futuramente."
        />

        <History
          show={history}
          onConfirm={() => this.setState({ history: false, rows: null })}
          title={title}
          content={() => (
          <>
           { rows?(
           <HistoryTable
              rows={rows}
            />
            ):(
            <Col style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Col>
            )}
          </>
          )}
        />

        {corporation.user && (
          <>
            <NewCorpEmailModal
              show={showNewEmailModal}
              corporation={corporation}
              onHide={() => this.setState({ showNewEmailModal: false })}
            />
          </>
        )}

        <Dialog
          type={swalMessage[0]}
          show={swalMessageShow}
          title={swalMessage[1]}
          onConfirm={() => {
            this.setState({ swalMessageShow: false });
          }}
          text={swalMessage[2]}
          boldText={swalMessage[3]}
        />
        <LoadingAlert show={swalShowLoading} />

        <ConfirmationAlert
          title=''
          show={this.state.showSwal}
          onCancel={() => {
            this.setState({ showSwal: false });
          }}
          onConfirm={() => {
            this.resetPassword();
            this.setState({ showSwal: false });
          }}
          text={`Deseja confirmar o reenvio de uma nova senha para o email ${corporation.user ? corporation.user.email : ''}?`}
        />

        <Row>
          <Col lg={3} md={4} sm={12}>
            <Card
              style={{ minHeight: 417 }}
              content={
                <>
                  <div className="corp-details-image">
                    <img
                      alt=""
                      src={
                        corporation.logo ? corporation.logo : logoPlaceholder
                      }
                    />
                  </div>

                  <div className="card-details-logo-corporation">
                    {['master', 'submaster'].includes(userData.level) ? (
                      <>
                        <BigTooltip title=" Resetar email" placement="top" data-intro={dataTour.corpDetailsCard.changeEmail}>
                          <IconButton
                            disabled={corporation._id === affiliateCorpId}
                            onClick={this.requestEmail}
                          >
                            <Email />
                          </IconButton>
                        </BigTooltip>

                        <BigTooltip
                          title="Resetar senha" placement="top"
                          data-intro={dataTour.corpDetailsCard.changePassword}
                        >
                          <IconButton
                            disabled={corporation._id === affiliateCorpId}
                            onClick={() => this.setState({ showSwal: true })}
                          >
                            <Lock />
                          </IconButton>
                        </BigTooltip>
                      </>
                    ) : null}

                    {['master', 'submaster', 'corporation', 'subcorp'].includes(userData.level) ? (
                      <>
                        <BigTooltip title="Editar Logo" placement="top">
                          <IconButton
                            data-intro={dataTour.corpDetailsCard.changeImage}
                            onClick={() => {
                              const input = document.getElementById("react-image-input")
                              input.click()
                            }}
                          >
                            <PhotoLibrary />
                          </IconButton>
                        </BigTooltip>
                        <input
                          id="react-image-input"
                          type="file"
                          accept="image/png, image/jpeg, image/jpg"
                          style={{ display: "none" }}
                          onChange={this.updateLogo}
                        />

                      </>
                    ) : null}
                  </div>
                    {
                      corporation.integrationIds ? 
                        <div className="flex-full-center my-2 tag-corban-corp-details-disabled"
                        >
                          <LockOpenOutlined />
                          <span className="pl-2">
                            API de Integração liberada
                          </span>
                        </div>
                      : isMaster && userData.id === agxSubmasterId ? 
                      <div className="flex-full-center my-2 tag-corban-corp-details"
                      onClick={() => this.setState({confimation:true})}
                      style={{ cursor: 'pointer' }}
                    >
                      <LockOutlined />
                      <span className="pl-2">
                        Liberar API de Integração
                      </span>
                    </div> 
                        : <></>
                    }
                </>
              }
            />
          </Col>

          <Col style={{ paddingLeft: 0, paddingRight: 0 }} lg={['master', 'submaster'].includes(userData.level) ? 6 : 9} md={['master', 'submaster'].includes(userData.level) ? 8 : 9} sm={12}>
            <div data-intro={dataTour.corpDetailsCard.description}>
              <Card
                style={{ minHeight: 417 }}
                ctTableFullWidth
                content={
                  <>
                    <div style={{ paddingLeft: 15 }}>
                      <div className="corp-details-wrapper">
                        <div style={{ display: 'flex', flexDirection: 'row', fontSize: '1.7em' }}>
                          {
                           isMaster ?
                              corporation.name ? (
                                <>
                                <div className="flex-full-center my-2 corp-details-card-name">
                                  <Label 
                                    bsStyle={corporation?.hasManager ? "primary" : "warning"}
                                    style={{ display: 'flex', flexDirection: 'row', marginRight: '10px' }}
                                  >
                                    <span style={{ fontSize: '15px' }}>
                                      {corporation?.hasManager ? "Corban" : "Corporação"}
                                    </span>
                                  </Label>
                                  {corporation.name ? corporation.name : 'Carregando...'}
                                  {
                                    String(affiliateCorpId) !== String(corporation._id) ? (
                                      <button
                                        className="link-style remove-button-focus-border"
                                        onClick={() =>
                                          this.setState({
                                            update: { name: !update.name },
                                            updateCorporationData: { name: corporation.name },
                                          })
                                        }>{" "}Editar</button>
                                    ) : ''
                                  }
                                </div>
                                </>
                              ) : 'Carregando...'
                              : (corporation.name ? corporation.name : 'Carregando...')
                          }
                        </div>
                        {
                          update.name ?
                            <div className="edit-corporation-commission">
                              <TextField
                                label="Nome"
                                InputProps={{
                                  classes: {
                                    input: "mui-input-control",
                                    root: "mui-input-control"
                                  }
                                }}
                                InputLabelProps={{
                                  classes: { root: "mui-input-control mui-label" }
                                }}
                                variant="outlined"
                                value={updateCorporationData.name}
                                fullWidth
                                onChange={event => {
                                  this.setState({
                                    updateCorporationData: { name: event.target.value },
                                  });
                                }}
                                placeholder="Nome fantasia"
                              />
                              <Button
                                className="btn-fill btn-mlm"
                                onClick={this.updateCorporation}
                                style={{ margin: '10px 15px', minWidth: 108.48 }}
                              >
                                Salvar
                            </Button>

                              <Button
                                style={{ margin: '10px 15px', minWidth: 108.48 }}
                                className="btn-fill btn-danger"
                                onClick={() =>
                                  this.setState({
                                    update: { socialName: false },
                                  })
                                }
                              >
                                Cancelar
                            </Button>
                            </div> : ''
                        }
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <strong style={{ marginRight: '10px' }}>Nome fantasia:</strong>
                          {
                            corporation.socialName ? (
                              <>
                                {corporation.socialName ? corporation.socialName : 'Carregando...'
                                }
                                {
                                  String(affiliateCorpId) !== String(corporation._id) ? (
                                    <button
                                      className="link-style remove-button-focus-border"
                                      onClick={() =>
                                        this.setState({
                                          update: { socialName: !update.socialName },
                                          updateCorporationData: { socialName: corporation.socialName },
                                        })
                                      }>{" "}Editar</button>) : ''
                                }
                              </>
                            ) : 'Carregando...'
                          }
                        </div>
                        {
                          update.socialName ?
                            <div className="edit-corporation-commission">
                              <TextField
                                label="Nome fantasia"
                                InputProps={{
                                  classes: {
                                    input: "mui-input-control",
                                    root: "mui-input-control"
                                  }
                                }}
                                InputLabelProps={{
                                  classes: { root: "mui-input-control mui-label" }
                                }}
                                variant="outlined"
                                value={updateCorporationData.socialName}
                                fullWidth
                                onChange={event => {
                                  this.setState({
                                    updateCorporationData: { socialName: event.target.value },
                                  });
                                }}
                                placeholder="Nome fantasia"
                              />
                              <Button
                                className="btn-fill btn-mlm"
                                onClick={this.updateCorporation}
                                style={{ margin: '10px 15px', minWidth: 108.48 }}
                              >
                                Salvar
                              </Button>

                              <Button
                                style={{ margin: '10px 15px', minWidth: 108.48 }}
                                className="btn-fill btn-danger"
                                onClick={() =>
                                  this.setState({
                                    update: { socialName: false },
                                  })
                                }
                              >
                                Cancelar
                              </Button>
                            </div> : ''
                        }
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <strong style={{ marginRight: '10px' }}>Descrição: </strong>
                          {
                            corporation.description ? (
                              <>
                                {corporation.description ? corporation.description : 'Carregando...'
                                }
                                {
                                  String(affiliateCorpId) !== String(corporation._id) ? (
                                    <button
                                      className="link-style remove-button-focus-border"
                                      onClick={() =>
                                        this.setState({
                                          update: { description: !update.description },
                                          updateCorporationData: { description: corporation.description },
                                        })
                                      }>{" "}Editar</button>) : ''
                                }
                              </>
                            ) : 'Carregando...'
                          }
                        </div>
                        {
                          update.description ?
                            <div className="edit-corporation-commission">
                              <TextField
                                label="Descrição"
                                InputProps={{
                                  classes: {
                                    input: "mui-input-control",
                                    root: "mui-input-control"
                                  }
                                }}
                                InputLabelProps={{
                                  classes: { root: "mui-input-control mui-label" }
                                }}
                                variant="outlined"
                                value={updateCorporationData.description}
                                fullWidth
                                onChange={event => {
                                  this.setState({
                                    updateCorporationData: { description: event.target.value },
                                  });
                                }}
                                placeholder="Nome fantasia"
                              />
                              <Button
                                className="btn-fill btn-mlm"
                                onClick={this.updateCorporation}
                                style={{ margin: '10px 15px', minWidth: 108.48 }}
                              >
                                Salvar
                              </Button>

                              <Button
                                style={{ margin: '10px 15px', minWidth: 108.48 }}
                                className="btn-fill btn-danger"
                                onClick={() =>
                                  this.setState({
                                    update: { description: false },
                                  })
                                }
                              >
                                Cancelar
                              </Button>
                            </div> : ''
                        }

                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <strong style={{ marginRight: '10px' }}>Código Corban: </strong>
                          {
                            loadingCorp ? 'Carregando...' :
                            ['master', 'submaster'].includes(userData.level) ?
                                <>
                                  {corporation.corbanCode ? (corporation.corbanCode ) : 'Não atribuido'}
                                  {
                                    String(affiliateCorpId) !== String(corporation._id) ? (
                                      <button
                                        className="link-style remove-button-focus-border"
                                        onClick={() =>
                                          this.setState({
                                            update: { corbanCode: !update.corbanCode },
                                            updateCorporationData: { corbanCode: corporation.corbanCode },
                                          })
                                        }>{" "}Editar</button>) : ''
                                  }
                                </>
                              : (corporation.corbanCode ? corporation.corbanCode : 'Não atribuido')
                          }
                        </div>
                        {
                          update.corbanCode ? (
                            <div className="edit-corporation-commission">
                               <TextField
                                label="Código Corban"
                                InputProps={{
                                  classes: {
                                    input: "mui-input-control",
                                    root: "mui-input-control"
                                  }
                                }}
                                InputLabelProps={{
                                  classes: { root: "mui-input-control mui-label" }
                                }}
                                variant="outlined"
                                value={updateCorporationData.corbanCode}
                                fullWidth
                                onChange={event => {
                                  this.setState({
                                    updateCorporationData: { corbanCode: event.target.value },
                                  });
                                }}
                                placeholder="Código Corban"
                              />

                              <Button
                                className="btn-fill btn-mlm"
                                onClick={this.updateCorporation}
                                style={{ margin: '10px 15px', minWidth: 108.48 }}
                              >
                                Salvar
                              </Button>

                              <Button
                                style={{ margin: '10px 15px', minWidth: 108.48 }}
                                className="btn-fill btn-danger"
                                onClick={() =>
                                  this.setState({
                                    update: { corbanCode: false },
                                  })
                                }
                              >
                                Cancelar
                              </Button>
                            </div>
                          ) : ''
                        }

                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <strong style={{ marginRight: '10px' }}>CNPJ: </strong>
                          {
                            ['master', 'submaster'].includes(userData.level) ?
                              corporation.cnpj ? (
                                <>
                                  {corporation.cnpj ? (
                                    <NumberFormat
                                      value={corporation.cnpj}
                                      displayType={"text"}
                                      format="##.###.###/####-##"
                                    />) : 'Carregando...'
                                  }
                                  {
                                    String(affiliateCorpId) !== String(corporation._id) ? (
                                      <button
                                        className="link-style remove-button-focus-border"
                                        onClick={() =>
                                          this.setState({
                                            update: { cnpj: !update.cnpj },
                                            updateCorporationData: { cnpj: corporation.cnpj },
                                          })
                                        }>{" "}Editar</button>) : ''
                                  }
                                </>
                              ) : 'Carregando...'
                              : (corporation.cnpj ? corporation.cnpj : 'Carregando...')
                          }
                        </div>
                        {
                          update.cnpj ? (
                            <div className="edit-corporation-commission">
                              <InputMask
                                mask="99.999.999/9999-99"
                                value={updateCorporationData.cnpj}
                                onChange={event => {
                                  this.setState({
                                    updateCorporationData: { cnpj: event.target.value.replace(/\D/g, "") }
                                  });
                                }}
                              >
                                {() => (
                                  <TextField
                                    label="CNPJ"
                                    InputProps={{
                                      classes: {
                                        input: "mui-input-control edit-corporation-input-comission",
                                        root: "mui-input-control"
                                      },
                                      type: "tel",
                                      pattern: "[0-9]*",
                                      inputMode: "numeric"
                                    }}
                                    fullWidth
                                    InputLabelProps={{
                                      classes: {
                                        root: "mui-input-control mui-label"
                                      }
                                    }}
                                    variant="outlined"
                                  // error={Boolean(cnpjMessage)}
                                  />
                                )}
                              </InputMask>

                              <Button
                                className="btn-fill btn-mlm"
                                onClick={this.updateCorporation}
                                style={{ margin: '10px 15px', minWidth: 108.48 }}
                              >
                                Salvar
                              </Button>

                              <Button
                                style={{ margin: '10px 15px', minWidth: 108.48 }}
                                className="btn-fill btn-danger"
                                onClick={() =>
                                  this.setState({
                                    update: { cnpj: false },
                                  })
                                }
                              >
                                Cancelar
                              </Button>
                            </div>
                          ) : ''
                        }
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <strong style={{ marginRight: '10px' }}>Telefone: </strong>
                          <>
                            {corporation.phone ?
                              (
                                <>
                                  <NumberFormat
                                    value={corporation.phone}
                                    displayType={"text"}
                                    format="(##) #####-####"
                                  />
                                  {
                                    String(affiliateCorpId) !== String(corporation._id) ? (
                                      <button
                                        className="link-style remove-button-focus-border"
                                        onClick={() =>
                                          this.setState({
                                            update: { phone: !update.phone },
                                            updateCorporationData: { phone: corporation.phone },
                                          })
                                        }>{" "}Editar</button>) : ''
                                  }
                                </>
                              ) : 'Carregando...'
                            }
                          </>
                        </div>
                        {
                          update.phone ? (
                            <div className="edit-corporation-commission">
                              <InputMask
                                mask="(99) 99999-9999"
                                value={updateCorporationData.phone}
                                onChange={event => {
                                  this.setState({
                                    updateCorporationData: { phone: event.target.value.replace(/\D/g, "") }
                                  });
                                }}
                              >
                                {() => (
                                  <TextField
                                    label="Telefone/Fixo para contato."
                                    data-intro={dataTour.createCorp.telephone}
                                    InputProps={{
                                      classes: {
                                        root: "mui-input-control",
                                        input: "mui-input-control",
                                        notchedOutline: "mui-input-focus"
                                      }
                                    }}
                                    fullWidth
                                    InputLabelProps={{
                                      classes: {
                                        root: "mui-input-control mui-label"
                                      }
                                    }}
                                    // error={Boolean(phoneMessage)}
                                    variant="outlined"
                                  />
                                )}
                              </InputMask>

                              <Button
                                className="btn-fill btn-mlm"
                                onClick={this.updateCorporation}
                                style={{ margin: '10px 15px', minWidth: 108.48 }}
                              >
                                Salvar
                                </Button>

                              <Button
                                style={{ margin: '10px 15px', minWidth: 108.48 }}
                                className="btn-fill btn-danger"
                                onClick={() =>
                                  this.setState({
                                    update: { phone: false },
                                  })
                                }
                              >
                                Cancelar
                                </Button>
                            </div>
                          ) : ''
                        }
                        {['master', 'submaster'].includes(userData.level) ? (
                          <>
                            <span>
                              <strong style={{ marginRight: '10px' }}>Bonificação do Consignado:
                              {isMaster && <BigTooltip title="Ver histórico de edições." placement="top">
                                  <IconButton
                                    style={{ height: '10px', width: '10px', marginLeft: '5px' }}
                                    disabled={corporation._id === affiliateCorpId}
                                    onClick={() => this.getEditHistory('commission')}
                                  >
                                    <InfoOutlined />
                                  </IconButton>
                                </BigTooltip>}
                              </strong>

                              {corporation.percentage ?
                                <>{`${corporation.percentage}%`}
                                  {
                                    String(affiliateCorpId) !== String(corporation._id) ? (
                                      <button
                                        className="link-style remove-button-focus-border"
                                        onClick={() =>
                                          this.setState({
                                            update: { commission: true },
                                            edit: !this.state.edit,
                                            newCommission: corporation.percentage,
                                          })
                                        }>{" "}Editar</button>) : ''
                                  }
                                </>
                                :
                                "Carregando..."
                              }
                              {/* eslint-disable-next-line */}
                              <br />
                            </span>
                          </>
                        ) : null}
                        <>
                          <span>
                            <strong style={{ marginRight: '10px' }}>Bonificação Conta Digital:
                              {isMaster && <BigTooltip title="Ver histórico de edições. " placement="top">
                                <IconButton
                                  style={{ height: '10px', width: '10px', marginLeft: '5px' }}
                                  disabled={corporation._id === affiliateCorpId}
                                  onClick={() => this.getEditHistory('valuePerAccount')}
                                >
                                  <InfoOutlined />
                                </IconButton>
                              </BigTooltip>}
                            </strong>
                            {!isNaN(corporation.valuePerAccount) ?
                              <>{`R$ ${corporation.valuePerAccount}`}
                                {['master', 'submaster'].includes(userData.level) ? (
                                  String(affiliateCorpId) !== String(corporation._id) ? (
                                    <button
                                      className="link-style remove-button-focus-border"
                                      onClick={() =>
                                        this.setState({
                                          update: { valuePerAccount: true },
                                          edit: !this.state.edit,
                                          newValuePerAccount: corporation.valuePerAccount,
                                        })
                                      }>{" "}Editar</button>) : ''
                                ) : null}
                              </>
                              : "Carregando..."}
                            {/* eslint-disable-next-line */}
                            <br />
                          </span>
                        </>

                        <>
                          <p style={{ fontSize: 14 }}><strong className="mr-2">Email:</strong> { corporation.user ? corporation.user.email : '' }</p>
                        </>
                      </div>
                    </div>

                    {['master', 'submaster'].includes(userData.level) ? (
                      <>
                        <span>
                          {!edit ? (
                            <div style={{ paddingTop: 15 }}>
                              <Table striped responsive style={{ paddingTop: 15 }}>
                                <thead>
                                  <tr>
                                    <th>Lojas</th>
                                    <th>Vendedores</th>
                                    <th>Links Gerados</th>
                                    <th>Cliques</th>
                                    <th>Convertidos</th>                                    
                                    <th>Bonificação</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>{corporation.agencies}</td>
                                    <td>{corporation.invited}</td>
                                    <td>{corporation.links}</td>
                                    <td>{corporation.clicked}</td>
                                    <td>{corporation.converted}</td>
                                    <td>{numToBRL(corporation.commission)}</td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          ) : update.commission ? (
                            <div className="edit-corporation-commission">
                              <TextField
                                type="number"
                                InputProps={{
                                  classes: {
                                    input: "mui-input-control edit-corporation-input-comission",
                                    root: "mui-input-control",
                                  },
                                  inputMode: "tel",
                                  pattern: "[0-9]*",
                                  startAdornment: (
                                    <InputAdornment
                                      style={{
                                        position: "absolute",
                                        marginLeft: Number.isInteger(
                                          Number(newCommission)
                                        )
                                          ? 52
                                          : 52,
                                      }}
                                      position="start"
                                    >
                                      {" "}
                                      %
                                    </InputAdornment>
                                  ),
                                }}
                                InputLabelProps={{
                                  classes: {
                                    root: "mui-input-control mui-label",
                                  },
                                }}
                                // eslint-disable-next-line react/jsx-no-duplicate-props
                                inputProps={{
                                  step: "0.1",
                                }}
                                variant="outlined"
                                value={newCommission}
                                style={{ margin: '10px 15px' }}
                                onChange={(event) => {
                                  let commission = event.target.value;
                                  if (commission >= 100) commission = 100;
                                  if (commission < 0) commission = "";
                                  this.setState({ newCommission: commission });
                                }}
                              />

                              <Button
                                className="btn-fill btn-mlm"
                                onClick={this.setCorpCommission}
                                style={{ margin: '10px 15px', minWidth: 108.48 }}
                              >
                                Salvar
                              </Button>

                              <Button
                                style={{ margin: '10px 15px', minWidth: 108.48 }}
                                className="btn-fill btn-danger"
                                onClick={() =>
                                  this.setState({
                                    edit: !this.state.edit,
                                    update: { commission: false },
                                    newCommission: corporation.percentage,
                                  })
                                }
                              >
                                Cancelar
                              </Button>
                            </div>
                          ) : update.valuePerAccount ? (
                            <div className="edit-corporation-commission">
                              <TextField
                                type="number"
                                InputProps={{
                                  classes: {
                                    input: "mui-input-control edit-corporation-input-comission",
                                    root: "mui-input-control",
                                  },
                                  inputMode: "tel",
                                  pattern: "[0-9]*",
                                  startAdornment: (
                                    <InputAdornment
                                      style={{
                                        position: "absolute",
                                        marginRight: Number.isInteger(
                                          Number(newCommission)
                                        )
                                          ? 40
                                          : 50,
                                      }}
                                      position="start"
                                    >
                                      R$
                                      {" "}
                                    </InputAdornment>
                                  ),
                                }}
                                InputLabelProps={{
                                  classes: {
                                    root: "mui-input-control mui-label",
                                  },
                                }}
                                // eslint-disable-next-line react/jsx-no-duplicate-props
                                inputProps={{
                                  step: "0.1",
                                  style: { marginLeft: 35 }
                                }}
                                variant="outlined"
                                value={newValuePerAccount}
                                style={{ margin: '10px 15px' }}
                                onChange={(event) => {
                                  if (Number(event.target.value)) {
                                    let accountCommissionCorp = numberLength(event.target.value);
                                    if (accountCommissionCorp < 0) accountCommissionCorp = 0;
                                    this.setState({
                                      newValuePerAccount: accountCommissionCorp,
                                    })
                                  } else this.setState({ newValuePerAccount: 0 })
                                }}
                              />

                              <Button
                                className="btn-fill btn-mlm"
                                onClick={this.setCorpValuePerAccount}
                                style={{ margin: '10px 15px', minWidth: 108.48 }}
                              >
                                Salvar
                              </Button>

                              <Button
                                style={{ margin: '10px 15px', minWidth: 108.48 }}
                                className="btn-fill btn-danger"
                                onClick={() =>
                                  this.setState({
                                    edit: !this.state.edit,
                                    update: { valuePerAccount: false },
                                    newValuePerAccount: corporation.newValuePerAccount,
                                  })
                                }
                              >
                                Cancelar
                              </Button>
                            </div>
                          ) : <></>}
                        </span>
                      </>
                    ) : (
                        <div style={{ paddingTop: 15 }}>
                          <Table striped responsive>
                            <thead>
                              <tr>
                                <th>Lojas</th>
                                <th>Vendedores</th>
                                <th>Links Gerados</th>
                                <th>Links Clicados</th>
                                <th>Conversões</th>                                
                                <th>Bonificação</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  {corporation.agencies ? (
                                    corporation.agencies
                                  ) : (
                                      <img
                                        src={loadingGif}
                                        alt=""
                                        className="loading-gif"
                                      />
                                    )}
                                </td>
                                <td>
                                  {corporation.invited !== undefined ? (
                                    corporation.invited
                                  ) : (
                                      <img
                                        src={loadingGif}
                                        alt=""
                                        className="loading-gif"
                                      />
                                    )}
                                </td>
                                <td>
                                  {corporation.links !== undefined ? (
                                    corporation.links
                                  ) : (
                                      <img
                                        src={loadingGif}
                                        alt=""
                                        className="loading-gif"
                                      />
                                    )}
                                </td>
                                <td>
                                  {corporation.clicked !== undefined ? (
                                    corporation.clicked
                                  ) : (
                                      <img
                                        src={loadingGif}
                                        alt=""
                                        className="loading-gif"
                                      />
                                    )}
                                </td>
                                <td>
                                  {corporation.converted !== undefined ? (
                                    corporation.converted
                                  ) : (
                                      <img
                                        src={loadingGif}
                                        alt=""
                                        className="loading-gif"
                                      />
                                    )}
                                </td>
                                
                                <td>
                                  {corporation.total !== undefined ? (
                                    numToBRL(corporation.total)
                                  ) : (
                                      <img
                                        src={loadingGif}
                                        alt=""
                                        className="loading-gif"
                                      />
                                    )}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      )}
                  </>
                }
              />
            </div>
          </Col>
          {
            ['master', 'submaster'].includes(userData.level) ?
              <Col lg={3} md={6} sm={12}>
                <Card
                  style={{ minHeight: 417 }}
                  title="Produtos"
                  content={
                    <>
                      {
                        corpProducts.map((product, index) => (
                          <div key={product.id}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="default"
                                  name="permission"
                                  checked={product.enabled}
                                  disabled={corporation._id === affiliateCorpId}
                                  onChange={(event) => {
                                    corpProducts[index].enabled = !product.enabled
                                    this.setState({ corpProducts })
                                  }}
                                />
                              }
                              label={product.name}
                            />
                            <br />
                          </div>
                        ))
                      }

                      {
                        !!corpProducts.length && (corporation._id !== affiliateCorpId) ?
                          <button
                            disabled={corporation._id === affiliateCorpId}
                            className="btn-products btn btn-fill btn-mlm-sm btn-mlm "
                            onClick={() => this.handleDisableProducts()}>
                            Salvar
                        </button> : ''
                      }
                    </>
                  }
                />
              </Col> : ''
          }
        </Row>

      </>
    );
  }
}

export default CorpDetailsCard
