import React, { memo } from 'react'
import { TextField } from "@material-ui/core"

import { Wrapper } from './styled'

const InputMuiFilter = ({ label, chidren, value, error, ...rest }) => {
  
  return (
    <Wrapper>
      <TextField
        InputProps={{
          classes: {
            input: "mui-input-control",
            root: "mui-input-control",
          },
        }}
        InputLabelProps={{
          classes: {
            root: "mui-input-control mui-label",
          },
        }}
        fullWidth
        color="primary"
        variant="outlined"
        label={label}
        value={value}
        error={error}
        { ...rest }
        >
        {chidren}
      </TextField>            
    </Wrapper>
  )  
}

export default memo(InputMuiFilter)