import React, { useState, useEffect } from 'react'

import { Dialog, LoadingAlert }  from 'components/Alert/CustomAlert.jsx'

import { TextField } from "@material-ui/core";
import Modal from '@material-ui/core/Modal';
import mainApi from "services/mainApi";
import SweetAlert from "sweetalert2";

import logoPdf from '../assets/img/pdf17.svg'
import picturePlaceholder from '../assets/img/picture-placeholder.png'

import MuiSelect from 'components/Selects/MuiSelect'

const invalidFieldsMessages = {'name': "Insira um nome para o arquivo!"}

function DisclosureModal ({ open, onClose, selectOptions, editMaterial }) {
  const suportedFiles = ['jpeg', 'jpg', 'png'];
  
  const [file, setFile] = useState({})
  const [upload, setUpload] = useState('Clique para escolher o material')
  const [filePreview, setFilePreview] = useState(picturePlaceholder)
  const [name, setName] = useState('')
  const [alert, setAlert] = useState({ show: false })
  const [showLoading, setShowLoading] = useState(false)
  const [selectedProducts, setSelectedProducts] = useState([])

  // const handleAlert = () => {
  //   if(selectedProducts.length) {
  //     handleSubmit()
  //     return
  //   }
    
  //   SweetAlert.fire({
  //     title: 'Atenção!',
  //     text: 'Ao não selecionar um produto, o material será enviado para TODOS os usuários do sistema. Confirma?',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Sim',
  //     cancelButtonText: 'Cancelar',
  //     customClass: {
  //       container: 'disclosure-alert'
  //     }
  //   }).then(async (result) => {
  //     if(result.isConfirmed) handleSubmit()
  //   })
  // }

  const handleSubmit = async () => { 

    setShowLoading(true)

    const formData = new FormData()
    formData.append('material', file)
    formData.append('name', name)

    let productsId = selectedProducts.map(v=> v.value)
    formData.append('productsId', productsId)

    try {
      const response = await mainApi.post('/material', formData,{
        headers: { "Content-Type": "multipart/form-data" }
      })
      
      setAlert({
        type: 'success',
        title: 'Sucesso',
        text: response.data.message,
        show: true
      })

      clearStates()
      onClose()

    } catch (err) {

      if (!err.response) {
        setAlert({
          type: 'warning',
          title: 'Atenção!',
          text: 'Não foi possivél realizar esta ação, reinicie sua internet ou tente mais tarde!',
          show: true
        })
      } else {
        const fields = err.response.data.fields
        let invalidFields
        if(fields){
          invalidFields = fields.reduce((acc, field) => invalidFieldsMessages[field] ? [...acc, invalidFieldsMessages[field]] : acc, []).join(',')
        }

        setAlert({
          type: 'warning',
          title: 'Atenção!',
          text: err.response.data.message,
          boldText: invalidFields,
          show: true
        })
      }
    }

    setShowLoading(false)
  }
  
  const handleInputFile = (file) => {
    const [,type] = file.type.split('/')

    if (suportedFiles.includes(type)) {
      setFile(file)
      setUpload(`Arquivo ${type} selecionado com sucesso.`)
      setFilePreview(URL.createObjectURL(file))
    } else if (type === 'pdf') {
      setFile(file)
      setUpload(`Arquivo ${type} selecionado com sucesso.`)
      setFilePreview(logoPdf)
    } else {
      setAlert({
        type: 'warning',
        title: 'Atenção',
        text: 'Tipo de arquivo invalido!',
        show: true
      })
    }
  } 

  const clearStates = () => {
    setName('')
    setFile({})
    setFilePreview(picturePlaceholder)
    setSelectedProducts([])
    setUpload('Clique para escolher o material')
  }

  const handleSelectChange = (value, {action, removedValue}) => {

    
    switch (action) {
      case 'remove-value':
      case 'pop-value':
        setSelectedProducts(selectedProducts.filter(v=>v.value!==removedValue.value))
        break;
      case 'clear':
        value = setSelectedProducts([]);
        break;
      case 'select-option': 
        const selectedValue = value[value?.length-1];
        setSelectedProducts(value)
        if(selectedValue.label == "Todos os produtos") {
          setSelectedProducts(selectOptions.filter(prod=> prod.label !== "Todos os produtos"))
        }
        break;
    }
  }

  const handleUpdate = async () =>{
    if(!editMaterial?._id) return

    setShowLoading(true)

    const formData = new FormData()
    // formData.append('material', file)
    formData.append('name', name)

    let productsId = selectedProducts.map(v=> v.value)
    formData.append('productsId', productsId)

    const data = {
      name,
      productsId
    }

    try {
      const response = await mainApi.patch(`material/${editMaterial?._id}`, data)
      
      setAlert({
        type: 'success',
        title: 'Sucesso',
        text: response.data.message,
        show: true
      })

      clearStates()
      onClose()

    } catch (err) {

      if (!err.response) {
        setAlert({
          type: 'warning',
          title: 'Atenção!',
          text: 'Não foi possivél realizar esta ação, reinicie sua internet ou tente mais tarde!',
          show: true
        })
      } else {
        const fields = err.response.data.fields
        let invalidFields
        if(fields){
          invalidFields = fields.map(field => invalidFieldsMessages[field]).join(',')
        }

        setAlert({
          type: 'warning',
          title: 'Atenção!',
          text: err.response.data.message,
          boldText: invalidFields,
          show: true
        })
      }
    }

    setShowLoading(false)
  }

  useEffect(()=>{
    if(editMaterial){
      const product = selectOptions?.filter((opt) => editMaterial.productsId?.includes(opt.value))
      setName(editMaterial.name)
      setSelectedProducts(product)
      if (suportedFiles.includes(editMaterial.format)) {
        setFilePreview(editMaterial.url)
      } else if (editMaterial.format === 'pdf') {
        setFilePreview(logoPdf)
      }

      // setUpload('')
    }
  },[editMaterial])
  
  return (
    <>
      < Dialog
        type={alert.type}
        title={alert.title}
        text={alert.text}
        show={alert.show}
        boldText={alert.boldText}
        onConfirm={() => setAlert({ show: false } )}
      />
      < LoadingAlert
        show={showLoading}
        title='Carregando...'
      />
      <Modal open={open} >
        <div className='flex-center max-size modal-media'>
          <div className='modal-card' >
            <div className='bd-bottom' >
              <h3 className='material-title' >Material para divulgação</h3>
            </div>

            <div className=' modal-container' >
              <div className='max-size flex-center bd-right' >
                <img alt="" className='modal-image' src={filePreview} />
              </div>
              
              <div className='max-size modal-form' >
                <TextField
                  label="Nome"
                  InputProps={{
                    classes: {
                      input: "mui-input-control",
                      root: "mui-input-control"
                    }
                  }}
                  InputLabelProps={{
                    classes: { root: "mui-input-control mui-label" }
                  }}
                  // error={Boolean(nameMessage)}
                  variant="outlined" 
                  value={name}
                  fullWidth
                  onChange={event => setName(event.target.value)}
                  placeholder="Nome"
                />
                {!editMaterial && <div className='max-size flex-center mg-top-2'>
                  <div className={!!editMaterial?'material-file-disabled':'material-file'}>
                    {upload}
                    <input 
                      type="file" 
                      multiple={false} 
                      disabled={!!editMaterial}
                      name="example"
                      accept='image/jpeg, image/png, application/pdf' 
                      onChange={(event) => { 
                        if(event.target.files.length) handleInputFile(event.target.files[0])
                      }}
                      className='max-size modal-input-file'
                    />
                  </div>
                </div>}
                <div className="material-select-product">
                  <label>Quais produtos este material divulga?</label>
                  <MuiSelect 
                    options={selectOptions} 
                    isMulti onChange={handleSelectChange} 
                    placeholder={"Selecionar Produtos"} 
                    value={selectedProducts}
                  />
                </div>
              </div>
            </div>
            
            <div className='flex-end max-size pd-2 bd-top' >
              <button
                className="btn btn-fill btn-mlm btn-mlm-sm"
                onClick={() => {
                  clearStates()
                  onClose()
                }}
              >
                Voltar
              </button>
              <button
                className="btn btn-fill btn-mlm btn-mlm-sm"
                onClick={() => editMaterial? handleUpdate():handleSubmit()}
              >
                {editMaterial?"Atualizar":"Criar"}
              </button>
            </div>
          </div>
          
        </div>

      </Modal>
    </>
  )
}

export default DisclosureModal