export const onlyDigits = (value) => {
  if (value) {
    return value.replace(/\D/g, '')
  }
}

export const numberLength = value => {
  const [number, afterComma] = String(value).split('.')

  if(!afterComma) return Number(value)
  
  return Number(`${number}.${afterComma.slice(0, 2)}`)
}

export const formatSize = value => {
  const aux = String(value)

  if(aux.length <= 3) return `${value} B`
  
  if(aux.length <= 6) {
    const [kb, b] = String(value/1000).split('.')

    if(b[0] === '0') return `${kb} KB`
    return `${kb},${b.charAt(0)} KB`
  } 

  if(aux.length > 6) {
    const [mb, kb] = String(value/1000000).split('.')

    if(kb[0] === '0') return `${mb} MB`
    return `${mb},${kb.charAt(0)} MB`
  }

  return value
}