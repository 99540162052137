import React from 'react'
import { formatSize } from 'utils/numberMethods'
import logoPdf from '../../assets/img/pdf17.svg';
import { formatToBr } from 'utils/formaterDate';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DownloadOutlinedIcon from '@material-ui/icons/CloudDownload'

function MaterialCard({ material, editMaterial, downloadMaterial, showProducts, deleteMaterial }) {
  return (
    <div className="card" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', flexBasis: '250px', flexGrow: 1, padding: '10px' }}>
      <div className="card-image" style={{ height: 350, maxHeight: 350, display: 'flex', alignItems: 'center'}}>
        <img
          className="img-rounded"
          src={(material.format !== 'pdf') ? material.url : logoPdf}
          alt={material.name}
          style={{ maxHeight: material.format !== 'pdf' ? 350 : 200, maxWidth: '100%', margin: 'auto', display: 'block' }}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h3 className="title" style={{ margin: '.5em', textAlign: 'center' }}>{material.name}</h3>
        <p style={{margin: 0}}>
          <strong>Tipo:</strong> {String(material.format).toUpperCase()}
        </p>
        <p style={{margin: 0}}>
          <strong>Tamanho:</strong> {formatSize(material.size)}
        </p>
        <p style={{margin: 0}}>
          <strong>Data criação:</strong> {formatToBr(material.createdAt)}
        </p>
        <p style={{margin: 0}}>
          <strong>Autor:</strong> {material.uploaderName}
        </p>
        <p style={{margin: 0}}>
          <strong>Nível do autor:</strong> {material.uploaderLevel}
        </p>
        <div style={{display: 'flex', gap: '.5em', alignItems: 'center'}}>
          <p style={{margin: 0}}>
            <strong>Produtos:</strong>
          </p>
          {material.productInfo.length 
            ? <a style={{ textDecoration: 'underline' }} onClick={() => showProducts(material)}>Ver ({material.productInfo.length})</a>
            : <p style={{margin: 0}}>Todos</p>
          }
          
        </div>
        
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end', alignItems: 'center', gap: '2em', margin: '1em' }}>
          {
            material.edit &&
            <DeleteOutlineOutlinedIcon
              color='error' 
              style={{ cursor: 'pointer', transform: "scale(1.5)", }}
              onClick={() => deleteMaterial(material._id)} 
            />
          }
          {
            material.edit && 
            <EditOutlinedIcon style={{ cursor: 'pointer', transform: "scale(1.5)" }} onClick={() => editMaterial(material)} />
          }
          <DownloadOutlinedIcon className='primary-text' style={{ cursor: 'pointer', transform: "scale(1.5)" }} onClick={() => downloadMaterial(material)} />
        </div>
    </div>
  )
}

export default MaterialCard