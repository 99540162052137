import React, { Component } from "react";
import { Grid, Col, Row } from 'react-bootstrap'
import MUIDataTable from "mui-datatables";
import DatePicker from "react-datepicker";
import Moment from 'moment'

import { Dialog } from 'components/Alert/CustomAlert'
import { StatsCard } from "components/StatsCard/StatsCard.jsx";
import MahoeIcon from "../../components/MahoeIcons";
import { numToBRL, getMonthAndYear } from "utils/formatData";
import mainApi from "services/mainApi";
import { introSystem } from 'utils/introToSystem';
import getUserData from "utils/checkUser";
import { translateMonths } from 'utils/translate'
import { contaId } from "utils/CommonData";

import './styles.css'
import dev from "utils/dev";
import { MuiThemeProvider } from "@material-ui/core";
import getMuiTheme from "utils/getMuiTheme";
import Select from "react-select";
import LoadingCover from "components/LoadingCover";


class Extract extends Component {

  constructor(props) {
    super(props)
    this.state = {
      extracts: [],
      corporations: [],
      products: [],
      csv: '',

      // query
      search: '',
      sort: '',
      selectDate: new Date(),
      selectProduct: '',

      // totals
      totalCommission: 0,
      totalCorporation: 0,
      totalAffiliate: 0,
      totalCount: 0,

      // pagination
      page: 1,
      limit: 10,
      totalDocs: 0,

      loading: false,
      userData: {},
      swalMessageShow: false,
      swalTitle: '',
      swalText: ''
    }

    this.teamHeader = [
      {
        name: "name",
        label: "Nome",
        options: {
          filter: true,
          sort: true,
          hint: "Nome da corporação.",
        }
      },
      {
        name: "count",
        label: "Qtde",
        options: {
          filter: true,
          sort: true,
          hint: "Quantidade total de propostas feita pela corporação.",
        }
      },
      {
        name: "commission",
        label: "Bonificação",
        options: {
          filter: true,
          sort: true,
          hint: "Bonificação total da corporação.",
          customBodyRender: value => {
            return numToBRL(value);
          }
        }
      },
      {
        name: "total",
        label: "Valor",
        options: {
          filter: true,
          sort: true,
          hint: "Valor total vendido pela corporação.",
          customBodyRender: (value) => {
            return numToBRL(value);
          }
        },
      },
    ];
  }

  handleStatsCard = async () => {
    const { selectDate, selectProduct } = this.state;

    this.setState({ loading: true })

    const formatedDate = Moment(selectDate).format('YYYY-MM')

    try {
      const response = await mainApi.get(
        `/master/extract/details?date=${formatedDate}&product=${selectProduct.value}`
      );
      this.setState({
        // corporations: response.data.extract.docs,
        totalCommission: response.data.count.total,
        totalCorporation: response.data.count.corporation,
        totalAffiliate: response.data.count.affiliate,
        totalCount: response.data.totalCount,
        loading: false
      })
    } catch (err) {
      dev.log(err);
      this.setState({
        // corporations: response.data.extract.docs,
        totalCommission: 0,
        totalCorporation: 0,
        totalAffiliate: 0,
        totalCount: 0,
        loading: false
      })
    }
  };

  handleCorporationList = async () => {
    const { selectDate, search, page, limit, sort, selectProduct } = this.state;

    const formatedDate = Moment(selectDate).format('YYYY-MM')
    const query = `?date=${formatedDate}&product=${selectProduct.value}&page=${page}&limit=${limit}${search}${sort}`

    try {
      const response = await mainApi.get(
        `/master/extract/corporation/list${query}`
      );

      this.setState({
        corporations: response.data.docs,
        totalDocs: response.data.totalDocs
      })
    } catch (err) {
      dev.log(err);
    }
  }

  handleCorporationCsv = async () => {
    const { selectDate, search, selectProduct } = this.state;

    const formatedDate = Moment(selectDate).format('YYYY-MM')
    const query = `?date=${formatedDate}&product=${selectProduct.value}&download=true${search}`

    try {
      const response = await mainApi.get(
        `/master/extract/details${query}`
      );

      this.setState({ csv: response.data.csv }, () => {
        if (this.state.csv) {
          this.createDownloadLink('corporations');
        } else {
          this.setState({
            swalMessageShow: true,
            swalTitle: 'Documento não disponível',
            swalText: 'O documento solicitado nao possui nenhum registro.'
          })
        }
      })
    } catch (err) {
      dev.log(err);
    }
  }

  handleAffiliateCsv = async () => {
    const { selectDate, search, sort, selectProduct } = this.state;

    const formatedDate = Moment(selectDate).format('YYYY-MM')
    const query = `?date=${formatedDate}&product=${selectProduct.value}${search}${sort}`

    try {
      const response = await mainApi.get(
        `/master/extract/affiliates${query}`
      );
      dev.log(response.data)

      this.setState({ csv: response.data.csv }, () => {
        if (this.state.csv) {
          this.createDownloadLink('affiliates');
        } else {
          this.setState({
            swalMessageShow: true,
            swalTitle: 'Documento não disponível',
            swalText: 'O documento solicitado nao possui nenhum registro.'
          })
        }
      })
    } catch (err) {
      dev.log(err);
    }
  }

  getProducts = async () => {
    const { userData } = this.state;

    let endpoint = "";

    if (["master", "submaster"].includes(userData.level))
      endpoint = "/master/products";
    else if (["corporation", "subcorp"].includes(userData.level))
      endpoint = `/corporation/${userData.id}/products`
    else endpoint = "/products";


    try {
      const response = await mainApi.get(endpoint);
      const products = endpoint == "/master/products" || endpoint == "/products" ? response.data.products : response.data.data.products;

      const productsToSelect = [];

      products.forEach((product) => {
        productsToSelect.push({
          value: product._id,
          label: product.name,
        });
      });

      this.setState({
        products: productsToSelect,
        selectProduct: productsToSelect[0]
      });
    } catch (err) {
      if (err.response) console.log(err.response.data);
    }

    this.handleStatsCard()
    this.handleCorporationList()
  };

  createDownloadLink = (name) => {
    const { csv, selectDate } = this.state

    let file = "text/CSV;charset=utf-8,%EF%BB%BF" + encodeURI(csv);
    let filename = `${name}_${Moment(selectDate).format('YYYY-MM')}`;

    let a = document.createElement('a');

    a.href = "data:" + file;
    a.download = filename + ".csv";

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    this.setState({ csv: '' })
  }

  clearStates = () => {
    this.setState({
      totalCommission: 0,
      totalCorporation: 0,
      totalAffiliate: 0,
      csv: '',
      corporations: [],
      search: '',
      page: 1,
      limit: 10
    })
  }

  componentDidMount = async () => {
    const userData = await getUserData(this.props.history);

    this.setState({ userData })

    window.digitalData = {
      "site": {
        "nome": "Banco Pan"
      },
      "page": {
        "secao": "IndiqueoPan",
        "estadoLogin": "LG",
        "nome": "BP:IndiqueOPan:Extrato",
      },
      "user": {
        "crmid": userData.id,
        "tipo": userData.brType
      }
    }

    var evt = new CustomEvent('event-view-start');
    document.body.dispatchEvent(evt);

    this.getProducts()

    const { routeKey } = this.props

    introSystem(routeKey)
  }

  changeSelectProduct = async (event) => {
    this.setState(
      { selectProduct: event }, () => {
        this.handleStatsCard()
        this.handleCorporationList()
      }
    );
  };

  render() {
    const {
      selectDate,
      totalCommission,
      totalCorporation,
      totalAffiliate,
      loading,
      corporations,
      swalMessageShow,
      swalTitle,
      swalText,
      selectProduct,
      totalCount } = this.state

    const isConta = selectProduct && selectProduct.value == contaId

    const tableOptions = {
      elevation: 0,
      filterType: "dropdown",
      count: this.state.totalDocs,
      responsive: "scrollMaxHeight",
      serverSide: true,
      pagination: true,
      selectableRows: false,
      viewColumns: false,
      download: false,
      print: false,
      search: true,
      filter: false,
      rowsPerPage: this.state.limit,
      rowsPerPageOptions: [10, 15, 30, 50],
      onSearchChange: (text) => {
        if (text?.length <= 0 || text?.length >= 4)
          this.setState({ search: `&name=${text}` }, () => this.handleCorporationList())
      },
      onSearchClose: () => {
        this.setState({ search: '' }, () => this.handleCorporationList())
      },
      onTableChange: (action, tableState) => {
        switch (action) {
          case "changePage":
            this.setState({ page: tableState.page + 1 }, () => {
              this.handleCorporationList()
            })
            break;
          case "changeRowsPerPage":
            this.setState({ limit: tableState.rowsPerPage, page: 0 }, () => {
              this.handleCorporationList()
            })
            break;
          default: break;
        }
      },
      textLabels: {
        body: {
          noMatch: "Desculpe, nenhum dado corresponde a pesquisa"
        },
        pagination: {
          next: "Próxima Página",
          previous: "Pagina Anterior",
          rowsPerPage: "Linhas por página:",
          displayRows: "de"
        },
        toolbar: {
          search: "Procurar",
          downloadCsv: "Download CSV",
          print: "Imprimir",
          viewColumns: "Mostrar Colunas",
          filterTable: "Filtrar Tabela"
        },
        filter: {
          title: "FILTROS",
          reset: "limpar",
          all: "Todos"
        },
        viewColumns: {
          title: "Mostrar Colunas"
        }
      },
    };

    const DatePickerInput = ({ value, onClick }) => (
      <button className="datepicker-input-custom" onClick={onClick}>
        {value}
      </button>
    );

    return (
      <>
        {loading && <LoadingCover dashboard />}

        <Dialog
          type={'warning'}
          show={swalMessageShow}
          title={swalTitle || ""}
          onConfirm={() => this.setState({ swalMessageShow: false })}
          text={swalText}
        />
        <div className="content">
          <Grid fluid style={{ paddingRight: 15 }}>
            <Row style={{ marginBottom: '10px' }}>
              <Col className="form-group" md={4} lg={4}>
                <Select
                  className="custom-select"
                  placeholder="Selecione um Produto"
                  options={this.state.products}
                  value={selectProduct}
                  onChange={(event) => this.changeSelectProduct(event)}
                />
              </Col>

              <Col className="form-group" md={4} lg={3}>
                <DatePicker
                  className="datepicker-theThird"
                  dateFormat="MM/yyyy"
                  locale="pt-BR"
                  customInput={
                    <div className="div-datepicker">
                      <div className="col-6">
                        <span className="placeholder-datepicker">
                          {selectDate ? "Mês selecionado:" : "Selecione um mês"}
                        </span>
                      </div>
                      <div className="col-3">
                        <p className="date-datepicker">
                          {selectDate ? getMonthAndYear(selectDate) : ""}
                        </p>
                      </div>
                      <div
                        className="col-3"
                        style={{ alignSelf: "center" }}
                      >
                        {selectDate ? (
                          <></>
                        ) : (
                            <MahoeIcon
                              aria-describedby={"iconTooltip"}
                              name="info"
                              size="23px"
                              fill="#039BE5"
                            />
                          )}
                      </div>
                    </div>
                  }
                  selected={selectDate}
                  showMonthYearPicker
                  onChange={date => {
                    this.setState({ selectDate: date }, () => {
                      this.clearStates()
                      this.handleStatsCard()
                      this.handleCorporationList()
                    })
                  }}
                  minDate={new Date('12-12-2019')}
                  maxDate={new Date()}
                />
              </Col>

            </Row>

            <Row>
              <Col lg={4} md={4} sm={12}>
                <StatsCard
                  bigIcon={
                    <MahoeIcon
                      name="coins"
                      size="25px"
                      fill="rgb(128, 210, 99)"
                    />
                  }
                  id="data-tour-extract-master-total-corporation"
                  statsText={loading ? 'n/a' : (totalCorporation === 0 ? "R$ 0,00" : numToBRL(totalCorporation))}
                  statsValue="Total corporações"
                  statsIcon={<i className="fa fa-calendar-o" />}
                  statsIconText="Atual"
                />
              </Col>

              <Col lg={4} md={4} sm={12}>
                <StatsCard
                  bigIcon={
                    <MahoeIcon
                      name="coins"
                      size="25px"
                      fill="rgb(128, 210, 99)"
                    />
                  }
                  id="data-tour-extract-master-total-affiliate"
                  statsText={loading ? 'n/a' : (totalAffiliate === 0 ? "R$ 0,00" : numToBRL(totalAffiliate))}
                  statsValue="Total afiliados mar aberto"
                  statsIcon={<i className="fa fa-calendar-o" />}
                  statsIconText="Atual"
                />
              </Col>

              <Col lg={4} md={4} sm={12}>
                <StatsCard
                  bigIcon={
                    <MahoeIcon
                      name={isConta ? "contract" : "coins"}
                      size="25px"
                      fill="rgb(128, 210, 99)"
                    />
                  }
                  id="data-tour-extract-corporation-total-sells"
                  statsText={
                    loading ? 'n/a' : isConta
                      ? totalCount
                      : (totalCommission === 0 ? "R$ 0,00" : numToBRL(totalCommission))
                  }
                  statsValue={isConta ? "Quantidade de contas abertas" : "Total vendido"}
                  statsIcon={<i className="fa fa-calendar-o" />}
                  statsIconText="Atual"
                />
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={12} sm={12}>
                <p style={{ marginLeft: '10px' }}><strong>Atenção:</strong> ao baixar e abrir o extrato, separe apenas por <strong style={{ fontSize: '25px' }}>;</strong> (ponto-e-vírgula) para realizar a leitura dos dados corretamente.</p>
                <div className="extract-list">
                  <div className="extract-donwload-container">
                    <p className="mb-0" style={{ flex: 1, fontSize: 18 }}>Dados referentes ao produto <em className="bold">{selectProduct.label}</em>  no mês de <em className="bold">{translateMonths(Moment(selectDate).format('MMMM'))}</em></p>
                    <button
                      id="data-tour-extract-master-button-corporation"
                      className="btn btn-fill btn-mlm btn-mlm-sm btn-small-extract"
                      onClick={() => {
                        this.handleCorporationCsv();
                      }}
                    >
                      Baixar extrato corporações
                    </button>
                    <button
                      id="data-tour-extract-master-button-affiliate"
                      onClick={() => {
                        this.handleAffiliateCsv()
                      }}
                      className="btn btn-fill btn-mlm btn-mlm-sm btn-small-extract"
                    >
                      Baixar extrato afiliados
                    </button>
                  </div>
                  <MuiThemeProvider theme={getMuiTheme}>
                    <MUIDataTable
                      columns={this.teamHeader}
                      data={corporations}
                      options={tableOptions}
                    />
                  </MuiThemeProvider>
                </div>
              </Col>
            </Row>
          </Grid>
        </div>
      </>
    )
  }
}

export default Extract;
