import React from 'react'
import { createMuiTheme } from "@material-ui/core/styles";

import BasicPopover from '../../components/BasicPopover/BasicPopover'
import { translateLevel } from 'utils/translate';

const columns = [
  {
    name: "id",
    label: "ID",
    options: {
      display: false,
      hint: "Identificador do afiliado"
    }
  },
  {
    name: "name",
    label: "Nome",
    options: {
      sort: false,
      hint: "Nome do afiliado"
    }
  },
  {
    name: "level",
    label: "Nível",
    options: {
      sort: false,
      hint: "Nível do afiliado no sistema"
    }
  },
  {
    name: "email",
    label: "Email",
    options: {
      sort: false,
      hint: "Email do afiliado",
      customBodyRender: (value) =>
      value ? (
        <BasicPopover
          text={value}
          buttonText="Ver Email"
        />
      ) : (
        "n/a"
      ),
    }
  },
  {
    name: "createdAt",
    label: "Data cadastro",
    options: {
      sort: false,
      hint: "Data que o afiliado realizou o cadastro."
    }
  },
  {
    name: "approvedAt",
    label: "Data aprovação",
    options: {
      sort: false,
      hint: "Data de aprovação do afiliado caso tenha se cadastrado no sistema por conta própria."
    }
  },
  {
    name: "agencyName",
    label: "Loja",
    options: {
      sort: false,
      hint: "Nome da loja a qual pertence o afiliado."
    }
  },
  {
    name: "corporationName",
    label: "Corporação",
    options: {
      sort: false,
      hint: "Nome da corporação a qual pertence o afiliado."
    }
  },
  {
    name: "affiliate",
    label: "Tipo",
    options: {
      sort: false,
      hint: "Tipo do afiliado."
    }
  }
]

const getMuiTheme = () => {
  createMuiTheme({
    typography: {
      fontFamily: '"Montserrat"',
      fontSize: 14
    },
    overrides: {
      typography: {
        fontFamily: '"Montserrat"',
        fontSize: 14
      },
      MUIDataTable: {
        root: {
          fontSize: 44
        }
      },
      MuiTypography: {
        root: {
          fontFamily: '"Montserrat"',
          fontSize: 14
        }
      },
      MUIDataTableBodyCell: {
        root: {
          fontSize: 14,
          fontFamily: "Montserrat",
          lineHeight: "20px",
          fontWeight: "500",
          color: "#616161",
          textAlign: "left"
        }
      },
      MUIDataTableHeadCell: {
        root: {
          fontSize: 14,
          fontFamily: "Montserrat",
          lineHeight: "20px",
          fontWeight: "600",
          color: "#414141",
          textAlign: "left"
        }
      }
    }
  });
}

const levelOptions = (strictId) => [
  { value: '', label: 'Todos' },
  { value: 'seller', label: translateLevel('seller', strictId) },
  { value: 'supervisor', label: translateLevel('supervisor', strictId) },
  { value: 'administrator', label: translateLevel('administrator', strictId),  }
]

const typeAffiliate = [
  { value: '', label: 'Todos' },
  { value: 'true', label: 'Individual' },
  { value: 'false', label: 'Lojista' },
]

export { columns, getMuiTheme, levelOptions, typeAffiliate }

