import React, { useLayoutEffect, useState } from "react";
import mainApi from "services/mainApi";
import { MuiTableConfig } from "utils/CommonData";
import getMuiTheme from "utils/getMuiTheme";
import Card from "../Card/Card";
import { MuiThemeProvider } from "material-ui/styles";
import CampaignList from "components/CampaignList";
import to from "await-to-js";

import MUIDataTable from "mui-datatables";
import { IconButton, MenuItem } from "material-ui";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  Button,
  ButtonGroup,
  Menu,
  Tooltip,
  CircularProgress,
  FormHelperText,
} from "@material-ui/core"
import MuiSelect from 'components/Selects/MuiSelect'
import spinner from "assets/img/loading.gif"

import moment from "moment";

import CopyToClipboard from "react-copy-to-clipboard";
import UnimportantText from "../UnimportantText";
import format from "utils/formater";
import Totals from "./Totals";
import { Row, Col } from 'react-bootstrap'

import { getMonthAndYear } from "utils/formatData";
import MahoeIcon from "components/MahoeIcons";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptbr from "date-fns/locale/pt-BR";
import { translateMonths } from "utils/translate";
registerLocale("pt-BR", ptbr);

const isActive = {
  backgroundColor: "#039be5",
  color: "white",
};

const LinksExplanation = {
  static: "Links estáticos da loja.",
  user: "Seus links.",
  all: "Todos os links gerados pelos vendedores / supervisores da loja.",
  campaign: "Links de campanhas da loja.",
};

// eslint-disable-next-line
Array.prototype.insert = function (index, item) {
  this.splice(index, 0, item);
};

function LinkActions({ code, shortenBaseUrl }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const url = shortenBaseUrl + "/" + code;
  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: "15ch",
          },
        }}
      >
        <span role="menuitem" className="mui-menuitem">
          <a
            className="mui-menuitem-text"
            key={code}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleClose}
          >
            Abrir
          </a>
        </span>
        <CopyToClipboard text={url}>
          <MenuItem onClick={handleClose}>Copiar</MenuItem>
        </CopyToClipboard>
      </Menu>
    </>
  );
}

function UserLinksTabs({ userLevel, reloadCampaign, isReload }) {
  const [links, setLinks] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [pagination, setPagination] = useState({});
  const [filterLinks, setFilterLinks] = useState("static");
  const [userHasLinks, setUserHasLinks] = useState(false);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([])
  const [selectedProduct, setSelectedProduct] = useState('')

  const [ selectDate, setSelectDate ] =useState(new Date())
  const [loadingData, setLoadingData] = useState(false);
  
  const getProducts = async () => {

    const [err, response] = await to(mainApi.get(`/products`))


    if (err || !response) {
      return
    }

    const products = response.data.products
    const productsToSelect = products.map(item => ({ value: item._id, label: item.name }))

    setProducts(productsToSelect)

  }

  async function checkIfUserHasLinks() {
    try {
      const response = await mainApi.get(`/links?page=1&limit=1&filter=user`);
      const links = response.data.data.links.docs;
      if (links.length) {
        setUserHasLinks(true);
      }
    } catch (err) {
      setUserHasLinks(false);
    }
  }

  async function getUserLinks(selectedPage = 1, selectedLimit = limit) {
    const filter = filterLinks !== "all";
    setLoading(true);
    try {
      const response = await mainApi.get(
        `/links?page=${selectedPage}&limit=${selectedLimit}${filter ? "&filter=" + filterLinks : ""
        }`
      );

      const shortenBaseUrl = response.data.data.shortenBaseUrl;
      const links = response.data.data.links.docs;

      const linksWithActions = links.map((link) => {
        return {
          ...link,
          type: link.staticLink
            ? "Loja"
            : link.specific
              ? "Específico"
              : "Genérico",
          actions: (
            <LinkActions
              code={link?.shorten.code}
              shortenBaseUrl={shortenBaseUrl}
            />
          ),
        };
      });
      setLoading(false);

      setLinks(linksWithActions);

      const linksPagination = { ...response.data.data.links, docs: undefined };
      setPagination(linksPagination);
    } catch (err) { }
  }

  useLayoutEffect(() => {

    getProducts()
    getUserLinks();
    setPage(0);
    //eslint-disable-next-line
  }, [filterLinks]);

  useLayoutEffect(() => {
    checkIfUserHasLinks();
    //eslint-disable-next-line
  }, []);

  function updateClicks ({id, value}) {
    setLinks(prev => prev.map(link => link._id !== id ? link :
      ({...link, shorten: {...link.shorten, clicks: value}})
    ))
  }

  const showClick = async (id) => {
    updateClicks({id, value: 'loading'})
    try {
      const response = await  mainApi.get(`/links/${id}/click`)
      updateClicks({id, value:  response.data.data.clicks})
    } catch (e) {
      console.log(e)
      updateClicks({id, value: undefined})
    }
  }

  const columns = [
    {
      name: "_id",
      label: "ID",
      options: {
        display: false,
        viewColumns: false,
      }
    },
    {
      name: "product.name",
      label: "Produto",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "createdAt",
      label: "Criado em",
      options: {
        customBodyRender: (value) => {
          if (value?.trim()) return moment(value).format("HH:mm DD/MM/YYYY");
          else return "n/a";
        },
        filter: false,
        sort: false,
      },
    },
    {
      name: "shorten.code",
      label: "Código",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "cpf",
      label: "CPF (específico)",
      options: {
        customBodyRender: (value) => {
          if (value?.trim()) return format.toCPF(value);
          else return <UnimportantText text="Link Genérico" />;
        },
        filter: false,
        sort: false,
      },
    },
    {
      name: "shorten.clicks",
      label: "Cliques",
      options: {
        filter: false,
        sort: false,
        sort: false,
        customBodyRender: (value, tableInfo) => {
          const { rowData, columnIndex } = tableInfo;
          if (typeof value === 'number')
            return value
          if (value === 'loading')
            return <img style={{height: 14, width: 14}} src={spinner} alt="" />
          return <button onClick={() => showClick(rowData[0])} className='anchor-button'>Ver</button>
        }
      },
    },
    {
      name: "actions",
      label: "Ações",
      options: {
        filter: false,
        sort: false,
      },
    },
  ];
  if (userLevel && !["seller", "supervisor"].includes(userLevel))
    columns.insert(2, {
      name: "user.name",
      label: "Criado por",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={value || ""}>
              <span>{format.toJustFirstName(value)}</span>
            </Tooltip>
          );
        },
        filter: false,
        sort: false,
      },
    });

  const userLinksTableConfig = {
    ...MuiTableConfig,
    download: false,
    filter: false,
    search: false,
    print: false,
    sort: false,
    viewColumns: false,
    rowsPerPageOptions: [5, 15, 30],
    rowsPerPage: limit,
    serverSide: true,
    count: pagination.totalDocs || 0,
    page: page || 0,
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          setPage(tableState.page);
          getUserLinks(tableState.page + 1);
          break;
        case "propsUpdate":
          if (page !== tableState.page) tableState.page = 0;
          break;
        case "changeRowsPerPage":
          setPage(0);
          setLimit(tableState.rowsPerPage);
          getUserLinks(1, tableState.rowsPerPage);
          break;
        default:
          break;
      }
    }
  };

  return (
    <Card
      content={
        <>
          <Row>
            <Col md={filterLinks === "campaign"? 6 : 9} sm={12} style={{paddingBottom:28}}>
            {userHasLinks ? (
              <ButtonGroup disableElevation variant="outlined" color="black">
                <Button
                  style={filterLinks === "static" ? isActive : {}}
                  onClick={() => setFilterLinks("static")}
                >
                  Links da Loja
              </Button>
                <Button
                  style={filterLinks === "user" ? isActive : {}}
                  onClick={() => setFilterLinks("user")}
                >
                  Seus Links
              </Button>
                <Button
                  style={filterLinks === "all" ? isActive : {}}
                  onClick={() => setFilterLinks("all")}
                >
                  Todos os Links
              </Button>
                <Button
                  style={filterLinks === "campaign" ? isActive : {}}
                  onClick={() => setFilterLinks("campaign")}
                >
                  Links de Campanha
              </Button>
              </ButtonGroup>
            ) : (
                <ButtonGroup disableElevation className="pt-2" variant="outlined" color="black" style={{ flex: 1 }}>
                  <Button
                    style={filterLinks === "static" ? isActive : {}}
                    onClick={() => setFilterLinks("static")}
                  >
                      Links da Loja
                  </Button>
                  <Button
                    style={filterLinks === "campaign" ? isActive : {}}
                    onClick={() => setFilterLinks("campaign")}
                  >
                      Links de Campanha
                  </Button>
                </ButtonGroup>
              )}
            </Col>              

            {filterLinks === "campaign" && (
              <Col md={6} sm={12} className="flex-campaign">
                <Col className="form-group" md={6} >
                  <FormHelperText>Filtrar por mês</FormHelperText>
                  <DatePicker
                    className={`datepicker-theThird ${loadingData ? 'datepicker-theThird-disabled' : ''}`}
                    dateFormat="MM/yyyy"
                    locale="pt-BR"
                    disabled={loadingData}
                    customInput={
                    <div className="div-datepicker" style={{height:'initial'}}>
                      <div className="col-6">
                        <span className="placeholder-datepicker">
                          {selectDate ? "Mês selecionado:" : "Selecione um mês"}
                        </span>
                      </div>
                      <div className="col-3">
                        <p className="date-datepicker">
                          {selectDate ? getMonthAndYear(selectDate) : ""}
                        </p>
                      </div>
                      <div
                        className="col-3"
                        style={{ alignSelf: "center" }}
                        onMouseEnter={(event) => this.handleMouse(event)}
                        onMouseLeave={() =>
                          this.setState({ openPopper: false })
                        }
                      >
                        {selectDate ? (
                          <></>
                        ) : (
                          <MahoeIcon
                            aria-describedby={"iconTooltip"}
                            name="info"
                            size="23px"
                            fill="#039BE5"
                          />
                        )}
                      </div>
                    </div>
                    }
                    selected={selectDate}
                    showMonthYearPicker
                    onChange={(date, event) => setSelectDate(date)}
                    maxDate={new Date()}
                    minDate={new Date("2020-01-01")}
                  />
                </Col>
                <Col md={6}>              
                <FormHelperText>Filtrar por produto</FormHelperText>
                <MuiSelect          
                  sm
                  onChange={(event) => setSelectedProduct(event.value)}
                  placeholder="Selecionar o produto"
                  options={[ {value: "", label: "Todos os produtos"}, ...products ] || []}
                  defaultValue={{value: "", label: "Todos os produtos"}}
                />
                </Col>
              </Col>
            )}
          </Row>


          <div className='campaign-header'>
            <div className="display-flex flex-start">
              <h5
                variant="title"
                className="pb-3"
                style={{ fontWeight: "500", marginBottom: 0, paddingBottom: 0 }}
              >
                {LinksExplanation[filterLinks]}
              </h5>

              {loading ? (
                <CircularProgress
                  size={24}
                  style={{
                    marginLeft: 15,
                    position: "relative",
                    top: 15
                  }}
                />
              ) : (
                  <></>
                )}
            </div>
            
            {filterLinks === "campaign" && (
              <div style={{paddingRight:15}}>
                <p>Dados referentes ao mês de <strong>{translateMonths(moment(selectDate).format('MMMM'))}</strong></p>
              </div>
            )}
          </div>

          {filterLinks === "campaign" ? (
            <CampaignList
              product={selectedProduct}
              level={userLevel}
              date={selectDate}
              reloadCampaign={reloadCampaign}
              isReload={isReload}
            />
          ) : (
              <MuiThemeProvider theme={getMuiTheme}>
                <MUIDataTable
                  columns={columns}
                  data={links}
                  options={userLinksTableConfig}
                />
              </MuiThemeProvider>
            )}

        </>
      }
    />
  );
}

export default UserLinksTabs;
